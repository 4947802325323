import * as moment from 'moment-timezone';
import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { LangService } from '../../core/lang.service';
import { IUserInfo, AuthService } from '../../api/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import { AccountType } from '../../constants/account-types';
import { Title } from '@angular/platform-browser';
import { WhitelabelService } from '../../domain/whitelabel.service';
import {ChatService} from '../../chat/chat.service';
import { interval, Subscription } from 'rxjs';
import { LoginGuardService } from '../../api/login-guard.service';
import { NotificationsService } from 'src/app/ui-item-maker/notifications.service';

export const getSiteSectionColor = (accountType: AccountType | null) => {
  switch (accountType) {
    case AccountType.TEST_ADMIN : return 'rgba(255, 214, 0, 0.37)';
    case AccountType.TEST_TAKER : return 'rgba(99, 237, 35, 0.37)';
    case AccountType.TEST_CTRL : return 'rgba(236, 64, 64, 0.37)';
    case AccountType.CERT_BODY : return 'rgba(0, 0, 0, 0.37)';
    default: return 'rgba(64, 143, 236, 0.37)';
  }
};

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy, OnChanges {

  @Input() breadcrumbPath: {caption: string, route: string, params?}[];
  @Input() siteSection: boolean;
  @Input() accountInfo: string;
  @Input() hasSidebar: boolean;
  @Input() hideSupportBtn: boolean;
  @Input() isLoginPage: boolean;
  @Input() isLoginHidden: boolean;
  @Input() hasPrint: boolean;
  @Input() icon: string;
  @Input() accountType: AccountType;
  @Input() newMessage: boolean;
  @Input() messagePoolId: string;
  @Input() noLangSwitcher: boolean
  @Input() isOpaque: boolean
  @Input() techSupportDataKey: string

  userInfo: IUserInfo;
  isMenuRevealed: boolean;
  schoolType: string;
  
  langs = [
    {code: 'en', caption: 'English'},
    {code: 'fr', caption: 'Français'},
  ];

  public timeZone: string;
  subscription = new Subscription();
  notificationSub = new Subscription();

  constructor(
    public langService: LangService,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private whitelabel: WhitelabelService,
    private titleService: Title,
    private loginGuard: LoginGuardService,
    public notifications: NotificationsService
    // private headerChatService: ChatService
  ) { }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if(this.notificationSub) {
      this.notificationSub.unsubscribe();
    }
  }

  ngOnInit() {
    this.subscription.add(
      this.auth
      .user()
      .subscribe(t => this.userInfo = t)
    );
    this.updateTitle();
    this.timeZone = moment.tz.guess();
    this.schoolType = this.route.snapshot.data['schoolType'] === 'BC_GRAD' ? 'bc-grad' : 'bc-fsa';
    this.notifications.refreshData();
    const source = interval(60000);
    this.notificationSub = source.subscribe( val => {this.notifications.refreshData();}) //refresh unread data every minute
  }
  
  supportReqActivate(){
    let techSupportInfo;
    if (this.techSupportDataKey){
      techSupportInfo = this.whitelabel.getSiteData(this.techSupportDataKey)
    }
    this.loginGuard.supportReqActivate(techSupportInfo)
  }
  
  ngOnChanges(changes:SimpleChanges){
    if (changes.breadcrumbPath){
      this.updateTitle();
    }
  }

  goBackHome(){
    const msg = this.langService.tra('txt_alert_login_home');
    this.router.navigateByUrl(`/${this.langService.c()}/login-portal`)
  }

  updateTitle() {
    if (this.breadcrumbPath && this.breadcrumbPath.length > 0) {
      const crumb = this.breadcrumbPath[this.breadcrumbPath.length - 1];
      this.titleService.setTitle( this.langService.tra(crumb.caption) + ' | ' + this.langService.tra(this.getBranding()) );
    }
  }

  getWhitelabelUrl(slug:string){
    return this.whitelabel.getSiteText(slug);
  }

  isBreadcrumbMenuEnabled() {
    return this.whitelabel.getSiteFlag('BREADCRUMB_MENU');
  }

  getHomeScreenRoute() {
    return this.whitelabel.getHomepageRoute();
  }

  getBranding() {
    return this.whitelabel.currentBrandName;
  }

  getStripColor() {
    return getSiteSectionColor( this.getAccountType() );
  }

  toggleMenu() {
    this.isMenuRevealed = !this.isMenuRevealed;
  }

  getDashboardRoute(){
    return this.auth.getDashboardRoute(this.langService.c())
  }

  getAccountType(useDefault: boolean= false) {
    if (!this.accountType) {
      if (this.userInfo) {
        return this.userInfo.accountType;
      }
    } else {
      return this.accountType;
    }
    if (useDefault) {
      return AccountType.TEST_TAKER;
    }
    return null;
  }

  getUserDisplayName() {
    return this.userInfo.firstName + ' ' + this.userInfo.lastName;
  }

  getLang() {
    return this.langService.getCurrentLanguage();
  }

  isLangActive(langCode: string) {
    return (this.getLang() === langCode);
  }

  setLang(langCode: string) {
    this.langService.loadInLang(langCode);
  }
  isEnglish(){
    return this.isLangActive('en');
  }

  getLoginRoute() {
    return `/${this.langService.c()}/${this.getAccountType(true)}/login/true`;
  }

  logout() {
    const loginRoute = this.getLoginRoute();
    this.auth
      .logout()
      .then( r => {
        this.router.navigate([loginRoute]);
        setTimeout(() => {
          window.location.reload();
        }, 300);
      });
  }

  onClickNewMessage() {
    console.log('click');
  }

  getNotificationsText() {
    let text = "Notifications";
    // let text = "";

    const numUnread = this.notifications.getNumUnread();
    
    if(numUnread) {
      text += ` (${numUnread})`
    } 

    return text;
  }

  isLoggedIn() {
    return this.auth.checkLoggedIn()
  }
}
