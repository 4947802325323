import { Injectable, ElementRef } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ItemBankCtrl } from '../ui-item-maker/item-set-editor/controllers/item-bank';
import { StyleprofileService, processText } from '../core/styleprofile.service';
import { LangService } from '../core/lang.service';
import { namedCharRefs } from './data/named-char-refs';
import * as Diff from 'diff';
import { ItemComponentEditService } from '../ui-item-maker/item-component-edit.service';
import { RoutesService } from '../api/routes.service';
import { AuthService } from '../api/auth.service';
import { EditViewMode } from '../ui-item-maker/item-set-editor/models/types';
import * as _ from 'lodash';
import { EditingDisabledService } from '../ui-item-maker/editing-disabled.service';
import { LoginGuardService } from '../api/login-guard.service';
import { SafeResourceUrl} from '@angular/platform-browser';

export interface IConfigElemHighlight {
  entryId: number,
  prop: string,
  selection:IConfigElemSelection
}

export interface IConfigElemSelection {
  start?: number,
  end?: number,
  isWhole?: number
  highlightHtml?:string,
  isImage?:number
}

export enum ModeType {
  SUGGESTION = "SUGGESTION",
  REAL = "REAL",
}

export enum DiffActionType {
  ACCEPT = "ACCEPT",
  REJECT = "REJECT",
}

export interface IInfoMapEntry {
  noteId:number,
  start?:number,
  end?:number,
  isWhole?:number,
  isInvalid?:boolean,
  isImage?:number
}

@Injectable({
  providedIn: 'root'
})
export class HighlighterService {

  constructor(
    private profile: StyleprofileService,
    private lang: LangService,
    private itemComponentEdit: ItemComponentEditService,
    private routes: RoutesService,
    private auth: AuthService,
    private editingDisabled: EditingDisabledService,
    private loginGuard: LoginGuardService
  ) {
    this.handleDiffActionSub = this.itemComponentEdit.textDiffAction.subscribe(diffActionDetails => {
      this.handleDiffAction(diffActionDetails);
    });
  }
  private handleDiffActionSub: Subscription; //TODO: Remove?
  public currentSelectedHighlight;
  public changeIntervalAttemptNoteId;
  public isModeTransitionInProgress:boolean;
  public isHighlightCommentButtonActive: boolean;
  public editViewMode: EditViewMode = EditViewMode.AFTER;
  public highlightInfoMap = new Map<string, Map<string, any>>([
    [ModeType.REAL, new Map<string, IInfoMapEntry[]>()],
    [ModeType.SUGGESTION, new Map<string, IInfoMapEntry[]>()]
  ]);


  private passedSelections = [];

  /** Once info about comments mapping to text arrives, save it on the clientside */
  loadHighlightMap(highlightInfo){
    for (let mapKey in highlightInfo[ModeType.REAL]){
      this.highlightInfoMap.get(ModeType.REAL).set(mapKey, highlightInfo[ModeType.REAL][mapKey])
    }
    for (let mapKey in highlightInfo[ModeType.SUGGESTION]){
      this.highlightInfoMap.get(ModeType.SUGGESTION).set(mapKey, highlightInfo[ModeType.SUGGESTION][mapKey])
    }
  }

  //** After a new highlight note is created, add it to the client map which tracks locations */
  addHighlightCommentToMap(input: {modeType: ModeType, noteId: number, entryId: number, prop:string, start?:number, end?:number, isWhole?:number, isImage?:number}){
    const {modeType, noteId, entryId, prop, start, end, isWhole, isImage} = input;
    const mapKey = entryId + "-" + prop;
    let commentInfoList = this.highlightInfoMap.get(modeType).get(mapKey);
    if (!commentInfoList) {
      commentInfoList = [];
      this.highlightInfoMap.get(modeType).set(mapKey, commentInfoList);
    }
    const commentInfo:IInfoMapEntry = {noteId, start, end, isWhole, isImage}
    commentInfoList.push(commentInfo)
  }

  //** Given a comment ID, return where it applies to the config, in the currently open view (real vs. suggestion) */
  getCommentDetailById(noteId){
    const commentConfig = []
    const currentMode = this.identifyViewMode();
    for (let mapKey of this.highlightInfoMap.get(currentMode).keys()){
      for (const commentInfo of this.highlightInfoMap.get(currentMode).get(mapKey)){
        if (commentInfo.noteId === noteId){
          const [entryId, prop] = [+mapKey.split("-")[0], mapKey.split("-")[1]];
          const {isWhole, isImage, start, end, isInvalid} = commentInfo;
          commentConfig.push({isWhole, isImage, start, end, isInvalid, entryId, prop})
        }
      }
    }
    return commentConfig;
  }

  /** Identifies what is being displayed in the question runner
   * @returns `ModeType.REAL` or `ModeType.SUGGESTION`
  */
  identifyViewMode(){
    if (this.identifyMode() == ModeType.REAL) return ModeType.REAL
    return (this.editViewMode == EditViewMode.BEFORE) ? ModeType.REAL : ModeType.SUGGESTION
  }

  /** Identifies what is being edited - the suggestion if in tracking changes, or real question otherwise
   * @returns `ModeType.REAL` or `ModeType.SUGGESTION`
  */
  identifyMode(){
    return this.editingDisabled.getCurrQTrackingChanges() ? ModeType.SUGGESTION : ModeType.REAL
  }

  // This is for what is currently being viewed...
  // For accept/reject can be a thing...
  // ALSO -- When switching between before / after -- this list of comments has to update...
  setEditViewMode(newMode: EditViewMode) {
    this.editViewMode = newMode;
  }

  /** Given the entryId and prop reference, find the string in either real config or suggestion depending on mode */
  getTargetString(modeType:ModeType, entryId, prop){
    try {
      const questionContent = modeType == ModeType.REAL ? this.itemComponentEdit.originalQuestionState : this.itemComponentEdit.suggestion?.state
      const entryIdElem = this.itemComponentEdit.deepFind(questionContent, 'entryId', entryId);
      const targetString = entryIdElem[prop]
      return targetString
    } catch (e){
      // Return undefined if not found
    }
  }

  /** 
   * Finds the config text in HTML format that a comment applies to, in the current view
   * @param noteId - Id of the comment
   * @returns The HTML string of the highlight, or undefined if not found
  */
  getHighlightHtmlByCommentId(noteId:number){
    try {
      const currentViewMode = this.identifyViewMode();
      let highlightHtmlList = []
      const commentDetail = this.getCommentDetailById(noteId)
      commentDetail.forEach(detail => {
        const {entryId, prop, start, end, isWhole, isInvalid} = detail;
        const rawTargetString = this.getTargetString(currentViewMode, entryId, prop)
        if (isInvalid) return;
        // If comment on the whole string, the whole thing is the highlight, otherwise find by start-end interval
        if (isWhole) highlightHtmlList.push(this.rawToHtmlString(rawTargetString))
        else highlightHtmlList.push(this.splitHtmlOnHighlight(rawTargetString, start, end).highlight)
      })
      if (highlightHtmlList.length) return highlightHtmlList.join('<br/>');
    } catch (e) {
    }
  }

  /**
   * If the given note maps to an image, find its URL
   * @param noteId 
   * @returns A `SafeResourceUrl` of the image in the config, only if the mapping exists
   */
  getTargetImageByCommentId(noteId:number){
    try {
      const currentViewMode = this.identifyViewMode();
      const commentDetail = this.getCommentDetailById(noteId)
      // There is no multi-element selection possible in one note with images, the image mapping would be the first and only valid one in the list
      const firstValidEntry = commentDetail.find(d => !d.isInvalid)
      if (firstValidEntry && firstValidEntry.isImage) {
        const {entryId, prop} = firstValidEntry;
        const rawTargetImage = this.getTargetString(currentViewMode, entryId, prop)
        return rawTargetImage;
      }
    } catch (e) {}
  }

  /** When a question or suggestion gets saved (creating a new version), save its current comment map into the db*/
  saveHighlightInfoMap(input: {test_question_id: number, test_question_version_id?: number, test_question_suggestion_version_id?:number}){
      const {test_question_id, test_question_version_id, test_question_suggestion_version_id} = input;
      // Depending on the input, saving either the real or suggestion part of the map
      const saveType = test_question_version_id ? ModeType.REAL : ModeType.SUGGESTION
      const mapToSave = this.highlightInfoMap.get(saveType)

      const saveDataByNoteId = {}
      for (let mapKey of mapToSave.keys()){
        const [entryId, prop] = [+mapKey.split("-")[0], mapKey.split("-")[1]];
        for (let elem of mapToSave.get(mapKey)){
          //If isInvalid (part of config no longer exists), don't include in mapping (but still proceed to save even potentially empty map, invalidly comments become detatched but not deleted)
          if (!saveDataByNoteId[elem.noteId]) saveDataByNoteId[elem.noteId] = {
            test_question_id,
            // One of the following two keys is populated from input, the other must be undefined
            test_question_version_id,
            test_question_suggestion_version_id,
            test_question_auth_note_id: elem.noteId,
            config_map: (!elem.isInvalid) ? [{
              entryId, prop, start: elem.start, end: elem.end, isWhole: elem.isWhole, isImage: elem.isImage
            }] : []
          }
          else {
            if (!elem.isInvalid) saveDataByNoteId[elem.noteId].config_map.push({
              entryId, prop, start: elem.start, end: elem.end, isWhole: elem.isWhole, isImage: elem.isImage
            })
          }
        }
      }
      const saveData = Object.values(saveDataByNoteId).map((data:any) => {
        return {...data, config_map: JSON.stringify(data.config_map)}
      })
      if (!saveData.length) return;
      return this.auth.apiCreate(this.routes.TEST_AUTH_HIGHLIGHT_NOTES, saveData, {query: {
        lang: this.lang.c(),
        // If resaving the map because the question was saved to the new version, highlights for the other language will also need to be copied into the new version
        resaveOtherLang: test_question_version_id ? 1 : undefined,
        test_question_id,
        new_test_question_version_id: test_question_version_id,
      }})
      .then((res) => {
      })
  }

  isStartEndInvalid(start:number,end:number){
    return start === -1 || end === -1 || end <= start
  }


  // When "New Highlight Comment" is pressed - should initiate finding what user selected on the screen
  private initHighlightCommentSource:BehaviorSubject<boolean> = new BehaviorSubject(false);
  initHighlightcomment = this.initHighlightCommentSource.asObservable();
  initHighlightCommentAttempt(){
    this.initHighlightCommentSource.next(true)
    // Wait 0.3 sec for all highlightable components to send their highlighted ranges
    setTimeout(() => {
      this.processSelectionFromElements()
    }, 300)
  }

  /** Pass all the selections gathered from highlightable elements, then clear gathered selections */
  processSelectionFromElements(){
    if (this.passedSelections.length) this.newValidSelectedTextSource.next(
      this.passedSelections.map(v => {
        delete v.nativeElement
        return v
      })
    )
    this.passedSelections = [];
  }

  // When user's selection is successfull detected - pass the details to initiate highlight comment modal
  private newValidSelectedTextSource:BehaviorSubject<any> = new BehaviorSubject(null);
  newValidSelectedText = this.newValidSelectedTextSource.asObservable();
  passSelectionFromElement(entryId: number, prop: string, selection: IConfigElemSelection, nativeElement: ElementRef){
    this.passedSelections.push({entryId, prop, selection, nativeElement})
  }

  // When one of the highlight comments is selected
  // Initiates highlighting the corresponding config fragment, and unhilighting everything else and unselecting other highlight comments
  private applyHighlightSource:BehaviorSubject<any> = new BehaviorSubject(null);
  applyHighlight = this.applyHighlightSource.asObservable();
  initApplyHighlight(highlightDetail: {targets?, noteId?:number, isResolved?:boolean}){
    this.currentSelectedHighlight = highlightDetail;
    this.applyHighlightSource.next(this.currentSelectedHighlight)
  }

  refreshHighlight(){
    this.applyHighlightSource.next(this.currentSelectedHighlight)
  }

  /**  When user submits the new comment, prep the data that goes to the API */
  prepHighlightInfo(highlightSelections: IConfigElemHighlight[]){
    const realConfigMap=[]
    const suggConfigMap=[]
    highlightSelections.forEach(selection => {
      const processedSelectionInfo = this.prepHighlightSelectionByMode(selection)
      if (processedSelectionInfo[ModeType.REAL]) realConfigMap.push(processedSelectionInfo[ModeType.REAL])
      if (processedSelectionInfo[ModeType.SUGGESTION]) suggConfigMap.push(processedSelectionInfo[ModeType.SUGGESTION])
    })
    const highlightInfo =  [];
    if (realConfigMap.length) highlightInfo.push({modeType: ModeType.REAL, config_map: JSON.stringify(realConfigMap)})
    if (suggConfigMap.length) highlightInfo.push({modeType: ModeType.SUGGESTION, config_map: JSON.stringify(suggConfigMap)})
    return highlightInfo;
  }

  /** For a part of the selection, rearrange and find possible match in the other mode (real/suggested) */
  prepHighlightSelectionByMode(highlightRef: IConfigElemHighlight){
    const {entryId, prop, selection} = highlightRef;
    const highlightSelectionByMode = {}
    const elementSelection = {
      entryId,
      prop,
      start: selection?.start,
      end: selection?.end,
      isWhole: selection?.isWhole,
      isImage: selection?.isImage
    }
    
    // If not in tracking changes, only apply comment to the real and stop there
    if (this.identifyMode() == ModeType.REAL) {
      highlightSelectionByMode[ModeType.REAL] = elementSelection
      return highlightSelectionByMode;
    }
    
    //If in suggestion mode, need to know if the selection was on the real or the suggested view
    const currentViewMode = this.identifyViewMode();
    const oppositeViewMode = currentViewMode == ModeType.REAL ? ModeType.SUGGESTION : ModeType.REAL;

    // Push the deails for the current view
    if (currentViewMode == ModeType.REAL) {
      highlightSelectionByMode[ModeType.REAL] = elementSelection
    } else {
      highlightSelectionByMode[ModeType.SUGGESTION] = elementSelection
    }

    // Find what string is being commented on and what it is in the other view.
    // If it doesn't exist in the other view (e.g. It's in a block that was suggested and not accepted yet and the current view is suggestion, or it's a block suggested for deletion and the current view is real etc.), stop here
    const currentViewString = this.getTargetString(currentViewMode, entryId, prop)
    const oppositeViewString = this.getTargetString(oppositeViewMode, entryId, prop)
    if (!oppositeViewString) {
      return highlightSelectionByMode;
    }

    const oppositeElementSelection = {...elementSelection}
    // If it's a whole-string rather than start/end interval comment, it will be the same in the other view
    if (selection.isWhole) {
      oppositeElementSelection.isWhole = selection.isWhole;
      if (selection.isImage) oppositeElementSelection.isImage = selection.isImage;
    }
    else {
      // Find where the comment would span from in the other view given that strnigs can differ. If it's not a valid span, stop here
      const {newStart, newEnd} = this.getEquivIndex(currentViewString, oppositeViewString, selection.start, selection.end)
      if (this.isStartEndInvalid(newStart, newEnd)) {
        return highlightSelectionByMode;
      }
      oppositeElementSelection.start = newStart;
      oppositeElementSelection.end = newEnd;
    }

    // If there is a valid equivalent span, save the mapping for the opposite view
    if (oppositeViewMode == ModeType.SUGGESTION) {
      highlightSelectionByMode[ModeType.SUGGESTION] = oppositeElementSelection
    } else {
      highlightSelectionByMode[ModeType.REAL] = oppositeElementSelection
    }
    return highlightSelectionByMode;

  }


  /**
 * Converts the raw string from the config to innerHTML (using the same process as the markdown-inline component)
 * @param input - The raw string that is saved in the config
 * @returns The string of the innerHTML that is rendered
 *  * @example
 * ```typescript
 * rawToHtmlString("**bold** and *italic* &mdash;")
 * // "<strong>bold</bold> and <em>italic</em> &mdash;"
 * ```
 */
  rawToHtmlString(input:string){
    let output;
    const lang = this.lang.c();
    if (this.profile.getStyleProfile()) {
      output = processText(input, this.profile.getStyleProfile()[lang].renderStyling.plainText.transforms);
    } else {
      output = input
    }
    return output;
  }

   /**
 * Converts the innerHTML to the text that appears when rendered
 * @param input - The innerHTML string
 * @returns A string that appears when rendered (the text that can be selected)
 * @example
 * ```typescript
 * htmlToRenderedString("**bold** and *italic* &mdash;")
 * // "bold and italic —"
 * ```
 */
  htmlToRenderedString(input:string){
    const leadingWhitespace = input.match(/^[\n\s]*/)[0];
    // get raw string
    const parser = new DOMParser();
    const doc = parser.parseFromString(input, 'text/html');
    const body = doc.body;
    return (leadingWhitespace + body.textContent) || '';
  }

    /**
   * Converts the raw string from the config to the text that appears when rendered
   * @param input -  The raw string that is saved in the config
   * @returns A string that appears when rendered (the text that can be selected)
   *  *  * @example
   * ```typescript
   * htmlToRenderedString("<strong>bold</bold> and <em>italic</em> &mdash;")
   * // "bold and italic —"
   * ```
   */
  rawToRenderedString(input:string){
    const htmlString = this.rawToHtmlString(input)
    const renderedString = this.htmlToRenderedString(htmlString)
    return renderedString
  }


   /**
    * Given an index within one version of the string, find the corresponding index in a different version of the string.
    * @param input - Object with optinal keys.
    * Three use cases, depending on which inputs are provided:
    * - Given the position in the rendered string (`targetRenderedIndex`) and `rawString`, returns an object with key `rawIndex` for the corresponding index in the raw string
    * - Given the position in the rendered string (`targetRenderedIndex`) and `htmlString`, returns an object with key `htmlIndex` for the corresponding index in the HTML string
    * - Given the position in the raw string (`targetRawIndex`) and `rawString`, returns an object with key `renderedIndex` for the corresponding index in the rendered string
    * @return - Object with key `renderedIndex`, `rawIndex`, or `htmlIndex` depending on which one was requested
  */
  indexConversion(input: {targetRawIndex?: number, targetRenderedIndex?:number, rawString?:string, htmlString?:string}){
    let {targetRawIndex, targetRenderedIndex, rawString, htmlString} = input;
    const renderedString = rawString ? this.rawToRenderedString(rawString) : this.htmlToRenderedString(htmlString)
    const complexString = rawString ? rawString: htmlString
    const complexStringSplit = this.splitByNamedCharsHtmlAndLetters(complexString)

    let currRenderedStringIndex =  0, currComplexStringIndex = 0;
    for (let currElem of complexStringSplit){
      if ((targetRenderedIndex !== undefined && currRenderedStringIndex === targetRenderedIndex) || (targetRawIndex !== undefined && currComplexStringIndex === targetRawIndex)) break;
      const currRenderedChar = renderedString[currRenderedStringIndex];
      if (currRenderedChar === currElem) { //A symbol in the complex string that is also in the rendered string
        currRenderedStringIndex ++
        currComplexStringIndex ++
      } else if (this.isNameCharRef(currElem)){ //A named char corresponds to one displayed symbol, e.g. "&AElig;" is "Æ"
        currRenderedStringIndex ++
        currComplexStringIndex += currElem.length
      } else if (this.isHtmlTag(currElem)) { //An html tag is not part of the rendered
        currComplexStringIndex += currElem.length
      } else { //Other case must be a single symbol like '*' or '^' which is not part of the rendered string
        currComplexStringIndex ++
      }
    }
    return {
      // Only one of the below a value depending on what the call was asking for based on the inputs
      renderedIndex: (targetRawIndex !== undefined) ? currRenderedStringIndex : undefined,
      rawIndex: (targetRenderedIndex !== undefined && rawString !== undefined) ? currComplexStringIndex : undefined,
      htmlIndex: (targetRenderedIndex !== undefined && htmlString !== undefined) ? currComplexStringIndex : undefined,
    }
  }

/**
 * @param inputString - A string to be split
 * @returns A list from inputString split into html tags, named character references, and individual letters
 * @example
 * ```typescript
 * splitStringWithSpecialStrings("<b>aa&mdash;bb</b>"); 
 * // ["<b>", "a", "a", "&mdash;", "b", "b", "</b>"]
 * ```
 */
  splitByNamedCharsHtmlAndLetters(inputString: string) {
    const namedCharPattern = new RegExp(`(${[...namedCharRefs].map(s => s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')).join('|')})`, 'g');
    const htmlTagPattern = /(<[^>]+>|[^<]+)/g;
    let initialSplit = []
    inputString.split(namedCharPattern).filter(Boolean).forEach(elem => {
      initialSplit = initialSplit.concat(elem.split(htmlTagPattern).filter(Boolean))
    })
    let fullySplitList = [];
    initialSplit.forEach(elem => {
      if (this.isNameCharRef(elem) || this.isHtmlTag(elem)) fullySplitList.push(elem)
      else fullySplitList = [...fullySplitList, ...elem.split('')]
    })
    return fullySplitList
  }


  splitByHtmlTags(inputString: string) {
    const htmlTagPattern = new RegExp('(<[^>]+>|[^<]+)', 'g');
    return inputString.split(htmlTagPattern);
  }

  isNameCharRef(str: string){
    return namedCharRefs.has(str)
  }

  isOpeningTag(str) {
    return /^<[^/]+?[^>]*?>$/.test(str);
  }
  
  isClosingTag(str) {
    return /^<\/[^>]+?>$/.test(str);
  }
  
  isHtmlTag(str) {
    return /^<[^>]+?>$/.test(str);
  }

  /**
   * Based on the highlight location, splits the HTML of the string and adds additional tags such that the three strings together render the same way as the original.
   * Assumes that if the user added their own HTML tags within the raw string, they also closed them in first-in-last-out order
   * @param rawString - The raw/markdown string
   * @param startRaw - The character index where the highlight starts in the raw string (inclusive)
   * @param endRaw - The character index where the highlight ends in the raw string (exclusive)
   * @returns An object with `preHighlight`, `highlight`, `postHighlight` as HTML strings where tags have been closed and reopened appropriately
   * @example
   * ```typescript
   * splitHtmlOnHighlight("**Bold** and *italic*", 4, 17)
   * // The input means that the string is split like so: "**Bo------ld** and *ita-----lic*"
   * // The HTML of the entire string is "<strong>Bold</strong> and <em>italic</em>"
   * // Returns:
   {
      preHighlight:  "<strong>Bo</strong>"
      highlight: "<strong>ld</strong> and <em>ita</em>",
      postHighlight: "<em>lic</em>",
   * }
   * ```
   */
  splitHtmlOnHighlight(rawString: string, startRaw: number, endRaw: number){

    const htmlString = this.rawToHtmlString(rawString)

    const startRendered = this.indexConversion({targetRawIndex: startRaw, rawString}).renderedIndex;
    const endRendered = this.indexConversion({targetRawIndex: endRaw, rawString}).renderedIndex;

    const startHtml = this.indexConversion({targetRenderedIndex: startRendered, htmlString}).htmlIndex;
    const endHtml = this.indexConversion({targetRenderedIndex: endRendered, htmlString}).htmlIndex;

    let preHighlightHtmlList = this.splitByHtmlTags(htmlString.substring(0, startHtml))
    let highlightHtmlList = this.splitByHtmlTags(htmlString.substring(startHtml, endHtml))
    let postHighlightHtmlList = this.splitByHtmlTags(htmlString.substring(endHtml))

    // Which opened tags continue from pre-highlight into highlight
    let unclosedTags = []
    preHighlightHtmlList.forEach(elem => {
      if (this.isOpeningTag(elem)) unclosedTags.push(elem)
      else if (this.isClosingTag(elem)) unclosedTags.pop()
    })

    // Add corresponding closing to prehilight string
    for (let i = unclosedTags.length - 1; i >= 0; i--) {
      const openingTag = unclosedTags[i];
      preHighlightHtmlList.push(this.generateClosingTag(openingTag))
    }

    // Prepend tags which carry over to highlight string
    highlightHtmlList = [...unclosedTags, ...highlightHtmlList]

    unclosedTags = [];

    // Which opened tags continue into post-highlight
    highlightHtmlList.forEach(elem => {
      if (this.isOpeningTag(elem)) unclosedTags.push(elem)
      else if (this.isClosingTag(elem)) unclosedTags.pop()
    })

    // Add corresponding closing to highlight string
    for (let i = unclosedTags.length - 1; i >= 0; i--) {
      const tag = unclosedTags[i];
      highlightHtmlList.push(this.generateClosingTag(tag))
    }

    // Prepend tags which carry over to post-highlight string
    postHighlightHtmlList = [...unclosedTags, ...postHighlightHtmlList]

    return { 
      preHighlight: preHighlightHtmlList.join(''),
      highlight: highlightHtmlList.join(''),
      postHighlight: postHighlightHtmlList.join('')
    }
  }

  generateClosingTag(openingTag:string) {
    const tagName = openingTag.match(/<(\w+)[\s>]/)[1];
    return `</${tagName}>`;
  }




  /**
   * After a text property in the config has changed, shift the locations of highlights as needed
   * @param entryId of the element where a text propety was changed
   * @param prop name of text property that was changed
   * @param ogString  the previous string value of the propety
   * @param newString the new string value of the propety
   */
  processConfigTextChange(entryId:number, prop:string, ogString:string, newString:string, forceViewMode?:ModeType){
    const currentViewMode = forceViewMode || this.identifyViewMode();
    const mapKey = entryId + "-" + prop
    // Get any existing comments on this string
    const commentInfoList = this.highlightInfoMap.get(currentViewMode).get(mapKey)
    // Adjust the start and end position of each if needed
    commentInfoList?.forEach(commentInfo => {
      if (commentInfo.isWhole) return;
      const {newStart, newEnd} = this.getEquivIndex(ogString, newString, commentInfo.start, commentInfo.end)
      commentInfo.start = newStart;
      commentInfo.end = newEnd;
    })
  }

  /** When part of the config that was commented on is deleted, invalidate those mappings */
  invalidateElementHighlights(entryId:number, prop:string){
    // If it's re-rendering due to moving between Real/Suggested view, don't need to invalidate
    if (this.isModeTransitionInProgress) return;
    const currentViewMode = this.identifyViewMode();
    const mapKey = entryId + "-" + prop
    const commentInfoList = this.highlightInfoMap.get(currentViewMode).get(mapKey)
    commentInfoList?.forEach(commentInfo => {
      commentInfo.isInvalid = true;
    })
  }


  //* When a string changes into another string, find the new interval based on the interval for the old string*/
  getEquivIndex(ogString:string, newString:string, ogStart: number, ogEnd: number) {
    let newStart = ogStart, newEnd = ogEnd;

    const allDiffs = Diff.diffChars(ogString, newString);
    this.labelDiffIndices(allDiffs)

    allDiffs.forEach(diff => {
      if (diff.added) {
         // @ts-ignore
        const adjusted = this.adjustForAdded(newStart, newEnd, diff.index, diff.count)
        newStart = adjusted.newStart
        newEnd = adjusted.newEnd
      }
      else if (diff.removed) {
        // @ts-ignore
        const adjusted = this.adjustForRemoved(newStart, newEnd, diff.index, diff.count)
        newStart = adjusted.newStart
        newEnd = adjusted.newEnd
      }
    });

    return {newStart, newEnd};
  }


  //** Given original start/end interval and place and size of addition, determine the new interval of a comment */
  adjustForAdded(ogStart:number, ogEnd:number, addedIndex:number, addedLength:number){
    let newStart = ogStart, newEnd = ogEnd;
    // Addition before the comment - shift the comment right
    if (addedIndex <= ogStart) {
      newStart += addedLength
      newEnd += addedLength
    }
    // Addition within the comment stretches the comment
    else if (addedIndex > ogStart && addedIndex < ogEnd) {
      newEnd += addedLength
    }
    // (And if addition is after the comment, nothing changes)
    return {newStart, newEnd}
  }

    //** Given original start/end interval and place and size of deletion, determine the new interval of a comment */
  adjustForRemoved(ogStart:number, ogEnd:number, removedIndex:number, removedLength:number){
    let newStart = ogStart, newEnd = ogEnd;
    const removedEndIndexInPrevString = removedIndex + removedLength
    // Deletion before the comment without deleting comment text - shift the comment left
    if (removedEndIndexInPrevString < ogStart){
      newStart -= removedLength
      newEnd -= removedLength
    }
    // The entire comment is within a deletion, flag it with -1
    else if (removedIndex <= ogStart && removedEndIndexInPrevString > ogEnd) {
      newStart = -1
      newEnd = -1;
    }
    // Deletetion spans text before the comment and part of the comment text
    else if (removedIndex < ogStart && removedEndIndexInPrevString > ogStart && removedEndIndexInPrevString < ogEnd){
      newStart -= removedLength
      newEnd = removedIndex
    }
    // Deletion is entirely within the comment
    else if (removedIndex >= ogStart && removedEndIndexInPrevString <= ogEnd ){
      newEnd -= removedLength
    }
    // (And if deletion is after the comment, nothing changes)
    return {newStart, newEnd}
  }

  // Label diffs with indices signifying the position they start in the strings
  labelDiffIndices(diffs) {
    let indexPtr = 0;
    diffs.forEach((diff) => {
      diff.index = indexPtr
      if (!diff.removed) indexPtr += diff.value.length;
    });
  }

  /** When a new suggestion is created, copy the current comment map for the real into the suggested. */ 
  initSuggHighlightMap(){
    const realMapCopy = _.cloneDeep(this.highlightInfoMap.get(ModeType.REAL))
    this.highlightInfoMap.set(ModeType.SUGGESTION, realMapCopy)
  }

  /** Prompts to shift the comment mapping as the string changes from diff accept/rejects.
   * But only if currently in the opposite view to where the change is seen immediately, because otherwise `processConfigTextChange` is already called from `render-highlightable`.
  */
  handleDiffAction(input: {action:DiffActionType, entryId:number, prop:string, ogString:string, newString:string}){
    const {action, entryId, prop, ogString, newString} = input;
    const currentViewMode = this.identifyViewMode()
    if (action == DiffActionType.ACCEPT && currentViewMode == ModeType.SUGGESTION) {
      this.processConfigTextChange(entryId, prop, ogString, newString, ModeType.REAL)
    } else if (action == DiffActionType.REJECT && currentViewMode == ModeType.REAL) {
      this.processConfigTextChange(entryId, prop, ogString, newString, ModeType.SUGGESTION)
    }
  }

  /** When a user clicked in the testrunner on something that could have a whole-string comment, proceed with comment modal only if the button for highlight comments is on */
  public checkInitWholeHighlight(entryId:number, prop:string, rawString:string|SafeResourceUrl, isImage?:number){
    const newSelections = [{entryId, prop, selection: {isWhole: 1, isImage, highlightHtml: isImage ? rawString : this.rawToHtmlString(''+rawString)}}]

    if (this.isHighlightCommentButtonActive) {
      this.newValidSelectedTextSource.next(newSelections)
    } else if (this.changeIntervalAttemptNoteId && !this.isHighlightCommentButtonActive) {
      this.loginGuard.confirmationReqActivate({
        caption: 'Are you sure you want to change the interval of this comment?',
        confirm: () => {
          this.changeHighlightInterval(this.changeIntervalAttemptNoteId, newSelections)
          this.changeIntervalAttemptNoteId = undefined;
        },
        close: () => this.changeIntervalAttemptNoteId = undefined
      });
    }
  }

  /**
   * Given an existing note, modify where it maps to on the config
   * Applies only to the current view (real or suggestion)
   * @param noteId ID of the note
   * @param validSelection User's newest selection for where to map the note
   */
  changeHighlightInterval(noteId:number, validSelection:IConfigElemHighlight[]){
    const currentViewMode = this.identifyViewMode();
    const lang = this.lang.c();
    // Save the new config map for the note
    const newConfig = validSelection.map(s => {
      return {entryId: s.entryId, prop: s.prop, start: s.selection.start, end:s.selection.end, isWhole:s.selection.isWhole, isImage: s.selection.isImage}
    })
    return this.auth.apiPatch(this.routes.TEST_AUTH_HIGHLIGHT_NOTES, 1, {config_map: newConfig}, { query: {
      lang,
      test_question_id: this.itemComponentEdit.originalQuestionState.id,
      test_question_auth_note_id: noteId,
      mode: currentViewMode, // Pass the mode so API will apply it to either real or suggestion version
    }
    }).then(() => {
      // Invalidate any existing mappings for this comment 
      for (let mapKey of this.highlightInfoMap.get(currentViewMode).keys()){
        for (const commentInfo of this.highlightInfoMap.get(currentViewMode).get(mapKey)){
          if (commentInfo.noteId === noteId){
            commentInfo.isInvalid = true;
          }
        }
      }
      // Create new mappings based on updated selection
      validSelection.forEach(s => {
        this.addHighlightCommentToMap({modeType: currentViewMode, noteId, entryId: s.entryId, prop: s.prop, start: s.selection.start, end:s.selection.end, isWhole:s.selection.isWhole, isImage: s.selection.isImage})
      })
    })
  }

}
