<div [class.katex-space-adjustment]="this.styleProfile.getMathAdjustedKatex()"
    class="math-container"
    [class.is-inline-alignment]="isInline"
    [class.is-heading]="isHeading()"
    [class.is-heading-small]="isHeadingSmall()"
    [class.is-body]="isBody()"
    [class.is-small]="isSmall()"
    [class.is-adjusted-heading]="isHeading() && whitelabel.isABED()"
    [class.is-adjusted-heading-small]="isHeadingSmall() && whitelabel.isABED()"
    [class.is-adjusted-body]="isBody() && whitelabel.isABED()"
    [class.is-adjusted-small]="isSmall() && whitelabel.isABED()"
>
    <render-math [obj]="element" [prop]="getProp()"></render-math>
</div>
