import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PageModalService, IOverlayCancel } from '../page-modal.service';

@Component({
  selector: 'num-scrub',
  templateUrl: './num-scrub.component.html',
  styleUrls: ['./num-scrub.component.scss']
})
export class NumScrubComponent implements OnInit {

  @Input() el:any;
  @Input() prop:string;
  @Input() width:number = 4;
  @Input() step:number = 0.01;
  @Input() min:number = 0.01;
  @Input() max:number = 5;

  @Output() change = new EventEmitter<null>();

  constructor(
    private pageModalService: PageModalService,
  ) { }

  isSliderActive:boolean;
  overlayCancel:IOverlayCancel;

  ngOnInit(): void {
    this.overlayCancel = {
      cancel: () => this.toggleOff(true)
    } 
  }

  toggleOn(){
    this.isSliderActive = true;
    this.pageModalService.updateActiveOverlay(this.overlayCancel)
  }
  toggleOff(isCancel?:boolean){
    this.isSliderActive = false;
    if (!isCancel){
      this.pageModalService.updateActiveOverlay(this.overlayCancel, true)
    }
  }
  toggleSlider(){
    if (this.isSliderActive){
      this.toggleOff();
    }
    else{
      this.toggleOn();
    }
  }

}
