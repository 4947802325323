<div class="virtual-tools-container">
    <div class="virtual-tools-container-child">
        <div #pixiDiv class="pixi-canvas"></div>
        <div class="virtual-tools-toolbar ">
            <div *ngFor="let category of toolbarCategoryFiltered"
                class="img-container"
                [class.is-selected]="isCategorySelected(category.id)"
                [class.is-disabled]="isQuestionLocked()"
            >
                <img [src]="category.iconUrl" (click)="onToolBarCategoryClick(category.id)" [ngStyle]="disableHC()"/>
                <div *ngIf="category.id == 'PALETTE'" class="selected-color-indicator" [style.background-color]="selectedColor.string">
                    
                </div>
                <!-- Multi tools menu  --> 
                <div *ngIf="showCategoryMultiToolsMenu(category)" class="virtual-tools-extended-toolbar"> 
                    <div *ngFor="let tool of getCategoryTools(currentSelectedToolCategoryId)" 
                        class="img-container"
                        [class.is-selected]="tool.isSelected()"
                        [class.is-disabled]="tool.isDisabled()"
                    >
                        <img [src]="tool.iconUrl" (click)="tool.toggle(tool.id)" [ngStyle]="disableHC()"/>
                        <!-- Extended tool bar menus -->
                        <!-- <div class="virtual-tools-extended-toolbar" *ngIf="!isObjectEmpty(extendedTools) && extendedTools.parent == tool.id && extendedTools.tools.length>=1">
                            <div *ngFor="let extTool of extendedTools.tools"
                                (click)="extTool.action()"
                                class="img-container"
                                [class.is-selected]="extTool.isSelected()"
                            >
                                <img *ngIf="!extTool.isColorPick" 
                                    [src]="extTool.iconUrl"
                                    [ngStyle]="disableHC()"
                                />
                                <div 
                                    style="position: relative;" 
                                    *ngIf="extTool.isColorPick"
                                    [class.is-selected]="showColorPalette"
                                >
                                    <img
                                        [src]="'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/color-palette-3/1685478658551/color-palette-3.png'"
                                        style="width: 1.4em; height: auto;"
                                        [ngStyle]="disableHC()"
                                        (click)="toggleColorPalette()"
                                    />
                                </div>
                            </div>

                            <div class="dropdown" *ngIf="showColorPalette">
                                <div *ngFor="let color of extendedTools.colors" 
                                    class="color-square" 
                                    [style.backgroundColor]="color"
                                    (click)="extendedTools.colorHandler(color)"
                                ></div>
                            </div>
                        </div>  -->
                    </div>
                </div>

                <!-- Single Tool extended menu -->
                <div *ngIf="isColorPaletteAndShowing(category) || (showCategorySingleToolsMenu(category) && category.hasSubMenu)" class="virtual-tools-extended-toolbar palette-extended-toolbar"> 
                    

                    <div *ngIf="showColorPalette" class="palette">
                        <!-- '#ffce18','#c43f2e','#2a91d6', -->
                        <!-- <div *ngFor="let color of  ['#333333', '#ebebeb', '#ff0000', '#ff8000', '#ffff00', '#00ff00', '#00ffff', '#0000ff', '#8000ff']"  -->
                        <div *ngFor="let color of  ['#333333', '#ebebeb', '#E81416', '#FFA500', '#FAEB36', '#79C314', '#487DE7', '#4B369D', '#70369D', '#CCCCCC']" 
                            class="color-square" 
                            [style.backgroundColor]="color"
                            (click)="onColorSelection(color)"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>