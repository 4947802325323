import { IContentElement } from "../models";

export interface IContentElementMath extends IContentElement {
    latex: string;
    paragraphStyle: string;
    elementMode?: IEquationBlockMode;
    rawLatex?: string
  }

  export const mathEditInfo = {
    editExcludeFields: [],
    editTextFields: ['latex'],
    editKeyFieldsToShow: ['latex', 'paragraphStyle']
  }

export enum IEquationBlockMode{
  MATH = 'Math',
  CHEMISTRY = 'Chemistry'
}