<div class="bc-accounts-admins-school">
    <div *ngIf="isSchoolAdmin() && !isScoreEntrySchoolAdmin()" class="invitation-section">
        <button class="new-invite-button" (click)="newInviteClicked()">
            <tra slug="sa_aa_new_invite"></tra>
        </button>
        <button class="revoke-access-button" (click)="revokeAccessClicked()" [disabled]="shouldDisableRevokeButton()">
            <tra slug="sa_aa_revoke_access"></tra>
        </button>
    </div>

    <ng-container *ngIf="districtDetail">
        <ng-container *ngIf="!isSelectedAllSchools()">
            <input class="search-name" type="text" [placeholder]="nameSearchPlaceHolder" (input)="searchByName($event)"
                [value]="nameSearch" />

            <bc-paginator *ngIf="pagination" [paginator]="pagination" (refresh)="onPaginationChange()"
                [showCount]="true">
            </bc-paginator>

            <div class="table-section">
                <table class="table is-width-auto">
                    <tr>
                        <th *ngIf="!isScoreEntrySchoolAdmin()">
                            <input type="checkbox" [(ngModel)]="selectAll" (change)="onSelectAllChange()">
                        </th>
                        <th>
                            <tra slug="sa_aa_accounts"></tra>
                        </th>
                        <th>
                            <tra slug="sa_aa_roles"></tra>
                        </th>
                    </tr>
                    <tr *ngFor="let row of filteredRows">
                        <td *ngIf="!isScoreEntrySchoolAdmin()">
                            <input type="checkbox" [(ngModel)]="row.selected" (change)="onSelectChange(row)"
                                [disabled]="row.revoked">
                        </td>
                        <td>
                            <div class="account-cell">
                                <div class="name-and-email">
                                    <div class="name-field">{{getNameDisplay(row)}}</div>
                                    <div class="email-field">{{row.contact_email}}</div>
                                </div>
                                <div class="status-field" [class.pending]="row.is_pending || row.revoked">
                                    {{row.status}}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="roles-cell">
                                <select [(ngModel)]="row.editedRole" (change)="onSelectedRoleChange(row)"
                                    [disabled]="isLoading || row.revoked || !isSchoolAdmin() || isScoreEntrySchoolAdmin()">
                                    <option *ngFor="let role of roles" [ngValue]="role">
                                        <tra [slug]="role"></tra>
                                    </option>
                                </select>
                                <div *ngIf="isSchoolAdmin() && !isScoreEntrySchoolAdmin()">
                                    <button class="save-button" [disabled]="shouldDisableSaveButtonForTable(row)"
                                        (click)="saveRole(row)">
                                        <tra slug="sa_aa_save"></tra>
                                    </button>
                                    <button class="cancel-button" [disabled]="shouldDisableSaveButtonForTable(row)"
                                        (click)="cancelRole(row)">
                                        <tra slug="sa_aa_cancel"></tra>
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </ng-container>

        <ng-container *ngIf="isSelectedAllSchools()">
            <bc-paginated-table [columnHeadings]="columnHeadings" [pagination]="allSchoolsPagination"
                [getRows]="getRows" [getDisplay]="getDisplay" [defaultFilterCondition]="FilterCondition.LIKE"
                [columnWidths]="tableColumnWidths" [zebra]="true" [zebraField]="zebraField"
                [extraToggle]="showAdminInfoToggle" (toggle)="onToggleShowAdminInfo($event)">
            </bc-paginated-table>
        </ng-container>
    </ng-container>
</div>

<div class="custom-modal" *ngIf="shouldOpenNewAdminModal">
    <div class="modal-contents" style="width:700px">
        <div class="modal-header">
            <tra slug="sa_aa_new_invite_for_sa"></tra>
        </div>
        <div class="two-columns">
            <div class="a-column">
                <div class="modal-question">
                    <tra slug="sa_aa_fname"></tra>
                </div>
                <input class="input" type="text" [(ngModel)]="modalCreateSchoolAdminData.first_name">
                <div class="error-message" *ngIf="createSchoolAdminErrors.first_name">
                    {{createSchoolAdminErrors.first_name}}
                </div>
                <div class="modal-question">
                    <tra slug="sa_aa_email"></tra>
                </div>
                <input class="input" type="text" [(ngModel)]="modalCreateSchoolAdminData.email">
                <div class="error-message" *ngIf="createSchoolAdminErrors.email">{{createSchoolAdminErrors.email}}</div>
                <div class="modal-question">
                    <tra slug="sa_aa_school"></tra>
                </div>
                <div class="school-selector">
                    <select [(ngModel)]="modalCreateSchoolAdminData.school" [disabled]="isLoading">
                        <option *ngFor="let school of schools" [ngValue]="school">
                            {{getSchoolDisplay(school)}}
                        </option>
                    </select>
                </div>
                <div class="error-message" *ngIf="createSchoolAdminErrors.school">{{createSchoolAdminErrors.school}}
                </div>
            </div>
            <div class="a-column">
                <div class="modal-question">
                    <tra slug="sa_aa_lname"></tra>
                </div>
                <input class="input" type="text" [(ngModel)]="modalCreateSchoolAdminData.last_name">
                <div class="error-message" *ngIf="createSchoolAdminErrors.last_name">
                    {{createSchoolAdminErrors.last_name}}</div>
                <div class="modal-question">
                    <tra slug="sa_aa_roles"></tra>
                </div>
                <div class="role-selector">
                    <select [(ngModel)]="modalCreateSchoolAdminData.role">
                        <option *ngFor="let role of roles" [ngValue]="role">
                            <span *ngIf="!role">
                                <tra slug="sa_aa_select_role"></tra>
                            </span>
                            <span *ngIf="role"><tra [slug]="role"></tra></span>
                        </option>
                    </select>
                </div>
                <div class="error-message" *ngIf="createSchoolAdminErrors.role">{{createSchoolAdminErrors.role}}</div>
                <div class="modal-question">
                    <tra slug="sa_aa_district"></tra>
                </div>
                <input class="input" type="text" disabled [value]="getDistrictDisplay(districtDetail)">
            </div>
        </div>
        <div class="actions">
            <button class="cancel-button" (click)="closeNewInviteModal()">
                <tra slug="sa_aa_cancel"></tra>
            </button>
            <button class="save-button" (click)="saveNewInviteModal()" [disabled]="shouldDisableNewInviteSave()">
                <tra slug="sa_aa_save"></tra>
            </button>
        </div>
    </div>
</div>