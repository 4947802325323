import { IContentElementTable } from "src/app/ui-testrunner/element-render-table/model";

export function getHeaderRowsIndex(element: IContentElementTable) : number[]{
    const numberPattern = /[0-9]+/g;
    const inputNumberStringArr = element.headerRows.match(numberPattern);

    if (!inputNumberStringArr || inputNumberStringArr == null) return [];

    let headerRowIndexArray = inputNumberStringArr.map(entry => Number.parseInt(entry) - 1);
    if (element.isHeaderRow && !headerRowIndexArray.includes(0)) headerRowIndexArray.push(0);
    headerRowIndexArray = headerRowIndexArray.sort(function (a, b) {return a - b;});

    return headerRowIndexArray;
}

export function getCellRowSpan(row_i: number, col_i: number){
    const cell = this.getCell(row_i, col_i);
    if (cell.mergedCellId){
        return cell.mergedCellRowSpan + 1;
    }
    return 1;
}

export function getCellColSpan(row_i: number, col_i: number){
    const cell = this.getCell(row_i, col_i);
    if (cell.mergedCellId){
        return cell.mergedCellColSpan + 1;
    }
    return 1;
}

  

export function getHeaderColsIndex(element: IContentElementTable) : number[]{
    const numberPattern = /[0-9]+/g;
    const inputNumberStringArr = element.headerCols.match(numberPattern);

    if (!inputNumberStringArr || inputNumberStringArr == null) return [];

    let headerColIndexArray = inputNumberStringArr.map(entry => Number.parseInt(entry) - 1);
    if (element.isHeaderRow && !headerColIndexArray.includes(0)) headerColIndexArray.push(0);
    headerColIndexArray = headerColIndexArray.sort(function (a, b) {return a - b;});

    return headerColIndexArray
}

export function isCellHidden(row_i: number, col_i: number){
    const cell = this.getCell(row_i, col_i);
    if (!cell.mergedCellId) return false;
    return !(cell.mergedCellTopLeft[0] == row_i && cell.mergedCellTopLeft[1] == col_i);
}
  

export function isRowHeader(row_i: number){
    if (row_i === undefined || !this.element.headerRows || this.isRowHeader_no_bottom_border(row_i) || !this.element.someRowsAreHeader) return false;
    const headerRowIndexArray = getHeaderRowsIndex(this.element);

    if (headerRowIndexArray.includes(row_i)) return true;
    return false;
}
  

export function isRowHeader_no_bottom_border(row_i: number){
    if (row_i === undefined || !this.element.headerRows || !this.element.someRowsAreHeader) return false;
    const headerRowIndexArray = getHeaderRowsIndex(this.element);

    let hasTwoOrMoreHeaderChunks = false;
    headerRowIndexArray.forEach((index, i) => {
        if (i != headerRowIndexArray.length - 1 && !headerRowIndexArray.includes(index + 1)){
        hasTwoOrMoreHeaderChunks = true;
        }
    })

    const index = headerRowIndexArray.indexOf(row_i);
    if (index != -1 && (headerRowIndexArray.includes(row_i + 1) || hasTwoOrMoreHeaderChunks)) return true;
    return false;
}
  

export function isColHeader(col_i: number){
    if (col_i === undefined || !this.element.headerCols || this.isColHeader_no_right_border(col_i) || !this.element.someColsAreHeader) return false;
    const headerColIndexArray = getHeaderColsIndex(this.element);

    if (headerColIndexArray.includes(col_i)) return true;
    return false;
}
  

export function isColHeader_no_right_border(col_i: number){
    if (col_i === undefined || !this.element.headerCols || !this.element.someColsAreHeader) return false;
    const headerColIndexArray = getHeaderColsIndex(this.element);

    let hasTwoOrMoreHeaderChunks = false;
    headerColIndexArray.forEach((index, i) => {
        if (i != headerColIndexArray.length - 1 && !headerColIndexArray.includes(index + 1)){
        hasTwoOrMoreHeaderChunks = true;
        }
    })

    const index = headerColIndexArray.indexOf(col_i);
    if (index != -1 && (headerColIndexArray.includes(col_i + 1) || hasTwoOrMoreHeaderChunks)) return true;
    return false;
}