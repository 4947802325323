import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WhitelabelService } from '../../domain/whitelabel.service';

@Component({
  selector: 'view-index',
  templateUrl: './view-index.component.html',
  styleUrls: ['./view-index.component.scss']
})
export class ViewIndexComponent implements OnInit {

  constructor(
    private router: Router,
    private whiteLabel: WhitelabelService,
  ) { }

  ngOnInit(): void {
    this.router.navigate([
      this.whiteLabel.getHomepageRoute()
    ]);
  }

}
