import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataGuardService } from '../../core/data-guard.service';
import { AuthService } from '../../api/auth.service';
import { LoginGuardService } from '../../api/login-guard.service';
import { RoutesService } from '../../api/routes.service';
import { BreadcrumbsService } from '../../core/breadcrumbs.service';
import { LangService } from '../../core/lang.service';
import { StyleprofileService } from '../../core/styleprofile.service';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { ITestDesignPayload, ISectionDef } from '../../ui-testtaker/view-tt-test-runner/view-tt-test-runner.component';
import { IQuestionConfig, ElementType, IAssetImpressionConfig } from '../../ui-testrunner/models';
// IContentElementMcq
import { ITestDef } from '../../ui-testrunner/sample-questions/data/sections';
import { EResultsPageTypes, IAssessmentFrameworkDetail, TestFormConstructionMethod } from '../item-set-editor/models/assessment-framework';
import { IContentElementMcq } from 'src/app/ui-testrunner/element-render-mcq/model';


const LOCAL_STORAGE_KEY = 'eqao-sample';
const OBF_CONST = 'h928f92d9gd2';
const obf = (o) => {
  return OBF_CONST + btoa(JSON.stringify(o));
};
const deobf = (str) => {
  str = str.substr(OBF_CONST.length, str.length - OBF_CONST.length)
  return atob ( str )
};

export enum QuestionScore {
  CORRECT = 'CORRECT',
  INCORRECT = 'INCORRECT',
  UNMARKED = 'UNMARKED'
}
@Component({
  selector: 'view-g9-sample',
  templateUrl: './view-g9-sample.component.html',
  styleUrls: ['./view-g9-sample.component.scss']
})
export class ViewG9SampleComponent implements OnInit, OnDestroy{

  constructor(
    public lang:LangService,
    private loginGuard: LoginGuardService, //
    private breadcrumbsService: BreadcrumbsService,
    private routes: RoutesService,
    private auth: AuthService,
    private whitelabel: WhitelabelService,
    private router:Router,
    private route: ActivatedRoute,
    private dataGuard: DataGuardService,
    private styleProfile: StyleprofileService
  ) { }

  public isToolExploration: boolean = this.route.snapshot.queryParams.explore === 'true';
  routeSub:Subscription;
  queryParamSub: Subscription;
  userSub: Subscription;
  itemSetId:string;
  testVersionId:string;
  asmtLabel:string;
  asmtFmrk:IAssessmentFrameworkDetail;
  isShowingResults = false;
  questions:Map<number, any>;
  testTakerName: string;
  testSessionId: number;
  testForm: {
    currentTestDesign: ITestDef,
    questionSrcDb: Map<number, any>,
    questionStates: {[key: string]: any},
    testLang: string,
  };
  questionTitleMap:any;
  isAuthenticated:boolean;
  defaultZoom:number;

  styleProfileSub;
  private isFromStudentDashboard: boolean

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe( async (params) => {
      this.testForm = null;
      this.itemSetId = params['itemSetId'];
      this.testVersionId = params['testVersionId'];
      this.asmtLabel = params['label'];
      this.testSessionId = params['testSessionId'];
      
      

      if (this.testSessionId){
        this.initLoadQuestions();
      }
      else {

        try { 
          const isPassPrt = await this.auth.apiGet(this.routes.TEST_AUTH_PUB_PWD_PROTECTED, this.itemSetId, {query: {ts_id: this.testSessionId}})
          this.isAuthenticated = !isPassPrt;
        }
        catch (e){ 
          this.isAuthenticated = true;
        }

        this.auth.apiCreate('public/log', {
          slug: 'SAMPLE_TEST_START',
          data: {
            itemSetId: this.itemSetId,
            asmtLabel:this.asmtLabel,
            lang: this.lang.c() ,
            userPen: window['PEN'],
            context: this.whitelabel.context,
          }
        })

        if (!this.isAuthenticated){
          this.loginGuard.activate();
        } 
        else {
          this.initLoadQuestions();
        }

      }


      
      this.userSub = this.auth.user().subscribe((userInfo:any) => {
        if (userInfo){
          this.isAuthenticated = true;
          this.initLoadQuestions();
          this.testTakerName = [userInfo.first_name, userInfo.last_name].join(' ')
        }
      })
    });

    this.queryParamSub = this.route.queryParams.subscribe(queryParams => {
      if (queryParams['bcedCopyrightNotice']){
        this.loginGuard.quickPopup( this.lang.tra('txt_asmt_mat_copyright') )
      }
      if (queryParams['defaultZoom']){
        this.defaultZoom = +queryParams['defaultZoom'];
      }
      if(queryParams['isFromStudentDashboard']) {
        this.isFromStudentDashboard = queryParams['isFromStudentDashboard'] === 'true';
      }
    })

    this.dataGuard.activate();
  }

  initLoadQuestions() {
    if(this.styleProfileSub) {
      return;
    }

    this.styleProfileSub = this.styleProfile.styleProfileSub.subscribe(loaded => {
      if(loaded && !this.isQuestionLoaded){
        this.isQuestionLoaded = true
        this.loadQuestions();
      }
    })
  }

  ngOnDestroy(): void {
    this.dataGuard.deactivate();
    if(this.queryParamSub) {
      this.queryParamSub.unsubscribe();
    }
    if(this.routeSub) {
      this.routeSub.unsubscribe();
    }
    if(this.userSub) {
      this.userSub.unsubscribe();
    }
    if(this.styleProfileSub) {
      this.styleProfileSub.unsubscribe();
    }
  }

  isShowingEmbeddedResults(){
    return this.isShowingResultsPage() 
      && this.asmtFmrk?.resultsPagetype === EResultsPageTypes.EMBEDDED;
  }
  isShowingExternalResults(){
    return this.isShowingResultsPage() 
      && this.asmtFmrk?.resultsPagetype === EResultsPageTypes.EXTERNAL;
  }

  isResultsPageAvailable = () => this.whitelabel.isResultPageAvailable() && this.asmtFmrk?.isResultsPageEnabled;

  isShowingResultsPage = () => {
    return this.isResultsPageAvailable() && this.isShowingResults;
  }

  isShowingTestRunner = () => {
    if (this.isShowingResults) {
      if (!this.isResultsPageAvailable()) {
        return false;
      }
      return this.isShowingEmbeddedResults();
    }

    return !!this.testForm
  }

  private objectToNumMap( obj:{[key:number]: any}){
    const map = new Map();
    Object.keys(obj).forEach(key => {
      map.set(+key, obj[key]);
    })
    return map;
  }
  private numMapToObject( map:Map<number, any>){
    const obj:any = {};
    map.forEach((value, key) => {
      obj[key] = value;
    })
    return obj;
  }


  errorMessage:string;
  isQuestionLoaded: boolean;
  loadQuestions(){
    this.loadFromCache(()=>{
      const apiGetParams = {query: {
        testVersionId: this.testVersionId,
        ts_id: this.testSessionId,
        lang: this.lang.c(),
      }};
      this.auth
      .apiGet(this.routes.ANON_SAMPLE_TEST_DESIGN_FORM, this.itemSetId, apiGetParams)
      .then ( (res:{testFormData:any, testDesignRecord: any, framework:string, styleProfileConfig:any}) => {

        // check for domain redirect and if current is different redirect them to provided.
        let domain_redirect: string = res.testDesignRecord?.domain_redirect?.trim();
        const origin = window.location.origin;
        if (domain_redirect && domain_redirect !== origin){
          window.location.href = window.location.href.replace(origin, domain_redirect);
          return;
        }


        // console.log(res.testFormData)
        this.asmtFmrk = JSON.parse(res.framework);

        // ensure packed style profile from s3 is loaded before question display
        if(res.styleProfileConfig){
          const {id, slug, config, version_id} = res.styleProfileConfig
          this.styleProfile.setStyleProfileFromS3(config, slug, id, version_id);
        }

        this.questions = this.objectToNumMap(res.testFormData.questionDb),
        this.testForm = {
          currentTestDesign: {
            ... res.testFormData,
            questionDb: null,
          },
          questionStates: {},
          testLang: res.testFormData.lang,
          questionSrcDb: this.questions,
        };
      })
      .catch(e => {
        this.errorMessage = 'This sample test is not currently available.'
      })
    })
    return;
  }

  isFrameworkTimer(){
    if (this.whitelabel.getSiteFlag('IS_TIMER_ENABLED')){
      return !this.asmtFmrk.isTimerDisabled
    }
    return false;
  }


  loadFromCache(alt){
    return alt();
    // try {
    //   let cachedState = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    //   if (cachedState && cachedState !== '') {
    //     const save = deobf(cachedState);
    //     console.log('save', save)
    //     if (save.itemSetId !== this.itemSetId) {
    //       // do not restore state if it was a different assessment
    //       this.testState = save.testState
    //       this.testForm = {
    //         ... save.testState,
    //         questionSrcDb: this.numMapToObject(save.testState.questionSrcDb)
    //       }
    //       return;
    //     }
    //     else{
    //       alt();
    //     }
    //   }
    // } catch (e) {
    //   console.error('malformed test state');
    //   alt();
    // }
  }

  updateLocalStorage(data) {
    this.auth.apiCreate('public/log', {
      slug: '__qdatares',
      data: {
        // uid: this.auth.getUid(),
        test_question_id: data.test_question_id,
        question_caption: data.question_caption,
        response: obf(data),
        context: this.whitelabel.context,

        userPen: window['PEN'],
      }
    })
    // if(this.route.snapshot.queryParams.withTeacher){
    // }
    const save = {
      itemSetId: this.itemSetId,
      testState: this.testState,
      testForm: {
        ... this.testForm,
        questionSrcDb: this.objectToNumMap(this.testForm)
      }
    }
    window.localStorage.setItem(LOCAL_STORAGE_KEY, obf(save));
  }

  clearLocalStorage() {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, '');
    this.testState = null;
  }

  questionScores = new Map();
  scoreAllQuestions() {
    const states = this.testForm.questionStates;
    this.testForm.currentTestDesign.sections.forEach(section => {
      section.questions.forEach(qId => {
        const qAns = this.answerKey[qId];
        const qRes = states[qId];
        let isAllCorrect = true;
        if (!qRes) {
          isAllCorrect = false;
        } else {
          Object.keys(qAns).forEach(entryId => {
            const eAns = qAns[entryId];
            const eRes = qRes[entryId];
            isAllCorrect = isAllCorrect && this.isEResCorrect(eRes, eAns);
          });
        }
        const isCorrect = isAllCorrect;
        this.questionScores.set(qId, isCorrect);
      });
    });
  }

  getQuestionById(id: number) {
    let question: IQuestionConfig;
    this.questions.forEach(_question => {
      if (_question.id === id) {
        question = _question;
      }
    });
    return question;
  }
  getQuestionByLabel(label: string) {
    let question: IQuestionConfig;
    this.questions.forEach(_question => {
      if (_question.label === label) {
        question = _question;
      }
    });
    return question;
  }

  // ANSWER KEY STUFF
  testState = {
    currentSectionIndex: 0,
    currentQuestionIndex: 0,
    itemSetId: null,
  };

  answerKey: { [key: string]: any } = {};

  submitTest = (skipPost?: boolean) => {
    try {
      this.auth.apiCreate('public/log', {
        slug: 'SAMPLE_TEST_SUBMIT',
        data: {
          itemSetId: this.itemSetId,
          asmtLabel:this.asmtLabel,
          lang: this.lang.c(),
          states: this.testForm.questionStates,
          userPen: window['PEN'],
          context: this.whitelabel.context,
        }
      })
    } catch(e) {}

    if(!skipPost) {
      this.showAnswers();
    }
  }

  showAnswers = (): Promise<void> => {
    if(this.isFromStudentDashboard) {
      this.router.navigate([`${this.lang.c()}/student/dashboard/main`]);
    }

    if (this.isToolExploration || this.isSecureProxy()) {
      this.router.navigate([`${this.lang.c()}/student/dashboard/resources`]);
    }
    else {
      try {
        if(!this.whitelabel.getSiteFlag('IS_BCED')) {
          this.scoreAllQuestions();
          this.feedAnswersToAllQuestions();
        }
      } catch(e) {}
      this.selectSectionAndQuestion(0, 0);
      // alert('The answer key page is currently not available.')
    }
    this.isShowingResults = true;
    return Promise.resolve();
  }
  isSecureProxy(){
    return (this.asmtFmrk.testFormType === TestFormConstructionMethod.MSCAT) && !this.testForm.currentTestDesign.isPanelRoutingByNumCorrect;
  }

  selectSectionAndQuestion(sectionIndex, questionIndex){
    this.testState.currentSectionIndex = sectionIndex;
    this.testState.currentQuestionIndex = questionIndex;
  }

  feedAnswersToAllQuestions() {
    this.testForm.currentTestDesign.sections.forEach(section => {
      section.questions.forEach(qId => {
        const qAns = this.answerKey[qId];
        const q = this.getQuestionById(qId);
        // to do : this is fixed to MCQ
        Object.keys(qAns).forEach(entryId => {
          const eAns = qAns[entryId];
          // to do: assuming flat question structures
          q.content.forEach((el:IContentElementMcq) => {
            if (el.entryId === +entryId) {
              el.options.forEach( (option, optionIndex) => {
                if (eAns.optionIndex === optionIndex) {
                  option.isCorrect = true;
                }
              });
            }
          });
        });
      });
    });
  }

  saveQuestionResponse = (data) => {
    try {
      this.updateLocalStorage(data)
    }
    catch (e){
      try {
        this.updateLocalStorage({
          ... data,
          response_raw: null
        })
      }
      catch(e){}
    }
    return Promise.all([]);
  }

 logAssetView = async (assetsToSave) => {
    if (!this.itemSetId) throw new Error('no itemSetId');

    return await Promise.all(assetsToSave.map(async (asset) => {
      const data = <IAssetImpressionConfig> { ...asset, item_set_id: this.itemSetId };
      return await this.auth.apiCreate(this.routes.TEST_AUTH_ITEM_IMPRESSION, data)
    }));
  }

  logItemView = async (itemToSave) => {
    if (!this.itemSetId) throw new Error('no itemSetId');
    // const data = <IAssetImpressionConfig> { ...itemToSave, item_set_id: this.itemSetId };
    // return await this.auth.apiCreate(this.routes.TEST_AUTH_ITEM_IMPRESSION, data);
  }

  goHome = () => {
    // this.router.navigate([`${this.lang.c()}/login-router-st`]);
    alert('You have completed the assessment. You may now close this tab.');
  }

  private isEResCorrect(eRes, eAns) {
    if (eRes && eRes.selections) {
      if (eRes.selections[0] && eRes.selections[0].i === eAns.optionIndex) {
        return true;
      }
    }
    return false;
  }

}
