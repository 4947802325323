<div class="dob-container">

    <div class="dob-row">

        <mat-form-field 
            *ngFor="let input of inputs"
            class="autocomplete-form-field" 
            floatLabel="auto"
        >
            <mat-label> <tra [slug]="input.label"></tra> </mat-label>
            <input type="text" 
                matInput 
                [(ngModel)]="selection[input.eId]"
                [matAutocomplete]="autocomplete"
                (change)="validateInputs(input.eId)">
            <mat-autocomplete 
                #autocomplete="matAutocomplete"
                (optionSelected)="onOptionSelected(input.eId, $event)" 
                [displayWith]="input.render"
                class="autocomplete-dropdown" classList="autocomplete-dropdown"
            >
                <mat-option *ngFor="let el of lists[input.eId]" [value]="el"> {{input.render(el)}} </mat-option>
            </mat-autocomplete>
        </mat-form-field>

<!-- 
        <mat-form-field class="autocomplete-form-field" floatLabel="auto">
            <mat-label> <tra slug="lbl_dob_month"></tra> </mat-label>
            <input type="text" 
                matInput 
                [matAutocomplete]="autoMonth" 
                (change)="validateInputs(eInput.MONTH)">
            <mat-autocomplete 
                #autoMonth="matAutocomplete" 
                (optionSelected)="onSelectMonth($event)" 
                [displayWith]="renderMonth"
                class="autocomplete-dropdown" classList="autocomplete-dropdown"
            >
                <mat-option *ngFor="let month of months" [value]="month"> {{renderMonth(month)}} </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    
        <mat-form-field class="autocomplete-form-field" floatLabel="auto">
            <mat-label> <tra slug="lbl_dob_day"></tra> </mat-label>
            <input type="text" matInput [matAutocomplete]="autoDay" >
            <mat-autocomplete 
                #autoDay="matAutocomplete" 
                (optionSelected)="onSelectDay($event)" 
                class="autocomplete-dropdown" classList="autocomplete-dropdown"
            >
                <mat-option *ngFor="let day of days" [value]="day"> {{day}} </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    
        <mat-form-field class="autocomplete-form-field" floatLabel="auto">
            <mat-label> <tra slug="lbl_dob_year"></tra> </mat-label>
            <input type="text" matInput [matAutocomplete]="autoYear" >
            <mat-autocomplete 
                #autoYear="matAutocomplete" 
                (optionSelected)="onSelectYear($event)" 
                class="autocomplete-dropdown" classList="autocomplete-dropdown"
            >
                <mat-option *ngFor="let year of years" [value]="year"> {{year}} </mat-option>
            </mat-autocomplete>
        </mat-form-field> -->

    </div>

    <div *ngIf="validationMessage" class="dob-notification-message">
        {{validationMessage}}
    </div>
    <div *ngIf="!isDateHidden" style="text-align:center; color: #ccc; font-size: 0.6em;">
        {{formControlModel.value}}
        <!-- This is basically a debug display, but I dont see the harm in users seeing it -->
    </div>


</div>