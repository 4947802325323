<mat-form-field appearance="fill" [style.width]="inputWidth">
    <mat-label>
        {{label}}
        <span *ngIf="isOptionSelected()">
            (selected)
        </span>
    </mat-label>
    <input 
        type="text" 
        placeholder="Select an option" aria-label="Select an option"
        matInput 
        [matAutocomplete]="auto" 
        [formControl]="optionControl"
    >
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{option.caption}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>