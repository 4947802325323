import {Component, Input, OnInit} from '@angular/core';

interface IRecord  {[key: string]: number | string; }
interface IProcessSeriesConfig {
  records: IRecord[];
  filterBy: string;
  seriesBy: string;
  xAxis: (record: IRecord) => string | number;
  yAxis: (record: IRecord) => number;
}

export const processFilteredTimeSeriesFromRaw = (config: IProcessSeriesConfig) => {
  const filterValuesCheck = {};
  const recordsByFilter = {};
  config.records.forEach(record => {
    const filterValue = record[config.filterBy];
    filterValuesCheck[filterValue] = true;
    if (!recordsByFilter[filterValue]) { recordsByFilter[filterValue] = {}; }
    const filteredRecords = recordsByFilter[filterValue];
    const seriesId = record[config.seriesBy];
    if (!filteredRecords[seriesId]) { filteredRecords[seriesId] = []; }
    const series = filteredRecords[seriesId];
    series.push({
      name: config.xAxis(record),
      value: config.yAxis(record)
    });
  });
  const filterValues = Object.keys(filterValuesCheck);
  const filteredData = {};
  filterValues.forEach(filterValue => {
    const filteredRecords = recordsByFilter[filterValue];
    let data = [];
    Object.keys(filteredRecords).forEach(lineId => {
      const line = filteredRecords[lineId];
      data.push({
        name: lineId,
        series: line,
      });
    });
    filteredData[filterValue] = data;
  });
  return {
    filterValues,
    filteredData,
  };
};

@Component({
  selector: 'line-graph',
  templateUrl: './line-graph.component.html',
  styleUrls: ['./line-graph.component.scss']
})
export class LineGraphComponent {

  view: any[] = ['100%', 240];

  @Input() data: any[];
  @Input() labelX: string;
  @Input() labelY: string;

  // options
  legend = true;
  showLabels = true;
  animations = true;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = true;
  timeline = true;
  yAxisTicks = [0, 25, 50, 75, 100];
  trimYAxisTicks = false;
  trimXAxisTicks = false;

  colorScheme = {
    domain: [
      '#390099',
      '#9E0059',
      '#FF0054',
      '#FF5400',
      '#FFBD00',
      '#99582a'
    ]
  };

  constructor() {
    Object.assign(this, { data: this.data });
  }

  onSelect(data): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

}
