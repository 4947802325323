import { AssessmentCode } from 'src/app/ui-schooladmin/data/mappings/eqao-g9';
import { IStudentAccount, IStudentTestSession } from '../../ui-schooladmin/data/types';
import { IMappedList } from '../../ui-schooladmin/data/util';

export interface IClassroomArchivedTeachers {
    __id?: string,
    classroomId: string,
    timestamp: any,
    uid:string
  }
  export interface IUserInfo {
    uid: string,
    displayName: string,
  }
  export interface IClassroomSlot {
    id: string, 
    archiveId?: string, 
    name: string,
    isAssigned?:boolean
    curricShort?: string,
    classCode?: string,
    numStudents?: number,
    numTeachers?: number,
    is_fi?: number,
  }
   export interface IClassroom {
    id: string, 
    test_window_id: number,
    semester_id: number,
    group_id: number, 
    name:string;
    classCode?:string;
    isAssigned?:boolean
    curricShort?: AssessmentCode,
    owner?: string;
    enableClassListingByCC?: boolean;
    currentStudents?: IMappedList<IStudentAccount>,
    currentTeachers?: string[],
    openAssessments?: any[],
    scheduledAssessments?: any[],
    recentAssessments?: any[],
    timeCreated?: string;
    timeLastTouched?: string;
    is_invigilating?:boolean,
    is_fi?: number,
    allow_ISR?:number,
    foreign_id?: string,
    course_name_short?: string,
    course_name_full?: string
  }
  export interface IStudent {
    oen: string,
    firstname: string,
    lastname: string,
    accommodation:string
  }
  export interface IStudentList {
    studentAccount:IStudentAccount,
    is_online?: boolean,
    session_a?:ISession,
    session_b?:ISession
    
  }
  export interface ISession{
    is_active?:boolean,
    is_session_active:boolean,
    time_spent?:string
  }
  
  export interface ISessionBegin{
    slug: string,
    isScheduled:boolean,
    scheduled_time?:string[]
  }

  export enum ASSESSMENT {
    G9_SAMPLE = 'G9_SAMPLE',
    G9_OPERATIONAL = 'G9_OPERATIONAL',
    OSSLT_SAMPLE = 'OSSLT_SAMPLE',
    OSSLT_OPERATIONAL = 'OSSLT_OPERATIONAL',
    PRIMARY_SAMPLE = 'PRIMARY_SAMPLE',
    PRIMARY_OPERATIONAL = 'PRIMARY_OPERATIONAL',
    JUNIOR_SAMPLE = 'JUNIOR_SAMPLE',
    JUNIOR_OPERATIONAL = 'JUNIOR_OPERATIONAL',
    TCLE_SAMPLE = 'TCLE_SAMPLE',
    TCLE_OPERATIONAL = 'TCLE_OPERATIONAL',
    TCN_SAMPLE ='TCN_SAMPLE',
    TCN_OPERATIONAL ='TCN_OPERATIONAL',
    SCIENCES8_SAMPLE = 'SCIENCES8_SAMPLE',
    SCIENCES8_OPERATIONAL = 'SCIENCES8_OPERATIONAL',
    MBED_SAMPLE = 'MBED_SAMPLE',
    ABED_SAMPLE = 'ABED_SAMPLE',
    ABED_OPERATIONAL = 'ABED_OPERATIONAL',
    SMCS_EN_G7 = 'SMCS_EN_G7',
    ABED_SAMPLE_FR_FIRST_6 = 'ABED_SAMPLE_FR_FIRST_6',
    ABED_SAMPLE_FR_IMMERSION_6 = 'ABED_SAMPLE_FR_IMMERSION_6',
    ABED_SAMPLE_SCIENCE_6 = 'ABED_SAMPLE_SCIENCE_6',
    ABED_SAMPLE_MATH_6 = 'ABED_SAMPLE_MATH_6',
    ABED_SAMPLE_ELA_6 = 'ABED_SAMPLE_ELA_6',
    ABED_KE_SOCIAL_STUDIES_9 = 'ABED_K&E_SOCIAL_STUDIES_9',
    ABED_KE_SCIENCES_9 = 'ABED_K&E_SCIENCES_9',
    ABED_KE_MATH_9 = 'ABED_K&E_MATH_9',
    ABED_KE_ELA_9B = 'ABED_K&E_ELA_9B',
    ABED_KE_ELA_9A = 'ABED_K&E_ELA_9A',
    ABED_SOCIAL_STUDIES_9 = 'ABED_SOCIAL_STUDIES_9',
    ABED_SCIENCES_9 = 'ABED_SCIENCES_9',
    ABED_MATH_9B = 'ABED_MATH_9B',
    ABED_MATH_9A = 'ABED_MATH_9A',
    ABED_FLA_9B = 'ABED_FLA_9B',
    ABED_FLA_9A = 'ABED_FLA_9A',
    ABED_ELA_9B = 'ABED_ELA_9B',
    ABED_ELA_9A = 'ABED_ELA_9A',
    ABED_SOCIAL_STUDIES_6 = 'ABED_SOCIAL_STUDIES_6',
    ABED_SCIENCES_6 = 'ABED_SCIENCES_6',
    ABED_MATH_6B = 'ABED_MATH_6B',
    ABED_MATH_6A = 'ABED_MATH_6A',
    ABED_FLA_6B = 'ABED_FLA_6B',
    ABED_FLA_6A = 'ABED_FLA_6A',
    ABED_MATH_6A2 = 'ABED_MATH_6A2',
    ABED_MATH_6B2 = 'ABED_MATH_6B2',
    ABED_ELA_6B = 'ABED_ELA_6B',
    ABED_ELA_6A = 'ABED_ELA_6A',
    ABED_PWR = "PWR"
  }

  export const isAssessmentABED = (assessment: string) => 
  {
    return assessment.includes("ABED_");
  };

  export enum SCHEDULER 
  {
    NOW = 'NOW',
    LATER = 'LATER'
  }
export enum TeacherModal {
  SAMPLE_MODAL = 'SAMPLE_MODAL',
  EDIT_MODAL = 'EDIT_MODAL',
  SAMPLE_MODAL_OTHER = 'SAMPLE_MODAL_OTHER',
  REPORT_MODAL = "REPORT_MODAL",
  ALREADY_EXIST = "ALREADY_EXIST",
  REVIEW_SUBM_MODAL = 'REVIEW_SUBM_MODAL',
  REVIEW_CURR_SS_STATUS_MODAL = 'REVIEW_CURR_SS_STATUS_MODAL',
  UNSUBMIT_STUDENT_MODAL = 'UNSUBMIT_STUDENT_MODAL',
  INVIG_VIDEO_INTRO_SEEN = 'invig_video_intro_seen'
}

export const ASSESSION_SLUG_MAPPING = () => [
  {target_1:'L-A', target_2:'pj_lbl_lang_session_a', source: 'lang_session_a'},
  {target_1:'L-B', target_2:'pj_lbl_lang_session_b', source: 'lang_session_b'},
  {target_1:'L-C', target_2:'pj_lbl_lang_session_c', source: 'lang_session_c'},
  {target_1:'L-D', target_2:'pj_lbl_lang_session_d', source: 'lang_session_d'},
  {target_1:'M-1', target_2:'pj_lbl_math_stage_1', source: 'math_stage_1'},
  {target_1:'M-2', target_2:'pj_lbl_math_stage_2', source: 'math_stage_2'},
  {target_1:'M-3', target_2:'pj_lbl_math_stage_3', source: 'math_stage_3'},
  {target_1:'M-4', target_2:'pj_lbl_math_stage_4', source: 'math_stage_4'},
  {target_1:'Q', target_2:'session_q', source: 'session_q'},
]; 

export enum PrintScanModal {
  STUDENT_INFO = 'STUDENT_INFO',
  INSTRUCTION_MODAL = 'INSTRUCTION_MODAL',
};
