<div class="confidentiality-modal">
  <div>
    <div class="title"><tra slug="abed_confidentiality_title"></tra></div>
    <div class="sub-title">
      <tra slug="abed_confidentiality_sub_title"></tra>
    </div>
    <br>
    <div>
      <tra slug="abed_confidentiality_body"></tra>
    </div>
    <br>
    <div class="checkbox-container">
      <div class="input-n-label" *ngFor="let check of savePayload.confidentialityChecks; let i = index">
        <input [id]="i" type="checkbox" [(ngModel)]="check.val" style="margin-right: 1em; height: 1.4em; width: 1.4em" /> 
        <div style="width: 100%"><label [for]="i"><tra [slug]="check.caption"></tra></label></div>
      </div>
    </div><br>
    <div>
      <div class="input-n-label">
        <div style="width: 3em"><tra slug="abed_name_header"></tra></div> 
        <input type="text" [(ngModel)]="savePayload.confidentialityName" /><br>
      </div>
      <div class="input-n-label">
        <div style="width: 3em"><tra slug="abed_date_header"></tra></div> 
        <input type="date" [(ngModel)]="savePayload.confidentialityDate" disabled /><br>
      </div>
    </div>
  </div>
</div>