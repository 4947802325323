import * as PIXI from "pixi.js-legacy";
import { IContentElementVirtualTools, IEntryStateVirtualTools } from "../model";
import { VirtualTools, EToolType } from "./virtual-tools";
import * as _ from "lodash";
import { SpriteLoader } from "../../element-render-custom-interaction/controllers/sprite-loader";
import { IPoint } from "./virtual-tools";
import { IExtendedTools } from "../element-render-virtual-tools.component";
import { EPixiTools, ENamingTypes, IDrawnMeta } from "../types/types";
import { LAYER_LEVEL } from "../types/constants";
import { QuestionState } from "../../models";

enum ACTIONS {
  FLIPH = "FLIPH",
  FLIPV = "FLIPV",
  DUPLICATE = "DUPLICATE",
  DELETE = "DELETE"
}
export class FreehandPolygon extends VirtualTools {
  element: IContentElementVirtualTools;
  spriteLoader: SpriteLoader
  stage: PIXI.Container;
  polygon: PIXI.Graphics;
  vertices: PIXI.Graphics;
  lines: PIXI.Graphics;
  backgroundSprite: PIXI.Sprite;
  vFlipSprite: PIXI.Sprite;
  hFlipSprite: PIXI.Sprite;
  dupeSprite: PIXI.Sprite;
  deleteSprite: PIXI.Sprite;
  polygons: PIXI.Graphics[] = [];
  polygonTools: IExtendedTools;

  constructor(
    questionState: QuestionState,
    element: IContentElementVirtualTools,  
    addGraphic, 
    render,
    getToolStateSub,
    stage,
    isLocked, 
    textToSpeech,
    isGlobalRotating: boolean, 
    isGlobalDragging: boolean,
    backgroundSprite: PIXI.Sprite,
    colorController: {color: number},
    layerLevel: LAYER_LEVEL,
    spriteLoader: SpriteLoader
  ) {
    super(questionState, addGraphic, render, getToolStateSub, stage, isLocked, textToSpeech, isGlobalRotating, isGlobalDragging);
    this.initTool({name: EPixiTools.POLYGON, type: EToolType.TOGGLE, layerLevel: layerLevel});
    this.questionState = questionState;    this.initTool({name: EPixiTools.POLYGON, type: EToolType.TOGGLE });
    this.element = element;
    this.spriteLoader = spriteLoader;
    this.backgroundSprite = backgroundSprite;
    this.vFlipSprite = new PIXI.Sprite();
    this.hFlipSprite = new PIXI.Sprite();
    this.dupeSprite = new PIXI.Sprite();
    this.deleteSprite = new PIXI.Sprite();

    // this.drawShapeVertices.bind(this)
    // this.drawVerticeLine.bind(this);

    this.loadAssets().then(this.initSprites);

    //#stopping Ticker
    PIXI.Ticker.system.autoStart = false
    PIXI.Ticker.system.stop()

    this.addDrawVertexListener();

    this.vertices = new PIXI.Graphics();
    this.lines = new PIXI.Graphics();
    this.lines.zIndex = 1;
    this.vertices.zIndex = 8;
    this.addGraphic(this.vertices);
    this.addGraphic(this.lines);

    // this.polygonTools = {
    //   parent: 'polygon',
    //   colors: [
    //     this.element.fhPolygonColor,
    //     '#c43f2e',
    //     '#2a91d6'
    //   ],        
    //   colorHandler: (color: string) => {
    //     this.polygonColor = color;
    //   },
    //   tools: [
    //   {
    //     id: 'delete',
    //     action: () => this.clearPolygons(),
    //     iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/delete/1684351529074/delete.png',
    //     isSelected: () => false,
    //   },
    //   {
    //     id: 'colorPicker',
    //     isColorPick: true,
    //     colorController: this.polygonColor,
    //     action: () => {},
    //     isSelected: () => false,
    //   },
    // ]}

    // Render scene
    this.render();
  }

  initSprites = ({resources}) => {
    if (!resources || Object.keys(resources).length == 0) {
      this.vFlipSprite.texture = PIXI.utils.TextureCache.vflip;
      this.hFlipSprite.texture = PIXI.utils.TextureCache.hflip;
      this.dupeSprite.texture = PIXI.utils.TextureCache.duplicate;
      this.deleteSprite.texture = PIXI.utils.TextureCache.delete;
    } else {
      this.vFlipSprite.texture = resources.vflip.texture;
      this.hFlipSprite.texture = resources.hflip.texture;
      this.dupeSprite.texture = resources.duplicate.texture;
      this.deleteSprite.texture = resources.delete.texture;
    }
  }

  drawSelectedOutline(obj: PIXI.Graphics, container: PIXI.Graphics, points?: IPoint[]) {
    if(!points?.length) {
      points = this.getObjectMetaProps()[container.name].points;
    }

    let outline = new PIXI.Graphics();
    outline.beginFill(0x0000, 0);
    outline.lineStyle(2, 0x0000, 1);
    for(let i=0; i<points.length; i++) {
      if(i==0) {
        outline.moveTo(points[i].x, points[i].y);
      } else {
        outline.lineTo(points[i].x, points[i].y);
        if(!points[i+1]) { 
          outline.lineTo(points[0].x, points[0].y);
        }
      }
    }
    outline.closePath();
    outline.endFill();
    outline.alpha = 0;

    // obj.addChild(outline);

    return outline;
  }
  
  completePolygon(points: IPoint[], isRestore?: boolean, origin?: IPoint, color?: number, dupRefContainer?: PIXI.Graphics) {
    // Translate points to 0,0
    let transX = 0;
    let transY = 0;
    for(let i = 0; i< points.length; i++) {
      if(i == 0) {
        transX = points[0].x;
        transY = points[0].y;
        points[0].x = 0;
        points[0].y = 0;
      } else {
        points[i].x -= transX;
        points[i].y -= transY;
      }
    }

    // Draw the polygon
    this.vertices.removeChildren();
    this.lines.removeChildren();

    const polygonContainer = new PIXI.Graphics();
    polygonContainer.name = this.getName(ENamingTypes.CONTAINER)
    const fillColor = color ? color : this.selectedColor;
    const fillOpacity = this.element.fhCircleShapeOpacity || 0.8;
    const fill = {color: fillColor, opacity: fillOpacity};
    const polygon = this.drawPolygon(0, 0, fill, points, false);
    polygon.name = this.getName(ENamingTypes.TOOL)
    polygon.pivot.set(0.5);
    polygon.interactive = false;
    polygonContainer.addChild(polygon);
    polygonContainer.zIndex = this.getContainerZindex(7);
    const center = this.getShapeCenter(points);
    polygonContainer.pivot.set(center.x,center.y);
    const rotationPoint = this.drawRotationPoint({x: center.maxX, y: center.minY}, polygonContainer);
    rotationPoint.name = this.getName(ENamingTypes.ROTATION_ANCHOR);

    if(isRestore) {
      polygonContainer.position.set(origin.x, origin.y);

      if(dupRefContainer){
        const dupRef = this.getToolObjectFromName(dupRefContainer.name);
        polygonContainer.rotation = dupRefContainer.rotation;
        polygon.x = dupRef.x;
        polygon.scale.x = dupRef.scale.x;
        polygon.y = dupRef.y;
        polygon.scale.y = dupRef.scale.y;
      }

    } else {
      polygonContainer.position.set(transX + center.x, transY + center.y);
    }

    rotationPoint.alpha = 0;
    // polygonContainer.interactiveChildren = false

    // this.addGraphic(polygonContainer);
    // this.polygons.push(polygonContainer);
    const newPolygon = {
      points,
      _isSelected: false,
      color: fillColor,
      fillOpacity,
      zIndex: polygonContainer.zIndex,
      x: polygonContainer.x,
      y: polygonContainer.y
    }
    this.addContainer(polygonContainer, newPolygon)

    this.updateState();

    this.addSelectPolygonListener(polygon, polygonContainer, rotationPoint, [...points]);
    return polygonContainer;
  }

  drawMenu(obj: PIXI.Graphics, container: PIXI.Graphics, points?: IPoint[]) {
    if(!points?.length) points = this.getObjectMetaProps()[container.name].points;
    const center = this.getShapeCenter(points);
    const menu = new PIXI.Graphics();
    const menuYOffset = 15;
    const menuY = center.y + ((center.maxY - center.minY) / 2) + menuYOffset
    const menuHeight = 30;
    const menuWidth = 95;
    menu.beginFill(0x333c42);
    menu.lineStyle(0);
    menu.drawRoundedRect(0, 0, menuWidth, menuHeight, 5);
    menu.position.set(center.minX, menuY);
    menu.endFill();

    menu.alpha = 0;
    const containerId = this.getContainerIdFromName(container.name);

    // Menu icons
    const hFlip = new PIXI.Sprite();
    hFlip.texture = this.hFlipSprite.texture;
    hFlip.scale.set(0.03);
    hFlip.anchor.set(0,0.5);
    hFlip.y = menuHeight / 2;
    hFlip.x = 10;
    hFlip.interactive = true;
    hFlip.name = this.getName(ENamingTypes.MENU_HFLIP, containerId)

    const vFlip = new PIXI.Sprite();
    vFlip.texture = this.vFlipSprite.texture;
    vFlip.scale.set(0.03);
    vFlip.anchor.set(0,0.5);
    vFlip.y = menuHeight / 2;
    vFlip.x = hFlip.x + hFlip.width + 5;
    vFlip.interactive = true;
    vFlip.name = this.getName(ENamingTypes.MENU_VFLIP, containerId)

    const dupe = new PIXI.Sprite();
    dupe.texture = this.dupeSprite.texture;
    dupe.scale.set(0.03);
    dupe.anchor.set(0,0.5);
    dupe.y = menuHeight / 2;
    dupe.x = vFlip.x + vFlip.width + 5;
    dupe.interactive = true;
    dupe.name = this.getName(ENamingTypes.MENU_DUPE, containerId)

    const del = new PIXI.Sprite();
    del.texture = this.deleteSprite.texture;
    del.scale.set(0.03);
    del.anchor.set(0,0.5);
    del.y = menuHeight / 2;
    del.x = dupe.x + dupe.width + 5;
    del.interactive = true;
    del.name = this.getName(ENamingTypes.MENU_DEL, containerId)

    menu.addChild(hFlip);
    menu.addChild(vFlip);
    menu.addChild(dupe);
    menu.addChild(del);
    this.addMenuListeners(obj, ACTIONS.FLIPH, hFlip, points);
    this.addMenuListeners(obj, ACTIONS.FLIPV, vFlip, points);
    this.addMenuListeners(obj, ACTIONS.DUPLICATE, dupe, points, container);
    this.addMenuListeners(container, ACTIONS.DELETE, del, points);
    
    // container.addChild(menu);

    return menu;
  }

  addMenuListeners(obj: PIXI.Graphics, action: ACTIONS, option: PIXI.Sprite, points: IPoint[], container?: PIXI.Graphics) {
    const center = this.getShapeCenter(points);
    let func;
    let flipped;
    option.cursor = 'pointer';
  
    if(action == ACTIONS.FLIPH) {
      func = (e) => {
        if(obj.scale.y == -1){ // determine if the obj is already flipped
          obj.scale.y = 1;
          obj.y = 0;
        } else {
          obj.scale.y = -1;
          obj.y = center.y * 2;
        }

        this.render();
      }
    } else if(action == ACTIONS.FLIPV) {
        func = (e) => {
          if(obj.scale.x == -1) { // determine if the obj is already flipped
            obj.scale.x = 1;
            obj.x = 0;
          } else {
            obj.scale.x = -1;
            obj.x = center.x * 2;
          }
          this.render();
      }
    } else if(action == ACTIONS.DUPLICATE) {
      func = (e) => {
        const fillColor = this.getFillColor(container);
        const dupPosition = this.getDuplicateObjectPosition(e.currentTarget)
        const polygonContainer = this.completePolygon([...points], true, {x: dupPosition.x, y: dupPosition.y}, fillColor, container);
        this.render();
      }
    } else if(action == ACTIONS.DELETE) {
      func = (e) => {
        this.deleteContainerFromMenu(e.currentTarget as PIXI.Sprite)
        this.updateState();
      }
    }
    option.on('pointerup', func);
  }

  deleteObject(obj: PIXI.Graphics) {
    obj.removeChildren();
    obj.clear();
  }

  addSelectPolygonListener(obj: PIXI.Graphics, container: PIXI.Graphics, rotationPoint: PIXI.Graphics, points: IPoint[]) {
    obj.on('pointerdown', ($event) => super.onPointerDown($event))
  }

  clearActiveSelection = (container: PIXI.Graphics) => {
    this.removePolygonMenu(container);
  }

  addActiveSelection = (container: PIXI.Graphics) => {
    this.addPolygonMenu(container)
  }

  addPolygonMenu(container: PIXI.Graphics){
    // get lineMenuContainer Object
    const containerId = this.getContainerIdFromName(container.name);
    const menuContainerName = this.getName(ENamingTypes.MENU, containerId)
    let menuContainer = <PIXI.Container>container.getChildByName(menuContainerName);
    const polygonName = this.getName(ENamingTypes.TOOL, containerId)
    const polygon = <PIXI.Graphics>container.getChildByName(polygonName);

    // rotation point
    const rotationPointName = this.getName(ENamingTypes.ROTATION_ANCHOR, containerId);
    const rotationPoint = <PIXI.Graphics>container.getChildByName(rotationPointName);
    rotationPoint.alpha = 1
    rotationPoint.interactive = true


    // new menu container childs
    const outline = this.drawSelectedOutline(polygon, container);
    const outterBorder = this.drawSelectedBorder(container);
    const menu = this.drawMenu(polygon, container);
    outline.alpha = 1;
    outterBorder.alpha = 1
    // rotationPoint.alpha = 1
    menu.alpha = 1;

    if(!menuContainer){
      // add new menu container inside lineContainer
      menuContainer =  new PIXI.Container();
      menuContainer.name = this.getName(ENamingTypes.MENU, containerId);
      container.addChild(menuContainer);
    }
    menuContainer.addChild(...[outline, outterBorder, menu])
  }

  removePolygonMenu(container: PIXI.Graphics){
    const containerId = this.getContainerIdFromName(container.name);
    const menuContainerName = this.getName(ENamingTypes.MENU, containerId)
    let menuContainer = <PIXI.Container>container.getChildByName(menuContainerName);
    if(menuContainer) menuContainer.removeChildren()

    // resize point
    const rotationPointName = this.getName(ENamingTypes.ROTATION_ANCHOR, containerId);
    const rotationPoint = <PIXI.Graphics>container.getChildByName(rotationPointName);
    if(rotationPoint) {
      rotationPoint.alpha = 0
      rotationPoint.interactive = false
    }
  }

  points: IPoint[] = [];
  addDrawVertexListener() {
    const addVertice = (e) => {
        if(this.isDrawMode && !this.isVertexDragging && !this.isGlobalRotating) {
            const {x,y} = e.data.getLocalPosition(this.stage);
            const point = {x, y};
            this.points.push(point);
            this.drawShapeVertices(this.vertices, this.lines, this.points, this.addMoveVertexListener.bind(this));
        }
    }

    this.stage.on('pointerup', addVertice);
  }

  isVertexDragging = false;
  addMoveVertexListener(obj, index) {
    let isClicked = false;
    let initialDiffX = 0;
    let initialDiffY = 0;
    const onDragStart = (e) => {
      if(this.isDrawMode){
        const mousePosition = e.data.getLocalPosition(this.stage);
        initialDiffX = mousePosition.x - obj.x
        initialDiffY = mousePosition.y - obj.y
        isClicked = true;
        obj.cursor = 'grabbing';
      }
    }
    const onDragMove = (e) => {
        if(isClicked && this.isDrawMode) {
            this.isVertexDragging = true;
            const mousePosition = e.data.getLocalPosition(this.stage);
            obj.x = mousePosition.x - initialDiffX;
            obj.y = mousePosition.y - initialDiffY;
            this.render();
        }
    }
    const onDragEnd = (e) => {
      if(this.isDrawMode){
        if(!this.isVertexDragging && index == 0 && this.points.length >= 3) {
          this.completePolygon(this.points);
          
          this.points = [];

          // this.isDrawMode = false;
          this.stage.cursor = 'default';
          this.backgroundSprite.cursor = 'default';
          this.render();
        } else if(!this.isVertexDragging && index != 0) {
          // delete counter
          this.points.splice(index, 1);
          this.drawShapeVertices(this.vertices, this.lines, this.points, this.addMoveVertexListener.bind(this));
        } else {
          isClicked = false;
          this.isVertexDragging = false;
          obj.cursor = 'grab';
          this.points[index].x += obj.x;
          this.points[index].y += obj.y;
          this.drawShapeVertices(this.vertices, this.lines, this.points, this.addMoveVertexListener.bind(this));
        }
      }
    }

    obj.on('pointerdown', onDragStart)
    .on('pointermove', onDragMove)
    .on('pointerup', onDragEnd)
    .on('pointerupoutside', onDragEnd)
  }

  isDrawMode = false;
  toggleDrawMode(mode?: boolean) {
    this.isDrawMode = mode == null ? !this.isDrawMode : mode;
    if(!this.isDrawMode) {
        this.stage.cursor = 'default';
        this.backgroundSprite.cursor = 'default';
    } else {
        this.stage.cursor = 'pointer';
        this.backgroundSprite.cursor = 'pointer';
    }
    this.vertices.removeChildren();
    this.lines.removeChildren();
    this.points = [];

    this.render(); 

    return this.isDrawMode;
  }

  clearPolygons() {
    this.polygons.forEach((polygon) => {
      this.deleteObject(polygon);
      polygon.destroy();
    });
    this.polygons = [];
    this.render();
  }

  getPolygonTools() {
    return this.polygonTools;
  }

  loadAssets() {
    return this.spriteLoader.loadSprites()
  }

  // initToolStateSub(){
  //   const toolStateSub = this.getToolStateSub();
  //   Object.keys(toolStateSub).forEach(key => {
  //     switch (key) {
  //       case 'selector':
  //         toolStateSub[key].subscribe(o => this.onSelectToolSub(o))   
  //         break;      
  //       default:
  //         break;
  //     }
  //   })
  // }

  // onSelectToolSub(o: {val: boolean, origin: 'BASE' | 'CHILD'}){
  //   if(!o.val) return this.removeListners();
  //   this.activateListners();
  // }

  // removeListners(){
  //   this.polygons.forEach(polygonContainer => {
  //     // TODO: these follow the same as freehand-line but will require alot of refactoring so going with this instead
  //     this.removeListner(polygonContainer);
  //   })
  // }

  // activateListners(){
  //   this.polygons.forEach(polygonContainer => {
  //     // TODO: these follow the same as freehand-line but will require alot of refactoring so going with this instead
  //     this.activateListner(polygonContainer)
  //   })
  // } 

  // removeListner(polygonContainer: PIXI.Graphics){
  //   polygonContainer.interactiveChildren = false;
  // }

  // activateListner(polygonContainer: PIXI.Graphics){
  //   polygonContainer.interactiveChildren = true;
  // }

  changeDrawnGraphicColor(currentSelectedObject: PIXI.Graphics){
    // console.log(currentSelectedObject)
    // if (currentSelectedObject.name)

    if (!currentSelectedObject.name) return;
    const polygonGraphic = <PIXI.Graphics>currentSelectedObject.children.filter(child => child.name.includes(ENamingTypes.TOOL))[0]
    const pos_x = polygonGraphic.x;
    const pos_y = polygonGraphic.y;
    const rotation = polygonGraphic.rotation;
    const pivot = polygonGraphic.pivot;
    const alpha = polygonGraphic.alpha;
    const zIndex = polygonGraphic.zIndex;

    const points = this.ObjectMetaPropsContainer[currentSelectedObject.name].points;
    const fillColor = this.selectedColor;
    this.ObjectMetaPropsContainer[currentSelectedObject.name].color = fillColor;

    polygonGraphic.clear();
    polygonGraphic.beginFill(this.selectedColor, this.ObjectMetaPropsContainer[currentSelectedObject.name].fillOpacity);
    const transformedPoints = []
    
    points.map(point => {
      transformedPoints.push({x: point.x, y: point.y});
    })

    polygonGraphic.drawPolygon(transformedPoints);

    polygonGraphic.endFill();

    polygonGraphic.position.set(pos_x, pos_y);
    polygonGraphic.rotation = rotation;
    polygonGraphic.pivot = pivot
    polygonGraphic.zIndex = zIndex;

    this.render();
  }

  getUpdatedState(entry: Partial<IEntryStateVirtualTools>): Partial<IEntryStateVirtualTools> {
    entry.data[EPixiTools.POLYGON] = Object.values(this.ObjectMetaPropsContainer);
    console.log(entry.data[EPixiTools.POLYGON], 'update circle')
    return entry;
  }

  handleNewState() {
    const data = this.getQuestionState(EPixiTools.POLYGON);
    if(!data || data.length == 0) {
      return;
    }

    data.forEach((polygon) => {
      const temp = JSON.parse(JSON.stringify(polygon))
      this.completePolygon(temp.points, true, {x: polygon.x, y: polygon.y}, polygon.color);
    });

    this.render();
  }
}
