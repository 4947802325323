import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import {LoginGuardService} from "../../api/login-guard.service";

export interface IMenuTabConfig<T> {
  id: T;
  caption: string;
  count?: () => number | string;
  showIf?: () => boolean;
  ///
  isCountEnabled?: boolean;
  isShowIfEnabled?: boolean;
  data?: any;
  disabled?: boolean;
}

@Component({
  selector: 'menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss']
})
export class MenuBarComponent implements OnInit, OnChanges {

  @Input() isSubMenu: boolean;
  @Input() menuTabs: IMenuTabConfig<string>[];
  @Input() tabIdInit: string;
  @Input() emitChangeOnClickOnly?:boolean;
  @Input() isDocumentSheet?: boolean = false;
  @Input() isNestedView: boolean;
  @Output() change = new EventEmitter();
  @ViewChildren ('tabElement') tabElement: QueryList<ElementRef>

  selectSheetDropdownOn: boolean

  private activeTabId: string;

  constructor(
      private loginGuard: LoginGuardService
  ) { }

  ngOnInit(): void {
    this.selectSheetDropdownOn = false;
    if (this.tabIdInit) {
      this.setActiveId(this.tabIdInit);
    }
  }

  ngOnChanges() {
    if (this.tabIdInit){
      this.setActiveId(this.tabIdInit);
    }
    if (this.menuTabs){
      this.menuTabs.forEach(tab => {
        tab.isCountEnabled = (typeof tab.count === 'function');
        tab.isShowIfEnabled = (typeof tab.showIf === 'function');
      });
    }
  }

  isTabHidden(tab:IMenuTabConfig<any>){
    if (tab.isShowIfEnabled){
      return tab.showIf();
    }
    return true;
  }

  isActiveId(id:string){ return this.activeTabId === id; }
  setActiveId(id:string, isClicked?:boolean){
    const tab = this.menuTabs.filter(tab => tab.id + '' === id + '')[0];
    if (!tab) {
      return;
    }

    // console.log(tab);
    if (tab.disabled) {
      this.loginGuard.quickPopup('This page is currently unavailable.')
      return;
    }
    const isChangeOnClick = (typeof isClicked !== 'undefined' && isClicked);
    this.activeTabId = id + '';
    if (this.emitChangeOnClickOnly) {
      if (isChangeOnClick) {
        this.change.emit(id);
      }
    } else {
      this.change.emit(id);
    }

    if (this.isNestedView && this.isDocumentSheet){
      this.selectSheetDropdownOn = false;
      this.searchWord = undefined;
      this.resetFilter();
    }
  }

  toggleSelectSheetDropdown(searchFocus?: boolean){
    if (!searchFocus) this.selectSheetDropdownOn = !this.selectSheetDropdownOn;
    else this.selectSheetDropdownOn = true;

    if (this.selectSheetDropdownOn && this.sheetDropdownCloseTimeout){
      clearTimeout(this.sheetDropdownCloseTimeout);
    }
  }

  searchWord: string;
  noResult: boolean
  filterFunction() {
    let input, filter, txtValue;
    input = this.searchWord;
    filter = input.toUpperCase();
    this.noResult = true

    this.tabElement.forEach((child) => { 
      const element = child.nativeElement
      txtValue = element.textContent || element.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        element.style.display = "";
        this.noResult = false;
      } else {
        element.style.display = "none";
      }
    })
  }

  resetFilter(){
    if (!this.tabElement) return;
    this.tabElement.forEach((child) => { 
      const element = child.nativeElement;
      element.style.display = "";
    })
  }

  getActiveTab(){
    return this.menuTabs.filter(tab => tab.id + '' === this.activeTabId + '')[0];
  }

  sheetDropdownCloseTimeout: NodeJS.Timeout
  onNestedSelectionFocusOut(){
    this.sheetDropdownCloseTimeout = setTimeout(() => {
      this.selectSheetDropdownOn = false;
      this.sheetDropdownCloseTimeout = undefined;
    }, 300)
  }
}
