import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { TextToSpeechService } from '../../ui-testrunner/text-to-speech.service';
import { ElementType, getElementWeight, QuestionState, ScoringTypes } from '../models';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { ESelectionTableScoreMode, getDerivedLegacyScoreMode, getScoreMode, IContentElementSelectionTable, IEntryStateSelectionTable } from './model';

interface IScoreResult {
  isFilled: boolean,
  isStarted: boolean,
  percentCorrect: number
}

@Component({
  selector: 'element-render-selection-table',
  templateUrl: './element-render-selection-table.component.html',
  styleUrls: ['./element-render-selection-table.component.scss']
})
export class ElementRenderSelectionTableComponent implements OnInit {
  
  @Input() element:IContentElementSelectionTable;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() changeCounter: number;
  @Input() questionPubSub?: QuestionPubSub;

  isStarted:boolean;
  testTakerAnswers:{value: boolean}[][] = [];
  lastQuestionState:IEntryStateSelectionTable;

  constructor(private textToSpeech:TextToSpeechService) { }
  hoverTriggers = new Map()

  ngOnInit(): void {
    this.resetChecks();
    if (this.questionState && this.questionState[this.element.entryId]) {
      this.handleNewState();
    } else {
      this.ensureState();
    }
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes.changeCounter) {
      this.resetChecks();
    }
    if (this.lastQuestionState !== <any> this.questionState) {
      this.lastQuestionState = <any> this.questionState;
      if (this.questionState[this.element.entryId]) {
        this.handleNewState();
      } 
      else {
        this.ensureState();
      }
    }
    if (this.isLocked){
      this.lockAnswer();
    }
  }
  getToprowValue(){
    if(!this.element.topRow || this.element.topRow.length === 0) {
      return false; 
    }
    
    if(this.element.topRow[0].content.elementType !== "text"){
      return true
    } else return ( this.element.topRow[0].content.elementType == "text" && (this.element.topRow[0].content.caption !== '') ) || ( this.element.topLeftText.caption !== '' );
  }

  getHoverTrigger(obj) {
    let trigger = this.hoverTriggers.get(obj)
    if (!trigger) {
      trigger = new Subject();
      this.hoverTriggers.set(obj, trigger)
    }
    return trigger
  }

  hoverOver(obj) {
    const trigger = this.hoverTriggers.get(obj);
    if (trigger) {
      trigger.next(true);
    }
  }

  isVoiceOverEnabled(){
    return this.textToSpeech.isActive;
  }

  lockAnswer() {
    if (this.questionState) {
      const quest = this.questionState[this.element.entryId];
      this.testTakerAnswers == quest.checkMarks;
    }
  }

  isAudio(cell) {
    return cell.content.elementType == ElementType.AUDIO;
  }

  resetChecks() {
    
    this.testTakerAnswers = [];

    if (this.element.leftCol && this.element.topRow) {
      for (let i = 0;i<this.element.leftCol.length;i++) {
        if (this.testTakerAnswers.length<this.element.leftCol.length) this.testTakerAnswers.push([]);
        for (let j = 0;j<this.element.topRow.length;j++) {
          if (this.testTakerAnswers[i].length<this.element.topRow.length) this.testTakerAnswers[i].push({value: false});
        }
      }
    }
    
    if (this.testTakerAnswers.length==0) {
      this.testTakerAnswers.push([{value: false}]);
    }
  }

  handleNewState() {
    if (this.questionState) {
      //console.log("handle new state");
      //console.log(this.questionState);
      const entryState:IEntryStateSelectionTable = this.questionState[this.element.entryId];
      if (entryState) {
        this.isStarted = entryState.isStarted;
        this.testTakerAnswers = [];
        entryState.checkMarks.forEach((row, r) => {
          this.testTakerAnswers.push([]);
          row.forEach((col, c) => {
            if (col["value"]) this.testTakerAnswers[r].push({value: true});
            else this.testTakerAnswers[r].push({value: false});
          })
        });
        this.updateState();
      }
      //console.log(this.testTakerAnswers);
    }
  }

  ensureState() {
    if (this.questionState){
      const entryId = this.element.entryId;
      const weight = getElementWeight(this.element);
      let score = 0;
      if (!this.questionState[entryId]){
        const newCheck = [];
        this.testTakerAnswers = []
        if (this.element.leftCol) {
          this.element.leftCol.forEach((row, r)=>{
            newCheck.push([]);
            this.testTakerAnswers.push([]);
            this.element.topRow.forEach((col, c)=>{
              newCheck[r].push({value:false});
              this.testTakerAnswers[r].push({value: false});
            })
          })
        }
        // if (this.testTakerAnswers.length==0) {
        //   this.testTakerAnswers.push([])
        // }
        // const isCorrect = (+score === +weight);
        // if (!isCorrect && !this.element.enableProportionalScoring){
        //   score = 0;
        // }
        let entryState:IEntryStateSelectionTable = {
          type: 'select_table',
          isStarted: false,
          isFilled: false,
          isResponded: false,
          isCustomGrading: false,
          isCorrect: false,
          checkMarks: newCheck, 
          score,
          weight,
          scoring_type: ScoringTypes.AUTO,
        }
        this.questionState[entryId] = entryState;
      }
    }
  }

  updateState() {
    //console.log(this.questionState);
    if (this.questionState) {
      const entryId = this.element.entryId;
      const checkMarks = this.captureCheckState();
      const result = this.getNewChecks();
      // const isResponded = this.questionState[entryId].isResponded || result.isFilled;
      let entryState:IEntryStateSelectionTable = {
        type: ElementType.SELECT_TABLE,
        isStarted: result.isStarted,
        isCorrect: result.isCorrect,
        isFilled: result.isFilled,
        isResponded: result.isResponded,
        score: result.score,
        isCustomGrading: false,
        checkMarks,
        weight: getElementWeight(this.element),
        scoring_type: ScoringTypes.AUTO,
      }
      this.questionState[entryId] = entryState;
    }
  }

  captureCheckState(){
    const capturedGridState:{value: boolean}[][] = [];
    const colIndex = new Map();
    this.testTakerAnswers.forEach((row, rowIndex)=>{
      const capturedRowState = [];
      capturedGridState.push(capturedRowState);
      row.forEach((cell, colIndex)=>{
        capturedRowState.push({
          value: !! cell.value
        })
      })
    })
    return capturedGridState
  }

  getScoreMode() {
    return getScoreMode(this.element);
  }

  getCellScore() : IScoreResult {
    const checkBoxGridDef = this.element.checkBoxRows;
    const firstRowDef = checkBoxGridDef[0];
    const numRows = checkBoxGridDef.length;
    const numCols = firstRowDef.length;

    let numIncorrect = 0;
    let numCorrect = 0;
    let isStarted;
    let isFilled;
    let percentCorrect;

    let onePerRow = true;
    let onePerCol = true;

    let numChecked = 0;

    let requiredAnswers;
    if(this.element.isMaxDenoScoring) {
      requiredAnswers = this.getNumRequiredAnswers();
    }

    const numCells:number = numRows * numCols;
      const colIndex = new Map();
      this.testTakerAnswers.forEach((row, r)=>{
        let rowChecked = false;
        row.forEach((col, c)=>{
          if (this.testTakerAnswers[r][c].value) {
            rowChecked = true;
            isStarted = true;
            colIndex.set(c, true);
            numChecked++;
          } 
          
          if (this.element.checkBoxRows[r][c].value !== this.testTakerAnswers[r][c].value) {
            numIncorrect++;
          } else if(this.element.checkBoxRows[r][c].value === this.testTakerAnswers[r][c].value && this.testTakerAnswers[r][c].value) {
            numCorrect++;
          }
        })
        if (!rowChecked) onePerRow = false;
      })

      if(this.getScoreMode() === ESelectionTableScoreMode.LEGACY) {
        const colLen = this.testTakerAnswers[0].length;
        for (let i = 0;i<colLen;i++) {
          if (!colIndex.has(i)) onePerCol = false;
        }
        let isColReqFulfilled = false;
        let isRowReqFulfilled = false;
        
        if (!this.element.isMustHaveOnePerCol || onePerCol) isColReqFulfilled = true;
        if (!this.element.isMustHaveOnePerRow || onePerRow) isRowReqFulfilled = true;
        if (isStarted && isColReqFulfilled && isRowReqFulfilled) isFilled = true;
      } else {
        if(requiredAnswers) {
          isFilled = numChecked === requiredAnswers;
        } else {
          isFilled = isStarted;
        }
      }

      let denom = requiredAnswers || numCells;

      let numerator = requiredAnswers ? numCorrect : (denom - numIncorrect);
      percentCorrect =  Math.max(0, numerator/denom);
      return {
        isStarted,
        isFilled,
        percentCorrect
      }
  }

  getRowColScore() : IScoreResult {
    const checkBoxGridDef = this.element.checkBoxRows;
    const firstRowDef = checkBoxGridDef[0];
    const numCols = firstRowDef.length;
    const gridSelections = this.captureCheckState();
    const numRows = checkBoxGridDef.length;
    const gridAnswerKey = this.element.checkBoxRows;

    let isMaxDenoScoring;
    
    let requiredPerCol = Math.min(numRows, this.element.maxCheckedPerCol);
    let requiredPerRow = Math.min(numCols, this.element.maxCheckedPerRow);

    if(this.getScoreMode() === ESelectionTableScoreMode.LEGACY) {
      isMaxDenoScoring = this.element.isMaxDenoScoring && requiredPerCol > 1 && !(numRows > 1);
    } else {
      isMaxDenoScoring = this.element.isMaxDenoScoring;
    }
    
    let isStarted;
    let isFilled;
    let percentCorrect;

    let numRowsFilled = 0;
    let numCorrect = 0;
    if (numRows > 0){
      for (let iRow=0; iRow<numRows; iRow++){
        let numFilledInRow = 0;
        for(let iCol=0; iCol < numCols; iCol++) {
          const cellKey = gridAnswerKey[iRow][iCol];
          
          const cellSelection = gridSelections[iRow][iCol];

          if(!isMaxDenoScoring || cellKey.value ) {
            if(!!cellKey.value === cellSelection.value){
              numCorrect++;
            }
          }
          
          if (cellSelection.value){
            numFilledInRow++;
          }
        }

        if ((requiredPerRow && numFilledInRow >= requiredPerRow) || (!requiredPerRow && numFilledInRow > 0) ){
          numRowsFilled++;
        } 
      }
      isStarted = numRowsFilled > 0;
      isFilled = requiredPerCol ?  (numRowsFilled  === requiredPerCol) : (numRowsFilled === numRows);

      let rowColDenom = numRows;
      let colRowDenom = numCols;
      if(isMaxDenoScoring) {
        rowColDenom =  Math.min(numRows, (requiredPerCol ?? numRows));
        colRowDenom = Math.min(numCols, (requiredPerRow ?? numCols));
      }
      const denom = rowColDenom*colRowDenom;
      percentCorrect = denom === 0 ? 0 : (numCorrect/denom);
    }

    return {
      isStarted,
      isFilled,
      percentCorrect
    }  
  }

  getScoreResult() {
    switch(getDerivedLegacyScoreMode(this.element)) {
      case ESelectionTableScoreMode.ROW_COL :
        return this.getRowColScore();
      case ESelectionTableScoreMode.CELL :
        return this.getCellScore();
    }
  }


  getNewChecks() {
    const weight = getElementWeight(this.element);
    const scoreResult: IScoreResult = this.getScoreResult();
    const {isFilled, isStarted, percentCorrect} = scoreResult;
    const isCorrect = percentCorrect === 1
    const score = this.element.enableProportionalScoring ?  weight*percentCorrect : (isCorrect ? 1 : 0);

    let isResponded = this.questionState[this.element.entryId].isResponded || isStarted; //isResponded is just isStarted that cannot be reset back to false.
    return { score, isStarted, isFilled, isCorrect, isResponded};
  }

  checkOrUnCheckAnswer(row:number, col:number) {
    if (this.isLocked){
      return;
    }
    if (this.element.checkBoxRows[row][col].isSelectionDisabled) {
      return;
    }
    const prop = this.testTakerAnswers[row][col];
    if (prop.value){
      prop.value = false;
    }
    else {
      let checkAnswer = true;

      const scoreMode = this.getScoreMode();
      switch(scoreMode) {
        case ESelectionTableScoreMode.LEGACY:
        case ESelectionTableScoreMode.ROW_COL:
          if (this.element.maxCheckedPerCol && this.element.maxCheckedPerCol>0) {
            let checkedInCol = 0;
            for (let iRow = 0;iRow<this.element.leftCol.length;iRow++) {
              if (this.testTakerAnswers[iRow][col].value) {
                checkedInCol++;
              }
              if (checkedInCol >= this.element.maxCheckedPerCol) {
                checkAnswer = false;
                break;
              }
            }
          }
    
          if (checkAnswer && +this.element.maxCheckedPerRow === 1){
            for (let iCol = 0;iCol<this.element.topRow.length;iCol++) {
              this.testTakerAnswers[row][iCol].value = false;
            }
          }
          else if (this.element.maxCheckedPerRow && this.element.maxCheckedPerRow>0 && checkAnswer) {
            let count = 0;
            for (let i = 0;i<this.element.topRow.length;i++) {
              if (this.testTakerAnswers[row][i].value){
                count++;
              }
              if (count >= this.element.maxCheckedPerRow) {
                checkAnswer = false;
                break;
              }
            }
          }
          break;
        case ESelectionTableScoreMode.CELL:
          if(this.element.isMaxDenoScoring) {
            const requiredAnswers = this.getNumRequiredAnswers();
            let numChecked = 0;
            for(let iRow = 0; iRow < this.testTakerAnswers.length; iRow++) {
              for(let iCol = 0; iCol < this.testTakerAnswers[iRow].length; iCol++) {
                if(this.testTakerAnswers[iRow][iCol].value) {
                  numChecked++;
                  if( requiredAnswers === 1) {
                    this.testTakerAnswers[iRow][iCol].value = false;
                  } else if(numChecked >= requiredAnswers) {
                    checkAnswer = false;
                    break;
                  }
                }
              }
              if(!checkAnswer) {
                break;
              }
            } 
          } 
          break;
        default:
          break;
      }

      if (checkAnswer){
        prop.value = true;
      }
    }
    this.updateState();
  }

  getTableMaxWidth() {
    if (!this.element.isAnswerWidthSet || !this.element.isQuestionWidthSet) return "none";
    if (!this.element.answerColumnWidth || !this.element.questionColumnWidth) return "none";

    let width = this.element.questionColumnWidth+this.element.topRow.length*this.element.answerColumnWidth;
    return width+'em';
  }

  getTableStyle() {
    let style = {}
    var isEdge = /Edg\/(\d+)/.test(navigator.userAgent);
    if(isEdge) {
      // Issue with rendering the border-collapse property for EDGE
      style['border-collapse'] ='separate'
    }
    return style
  }

  getCellStyle(row:number, col:number) {
    if (!this.testTakerAnswers[row]) return
    const style = {}
    // if (this.element.isElementInSelectableCell) {
      if (!this.testTakerAnswers[row][col].value) {
        if (!this.element.checkBoxRows[row][col].defaultColor) {
          style["background-color"] = "#ffffff"
        } else {
          style["background-color"] = this.element.checkBoxRows[row][col].defaultColor;
        }
      } else {
        if (!this.element.checkBoxRows[row][col].selectedColor) {
          style["background-color"] = "#ffffff"
        } else {
          style["background-color"] = this.element.checkBoxRows[row][col].selectedColor;
        }
      }

      if (this.element.isCellPaddingSet) {
        if (this.element.topCellPadding != undefined) {
          style["padding-top"]=this.element.topCellPadding+"em"
        }
        if (this.element.rightCellPadding != undefined) {
          style["padding-right"]=this.element.rightCellPadding+"em"
        }
        if (this.element.bottomCellPadding != undefined) {
          style["padding-bottom"]=this.element.bottomCellPadding+"em"
        }
        if (this.element.leftCellPadding != undefined) {
          style["padding-left"]=this.element.leftCellPadding+"em"
        }
      }
    // }

    /*if (this.element.isAnswerHeightSet && this.element.answerColumnHeight) {
      style["max-height"]=this.element.answerColumnHeight+"em"
      style["max-height"]=this.element.answerColumnHeight+"em"
    } else if (this.element.isAnswerWidthSet && this.element.answerColumnWidth) {
      style["max-width"]=this.element.answerColumnWidth+"em"
    }*/
    // if (this.textToSpeech.isHiContrast) {
    //   style["filter"]="invert(1)"
    // }
    return style
  }

  isHiContrast() {
    return this.textToSpeech.isHiContrast
  }

  getNumRequiredAnswers() {
    let numRequired = 0;
    for(const row of this.element.checkBoxRows) {
      for(const col of row) {
        if(col.value) {
          numRequired++;
        }
      }
    }

    return numRequired;
  }

  getCellContentAlignment(cell){
    return cell.alignItems ? cell.alignItems : 'flex-start'
  }
}
