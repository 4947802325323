import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { LangService } from '../../core/lang.service';
import { IEntryStateMcq } from '../element-render-mcq/model';
import { ElementType, getElementWeight, QuestionState, ScoringTypes } from '../models';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { IContentElementTextSelection } from './model';
import { TextToSpeechService } from '../text-to-speech.service';
import { HighlighterService } from '../highlighter.service';



const SCORING_TYPE = ScoringTypes.AUTO;

interface SelectableWord {
  index: number,
  word: string,
  isDisabled?: boolean,
}

@Component({
  selector: 'element-render-select-text',
  templateUrl: './element-render-select-text.component.html',
  styleUrls: ['./element-render-select-text.component.scss']
})
export class ElementRenderSelectTextComponent implements OnInit {

  @Input() element:IContentElementTextSelection;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() changeCounter:number;
  @Input() questionPubSub?: QuestionPubSub;

  constructor(
    private lang: LangService,
    private textToSpeech: TextToSpeechService,
    public highlighter: HighlighterService
  ) { }

  blocks:SelectableWord[] = [];
  paragraphs: SelectableWord[][]
  isSelected = []

  ngOnInit() {
    this.setBlocks();
    if(this.questionState && this.questionState[this.element.entryId]) {
      for(let selection of this.questionState[this.element.entryId].selections) {
        this.isSelected[selection.i] = true;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.ensureState()
    if (changes.changeCounter) {
      this.setBlocks();
    }
  }

  setBlocks() {
    if (!this.element.texts) return;
    this.blocks = []
    this.isSelected = []
    this.paragraphs = []

    const parseWordList = (block:any) => {
      if (this.element.isSpaceBetweenDisabled){
        return [block["content"]]
      }
      else {
        return block["content"].split(' ');
      }
    }

    if(this.element.isParagraphMode){
      this.element.texts = []
      let sentenceIdx = 0
      let wordIdx = 0
      this.element.paragraphs.forEach(paragraph => {
        let para = []
        paragraph.forEach((block,index) => {
          if (block["content"]!=undefined) {
            const wordList = parseWordList(block);
            wordList.forEach((word)=>{
              this.blocks.push({ word: word, index:sentenceIdx})
              para.push({ word: word, index:sentenceIdx, wordIdx})
              wordIdx++
            })
            sentenceIdx++
            this.isSelected.push(false);
            this.element.texts.push(block)
          }
        })
        this.paragraphs.push(para)
      })
      return
    }

    this.element.texts.forEach((block, index)=>{
      if (block["content"]!=undefined) {
        const wordList = parseWordList(block);
        wordList.forEach((word)=>{
          this.blocks.push({ word: word, index, isDisabled: block.isDisabled})
        })
        this.isSelected.push(false);
      }
    })
  }

  showMaxSelectedMsg;
  toggleAnswer(index:number, isDisabled:boolean = false) {
    if (isDisabled){
      return
    }
    if (this.isSelected[index]) {
      this.showMaxSelectedMsg = false;
      this.isSelected[index]=false;
    }
    else {
      let canSelect = true;
      if (this.element.isSelectionsLimited) {
        if (this.element.maxSelections > 1) {
          let count = 0;
          this.isSelected.forEach((selection)=>{
            if (selection) {
              count++;
            }
          })
          if (count>=this.element.maxSelections) {
            canSelect = false;
          }
        } else if (this.element.maxSelections == 1) {
          for (let i = 0;i<this.isSelected.length;i++) {
            this.isSelected[i] = false;
          }
        }
        
      }
      if (canSelect ==false) {
        this.showMaxSelectedMsg = true;
        return;
      }
      this.isSelected[index]=true; 
    }
    this.updateState();
  }

  getMaxMsgText() {
    if (this.lang.getCurrentLanguage()=="en") {
      return "You have made the maximum number of selections.\nPlease unselect one to make a new selection."
    } else {
      return "Vous avez effectué le nombre maximum de sélections.\nVeuillez en désélectionner un pour faire une nouvelle sélection."
    }
  }

  getClickToDismissMsg() {
    return this.lang.tra('btn_click_dismiss');
    // if (this.lang.getCurrentLanguage()=="en") {
    //   return "Click to dismiss"
    // } else {
    //   return "Cliquez pour ignorer"
    // }
  }

  addBorder(style:any, border:string, block:SelectableWord, index:number) {
    style["border-top"] = border;
    style["border-bottom"] = border;

    if (index!=0 && block.index == this.blocks[index-1].index) {
      style["border-left"]="none"
    } else {
      style["border-left"]=border;
    }

    if (index < this.blocks.length-1 && this.blocks[index].index == this.blocks[index+1].index ) {
      style["border-right"]="none";
    } else {
      style["border-right"]=border;
    }
  }

  getWordStyle(block:SelectableWord, index:number) {
    let style:any = {};
    // if (this.isSelected[block.index]) style["background-color"]="rgba(255,255,0, 0.4)";

    if (this.element.hasOutline) {
      if (!this.isSelected[block.index]) {
        this.addBorder(style, "2px dotted black", block, index)
      }
      else {
        this.addBorder(style, "2px dotted red", block, index)
      }
    }
    style["min-height"]="1.5em"
    return style;
  }

  getFormattedWord(word: string) {
    const NBSP = '&nbsp;'
    if (this.element.isSpaceBetweenDisabled){
      return word.split(' ').join(NBSP)
    }
    else {
      return word+NBSP
    }
  }

  ensureState() {
    if (this.questionState){
        const entryId = this.element.entryId;
        if (!this.questionState[entryId]){
            let entryState:IEntryStateMcq = {
            type: 'mcq',
            isCorrect: false,
            isStarted: false,
            isFilled: false,
            isResponded: false,
            selections: [],
            alreadyScrambled: true,
            score: 0,
            weight: getElementWeight(this.element),
            scoring_type: ScoringTypes.AUTO, 
            }
            this.questionState[entryId] = entryState;
        }
    }
  }

  updateState() {
    let isCorrect = true;
    let isStarted = false;
    let isFilled = false;
    let weight = getElementWeight(this.element);
    let score = this.isSelected.length;

    let selections = []
    this.isSelected.forEach((isSelect, index)=>{
      if (isSelect) {
        isStarted = true;
        isFilled = true;
        const option = this.element.texts[index];
        selections.push({i: index, id: option.optionId, elementType: ElementType.TEXT, content: option.content})
      }

      if ((isSelect && !this.element.texts[index].isCorrect) || (!isSelect && this.element.texts[index].isCorrect)) {
        isCorrect = false;
        score--;
      }
    })
    
    if (score === this.element.texts.length){
      score = weight
    }
    else {
      score = 0;
    }
    
    let isResponded =  this.questionState[this.element.entryId].isResponded || isFilled;
    
    // console.log(score)
    let entryState:IEntryStateMcq = {
      type: 'mcq',
      isCorrect,
      isStarted,
      isFilled,
      isResponded,
      selections,
      alreadyScrambled: false,
      score,
      weight,
      scoring_type: SCORING_TYPE, 
    }

    if (this.questionState){
      this.questionState[this.element.entryId] = entryState
    }
  }

  getStyle(idx?) {
    let style = {};
    if (this.element.width) {
      style['width']=this.element.width+'em';
      style['max-width']=this.element.width+'em';
      style['min-width']=this.element.width+'em';
      if(this.element.isParagraphMode){
        style['margin-bottom.em'] =  idx == this.paragraphs.length - 1 ? 0 : this.element.spaceAfterParagrapgh
      }
    }
    return style;
  }

  //** When in authoring and have highlight-comment activated, allow clicking on a block to select it for highlight */
  allowBlockPtrEvents(){
    return (this.highlighter.isHighlightCommentButtonActive);
  }
}
