import { IContentElementCanvas } from "../element-render-canvas/model";
import { DndTargetType, IContentElementDndDraggable, IContentElementDndSub, IDndGroup } from "../element-render-dnd/model";
import { IContentElementImage } from "../element-render-image/model";
import { IContentElementText } from "../element-render-text/model";
import { IContentElement, IContentElementColorPanel, IEntryStateScored, IScoredResponse, IVoiceover, OrderCombination } from "../models";

export interface IContentElementGroup extends IContentElement, IScoredResponse {
    // backgroundElements: Array<IContentElementDndBackground>;
    arrangementType?: GroupingArrangementType;
    correctCombinations?: GroupingCombination[];
    correctOrders?: OrderCombination[];
    customTargetDim?: boolean;
    defaultTargetStyle: IContentElementDndSub;
    delimiter?: string;
    delimiterIcon?: string;
    delimiterImg?: IContentElementImage;
    delimiterType?: string;
    dragContainerWidth?: number;
    draggableColourScheme?: IContentElementColorPanel;
    draggableCounter: number;
    draggableHeight?: number;
    draggables: Array<IContentElementDndDraggable>;
    draggableWidth?: number;
    groups: IDndGroup[];
    groupSize?: number;
    groupWidth?: number;
    height?: number;
    isDragContainerWidthSet?: boolean;
    isDraggableContainerGone?: boolean;
    isGroupContainerNoWrap?: boolean;
    isGroupSizeLimited?: boolean;
    isInstructionsDisabled: boolean;
    isManualDraggableOrientationHorizontal?: boolean;
    isMatchingMode: boolean;
    isNotPadded?: boolean;
    isOptionsReusable?: boolean;
    isPartialWeightsEnabled?: boolean;
    isRenderDelimiter?: boolean;
    isShowDelimiter?: boolean;
    isShowSeparator?: boolean;
    isShowSeparatorInRenderHidden?: boolean;
    isTargetListHorizontal?: boolean;
    isTargetsAbove?: boolean;
    isTargetsContentsCentred?: boolean;
    isTargetsOrdered?: boolean;
    isTargetsSame?: boolean;
    isVerticalLayout: boolean;
    perGroupCheck?: boolean;
    removeContainerBgBorder?: boolean;
    removeDraggableBgBorder?: boolean;
    reqMinimumPlacedTarget?: number;
    separatorCanvas?: IContentElementCanvas;
    separatorImage?: IContentElementImage;
    separatorText?: IContentElementText;
    separatorType?: string;
    sortDraggablesInGroups?: boolean;
    targetColourScheme?: IContentElementColorPanel;
    targetCounter: number;
    targetHeight?: number;
    targets: Array<IContentElementGroupTarget>;
    targetType: DndTargetType;
    targetWidth?: number;
    topAlignTargets?: boolean;
    width?: number;
    wrapInTargets?: boolean;
    targetVerticalSpacing?: number;
    draggableVerticalSpacing?: number;
    draggableTargetSpacing?: number;
    targetContainerWidth?: number
}
  
export interface IContentElementGroupTarget extends IContentElementDndSub {
  backgroundImg?: IContentElementImage;
  bgColor?: string;
  key_id?: string;
  draggableId?: number;
  element?: IContentElement;
  groupId?: number;
  groups?: any[];
  headerBGColour?: string;
  headerMinHeight?: number;
  headerTextColor?: string;
  voiceover?: IVoiceover;
}

export enum GroupingArrangementType {
  MANUAL = "manual",
  SIDE_BY_SIDE = "side",
  NORMAL = "normal",
  HORIZONTAL = "horizontal"
}

export interface GroupingCombination {
  id2amount: {}
}

export interface IEntryStateGroup extends IEntryStateScored {
  draggables: any[];
  targets: any[];
}

export const groupingEditInfo = {
  editExcludeFields: ['defaultTargetStyle', 'draggables', 'targets', 'separatorText', 'separatorImage', 'separatorCanvas', 'delimiterImg', 'draggableColourScheme', 'targetColourScheme'],
  editKeyFieldsToShow: [null],
  isNoKeyFieldsOverride: true
}

