import { Injectable, OnDestroy } from '@angular/core';
import { LangService } from '../core/lang.service';
import { Router } from '@angular/router';
import { StudentDashboardView } from './view-student-g9-dashboard/view-student-g9-dashboard.component';
import { AuthService } from '../api/auth.service';
import { RoutesService } from '../api/routes.service';
import { StudentG9ConnectionService } from './student-g9-connection.service';
import { OnlineOrPaperService } from '../ui-testrunner/online-or-paper.service';
import { G9DemoDataService } from '../ui-schooladmin/g9-demo-data.service';

export interface AssessmentSettings {
  id: number,
  assessment_def_id: number,
  assessment_slug: string,
  settings_type_slug: string,
  is_payment_required: number, // 0 or 1
  is_insecure: number,         // 0 or 1
  is_softlock_enabled?: number, // 0 or 1
  tech_readi_config: {},
  created_by_uid: number,
  created_on: string,
  updated_by_uid: number,
  updated_on: string,
}

@Injectable({
  providedIn: 'root'
})
export class StudentG9DashboardService implements OnDestroy {

  constructor(
    private lang: LangService,
    private router: Router,
    private auth: AuthService,
    private routes: RoutesService,
    private studentG9Connection: StudentG9ConnectionService,
    private onlineOrPaper: OnlineOrPaperService,
    private g9DemoData: G9DemoDataService
  ) {
  }

  userSub;
  private schoolSoftLockEnable: boolean = false
  private schoolIsInsecure: boolean = false

  init() {
    if(!this.userSub) {
      this.userSub = this.auth.user().subscribe(async (info) => {
        await this.studentG9Connection.onUserInfoChange(info);
      });
    }
  }


  ngOnDestroy() {
    if(this.userSub) {
      this.userSub.unsubscribe();
    }
  }

  goToDashboardView = (view: StudentDashboardView, isOsslt?: boolean) => {
    const baseURL = `${this.lang.c()}/student/dashboard`;
    this.router.navigate([`${baseURL}/${view}`]);
  }


  goToWaitingRoom = () => {
    this.router.navigate([`${this.lang.c()}/student/waiting-room`]);
  }

  configureQueryParams(allow_inactive_subsession?: boolean) {
    if (this.auth.u()) {
      return {
        query: {
          schl_class_group_id: this.auth.u().sch_class_group_id,
          allow_inactive_subsession: allow_inactive_subsession
        }
      }
    }
    return null;
  }

  async loadAssessments(allow_inactive_subsession?: boolean){
    return this.auth
      .apiFind(this.routes.STUDENT_SESSION, this.configureQueryParams(allow_inactive_subsession))
  }

  async loadAttempt(test_session_id, route:string = this.routes.STUDENT_SESSION){
    const sch_class_group_id = this.auth.u()?.sch_class_group_id;
    if(!sch_class_group_id) {
      return Promise.resolve();
    }

    const attemptInfo = await this.auth
      .apiGet(route, test_session_id, {query:{KIOSK_PASSWORD: this.auth.getKioskPassword(), schl_class_group_id: sch_class_group_id}}).then((res) => {
        this.onlineOrPaper.setIsPaper(res[0].IsCrScanDefault, this.g9DemoData.asmtSlugToCurricShort(res[0].asmt_slug))
        return res;
    });
    this.schoolSoftLockEnable = (attemptInfo[0].is_soft_lock_disabled == 0)

    // todo: why isnt this pulled in with the attempt payload?
    await this.auth.apiGet(this.routes.SCHOOL_ADMIN_SCHOOL_PROFILE, this.auth.u().uid, {
      query: {
        schl_group_id: sch_class_group_id,
      }
    })
    .then((school_assessments: AssessmentSettings[]) => {
      const current_assessment_settings = school_assessments.find(asmt => attemptInfo[0].asmt_slug.includes(asmt.assessment_slug));
      console.log('current_assessment_settings', current_assessment_settings)
      if (current_assessment_settings) {
        this.schoolIsInsecure = !!current_assessment_settings.is_insecure;
      }
    });

    return attemptInfo;
  }

  getSchoolSoftLockEnable(){
    return this.schoolSoftLockEnable
  }

  getSchoolIsInsecure() {
    return this.schoolIsInsecure;
  }

  goToCoverPageResource = (lang: string, resource_td_id: number, queryParams?: any) => {
    this.router.navigate([`${lang}/test-auth/shared-test-version/643/${resource_td_id}`], { queryParams });
    return
  }

}
