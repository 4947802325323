import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { LoginGuardService } from '../../api/login-guard.service';
import { LangService } from '../../core/lang.service';
import { StyleprofileService } from '../../core/styleprofile.service';
import { ImageStates } from '../element-render-image/model';
import { McqRenderer } from '../element-render-mcq/element-render-mcq-functions';
import { IContentElementMcqOption } from '../element-render-mcq/model';
import { ElementType, QuestionState } from '../models';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { TextToSpeechService } from '../text-to-speech.service';
import { IContentElementCustomMCQ } from './model';
import { EditSelectionService } from '../edit-selection.service';


@Component({
  selector: 'element-render-custom-mcq',
  templateUrl: './element-render-custom-mcq.component.html',
  styleUrls: ['./element-render-custom-mcq.component.scss']
})
export class ElementRenderCustomMCQComponent implements OnInit {

    @Input() element:IContentElementCustomMCQ;
    @Input() isLocked:boolean;
    @Input() isShowSolution:boolean;
    @Input() questionState:QuestionState;
    @Input() questionPubSub?: QuestionPubSub;

  mcqRenderer;

  getMcqContent = (selection)=>{
    let content = selection.dynamicImage.images[ImageStates.SELECTED];
    if (!content || !content.image) {
      content = selection.dynamicImage.images[ImageStates.DEFAULT];
    }
    let url = "";
    if (content && content.image && content.image.url) {
      url = content.image.url;
    }
    return url;
  }

  constructor(
    private textToSpeech:TextToSpeechService,
    private profile: StyleprofileService,
    private lang: LangService,
    private loginGuard: LoginGuardService,
    public editSelection: EditSelectionService
  ) {
    
  }

  ElementType = ElementType;

  currentSelections:IContentElementMcqOption[] = [];
  isStarted = false;

  ngOnInit() {
    this.ensureMcqRenderer();
    this.ensureState();
  }

  ensureMcqRenderer(){
    if (!this.mcqRenderer){
      this.mcqRenderer = new McqRenderer(
        this.element,
        this.questionState,
        this.getMcqContent,
        this.isShowSolution,
        this.isLocked,
        this.textToSpeech,
        this.profile,
        this.loginGuard,
        this.lang,
        null,
      );
    }
  }

  ngOnChanges(changes:SimpleChanges) {
    this.ensureMcqRenderer();
    this.mcqRenderer.checkForChanges(changes)
  }

  isSelected(option: IContentElementMcqOption) {
    return this.mcqRenderer.isSelected(option);
  }

  selectOptionManual(option:IContentElementMcqOption){
    return this.mcqRenderer.selectOptionManual(option); 
  }

  selectOption(option:IContentElementMcqOption) {
    return this.mcqRenderer.selectOption(option);
  }

  ensureState(){
    return this.mcqRenderer.ensureState();
  }

  handleNewState(){
     this.mcqRenderer.handleNewState();
  }

  getIsMaxMsgShowing() {
    return this.mcqRenderer.isMaxAnswerMsgShown;
  }
  
  getMaxMsgSlug() {
    return this.mcqRenderer.getMaxMsgSlug();
  }

  getClickToDismissMsg() {
    return this.mcqRenderer.getClickToDismissMsg();
  }

  turnMaxMsgOff() {
    this.mcqRenderer.turnMaxMsgOff();
  }

  updateState(){

    // console.log('updateState mcq -----')
    this.mcqRenderer.updateState();
  }

}