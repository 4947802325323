import * as PIXI from "pixi.js-legacy";

interface IPIXISpriteAssets {
  name: any;
  path: string;
}

export class SpriteLoader {
  loader: PIXI.Loader;
  constructor() {
    this.loader = new PIXI.Loader();
    this.loader.onStart.add(this.started); // Called when a resource starts loading.
    this.loader.onError.add(this.errored); // Called when a resource fails to load.
    this.loader.onLoad.add(() => {}); // Called when a resource successfully loads.
    this.loader.onProgress.add(this.loaderProgress); // Called when a resource finishes loading (success or fail).
    this.loader.onComplete.add(this.doneLoading); // Called when all resources have finished loading.
  }

  addSpritestoLoader = (assets: IPIXISpriteAssets[]) => {
    assets.forEach(asset => {
      if (!this.loader.resources[asset.name] && !this.isSpriteAlreadyInCache(asset)) {
        let { name, path } = asset;
        this.loader.add(name, path, { crossOrigin: true });
      }
    });
  };

  /**
   * Method to determine if the PIXI texture cache already contains a texture with the same id
   * @param asset the asset to be added to PIXI texture.
   * @returns Ture if texture is already in cache. False if not.
   */
  isSpriteAlreadyInCache(asset: IPIXISpriteAssets){
    if (PIXI.utils.TextureCache[asset.name]){
      return true;
    } 
    this.loader.loading
    return false;
  }

  loadSprites = (): Promise<PIXI.Loader> => new Promise(resolve => this.loader.load(resolve))
  started = () => {};
  errored = e => console.error("load Error", e);
  loaderProgress = () => {};
  doneLoading = () => {}//console.info("Resources loaded");
}
