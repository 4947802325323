import { Component, OnInit, Input, SimpleChanges, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { IContentElement, QuestionState, IEntryStateMic, ScoringTypes, getElementWeight, IContentElementMic } from '../models';
import { isOldFirefoxBrowser } from '../services/util'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from '../../api/auth.service';
import { Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import Recorder from 'recorder-js';
import { DataGuardService } from '../../core/data-guard.service';
import { TextToSpeechService } from '../text-to-speech.service';
import { LangService } from 'src/app/core/lang.service';
import { LoginGuardService } from 'src/app/api/login-guard.service';

//const audioContext =  new (window.AudioContext || window['webkitAudioContext'])();
const SCORING_TYPE = ScoringTypes.MANUAL;
declare var MediaRecorder: any;
declare var WaveSurfer: any;

export const timeLeadingZero = (num:number) => {
  let str = ''+num;
  if (!str){
    str = '00';
  }
  if (str.length < 2){
    str = '0'+str;
  }
  return str.substr(0, 2)
}
@Component({
  selector: 'element-render-mic',
  templateUrl: './element-render-mic.component.html',
  styleUrls: ['./element-render-mic.component.scss']
})
export class ElementRenderMicComponent implements OnInit, OnDestroy {

  @Input() element:IContentElementMic;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() changeCounter:number;
  @Input() isCountdownEnabled:number;
  @Input() questionPubSub?: QuestionPubSub;
  isPlaying: boolean;

  
  constructor(
    private sanitizer: DomSanitizer,
    private auth: AuthService,
    private dataGuard: DataGuardService,
    private text2Speech: TextToSpeechService,
    public lang: LangService,
    private loginGuard: LoginGuardService,
  ) { }
  
  capture:Partial<IEntryStateMic> = {};
  time:number;
  isRecordingSim:boolean;
  isRecording:boolean;
  
  timer;
  private wavesurfer:any;
  
  warningForAuto = {
    url: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/beep/1613053268449/beep.mp3',
  }
  standardBeep = {
    url: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/beep/1613495776328/beep.mp3',
  }
  msg = 'Prêt';
  hasMicStarted:boolean;
  hasRecordingStored:boolean;
  private mediaRecorder:any;
  currentRecordingTime: string;
  audioUrl: SafeResourceUrl;
  recordingStart:number;
  recordingTimerInterval:any;
  isCountdownInProgress:boolean;
  audioTrigger = new Subject();
  warningForAutoTrigger = new Subject();
  standardBeepTrigger = new Subject();
  isDeviceError:boolean;
  isRecorded: boolean;
  isTransferred: boolean;
  isFinalCountdown: boolean;
  blobData: Blob;
  blobDataURI: string;

  ngOnInit() {
    this.ensureState(false, true);
    this.resetTimer();
    this.iPadCheck();
  }

  ngOnDestroy(){
    if (this.wavesurfer && this.wavesurfer.microphone) {
      this.wavesurfer.microphone.destroy();
    }
    try { clearInterval(this.recordingTimerInterval) }catch(e){}
    try { clearInterval(this.timer) }catch(e){}
  }

  initWaveForm(){
    const WaveSurfer = window['WaveSurfer'];
    if (!WaveSurfer.microphone){
      WaveSurfer.microphone = window['Microphone']; 
    }
    this.wavesurfer = WaveSurfer.create({
      container     : '#waveform',
      waveColor     : 'black',
      height        : 40,
      barHeight     : 3,
      interact      : false,
      cursorWidth   : 0,
      hideScrollbar : true,
      plugins: [
        WaveSurfer.microphone.create()
      ]
    }); 
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.changeCounter){
      this.resetTimer();
    }
  }

  
  stopRec(){
    if (this.isRecorded || !this.isRecording){
      return;
    }
    this.isRecording = false;
    this.isRecordingSim = false;
    this.isRecorded = true;
    this.hasRecordingStored = false;
    this.msg = 'Terminé';
    this.isRecordingPaused = false;
    if (this.isChrome()){
      this.stopRecChrome()
    }
    else{
      this.stopRecSimple()
      // this.stopRecOther()
    }
    
    if (this.wavesurfer && this.wavesurfer.microphone){
      this.wavesurfer.microphone.pause();
    }

    const caption = this.lang.tra('recording_need_to_listen_popup_first')
    const caption_beneath_button = this.lang.tra('recording_popup_contact_support_first')
    if(this.element.mustListenBeforeUpload){
      this.hasListenedToRecording = false;
      this.loginGuard.confirmationReqActivate({
        caption,
        hideCancel: true,
        btnProceedCaption: this.lang.tra('btn_alert_bc_cannot_proceed_confirm'),
        // caption_beneath_button: caption_beneath_button,
        confirm: () => {}
      })
    }
  }

  stopRecChrome(){
    this.mediaRecorder.stop();
  }
  stopRecSimple(){
    this.triggerStopRec.next(true)
    if (this.element.enablePeriodicSave){
      clearInterval(this.perioridSaveInterval)
    }
  }
  stopRecOther(){
    console.log("stopButton clicked");

    //tell the recorder to stop the recording
    this.recorder.stop();

    //stop microphone access
    this.gumStream.getAudioTracks()[0].stop();

    //create the wav blob and pass it on to createDownloadLink
    this.recorder.exportWAV(blob => this.onRecorded(blob));
  }

  onRecorded(blob) {
    // const url = (window.URL || window.webkitURL).createObjectURL(blob);
    // this.capture.url = url;
    this.blobToWavDataURI(blob);
    // this.updateAudioUrl();
    this.isUploading = false;
    this.upgradeToRemoteLink(blob)
    return;
  }

  invalidRecorededAudio = false;
  upgradeToRemoteLink(wavDataUri:Blob){
    // console.log('upgradeToRemoteLink', wavDataUri)
    // console.log(wavDataUri)
    // var processorUrl = "https://wkowskfxl8.execute-api.ca-central-1.amazonaws.com/transcodeToMp3";
    // var xhr = new XMLHttpRequest();
    // xhr.open("POST", processorUrl);
    // xhr.setRequestHeader("Content-Type", "application/json");
    // xhr.onreadystatechange =  () => {
    //    if (xhr.readyState === 4) {
    //       console.log(xhr.status);
    //       // console.log(xhr.responseText);
    //       this.capture.url = xhr.responseText;
    //       // this.capture['urlBackup'] = xhr.responseText;
    //       this.updateAudioUrl();
    //       this.confirmUpload();
    //       this.isSaving = false;
    //    }};
    const filename = 'rec'+(new Date()).valueOf()+Math.random()+'.wav';
    // var data = JSON.stringify({filename, wavDataUri})
    // xhr.send(data);

    if (!wavDataUri){
      alert(this.lang.tra('alert_bc_voice_upload_failed'));
      this.invalidRecorededAudio = true;
      this.isUploadErrored = true;
      this.isRecorded = false;
      this.isSaving = false;
      return
    }
    this.invalidRecorededAudio = false;
    const file = new File([wavDataUri], filename);
      this.auth
        .uploadFile(file, file.name, 'voice', true)
        .then(res => {
          console.log(res.url)
          this.capture.url = res.url;
          // this.updateAudioUrl();
          this.confirmUpload();
          this.isSaving = false;
          this.invalidRecorededAudio = false;
          this.isUploadErrored = false;
          // Once audio uploaded Force save the answer to db so they cannot re-record
          this.dataGuard.triggerForceSave();

          URL.revokeObjectURL(this.blobDataURI)
          this.blobDataURI = this.capture.url;
          this.blobData = null;
          this.updateAudioUrl();

        }).catch((e) => {
          console.log("ERR_RECORDED_VOICE_UPLOAD", e)
          this.isUploadErrored = true;
          this.isSaving = false;
          if(e.statusText == 'Unknown Error' && !this.loginGuard.getDevNetOnlineStatus()){this.showOfflinePopup();}
        })
  }

  showOfflinePopup(){
    const caption = 'offline_warning';
    this.loginGuard.confirmationReqActivate({
      caption,
      hideCancel: true,
      btnProceedCaption: this.lang.tra('btn_dismiss_notif'),
      confirm: () => {
        this.saveRec();
      }
    })
  }

  iPadCheck(){
    const userAgent = window.navigator.userAgent;
    if (userAgent.match(/iPad/i) && 
        userAgent.indexOf("Safari") === -1
    ){
      alert('Le navigateur que vous utilisez ne soutien pas la possibilité d\'enregistrer la voix. Veuillez utilisé Safari afin de compléter cette composante de l\'évaluation.')
    }
  }
  isChrome(){
    const userAgent = window.navigator.userAgent;
    return (userAgent.indexOf("Chrome") > -1 ||
           userAgent.indexOf("Edg") > -1) && // e is left out intentionally, this is how MSFt signals newer version
           userAgent.indexOf("Edge") === -1 // do not treat older versions of Edge like Chrome
  }
  
  isCountdownEarlyEnd:boolean;
  startRec(isAuto?:boolean){
    this.isPlaying = false;
    if (this.isFinalCountdown){
      return;
    }
    if (this.isCountdownInProgress){
      // this.isCountdownEarlyEnd = true;
      this.onCountdownTimerEnd();
    }
    if (this.isRecorded || this.isTransferred){
      return;
    }
    if (!this.isRecordingSim){
      this.standardBeepTrigger.next(true);
      let isConfirmed = true;
      this.capture.isStarted = true;
      // this.capture.isResponded = true;
      if (this.hasRecordingStored){
        isConfirmed = confirm('Cela effacera votre enregistrement précédent pour cette question et en commencera un nouveau. Continuer?')
      }
      if (isConfirmed){
        this.isRecordingSim = true;
        this.startRecording();
        
      }
    }
  }

  uploadRecording(){
    if (this.element.askTolistenBeforeUpload){
      const caption = this.lang.tra('recording_need_to_listen_popup')
      const caption_beneath_button = this.lang.tra('recording_popup_contact_support')
      let cancel = false;
      this.loginGuard.confirmationReqActivate({
        caption,
        hideCancel: false,
        btnProceedCaption: this.lang.tra('recording_popup_btn_yes'),
        btnCancelCaption: this.lang.tra('recording_popup_btn_no'),
        caption_beneath_button: caption_beneath_button,
        confirm: () => {this.saveRec();},
        close:() => {cancel = true}
      })
      if (cancel){
        return;
      }
    }
    else {
      this.saveRec();
    }
  }

  isSaving:boolean;
  saveRec(){
    if (this.isTransferred || this.isSaving){
      return;
    }
    this.isSaving = true;
    this.upgradeToRemoteLink(this.blobData)
    // this.dataGuard.triggerForceSave();
    // setTimeout(()=>{
    //   this.confirmUpload();
    //   this.isSaving = false;
    // }, 5000)
  }

  updateCurrentRecordingTime(){
    if (this.isRecordingSim && !this.isRecordingPaused){
      const now = (new Date()).valueOf();
      const seconds = Math.floor((now - this.recordingStart)/1000) - this.totalPauseDuration;
      const minutesRaw = seconds/60;
      const minutes = Math.floor(minutesRaw);
      const secondsDisplayed = Math.floor(seconds - minutes*60);
      this.currentRecordingTime = timeLeadingZero(minutes)+':'+timeLeadingZero(secondsDisplayed);
      if (this.element.maxDuration && minutesRaw >= this.element.maxDuration){
        this.stopRec();
      }
    }
  }

  currentPauseDuration = 0
  updatePausetime(){
    if (this.isRecordingSim && this.isRecordingPaused){
      const now = (new Date()).valueOf();
      this.currentPauseDuration = Math.floor((now - this.pauseStart)/1000);
      // console.log("Paused for: " + this.currentPauseDuration + " seconds")
    }
  }

  isWarningPlayed:boolean
  resetTimer() {
    const timerState = this.capture.time > 0 ? this.capture.time : 0
    this.time = timerState ? timerState : this.element.time;
    // console.log('resetTimer', this.capture)
    if (this.capture.url || this.capture.isFilled || this.blobDataURI){
      this.isCountdownInProgress = false;
      return;
    }
    if (this.timer) {
      clearInterval(this.timer)
    }
    if (!this.element.setTimer) return;
    this.isCountdownInProgress = true;
    this.timer = setInterval(
      () => {
        if (!this.isCountdownInProgress || this.isLocked){
          return;
        }
        if (this.time>0) {
          this.time--
        };
        if (!this.isWarningPlayed && Math.floor(this.time) < 11) {
          this.isFinalCountdown = true;
          this.warningForAutoTrigger.next(true);
          this.isWarningPlayed = true;
        }
        if (Math.floor(this.time) === 5) {
          const el = document.getElementById('microphone');
          el.scrollIntoView({behavior:'smooth'})
        }
        this.capture.time = this.time
        if (this.time<=0) {
          this.capture.time = 0
          this.onCountdownTimerEnd();
          setTimeout(()=>{
            this.startRec(true);
          }, 1000);
        } 
      },
      1000
    );
  }

  onCountdownTimerEnd(isFastForward?:boolean){
    if (this.isCountdownInProgress || isFastForward){
      this.isFinalCountdown = false;
      this.isCountdownInProgress = false;
      if (this.element.onCountdownEnd){
        this.element.onCountdownEnd.forEach(pub => {
          this.questionPubSub.elementPub(+pub.entryId, pub.type, pub.data);
        })
      }
    }
  }

  playRecording(){
    this.msg = 'Écoute...';
    this.audioTrigger.next(true);
    this.isPlaying = true;
  }

  startRecording = () => {
    if (this.isRecording){
      return;
    }
    this.isRecording = true;
    if (this.isChrome()){
      this.startRecordingChrome();
    }
    else{
      this.startRecordingSimple();
    }
    // this.notifyRecordingStart.emit();
  };  
  
  startRecordingChrome(){
    // if (!this.isRecorderInited){
      navigator
        .mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then(this.startStreamChrome)
        .catch(e =>{
          //enable the record button if getUserMedia() fails
           console.log(e)
           this.onDeviceError(true)
          })
    // }
  }

  //stream from getUserMedia()
  gumStream = null;

  //MediaStreamAudioSourceNode we'll be recording	
  input = null;

  //audio context to help us record
  audioContext = null

  //Recorder.js object				
  recorder: any;


  isRecorderInitializing:boolean;


  triggerStartRec:Subject<boolean> = new Subject();
  triggerStopRec: Subject<boolean> = new Subject();
  triggerPauseRec: Subject<boolean> = new Subject();
  triggerResumeRec: Subject<boolean> = new Subject();
  triggerPeriodicSave: Subject<boolean> = new Subject();
  perioridSaveInterval: any;
  startRecordingSimple(){
    this.isRecording = true;
    this.isRecordingSim = true;
    // this.isRecorderInitializing = true;
    this.triggerStartRec.next(true);
    this.isRecorderInitializing = true;
    setTimeout(()=>{
      this.isRecorderInitializing = false;
      this.trackRecordingStart();
    }, 1000)

    if (this.element.enablePeriodicSave && this.element.saveInterval){
      this.perioridSaveInterval = setInterval(this.triggerPeriodicSaveSimple.bind(this), this.element.saveInterval*1000)
    }
  }

  startRecordingOther(){
    this.isRecording = false;
    this.isRecordingSim = false;
    this.isRecorderInitializing = true;
    const constraints = { audio: true, video: false }

    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      return;
      this.isRecording = true;
      this.isRecordingSim = true;

      this.trackRecordingStart();

      // console.log("getUserMedia() success, stream created, initializing Recorder.js ...");

      this.onDeviceError(false);
      // create an audio context after getUserMedia is called
      // sampleRate might change after getUserMedia is called, like it does on macOS when recording through AirPods
      // the sampleRate defaults to the one set in your OS for your playback device
      this.audioContext = new (window.AudioContext || window['webkitAudioContext'])();

      // assign to gumStream for later use 
      this.gumStream = stream;

      // use the stream
      this.input = this.audioContext.createMediaStreamSource(stream);

      // Create the Recorder object and configure to record mono sound (1 channel)
      // Recording 2 channels will double the file size
      this.recorder = new window['Recorder'](this.input, { numChannels: 1 })

      // start the recording process
      this.recorder.record()

      setTimeout(()=>{ this.isRecorderInitializing = false; }, 800)

      console.log("Recording started");
      this.isRecorderInited = true

      // this.initWaveForm();
      // this.wavesurfer.microphone.start();

    }).catch((err) => {
      //enable the record button if getUserMedia() fails
      this.onDeviceError(true)
    });

  }
  
  isUploadErrored:boolean = false
  confirmUpload(){
    if (this.capture && !this.isUploading){
      if (this.capture.url){
        this.capture.isFilled = true;
        this.isTransferred = true;
        this.capture.isResponded = true;
        alert('Votre enregistrement a été envoyé.');
        // reset
      } else {
        this.capture.isResponded = false;
      }
    }
    else{
      // alert('ERREUR !')
      this.isUploadErrored = true;
    }
  }

  resetRecording(){
    if (this.element.isPractice){
      this.isRecorded = false;
      this.hasRecordingStored = false;
      this.isTransferred = false;
      this.ensureState();
      if (this.wavesurfer && this.wavesurfer.microphone) {
        this.wavesurfer.microphone.destroy();
      }
      this.isRecorderInited = false;
    }

    if (this.element.enablePause){
      this.currentPauseDuration = 0;
      this.totalPauseDuration = 0;
    }

    if (this.element.enablePeriodicSave){
      this.upgradeChunkIndex = 0
      this.periodicSaveChunks = new Map()
      this.isUploadChunkErrored = false;
    }

    URL.revokeObjectURL(this.blobDataURI)
    this.blobData = null;
  }

  onDeviceError(reset){
    if(!reset) {
      this.isDeviceError = false
      return;
    }
    this.isDeviceError = true
    this.isRecording = false;
    this.isRecordingSim = false;
    this.capture.isStarted = false;
  }


  ensureState(forceReset:boolean=false, isFirst?:boolean){
    if (this.questionState){
      // console.log('mic entry id', this.element.entryId)
      const entryId = this.element.entryId;
      if (!this.questionState[entryId] || forceReset){
        let entryState:IEntryStateMic = {
          type: 'mic',
          isCorrect: null,
          isStarted: false,
          isFilled: false,
          isResponded: false,
          url: '',
          time: this.time,
          score: 0,
          weight: getElementWeight(this.element),
          scoring_type: SCORING_TYPE, 
        }
        this.questionState[entryId] = entryState;
      }
      this.capture = this.questionState[entryId];
      if (isFirst && this.capture.url){
        this.isRecorded = true;
        this.isTransferred = true;
        this.capture.isFilled = true;
        this.capture.isResponded = true;
        this.onCountdownTimerEnd(true);
      }
    }
  }



  voiceCaptureStarted() {
    this.isRecording = true;
    clearInterval(this.timer)
    this.time = 0;
    this.capture.isResponded = true;
  }

  updateAudioUrl(){
    if (this.element && this.blobDataURI){
      this.audioUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.blobDataURI);
    }
    else{
      this.audioUrl = null;
    }
    // this.urlUpdate.emit({url: this.element.url});
  }

  onUrlGenerated (blob:Blob){
    this.blobData = undefined ?? blob;
    this.blobToWavDataURI(blob);
  }

  trackRecordingStart(){
    this.recordingStart = (new Date()).valueOf()
    this.msg = 'Enregistrement';
    this.updateCurrentRecordingTime();
    this.recordingTimerInterval = setInterval(() => {
      this.updateCurrentRecordingTime();
    }, 500)
  }


  isUploading:boolean;
  audioSource:any;
  isRecorderInited:boolean;
  startStreamChrome = (stream:MediaStream) => {
    this.onDeviceError(false);
    // console.log('startStream', stream)
    
    if (!this.isRecorderInited){
      this.initWaveForm();
      this.wavesurfer.microphone.start();
      this.isRecorderInited = true;
    }
    this.wavesurfer.microphone.play();
    
    const options = {mimeType: 'audio/webm', audioBitsPerSecond:1024000};
    const recordedChunks = [];
    if (this.mediaRecorder){
      this.mediaRecorder.stop;
    }
    this.mediaRecorder = new MediaRecorder(stream, options);
    this.mediaRecorder.addEventListener('dataavailable', (e) => {
      if (e.data.size > 0) {
        recordedChunks.push(e.data);

        if (this.element.enablePeriodicSave){
          const blobData = new Blob(recordedChunks, { 'type' : 'audio/webm' });
          this.onPeriodicSaveDataAvailable(blobData)
        }
      }
    });
    this.mediaRecorder.addEventListener('stop', () => {
      // this.isUploading = true;
      // const blob = new Blob(recordedChunks, { 'type' : 'audio/webm' });
      // this.blobToWavDataURI(blob);

      if(this.element.enablePeriodicSave){this.upgradeChunkIndex = 0;}

      this.blobData = new Blob(recordedChunks, { 'type' : 'audio/webm' });
      this.blobToWavDataURI(this.blobData);
    });

    if(this.element.enablePeriodicSave){
      this.mediaRecorder.start(this.element.saveInterval * 1000);
    } else {
      this.mediaRecorder.start();
    }
    
    this.trackRecordingStart();

    this.isRecorderInited = true;

  };

  blobToWavDataURI = (blob: Blob) => {
    // this.blobData = blob;
    // // locally store
    // const reader = new FileReader();
    // reader.onload = (e) => {
    //     this.blobDataURI = <string> e.target.result;
    //     this.updateAudioUrl();
    //     this.isUploading = false;
    // };
    // reader.readAsDataURL(blob);

    this.blobDataURI = URL.createObjectURL(blob)
    this.updateAudioUrl();
    this.isUploading = false;
  }


  startStreamOther = (stream:MediaStream) => {
    this.onDeviceError(false);
    this.isRecorderInited = true;
    // console.log('startStream', stream)

    this.recorder.init(stream).then(()=>{
      // console.log('recorder inited')
      this.recorder.start()
        .then(()=> {} )
        .catch(()=> {
          this.isDeviceError = false;
          console.error('failed to start recording') 
        })
      this.initWaveForm();
      this.wavesurfer.microphone.start();
      this.trackRecordingStart();
    })
    .catch(e => {
      console.error('Failed to init recording', e)
    });
  };

  isShowAudioInfoPanel = () => {

    return (this.isRecordingSim || this.isRecorded) && (!this.isTransferred || this.element.isPractice || this.element.allowReplayAfterUpload) && !this.isDeviceError
  }

  isInitialState = () => {
    return !this.isRecordingSim && !this.isRecorded
  }

  isRecordingState = () => {
    return this.isRecordingSim
  }

  hasListenedToRecording = false
  donePlaying(evt) {
    this.msg = 'Terminé';
    this.isPlaying = false;
    if(this.element.mustListenBeforeUpload) {this.hasListenedToRecording = true;}
  }

  getSavingState = () => {
    if ((!this.isSaving && !this.isTransferred) || this.isUploadErrored) return "save"
    if (this.isSaving) return "saving"
    if (!this.isSaving && this.isTransferred) return "uploaded"
  }

  isTimeDisplayed = () => {
    return !this.isResetButtonShown() && (this.isRecordingSim || this.isRecorded) && (!this.isTransferred || this.element.allowReplayAfterUpload)
  }

  isResetButtonShown = () => {
    return this.element.isPractice && this.isRecorded
  }

  progressBarWidthEm = 10;
  progressBarHeightEm = 1;
  borderThicknessEm = 0.1;
  barColor = "#3298DC"
  getProgressBarFillEm() {
    const style = {};
    const fillWidth = (this.progressBarWidthEm - this.borderThicknessEm*2)
    const fillHeight = (this.progressBarHeightEm - this.borderThicknessEm*2)
    style["height"] = fillHeight + "em"
    style["width"] = fillWidth*this.time/this.element.time + "em"
    style["background-color"]=this.barColor
    return style;
  }

  getProgressBarBorder() {
    const style = {};
    style["height"] = this.progressBarHeightEm +"em";
    style["width"] = this.progressBarWidthEm +"em";
    style["border"] = this.borderThicknessEm +"em";
    style["border-style"]="solid";
    style["border-color"]=this.barColor
    return style;
  }

  isOldBrowser() {
    return isOldFirefoxBrowser()
  }

  isHighContrast() {
    return this.text2Speech.isHiContrast
  }

  getCountdownProgressBarPosition() {
    if (this.element.absoluteCountdownProgressBarPosition){
      return 'absolute'
    }
    return 'relative'
  }

  getCountdownProgressBarTopMargin() {
    if (this.element.absoluteCountdownProgressBarPosition && this.element.countdownProgressBarTopMargin){
      return this.element.countdownProgressBarTopMargin
    }
    return 1;
  }

  canUploadRecording(){

    if (this.element.mustListenBeforeUpload){
      return this.isRecorded && this.hasListenedToRecording;
    }
    else {
      return this.isRecorded;
    }
  }

  upgradeChunkIndex = 0
  periodicSaveChunks = new Map<string, {blob: Blob, url: string}>()
  periodicSaveQueue = new Map()
  isUploadChunkErrored = false;
  upgradeChunkToRemoteLink(wavDataUri:Blob){
    const filename = 'rec_'+this.upgradeChunkIndex+'_'+(new Date()).valueOf()+Math.random()+'_part'+ this.upgradeChunkIndex+'.wav';
    this.upgradeChunkIndex = this.upgradeChunkIndex + 1;
    this.invalidRecorededAudio = false;
    const lastUploadKey = Array.from(this.periodicSaveChunks.keys()).pop();
    this.periodicSaveChunks.set(filename, {blob: wavDataUri, url: ''});

    // Check if the previous upload has completed or not, if the previous audio blob does not 
    // have a url yet, meaning the previous upload has not finishedm the just add the current 
    // blob to the map queue and return.
    if (lastUploadKey && this.periodicSaveChunks[lastUploadKey] && this.periodicSaveChunks[lastUploadKey].url == ''){
      return;
    } 

    this.uploadBlobFile(wavDataUri, filename);
  }

  uploadBlobFile(wavDataUri:Blob, filename){
    const file = new File([wavDataUri], filename);
    this.auth
      .uploadFile(file, file.name, 'voice', true)
      .then(res => {
        // Once audio uploaded Force save the answer to db so they cannot re-record
        this.dataGuard.triggerForceSave();
        this.periodicSaveChunks.set(filename, {blob: wavDataUri, url: res.url})
        this.capture.url = res.url;
        this.blobDataURI = res.url;
        this.capture.partialRecChunkUrls = [...this.periodicSaveChunks.entries()].map(element => [element[0], element[1].url]);

        // Once the current upload is successful, go through the map queus to see if there is
        // other audio blob pending to be uploaded.
        const blobsToBeUploaded = new Map([...this.periodicSaveChunks].filter(([k, v])=>v.url == ''))
        if (blobsToBeUploaded.size > 0){
          this.uploadBlobFile(blobsToBeUploaded.keys().next().value, blobsToBeUploaded.values().next().value.blob)
        }
      }).catch((e) => {
        this.isUploadChunkErrored = true;
        console.log("ERR_RECORDED_VOICE_UPLOAD", e)
        if(e.statusText == 'Unknown Error' && !this.loginGuard.getDevNetOnlineStatus()){this.showOfflinePopup();}
      })
  }

  triggerPeriodicSaveSimple(){
    this.triggerPeriodicSave.next(true);
  }

  onPeriodicSaveDataAvailable(blob:Blob){
    // console.log('pushed new blob')
    // console.log(blob)
      this.upgradeChunkToRemoteLink(blob);
  }

  //For the new pause feature

  // Display the pause button if recording is ongoing and pause is enabled.
  // The stop buttton will dispay when user has clicked the pause button
  displayPauseButton(){
    return this.element.enablePause && this.isRecordingState() && !this.isRecordingPaused
  }

  isRecordingPaused = false
  pauseInterval
  pauseStart
  totalPauseDuration = 0
  pauseRec(){
    this.isRecordingPaused = true;
    this.msg = 'Terminé';
    if (this.isChrome()){
      this.pauseRecChrome()
    }
    else{
      this.pauseRecSimple()
    }

    this.pauseStart = (new Date()).valueOf()
    this.pauseInterval = setInterval(this.updatePausetime.bind(this), 500)
    
    if (this.wavesurfer && this.wavesurfer.microphone){
      this.wavesurfer.microphone.pause();
    }
  }

  pauseRecChrome(){
    this.mediaRecorder.pause();
  }

  pauseRecSimple(){
    this.triggerPauseRec.next(true)
    if (this.element.enablePeriodicSave){
      clearInterval(this.perioridSaveInterval)
    }
  }

  resumeRec(){
    this.isRecordingPaused = false;
    if (this.isChrome()){
      this.resumeRecChrome();
    }
    else{
      this.resumeRecSimple();
    }

    clearInterval(this.pauseInterval);
    this.totalPauseDuration = this.totalPauseDuration + this.currentPauseDuration

    if (this.wavesurfer && this.wavesurfer.microphone){
      this.wavesurfer.microphone.start();
    }
  }

  resumeRecChrome(){
    try{
      this.mediaRecorder.resume();
    }catch(e){
      console.log(e)
    }
  }

  resumeRecSimple(){
    this.triggerResumeRec.next(true)
    if (this.element.enablePeriodicSave && this.element.saveInterval){
      this.perioridSaveInterval = setInterval(this.triggerPeriodicSaveSimple.bind(this), this.element.saveInterval*1000)
    }
  }

  isResumeButtonEnabled(){
    return this.isRecordingPaused && !this.isRecorded && !this.isInitialState()
  }

  renderDownloadUrl(){
    return this.capture.url;
  }
}
