import { Component, OnInit } from '@angular/core';
import { AuthService, IUserInfo } from 'src/app/api/auth.service';
import { LangService } from 'src/app/core/lang.service';
import { LoginGuardService } from '../../api/login-guard.service';
import { RoutesService } from "../../api/routes.service";
import {Router} from '@angular/router';
import { WhitelabelService } from 'src/app/domain/whitelabel.service';

const replaceProps = (str:string, params:any) => {
  Object.keys(params).forEach(paramKey => {
    str = str.split(':'+paramKey).join(params[paramKey])
  });
  return str;
}
@Component({
  selector: 'view-login-portal',
  templateUrl: './view-login-portal.component.html',
  styleUrls: ['./view-login-portal.component.scss']
})
export class ViewLoginPortalComponent implements OnInit {

  constructor(
    private auth:AuthService,
    private lang:LangService,
    public loginGuard: LoginGuardService,
    private whiteLabel:WhitelabelService,
    private routes: RoutesService,
    private router: Router,
  ) { }

  fullName:string;
  accountTypes:any = [];
  accountTypelocalStorageAffix = 'accountTypes:'

  ngOnInit(): void {

    this.auth.user().subscribe(async userInfo => {
      if (userInfo) {
        this.initAccountTypes(userInfo)
      }
    })

  }

  async logout(){
    await this.auth.logout();
    this.router.navigate([
      this.whiteLabel.getHomepageRoute()
    ]);
  }

  async initAccountTypes(userInfo: IUserInfo){
    this.fullName = userInfo.firstName +' ' + userInfo.lastName
    const lang = this.lang.c();
    // todo: do not call this if you have logged in in the past 10 seconds...
    await this.auth.getLatestUserRoles()
    this.accountTypes = userInfo.accountTypes.map(record => {
      const {route_template, group_id} = record;
      record.route = '#'+replaceProps(route_template, {
        lang,
        group_id,
      })
      return record;
    })
  }

  showConfirmBtn(accountType){
    if(+accountType.is_conf_req === 1 && +accountType.is_confirmed === 0){
      return true;
    }
    return false;
  }

  confirmBtnClick(accountType){
    const ur_id = accountType.ur_id
    const langCode = this.lang.getCurrentLanguage();
    this.auth.apiPatch(this.routes.LANDING_CONFIRM_USER_ROLE, ur_id, {} )
      .then(res => {
        if(+res.is_confirmed === 1 && +accountType.is_confirmed === 0){
          accountType.is_confirmed = 1
        }
      })
  }

  notMySchoolBtnClick(accountType){
    const uid = this.auth.getUid();
    const langCode = this.lang.getCurrentLanguage();
    const confirmationMsg = this.lang.tra('lp_not_schl_admin_warning');
    this.loginGuard.confirmationReqActivate({
      caption: confirmationMsg,
      confirm: () => {
        //revoke self as school admin
        this.auth.apiRemove(this.routes.LANDING_CONFIRM_USER_ROLE, uid, { query: { schl_group_id: accountType.group_id } })
        //remove school admin
        const i = this.accountTypes.indexOf(accountType);
        this.accountTypes.splice(i, 1);
      }
    })
  }
}
