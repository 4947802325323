import { IContentElementFrameStateStyle } from "../element-render-frame/model";
import { IContentElementDynamicImage } from "../element-render-image/model";
import { IContentElement } from "../models";

export interface IContentElementCanvasPage {
    displayList: IContentElementCanvasDisplayElement[];
}
  
export interface IContentElementCanvas extends IContentElement {
    width: number;
    height: number;
    scale?: number;
    pages: IContentElementCanvasPage[];
    readerId?: string;
    caption?: string;
    showBookmarks?: boolean;
    frame?: IContentElementFrameStateStyle;
    bgImg?: IContentElementDynamicImage;
    isPaginationHidden?: boolean;
    isFrameHidden?: boolean;
}

export interface IContentElementCanvasDisplayElement extends IContentElement {
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    _isCollapsed?: boolean;
    element?: IContentElement;
    maintainAspectRatio?: number;
    isDisabled?: boolean;
}

export const canvasEditInfo = {
    editExcludeFields: ['pages', 'bgImg'],
    editKeyFieldsToShow: [null]
}

export const canvasBookmarkEditInfo = {
    editExcludeFields: []
}
