import {Component, Input} from '@angular/core';

@Component({
  selector: 'histogram',
  templateUrl: './histogram.component.html',
  styleUrls: ['./histogram.component.scss']
})
export class HistogramComponent {
  view: any[] = [700, 400];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  // xAxisLabel = 'Number of Students in ELL Program';
  showYAxisLabel = true;
  // yAxisLabel = 'Number of Classes';
  showDataLabel = true;
  toolTipDisabled = true;


  @Input() data: Array<{name, value}>;
  @Input() labelX: string;
  @Input() labelY: string;

  colorScheme = {
    domain: [
      '#A10A28'
    ]
  };

  constructor() {
    Object.assign(this, { data: this.data });
  }

  onSelect(event) {
    console.log(event);
  }

}
