import { AuthService } from "src/app/api/auth.service";
import { RoutesService } from "src/app/api/routes.service";
import { LangService } from "src/app/core/lang.service";
import { SelectedWord } from "../widget-dictionary.component";

export enum DICTIONARY_LANG {
    EN = 'en',
    FR = 'fr'
}

export abstract class Dictionary {

    lang: LangService;
    route: RoutesService;
    auth: AuthService;
    selectedWord: SelectedWord;
    dictionaryLang: DICTIONARY_LANG;

    constructor(lang: LangService, route: RoutesService, auth: AuthService){
        this.lang = lang;
        this.route = route;
        this.auth = auth;

        this.selectedWord = {
            word: '',
            json: undefined,
            isActive: false
        }
    }

    abstract getWordData(word: string): Promise<any>;

    abstract getSuggestions(userData: string): Promise<any>;

    abstract preprocessResponseRawJSON(raw_json: string): string;

    abstract getCurrentWordDefinition();

    abstract getDefEntryExampleData(defEntry);

    abstract highlightTargetWord(example);

    abstract getDefEntryDefData(defEntry);

    abstract getSelectedWordDefinitionContent(selectedWordDefinitionEntry);

    // For FR dictionary only:
    abstract getDefEntrySynonymOpposites(defEntry): any[];
    abstract getDefEntrySynonymDisplay(jsonEntry);
    abstract getDefEntryOppositeDisplay(jsonEntry);
}