import * as PIXI from "pixi.js-legacy";
import { IContentElementVirtualTools } from "../model";
import { IPoint, VirtualTools } from "./virtual-tools";
import * as _ from "lodash";
import { SpriteLoader } from "../../element-render-custom-interaction/controllers/sprite-loader";
import events from "events";
import { QuestionState } from "../../models";

enum textboxHash {
  TEXT = 'text',
  SELECT_OUTLINE = 'selectOutline',
  OUTLINE = 'outline',
  CARET = 'caret',
  TEXTBOX_CONTAINER = 'textBoxContainer',
  CELL = 'cell'
}
export interface ITextboxData {
    id: number,
    input: string,
    x?: number,
    y?: number,
    width?: number,
    height?: number,
    isRevoked?: boolean,
    fontSize?: number,
    activeCharIndex?: number,
    isTableCell?: boolean,
    isDrawn?: boolean,
    color?: number,
    tableId?: number,
    isActive?: boolean,
    sourceContainer?: PIXI.Graphics

  }
export class MenuInputBox extends VirtualTools {
  element: IContentElementVirtualTools;
  spriteLoader: SpriteLoader
  backgroundSprite: PIXI.Sprite;
  hcPrevState: boolean;
  staticBg: PIXI.Graphics;
  wasTextBoxSelected: boolean = false;
  activeTextBoxData: ITextboxData = null;
  cellHeight = 30;
  cellWidth = 60;
  padding = (this.cellHeight - 14)/2;
  textBoxesContainer: PIXI.Graphics;
  singleTextBoxData: ITextboxData[] = [];
  singleTextboxes =  [];
  deleteSprite: PIXI.Sprite;
  upSprite: PIXI.Sprite;
  downSprite: PIXI.Sprite;
  dupeSprite: PIXI.Sprite;
  fontSize: number = 10;
  cellIdCounter: number;
  onChange = new events.EventEmitter();;
  redrawTable: (tableIndex: number) => void

  constructor(
    questionState: QuestionState,
    element: IContentElementVirtualTools,  
    addGraphic, 
    render,
    getToolStateSub,
    stage,
    isLocked, 
    textToSpeech,
    isGlobalRotating: boolean, 
    isGlobalDragging: boolean,
    redrawTable: (tableIndex: number) => void
  ) {
    super(questionState, addGraphic, render, getToolStateSub, stage, isLocked, textToSpeech, isGlobalRotating, isGlobalDragging);
    this.questionState = questionState;
    //#stopping Ticker
    PIXI.Ticker.system.autoStart = false
    PIXI.Ticker.system.stop()
    this.hcPrevState = this.textToSpeech.isHiContrast;  

    this.element = element;
    this.spriteLoader = new SpriteLoader();
    this.textBoxesContainer = new PIXI.Graphics();
    this.redrawTable = redrawTable

    this.deselectAllTextBoxListener();

    this.addTextEventListener();

    this.addGraphic(this.textBoxesContainer);
  }

  
  createOutline = (outline: PIXI.Graphics, color: number, width: number, height: number, fillColor?: number) => {
    outline.lineStyle(1, color, 1);
    if(fillColor != null) {
      outline.beginFill(fillColor);
    }
    outline.drawRoundedRect(0,0, width, height, 5)
    outline.endFill();
  }

  textBoxData: ITextboxData;
  parantGraphContainer: PIXI.Graphics;
  createTextBox(x: number, y: number, color: number, textBoxData: ITextboxData, width: number, height: number, sourceContainer: PIXI.Graphics) {
    const textBoxContainer = new PIXI.Graphics();
    const cell = new PIXI.Graphics();
    const outline = new PIXI.Graphics();
    const selectOutline = new PIXI.Graphics();

    this.cellWidth = width;
    this.cellHeight = height;
    this.padding = (this.cellHeight - 14)/2;


    if(!textBoxData || textBoxData == null) {
        const defaultData: ITextboxData = {
          id: this.singleTextBoxData.length,
          input: '', 
          x, 
          y, 
          width: this.cellWidth, 
          height: this.cellHeight, 
          isRevoked: false,
          fontSize: this.fontSize,
          activeCharIndex: -1,
        };
        this.singleTextBoxData.push(JSON.parse(JSON.stringify(defaultData)));
        textBoxData = this.singleTextBoxData[this.singleTextBoxData.length-1];
    }

    textBoxData.isDrawn = true;
    textBoxData.color = color;
    textBoxData.sourceContainer = sourceContainer;

    // const caret = new Caret(this.render);
    const caret = new PIXI.Graphics;
    caret.beginFill(0xbbbbbb);
    caret.drawRect(0,0, 1, textBoxData.fontSize);
    caret.endFill();
    // caret.pivot.set(0, caret.height/2);
    // caret.y = -textBoxData.fontSize/2

    const text = new PIXI.Text('', {fontSize: textBoxData.fontSize});
    text.text = textBoxData.input;
    text.style.fill = 0xffffff;
    text.y = (this.cellHeight / 2 - text.height / 2);
    text.x = (this.cellWidth / 2 - text.width /  2);
    // text.anchor.set(0.5, 0.5);
    text.zIndex = 3;
    text.interactive = true;
    text.cursor = 'text';

    // caret.pivot.set(text.width/2, text.height/2);
  
    cell.beginFill(0x0000, 0.15);
    cell.drawRoundedRect(0,0, textBoxData.width || this.cellWidth, textBoxData.height || this.cellHeight, 5);
    cell.interactive = true;
    cell.endFill();
    // cell.x = x;
    // cell.y = y;

    this.createOutline(outline, 0xbbbbbb, textBoxData.width || this.cellWidth, textBoxData.height || this.cellHeight);
    this.createOutline(selectOutline, 0xffffff, textBoxData.width || this.cellWidth, textBoxData.height || this.cellHeight);
    outline.alpha = textBoxData.input.length > 0 ? 0 : 1;
    selectOutline.alpha = 0;
    caret.alpha = 0;
    outline.zIndex = 1;
    selectOutline.zIndex = 2;

    cell.addChild(outline);
    cell.addChild(selectOutline);
    text.addChild(caret);
    cell.addChild(text);

    textBoxContainer.addChild(cell);
    textBoxContainer.position.set(x,y);

    const textboxHashmap = new Object();
    textboxHashmap[textboxHash.TEXT] = text;
    textboxHashmap[textboxHash.SELECT_OUTLINE] = selectOutline;
    textboxHashmap[textboxHash.OUTLINE] = outline;
    textboxHashmap[textboxHash.CARET] = caret;
    textboxHashmap[textboxHash.TEXTBOX_CONTAINER] = textBoxContainer;
    textboxHashmap[textboxHash.CELL] = cell;

    this.singleTextboxes.push(textboxHashmap);
    this.selectTextBoxListener(cell, textBoxData);

    if(textBoxData.isTableCell) {
      const caretPos = this.findCaretPosition(textBoxData, text)
      caret.position.set(caretPos.x, caretPos.y);
    }
    if(textBoxData.isActive) {
      this.selectTextbox(textBoxData);
    }

    this.selectCharListener(text, textBoxData);
    this.textBoxesContainer.addChild(textBoxContainer);
    this.deselectAll();    
    this.selectTextbox(textBoxData);
    this.textBoxData = textBoxData;

    return cell;
  }

  selectTextbox(textBoxData: ITextboxData) {
    this.isSelected = true;
    this.wasTextBoxSelected = true;
    this.deselectTextboxes();
    textBoxData.isActive = true;
    this.activeTextBoxData = textBoxData;
    this.singleTextboxes[textBoxData.id][textboxHash.TEXT].cursor = 'text';
    this.singleTextboxes[textBoxData.id][textboxHash.CARET].alpha = 1;
    this.singleTextboxes[textBoxData.id][textboxHash.SELECT_OUTLINE].alpha = 1;
    this.render();
  }
  selectTextBoxListener(obj: PIXI.Graphics, textBoxData: ITextboxData) {
    const selectText = () => {
      this.wasTextBoxSelected = true;
      this.deselectTextboxes();
      this.selectTextbox(textBoxData);
    }

    obj.on('pointerdown', selectText);
  }

  isSelected = false
  deselectTextboxes() {
    this.activeTextBoxData = null;
    //[text, selectOutline, outline, caret]
    this.singleTextboxes.forEach(textbox => {
      textbox[textboxHash.SELECT_OUTLINE].alpha = 0
      textbox[textboxHash.CARET].alpha = 0
      textbox[textboxHash.TEXT].cursor = 'grab';
    })
    this.singleTextBoxData.forEach((data) => {
      data.isActive = false;
    })
  }

  deselectAll() {
    if(!this.wasTextBoxSelected) {
      this.deselectTextboxes();
      this.render();

    } else {
      this.wasTextBoxSelected = false;
    }
  }

  deselectAllTextBoxListener() {
    this.stage.on('pointerup', this.deselectAll.bind(this));
  }

  
  selectCharListener(textContainer: PIXI.Text, textboxData: ITextboxData) {
    const selectChar = (e) => {
      if(this.activeTextBoxData != null && textboxData.isActive) {
        const mousePosition = e.data.getLocalPosition(textContainer);

        const text = this.singleTextboxes[textboxData.id][textboxHash.TEXT];
        const cursor = {
          x: mousePosition.x,
          y: mousePosition.y
        }
  
        const caretPos = this.findCursorCaretPosition(textboxData, text, cursor)
        this.singleTextboxes[textboxData.id][textboxHash.CARET].position.set(caretPos.x, caretPos.y)
        textboxData.activeCharIndex = caretPos.index - 1;
  
        this.render();
      }
    }
    textContainer.on('pointerdown', selectChar)
  }

  findCursorCaretPosition(textboxData: ITextboxData, text: PIXI.Text, cursor: IPoint) {
    const lines = textboxData.input.split('\n');
    
    const height = PIXI.TextMetrics.measureText(lines[0], text.style).height;
    const index = cursor.y / height;
    let lineIndex = 0;

    for(let i=1; i<=lines.length; i++){
      if(index < i && index > i-1) {
        lineIndex = i-1;
        break;
      }
    }

    const line = lines[lineIndex];
    let measuredWidth = 0;
    let charIndex = 0;
    for(let i=0; i<=line.length; i++) {
      const measure = PIXI.TextMetrics.measureText(line.substring(0, i), text.style);
      const charWidth = PIXI.TextMetrics.measureText(line.substring(i, i > 0 ? i-1 : 0), text.style).width;
      if(cursor.x < measure.width) {
        if(cursor.x < measure.width - (charWidth / 2)) {
          measuredWidth = measure.width - charWidth;
          charIndex = i-1;
        } else {
          measuredWidth = measure.width;
          charIndex = i;
        }
        break;
      } else if(i == line.length && cursor.x > measure.width) {
        measuredWidth = measure.width;
        charIndex = i;
      }
    }

    for(let i = 0; i<lineIndex; i++) {
      charIndex += lines[i].length + 1
    }
    const measuredHeight = lineIndex * height // Additional vertical spacing

    return {x: measuredWidth, y: measuredHeight, index: charIndex}
  }


  findCaretPosition(textboxData: ITextboxData, text: PIXI.Text): IPoint {
    const lines = textboxData.input.split('\n');
    let lineIndex = 0;
    let charIndex = 0;
    let index = 0;
    for(let i = 0; i<lines.length; i++) {
      for(let v = 0; v<lines[i].length; v++) {
        if(index == textboxData.activeCharIndex) {
          lineIndex = i;
          charIndex = v;
        }
        
        index++;
      }
      if(index == textboxData.activeCharIndex) {
        charIndex = -1;
        lineIndex = i+1;
      }
      index++;
    }
    
    const line = lines[lineIndex]; 

    const measure = PIXI.TextMetrics.measureText(line.substring(0, charIndex+1), text.style);
    const measuredWidth = measure.width;
    const measuredHeight = lineIndex * measure.height // Additional vertical spacing

    if(textboxData.activeCharIndex == -1) {
      return {x: 0, y: measuredHeight}
    }
    return {x: measuredWidth, y: measuredHeight}
  }

  updateTextInput(textboxData: ITextboxData, fromButtons?: boolean) {
    const text = this.singleTextboxes[textboxData.id][textboxHash.TEXT];
    const selectBorder = this.singleTextboxes[textboxData.id][textboxHash.SELECT_OUTLINE];
    const outlineBorder = this.singleTextboxes[textboxData.id][textboxHash.OUTLINE];
    const cell = this.singleTextboxes[textboxData.id][textboxHash.CELL];
    selectBorder.clear();

    text.text = textboxData.input;
    text.y = (this.cellHeight / 2 - text.height / 2);
    text.x = (this.cellWidth / 2 - text.width /  2);

    // Update caret position based on text width, height, and caret index
    const caretPos = this.findCaretPosition(textboxData, text)
    this.singleTextboxes[textboxData.id][textboxHash.CARET].position.set(caretPos.x, caretPos.y)

    const totalMeasure = PIXI.TextMetrics.measureText(textboxData.input, text.style);

    if(textboxData.input.length <= 0) {
      textboxData.width = this.cellWidth;
      outlineBorder.alpha = 1;
      this.createOutline(selectBorder, 0xffffff, this.cellWidth, this.cellHeight);
      if(textboxData.isTableCell) {
        outlineBorder.clear();
        this.createOutline(outlineBorder, 0xbbbbbb, this.cellWidth, this.cellHeight);
      }
    } else {
      const width = textboxData.width = totalMeasure.width + (this.padding * 2) > this.cellWidth ? totalMeasure.width  + (this.padding * 2) : this.cellWidth;
      outlineBorder.alpha = textboxData.isTableCell ? 1 : 0;
      this.createOutline(selectBorder, 0xffffff, width, totalMeasure.height + (this.padding * 2) + 2);
      if(textboxData.isTableCell) {
        outlineBorder.clear();
        this.createOutline(outlineBorder, 0xbbbbbb, width, totalMeasure.height + (this.padding * 2) + 2, textboxData.color);
      }
    }

    if(!textboxData.isTableCell) {
      textboxData.height = totalMeasure.height + (this.padding * 2) + 2;
    } else {
      this.redrawTable(textboxData.tableId)
    }

    // this.wasTextBoxSelected = true;

    if(!fromButtons) this.onChange.emit('onInputUpdate', textboxData);
    this.render();
  }

  addTextEventListener() {
    // global active text and global actiev textboxdata
    const keyDown = (e) => {
      if(this.activeTextBoxData != null && this.isSelected) {
        if((
          e.keyCode == 32 || 
          e.keyCode == 222
        ) && e.target == document.body) {
          e.preventDefault();
        }
        if(e.keyCode == 37) { // left
          this.activeTextBoxData.activeCharIndex -= this.activeTextBoxData.activeCharIndex >= 0 ? 1 : 0;
        } else if(e.keyCode == 39) { // right
          this.activeTextBoxData.activeCharIndex += this.activeTextBoxData.activeCharIndex < this.activeTextBoxData.input.length - 1 ? 1 : 0;
        } else if(e.keyCode == 8 && this.activeTextBoxData.activeCharIndex >= 0) { // backspace
          // this.activeTextBoxData.input = this.activeTextBoxData.input.slice(0, this.activeTextBoxData.input.length - 1);
          this.activeTextBoxData.input = this.activeTextBoxData.input.slice(0, this.activeTextBoxData.activeCharIndex) + this.activeTextBoxData.input.slice(this.activeTextBoxData.activeCharIndex+1);
          this.activeTextBoxData.activeCharIndex -= this.activeTextBoxData.activeCharIndex >= 0 ? 1 : 0;
        } else if(e.keyCode == 13) { //enter
          this.activeTextBoxData.input = this.activeTextBoxData.input.slice(0, this.activeTextBoxData.activeCharIndex+1) + '\n' + this.activeTextBoxData.input.slice(this.activeTextBoxData.activeCharIndex+1);
          this.activeTextBoxData.activeCharIndex++;
        } else {
          this.activeTextBoxData.input = e.key.length <= 1 ? 
            this.activeTextBoxData.input.slice(0, this.activeTextBoxData.activeCharIndex+1) + e.key + this.activeTextBoxData.input.slice(this.activeTextBoxData.activeCharIndex+1)
            : this.activeTextBoxData.input;
          this.activeTextBoxData.activeCharIndex += e.key.length <= 1 ? 1 : 0;
        }
        

        this.updateTextInput(this.activeTextBoxData);
      }
    }

    window.addEventListener("keydown", keyDown.bind(this));
  }

  updateText(text: string){

    this.textBoxData.input = text
    this.textBoxData.activeCharIndex = text.length - 1;
    this.updateTextInput(this.textBoxData, true);
  }

  stopAllBlinks() {
    // this.textBoxOutlines.forEach((v, i) => {
    //   this.textBoxOutlines[i][1].stopBlink();
    // })
  }

  loadAssets(): Promise<PIXI.Loader> { throw new Error("Method not implemented.");}
  changeDrawnGraphicColor(){}

  getUpdatedState() {
    return null;
  }
  handleNewState() {}
  
}
class Caret extends PIXI.Graphics {
  rerender;
  isBlinkIn = false;
  alphaTracker: number;
  ticker: PIXI.Ticker;
  duration: number;
  constructor(rerender) {
    super();
    this.rerender = rerender;
    this.ticker = PIXI.Ticker.shared;
  }

  onTick = (deltaTime) => {
    const deltaMS = deltaTime / PIXI.settings.TARGET_FPMS;

    // increase the alpha proportionally
    if (this.alphaTracker >= 1) {
      this.isBlinkIn = false;
    } else if(this.alphaTracker <= 0){
      this.isBlinkIn = true;
    }

    if(this.isBlinkIn) {
      this.alphaTracker += deltaMS / this.duration;
    } else {
      this.alphaTracker -= deltaMS / this.duration;
    }

    if(this.alphaTracker > 0.8) {
      this.alpha = 1;
    } else if(this.alphaTracker < 0.2) {
      this.alpha = 0;
    }

    console.log(`delta ${deltaTime}`);

    this.rerender();
  }

  blink(duration: number) {
    this.alphaTracker = 1;
    this.alpha = 1;
    this.duration = duration;

    this.ticker.add(this.onTick)
  }

  stopBlink() {
    this.ticker.remove(this.onTick);

    this.alpha = 0;

    this.rerender();
  }

}