<div *ngIf="isLoading">
    Loading...
</div>
<div style="margin-top:1em;">
    <div class="buttons">
        <button 
            *ngFor="let df of dataFrames"
            [class.is-info]="isDfSelected(df.slug)"
            (click)="selectDf(df.slug)"
            class="button"
        >
            {{df.title}}
        </button>
    </div>
    <div class="space-between">
        <div style="display: flex; gap: 1em;">
            <mat-slide-toggle [(ngModel)]="dataLoadOptions.includeAsmtSample">
                Include Practice Exams
            </mat-slide-toggle>
            <mat-slide-toggle [(ngModel)]="dataLoadOptions.focusAsmtSwapRisk">
                Focus on Swap Risk
            </mat-slide-toggle>
        </div>
        <button class="button is-dark" [disabled]="isLoading" (click)="loadData()">
            <span class="icon"><i class="fas fa-sync" aria-hidden="true"></i> </span>
        </button>
    </div>
    <div style="width: 100%;">
        <ng-container *ngFor="let df of dataFrames">
            <div 
                class="df-container" 
                *ngIf="isDfSelectedOrInited(df.slug)" 
                [class.is-invisible]="!isDfSelected(df.slug)"
            >
                <ag-grid-angular
                    class="ag-theme-alpine ag-grid-fullpage"
                    [rowData]="df.records"
                    [gridOptions]="df.gridOptions"
                    [enableCellTextSelection]="true"
                ></ag-grid-angular>
                <div style="margin-top:1em;">
                    <button class="button is-small" (click)="exportCsv(df)">
                        Export to CSV
                    </button>
                </div>
            </div>
        </ng-container>
    </div>

</div>
