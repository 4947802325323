import * as PIXI from "pixi.js-legacy";
import { LangService } from "../../../core/lang.service";
import { QuestionPubSub } from "../../../ui-testrunner/question-runner/pubsub/question-pubsub";
import { PubSubTypes } from '../../element-render-frame/pubsub/types';
import { ElementType, QuestionState } from "../../models";
import { CustomInteractionType, IContentElementSlider, IEntryStateSlider } from "../model";
import { CustomInteractionCtrl } from "./custom-interaction";
import { getElementWeight } from "../../models";
import { SpriteLoader } from "./sprite-loader";

interface FrameData{
    angle?: number;
    heightChange?: number;
    maxHeight?: number;
}
export class SliderCtrl extends CustomInteractionCtrl {
    element: IContentElementSlider;
    spriteLoader: SpriteLoader;
    sliderSprite: PIXI.Sprite;
    notches: PIXI.Graphics[];
    stage: PIXI.Container;
    isDragging: boolean;
    dragPosOffset: PIXI.Point;
    actualPos: number;
    sliderWidth: number;
    dimensionProp: string;
    dim2Prop: string;
    canvasSizeProp: string;
    canvasSize2Prop: string;
    value: number;
    lang: LangService;
    frameData: FrameData
    lastSliderLocation:number;
    frameScale:number;
    userInteracted: boolean = false;
    userMovedSlider: boolean = false;

    constructor(element: IContentElementSlider, questionState: QuestionState, questionPubSub: QuestionPubSub, addGraphic, render, lang: LangService, zoom, isLocked, textToSpeech){
        super(questionState, questionPubSub, addGraphic, render, zoom, isLocked, textToSpeech);
        this.lang = lang;
        this.element = element;
        this.spriteLoader = new SpriteLoader();
        this.initializeDefaults();

        this.dimensionProp = this.element.isVertical ? "y" : "x";
        this.dim2Prop = this.element.isVertical ? "x" : "y";
        this.canvasSizeProp = this.element.isVertical ? "canvasHeight" : "canvasWidth";
        this.canvasSize2Prop = this.element.isVertical ? "canvasWidth" : "canvasHeight";

        const sizeProp = this.element.isVertical ? "height" : "width";
        const size2Prop = this.element.isVertical ? "width" : "height";


        this.stage = new PIXI.Container();
        this.stage[this.dimensionProp] = this.element.padding;
        this.stage[this.dim2Prop] = 0;
        this.sliderWidth = this.element[this.canvasSizeProp] - this.element.padding*2;
        this.stage[sizeProp] = this.sliderWidth;
        this.stage[size2Prop] = this.element[this.canvasSize2Prop];

        addGraphic(this.stage);

        const distanceBetweenNotches = this.sliderWidth/(this.element.numNotches - 1);

        this.notches = [];
        const biggestNotchLength =  Math.max((this.element.notchLength*this.element.bigNotchScale), this.element.notchLength);

        for(let i = 0;  i < this.element.numNotches; i++) {
            const notch = new PIXI.Graphics();
            notch.zIndex = 50
            const position = distanceBetweenNotches*i;

            let notchLength = this.element.notchLength;
            
            if(i % this.element.bigNotchMod === 0) {
                notchLength *= this.element.bigNotchScale;
            } 

            notch[this.dimensionProp] = position;
            notch[this.dim2Prop] = this.element[this.canvasSize2Prop]/2.0 - notchLength/2.0;

            notch.lineStyle(this.element.notchThickness, this.getColor()).moveTo(0,0).lineTo(this.element.isVertical ? notchLength : 0 , this.element.isVertical ? 0 : notchLength);
            this.notches.push(notch);
            this.stage.addChild(notch);

            const percentageOfRange = (i/(this.element.numNotches - 1));
            const notchVal = Math.round(this.element.minValue + (this.element.isVertical ? 1 - percentageOfRange : percentageOfRange)*(this.element.maxValue - this.element.minValue));

            const text = this.generateNotchText(notchVal);
            
            if(i%this.element.textMod === 0 && !(this.element.skipFirstText && i === 0)) {
                //const notchText = new PIXI.Text(text, this.getTextStyle());
                const notchText = this.getText(text, this.getTextStyle(), 2, undefined, undefined)
                notchText.anchor[this.dimensionProp] = 0.5;
                notchText.anchor[this.dim2Prop] = this.element.switchTextSide ?  1 : 0;
                notchText[this.dimensionProp] = notch[this.dimensionProp];
                const textPadding = this.element.textPadding;
                notchText[this.dim2Prop] = this.element[this.canvasSize2Prop]/2.0 + (this.element.switchTextSide ? -1 : 1 ) * (biggestNotchLength/2.0 + textPadding);
                this.stage.addChild(notchText);
            } 
        }

        this.sliderSprite = new PIXI.Sprite();
        this.sliderSprite.interactive = true;
        this.sliderSprite.cursor = 'grab';
        this.sliderSprite.anchor.set(0.5);
        this.sliderSprite.scale.set(this.element.sliderScale);

        this.sliderSprite[this.dim2Prop] = this.element[this.canvasSize2Prop]/2.0 + this.element.sliderOffset;
        // this.sliderSprite[this.dimensionProp] = this.valToPos(this.value == null ? this.element.startingValue : this.value);

        this.sliderSprite.on("mousedown", (event) => {
            this.isDragging = true;
            this.sliderSprite.cursor = 'grabbing';
            let mousePos = this.getScaledMousePos(event.data);
            this.dragPosOffset = new PIXI.Point(mousePos.x - this.sliderSprite.x, mousePos.y - this.sliderSprite.y);
            this.userInteracted = true
        })

        this.sliderSprite.on("mouseup", (event) => {
            this.stopDragging();
        });

        this.sliderSprite.on("mouseupoutside", (event) => {
            this.stopDragging();
        });

        this.sliderSprite.on("mousemove", (event) => {
            if(this.isDragging && !isLocked) {
                const data : PIXI.InteractionData = event.data;
                let loc = this.getScaledMousePos(data);
                this.actualPos =  Math.min(Math.max(loc[this.dimensionProp] - this.dragPosOffset[this.dimensionProp], this.notches[0][this.dimensionProp] ), this.notches[this.element.numNotches - 1][this.dimensionProp] );
                let newPos = this.actualPos;
                for(let i = 0; i < this.element.numNotches; i++) {
                    const snapDistance = (distanceBetweenNotches/2.0)*this.element.snapAmount;
                    
                    const leftSnapBound = this.notches[i][this.dimensionProp] - snapDistance;
                    const rightSnapBound =this.notches[i][this.dimensionProp] + snapDistance;

                    if(this.actualPos > leftSnapBound && this.actualPos <= rightSnapBound ) { 
                        newPos = this.notches[i].position[this.dimensionProp];
                        break;
                    }
                }
                this.sliderSprite[this.dimensionProp] = newPos;
                if(this.element.isAllowTargetFrame){
                    this.setTragetFrame()
                    this.lastSliderLocation = this.getValueFromSliderLocation()//this.sliderSprite.y
                }
                this.userMovedSlider = true
                this.render();
            }
        })
        this.sliderSprite.visible = false;
        this.stage.addChild(this.sliderSprite);

        // const asset = 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6276/authoring/Penguin/1616793590305/Penguin.png';
        this.loadAssets().then(this.init)
        
    }

    init = ({resources}) => {
        this.sliderSprite.texture = resources['slider'].texture;
        this.sliderSprite.visible = true;
        this.render();
    }

    loadAssets = () => {
        let assets = []
        if(!this.element.sliderImage || !this.element.sliderImage.url) {
            assets.push({name: 'slider', path: this.getImage()});
            this.sliderSprite.scale.set(0.03*this.element.sliderScale);
            if(this.element.isVertical) {
                this.sliderSprite.rotation = -Math.PI/2;
            }
        } else {
            assets.push({name: 'slider', path: this.getImage()});
        }

        this.spriteLoader.addSpritestoLoader(assets);
        return this.spriteLoader.loadSprites();
    }

    generateNotchText(val: number) : string {
        let showUnitBefore = false;
        let noSpace = false;
        if(this.element.unit === '$') {
            if(this.lang.c() !== 'fr') {
                showUnitBefore = true;
            }
            noSpace = true;
        }

        if(this.element.unit === '%') {
            noSpace = true;
        }

        if(showUnitBefore) {
            return `${this.element.unit}${val}`;
        } else {
            return `${val}${noSpace || !this.element.unit ? "" : " "}${this.element.unit || ""}`
        }
    }

    stopDragging() {
        this.isDragging = false;
        this.sliderSprite.cursor = 'grab';
        if(this.element.isAllowTargetFrame){
            this.setTragetFrame()
            this.lastSliderLocation = this.getValueFromSliderLocation()//this.sliderSprite.y
        }
        this.setValue(this.getValueFromSliderLocation());
        if (this.element.isAllowMcqInteraction) this.mcqInteration(this.getIsCorrect())
        
    }

    initializeDefaults() {
        const defaults = {
            sliderScale: 1,
            sliderOffset: 0,
            notchLength: 50,
            notchThickness: 2,
            padding: 50,
            bigNotchScale: 2,
            bigNotchMod: 2,
            snapAmount: 0.5,
            minValue: 1,
            maxValue: 10,
            startingValue: 5,
            numNotches: 10,
            minCorrectValueRange: [],
            maxCorrectValueRange: [],
            fontSize: 24,
            textMod: 2,
            skipFirstText: false,
            switchTextSide: false,
            textPadding: 10
        }
        
        for(let def of Object.keys(defaults)) {
            if(this.element[def] == null) {
                this.element[def] = defaults[def];
            }
        }

        if (this.element.isAllowTargetFrame) {
          this.lastSliderLocation = this.element.minValue || 0;
          this.setFrameData(true)
        }
    }

    valToPos(val: number) {
        const percentageOfRange = (val - this.element.minValue)/(this.element.maxValue - this.element.minValue);
        return this.sliderWidth*(this.element.isVertical ? (1 - percentageOfRange) : percentageOfRange);
    }


    getValueFromSliderLocation() {
        if(!this.sliderSprite) {
            return undefined;
        }
        const sliderPos = this.sliderSprite[this.dimensionProp];
        const percentageOfRange = (sliderPos/this.sliderWidth);
        return Math.round(this.element.minValue + (this.element.maxValue - this.element.minValue)*(this.element.isVertical ? 1 - percentageOfRange : percentageOfRange));
    }

    updateSliderPosFromVal() {
        this.sliderSprite[this.dimensionProp] = this.valToPos(this.value);
        this.render();
    }

    setTragetFrame(){
        this.setFrameData()
        if(this.element.isAllowTargetFrame && this.element.targetFrameId){
            this.questionPubSub.elementPub(
                this.element.targetFrameId,
                this.element.frameType, //PubSubTypes.INC_HEIGHT,
                this.frameData
                )
            }
        }
        
    setFrameData(defaults:boolean = false){
        this.frameScale = this.element.frameScale *( this.element.canvasHeight / this.element.maxValue)
        
        switch(this.element.frameType){
            case PubSubTypes.INC_HEIGHT:
                this.frameData = {
                    heightChange: defaults? 0 : (this.getValueFromSliderLocation() - this.lastSliderLocation) * this.frameScale,
                    maxHeight: this.element.maxValue ? this.element.maxValue * this.frameScale : 10
                }
                break;
            case PubSubTypes.ROTATE:
                // (this.element.frameRotate * this.frameScale)
                this.frameData = { angle: defaults? 0 : (this.getValueFromSliderLocation() - this.lastSliderLocation) * this.element.frameRotate};
                break;
            default:
                this.frameData = {}
                break;
        }
    }

    mcqInteration({rangeIndex,isCorrect}){
        let option;
        if(isCorrect) option = this.element.mapRangetoMcqOptionArray[rangeIndex];
        else option = undefined
        this.questionPubSub.elementPub(this.element.mcqId, this.element.mcqType, { option });    
    }

    getIsCorrect() {
        let isCorrect = false;
        let rangeIndex;

        // Check if the value falls into Atleast one of the provided range
        for (rangeIndex = 0; rangeIndex < this.element.minCorrectValueRange.length; rangeIndex++) {
          isCorrect = this.value >= this.element.minCorrectValueRange[rangeIndex] && this.value <= this.element.maxCorrectValueRange[rangeIndex];
          if (isCorrect) break;
        }

        return { rangeIndex, isCorrect };
    }

    getUpdatedState(): Partial<IEntryStateSlider> {
        let {isCorrect} = this.getIsCorrect();
        let weight = getElementWeight(this.element)
        return {
            type: ElementType.CUSTOM_INTERACTION,
            subtype: CustomInteractionType.SLIDER,
            value: this.value,
            userInteracted: this.userInteracted,
            userMovedSlider : this.userMovedSlider,
            isStarted: this.userInteracted,
            isFilled: this.userMovedSlider,
            isResponded: this.userMovedSlider,
            isCorrect,
            score: isCorrect ? weight : 0
        }
    }

    setValue(val) {
        this.value = val;
        this.updateSliderPosFromVal();
        this.updateState();
    }

    handleNewState(): void {
        const qsValue = this.questionState[this.element.entryId].value;
        if(qsValue == null) {
            this.setValue(this.element.startingValue);
        } else {
            this.userInteracted = this.questionState[this.element.entryId].userInteracted
            this.userMovedSlider = this.questionState[this.element.entryId].userMovedSlider
            this.setValue(this.questionState[this.element.entryId].value)
            this.lastSliderLocation = this.getValueFromSliderLocation()
        }
    }

    getTextStyle(fontSize?){
        return new PIXI.TextStyle({
            fontSize: this.element.fontSize,
            fill: [this.getColor()] ,
            fontFamily: 'Arial',
            align: 'center'
        })
    }

    isHCmode(){ return this.textToSpeech.isHiContrast }

    getColor(){ return this.isHCmode() ? 0xFFFFFF : 0x000000 }

    getImage(){
        // console.log(this.element.sliderImage);
        let fallbackImg = 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6276/authoring/1920px-Black_triangle/1616958311130/1920px-Black_triangle.png';
        let normalUrl = this.element.sliderImage.url ? this.element.sliderImage.url.toString() : fallbackImg
        let hiContrastUrl = this.element.sliderImage.isHiContrastSensitive && this.element.sliderImage.hiContrastImg.url ? this.element.sliderImage.hiContrastImg.url.toString() : normalUrl  // Fallback to normal image
        
        return this.isHCmode() ? hiContrastUrl : normalUrl
    }
}