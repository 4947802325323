<div class="login-portal">

    <div style="font-size:1.4em; font-weight:600; margin-bottom:0.2em; display:flex; align-items: center;">
        {{fullName}}         
        <button (click)="logout()" class="button is-light" style="margin-left:1em;">
            <tra slug="title_logout"></tra>
        </button>

    </div>
    <div style="font-size:1.1em; margin-bottom: 2em">
        <tra-md slug="txt_login_portal_instr"></tra-md> 
    </div>

    <div class="portal-link-mosaic">
        <div *ngFor="let accountType of accountTypes" [style.background-color]="accountType.color" class="portal-link">
            <a [href]="accountType.route" [class.disabled-link] = "showConfirmBtn(accountType)">
                <tra [slug]="accountType.caption"></tra>
                <div style="font-weight:400;">
                    <span *ngIf="accountType.s_name">
                        {{accountType.s_name}} ({{accountType.s_foreign_id}})
                    </span>
                    <span *ngIf="accountType.sd_name">
                        {{accountType.sd_name}} ({{accountType.sd_foreign_id}})
                    </span>
                </div>
            </a>
            <div *ngIf="showConfirmBtn(accountType)" class="pre-button-strip">
                <button class = 'confirm' (click)="confirmBtnClick(accountType)"><tra slug="lp-button-confirm"></tra></button>
                <button class = 'not-my-school' (click)="notMySchoolBtnClick(accountType)"><tra slug="lp-button-not-my-school"></tra></button>
            </div>
        </div>
    </div>
</div>