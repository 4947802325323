<div 
    class="passage-container"
    [class.numbers-align-left]="isCounterAlignLeft()"
    [class.numbers-align-right]="isCounterAlignRight()"
    [style.backgroundColor]="bgFillService.getFillColor()"
    [class.is-selected-edit]="editSelection.isSelectedEdit(element, questionPubSub)"
    [style.border]="editSelection.getBorder(element, questionPubSub)"
>
    <div class="passage" [class.is-colored-image]="!bgFillService.isFillWhite()">
        <!-- todo:OPTIMIZE we should only re-render the text segments when there is a change to the text -->
        <div 
            *ngFor="let textSegment of renderTextSegments(); let i = index" 
            style="position:relative"
            [style.margin-bottom.em]="paragraphSpacing()"
        >
            <div [innerHtml]="textSegment.html"></div>
            <div 
                *ngIf="isShowCounter() && textSegment.isComplete" 
                class="passage-numbering-placer"
                [class.is-lines]="isLinesMode()"
                [class.is-paragraph]="isParagraphMode()"
                [class.align-left]="isCounterAlignLeft()"
                [class.align-right]="isCounterAlignRight()"
            >
                <span class="passage-numbering">{{renderLineCount(i)}}</span>
            </div>
        </div>
    </div>    
</div>
