import { IPoint } from "../controllers/virtual-tools";


export interface IDrawnMeta {
  // can be used to store reference when re-initializing prev state
  // common
  points: IPoint[];

  // tool specific

  //rectangle
  resizePoint?: IPoint;
  
  // In Memory props
  _isSelected?: boolean;

  color?: number;

  fillOpacity?: number;

  shapeName?: string;
  
  width?: number,
  height?: number,
  zIndex?: number
  x?: number,
  y?: number
}


// naming convention : do not use '-' on rhs
export enum ENamingTypes  {
    TOOL = 'tool',
    CONTAINER = 'container',
    RESIZE_ANCHOR = 'resizeAnchor',
    ROTATION_ANCHOR = 'rotationAnchor',
    MENU = 'menu',
    BORDER = 'BORDER',
    MENU_HFLIP = 'menu_hflip',
    MENU_VFLIP = 'menu_vflip',
    MENU_DUPE = 'menu_dulicate',
    MENU_DEL = 'menu_delete',
    MENU_PALETTE = 'menu_palette',
    AXIS_X = 'axis_x',
    AXIS_Y = 'axis_y',
    TICK_X = 'scale_x',
    TICK_Y = 'scale_y',
    TICK_NUMBER_X = 'tick_number_x',
    TICK_NUMBER_Y = 'tick_number_y',
}

// naming convention : do not use '-' on rhs
export enum EPixiTools {
  LINE = 'LINE',
  RECTANGLE = 'RECTANGLE',
  RECTANGLE_FILL = 'RECTANGLE_FILL',
  CIRCLE = 'CIRCLE',
  CIRCLE_FILL = 'CIRCLE_FILL',
  POLYGON = 'POLYGON',
  GRAPH = 'GRAPH',
  PENCIL = 'PENCIL',
  PROTRACTOR = 'PROTRACTOR',
  RULER = 'RULER',
  COUNTER = 'COUNTER',
  TABLE = 'TABLE',
  TEXTBOX = 'TEXTBOX'
}
