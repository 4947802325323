import { AfterViewInit, Component, Input, OnChanges, OnInit, Renderer2, SimpleChange, SimpleChanges, ViewChild } from "@angular/core";
import { QuestionPubSub } from "../question-runner/pubsub/question-pubsub";
import * as PIXI from "pixi.js-legacy";
import { LangService } from "../../core/lang.service";
import { ZoomService } from "../zoom.service";
import { TextToSpeechService } from "../text-to-speech.service";
import { CustomInteractionType, IContentElementVirtualTools, IEntryStateVirtualTools} from "./model";
import { MeasuringTools } from "./controllers/measuring-tools";
import { Counter } from "./controllers/counter";
import { Background } from "./controllers/background";
import { Polygon } from "./controllers/polygon";
import { FreehandPolygon } from "./controllers/freehand-polygon";
import { Table } from "./controllers/table";
import { Graph } from "./controllers/graph";
import { FreehandLine } from "./controllers/freehand-line";
import { DRAW_MODES, FreehandPencil } from "./controllers/freehand-pencil";
import { FreehandCircleShape } from "./controllers/freehand-circle";
import { FreehandRectangleShape } from "./controllers/freehand-rectangle";
import { mapBy } from "../../core/util/generate";
import { BehaviorSubject, Subscription } from "rxjs";
import { FreehandCircleOutline } from "./controllers/freehand-circle-outline";
import { FreehandRectangleOutline } from "./controllers/freehand-rectangle-outline";
import { LAYER_LEVEL, TOOLBAR_CATEGORY_ICON_URL, TOOLBAR_CATEGORY_ID } from "./types/constants";
import { ElementType, IEntryState, IEntryStateScored, QuestionState, ScoringTypes, getElementWeight } from "../models";
import { EPixiTools } from "./types/types";
import { VirtualTools } from "./controllers/virtual-tools";
import { PrintModeService } from "src/app/ui-item-maker/print-mode.service";
import { DEFAULT_TOOLBAR_ORDER_LIST, TOOLBAR_CONFIG_PRESETS, applyToolbarPreset } from "src/app/ui-item-maker/element-config-virtual-tools/types/constants";
import { SpriteLoader } from "../element-render-custom-interaction/controllers/sprite-loader";

export const paletteColors = ['#333333', '#ebebeb', '#E81416', '#FFA500', '#FAEB36', '#79C314', '#487DE7', '#4B369D', '#70369D', '#CCCCCC']
export interface IExtendedTools {
  parent?: string;
  tools?: {
    id: string, 
    action?: () => void,
    isSelected: () => boolean,
    isColorPick?: boolean,
    iconUrl?: string,
    colorController?: string,
  }[],
  colors?: string[],
  colorHandler?: (color: string) => void,
}

enum EToolCategoryType {
  SINGLE_TOOL = 'SINGLE',
  MULTI_TOOL = 'MULTI'
}

interface IToolbarTools {
  id: string;
  toggle: (toolId: string) => void;
  isSelected: () => boolean;
  isDisabled: () => boolean;
  iconUrl: string
}

interface IToolbarCategory {
  id: string;
  type: EToolCategoryType;
  tools: string[],
  iconUrl: string,
  hasSubMenu?: boolean //only used for SINGLE_TOOL
  isDisabled: () => boolean;
 }

 export interface IToolStateSub {
  [toolId: string]: BehaviorSubject<{
    val: boolean ;
    color?: {color: number, string: string},
    origin: 'BASE' | 'CHILD';
  }>
 }
@Component({
  selector: "element-render-virtual-tools",
  templateUrl: "./element-render-virtual-tools.component.html",
  styleUrls: ["./element-render-virtual-tools.component.scss"]
})
export class ElementRenderVirtualToolsComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() element: IContentElementVirtualTools;
  @Input() isLocked: boolean;
  @Input() questionState: QuestionState;
  @Input() questionPubSub?: QuestionPubSub;
  @Input() changeCounter: number;
  hcPrevState: boolean;

  // Pub-subs
  toolStateSubs: IToolStateSub = {
    'selector' : new BehaviorSubject({val: false, origin: 'BASE'}) ,
    'colorPicker' : new BehaviorSubject({val: false, color: {color: this.getParsedColor('#333333'), string: '#333333'}, origin: 'BASE'}) ,
  }

  @ViewChild("pixiDiv") pixiDiv;

  app: PIXI.Application;

  CustomInteractionType = CustomInteractionType;
  constructor(private renderer2: Renderer2, private lang: LangService, private zoom: ZoomService, private textToSpeech: TextToSpeechService, private printMode: PrintModeService) {
    PIXI.settings.ROUND_PIXELS = true;
    this.backgroundSprite = new PIXI.Sprite();
    this.staticBg = new PIXI.Graphics();
    this.backgroundSprite.interactive = true;
    this.zoomChangeSub = this.zoom.zoomSub.subscribe(val => {
      if (this.element) {
        this.refresh(true);
      }
    });

    this.screenShrinkChangeSub = this.zoom.screenShrinkSub.subscribe(val => {
      if(this.element && this.currentScreenShrinkSubVal != val) {
        this.currentScreenShrinkSubVal = val;
        this.refresh(true);
      } 
    })

    this.hcPrevState = this.textToSpeech.isHiContrast
    this.highContrastChangeSub = this.textToSpeech.isHighContrastSub.subscribe(val => {
      if(this.hcPrevState !== val){
        this.refresh(true);
        this.hcPrevState = val
      }
    })
  }

  renderer; //: PIXI.Renderer;
  stage: PIXI.Container;
  stageBorder: PIXI.Graphics;
  backgroundSprite: PIXI.Sprite;
  staticBg: PIXI.Graphics;

  zoomChangeSub: Subscription;
  screenShrinkChangeSub: Subscription;
  highContrastChangeSub: Subscription;
  currentScreenShrinkSubVal: number;

  MeasuringTools: MeasuringTools;
  Background: Background;
  Counter: Counter;
  Polygon: Polygon;
  FreehandPolygon: FreehandPolygon;
  Table: Table;
  Graph: Graph;
  FreehandLine: FreehandLine;
  FreehandPencil: FreehandPencil;
  FreehandCircleShape: FreehandCircleShape;
  RectangleShape: FreehandRectangleShape;
  FreehandCircleOutline: FreehandCircleOutline;
  RectangleOutline: FreehandRectangleOutline;
  spriteLoader: SpriteLoader;

  extendedTools:IExtendedTools = {};

  selectorToolState: boolean

  applyToolbarPreset = applyToolbarPreset.bind(this);

  toolbarTools: IToolbarTools[] = [
    {
      id: 'selector',
      toggle: (toolId) => this.toggleSelector(),
      isSelected: () => this.selectorToolState,
      isDisabled: () => false,
      iconUrl: TOOLBAR_CATEGORY_ICON_URL.SELECTION,
    },
    {
      id: 'pencil',
      toggle: (toolId) => this.toggleFreehandPencil(toolId),
      isSelected: () => this.FreehandPencil.isSelected_Pencil(),
      isDisabled: () => this.element.fhPencilDisabled !== undefined ? this.element.fhPencilDisabled : false,
      iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/pencil/1686838975207/pencil.png'
    },
    {
      id: 'marker',
      toggle: (toolId) => this.toggleFreehandMarker(toolId),
      isSelected: () => this.FreehandPencil.isSelected_Marker(),
      isDisabled: () => this.element.fhMarkerDisabled !== undefined ? this.element.fhMarkerDisabled : false,
      iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/marker/1689172036858/marker.png'
    },
    {
      id: 'highlighter',
      toggle: (toolId) => this.toggleFreehandHighlighter(toolId),
      isSelected: () => this.FreehandPencil.isSelected_Highlighter(),
      isDisabled: () => this.element.fhHighlighterDisabled !== undefined ? this.element.fhHighlighterDisabled : false,
      iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/highlighter/1689174719711/highlighter.png'
    },
    {
      id: 'eraser',
      toggle: (toolId) => this.toggleFreehandEraser(toolId),
      isSelected: () => this.FreehandPencil.isEraseMode,
      isDisabled: () => false,
      iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/eraser/1687449970161/eraser.png',
    },
    {
      id: 'text',
      toggle: (toolId) => this.createTextbox(toolId),
      isSelected: () => false,
      isDisabled: () => this.element.textBoxDisabled !== undefined ? this.element.textBoxDisabled : false,
      iconUrl: TOOLBAR_CATEGORY_ICON_URL.TEXT
      //https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/table/1686779324291/table.png
    },
    {
      id: 'table',
      toggle: (toolId) => this.createTable(toolId),
      isSelected: () => false,
      isDisabled: () => this.element.tableDisabled !== undefined ? this.element.tableDisabled : false,
      iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/table-2/1686779242810/table-2.png'
      //https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/table/1686779324291/table.png
    },
   {
      id: 'protractor',
      toggle: (toolId) => this.toggleProtractor(toolId),
      isSelected: () => this.MeasuringTools.isProtractorMode,
      isDisabled: () => this.element.isProtractorDisabled !== undefined ? this.element.isProtractorDisabled : false,
      iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/protractor-5/1684340267918/protractor-5.png',
    },
    {
      id: 'ruler',
      toggle: (toolId) => this.toggleRuler(toolId),
      isSelected: () => this.MeasuringTools.isRulerMode,
      isDisabled: () => this.element.isRulerDisabled !== undefined ? this.element.isRulerDisabled : false,
      iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/ruler-5/1684340292133/ruler-5.png',
    },
    {
      id: 'counter',
      toggle: (toolId) => this.toggleCounter(toolId),
      isSelected: () => this.Counter.isCounterMode,
      isDisabled: () => this.element.isCounterDisabled !== undefined ? this.element.isCounterDisabled : false,
      iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/tally-2/1684340305117/tally-2.png',
    },
    {
      id: 'polygon',
      toggle: (toolId) => this.toggleFreehandPolygon(toolId),
      isSelected: () => this.FreehandPolygon.isDrawMode,
      isDisabled: () => this.element.fhPolygonDisabled !== undefined ? this.element.fhPolygonDisabled : false,
      iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/polygon-3/1684188795969/polygon-3.png',
    },
    {
      id: 'line',
      toggle: (toolId) => this.toggleFreehandLine(toolId),
      isSelected: () => this.FreehandLine.isDrawMode,
      isDisabled: () => this.element.fhLineDisabled !== undefined ? this.element.fhLineDisabled : true,
      iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/line/1686862423245/line.png',
    },
    {
      id: 'circle',
      toggle: (toolId) => this.toggleFreehandCircle(toolId),
      isSelected: () => false,
      isDisabled: () => this.element.fhCircleShapeDisabled !== undefined ? this.element.fhCircleShapeDisabled : true,
      iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/circle/1686837012800/circle.png',
    },
    {
      id: 'rectangle',
      toggle: (toolId) => this.toggleRectangle(toolId),
      isSelected: () => false,
      isDisabled: () => this.element.fhRectangleShapeDisabled !== undefined ? this.element.fhRectangleShapeDisabled : true,
      iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/Rectangle-Filled/1687974469284/Rectangle-Filled.png',
    },
    {
      id: 'circle_outline',
      toggle: (toolId) => this.toggleFreehandCircleOutline(toolId),
      isSelected: () => this.FreehandCircleOutline.isDrawMode,

      // need to change to its own 
      isDisabled: () => this.element.fhCircleOutlineDisabled !== undefined ? this.element.fhCircleOutlineDisabled : true,
      iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/Circle-Unfilled/1687974725777/Circle-Unfilled.png',
    },
    {
      id: 'rectangle_outline',
      toggle: (toolId) => this.toggleRectangleOutline(toolId),
      isSelected: () => this.RectangleOutline.isDrawMode,
      isDisabled: () => this.element.fhRectangleOutlineDisabled !== undefined ? this.element.fhRectangleOutlineDisabled : true,
      iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/Rectangle_Unfilled/1687975796665/Rectangle_Unfilled.png',
    },
    {
      id: 'graph',
      toggle: (toolId) => this.toggleGraph(toolId),
      isSelected: () => this.Graph.isGraphMode,
      isDisabled: () => this.element.graphDisabled !== undefined ? this.element.graphDisabled : false,
      iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/graph/1686862515106/graph.png',
    },
    {
      id: 'colorPicker',
      toggle: (toolId) => this.toggleColorPalette(toolId),
      isSelected: () => false,
      isDisabled: () => false,
      iconUrl: TOOLBAR_CATEGORY_ICON_URL.PALETTE,
    },
  ]

  // create tools map
  toolbarToolsMap: Map<string, IToolbarTools> = mapBy(this.toolbarTools, 'id')

  toolbarCategory: IToolbarCategory[] 
  toolbarCategoryMap: Map<string, IToolbarCategory>
  toolbarCategoryFiltered: IToolbarCategory[]
  currentSelectedToolCategoryId: string = undefined;

  ngOnInit(): void {

    this.app = new PIXI.Application({ 
      autoStart: false,
      antialias: true,
      autoDensity: true,
      resolution: window.devicePixelRatio
    });
    // #Below code is experimental : deal with the sluggish performance after firing so many events
    // It has something to do with Ticker and System ticker in PIXI
    // this.app.ticker.autoStart =false
    // this.app.ticker.stop()

    // this.stage = new PIXI.Container();
    // this.renderVirtualTools();
    this.app.renderer.plugins.interaction.useSystemTicker = false
    
    // if (!this.element.toolbarSettingPreset){
    //   console.log('toolbarSettingPreset is not defined, setting default to ABED Pilot')
    //   this.ensureDefaultToolbarPreset();
    // }
    this.ensureToolbarPreset();
    this.ensureToolbarOrderList();
    this.initToolbarCategory();
    
    this.initSubs();
    this.handleNewState();
    this.addMouseUpListener();
  }

  ngOnDestroy() {
    this.zoomChangeSub.unsubscribe();
    this.screenShrinkChangeSub.unsubscribe();
    this.highContrastChangeSub.unsubscribe();
    this.toolStateSubscriptions.forEach(sub => {sub.unsubscribe()});

    this.Table.clearTickerListeners();
    this.Table.TextBox.removeTextEventListener();
    this.Table.TextBox.removeDeselectAllTextBoxListener_document();
    this.removeMouseUpListener();

    PIXI.utils.clearTextureCache();
    PIXI.utils.destroyTextureCache();

    this.stage.removeAllListeners();
    this.stage.destroy({children: true, texture: true, baseTexture: true});

    this.app.stop();
    this.app.renderer.removeAllListeners();
    this.app.stage.removeAllListeners();
    this.app.stage.removeChildren();
    this.app.stage.destroy({children: true, texture: true, baseTexture: true});
    this.app.destroy(true, {children: true, texture: true, baseTexture: true});

    this.renderer.gl.getExtension('WEBGL_lose_context').loseContext();
    this.renderer.clear();
    this.renderer.destroy();

    this.renderer2.destroy();
  }

  ngAfterViewInit() {
    this.renderer2.appendChild(this.pixiDiv.nativeElement, this.renderer.view);
  }

  initSubs(){
    this.initToolStateSubChangeFromChild();
  }

  initToolbarCategory(){
    // Note: Make sure the tool is also added to the virtual tool configurations toolbarCategoryOrderList
    // when adding new tools.
    this.toolbarCategory = [
      {
        id: TOOLBAR_CATEGORY_ID.SELECTION,
        type: EToolCategoryType.SINGLE_TOOL,
        tools: ['selector'],
        iconUrl: this.toolbarToolsMap.get('selector').iconUrl,
        isDisabled: () => false,
      },
      {
        id: TOOLBAR_CATEGORY_ID.PALETTE,
        type: EToolCategoryType.SINGLE_TOOL,
        tools: ['colorPicker'],
        iconUrl: this.toolbarToolsMap.get('colorPicker').iconUrl,
        hasSubMenu: true,
        isDisabled: () => false,
      },
      {
        id: TOOLBAR_CATEGORY_ID.MEASUREMENTS,
        type: EToolCategoryType.MULTI_TOOL,
        tools: ['protractor', 'ruler', 'counter'],
        iconUrl: TOOLBAR_CATEGORY_ICON_URL.MEASUREMENTS,
        isDisabled: () => this.element.measurementCategoryDisabled !== undefined ? this.element.measurementCategoryDisabled : false,
      },
      {
        id: TOOLBAR_CATEGORY_ID.SHAPES_DRAW,
        type: EToolCategoryType.MULTI_TOOL,
        tools: ['line', 'polygon', 'circle_outline', 'rectangle_outline'],
        iconUrl: TOOLBAR_CATEGORY_ICON_URL.SHAPES_DRAW,
        isDisabled: () => this.element.shapesCategoryDisabled !== undefined ? this.element.shapesCategoryDisabled : false,
      },
      {
        id: TOOLBAR_CATEGORY_ID.PENCIL,
        type: EToolCategoryType.MULTI_TOOL,
        tools: ['pencil', 'marker', 'highlighter', 'eraser'],
        iconUrl: TOOLBAR_CATEGORY_ICON_URL.PENCIL,
        isDisabled: () => this.element.pencilCategoryDisabled !== undefined ? this.element.pencilCategoryDisabled : true,
      },
      {
        id: TOOLBAR_CATEGORY_ID.TEXT,
        type: EToolCategoryType.SINGLE_TOOL,
        tools: ['text'],
        iconUrl: this.toolbarToolsMap.get('text').iconUrl,
        isDisabled: () => this.element.textBoxCategoryDisabled !== undefined ? this.element.textBoxCategoryDisabled : true,
      },
      {
        id: TOOLBAR_CATEGORY_ID.DATA,
        type: EToolCategoryType.MULTI_TOOL,
        tools: ['table', 'graph'],
        iconUrl: TOOLBAR_CATEGORY_ICON_URL.DATA,
        isDisabled: () => this.element.dataCategoryDisabled !== undefined ? this.element.dataCategoryDisabled : true,
      },
      {
        id: TOOLBAR_CATEGORY_ID.SHAPES_PRESET,
        type: EToolCategoryType.MULTI_TOOL,
        tools: ['circle', 'rectangle'],
        iconUrl: TOOLBAR_CATEGORY_ICON_URL.SHAPES_PRESET,
        isDisabled: () => this.element.shapePrestesCategoryDisabled !== undefined ? this.element.shapePrestesCategoryDisabled : true,
      },
    ]
  
    this.toolbarCategoryMap = mapBy(this.toolbarCategory, 'id');
    this.toolbarCategoryFiltered = this.toolbarCategory.filter(category => !category.isDisabled());

    const toolbarCategoryOrdered = []
    if (this.element.toolbarCategoryOrderList){
      this.element.toolbarCategoryOrderList.forEach((categoryId, i) => {
        const category = this.toolbarCategoryFiltered.filter(category => category.id === categoryId)[0]
        if (category) toolbarCategoryOrdered.push(category);
      })
      this.toolbarCategoryFiltered = toolbarCategoryOrdered;
    }
  }


  canvasWidth: number
  canvasHeight: number
  refresh(isZoomChange: boolean = false, isSimulateSubmissionToggle: boolean = false) {
    const zoom = !this.zoom.getScreenShrinkZoom() ? 1 : this.zoom.getScreenShrinkZoom();
    this.canvasWidth = zoom * (this.element.canvasWidth || 500);
    this.canvasHeight= zoom * (this.element.canvasHeight || 500);
    // console.log(this.element.canvasWidth, this.element.canvasHeight)
    if (!this.renderer) {
      this.initRenderer();
    } else {
      this.renderer.resize(this.canvasWidth, this.canvasHeight);
    }

    if(!isZoomChange) {
      this.initStage();
    }

    this.stage.scale.set(zoom);

    if(!isZoomChange) {
      this.stageBorder.beginFill(0x0000, 0);
      this.stageBorder.lineStyle(1, 0xe3e3e3);
      this.stageBorder.drawRect(0,0,this.element.canvasWidth || 500,this.element.canvasHeight || 500)
      this.stageBorder.endFill();
      this.stageBorder.name = 'StageBorder'
      this.stageBorder.zIndex = 11;
      this.initVTCtrl();

      if (isSimulateSubmissionToggle) this.handleNewState();
    } else {
    this.render();
    }
  }

  render() {
    this.stage.sortableChildren = true;
    this.stage.sortChildren();
    this.renderer.render(this.stage);
  }

  initRenderer() {
    this.renderer = PIXI.autoDetectRenderer({ 
      width: this.canvasWidth, 
      height: this.canvasHeight, 
      antialias: true,
      autoDensity: true,
      transparent: true,
      resolution: window.devicePixelRatio,
      clearBeforeRender: true
    });
  }

  initStage() {
    this.stage = new PIXI.Container();
    this.stage.interactive = true;
    this.stageBorder = new PIXI.Graphics();
    this.stage.addChild(this.stageBorder);
  }

  initSpriteLoader(){
    if (!this.spriteLoader) this.spriteLoader = new SpriteLoader();
  }

  addAssetsToSpriteLoader(){
    let assets = [];
    assets.push({
      name: "duplicate", 
      path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/duplicate-2/1684187819438/duplicate-2.png"
    });
    assets.push({
      name: "delete", 
      path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/delete/1684351529074/delete.png"
    });
    assets.push({
      name: "palette", 
      path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/color-palette-3/1685478658551/color-palette-3.png"
    });
    assets.push({
        name: "hflip", 
        path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/flip-vertical/1684187809679/flip-vertical.png"
      }
    );
    assets.push({
      name: "vflip", 
      path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/flip-horizontal/1684187792111/flip-horizontal.png"
    });
    assets.push({
      name: "column", 
      path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/right-arrow/1686793834889/right-arrow.png"
    });
    assets.push({
      name: "row", 
      path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/down-arrow/1686793819835/down-arrow.png"
    });
    assets.push({
      name: "deleteRow", 
      path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/x-2/1686941285169/x-2.png"
    });
    assets.push({
      name: "up", 
      path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/plus/1688752451789/plus.png"
    });
    assets.push({
      name: "down", 
      path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/minus/1688752477734/minus.png"
    });
    assets.push({
      name: "arrow_up", 
      path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/arrow-up-2/1687281115834/arrow-up-2.png"
    });
    assets.push({
      name: "arrow_down", 
      path: "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/arrow-down/1687281034720/arrow-down.png"
    });

    this.spriteLoader.addSpritestoLoader(assets);
  }

  isGlobalRotating = false;
  isGlobalDragging = false;

  controllers: VirtualTools[]
  initVTCtrl() {
    const render = () => {
      this.stage.sortableChildren = true;
      this.stage.sortChildren();
      this.renderer.render(this.stage);
    }

    const addGraphic = (gr: PIXI.DisplayObject) => {
      const map = this.stage.children.map(child => {return {name: child.name, zIndex: child.zIndex}})
      this.stage.addChild(gr);
    };

    this.initSpriteLoader();
    this.addAssetsToSpriteLoader();
    
    this.Background = new Background(this.questionState, <IContentElementVirtualTools>this.element, addGraphic, render, this.getToolStateSub, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging, this.backgroundSprite, this.staticBg, this.printMode);
    this.MeasuringTools = new MeasuringTools(this.questionState, <IContentElementVirtualTools>this.element, addGraphic, render, this.getToolStateSub, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging, LAYER_LEVEL.LEVEL_4);
    this.Counter = new Counter(this.questionState, <IContentElementVirtualTools>this.element, addGraphic, render, this.getToolStateSub, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging, this.backgroundSprite, LAYER_LEVEL.LEVEL_4, this.onColorSelection.bind(this));
    this.Polygon = new Polygon(this.questionState, <IContentElementVirtualTools>this.element, addGraphic, render, this.getToolStateSub, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging);
    this.FreehandPolygon = new FreehandPolygon(this.questionState, <IContentElementVirtualTools>this.element, addGraphic, render, this.getToolStateSub, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging, this.backgroundSprite, this.selectedColor, LAYER_LEVEL.LEVEL_1, this.spriteLoader);
    this.Table = new Table(this.questionState, <IContentElementVirtualTools>this.element, addGraphic, render, this.getToolStateSub, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging, this.backgroundSprite, LAYER_LEVEL.LEVEL_1, this.spriteLoader);
    this.Graph = new Graph(this.questionState, <IContentElementVirtualTools>this.element, addGraphic, render, this.getToolStateSub, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging, this.backgroundSprite, LAYER_LEVEL.LEVEL_1, this.spriteLoader);
    this.FreehandLine = new FreehandLine(this.questionState, <IContentElementVirtualTools>this.element, addGraphic, render, this.getToolStateSub, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging, this.backgroundSprite, this.selectedColor, LAYER_LEVEL.LEVEL_3, this.spriteLoader);
    this.FreehandPencil = new FreehandPencil(this.questionState, <IContentElementVirtualTools>this.element, addGraphic, render, this.getToolStateSub, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging, this.backgroundSprite, this.staticBg, LAYER_LEVEL.LEVEL_2, this.spriteLoader);
    this.FreehandCircleShape = new FreehandCircleShape(this.questionState, <IContentElementVirtualTools>this.element, addGraphic, render, this.getToolStateSub, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging, this.backgroundSprite, this.selectedColor, LAYER_LEVEL.LEVEL_1, this.spriteLoader);
    this.RectangleShape = new FreehandRectangleShape(this.questionState, <IContentElementVirtualTools>this.element, addGraphic, render, this.getToolStateSub, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging, this.backgroundSprite, this.selectedColor, LAYER_LEVEL.LEVEL_1, this.spriteLoader);
    this.FreehandCircleOutline = new FreehandCircleOutline(this.questionState, <IContentElementVirtualTools>this.element, addGraphic, render, this.getToolStateSub, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging, this.backgroundSprite, this.selectedColor, LAYER_LEVEL.LEVEL_3, this.spriteLoader);
    this.RectangleOutline = new FreehandRectangleOutline(this.questionState, <IContentElementVirtualTools>this.element, addGraphic, render, this.getToolStateSub, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging, this.backgroundSprite, this.selectedColor, LAYER_LEVEL.LEVEL_3, this.spriteLoader);

    this.controllers = [this.Background, this.MeasuringTools, this.Counter, this.Polygon, this.FreehandPolygon, this.Table, this.Graph, this.FreehandLine, this.FreehandPencil, this.RectangleShape, this.FreehandCircleOutline, this.RectangleOutline];
    this.initToolbarToolsToggleActionsMap();
  }

  getToolStateSub = () => this.toolStateSubs;

  toolbarToolsToggleActionsMap: {[id:string]: {toolToggleFunc: (mode: boolean | undefined) => boolean | void | PIXI.Graphics, toolIdsToToggle: string[] , canBeToggled: boolean }} ;
  initToolbarToolsToggleActionsMap(){

    const toolIds =  this.toolbarTools.map(tool => tool.id)
    const getToolIdsToToggle = (toolId: string) => {
      const shouldNotAutoToggle = [toolId, 'protractor', 'ruler', 'colorPicker']
      if (toolId === 'pencil') shouldNotAutoToggle.push('marker', 'highlighter');
      if (toolId === 'marker') shouldNotAutoToggle.push('pencil', 'highlighter');
      if (toolId === 'highlighter') shouldNotAutoToggle.push('marker', 'pencil');

      return toolIds.filter(id => shouldNotAutoToggle.indexOf(id) === -1 )
    }

    // TODO cleanup: we should really define which tools can stay active togather and rest of should be toggled off 
    this.toolbarToolsToggleActionsMap= {
      'pencil': {toolToggleFunc: (mode) => this.FreehandPencil.toggleDrawMode(DRAW_MODES.PENCIL, mode) , toolIdsToToggle: getToolIdsToToggle('pencil'), canBeToggled: true},  
      'marker': {toolToggleFunc: (mode) => this.FreehandPencil.toggleDrawMode(DRAW_MODES.MARKER, mode) , toolIdsToToggle: getToolIdsToToggle('marker'), canBeToggled: true},  
      'highlighter': {toolToggleFunc: (mode) => this.FreehandPencil.toggleDrawMode(DRAW_MODES.HIGHLIGHTER, mode) , toolIdsToToggle: getToolIdsToToggle('highlighter'), canBeToggled: true},  
      'eraser': {toolToggleFunc: (mode) => this.FreehandPencil.toggleEraseMode(mode) , toolIdsToToggle: getToolIdsToToggle('eraser') , canBeToggled: true},  
      'text': {toolToggleFunc: () => this.Table.newStandaloneTextbox() , toolIdsToToggle: getToolIdsToToggle('text') , canBeToggled: false},
      'table': {toolToggleFunc: () => this.Table.newTable() , toolIdsToToggle: getToolIdsToToggle('table') , canBeToggled: false},   
      'protractor': {toolToggleFunc: (mode) => this.MeasuringTools.toggleProtractor(mode) , toolIdsToToggle: getToolIdsToToggle('protractor') , canBeToggled: true},
      'ruler': {toolToggleFunc: (mode) => this.MeasuringTools.toggleRuler(mode), toolIdsToToggle: getToolIdsToToggle('ruler') , canBeToggled: true},   
      'counter': {toolToggleFunc: (mode) => this.Counter.toggleCounterMode(mode), toolIdsToToggle: getToolIdsToToggle('counter') , canBeToggled: true},
      'polygon': {toolToggleFunc: (mode) => this.FreehandPolygon.toggleDrawMode(mode) , toolIdsToToggle: getToolIdsToToggle('polygon') , canBeToggled: true}, 
      'line': {toolToggleFunc: (mode) => this.FreehandLine.toggleDrawMode(mode) , toolIdsToToggle: getToolIdsToToggle('line') , canBeToggled: true},
      'circle': {toolToggleFunc: (mode) => this.FreehandCircleShape.toggleDrawMode(mode) , toolIdsToToggle: getToolIdsToToggle('circle')   , canBeToggled: false}, 
      'rectangle': {toolToggleFunc: (mode) => this.RectangleShape.toggleDrawMode(mode) , toolIdsToToggle: getToolIdsToToggle('rectangle') , canBeToggled: false},
      'circle_outline': {toolToggleFunc: (mode) => this.FreehandCircleOutline.toggleDrawMode(mode) , toolIdsToToggle: getToolIdsToToggle('circle_outline') , canBeToggled: true}, 
      'rectangle_outline': {toolToggleFunc: (mode) => this.RectangleOutline.toggleDrawMode(mode) , toolIdsToToggle: getToolIdsToToggle('rectangle_outline') , canBeToggled: true},
      'graph': {toolToggleFunc: (mode) => this.Graph.toggleGraph(mode) , toolIdsToToggle: getToolIdsToToggle('graph') , canBeToggled: true},
      'selector': {toolToggleFunc: (mode) => this.toggleSelector(mode) , toolIdsToToggle: getToolIdsToToggle('selector') , canBeToggled: true},
      'colorPicker': {toolToggleFunc: (mode) => this.toggleColorPalette(mode) , toolIdsToToggle: [], canBeToggled: true}
    }
  }

  toggleRuler(currentToolId) {
    let toggled = false;
    if(!this.element.isRulerDisabled){
      toggled = this.MeasuringTools.toggleRuler();
    }

    if(toggled) {
      this.toggleToolCleanUp(currentToolId)
      this.extendedTools = {};
    }
  }

  toggleProtractor(currentToolId) {
    let toggled = false;
    if(!this.element.isProtractorDisabled){
      toggled = this.MeasuringTools.toggleProtractor();
    }

    if(toggled) {
      this.toggleToolCleanUp(currentToolId)
      this.extendedTools = {};
    }
  }

  toggleCounter(currentToolId) {
    this.toggleToolCleanUp(currentToolId);

    if(!this.element.isCounterDisabled) {
      this.extendedTools = this.Counter.toggleCounterMode() ? this.Counter.getCounterTools() : {};
    }
  }

  toggleFreehandPolygon(currentToolId) {
    this.toggleToolCleanUp(currentToolId)

    if(!this.element.fhPolygonDisabled) {
      this.extendedTools = this.FreehandPolygon.toggleDrawMode() ? this.FreehandPolygon.getPolygonTools() : {};
    }
  }

  showColorPalette = false;
  toggleColorPalette(toolId, mode?: boolean){
    // this.showColorPalette = mode != null && mode != undefined ? mode : !this.showColorPalette;
    const colorCategory = this.toolbarCategoryMap.get('PALETTE')
    this.showColorPalette = !this.showColorPalette;
  }

  // selectedColor = this.getParsedColor('#000000')
  selectedColor = {color: this.getParsedColor('#333333'), string: '#333333'}

  // #TODO: Need to make color controller
  // - colors array should come from controller
  // - all color related action should be handled in the controller
  // - selected color should be passed down to different controllers 
  
  onColorSelection(color: string){
    this.selectedColor.color = this.getParsedColor(color);
    this.selectedColor.string = color;

    this.toolStateSubs.colorPicker.next({val: false, color: this.selectedColor, origin: 'BASE' }) 

    if (this.Counter.isCounterMode){
      this.Counter.lastSelectedCounterColor = this.selectedColor.color;
    }
  }

  getParsedColor(hex: string) {
    return PIXI.utils.string2hex(hex);
  }

  toggleFreehandLine(currentToolId) {
    this.toggleToolCleanUp(currentToolId)

    if(!this.element.fhLineDisabled) {
      this.extendedTools = this.FreehandLine.toggleDrawMode() ? this.FreehandLine.getLineTools() : {};
    }
  }

  toggleFreehandCircle(currentToolId) {
    this.toggleToolCleanUp(currentToolId)

    if(!this.element.fhCircleShapeDisabled) {
      this.extendedTools = this.FreehandCircleShape.toggleDrawMode() ? this.FreehandCircleShape.getCircleTools() : {};
      this.extendedTools = {};
    }
  }

  toggleRectangle(currentToolId) {
    this.toggleToolCleanUp(currentToolId)

    if(!this.element.fhRectangleShapeDisabled) {
      this.extendedTools = this.RectangleShape.toggleDrawMode() ? this.RectangleShape.getRectangleTools() : {};
      this.extendedTools = {};
    }
  }

  toggleFreehandCircleOutline(currentToolId) {
    this.toggleToolCleanUp(currentToolId)

    if(!this.element.fhCircleOutlineDisabled) {
      this.extendedTools = this.FreehandCircleOutline.toggleDrawMode() ? this.FreehandCircleOutline.getCircleTools() : {};
    }
  }

  toggleRectangleOutline(currentToolId) {
    this.toggleToolCleanUp(currentToolId)

    if(!this.element.fhRectangleOutlineDisabled) {
      this.RectangleOutline.toggleDrawMode()
      // this.extendedTools = this.RectangleOutline.toggleDrawMode() ? this.RectangleOutline.getRectangleTools() : {};
    }
  }

  toggleGraph(currentToolId) {
    let toggled = false;
    if(!this.element.isGraphDisabled){
      toggled = this.Graph.toggleGraph();
    }

    if(toggled) {
      this.toggleToolCleanUp(currentToolId)
      this.extendedTools = {};
    }
  }

  toggleFreehandPencil(currentToolId) {
    const toggled = this.FreehandPencil.toggleDrawMode(DRAW_MODES.PENCIL);

    if(toggled) {
      this.toggleToolCleanUp(currentToolId)
    }

    this.extendedTools = toggled ? this.FreehandPencil.getPencilTools() : {};
  }

  toggleFreehandMarker(currentToolId) {
    const toggled = this.FreehandPencil.toggleDrawMode(DRAW_MODES.MARKER);

    if(toggled) {
      this.toggleToolCleanUp(currentToolId)
    }

    this.extendedTools = toggled ? this.FreehandPencil.getPencilTools() : {};
  }

  toggleFreehandHighlighter(currentToolId) {
    const toggled = this.FreehandPencil.toggleDrawMode(DRAW_MODES.HIGHLIGHTER);

    if(toggled) {
      this.toggleToolCleanUp(currentToolId)
    }

    this.extendedTools = toggled ? this.FreehandPencil.getPencilTools() : {};
  }

  toggleFreehandEraser(currentToolId) {
    const toggled = this.FreehandPencil.toggleEraseMode();

    if(toggled) {
      this.toggleToolCleanUp(currentToolId)
    }
  }

  toggleSelector(val?: boolean, skipPub?:boolean) {
    this.selectorToolState =  val != null ? val : !this.selectorToolState
    
    if(this.selectorToolState){
      this.toggleToolCleanUp('selector');
      this.extendedTools = {}
    }

    if(!skipPub) this.toolStateSubs.selector.next({val: this.selectorToolState, origin: 'BASE' })    
  }

  private _selectionToolToggleFromChild(o){
    // console.log('sub from child called')
    this.onToolBarCategoryClick('SELECTION', true)
    this.toggleSelector(o.val, true)
  }

  createTextbox(currentToolId) {
    this.toggleToolCleanUp(currentToolId)
    this.extendedTools = {};

    this.Table.newStandaloneTextbox();
  }

  createTable(currentToolId) {
    this.toggleToolCleanUp(currentToolId)
    this.extendedTools = {};

    this.Table.newTable()
  }

  // Toolbar category

  onToolBarCategoryClick(categoryId: string, skipToggle?: boolean){
    if (this.isQuestionLocked()) return;

    if (categoryId !== 'PALETTE'){
      this.showColorPalette = false;
      if(categoryId === this.currentSelectedToolCategoryId){
        this.currentSelectedToolCategoryId = undefined
      } else {
        this.currentSelectedToolCategoryId = categoryId
      }
    }

    const category = this.toolbarCategoryMap.get(categoryId);
    const toolIds = category.tools;
    
    // toggle off any selected tools
    if(category.type === EToolCategoryType.MULTI_TOOL){
      this.toolbarTools.forEach(tool => {
        const shouldNotAutoToggle = ['protractor', 'ruler', 'colorPicker']
        if(shouldNotAutoToggle.indexOf(tool.id) === -1 && tool.isSelected()){          
          this.toolbarToolsToggleActionsMap[tool.id].toolToggleFunc(false);
        }
      })
    }

    if(category.type === EToolCategoryType.SINGLE_TOOL && !skipToggle){
      
      if(!toolIds.length) return console.log('Category has no tools');
      const toolId = toolIds[0];
      const tool = this.toolbarToolsMap.get(toolId)
      tool.toggle(toolId);
    }
    

  }

  isCategorySelected(categoryId:string) {
    if (categoryId == 'PALETTE') return this.showColorPalette;
    return this.currentSelectedToolCategoryId === categoryId;
  }

  showCategoryTools(category){
    return this.currentSelectedToolCategoryId && category.id === this.currentSelectedToolCategoryId;
  }

  showCategoryMultiToolsMenu(category){
    return this.showCategoryTools(category) && this.isCategoryMultiTool(this.currentSelectedToolCategoryId)
  }

  showCategorySingleToolsMenu(category){
    return this.showCategoryTools(category) && !this.isCategoryMultiTool(this.currentSelectedToolCategoryId)
  }

  getCurrentSelectedCategory(){
    return this.toolbarCategoryMap.get(this.currentSelectedToolCategoryId);
  }

  isCategoryMultiTool(categoryId: string){
    return this.toolbarCategoryMap.get(categoryId).type === EToolCategoryType.MULTI_TOOL
  }

  getCategoryTools(categoryId) {
    const categoryToolIds = this.toolbarCategoryMap.get(categoryId).tools
    if(!categoryToolIds.length) return []
    return categoryToolIds.map(toolId => this.toolbarToolsMap.get(toolId)) ;  
  }


  isObjectEmpty(obj) {
    return Object.keys(obj).length == 0
  }

  disableHC() {
    const style = {}
    if (this.textToSpeech.isHiContrast) {
      style["filter"]="invert(0)";
    }
    return style;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.changeCounter && changes.changeCounter.currentValue !== undefined && !changes.changeCounter.firstChange) {
      this.questionState[this.element.entryId] = undefined; //(when we are not essentially performing an ngOnInit)
    }

    if(changes.changeCounter?.firstChange) {
      this.initRenderer();
      this.initStage();
      // this.initVTCtrl();   Probably not necessary as it will be called inside the this.refresh();
    }

    const isSimlationSubmissionToggle = changes.isLocked && !changes.isLocked.firstChange
    this.refresh(false, isSimlationSubmissionToggle);
  }

  // toggle state cleanup
  toggleToolCleanUp(currentToggleToolId: string){
    // toggle related other tools to false
    if(!currentToggleToolId) return console.log('Invalid Toggle Id')
    const {toolIdsToToggle} = this.toolbarToolsToggleActionsMap[currentToggleToolId]
    
    toolIdsToToggle?.forEach(toolId => {
      const {canBeToggled, toolToggleFunc} = this.toolbarToolsToggleActionsMap[toolId]
      if(canBeToggled){
        try {
          if(this.toolbarToolsMap.get(toolId).isSelected()) toolToggleFunc(false)
        } catch (error) {
          console.log(error)
        }
      } 
    })

  }

  toolStateSubscriptions = new Map<string, Subscription>();
  private initToolStateSubChangeFromChild(){
    const toolStateSub = this.getToolStateSub();

    const applyChange = (key: string, o: {val: boolean, origin: 'BASE' | 'CHILD'}, callbck: (t) => any) => {
      if(o.origin === 'CHILD') callbck(o);
    }

    Object.keys(toolStateSub).forEach(key => {
      switch (key) {
        case 'selector':
          const sub = toolStateSub[key].subscribe(o => applyChange(key, o, this._selectionToolToggleFromChild.bind(this)));
          this.toolStateSubscriptions.set(key, sub);
          break;      
        default:
          break;
      }
    })
  }

  isColorPaletteAndShowing(category){
    return category.id === 'PALETTE' && this.showColorPalette;
  }

  handleNewState(){
    if (this.questionState){
      const entryState:IEntryStateVirtualTools = this.questionState[this.element.entryId];
      if (entryState){
        this.injectStateToDom(entryState)
      }
      else{
        this.ensureState();
      }
    }
  }

  injectStateToDom(entryState: IEntryStateVirtualTools) {
    // this.controllers.forEach((ctrl) => {
    //   if (!ctrl.isInited){
    //     ctrl.loadAssets().then(() => ctrl.handleNewState());
    //   }
    // })
    this.Table.TextBox.loadAssets().then(() => this.Table.TextBox.handleNewState());
    this.Table.loadAssets().then(() => this.Table.handleNewState());
    this.Counter.loadAssets().then(() => this.Counter.handleNewState());
    this.FreehandPolygon.loadAssets().then(() => this.FreehandPolygon.handleNewState());
    this.FreehandPencil.loadAssets().then(() => this.FreehandPencil.handleNewState());
    this.Graph.loadAssets().then(() => this.Graph.handleNewState());
    this.FreehandLine.loadAssets().then(() => this.FreehandLine.handleNewState());
    this.FreehandCircleOutline.loadAssets().then(() => this.FreehandCircleOutline.handleNewState());
    this.RectangleOutline.loadAssets().then(() => this.RectangleOutline.handleNewState());
    this.FreehandCircleShape.loadAssets().then(() => this.FreehandCircleShape.handleNewState());
    this.RectangleShape.loadAssets().then(() => this.RectangleShape.handleNewState());
  }

  ensureState(){
    if (this.questionState){
      const entryId = this.element.entryId;
        if (!this.questionState[entryId]){
          const dataMap = {};
          
          for (let item in EPixiTools) {
            if (isNaN(Number(item))) {
                dataMap[item] = null;
            }
        }
        let entryState:IEntryStateVirtualTools = {
          type: ElementType.VIRTUAL_TOOLS,
          data: dataMap,
          isCorrect: false,
          isStarted: true,
          isFilled: this.element.isOptional? true : false,
          isResponded: this.element.isOptional? true : false,
          score: 0,
          weight: getElementWeight(this.element),
          scoring_type: ScoringTypes.MANUAL, 
        }
        this.questionState[entryId] = entryState;
      }
    }
  }

  isQuestionLocked(){
    return this.isLocked || this.printMode.isActive;
  }

  disableEdgeMenu(e: MouseEvent) {
    e.preventDefault();
  }
  edgeMenuListener = this.disableEdgeMenu.bind(this);
  addMouseUpListener() {
    window.addEventListener("mouseup", this.edgeMenuListener);
    window.addEventListener("mouseup", this.Table.TextBox.deselectAll_document_bind_this);
  }

  removeMouseUpListener() {
    window.removeEventListener("mouseup", this.edgeMenuListener);
    window.removeEventListener("mouseup", this.Table.TextBox.deselectAll_document_bind_this);
  }

  // Apply the preset on render so that any updates to existing presets will be automatically applied.
  ensureToolbarPreset(){
    if (this.element.toolbarSettingPreset === 'Custom') 
      return;

    if (this.element.toolbarSettingPreset && TOOLBAR_CONFIG_PRESETS[this.element.toolbarSettingPreset]) 
      this.applyToolbarPreset(TOOLBAR_CONFIG_PRESETS[this.element.toolbarSettingPreset]);
  }

  ensureToolbarOrderList(){
    if (!this.element.toolbarCategoryOrderList)
      this.element.toolbarCategoryOrderList = DEFAULT_TOOLBAR_ORDER_LIST;
  }
}
