import { IPanelLink } from "./type";

export const teacherPanels:IPanelLink[] = [
    {
        caption: 'lbl_classes_groups_ABED', // Class Sections for ABED
        routerLink: '/en/educator/classrooms',
        requiresClassroom: false,
        iconUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/website%2Ficons%2Ficon-classroom.png?alt=media&token=ce81d29b-8760-4154-a62e-c1da80903828',
    },
    {
        caption: 'g9_edu_Assess', // Assessments
        routerLink: '/en/educator/classrooms/:classroomId',
        requiresClassroom: true,
        iconUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/website%2Ficons%2Ficons-assessment.png?alt=media&token=e6aa3f51-1daf-4b08-ab11-7ae19b0e8b05',
    },
    // {
    //     caption: 'Booster Packs',
    //     routerLink: '/teacher/classroom/:classroomId/boosterpacks',
    //     requiresClassroom: true,
    //     iconUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/website%2Ficons%2Ficons-boosterpacks.png?alt=media&token=2142f93e-c2a5-4eaf-9465-4af843253153'
    // },
    {
        caption: 'g9_edu_stu', // Students
        routerLink: '/en/educator/students/:classroomId',
        requiresClassroom: true,
        iconUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/website%2Ficons%2Ficons-students.png?alt=media&token=1bb26aa0-e615-434e-aa7e-a97ebc52976a',
    },
    // {
    //     caption: 'g9_edu_reports',
    //     routerLink: '/en/educator/classrooms/:classroomId',
    //     requiresClassroom: true,
    //     iconUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/website%2Ficons%2Ficons-reports.png?alt=media&token=1d6305b0-ad8a-4f4d-a1e7-957ac05801ce'
    // },
]