import { Injectable } from "@angular/core";
import { Moment, utc } from "moment";
import { AuthService } from "../api/auth.service";
import { RoutesService } from "../api/routes.service";
import { AssessmentComponent, AssessmentType, TestWindow } from "../bc-assessments/bc-assessments.service";
import { AccountType } from "../constants/account-types";
import { RequestStatus as AegrotatRequestStatus } from "../ui-ministryadmin/bcg-student-info-modal/bcg-aegrotat-tab/bcg-aegrotat-tab.component";
import { RequestStatus as DisqualificationRequestStatus } from "../ui-ministryadmin/bcg-student-info-modal/bcg-disqualification-tab/bcg-disqualification-tab.component";
import { RequestStatus as SpecialFormatsRequestStatus } from "../ui-ministryadmin/bcg-student-info-modal/bcg-special-formats-tab/bcg-special-formats-tab.component";

export type PaginatedRows<T> = {
    count: number,
    data: T[],
};

export interface Pagination {
    skip: number,
    size: number,
    orderBy?: string,
    orderDirection?: 'asc' | 'desc' | 1 | -1,
    filters?: Filter[]
    count?: number,
    isLoading?: boolean
}

export const PAGE_SIZE = 20;


export enum FilterCondition {
    LIKE = 'like',
    MATCH = 'match',
    DONT_MATCH = 'dont match',
    MATCH_FIELD = 'match field',
    DONT_MATCH_FIELD = 'dont match field',
    CUSTOM = 'custom',
}

export interface Filter {
    field: string,
    value: string | number,
    condition: FilterCondition,
}

export interface District {
    name: string
    groupId: number
    foreignId: number
}

export interface School {
    groupId: number,
    foreignId: number,
    name: string,
}


export const ALL_DISTRICT: District = {
    name: 'All Districts',
    foreignId: -1,
    groupId: -1,
}

export const ALL_SCHOOLS: School = {
    groupId: -1,
    foreignId: -1,
    name: 'All Schools',
}

export interface DistrictAdminRow {
    firstName: string,
    lastName: string,
    contactEmail: string,
    district: number,
    districtGroupId: number,
    numSchools: number,
}

export interface GradDistrictAdminRow {
    firstName: string,
    lastName: string,
    district: number,
    districtGroupId: number,
    numSchools: number,
    nme10: number,
    nmf10: number,
    lte10: number,
    ltp10: number,
    lte12: number,
    ltp12: number,
    ltf12: number
}

export interface DistrictGradeCount {
    district: number,
    feb_g4_count: number,
    feb_g7_count: number,
    sept_g4_count: number,
    sept_g7_count: number,
}

export interface DistrictAdminRow {
    uid: number,
    first_name: string,
    last_name: string,
    contact_email: string,
    account_type: string,
    district_name: string,
    district_group_id: number,
    district_code: number,
    invite_created_on: string,
    invite_used_by_uid: number,
    invite_used_on: string,
    is_revoked: number,
    _isOdd?: boolean,
    _isFirstRow?: boolean,
    _displayButton?: boolean
}

export interface SchoolAdminRow {
    checked: boolean,
    uid: number,
    first_name: string,
    last_name: string,
    email: string,
    account_type: AccountType,
    school_name: string,
    school_code: number,
    school_group_id: number,
    school_type: string,
    district: number,
    is_participating_g4: boolean,
    is_participating_g7: boolean,
    invite_created_on: Moment,
    invite_used_by_uid: number,
    invite_used_on: Moment,
    has_invites: boolean,
    is_revoked: boolean,
    _isOdd?: boolean,
    _isFirstRow?: boolean,
    _displayButton?: boolean
}

export interface GradSchoolAdminRow extends SchoolAdminRow {
    nme10: number,
    nmf10: number,
    lte10: number,
    ltp10: number,
    lte12: number,
    ltp12: number,
    ltf12: number
}

export interface SchoolGradeCount {
    school: number,
    feb_g4_count: number,
    feb_g7_count: number,
    sept_g4_count: number,
    sept_g7_count: number,
}

export interface Score {
    uid: number,
    score: string,
}

export interface StudentRow {
    uid: number,
    checked: boolean
    school: string,
    schoolId: number,
    district: number,
    firstName: string,
    lastName: string,
    pen: string,
    grade: number,
    progress: {
        srNumeracy: number,
        srLiteracy: number,
        crScoreEntry: number,
    },
    isNew: boolean,
    validationCodes: string,
    sept: string,
    feb: string,
    adhoc: string,
    yukonSept: string,
    yukonFeb: string,
    mergedToPen: string,
    isWalkIn: boolean,
    unenrolled: boolean,
}

export interface InvigilatorRow {
    uid: number,
    firstName: string,
    lastName: string,
    school: string,
    schoolId: number,
    district: number,
}

export interface InvitationStatus {
    uid: number,
    createdOn: Date,
    status: string,
}

export interface SummaryInfo {
    districtAdmins: {
        count: number,
        pendingInvitations: number,
    },
    schoolAdmins: {
        count: number,
        pendingInvitations: number,
    },
    invigilators: {
        count: number,
        pendingInvitations: number,
    },
    students: {
        count: number,
        g4Count: number,
        g7Count: number,
    }
}

export interface GradSummaryInfo {
    districtAdmins: {
        count: number,
        pendingInvitations: number,
    },
    schoolAdmins: {
        count: number,
        pendingInvitations: number,
    },
    students: {
        count: number,
        lte10: number,
        ltp10: number,
        nme10: number,
        nmf10: number,
        lte12: number,
        ltp12: number,
        ltf12: number,
    }
}

export interface StudentRow2 {
    adhoc: string | number,
    crLiteracy: number,
    crNumeracy: number,
    district: number
    dup_name: string
    exempted: number
    exemption_reason: string,
    date_exempted: string
    feb: string
    first_load: number
    first_name: string
    grade: string
    is_walk_in: string
    last_name: string
    merged_to_pen: string
    pen: string
    school: string
    school_name: string
    school_group_id: number
    is_school_participating: number
    second_load: number
    sept: string
    srLiteracy: number
    srNumeracy: number
    uid: number
    unenrolled: number
    unenrolled_text: string,
    date_unenrolled: string
    yukon_first: string
    yukon_second: string
}

export interface SchoolCompletionReport {
    schoolName: string,
    schoolCode: number,
    schoolType: string,
    grade: number,
    submitted1: number,
    submitted2: number,
    submitted3: number,
    total: number,
}

export interface DistrictCompletionReport {
    submitted1: number,
    submitted2: number,
    submitted3: number,
    total: number,
}

export interface DistrictDetail {
    address: string,
    city: string,
    createdOn: Date,
    foreignId: number
    groupId: number
    name: string,
    province: string
}

export interface SchoolDetailAddress {
    address_1: string,
    address_2?: string,
    city: string,
    province: string,
}
export interface SchoolDetail {
    address: string,
    city: string,
    district: DistrictDetail,
    foreignId: number,
    groupId: number,
    isFsa: boolean,
    isGrad: boolean,
    name: string,
    grades: (string | number)[],
    phone_number: string,
    fax_number: string,
    mailing_address: SchoolDetailAddress,
    physical_address?: SchoolDetailAddress,
}

export interface LoginRow {
    firstName: string,
    lastName: string,
    pen: string,
    loginAt: Moment,
    typeSlug: string,
}

export interface SchoolLoginRow {
    school: School,
    total: number,
    componentCount: Record<string, number>,
}

export interface DistrictLoginRow {
    district: District,
    total: number,
    componentCount: Record<string, number>,
}

export interface ProvinceLoginRow {
    total: number,
    componentCount: Record<string, number>,
}

export interface SaStudentRow {
    checked: boolean,
    uid: number,
    pen: string,
    first_name: string,
    last_name: string,
    preferred_name: string,
    district_id: number
    school_id: number,
    school_name: string,
    pen_conflict?: number,
    is_walk_in: number,
    validationCodes: string[],
    is_validated: number,
    unenrolled: number,
    confirmation_code: string,
    historic_attempts: number,
    aegrotatStatus: AegrotatRequestStatus,
    disqualificationStatus: DisqualificationRequestStatus,
    specialFormatStatus: SpecialFormatsRequestStatus,
    is_submitted: number,
}

export interface AssessmentStageOneDataReportRow {
    component: string,
    sessionStatus: number,
    registration: number,
    submissionsWrote: number,
    largePrintAssessment: number,
    brailleAssessment: number,
    studentsNew: number,
    firstWrites: number,
    blockedWrites: number,
}

export interface AssessmentStageTwoDataReportRow {
    component: string,
    sessionStatus: number,
    registration: number,
    submissionsWrote: number,
    markingStatus: string,
    totalSubmitted: number,
    marked: number,
    notMarked: number,
    reviewItemsComplete: number,
    numberOfScans: number,
    numberOfProblemScans: number,
    numberOfMissingScans: number,
}

export interface AssessmentStageThreeDataReportRow {
    component: string,
    sessionStatus: number,
    registration: number,
    submissionsWrote: number,
    statusText: string,
    notWrittenAtSchool: number,
    totalSpecialCases: number,
    specialCaseQ: number,
    specialCaseA: number,
    studentsInEquatingPool: number,
    studentsWithProfLevel: number,
    missingSelectedResponses: number,
    missingScannedPages: number,
}

export interface AssessmentStageFourDataReportRow {
    component: string,
    sessionStatus: number,
    registration: number,
    submissionsWrote: number,
    statusText: string,
    studentsWhoWrote: number,
    studentsReceivedResults: number,
    studentsDidNotReceiveResults: number,
    studentsReleased: number,
}

export enum StudentRequestType {
    SPECIAL_FORMATS = 'special_formats',
    DISQUALIFICATION = 'disqualification',
    AEGROTAT = 'aegrotat',
}

export interface CreateDbStudentRequest {
    uid: number,
    type: StudentRequestType,
    status: string,
    test_session_id: number,
    form: string,
    form_type: 'JSON' | 'link'
}

export interface DbStudentRequest {
    id: number,
    uid: number,
    type: StudentRequestType,
    status: string,
    test_session_id: number,
    form: string,
    form_type: 'JSON' | 'link',
    created_by_uid: number,
    created_by: string,
    created_on: string,
}

export interface SchoolParticipationRow {
    school_group_id: number,
    district_code: number,
    school_name: string,
    school_code: number,
    school_type: string,
    grade_4_participating: boolean,
    grade_7_participating: boolean,
    grade_4_print_materials: boolean,
    grade_7_print_materials: boolean,
    grades: (string | number)[],
    has_students: boolean,
}

export interface SpecialMaterialRequestRow {
    district_code: number,
    school_group_id: number,
    school_name: string,
    school_code: number,
    is_participating: boolean,
    large_print_g4: number
    large_print_g7: number,
    braille_g4: number,
    braille_g7: number,
    has_g4: boolean,
    has_g7: boolean,
}


export type PrintOrderSendTo = 'Schools' | 'District First';
export interface PrintOrderRow {
    district_group_id: number,
    print_order: number,
    district_code: number,
    district_name: string,
    print_order_send_to: PrintOrderSendTo,
}

export interface PrintPackagePreviewGradeQuantity {
    collaboration_booklets_quantity: number,
    student_response_booklets_quantity: number,
    reg_print_quantity: number,
    large_print_quantity: number,
    possible_solutions_quantity: number,
    braille_quantity: number,
}

export interface PrintPackagePreview {
    school_name: string,
    school_code: number,
    school_grades: (string | number)[],
    school_separator_form_quantity: number,
    fsa_administration_manual_quantity: number,
    list_of_students_form_quantity_g4?: number,
    list_of_students_form_quantity_g7?: number,
    scoring_guide_quantity: number,
    packing_slip_quantity: number,
    password_information_sheet_quantity: number,
    grade_4_quantity?: PrintPackagePreviewGradeQuantity,
    grade_7_quantity?: PrintPackagePreviewGradeQuantity,
}

enum SupplementaryPrintOrderStatus {
    PENDING = 'Pending',
    NO_PRINT_ORDER = 'No Print Order',
    APPROVED = 'Approved',
}

export interface SupplementaryPrintOrderMaterial {
    name: string,
    quantity: number,
}
export interface SupplementaryPrintOrderRow {
    status: SupplementaryPrintOrderStatus,
    school_name: string,
    school_group_id: number,
    district_id: number,
    materials: SupplementaryPrintOrderMaterial[],
    request_date: Moment,
    received_date: Moment,
}

export interface ActivityLog {
    id: number,
    test_window_id: number,
    uid: number,
    created_on: Moment,
    message: string,
}

export enum LookupResult {
    NOT_EXISTS = 'not exists',
    EXISTS_IN_THIS_SCHOOL = 'exists in this school',
    EXISTS_IN_ANOTHER_SCHOOL = 'exists in another school',
    EXISTS_BUT_NO_SCHOOL = 'exists but no school',
}


export enum SchoolLevelDataReportProficiencyLevel {
    PLU = 'PLU',
    EMERGING = 'EM',
    ON_TRACK = 'OT',
    EXTENDING = 'EX',
}

export interface SchoolLevelDataReportRow {
    last_name: string,
    first_name: string,
    pen: string,
    grade: number,

    li_sr_attempted: number,
    li_sr_score: number,
    li_1_score: number,
    li_2_score: number,
    li_3_score: number,
    li_proficiency_level: SchoolLevelDataReportProficiencyLevel,

    nu_sr_attempted: number,
    nu_sr_score: number,
    nu_1_score: number,
    nu_2_score: number,
    nu_3_score: number,
    nu_proficiency_level: SchoolLevelDataReportProficiencyLevel,
}

export interface SchoolLevelDataReport {
    school_group_id: number,
    school_code: number,
    school_name: string,
    is_paginated: boolean,
    data: PaginatedRows<SchoolLevelDataReportRow> | SchoolLevelDataReportRow[],
}

export interface ItemDataReportRow {
    assessment_code: string,
    form: string,
    path_and_task: string,
    item_name: string,
    item_type: string,
    cumulative_count: number,
    scan_submissions: number,
    cr_submissions: number,
    not_selected: number,
}

export enum FsaDataLoad {
    FEBRUARY = 'feb',
    SEPTEMBER = 'sept',
}

export interface ConfirmationCode {
    id: number,
    test_session_id: number,
    test_attempt_id: number,
    uid: number,
    confirmation_code: string,
    created_on: Moment,
    is_unsubmitted: boolean,
    unsubmitted_on?: Moment,
}

export enum AdminRole {
    ADMINISTRATOR = 'sa_dashboard_school_admin',
    SCORE_ENTRY = 'role_score_entry', // 'sa_score_entry_bc',
}

export interface CreateSchoolAdminData {
    role: AdminRole,
    first_name: string,
    last_name: string,
    email: string,
    school_group_id: number,
}

export interface CreateDistrictAdminData {
    role: AdminRole,
    first_name: string,
    last_name: string,
    email: string,
    district_group_id: number,
}

@Injectable({
    providedIn: 'root'
})
export class BcAccountsService {

    private districts: District[] | null;
    private schools: Map<number, School[]>;
    private districtGradeCount: DistrictGradeCount[] | null;
    private schoolGradeCount: SchoolGradeCount[] | null;
    private scores: Score[] | null;
    private invigilatorsInvitations: InvitationStatus[] | null;

    private summary: SummaryInfo | null;

    constructor(private auth: AuthService, private routes: RoutesService) {
        this.districts = null;
        this.schools = new Map();
        this.districtGradeCount = null;
        this.schoolGradeCount = null;
        this.scores = null;
        this.invigilatorsInvitations = null;
        this.summary = null;
    }

    // school completion report

    async countSchoolCompletionReport(pagination: Pagination, district?: number): Promise<number> {
        const count = await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'count',
                count: 'school completion',
                district: district,
                pagination
            }
        })
        return count.count;
    }

    async exportSchoolCompletionReport(pagination: Pagination, district?: number) {

        window.open(this.auth.reportFilePath('public/bc-admin-coordinator/reports', JSON.stringify({
            query: {
                action: 'report',
                report: 'school completion',
                district: district,
                pagination,
            }
        }), `School-Completion-Report (${new Date().toISOString().slice(0, 10)})`), '_blank');
    }

    async getSchoolCompletionReport(pagination: Pagination, district?: number): Promise<SchoolCompletionReport[]> {
        const reports = await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'report',
                report: 'school completion',
                district: district,
                pagination,
            }
        })
        return reports.map(r => ({
            schoolName: r.school_name,
            schoolCode: parseInt(r.school_code),
            schoolType: r.school_type,
            grade: parseInt(r.grade),
            submitted1: parseInt(r.submitted1),
            submitted2: parseInt(r.submitted2),
            submitted3: parseInt(r.submitted3),
            total: parseInt(r.total),
        }))
    }

    async getDistrictCompletionReport(district: number): Promise<DistrictCompletionReport[]> {
        const report = await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'report',
                report: 'district completion',
                district,
            }
        });
        return report.map(r => ({
            submitted1: r.submitted1,
            submitted2: r.submitted2,
            submitted3: r.submitted3,
            total: r.total
        }));
    }

    // simulations

    makeSeptLoad() {
        return this.auth.apiUpdate('public/bc-admin-coordinator/registration', 0, {}, {
            query: {
                action: 'make sept load'
            }
        })
    }

    resetSeptLoad() {
        return this.auth.apiUpdate('public/bc-admin-coordinator/registration', 0, {}, {
            query: {
                action: 'reset sept load'
            }
        })
    }

    resetAttempts() {
        return this.auth.apiUpdate('public/bc-admin-coordinator/registration', 0, {}, {
            query: {
                action: 'reset attempts',
            }
        });
    }

    resetSubmissions() {
        return this.auth.apiUpdate('public/bc-admin-coordinator/registration', 0, {}, {
            query: {
                action: 'reset submissions',
            }
        })
    }

    startAttempts() {
        return this.auth.apiUpdate('public/bc-admin-coordinator/registration', 0, {}, {
            query: {
                action: 'start attempts'
            }
        });
    }

    makeSubmissions() {
        return this.auth.apiUpdate('public/bc-admin-coordinator/registration', 0, {}, {
            query: {
                action: 'make submissions',
            }
        });
    }

    // summary

    async makeSummary(): Promise<SummaryInfo> {
        if (this.summary !== null) return this.summary;

        const [daCount, daIS, saCount, saIS, inCount, inIS, distGradeCount] = await Promise.all([
            this.countDistrictAdmins(this.getInitialPagination()),
            this.findDistrictAdminsInvitationStatus(),

            this.countSchoolAdmins(this.getInitialPagination()),
            this.findSchoolAdminsInvitationStatus(),

            this.countInvigilators(this.getInitialPagination()),
            this.findInvigilatorsInvitationStatus(),

            // this.countStudnets(this.getInitialPagination()),

            this.countDistrictGrade(),
        ]);

        const countPending = (is: InvitationStatus[]): number => {
            return is.filter(a => a.status === 'pending').length;
        }

        let g4Count: number = 0;
        let g7Count: number = 0;
        distGradeCount.map(count => {
            g4Count += parseInt(count.feb_g4_count.toString());
            g7Count += parseInt(count.feb_g7_count.toString());
        })

        this.summary = {
            districtAdmins: {
                count: daCount,
                pendingInvitations: countPending(daIS),
            },
            schoolAdmins: {
                count: saCount,
                pendingInvitations: countPending(saIS),
            },
            invigilators: {
                count: inCount,
                pendingInvitations: countPending(inIS),
            },
            students: {
                count: 0,
                g4Count,
                g7Count
            }
        };
        return this.summary;
    }

    getInitialPagination(): Pagination {
        return {
            skip: 0,
            size: PAGE_SIZE,
            isLoading: false
        }
    }

    // Districts & Schools

    async findDistricts(): Promise<District[]> {
        if (this.districts !== null) return this.districts;

        const districts = await this.auth.apiFind('public/bc-admin-coordinator/accounts', {
            query: {
                action: 'districts',
            }
        })
        this.districts = districts.map(d => ({
            foreignId: d.foreign_id,
            name: d.name,
            groupId: d.group_id,
        }));
        this.districts.unshift(ALL_DISTRICT);
        return this.districts;
    }

    async findSchools(district?: number, assessment: 'grad' | 'fsa' = 'fsa'): Promise<School[]> {
        if (district === undefined && this.schools.has(-1)) return this.schools.get(-1);
        if (this.schools.has(district)) return this.schools.get(district);

        const schools = (await this.auth.apiFind('public/bc-admin-coordinator/accounts', {
            query: {
                action: 'schools',
                district: district === -1 || !district ? undefined : district,
                assessment,
            }
        })).map(s => ({
            groupId: s.group_id,
            foreignId: s.foreign_id,
            name: s.name,
        }));

        schools.unshift(ALL_SCHOOLS);
        if (district === undefined) {
            this.schools.set(-1, schools)
        } else {
            this.schools.set(district, schools);
        }

        return schools;
    }

    async getDistrictDetail(districtGroupId: number): Promise<DistrictDetail> {
        const district = await this.auth.apiGet(this.routes.MINISTRY_ADMIN_DISTRICTS, districtGroupId);
        return {
            address: district.address,
            city: district.city,
            createdOn: district.created_on,
            foreignId: district.foreign_id,
            groupId: district.group_id,
            name: district.name,
            province: district.province,
        };
    }

    async getSchoolDetail(schoolGroupId: number): Promise<SchoolDetail> {
        const school = await this.auth.apiGet(this.routes.MINISTRY_ADMIN_SCHOOLS, schoolGroupId);
        return {
            address: school.address,
            city: school.city,
            district: {
                address: school.district.address,
                city: school.district.city,
                createdOn: new Date(school.district.created_on),
                foreignId: school.district.foreign_id,
                groupId: school.district.group_id,
                name: school.district.name,
                province: school.district.province,
            },
            foreignId: school.foreign_id,
            groupId: school.group_id,
            isFsa: school.is_fsa == 1,
            isGrad: school.is_grad == 1,
            name: school.name,
            mailing_address: school.mailing_address,
            physical_address: school.physical_address,
            phone_number: school.phone_number,
            fax_number: school.fax_number,
            grades: school.grades,
        }
    }

    // District Admin

    async exportDistrictAdmins(pagination: Pagination, district?: number) {
        let filename = 'District-Admins';
        filename += await this.getDistrictAndSchoolInFilename(district, undefined);
        const url = this.auth.reportFilePath('public/bc-admin-coordinator/accounts', JSON.stringify({
            query: {
                action: 'account',
                account: 'district admin',
                district,
                pagination,
            }
        }), filename);
        window.open(url, '_blank');
    }

    async countDistrictAdmins(pagination: Pagination, district?: number, assessmentType = AssessmentType.FSA): Promise<number> {
        const count = await this.auth.apiFind('public/bc-admin-coordinator/accounts', {
            query: {
                action: 'count',
                count: 'district admin count',
                district,
                pagination,
                assessmentType
            }
        });

        return count.count;
    }

    async findDistrictAdmins(pagination: Pagination, district: number, assessmentType = AssessmentType.FSA): Promise<PaginatedRows<DistrictAdminRow>> {
        const admins = await this.auth.apiFind(this.routes.MINISTRY_ADMIN_ACCOUNTS, {
            query: {
                action: 'account',
                account: 'district admin',
                district_group_id: district,
                pagination,
                assessmentType
            }
        });
        return admins;
    }

    async findGradDistrictAdmins(pagination: Pagination, district?: number): Promise<GradDistrictAdminRow[]> {
        const admins = await this.auth.apiFind('public/bc-admin-coordinator/accounts', {
            query: {
                action: 'account',
                account: 'district admin',
                district,
                pagination,
                assessmentType: AssessmentType.GRAD
            }
        });
        return admins.data.map(da => ({
            firstName: da.first_name,
            lastName: da.last_name,
            district: da.district,
            districtGroupId: da.dist_group_id,
            numSchools: da.num_schools,
            nme10: da.nme10,
            nmf10: da.nmf10,
            lte10: da.lte10,
            ltp10: da.ltp10,
            lte12: da.lte12,
            ltp12: da.ltp12,
            ltf12: da.ltf12
        }))
    }

    async countDistrictGrade(): Promise<DistrictGradeCount[]> {
        if (this.districtGradeCount !== null) return this.districtGradeCount;

        const counts = await this.auth.apiFind('public/bc-admin-coordinator/accounts', {
            query: {
                action: 'count',
                count: 'district grade count',

            }
        });
        this.districtGradeCount = counts.map(c => ({
            ...c,
            g4_count: parseInt(c.g4_count),
            g7_count: parseInt(c.g7_count),
        }));
        return this.districtGradeCount;
    }

    getGradeCountForDistrict(district: number): DistrictGradeCount {
        if (this.districtGradeCount === null) return {
            district,
            feb_g4_count: 0,
            feb_g7_count: 0,
            sept_g4_count: 0,
            sept_g7_count: 0,
        };

        // binary search
        // each page will take 20log(n) where n is total number of scores
        let start = 0;
        let end = this.districtGradeCount.length - 1;

        // Iterate while start not meets end
        let found = -1;
        while (start <= end) {

            // Find the mid index
            let mid = Math.floor((start + end) / 2);

            // If element is present at mid, return True
            if (this.districtGradeCount[mid].district === district) {
                found = mid;
                break;
            }

            // Else look in left or right half accordingly
            else if (this.districtGradeCount[mid].district < district)
                start = mid + 1;
            else
                end = mid - 1;
        }
        if (found !== -1) {
            return this.districtGradeCount[found];
        }
        return {
            district,
            feb_g4_count: 0,
            feb_g7_count: 0,
            sept_g4_count: 0,
            sept_g7_count: 0,
        };
    }

    async findDistrictAdminsInvitationStatus(): Promise<InvitationStatus[]> {
        const invitations = await this.auth.apiFind('public/bc-admin-coordinator/accounts', {
            query: {
                action: 'invitation',
                invitation: 'district admin',
            }
        });
        return invitations.map(iv => ({
            uid: iv.uid,
            createdOn: new Date(iv.created_on),
            status: iv.status,
        }));
    }

    // School Admin

    async exportSchoolAdmins(pagination: Pagination, district?: number, school?: number) {
        let filename = 'School-Admins';
        filename += await this.getDistrictAndSchoolInFilename(district, school);
        const url = this.auth.reportFilePath('public/bc-admin-coordinator/accounts', JSON.stringify({
            query: {
                action: 'account',
                account: 'school admin',
                district,
                school,
                pagination,
                assessmentType: AssessmentType.FSA,
            }
        }), filename);
        window.open(url, '_blank');
    }

    async countSchoolAdmins(pagination: Pagination, district?: number, school?: number): Promise<number> {
        const count = await this.auth.apiFind('public/bc-admin-coordinator/accounts', {
            query: {
                action: 'count',
                count: 'school admin count',
                district,
                school,
                pagination,
                assessmentType: AssessmentType.FSA,
            }
        });
        return count.count;
    }

    async findSchoolAdmins(pagination: Pagination, district?: number, school?: number, assessmentType: AssessmentType = AssessmentType.FSA, withAdminInfo: boolean = false): Promise<PaginatedRows<SchoolAdminRow>> {
        const schoolAdmins = await this.auth.apiFind(this.routes.MINISTRY_ADMIN_ACCOUNTS, {
            query: {
                action: 'account',
                account: 'school admin',
                district_group_id: district,
                school_group_id: school,
                pagination,
                with_admin_info: withAdminInfo ? 1 : 0,
                assessmentType,
            }
        });
        schoolAdmins.data = schoolAdmins.data.map(sa => ({
            ...sa,
            checked: false,
            uid: sa.uid,
            email: sa.contact_email,
            account_type: sa.account_type as AccountType,
            school_code: sa.school_id,
            is_participating_g4: this.num2Boolean(sa.is_participating_g4),
            is_participating_g7: this.num2Boolean(sa.is_participating_g7),
            invite_created_on: sa.invite_created_on ? utc(sa.invite_created_on) : undefined,
            invite_used_on: sa.invite_used_on ? utc(sa.invite_used_on) : undefined,
            invite_used_by_uid: sa.invite_used_by_uid,
            has_invites: this.num2Boolean(sa.has_invites),
            is_revoked: this.num2Boolean(sa.is_revoked),
        }));
        return schoolAdmins;
    }

    async patchSchoolAdminRole(uid: number, role: AdminRole) {
        return await this.auth.apiPatch(this.routes.MINISTRY_ADMIN_ACCOUNTS, uid, {
            role,
            accountType: 'school admin',
        });
    }

    async revokeSchoolAdminAccess(uid: number, school_group_id: number) {
        await this.auth.apiRemove(this.routes.MINISTRY_ADMIN_ACCOUNTS, uid, {
            query: {
                accountType: 'school admin',
                school_group_id,
            }
        });
    }

    async revokeDistrictAdminAccess(uid: number, district_group_id: number) {
        await this.auth.apiRemove(this.routes.MINISTRY_ADMIN_ACCOUNTS, uid, {
            query: {
                accountType: 'district admin',
                district_group_id,
            }
        })
    }

    async findGradSchoolAdmins(pagination: Pagination, district?: number, school?: number): Promise<PaginatedRows<GradSchoolAdminRow>> {
        const schoolAdmins = await this.auth.apiFind(this.routes.MINISTRY_ADMIN_ACCOUNTS, {
            query: {
                action: 'account',
                account: 'school admin',
                district_group_id: district,
                school_group_id: school,
                pagination,
                assessmentType: AssessmentType.GRAD
            }
        });
        schoolAdmins.data = schoolAdmins.data.map(sa => ({
            checked: false,
            uid: sa.uid,
            firstName: sa.first_name,
            lastName: sa.last_name,
            schoolName: sa.school_name,
            schoolId: sa.school_id,
            schoolGroupId: sa.school_group_id,
            schoolType: sa.school_type,
            district: sa.district,
            nme10: sa.nme10,
            nmf10: sa.nme10,
            lte10: sa.lte10,
            ltp10: sa.ltp10,
            lte12: sa.lte12,
            ltp12: sa.ltp12,
            ltf12: sa.ltf12,
        }))
        return schoolAdmins;
    }

    async countSchoolGrade(assessmentType = AssessmentType.FSA): Promise<SchoolGradeCount[]> {
        if (this.schoolGradeCount !== null) return this.schoolGradeCount;

        const counts = await this.auth.apiFind('public/bc-admin-coordinator/accounts', {
            query: {
                action: 'count',
                count: 'school grade count',
                assessmentType
            }
        });

        this.schoolGradeCount = counts;
        return counts;
    }

    getGradeCountForSchool(sid: number): SchoolGradeCount {
        if (this.schoolGradeCount === null) return {
            school: sid,
            feb_g4_count: 0,
            feb_g7_count: 0,
            sept_g4_count: 0,
            sept_g7_count: 0,
        }
        // binary search
        // each page will take 20log(n) where n is total number of scores
        let start = 0;
        let end = this.schoolGradeCount.length - 1;

        // Iterate while start not meets end
        let found = -1;
        while (start <= end) {

            // Find the mid index
            let mid = Math.floor((start + end) / 2);

            // If element is present at mid, return True
            if (this.schoolGradeCount[mid].school === sid) {
                found = mid;
                break;
            }

            // Else look in left or right half accordingly
            else if (this.schoolGradeCount[mid].school < sid)
                start = mid + 1;
            else
                end = mid - 1;
        }
        if (found !== -1) {
            return this.schoolGradeCount[found];
        }
        return {
            school: sid,
            feb_g4_count: 0,
            feb_g7_count: 0,
            sept_g4_count: 0,
            sept_g7_count: 0,
        };
    }

    async findSchoolAdminsInvitationStatus(): Promise<InvitationStatus[]> {
        const invitations = await this.auth.apiFind('public/bc-admin-coordinator/accounts', {
            query: {
                action: 'invitation',
                invitation: 'school admin',
            }
        });
        return invitations.map(iv => ({
            uid: iv.uid,
            createdOn: new Date(iv.created_on),
            status: iv.status,
        }));
    }

    // Students

    async exportStudents(load: 'first_load' | 'second_load', testWindow: TestWindow, district?: number, school?: number) {
        let filename = 'Students';
        filename += await this.getDistrictAndSchoolInFilename(district, school);
        if (load === 'first_load') {
            filename += '-First Load';
        } else {
            filename += '-Second Load';
        }

        const url = this.auth.reportFilePath('public/bc-admin-coordinator/accounts', JSON.stringify({
            query: {
                action: 'account',
                account: 'student',
                district,
                school,
                testWindowId: testWindow.id,
                pagination: {
                    skip: 0,
                    size: -1,
                    filters: [{
                        field: load,
                        value: 1,
                        condition: FilterCondition.MATCH,
                    }]
                },
            }
        }), filename);
        window.open(url, '_blank');
    }

    async countStudnets(pagination: Pagination, testWindow: TestWindow, district?: number, school?: number): Promise<number> {
        const count = await this.auth.apiFind('public/bc-admin-coordinator/accounts', {
            query: {
                action: 'count',
                count: 'student count',
                district,
                school,
                pagination,
                testWindowId: testWindow.id,
            }
        })
        return count.count;
    }

    async findStudents2(pagination: Pagination, testWindow: TestWindow, districtGroupId?: number, schoolGroupId?: number, showNewStudents?: number): Promise<PaginatedRows<StudentRow2>> {
        const students = await this.auth.apiFind(this.routes.MINISTRY_ADMIN_ACCOUNTS, {
            query: {
                action: 'account',
                account: 'student',
                district: districtGroupId,
                school: schoolGroupId,
                pagination,
                testWindowId: testWindow.id,
                showNewStudents: showNewStudents ? 1 : 0,
            },
        });
        return students.data;
    }

    exportStudents2(pagination: Pagination, testWindow: TestWindow, districtGroupId?: number, schoolGroupId?: number, showNewStudents?: number) {
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_ACCOUNTS, JSON.stringify({
            query: {
                action: 'account',
                account: 'student',
                district: districtGroupId,
                school: schoolGroupId,
                pagination,
                testWindowId: testWindow.id,
                showNewStudents: showNewStudents ? 1 : 0,
            }
        }), 'Students');
        window.open(url, '_blank');
    }

    async findStudents(pagination: Pagination, testWindow: TestWindow, district?: number, school?: number, showNewStudents?: number): Promise<{
        count: number,
        data: StudentRow[],
    }> {
        const students = await this.auth.apiFind('public/bc-admin-coordinator/accounts', {
            query: {
                action: 'account',
                account: 'student',
                district,
                school,
                pagination,
                testWindowId: testWindow.id,
                showNewStudents: showNewStudents ? 1 : 0,
            },
        });
        students.data.data = students.data.data.map(s => ({
            uid: s.uid,
            checked: false,
            school: s.school_name,
            schoolId: s.school,
            district: s.district,
            firstName: s.first_name,
            lastName: s.last_name,
            pen: s.pen,
            grade: s.grade,
            progress: {
                srNumeracy: s.srNumeracy,
                srLiteracy: s.srLiteracy,
                crLiteracy: s.crLiteracy,
                crNumeracy: s.crNumeracy,
            },
            isNew: s.feb == 0 && s.sept == 1,
            validationCodes: s.dup_name,
            sept: s.sept,
            feb: s.feb,
            adhoc: s.adhoc,
            yukonSept: s.yukon_second,
            yukonFeb: s.yukon_first,
            mergedToPen: s.mergedToPen,
            isWalkIn: s.is_walk_in == 1,
            unenrolled: s.unenrolled == 1,
        }))
        // return students.data.map(s => ({
        //     ...s,
        //     schoolId: s.school_id,
        //     checked: false,
        //     firstName: s.first_name,
        //     lastName: s.last_name,
        //     isNew: s.feb == 0 && s.sept == 1,
        // }));
        return students.data;
    }

    async findScores(): Promise<Score[]> {
        if (this.scores !== null) return this.scores;

        const scores = await this.auth.apiFind('public/bc-admin-coordinator/accounts', {
            query: {
                action: 'scores',
            }
        });

        this.scores = scores.map(s => ({
            uid: s.uid,
            score: s.overall_validated
        }));
        return this.scores;
    }

    getScoreForStudent(uid: number): string {
        if (this.scores === null) return 'Ongoing';

        // binary search
        // each page will take 20log(n) where n is total number of scores
        let start = 0;
        let end = this.scores.length - 1;

        // Iterate while start not meets end
        let found = -1;
        while (start <= end) {

            // Find the mid index
            let mid = Math.floor((start + end) / 2);

            // If element is present at mid, return True
            if (this.scores[mid].uid === uid) {
                found = mid;
                break;
            }

            // Else look in left or right half accordingly
            else if (this.scores[mid].uid < uid)
                start = mid + 1;
            else
                end = mid - 1;
        }
        if (found !== -1) {
            const score = parseFloat(this.scores[found].score.split(';')[1]);
            return score.toFixed(2) + '%';
        }
        return 'Ongoing';
    }

    // Invigilators

    async exportInvigilators(pagination: Pagination, district?: number, school?: number) {
        let filename = 'Invigilators';
        filename += await this.getDistrictAndSchoolInFilename(district, school);
        const url = this.auth.reportFilePath('public/bc-admin-coordinator/accounts', JSON.stringify({
            query: {
                action: 'account',
                account: 'invigilator',
                district,
                school,
                pagination,
            }
        }), filename);
        window.open(url, '_blank');
    }

    async countInvigilators(pagination: Pagination, district?: number, school?: number): Promise<number> {
        const count = await this.auth.apiFind('public/bc-admin-coordinator/accounts', {
            query: {
                action: 'count',
                count: 'invigilator count',
                district,
                school,
                pagination,
            }
        });
        return count.count;
    }

    async findInvigilators(pagination: Pagination, district?: number, school?: number): Promise<InvigilatorRow[]> {
        const invigilators = await this.auth.apiFind('public/bc-admin-coordinator/accounts', {
            query: {
                action: 'account',
                account: 'invigilator',
                district,
                school,
                pagination,
            }
        });
        return invigilators.data.map(i => ({
            uid: i.uid,
            firstName: i.first_name,
            lastName: i.last_name,
            school: i.school,
            schoolId: i.school_id,
            district: i.district,
        }));
    }

    async findInvigilatorsInvitationStatus(): Promise<InvitationStatus[]> {
        if (this.invigilatorsInvitations !== null) return this.invigilatorsInvitations;
        const invitations = await this.auth.apiFind('public/bc-admin-coordinator/accounts', {
            query: {
                action: 'invitation',
                invitation: 'invigilator',
            }
        });
        this.invigilatorsInvitations = invitations.map(iv => ({
            uid: iv.uid,
            createdOn: new Date(iv.created_on),
            status: iv.status,
        }));
        return this.invigilatorsInvitations;
    }

    getInvigilatorInvitationStatus(uid: number): InvitationStatus | null {
        if (this.invigilatorsInvitations === null) return null;

        // binary search
        // each page will take 20log(n) where n is total number of scores
        let start = 0;
        let end = this.invigilatorsInvitations.length - 1;

        // Iterate while start not meets end
        let found = -1;
        while (start <= end) {

            // Find the mid index
            let mid = Math.floor((start + end) / 2);

            // If element is present at mid, return True
            if (this.invigilatorsInvitations[mid].uid === uid) {
                found = mid;
                break;
            }

            // Else look in left or right half accordingly
            else if (this.invigilatorsInvitations[mid].uid < uid)
                start = mid + 1;
            else
                end = mid - 1;
        }
        if (found !== -1) {
            return this.invigilatorsInvitations[found];
        }
        return null;
    }

    async getDistrictAndSchoolInFilename(district?: number, school?: number): Promise<string> {
        let filename = '';
        if (!this.districts) {
            await this.findDistricts();
        }
        if (district !== undefined) {
            const matched = this.districts.find(d => d.groupId === district);
            if (matched) {
                filename += `-${matched.name}`;
                filename += matched.foreignId === -1 ? '' : `(${this.formatDistrictCode(matched.foreignId)})`

                if (school !== undefined && this.schools.has(district)) {
                    const matched = this.schools.get(district).find(s => s.groupId === school);
                    if (matched) {
                        filename += `-${matched.name}`;
                        filename += matched.foreignId === -1 ? '' : `(${this.formatSchoolCode(matched.foreignId)})`
                    }
                }
            }
        }
        return filename.split(' ').join('-');
    }

    async createStudent(student: any): Promise<number> {
        const createdUid = await this.auth.apiCreate('public/bc-admin-coordinator/students', student);
        return createdUid;
    }

    async getStudentUidByPen(pen: string): Promise<number | null> {
        const student = await this.auth.apiFind('public/bc-admin-coordinator/students', {
            query: {
                pen
            }
        });
        return student.uid;
    }

    async findLogins(pagination: Pagination, startDate: Moment, endDate: Moment, testWindow: TestWindow, district?: number, school?: number, typeSlug?: string): Promise<LoginRow[]> {
        const logins = await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'report',
                report: 'login by student',
                pagination,
                district,
                school,
                test_window_id: testWindow.id,
                startDate: startDate.utc().format(),
                endDate: endDate.utc().format(),
                typeSlug,
            },
        });
        return logins.map(l => ({
            firstName: l.first_name,
            lastName: l.last_name,
            pen: l.pen,
            loginAt: utc(l.login_at),
            typeSlug: l.type_slug,
        }))
    }

    async countLogins(pagination: Pagination, startDate: Moment, endDate: Moment, testWindow: TestWindow, district?: number, school?: number, typeSlug?: string): Promise<number> {
        const counts = await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'count',
                count: 'login by student',
                pagination,
                district,
                school,
                test_window_id: testWindow.id,
                startDate: startDate.utc().format(),
                endDate: endDate.utc().format(),
                typeSlug,
            },
        });
        return counts.count;
    }

    async findLoginsBySchool(pagination: Pagination, startDate: Moment, endDate: Moment, testWindow: TestWindow, district?: number, typeSlug?: string): Promise<SchoolLoginRow[]> {
        const logins = await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'report',
                report: 'login by school',
                pagination,
                district,
                test_window_id: testWindow.id,
                startDate: startDate.utc().format(),
                endDate: endDate.utc().format(),
                typeSlug,
            },
        });

        const rows: Map<number, SchoolLoginRow> = new Map();
        logins.map(rawRow => {
            let existingRow = rows.get(rawRow.school_id);
            if (existingRow) {
                existingRow.componentCount[rawRow.assessment_code] = rawRow.num_logins;
                existingRow.total += rawRow.num_logins;
            } else {
                existingRow = {
                    school: {
                        name: rawRow.school_name,
                        groupId: rawRow.school_group_id,
                        foreignId: rawRow.school_id,
                    },
                    total: rawRow.num_logins,
                    componentCount: {
                        [rawRow.assessment_code]: rawRow.num_logins,
                    },
                };
                rows.set(rawRow.school_id, existingRow);
            }
        });
        return [...rows.values()].sort((a, b) => a.school.name < b.school.name ? -1 : 1);
    }
    async countLoginsBySchool(pagination: Pagination, startDate: Moment, endDate: Moment, testWindow: TestWindow, district?: number, typeSlug?: string): Promise<number> {
        const counts = await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'count',
                count: 'login by school',
                pagination,
                district,
                test_window_id: testWindow.id,
                startDate: startDate.utc().format(),
                endDate: endDate.utc().format(),
                typeSlug,
            },
        });
        return counts.count;
    }

    async findLoginsByDistrict(startDate: Moment, endDate: Moment, testWindow: TestWindow, typeSlug?: string): Promise<DistrictLoginRow[]> {
        const logins = await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'report',
                report: 'login by district',
                test_window_id: testWindow.id,
                startDate: startDate.utc().format(),
                endDate: endDate.utc().format(),
                typeSlug,
            },
        });

        const rows: Map<number, DistrictLoginRow> = new Map();
        logins.map(rawRow => {
            let existingRow = rows.get(rawRow.district_id);
            if (existingRow) {
                existingRow.componentCount[rawRow.assessment_code] = rawRow.num_logins;
                existingRow.total += rawRow.num_logins;
            } else {
                existingRow = {
                    district: {
                        name: rawRow.district_name,
                        groupId: rawRow.district_group_id,
                        foreignId: rawRow.district_id,
                    },
                    total: rawRow.num_logins,
                    componentCount: {
                        [rawRow.assessment_code]: rawRow.num_logins,
                    },
                };
                rows.set(rawRow.district_id, existingRow);
            }
        });
        return [...rows.values()].sort((a, b) => a.district.name < b.district.name ? -1 : 1);
    }

    async countLoginsByDistrict(startDate: Moment, endDate: Moment, testWindow: TestWindow, typeSlug?: string): Promise<number> {
        const counts = await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'count',
                count: 'login by district',
                test_window_id: testWindow.id,
                startDate: startDate.utc().format(),
                endDate: endDate.utc().format(),
                typeSlug,
            },
        });
        return counts.count;
    }

    async findLoginsByProvince(startDate: Moment, endDate: Moment, testWindow: TestWindow, typeSlug?: string): Promise<ProvinceLoginRow> {
        const logins = <any[]>await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'report',
                report: 'login by province',
                test_window_id: testWindow.id,
                startDate: startDate.utc().format(),
                endDate: endDate.utc().format(),
                typeSlug,
            },
        });

        return {
            total: logins.reduce((sum, rawRow) => sum + rawRow.num_logins, 0),
            componentCount: logins.reduce((componentCount, rawRow) => ({
                ...componentCount,
                [rawRow.assessment_code]: rawRow.num_logins
            }), {}),
        }

        // let total =
        // logins.map(rawRow => {
        //     let existingRow = rows.get(rawRow.district_id);
        //     if (existingRow) {
        //         existingRow.componentCount[rawRow.assessment_code] = rawRow.num_logins;
        //         existingRow.total += rawRow.num_logins;
        //     } else {
        //         existingRow = {
        //             district: {
        //                 name: rawRow.district_name,
        //                 groupId: rawRow.district_group_id,
        //                 foreignId: rawRow.district_id,
        //             },
        //             total: rawRow.num_logins,
        //             componentCount: {
        //                 [rawRow.assessment_code]: rawRow.num_logins,
        //             },
        //         };
        //         rows.set(rawRow.district_id, existingRow);
        //     }
        // });
        // return [...rows.values()].sort((a, b) => a.district.name < b.district.name ? -1 : 1);
    }

    async findFilteredByStudent(pagination: Pagination, startDate: Moment, endDate: Moment, testWindow: TestWindow, district?: number, school?: number, typeSlug?: string, loggedIn?: string, submitted?: string, registered?: string): Promise<LoginRow[]> {
        const logins = await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'report',
                report: 'filtered by student',
                pagination,
                district,
                school,
                test_window_id: testWindow.id,
                startDate: startDate.utc().format(),
                endDate: endDate.utc().format(),
                typeSlug,
                loggedIn,
                submitted,
                registered,
            },
        });
        return logins.map(l => ({
            firstName: l.first_name,
            lastName: l.last_name,
            pen: l.pen,
            loginAt: utc(l.login_at),
            typeSlug: l.type_slug,
        }))
    }

    async findSubmissionsByProvince(startDate: Moment, endDate: Moment, testWindow: TestWindow, typeSlug?: string): Promise<ProvinceLoginRow> {
        const submissions = <any[]>await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'report',
                report: 'submission by province',
                test_window_id: testWindow.id,
                startDate: startDate.utc().format(),
                endDate: endDate.utc().format(),
                typeSlug,
            },
        });

        return {
            total: submissions.reduce((sum, rawRow) => sum + rawRow.num_submissions, 0),
            componentCount: submissions.reduce((componentCount, rawRow) => ({
                ...componentCount,
                [rawRow.assessment_code]: rawRow.num_submissions
            }), {}),
        }
    }

    async findSubmissionsByDistrict(startDate: Moment, endDate: Moment, testWindow: TestWindow, typeSlug?: string): Promise<DistrictLoginRow[]> {
        const submissions = await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'report',
                report: 'submission by district',
                test_window_id: testWindow.id,
                startDate: startDate.utc().format(),
                endDate: endDate.utc().format(),
                typeSlug,
            },
        });

        const rows: Map<number, DistrictLoginRow> = new Map();
        submissions.map(rawRow => {
            let existingRow = rows.get(rawRow.district_id);
            if (existingRow) {
                existingRow.componentCount[rawRow.assessment_code] = rawRow.num_submissions;
                existingRow.total += rawRow.num_submissions;
            } else {
                existingRow = {
                    district: {
                        name: rawRow.district_name,
                        groupId: rawRow.district_group_id,
                        foreignId: rawRow.district_id,
                    },
                    total: rawRow.num_submissions,
                    componentCount: {
                        [rawRow.assessment_code]: rawRow.num_submissions,
                    },
                };
                rows.set(rawRow.district_id, existingRow);
            }
        });
        return [...rows.values()].sort((a, b) => a.district.name < b.district.name ? -1 : 1);
    }

    async findSubmissionsBySchool(pagination: Pagination, startDate: Moment, endDate: Moment, testWindow: TestWindow, district?: number, typeSlug?: string): Promise<SchoolLoginRow[]> {
        const submissions = await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'report',
                report: 'submission by school',
                pagination,
                district,
                test_window_id: testWindow.id,
                startDate: startDate.utc().format(),
                endDate: endDate.utc().format(),
                typeSlug,
            },
        });

        const rows: Map<number, SchoolLoginRow> = new Map();
        submissions.map(rawRow => {
            let existingRow = rows.get(rawRow.school_id);
            if (existingRow) {
                existingRow.componentCount[rawRow.assessment_code] = rawRow.num_submissions;
                existingRow.total += rawRow.num_submissions;
            } else {
                existingRow = {
                    school: {
                        name: rawRow.school_name,
                        groupId: rawRow.school_group_id,
                        foreignId: rawRow.school_id,
                    },
                    total: rawRow.num_submissions,
                    componentCount: {
                        [rawRow.assessment_code]: rawRow.num_submissions,
                    },
                };
                rows.set(rawRow.school_id, existingRow);
            }
        });
        return [...rows.values()].sort((a, b) => a.school.name < b.school.name ? -1 : 1);
    }

    async findRegisteredByProvince(startDate: Moment, endDate: Moment, testWindow: TestWindow, typeSlug?: string): Promise<ProvinceLoginRow> {
        const registered = <any[]>await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'report',
                report: 'registration by province',
                test_window_id: testWindow.id,
                startDate: startDate.utc().format(),
                endDate: endDate.utc().format(),
                typeSlug,
            },
        });
        return {
            total: registered.reduce((sum, rawRow) => sum + rawRow.num_registered, 0),
            componentCount: registered.reduce((componentCount, rawRow) => ({
                ...componentCount,
                [rawRow.assessment_code]: rawRow.num_registered
            }), {}),
        }
    }

    async findRegisteredByDistrict(startDate: Moment, endDate: Moment, testWindow: TestWindow, typeSlug?: string): Promise<DistrictLoginRow[]> {
        const registered = await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'report',
                report: 'registration by district',
                test_window_id: testWindow.id,
                startDate: startDate.utc().format(),
                endDate: endDate.utc().format(),
                typeSlug,
            },
        });

        const rows: Map<number, DistrictLoginRow> = new Map();
        registered.map(rawRow => {
            let existingRow = rows.get(rawRow.district_id);
            if (existingRow) {
                existingRow.componentCount[rawRow.assessment_code] = rawRow.num_registered;
                existingRow.total += rawRow.num_registered;
            } else {
                existingRow = {
                    district: {
                        name: rawRow.district_name,
                        groupId: rawRow.district_group_id,
                        foreignId: rawRow.district_id,
                    },
                    total: rawRow.num_registered,
                    componentCount: {
                        [rawRow.assessment_code]: rawRow.num_registered,
                    },
                };
                rows.set(rawRow.district_id, existingRow);
            }
        });
        return [...rows.values()].sort((a, b) => a.district.name < b.district.name ? -1 : 1);
    }

    async findRegisteredBySchool(pagination: Pagination, startDate: Moment, endDate: Moment, testWindow: TestWindow, district?: number, typeSlug?: string): Promise<SchoolLoginRow[]> {
        const registered = await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'report',
                report: 'registration by school',
                pagination,
                district,
                test_window_id: testWindow.id,
                startDate: startDate.utc().format(),
                endDate: endDate.utc().format(),
                typeSlug,
            },
        });

        const rows: Map<number, SchoolLoginRow> = new Map();
        registered.map(rawRow => {
            let existingRow = rows.get(rawRow.school_id);
            if (existingRow) {
                existingRow.componentCount[rawRow.assessment_code] = rawRow.num_registered;
                existingRow.total += rawRow.num_registered;
            } else {
                existingRow = {
                    school: {
                        name: rawRow.school_name,
                        groupId: rawRow.school_group_id,
                        foreignId: rawRow.school_id,
                    },
                    total: rawRow.num_registered,
                    componentCount: {
                        [rawRow.assessment_code]: rawRow.num_registered,
                    },
                };
                rows.set(rawRow.school_id, existingRow);
            }
        });
        return [...rows.values()].sort((a, b) => a.school.name < b.school.name ? -1 : 1);
    }

    async countRegisteredBySchool(pagination: Pagination, startDate: Moment, endDate: Moment, testWindow: TestWindow, district?: number, typeSlug?: string): Promise<number> {
        const counts = await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'count',
                count: 'registration by school',
                pagination,
                district,
                test_window_id: testWindow.id,
                startDate: startDate.utc().format(),
                endDate: endDate.utc().format(),
                typeSlug,
            },
        });
        return counts.count;
    }

    async findRegistered(pagination: Pagination, startDate: Moment, endDate: Moment, testWindow: TestWindow, district?: number, school?: number, typeSlug?: string): Promise<LoginRow[]> {
        const registered = await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'report',
                report: 'registered students',
                pagination,
                district,
                school,
                test_window_id: testWindow.id,
                startDate: startDate.utc().format(),
                endDate: endDate.utc().format(),
                typeSlug,
            },
        });
        return registered.map(l => ({
            firstName: l.first_name,
            lastName: l.last_name,
            pen: l.pen,
            loginAt: null,
            typeSlug: l.type_slug,
        }))
    }

    async countRegistered(pagination: Pagination, startDate: Moment, endDate: Moment, testWindow: TestWindow, district?: number, school?: number, typeSlug?: string): Promise<number> {
        const counts = await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'count',
                count: 'registered students',
                pagination,
                district,
                school,
                test_window_id: testWindow.id,
                startDate: startDate.utc().format(),
                endDate: endDate.utc().format(),
                typeSlug,
            },
        });
        return counts.count;
    }

    async findSubmitted(pagination: Pagination, startDate: Moment, endDate: Moment, testWindow: TestWindow, district?: number, school?: number, typeSlug?: string): Promise<LoginRow[]> {
        const submitted = await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'report',
                report: 'submitted students',
                pagination,
                district,
                school,
                test_window_id: testWindow.id,
                startDate: startDate.utc().format(),
                endDate: endDate.utc().format(),
                typeSlug,
            },
        });
        return submitted.map(l => ({
            firstName: l.first_name,
            lastName: l.last_name,
            pen: l.pen,
            loginAt: utc(l.submitted_at),
            typeSlug: l.type_slug,
        }))
    }

    async countSubmitted(pagination: Pagination, startDate: Moment, endDate: Moment, testWindow: TestWindow, district?: number, school?: number, typeSlug?: string): Promise<number> {
        const counts = await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'count',
                count: 'submitted students',
                pagination,
                district,
                school,
                test_window_id: testWindow.id,
                startDate: startDate.utc().format(),
                endDate: endDate.utc().format(),
                typeSlug,
            },
        });
        return counts.count;
    }

    async findLoggedIn(pagination: Pagination, startDate: Moment, endDate: Moment, testWindow: TestWindow, district?: number, school?: number, typeSlug?: string): Promise<LoginRow[]> {
        const loggedIn = await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'report',
                report: 'logged in students',
                pagination,
                district,
                school,
                test_window_id: testWindow.id,
                startDate: startDate.utc().format(),
                endDate: endDate.utc().format(),
                typeSlug,
            },
        });
        return loggedIn.map(l => ({
            firstName: l.first_name,
            lastName: l.last_name,
            pen: l.pen,
            loginAt: utc(l.login_at),
            typeSlug: l.type_slug,
        }))
    }

    async findStudentsOfAssessment(pagination: Pagination, schoolGroupId: number, testWindow: TestWindow, assessment: string): Promise<{
        count: number,
        data: SaStudentRow[],
    }> {
        const response = await this.auth.apiFind(this.routes.SCHOOL_ADMIN_BCG_STUDENTS, {
            query: {
                pagination,
                school_group_id: schoolGroupId == -1 ? undefined : schoolGroupId,
                test_window_id: testWindow.id,
                assessment_code: assessment,
            }
        });
        response.data = response.data.map(row => ({
            checked: false,
            ...row,
            validationCodes: row.validation_codes ? row.validation_codes.split(",") : [],
        }));
        return response;
    }

    async exportStudentsOfAssessment(schoolGroupId: number, schoolName: string, testWindow: TestWindow, assessment: string): Promise<void> {
        let filename = 'Registered-Students-' + schoolName;
        const url = this.auth.reportFilePath(this.routes.SCHOOL_ADMIN_BCG_STUDENTS, JSON.stringify({
            query: {
                pagination: {
                    skip: 0,
                    size: -1,
                },
                school_group_id: schoolGroupId,
                test_window_id: testWindow.id,
                assessment_code: assessment,
            }
        }), filename);
        window.open(url, '_blank');
    }

    async findStudentsOfAssessmentDuplicates(pagination: Pagination, testWindow: TestWindow): Promise<{
        count: number,
        data: SaStudentRow[],
    }> {
        const response = await this.auth.apiFind(this.routes.MINISTRY_ADMIN_BCGRAD_STUDENTS, {
            query: {
                pagination,
                test_window_id: testWindow.id,
                duplicates: true,
            }
        });
        response.data = response.data.map(row => ({
            checked: false,
            ...row,
            validationCodes: row.validation_codes ? row.validation_codes.split(",") : [],
        }));
        return response;
    }

    async exportStudentsOfAssessmentDuplicates(testWindow: TestWindow): Promise<void> {
        let filename = 'Duplicate-PENs';
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_BCGRAD_STUDENTS, JSON.stringify({
            query: {
                pagination: {
                    skip: 0,
                    size: -1,
                },
                test_window_id: testWindow.id,
                duplicates: true,
            }
        }), filename);
        window.open(url, '_blank');
    }

    async findStudentsOfAssessmentByDistrict(pagination: Pagination, districtGroupId: number, testWindow: TestWindow, assessment: string): Promise<{
        count: number,
        data: SaStudentRow[],
    }> {
        const response = await this.auth.apiFind(this.routes.MINISTRY_ADMIN_BCGRAD_STUDENTS, {
            query: {
                pagination,
                district_group_id: districtGroupId == -1 ? undefined : districtGroupId,
                test_window_id: testWindow.id,
                assessment_code: assessment,
            }
        });
        response.data = response.data.map(row => ({
            checked: false,
            ...row,
            validationCodes: row.validation_codes ? row.validation_codes.split(",") : [],
        }));
        return response;
    }

    async exportStudentsOfAssessmentByDistrict(districtGroupId: number, districtName: string, testWindow: TestWindow, assessment: string): Promise<void> {
        let filename = 'Registered-Students-' + districtName;
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_BCGRAD_STUDENTS, JSON.stringify({
            query: {
                pagination: {
                    skip: 0,
                    size: -1,
                },
                district_group_id: districtGroupId,
                test_window_id: testWindow.id,
                assessment_code: assessment,
            }
        }), filename);
        window.open(url, '_blank');
    }

    async findRegisteredStudents(pagination: Pagination, testWindow: TestWindow, district?: District, school?: School, assessment?: AssessmentComponent): Promise<{
        count: number,
        data: SaStudentRow[],
    }> {
        const response = await this.auth.apiFind(this.routes.MINISTRY_ADMIN_BCGRAD_STUDENTS, {
            query: {
                pagination,
                test_window_id: testWindow.id,
                school_group_id: school && school.groupId != -1 ? school.groupId : undefined,
                district_group_id: district && district.groupId != -1 ? district.groupId : undefined,
                assessment_code: assessment && assessment.id != -1 ? assessment.code : undefined,
            }
        });
        response.data = response.data.map(row => ({
            checked: false,
            ...row,
            validationCodes: row.validation_codes ? row.validation_codes.split(",") : [],
        }));
        return response;
    }

    async findStudentsOfAssessmentBySchool(pagination: Pagination, schoolGroupId: number, testWindow: TestWindow, assessment: string): Promise<{
        count: number,
        data: SaStudentRow[],
    }> {
        const response = await this.auth.apiFind(this.routes.MINISTRY_ADMIN_BCGRAD_STUDENTS, {
            query: {
                pagination,
                school_group_id: schoolGroupId == -1 ? undefined : schoolGroupId,
                test_window_id: testWindow.id,
                assessment_code: assessment,
            }
        });
        response.data = response.data.map(row => ({
            checked: false,
            ...row,
            validationCodes: row.validation_codes ? row.validation_codes.split(",") : [],
        }));
        return response;
    }

    async exportStudentsOfAssessmentBySchool(schoolGroupId: number, schoolName: string, testWindow: TestWindow, assessment: string): Promise<void> {
        let filename = 'Registered-Students-' + schoolName;
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_BCGRAD_STUDENTS, JSON.stringify({
            query: {
                pagination: {
                    skip: 0,
                    size: -1,
                },
                school_group_id: schoolGroupId,
                test_window_id: testWindow.id,
                assessment_code: assessment,
            }
        }), filename);
        window.open(url, '_blank');
    }

    async penLookup(assessmentType: AssessmentType, pen: string, schoolOrDistrictGroupId?: number, testWindow?: TestWindow, assessmentCode?: string): Promise<any | null> {
        if (assessmentType === AssessmentType.GRAD) {
            const result = <any[]>await this.auth.apiFind(this.routes.SCHOOL_ADMIN_BCG_STUDENTS_LOOKUP, {
                query: {
                    school_group_id: schoolOrDistrictGroupId,
                    test_window_id: testWindow ? testWindow.id : undefined,
                    assessment_code: assessmentCode,
                    pen,
                },
            });
            if (result.length === 0) return null;
            return result[0];
        } else {
            return await this.auth.apiFind(this.routes.DIST_ADMIN_PEN_LOOKUP, {
                query: {
                    pen: pen,
                    districtGroupId: schoolOrDistrictGroupId,
                },
            });
        }

    }

    async findRegisteredAssessmentsByPen(pen: string): Promise<string[]> {
        return await this.auth.apiFind(this.routes.SCHOOL_ADMIN_BCG_STUDENTS_LOOKUP, {
            query: {
                pen,
                action: 'find assessments',
            },
        })
    }

    async registerStudentToTestSession(accountType: AccountType, pen: string, assessmentCode: string, student: any, testWindow: TestWindow, schoolGroupId?: number) {
        let payload = {};
        let route;
        if (accountType === AccountType.MINISTRY_ADMIN) {
            payload = {
                pen,
                assessmentCode,
                ...student,
                schoolGroupId,
            };
            route = this.routes.MINISTRY_ADMIN_BCG_STUDENTS_REGISTER;
        } else {
            payload = {
                pen,
                assessmentCode,
                ...student,
            };
            route = this.routes.SCHOOL_ADMIN_BCG_STUDENTS_REGISTER;
        }
        await this.auth.apiCreate(route, payload, {
            query: {
                testWindowId: testWindow.id,
            }
        })
    }

    async getStudentInfo(uid: number): Promise<any> {
        return await this.auth.apiGet(this.routes.SCHOOL_ADMIN_BCG_STUDENTS, uid);
    }

    async validateWalkInStudent(uid: number, to: boolean): Promise<void> {
        await this.auth.apiUpdate(this.routes.MINISTRY_ADMIN_BCGRAD_STUDENTS, uid, {
            to: to ? 1 : 0,
        }, {
            query: {
                action: 'validate',
            },
        });
    }

    async validateWalkInStudentV(uid: number, testWindow: TestWindow, to: boolean): Promise<void> {
        await this.auth.apiUpdate(this.routes.MINISTRY_ADMIN_BCGRAD_STUDENTS, uid, {
            to: to ? 1 : 0,
        }, {
            query: {
                action: 'validate',
                testWindowId: testWindow.id,
            },
        });
    }

    validatePen(pen: string | number): boolean {

        if (typeof pen === 'string' && isNaN(parseInt(pen))) {
            return false;
        }

        if (pen.toString().length !== 9) return false;

        const digits1 = Array.from(pen.toString(), Number);
        const digits2 = Array.from('121212121', Number);

        const digits3 = [];

        for (let i = 0; i < digits1.length; i++) {
            const product = digits1[i] * digits2[i];

            // if product is 2 digits, take the sum
            if (product > 9) {
                digits3.push(Array.from(product.toString(), Number).reduce((a, b) => a + b, 0));
            } else {
                digits3.push(product);
            }
        }

        const sum = digits3.reduce((a, b) => a + b, 0);
        return sum % 10 === 0;
    }

    async getStageOneRows(startDate: Moment, endDate: Moment, testWindow: TestWindow, district?: number): Promise<AssessmentStageOneDataReportRow[]> {
        const stageOneData = await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'report',
                report: 'stage one',
                district,
                test_window_id: testWindow.id,
                startDate: startDate.utc().format(),
                endDate: endDate.utc().format(),
            },
        });

        let stageOneReturn: AssessmentStageOneDataReportRow[] = [];
        for (const rawRow of stageOneData) {

            let session_status = 0;
            if (rawRow.session_started == 1) {
                if (rawRow.session_closed == 1) {
                    session_status = 2;
                }
                else {
                    session_status = 1;
                }
            }
            else {
                session_status = 0;
            }

            stageOneReturn.push({
                component: rawRow.assessment_code,
                sessionStatus: session_status,
                registration: rawRow.num_registered,
                submissionsWrote: rawRow.num_submitted,
                largePrintAssessment: 0,
                brailleAssessment: 0,
                studentsNew: 0,
                firstWrites: 0,
                blockedWrites: 0,
            });
        }

        return stageOneReturn;

    }

    async getStageTwoRows(startDate: Moment, endDate: Moment, testWindow: TestWindow, district?: number): Promise<AssessmentStageTwoDataReportRow[]> {

        const stageTwoData = await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'report',
                report: 'stage one',
                district,
                test_window_id: testWindow.id,
                startDate: startDate.utc().format(),
                endDate: endDate.utc().format(),
            },
        });

        let stageTwoReturn: AssessmentStageTwoDataReportRow[] = [];
        for (const rawRow of stageTwoData) {

            let session_status = 0;
            if (rawRow.session_started == 1) {
                if (rawRow.session_closed == 1) {
                    session_status = 2;
                }
                else {
                    session_status = 1;
                }
            }
            else {
                session_status = 0;
            }

            stageTwoReturn.push({
                component: rawRow.assessment_code,
                sessionStatus: session_status,
                registration: rawRow.num_registered,
                submissionsWrote: rawRow.num_submitted,
                markingStatus: "",
                totalSubmitted: 0,
                marked: 0,
                notMarked: 0,
                reviewItemsComplete: 0,
                numberOfScans: 0,
                numberOfProblemScans: 0,
                numberOfMissingScans: 0,
            });
        }

        return stageTwoReturn;
    }

    async getStageThreeRows(startDate: Moment, endDate: Moment, testWindow: TestWindow, district?: number): Promise<AssessmentStageThreeDataReportRow[]> {

        const stageThreeData = await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'report',
                report: 'stage one',
                district,
                test_window_id: testWindow.id,
                startDate: startDate.utc().format(),
                endDate: endDate.utc().format(),
            },
        });

        let stageThreeReturn: AssessmentStageThreeDataReportRow[] = [];
        for (const rawRow of stageThreeData) {

            let session_status = 0;
            if (rawRow.session_started == 1) {
                if (rawRow.session_closed == 1) {
                    session_status = 2;
                }
                else {
                    session_status = 1;
                }
            }
            else {
                session_status = 0;
            }

            stageThreeReturn.push({
                component: rawRow.assessment_code,
                sessionStatus: session_status,
                registration: rawRow.num_registered,
                submissionsWrote: rawRow.num_submitted,
                statusText: "",
                notWrittenAtSchool: 0,
                totalSpecialCases: 0,
                specialCaseQ: 0,
                specialCaseA: 0,
                studentsInEquatingPool: 0,
                studentsWithProfLevel: 0,
                missingSelectedResponses: 0,
                missingScannedPages: 0,
            });
        }

        return stageThreeReturn;
    }

    async getStageFourRows(startDate: Moment, endDate: Moment, testWindow: TestWindow, district?: number): Promise<AssessmentStageFourDataReportRow[]> {

        const stageFourData = await this.auth.apiFind('public/bc-admin-coordinator/reports', {
            query: {
                action: 'report',
                report: 'stage one',
                district,
                test_window_id: testWindow.id,
                startDate: startDate.utc().format(),
                endDate: endDate.utc().format(),
            },
        });

        let stageFourReturn: AssessmentStageFourDataReportRow[] = [];
        for (const rawRow of stageFourData) {

            let session_status = 0;
            if (rawRow.session_started == 1) {
                if (rawRow.session_closed == 1) {
                    session_status = 2;
                }
                else {
                    session_status = 1;
                }
            }
            else {
                session_status = 0;
            }

            stageFourReturn.push({
                component: rawRow.assessment_code,
                sessionStatus: session_status,
                registration: rawRow.num_registered,
                submissionsWrote: rawRow.num_submitted,
                statusText: "",
                studentsWhoWrote: rawRow.num_submitted,
                studentsReceivedResults: 0,
                studentsDidNotReceiveResults: 0,
                studentsReleased: 0,
            });
        }

        return stageFourReturn;
    }

    async importFsaStudnets(students: {
        mincode: string,
        pen: string,
        grade: number,
        firstName: string,
        lastName: string,
    }[], load: 'sept' | 'feb' | 'yukonFirst' | 'yukonSecond' | 'adhoc'): Promise<any> {
        return await this.auth.apiCreate(this.routes.MINISTRY_ADMIN_REGISTRATION, {
            students,
        }, {
            query: {
                load,
            }
        });
    }

    async importGradTRAXC(students: {
        asmtMincode: string,
        pen: string,
        firstName: string,
        lastName: string,
        mincode: string,
        asmtCode: string,
    }[], testWindowId: number): Promise<any> {
        // to import historical assessment registration
        return await this.auth.apiCreate(this.routes.MINISTRY_ADMIN_REGISTRATION, {
            students,
        }, {
            query: {
                load: 'TRAX882C',
                testWindowId,
            }
        });
    }

    async lookupStudentRequestTypes(uid: number, testWindow: TestWindow, accountType: AccountType): Promise<StudentRequestType[]> {
        const route = accountType === AccountType.MINISTRY_ADMIN ? this.routes.MINISTRY_ADMIN_BCGRAD_STUDENTS_REQUESTS : this.routes.SCHOOL_ADMIN_BCG_STUDENTS_REQUESTS;
        return await this.auth.apiFind(route, {
            query: {
                lookup: 1,
                uid,
                testWindowId: testWindow.id,
            },
        });
    }

    async createStudentRequest(request: CreateDbStudentRequest, accountType: AccountType): Promise<DbStudentRequest> {
        const route = accountType === AccountType.MINISTRY_ADMIN ? this.routes.MINISTRY_ADMIN_BCGRAD_STUDENTS_REQUESTS : this.routes.SCHOOL_ADMIN_BCG_STUDENTS_REQUESTS;
        const createdRequest = await this.auth.apiCreate(route, request);
        return createdRequest;
    }

    async findStudentRequests(type: StudentRequestType, uid: number, testWindow: TestWindow, assessment: AssessmentComponent, accountType: AccountType): Promise<DbStudentRequest[]> {
        const route = accountType === AccountType.MINISTRY_ADMIN ? this.routes.MINISTRY_ADMIN_BCGRAD_STUDENTS_REQUESTS : this.routes.SCHOOL_ADMIN_BCG_STUDENTS_REQUESTS;
        const requests = await this.auth.apiFind(route, {
            query: {
                uid,
                type,
                testWindowId: testWindow.id,
                assessmentCode: assessment?.code,
            },
        });

        return requests;
    }

    async patchStudentRequest(id: number, data: any, accountType: AccountType): Promise<DbStudentRequest> {
        const route = accountType === AccountType.MINISTRY_ADMIN ? this.routes.MINISTRY_ADMIN_BCGRAD_STUDENTS_REQUESTS : this.routes.SCHOOL_ADMIN_BCG_STUDENTS_REQUESTS;
        return await this.auth.apiPatch(route, id, data);
    }

    async deleteStudentRequest(id: number, accountType: AccountType): Promise<DbStudentRequest> {
        const route = accountType === AccountType.MINISTRY_ADMIN ? this.routes.MINISTRY_ADMIN_BCGRAD_STUDENTS_REQUESTS : this.routes.SCHOOL_ADMIN_BCG_STUDENTS_REQUESTS;
        return await this.auth.apiRemove(route, id);
    }

    async unenrolStudent(uid: number, assessmentCode: string, testWindow: TestWindow): Promise<void> {
        await this.auth.apiRemove(this.routes.SCHOOL_ADMIN_BCG_STUDENTS_REGISTER, uid, {
            query: {
                assessmentCode,
                testWindowId: testWindow.id
            },
        });
    }

    async findSchoolParticipation(pagination: Pagination, district?: District): Promise<PaginatedRows<SchoolParticipationRow>> {
        const data = await this.auth.apiFind(this.routes.MINISTRY_ADMIN_SCHOOL_PARTICIPATION, {
            query: {
                pagination,
                districtGroupId: district ? district.groupId : undefined,
            },
        });
        return {
            count: data.count,
            data: data.data.map(row => ({
                ...row,
                grade_4_participating: this.num2Boolean(row.grade_4_participating),
                grade_7_participating: this.num2Boolean(row.grade_7_participating),
                grade_4_print_materials: this.num2Boolean(row.grade_4_print_materials),
                grade_7_print_materials: this.num2Boolean(row.grade_7_print_materials),
                grades: JSON.parse(row.school_grades),
                has_students: this.num2Boolean(row.has_students),
            })),
        };
    }

    async patchSchoolParticipation(row: SchoolParticipationRow): Promise<void> {
        await this.auth.apiPatch(this.routes.MINISTRY_ADMIN_SCHOOL_PARTICIPATION, row.school_group_id, row);
    }

    async patchSchoolParticipationById(schoolGroupId: number, data: any): Promise<void> {
        await this.auth.apiPatch(this.routes.MINISTRY_ADMIN_SCHOOL_PARTICIPATION, schoolGroupId, data);
    }

    async patchSchoolPhysicalAddressById(schoolGroupId: number, data: any): Promise<void> {
        await this.auth.apiPatch(this.routes.MINISTRY_ADMIN_SCHOOLS, schoolGroupId, data, {
            query: {
                physicalAddress: true,
            }
        });
    }

    async findSpecialMaterialRequests(pagination: Pagination, district?: District): Promise<PaginatedRows<SpecialMaterialRequestRow>> {
        const data = await this.auth.apiFind(this.routes.MINISTRY_ADMIN_SPECIAL_MATERIAL_REQUESTS, {
            query: {
                pagination,
                districtGroupId: district ? district.groupId : undefined,
            },
        });
        return {
            count: data.count,
            data: data.data.map(row => ({
                ...row,
                is_participating: row.is_participating == 1 ? true : false,
                large_print_g4: row.large_print_g4,
                large_print_g7: row.large_print_g7,
                braille_g4: row.braille_g4,
                braille_g7: row.braille_g7,
                has_g4: this.num2Boolean(row.has_g4),
                has_g7: this.num2Boolean(row.has_g7),
            })),
        };
    }

    async patchSpecialMaterialRequest(schoolGroupId: number, large_print_quantity4: number, braille_quantity4: number, large_print_quantity7: number, braille_quantity7: number): Promise<void> {
        await this.auth.apiPatch(this.routes.MINISTRY_ADMIN_SPECIAL_MATERIAL_REQUESTS, schoolGroupId, {
            large_print_quantity4,
            braille_quantity4,
            large_print_quantity7,
            braille_quantity7,
        });
    }

    async findPrintOrders(pagination: Pagination): Promise<PaginatedRows<PrintOrderRow>> {
        const data = await this.auth.apiFind(this.routes.MINISTRY_ADMIN_PRINT_ORDERS, {
            query: {
                pagination,
            },
        });
        return data;
    }

    async patchPrintOrders(districtGroupId: number, print_order_send_to: PrintOrderSendTo): Promise<void> {
        await this.auth.apiPatch(this.routes.MINISTRY_ADMIN_PRINT_ORDERS, districtGroupId, {
            print_order_send_to,
        });
    }

    async findAllPrintOrders(): Promise<number[]> {
        return await this.auth.apiFind(this.routes.MINISTRY_ADMIN_PRINT_ORDERS);
    }

    private num2Boolean(num: number | string): boolean {
        return (num == 0 || num == "0") ? false : true;
    }

    async findPrintPackages(schoolGroupIds?: number[], print_order?: number, check_sum?: boolean): Promise<PrintPackagePreview[]> {
        const data = await this.auth.apiFind(this.routes.MINISTRY_ADMIN_PRINT_PACKAGES, {
            query: {
                school_group_ids: schoolGroupIds ? JSON.stringify(schoolGroupIds) : undefined,
                print_order,
                check_sum,
            },
        });
        return data.map(d => ({
            ...d,
            school_grades: JSON.parse(d.school_grades),
        }))
    }

    async findDistrictsOfPrintOrder(printOrder: number): Promise<District[]> {
        let { districts } = await this.auth.apiGet(this.routes.MINISTRY_ADMIN_PRINT_ORDERS, printOrder, {
            query: {
                action: 'districts',
            }
        });
        districts = districts.map(d => ({
            foreignId: d.foreign_id,
            name: d.name,
            groupId: d.group_id,
        }));
        const ENTIRE_PRINT_ORDER = {
            ...ALL_DISTRICT,
        };
        ENTIRE_PRINT_ORDER.name = 'Entire Print Order - Districts grouped';
        districts.unshift(ENTIRE_PRINT_ORDER);
        return districts;
    }

    async findSchoolsOfPrintOrder(printOrder: number): Promise<School[]> {
        let { schools } = await this.auth.apiGet(this.routes.MINISTRY_ADMIN_PRINT_ORDERS, printOrder, {
            query: {
                action: 'schools',
            }
        });
        schools = schools.map(s => ({
            foreignId: s.foreign_id,
            name: s.name,
            groupId: s.group_id,
        }));
        return schools;
    }

    async searchSchoolsByNameOrCode(pagination: Pagination, searchText: string, districtGroupId?: number): Promise<School[]> {
        const result = await this.auth.apiFind(this.routes.MINISTRY_ADMIN_SCHOOLS, {
            query: {
                pagination,
                districtGroupId,
                searchText,
            },
        });
        return result.map(school => ({
            groupId: school.group_id,
            foreignId: school.foreign_id,
            name: school.name,
        }));
    }

    makeInitialPackage(): PrintPackagePreview {
        const pkg: PrintPackagePreview = {
            school_name: '',
            school_code: -1,
            school_grades: [],
            school_separator_form_quantity: 0,
            packing_slip_quantity: 0,
            fsa_administration_manual_quantity: 0,
            list_of_students_form_quantity_g4: 0,
            list_of_students_form_quantity_g7: 0,
            password_information_sheet_quantity: 0,
            scoring_guide_quantity: 0,
            grade_4_quantity: {
                collaboration_booklets_quantity: 0,
                student_response_booklets_quantity: 0,
                reg_print_quantity: 0,
                large_print_quantity: 0,
                possible_solutions_quantity: 0,
                braille_quantity: 0,
            },
            grade_7_quantity: {
                collaboration_booklets_quantity: 0,
                student_response_booklets_quantity: 0,
                reg_print_quantity: 0,
                large_print_quantity: 0,
                possible_solutions_quantity: 0,
                braille_quantity: 0,
            },
        };
        return pkg;
    }


    combineQuantities(pkg: PrintPackagePreview, pkg2: PrintPackagePreview) {
        pkg.school_separator_form_quantity += pkg2.school_separator_form_quantity;
        pkg.packing_slip_quantity += pkg2.packing_slip_quantity;
        pkg.password_information_sheet_quantity += pkg2.password_information_sheet_quantity;
        if (pkg.list_of_students_form_quantity_g4) {
            pkg.list_of_students_form_quantity_g4 += pkg2.list_of_students_form_quantity_g4 || 0
        }
        if (pkg.list_of_students_form_quantity_g7) {
            pkg.list_of_students_form_quantity_g7 += pkg2.list_of_students_form_quantity_g7 || 0
        }
        pkg.fsa_administration_manual_quantity += pkg2.fsa_administration_manual_quantity;
        pkg.scoring_guide_quantity += pkg2.scoring_guide_quantity;
        if (pkg2.grade_4_quantity) {
            pkg.grade_4_quantity!.collaboration_booklets_quantity += pkg2.grade_4_quantity.collaboration_booklets_quantity;
            pkg.grade_4_quantity!.student_response_booklets_quantity += pkg2.grade_4_quantity.student_response_booklets_quantity;
            pkg.grade_4_quantity!.reg_print_quantity += pkg2.grade_4_quantity.reg_print_quantity;
            pkg.grade_4_quantity!.large_print_quantity += pkg2.grade_4_quantity.large_print_quantity;
            pkg.grade_4_quantity!.possible_solutions_quantity += pkg2.grade_4_quantity.possible_solutions_quantity;
            pkg.grade_4_quantity!.braille_quantity += pkg2.grade_4_quantity.braille_quantity;
        }
        if (pkg2.grade_7_quantity) {
            pkg.grade_7_quantity!.collaboration_booklets_quantity += pkg2.grade_7_quantity.collaboration_booklets_quantity;
            pkg.grade_7_quantity!.student_response_booklets_quantity += pkg2.grade_7_quantity.student_response_booklets_quantity;
            pkg.grade_7_quantity!.reg_print_quantity += pkg2.grade_7_quantity.reg_print_quantity;
            pkg.grade_7_quantity!.large_print_quantity += pkg2.grade_7_quantity.large_print_quantity;
            pkg.grade_7_quantity!.possible_solutions_quantity += pkg2.grade_7_quantity.possible_solutions_quantity;
            pkg.grade_7_quantity!.braille_quantity += pkg2.grade_7_quantity.braille_quantity;
        }
    }

    async mergeStudents(students: StudentRow[], truePEN: string) {

        for (const student of students) {
            if (student.pen != truePEN) {
                await this.auth.apiPatch(this.routes.MINISTRY_ADMIN_REGISTRATION, student.uid, {
                    pen: truePEN,
                }, {
                    query: {
                        mergedPen: true,
                    }
                });
            }
        }
    }

    async findActivityLogs(uid: number, testWindow: TestWindow): Promise<ActivityLog[]> {
        const activityLogs = await this.auth.apiFind(this.routes.MINISTRY_ADMIN_ACTIVITY_LOGS, {
            query: {
                uid: uid,
                testWindowId: testWindow.id,
            },
        });
        return activityLogs.map(al => ({
            ...al,
            created_on: utc(al.created_on),
        }));
    }

    async lookup2Find(pen: string, schoolGroupId?: number): Promise<{ result: LookupResult, uid?: number }> {
        return await this.auth.apiFind(this.routes.SCHOOL_ADMIN_BCG_STUDENTS_LOOKUP2, {
            query: {
                pen,
                school_group_id: schoolGroupId,
            }
        })
    }

    async lookup2Get(uid: number, schoolGroupId: number, testWindow: TestWindow): Promise<AssessmentComponent[]> {
        const data = await this.auth.apiGet(this.routes.SCHOOL_ADMIN_BCG_STUDENTS_LOOKUP2, uid, {
            query: {
                school_group_id: schoolGroupId,
                test_window_id: testWindow.id,
            },
        });
        return data.map(d => ({
            ...d,
            code: d.assessment_code,
        }));
    }

    async findTestAttemptMetas(test_window_id: number, meta_key: string): Promise<any[]> {
        return await this.auth.apiFind(this.routes.MINISTRY_ADMIN_TEST_ATTEMPT_METAS, {
            query: {
                test_window_id,
                meta_key,
            }
        })
    }

    async createSpecialCase(test_window_id: number, uid: number, assessment: string, caseType: string): Promise<any[]> {
        return await this.auth.apiCreate(this.routes.MINISTRY_ADMIN_BCGRAD_STUDENTS_REQUESTS, {
            test_window_id,
            uid,
            assessment,
        },
            {
                query: {
                    requestType: caseType,
                },
            });
    }

    async registerStudentToFsaAssessmentComponents(uid: number, schoolGroupId: number, testWindow: TestWindow): Promise<void> {
        await this.auth.apiCreate(this.routes.SCHOOL_ADMIN_BCG_STUDENTS_REGISTRATION, {
            uid,
            school_group_id: schoolGroupId,
            test_window_id: testWindow.id,
            assessment_type: AssessmentType.FSA,
        });
    }

    async unregisterStudentFromFsaAssessmentComponents(uid: number, schoolGroupId: number, testWindow: TestWindow): Promise<void> {
        await this.auth.apiPatch(this.routes.SCHOOL_ADMIN_BCG_STUDENTS_REGISTRATION, uid, {
            school_group_id: schoolGroupId,
            test_window_id: testWindow.id,
            assessment_type: AssessmentType.FSA,
        });
    }

    async registerStudentToGradAssessmentComponents(uid: number, schoolGroupId: number, testWindow: TestWindow, components: AssessmentComponent[]): Promise<void> {
        await this.auth.apiCreate(this.routes.SCHOOL_ADMIN_BCG_STUDENTS_REGISTRATION, {
            uid,
            school_group_id: schoolGroupId,
            test_window_id: testWindow.id,
            slugs: components.map(c => `${c.assessment.toUpperCase()}_${c.code}`),
            assessment_type: AssessmentType.GRAD,
        });
    }

    async unregisterStudentFromGradAssessmentComponents(uid: number, schoolGroupId: number, testWindow: TestWindow, components: AssessmentComponent[]): Promise<void> {
        await this.auth.apiPatch(this.routes.SCHOOL_ADMIN_BCG_STUDENTS_REGISTRATION, uid, {
            school_group_id: schoolGroupId,
            test_window_id: testWindow.id,
            slugs: components.map(c => `${c.assessment.toUpperCase()}_${c.code}`),
            assessment_type: AssessmentType.GRAD,
        });
    }

    async enrollStudentToSchool(pen: string, schoolGroupId: number, firstName: string, lastName: string, grade?: number, assessmentType: AssessmentType = AssessmentType.FSA): Promise<{
        uid: number,
        message: string,
    }> {
        const data = await this.auth.apiCreate(this.routes.SCHOOL_ADMIN_BCG_STUDENTS_ENROLLMENT, {
            school_group_id: schoolGroupId,
            pen,
            firstName,
            lastName,
            grade,
            assessment_type: assessmentType,
        });
        return data;
    }

    async unenrollStudentFromSchool(uid: number, schoolGroupId: number): Promise<void> {
        await this.auth.apiPatch(this.routes.SCHOOL_ADMIN_BCG_STUDENTS_ENROLLMENT, uid, {
            school_group_id: schoolGroupId,
            assessment_type: AssessmentType.GRAD,
        });
    }

    async unenrollStudentFromFSASchool(uid: number, schoolGroupId: number): Promise<void> {
        await this.auth.apiPatch(this.routes.SCHOOL_ADMIN_BCG_STUDENTS_ENROLLMENT, uid, {
            school_group_id: schoolGroupId,
            assessment_type: AssessmentType.FSA,
        });
    }

    getDistrictDisplay(district: District): string {
        if (district === null) return '';
        if (district.groupId == -1 || district.foreignId == 0) return district.name;
        return `${district.name} (${this.formatDistrictCode(district.foreignId)})`;
    }

    formatDistrictCode(districtCode: number): string {
        return districtCode.toString().padStart(3, '0');
    }

    formatSchoolCode(schoolCode: number): string {
        return schoolCode.toString().padStart(8, '0');
    }

    getSchoolDisplay(school: School): string {
        if (school === null) return '';
        if (school.groupId == -1) return school.name;
        return `${school.name} (${this.formatSchoolCode(school.foreignId)})`;
    }

    async getSchoolLevelDataReport(pagination: Pagination, schoolGroupId: number, testWindow: TestWindow): Promise<SchoolLevelDataReport> {
        return await this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: 'school_level_data_report',
                school_group_id: schoolGroupId,
                test_window_id: testWindow.id,
                pagination,
            },
        });
    }

    async exportSchoolLevelDataReport(schoolGroupId: number, testWindow: TestWindow, isScaled: boolean): Promise<void> {
        let filename = isScaled ? 'School-Level-Scaled-Data' : 'School-Level-Raw-Data';
        const url = this.auth.reportFilePath(this.routes.MINISTRY_ADMIN_REPORTS, JSON.stringify({
            query: {
                action: 'report',
                report: 'school_level_data_report',
                school_group_id: schoolGroupId,
                test_window_id: testWindow.id,
                is_scaled: isScaled,
            }
        }), filename);
        window.open(url, '_blank');
    }

    async getItemDataReport(testWindow: TestWindow, districtGroupId?: number, schoolGroupId?: number, assessmentCode?: string): Promise<ItemDataReportRow[]> {
        return await this.auth.apiFind(this.routes.MINISTRY_ADMIN_REPORTS, {
            query: {
                action: 'report',
                report: 'item data report',
                district_group_id: districtGroupId,
                school_group_id: schoolGroupId,
                test_window_id: testWindow.id,
                assessment_code: assessmentCode,
            }
        })
    }

    async getConfirmationCodes(uid: number, testWindow: TestWindow, testSessionSlug: string): Promise<ConfirmationCode[]> {
        const codes = await this.auth.apiGet(this.routes.MINISTRY_ADMIN_CONFIRMATION_CODES, uid, {
            query: {
                test_window_id: testWindow.id,
                test_session_slug: testSessionSlug,
            },
        });

        return codes.map(c => ({
            ...c,
            created_on: utc(c.created_on),
            is_unsubmitted: this.num2Boolean(c.is_unsubmitted),
            unsubmitted_on: c.unsubmitted_on ? utc(c.unsubmitted_on) : null,
        }))
    }

    async hasUnsubmissions(uid: number, testWindow: TestWindow, testSessionSlug: string): Promise<boolean> {
        const data = await this.auth.apiFind(this.routes.MINISTRY_ADMIN_CONFIRMATION_CODES, {
            query: {
                uid,
                test_window_id: testWindow.id,
                test_session_slug: testSessionSlug,
            },
        });

        return data.has_unsubmissions == 1;
    }

    async downloadEdfmc2LineDataFile(school_group_ids: number[], testWindow: TestWindow) {
        const url = this.auth.textFilePath(this.routes.MINISTRY_ADMIN_EDFMC2, JSON.stringify({
            query: {
                school_group_ids: JSON.stringify(school_group_ids),
                test_window_id: testWindow.id,
            }
        }), 'EDFMC2_FSA', 'lin');
        window.open(url, '_blank');
    }

    async exemptStudent(uid: number, exempted: boolean, reason?: string, otherReason?: string): Promise<void> {
        await this.auth.apiPatch(this.routes.MINISTRY_ADMIN_EXEMPTION, uid, {
            exempted: exempted ? 1 : 0,
            reason,
            otherReason,
        });
    }

    async createSchoolAdmin(data: CreateSchoolAdminData): Promise<any> {
        return await this.auth.apiCreate(this.routes.MINISTRY_ADMIN_ACCOUNTS, {
            ...data,
            accountType: 'school admin',
        });
    }

    async createDistrictAdmin(data: CreateDistrictAdminData): Promise<any> {
        return await this.auth.apiCreate(this.routes.MINISTRY_ADMIN_ACCOUNTS, {
            ...data,
            accountType: 'district admin',
        })
    }

    calculateLevelFromIrtScore(componentCode: string, score: number): SchoolLevelDataReportProficiencyLevel {
        // component code is 'LTE4'
        let language = componentCode[2] == 'E' ? 'English' : 'French';
        let grade = parseInt(componentCode[3])
        let component = componentCode.substr(0, 2) == 'LT' ? 'literacy' : 'numeracy';

        const proficiencyLevelRange = {
            'English': {
                4: {
                    'literacy': [-0.8865, 0.9082],
                    'numeracy': [-0.7048, 1.2806],
                },
                7: {
                    'literacy': [-0.9901, 1.3053],
                    'numeracy': [-0.7048, 1.0312],
                },
            },
            'French': {
                4: {
                    'literacy': [-1.0096, 0.5236],
                    'numeracy': [-0.7048, 1.2806],
                },
                7: {
                    'literacy': [-0.0963, 0.7598],
                    'numeracy': [-0.7048, 1.0312],
                },
            },
        }

        let languageRange = proficiencyLevelRange[language];
        let gradeRange = languageRange[grade];
        let range = gradeRange[component];

        if (score < range[0]) {
            return SchoolLevelDataReportProficiencyLevel.EMERGING;
        }
        if (score < range[1]) {
            return SchoolLevelDataReportProficiencyLevel.ON_TRACK;
        }
        return SchoolLevelDataReportProficiencyLevel.EXTENDING;
    }

    translateProficiencyLevel(level: SchoolLevelDataReportProficiencyLevel): string {
        switch (level) {
            case SchoolLevelDataReportProficiencyLevel.EMERGING:
                return 'Emerging';
            case SchoolLevelDataReportProficiencyLevel.EXTENDING:
                return 'Extending';
            case SchoolLevelDataReportProficiencyLevel.ON_TRACK:
                return 'On Track';
            default:
                return 'Unknown: student did not participate';
        }
    }

    async createDistrict(data: {
        name: string,
        address?: string,
        city?: string,
        province?: string,
        postal_code?: string,
        phone_number?: string,
        fax_number?: string,
        foreign_id: number,
        print_order: 1 | 2 | 3 | 4 | 5 | 6 | 7,
    }) {
        return await this.auth.apiCreate(this.routes.MINISTRY_ADMIN_DISTRICTS, data);
    }

    async createSchool(data: {
        schl_dist_group_id: number,
        foreign_id: number,
        name: string,
        lang: string,
        address?: string,
        city?: string,
        province?: string,
        postal_code?: string,
        phone_number?: string,
        fax_number?: string,
        type: string,
        is_fsa?: number,
        is_grad?: number,
        grades?: string,
        is_independent?: number,
    }) {
        return await this.auth.apiCreate(this.routes.MINISTRY_ADMIN_SCHOOLS, data);
    }

    findSchoolsByGrade(pagination: Pagination): Promise<PaginatedRows<{
        group_id: number,
        schl_dist_group_id: number,
        school_code: number,
        school_name: string,
        grade: 4 | 7,
    }>> {
        return this.auth.apiFind(this.routes.MINISTRY_ADMIN_SCHOOLS, {
            query: {
                byGrade: 1,
                pagination,
            }
        });
    }
}


