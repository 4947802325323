import { Injectable } from '@angular/core';
import { AuthService } from './api/auth.service';
import { RoutesService } from './api/routes.service';

interface AssessmentDef 
{
  id: number,
  assessment_slug: string,
  order: number,
  is_disabled: 0 | 1 | null
}

enum Requests
{
  assessmentDef = "assessmentDef"
}

@Injectable({
  providedIn: 'root'
})

export class AssessmentsService {

  private isDataAlreadyInited: boolean = false;
  private assessmentDefs: AssessmentDef[] = [];
  constructor(private auth: AuthService, private routes: RoutesService)
  { 
  }

  getAssessmentDefs(): AssessmentDef[]
  {
    return this.assessmentDefs;
  }

  getEnabledAssessmentDefs(): AssessmentDef[]
  {
    return this.assessmentDefs.filter(def => def.is_disabled === 0);

  }

  setAssessmentDefs(definitions: AssessmentDef[]): void
  {
    if (definitions != null && definitions.length !== 0)
    {
      this.assessmentDefs = definitions;
    }
  }

  async initData(forceReinit: boolean = false)
  {
    if (!this.isDataAlreadyInited || forceReinit)
    {
      let defs = await this.auth.apiFind(this.routes.ASSESSMENTS_INFO, 
        {
          query: 
          {
            request: Requests.assessmentDef
          }
        });

        this.setAssessmentDefs(defs);
        this.isDataAlreadyInited = true;
    }
  }
}
