import { IContentElementImage } from "../element-render-image/model";
import { IContentElementMath } from "../element-render-math/model";
import { IContentElementText } from "../element-render-text/model";
import { IContentElement, IEntryStateScored, IScoredResponse, IVoiceover } from "../models";

export enum ESelectionTableScoreMode {
    ROW_COL = 'ROW',
    CELL = 'CELL',
    LEGACY = 'LEGACY'
}
export interface IContentElementSelectionTable extends IContentElement, IScoredResponse {
    topRow: IContentElementSelectionTableCell[];
    leftCol: IContentElementSelectionTableCell[];
    fontSize: number;
    checkBoxRows: IContentElementSelectionTableBool[][];
    isMustHaveOnePerRow: boolean;
    isMustHaveOnePerCol: boolean;
    maxCheckedPerRow?: number;
    maxCheckedPerCol?: number;
    checkBoxSize: number;
    isQuestionWidthSet?: boolean;
    isAnswerWidthSet?: boolean;
    isAnswerHeightSet?: boolean;
    isMaxDenoScoring?:boolean;
    questionColumnWidth?: number;
    answerColumnWidth?: number;
    answerColumnHeight?: number;
    isFirstRowHeader?: boolean;
    isFirstColHeader?: boolean;
    configColWidth?: number;
    configRowWidth?: number;
    showAudio?: boolean;
    isRadio?: boolean;
    topLeftText?: IContentElementText;
    topLeft_voiceover?: IVoiceover;
    isLeftColumnHidden?: boolean;
    isTopRowHidden?: boolean;
    isElementInSelectableCell?: boolean;
    isCellPaddingSet?:boolean;
    leftCellPadding?: number;
    rightCellPadding?: number;
    topCellPadding?: number;
    bottomCellPadding?: number;
    scoreMode?: ESelectionTableScoreMode; 
    splitData?: boolean;
}

export interface IContentElementSelectionTableCell extends IContentElement {
    content: any;
    alignText?: string;
    alignItems?: string;
    audio?: IContentElementImage;
}
  
export interface IContentElementSelectionTableBool {
    value: boolean;
  
    cellType?: string;
    mathCellElement?: IContentElementMath;
    textCellElement?: IContentElementText;
    imgCellElement?: IContentElementImage;
    selectedCellType?: string;
    mathCellSelectedElement?: IContentElementMath;
    textCellSelectedElement?: IContentElementText;
    imgCellSelectedElement?: IContentElementImage;
    isSelectionDisabled?: boolean;
    defaultColor?: string;
    selectedColor?: string;
}

export interface IEntryStateSelectionTable extends IEntryStateScored {
    checkMarks: { value: boolean }[][];
  }

  export const selectTableEditInfo= {
    editExcludeFields: ['topRow', 'leftCol', 'checkBoxRows', 'topLeftText'],
    keyFieldsToShow: ['showAudio']
}

export const getDerivedLegacyScoreMode = (element: IContentElementSelectionTable) => {
    const scoreMode = getScoreMode(element);
    if(scoreMode !== ESelectionTableScoreMode.LEGACY) {
        return scoreMode;
    }

    const isOneSelPerRow = (+element.maxCheckedPerRow === 1);
    const isOneSelPerCol = (+element.maxCheckedPerCol === 1);
    const isRowsAsEntries = isOneSelPerRow && !isOneSelPerCol;

    if(isRowsAsEntries){
      return ESelectionTableScoreMode.ROW_COL
    } else {
      return ESelectionTableScoreMode.CELL
    }
}

export const getScoreMode = (element: IContentElementSelectionTable) => {
    if(!element.scoreMode) {
        return ESelectionTableScoreMode.LEGACY
    } 
      
    return element.scoreMode;
}