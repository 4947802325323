import { PubSubTypes } from "../element-render-frame/pubsub/types";
import { IContentElementImage } from "../element-render-image/model";
import { McqDisplay } from "../element-render-mcq/model";
import { Direction, ElementType, IContentElement, IEntryState, IEntryStateScored, IScoredResponse } from "../models";
import { DRAW_MODES } from "./controllers/freehand-pencil";
import { EPixiTools, IDrawnMeta } from "./types/types";


export enum CustomInteractionType {
    GRID_DND          = "GRID_DND",
    GRID_FILL         = 'GRID_FILL',
    GRID_SCATTER_PLOT = 'GRID_SCATTER_PLOT',
    GROUPING          = 'GROUPING',
    PIE_CHART         = 'PIE_CHART',
    ROTATE_SPRITE     = "ROTATE_SPRITE",
    SCALE_RADIUS      = "SCALE_RADIUS",
    SLIDER            = "SLIDER",
    PLAYGROUND        = "PLAYGROUND",
  }

export interface IContentElementVirtualTools extends IContentElement, IScoredResponse {
    interactionType: CustomInteractionType;
    showGrid: boolean;
    tickSpacing: number;
    canvasWidth: number;
    canvasHeight: number;
    rulerHeight: number;
    rulerWidth: number;
    rulerX: number;
    rulerY: number;
    rulerImage?: IContentElementImage;
    isRulerDisabled: boolean;
    scale: number;
    spriteBgWidth: number;
    spriteBgHeight: number;
    spriteBgY: number;
    spriteBgX: number;
    scaleBg: number;
    bgImage?: IContentElementImage;
    protractorX: number;
    protractorY: number; 
    protractorRad: number;
    isProtractorDisabled: boolean;
    isShowAdvancedOptions: boolean;
    counterRadius: number;
    counterColor: string;
    counterNumberColor: string;
    counterOpacity: number;
    isCounterDisabled: boolean;
    polygonConfig: string;
    fhPolygonDisabled: boolean;
    fhPolygonColor: string;
    fhPolygonOpacity: number;
    fhLineDisabled: boolean;
    fhLineColor: string;
    fhLineOpacity: number;
    fhRectangleOutlineDisabled: boolean;
    fhRectangleOutlineColor: string;
    fhRectangleOutlineOpacity: number;
    fhCircleShapeDisabled: boolean;
    fhCircleShapeColor: string;
    fhCircleShapeOpacity: number;
    isGraphDisabled: boolean;
    graphDisabled: boolean;
    fhRectangleShapeDisabled: boolean;
    fhRectangleShapeColor: string;
    fhRectangleShapeOpacity: number;
    fhCircleOutlineDisabled: boolean;
    fhCircleOutlineColor: string;
    fhCircleOutlineOpacity: number;
    fhPencilDisabled: boolean;
    fhMarkerDisabled: boolean;
    fhHighlighterDisabled: boolean;
    fhHighlighterOpacity: number;
    textBoxDisabled: boolean;
    tableDisabled: boolean;
    pencilCategoryDisabled: boolean;
    textBoxCategoryDisabled: boolean;
    dataCategoryDisabled: boolean;
    measurementCategoryDisabled: boolean;
    shapesCategoryDisabled: boolean;
    shapePrestesCategoryDisabled: boolean;
    toolbarCategoryOrderList: String[];
    toolbarSettingPreset: string;
    toolbarSettings: {
        categoryName: string, 
        isCategoryDisabled?: boolean;
        tools: {toolName: string, isDisabled?: boolean}[]
    }[];
    noMajorGridLines: boolean;
}

export interface toolbarCategory {
    toolCategory: {name: string, tools: {toolName: string, isDisabled?: boolean}[]};
}

export interface ITextboxData extends IDrawnMeta{
    id: number,
    input: string,
    isRevoked?: boolean,
    fontSize?: number,
    activeCharIndex?: number,
    selectedStartIndex?: number,
    selectedEndIndex?: number,
    isTableCell?: boolean,
    isDrawn?: boolean,
    color?: number,
    tableId?: number,
    isActive?: boolean,
    isEditing?: boolean,
  }

export const customInteractionEditInfo = {
    editExcludeFields: {}
}

export interface IEntryStateVirtualTools extends IEntryState, IEntryStateScored {
    data: {}; // turn data field into a map for all virtual tools
}

export interface ITable extends IDrawnMeta {
    id: number,
    isRevoked: boolean,
}

export interface ICircle extends IDrawnMeta {
    radius: number
}

export interface IDrawing extends IDrawnMeta {
    type: DRAW_MODES,
    lineIndex?: number
}