import { Component, Input, OnInit } from '@angular/core';
import { LangService } from 'src/app/core/lang.service';
import { TextToSpeechService } from '../text-to-speech.service';
import { WhitelabelService } from 'src/app/domain/whitelabel.service';

@Component({
  selector: 'results-summary-cr',
  templateUrl: './results-summary-cr.component.html',
  styleUrls: ['./results-summary-cr.component.scss']
})
export class ResultsSummaryCrComponent implements OnInit {

  @Input() totalCRScore: number;
  @Input() numCRQuestions: number;
  @Input() isExportPage: boolean = false;
  
  constructor(public lang: LangService, public textToSpeech: TextToSpeechService, public whitelabel: WhitelabelService) {}

  ngOnInit(): void {
  }

  getStyle() {
    if(this.isExportPage) {
      return {
        'width.em': 30
      }
    } else {
      return {};
    }
  }

  isHiContrast(){
    return this.textToSpeech.isHiContrast;
  }

  isABED() {
    return this.whitelabel.isABED();
  }

  getResultsCrQuestionsSlug(){
    if (this.isABED()){
      return 'tr_results_cr_questions_ABED';
    }
    return 'tr_results_cr_questions';
  }

}
