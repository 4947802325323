import * as PIXI from "pixi.js-legacy";
import { ICircle, IContentElementVirtualTools, IEntryStateVirtualTools } from "../model";
import { VirtualTools, EToolType, OUTLINE_WIDTH } from "./virtual-tools";
import * as _ from "lodash";
import { SpriteLoader } from "../../element-render-custom-interaction/controllers/sprite-loader";
import { IPoint } from "./virtual-tools";
import { IExtendedTools } from "../element-render-virtual-tools.component";
import { EPixiTools, ENamingTypes } from "../types/types";
import { LAYER_LEVEL } from "../types/constants";
import { QuestionState } from "../../models";

enum ACTIONS {
  DUPLICATE = "DUPLICATE",
  DELETE = "DELETE",
}
export class FreehandCircleShape extends VirtualTools {
  element: IContentElementVirtualTools;
  spriteLoader: SpriteLoader
  stage: PIXI.Container;
  vertices: PIXI.Graphics;
  lines: PIXI.Graphics;
  backgroundSprite: PIXI.Sprite;
  dupeSprite: PIXI.Sprite;
  deleteSprite: PIXI.Sprite;
  paletteSprite: PIXI.Sprite;
  drawnCircle: PIXI.Graphics;
  drawnCircles: PIXI.Graphics[] = [];
  circleTools: IExtendedTools;
  circleRadius: number;
  isGlobalResizing: boolean;
  isGlobalDragging: boolean;
  circleContainersOnResizing: PIXI.Graphics
  DEFAULT_CIRCLE_RADIUS = 20;


  constructor(
    questionState: QuestionState,
    element: IContentElementVirtualTools,  
    addGraphic, 
    render,
    getToolStateSub,
    stage,
    isLocked, 
    textToSpeech,
    isGlobalRotating: boolean, 
    isGlobalDragging: boolean,
    backgroundSprite: PIXI.Sprite,
    colorController: {color: number},
    layerLevel: LAYER_LEVEL,
    spriteLoader: SpriteLoader
  ) {
    super(questionState, addGraphic, render, getToolStateSub, stage, isLocked, textToSpeech, isGlobalRotating, isGlobalDragging);
    this.initTool({name: EPixiTools.CIRCLE_FILL, type: EToolType.BUTTON, layerLevel: layerLevel});
    this.questionState = questionState;    this.initTool({name: EPixiTools.CIRCLE_FILL, type: EToolType.BUTTON });
    this.element = element;
    this.spriteLoader = spriteLoader;
    this.backgroundSprite = backgroundSprite;
    this.dupeSprite = new PIXI.Sprite();
    this.deleteSprite = new PIXI.Sprite();
    this.paletteSprite = new PIXI.Sprite();

    this.initialPositionOffest_X = 0;
    this.initialPositionOffest_Y = 0;

    this.loadAssets().then(this.initSprites);

    //#stopping Ticker
    PIXI.Ticker.system.autoStart = false
    PIXI.Ticker.system.stop()

    // this.addDrawVertexListener();
    // this.deselectAllCirclesListener();

    this.vertices = new PIXI.Graphics();
    this.lines = new PIXI.Graphics();
    this.lines.zIndex = 1;
    this.vertices.zIndex = 8;
    this.circleRadius = 12;
    this.addGraphic(this.vertices);
    this.addGraphic(this.lines);
    this.circleContainersOnResizing = new PIXI.Graphics;
    this.addGraphic(this.circleContainersOnResizing);

    // this.circleTools = {
    //   parent: 'circle',
    //   colors: [
    //     this.element.fhLineColor,
    //     '#c43f2e',
    //     '#2a91d6'
    //   ],        
    //   colorHandler: (color: string) => {
    //     this.circleColor = color;
    //   },
    //   tools: [
    //   {
    //     id: 'delete',
    //     action: () => this.clearDrawnCircles(),
    //     iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/delete/1684351529074/delete.png',
    //     isSelected: () => false,
    //   },
    //   {
    //     id: 'colorPicker',
    //     isColorPick: true,
    //     colorController: this.circleColor,
    //     action: () => {},
    //     isSelected: () => false,
    //   },
    // ]}

    // Render scene
    this.render();
  }

  initSprites = ({resources}) => {
    if (!resources || Object.keys(resources).length == 0) {
      this.dupeSprite.texture = PIXI.utils.TextureCache.duplicate;
      this.deleteSprite.texture = PIXI.utils.TextureCache.delete;
      this.paletteSprite.texture = PIXI.utils.TextureCache.palette;
    } else {
      this.dupeSprite.texture = resources.duplicate.texture;
      this.deleteSprite.texture = resources.delete.texture;
      this.paletteSprite.texture = resources.palette.texture;
    }    
  }
  
  // containerID = 0;
  // circleOutlines = new Map<string, {outline: PIXI.Graphics, menu: PIXI.Graphics, resizePoint: PIXI.Graphics, visible: boolean}>();
  completeCircle(points: IPoint[], circleRadius: number, isFromResize?: boolean, isRestore?: boolean, origin?: IPoint, drawOutlineBorder?: boolean, color?: number) {
    // Translate points to 0,0
    let transX = 0;
    let transY = 0;
    for(let i = 0; i< points.length; i++) {
      if(i == 0) {
        transX = points[0].x;
        transY = points[0].y;
        points[0].x = 0;
        points[0].y = 0;
      } else {
        points[i].x -= transX;
        points[i].y -= transY;
      }
    }

    this.vertices.removeChildren();

    const circleContainer = new PIXI.Graphics();
    circleContainer.name = this.getName(ENamingTypes.CONTAINER);

    const fillColor = color? color : this.selectedColor;
    const fillOpacity = this.element.fhCircleShapeOpacity || 0.8;
    const fill = {color: fillColor, opacity: fillOpacity};
    const circle = this.drawCircle(0, 0, fill, points, false, circleRadius);
    circle.name = this.getName(ENamingTypes.TOOL)
    circle.pivot.set(0.5);
    // circle.interactive = false;
    circleContainer.addChild(circle);
    circleContainer.zIndex = this.getContainerZindex(7);
    const center = this.getShapeCenter(points);
    
    circleContainer.pivot.set(center.x,center.y);    
    if(isRestore) {
      circleContainer.position.set(origin.x, origin.y);
    } else {
      circleContainer.position.set(transX + center.x, transY + center.y);
    }
    // if(!drawOutlineBorder && !this.isSelectionToolActive) circleContainer.interactiveChildren = false
    // this.addGraphic(circleContainer);
    // this.drawnCircle = circleContainer;

    const resizePoint = this.drawResizePoint({x: center.maxX + circleRadius, y: 0}, circleContainer, circle, isFromResize, isFromResize);
    circleContainer.addChild(resizePoint)
    // resizePoint.alpha = 0;

    let border;
    let menu;
    if (drawOutlineBorder && isFromResize){
      border = this.drawSelectedCircleBorder(circleContainer, points, circle.width/2);
      border.alpha = 1;

      const menuContainer =  new PIXI.Container();
      menuContainer.name = this.getName(ENamingTypes.MENU);
      menuContainer.addChild(border)
      circleContainer.addChild(menuContainer);

      // menu = this.drawMenu(circle, circleContainer, points);
      // menu.alpha = 1;
      // this.isLineSelected = true;
      // this.lineMenu = {border: border, menu: menu, rotationPoint: rotationPoints};
      // if (!isFromResize) this.circleOutlines.set(circleContainer.name, {outline: border, menu: menu, resizePoint: resizePoint, visible: true})
    }

    if (!isFromResize){
      this.addContainer(circleContainer, <ICircle>{
        points,  
        _isSelected: isRestore ? false : drawOutlineBorder,
        color: fillColor,
        fillOpacity,
        x: circleContainer.x,
        y: circleContainer.y,
        radius: circleRadius,
        zIndex: circleContainer.zIndex
      })
      
      this.updateState();
      this.addSelectCircleListener(circle, circleContainer, resizePoint, [...points], border, menu);
      // this.addDragAndResizeListener(circleContainer);
      // this.drawnCircles.push(circleContainer)
    } else {
      this.circleContainersOnResizing.addChild(circleContainer);
    }
    
    return circleContainer;
  }

  drawMenu(obj: PIXI.Graphics, container: PIXI.Graphics, points?: IPoint[]) {

    if(!points?.length) {
      points = this.getObjectMetaProps()[container.name].points
    }

    const center = this.getShapeCenter(points);
    const menu = new PIXI.Graphics();
    const menuYOffset = 5;
    const menuY = center.y + ((center.maxY - center.minY) / 2) + menuYOffset;
    const menuHeight = 30;
    const menuWidth = 55;
    menu.beginFill(0x645f73);
    menu.lineStyle(0);
    menu.drawRoundedRect(0, 0, menuWidth, menuHeight, 5);
    menu.position.set(center.minX - menuWidth/2, menuY + obj.width/2);
    menu.endFill();

    menu.alpha = 0;

    const containerId = this.getContainerIdFromName(container.name);

    // Menu icons
    const dupe = new PIXI.Sprite();
    dupe.texture = this.dupeSprite.texture;
    dupe.scale.set(0.03);
    dupe.anchor.set(0,0.5);
    dupe.y = menuHeight / 2;
    dupe.x = 10;
    dupe.interactive = true;
    dupe.name = this.getName(ENamingTypes.MENU_DUPE, containerId)

    const del = new PIXI.Sprite();
    del.texture = this.deleteSprite.texture;
    del.scale.set(0.03);
    del.anchor.set(0,0.5);
    del.y = menuHeight / 2;
    del.x = dupe.x + dupe.width + 5;
    del.interactive = true;
    del.name = this.getName(ENamingTypes.MENU_DEL, containerId)

    menu.addChild(dupe);
    menu.addChild(del);
    this.addMenuListeners(obj, ACTIONS.DUPLICATE, dupe, points, undefined, container);
    this.addMenuListeners(container, ACTIONS.DELETE, del, points);
    
    // container.addChild(menu);

    return menu;
  }

  addMenuListeners(obj: PIXI.Graphics, action: ACTIONS, option: PIXI.Sprite, points: IPoint[], menu?: PIXI.Graphics, container?: PIXI.Graphics) {
    let func;
    option.cursor = 'pointer';
    if(action == ACTIONS.DUPLICATE) {
      func = (e) => {
        const currentTarget = (e.currentTarget as PIXI.Sprite)
        const toolObj = this.getToolObjectFromName(currentTarget.name)
        const fillColor = this.getFillColor(container);
        const dupPosition = this.getDuplicateObjectPosition(currentTarget)
        const circleContainer = this.completeCircle([...points], toolObj.width/2, false, true, {x: dupPosition.x,y: dupPosition.y},false, fillColor);
        this.activateListner(circleContainer)
        this.render();
      }
    } else if(action == ACTIONS.DELETE) {
      func = (e) => {
        this.deleteContainerFromMenu(e.currentTarget as PIXI.Sprite);
        this.updateState();
      }
    } 
    option.on('pointerup', func);
  }

  deleteObject(obj: PIXI.Graphics) {
    obj.removeChildren();
    obj.clear();
  }

  circleBorder: PIXI.Graphics;
  circlesSelectStatus = new Map();
  addSelectCircleListener(circle: PIXI.Graphics, container: PIXI.Graphics, resizePoint: PIXI.Graphics, points: IPoint[], existingBorder: PIXI.Graphics,existingMenu: PIXI.Graphics) {
    // let dragging = false;
    // let resizing = false;
    // let outterBorder;
    // let menu;
    // this.circlesSelectStatus.set(container.name, false);
    // let selected = this.circlesSelectStatus.get(container.name);

    // const onClick = (e) => {
    //   dragging = false;

    //   this.stage.on('pointermove', checkDragging).on('pointerup', toggleMenu);
    // }
    // const checkDragging = (e) => {
    //   dragging = this.isGlobalDragging;
    //   resizing = this.isGlobalResizing;
    //   dragging = this.circleContainersDragStatus.get(container.name);
    // }
    // const toggleMenu = (e) => {      
    //   this.circlesClicked.set(container.name, true);
    //   if(!dragging && !this.isGlobalDragging && !resizing) {
    //     if (existingBorder || existingMenu){
    //       if (existingBorder){
    //         existingBorder.destroy();
    //         existingBorder = undefined;
    //       } 
    //       if (existingMenu){
    //         existingMenu.destroy();
    //         existingMenu = undefined;
    //       }
    //       resizePoint.alpha = 0;
    //       this.circlesSelectStatus.set(container.name, false);
    //       this.circleOutlines.delete(container.name);
    //       this.render();
    //       return;
    //     }

    //     selected = !selected;
    //     if(selected || !(this.circleOutlines.get(container.name) && this.circleOutlines.get(container.name).visible)) {
    //       outterBorder = this.drawSelectedCircleBorder(container, points, circle.width/2);
    //       menu = this.drawMenu(circle, container, points);
    //       outterBorder.alpha = 1;
    //       menu.alpha = 1;
    //       resizePoint.alpha = 1;
    //       this.circleOutlines.set(container.name, {outline: outterBorder, menu: menu, resizePoint: resizePoint, visible: true});
    //       this.circlesClicked.set(container.name, true);
    //       selected = true;
    //     } else {
    //       outterBorder.destroy();
    //       menu.destroy();
    //       resizePoint.alpha = 0;
    //       this.circleOutlines.set(container.name, undefined);
    //       this.circlesClicked.set(container.name, false);
    //     }

    //     this.circlesSelectStatus.set(container.name, selected);
    //     this.render();

    //     this.stage.removeListener('pointermove', checkDragging);
    //     this.stage.removeListener('pointerup', toggleMenu);
    //   }
    // }
    // circle.on('pointerdown', onClick);
    circle.on('pointerdown', ($event) => super.onPointerDown($event))
  }
  clearActiveSelection = (container: PIXI.Graphics) => {
    this.removeCircleMenu(container);
  }

  addActiveSelection = (container: PIXI.Graphics) => {
    this.addCircleMenu(container)
  }

  addCircleMenu(container: PIXI.Graphics){

    // get lineMenuContainer Object
    const containerId = this.getContainerIdFromName(container.name);
    const menuContainerName = this.getName(ENamingTypes.MENU, containerId)
    let menuContainer = <PIXI.Container>container.getChildByName(menuContainerName);
    const circleName = this.getName(ENamingTypes.TOOL, containerId)
    const circle = <PIXI.Graphics>container.getChildByName(circleName);

    // resize point
    const resizePointName = this.getName(ENamingTypes.RESIZE_ANCHOR, containerId);
    const resizePoint = <PIXI.Graphics>container.getChildByName(resizePointName);
    resizePoint.alpha = 1
    resizePoint.interactive = true
    
    
    // new menu container childs
    const border = this.drawSelectedCircleBorder(container, undefined, circle.width/2);
    const menu = this.drawMenu(circle, container);
    border.alpha = 1;
    menu.alpha = 1;

    if(!menuContainer){
      // add new menu container inside lineContainer
      menuContainer =  new PIXI.Container();
      menuContainer.name = this.getName(ENamingTypes.MENU, containerId);
      container.addChild(menuContainer);
    }
    menuContainer.addChild(...[border, menu])
  }

  removeCircleMenu(container: PIXI.Graphics){
    const containerId = this.getContainerIdFromName(container.name);
    const menuContainerName = this.getName(ENamingTypes.MENU, containerId)
    let menuContainer = <PIXI.Container>container.getChildByName(menuContainerName);
    if(menuContainer) menuContainer.removeChildren()

    // resize point
    const resizePointName = this.getName(ENamingTypes.RESIZE_ANCHOR, containerId);
    const resizePoint = <PIXI.Graphics>container.getChildByName(resizePointName);
    if(resizePoint) {
      resizePoint.alpha = 0
      resizePoint.interactive = false
    }
  }


  points: IPoint[] = [];
  addDrawVertexListener() {
    const addVertice = (e) => {
      if(this.isDrawMode && !this.isGlobalDragging && !this.isGlobalRotating && !this.isGlobalResizing) {
        const {x,y} = e.data.getLocalPosition(this.stage);
        const point = {x, y};
        this.points.push(point);
        this.drawShapeVertices(this.vertices, this.lines, this.points, () => {});
      
        if(this.points.length >= 1){
            this.completeCircle(this.points, this.DEFAULT_CIRCLE_RADIUS);
            this.points = [];

            // this.isDrawMode = false;
            this.stage.cursor = 'default';
            this.backgroundSprite.cursor = 'default';
            this.render();
        }
      }
    }

    // this.stage.on('pointerup', addVertice);
  }

  drawResizePoint(point: {x: number, y: number}, parent: PIXI.Graphics, circle: PIXI.Graphics, noListeners: boolean, showByDefault?: boolean) {
    const resizePoint = new PIXI.Graphics();
    const containerId = this.getContainerIdFromName(parent.name);
    resizePoint.name = this.getName(ENamingTypes.RESIZE_ANCHOR, containerId);
    resizePoint.beginFill(0x0000, 1);
    resizePoint.drawCircle(point.x, point.y, 4);
    resizePoint.endFill();
    resizePoint.pivot.set(0, 0);
    if (!showByDefault) resizePoint.alpha = 0;
    // resizePoint.rotation = -0.785398;
    // parent.addChild(resizePoint);

    if(!noListeners) this.addDragAndResizeListener(parent, circle, true, resizePoint);

    return resizePoint;
  }

  previousCircleContainerAnchor: PIXI.DisplayObject;
  circleContainersDragStatus = new Map();

  addDragAndResizeListener(circleContainer: PIXI.Graphics, circle: PIXI.Graphics, isResize?: boolean, resizePoint?: PIXI.Graphics) {
    this.isGlobalDragging = false;
    let isResizeDragging = false
    circleContainer.cursor = 'grab';
    // circleContainer.interactive = false;
    if (isResize){
      resizePoint.cursor = 'ew-resize'
      // resizePoint.interactive = false;
    }
    // this.circleContainersDragStatus.set(circleContainer.name, false);

    // const onDragStart = (e) => {
    //     const mousePosition = e.data.getLocalPosition(this.stage);
    //     isDragging = true;
    //     initialDiffX = mousePosition.x - circleContainer.x
    //     initialDiffY = mousePosition.y - circleContainer.y
    //     circleContainer.cursor = 'grabbing';

    //     this.stage.on('pointerup', onDragEnd)
    //     .on('pointermove', onDragMove);
    // }
    // const onDragEnd = (e) => {
    //   isDragging = false;
    //   this.isGlobalDragging = false
    //   circleContainer.cursor = 'grab';
    //   this.circleContainersDragStatus.set(circleContainer.name, false);
    //   this.circlesClicked.set(circleContainer.name, true);
    //   this.render();

    //   this.stage.removeListener('pointerup', onDragEnd);
    //   this.stage.removeListener('pointermove', onDragMove);

    //   this.initialPositionOffest_X = 0;
    //   this.initialPositionOffest_Y = 0;
    // }

    // const onDragMove = (e: PIXI.InteractionEvent) => {
    //     if(isDragging && !this.isGlobalRotating && !this.isProtRotateDragging && !this.isRulerRotateDragging && !this.isGlobalResizing) {
    //         this.isGlobalDragging = true;
    //         const mousePosition = e.data.getLocalPosition(this.stage);
    //         circleContainer.x = mousePosition.x - initialDiffX;
    //         circleContainer.y = mousePosition.y - initialDiffY;
    //         this.circleContainersDragStatus.set(circleContainer.name, true);

    //         this.render();
    //     } else if(!isDragging) {
    //       this.isGlobalDragging = false;
    //     }
    // }

    const onResizeStart = (e) => {
      this.isResizingPointerDown = true
      isResizeDragging = true;
      this.isGlobalResizing = true;
      circleContainer.cursor = 'grabbing';
      circleContainer.removeAllListeners();

      this.stage.on('pointerup', onResizeEnd)
      .on('pointermove', onResizeMove);
    }
    const onResizeEnd = (e) => {
      this.isResizingPointerDown = false
      isResizeDragging = false;
      this.isGlobalResizing = false;
      circleContainer.cursor = 'grab';
      this.circleContainersOnResizing.removeChildren();
      const mousePosition = e.data.getLocalPosition(this.stage);

      if (this.previousCircleContainerAnchor) this.previousCircleContainerAnchor.destroy();
      // circleContainer.children.forEach((child, i) => {
      //   circleContainer.getChildAt(i).removeAllListeners();
      //   circleContainer.getChildAt(i).destroy();
      // })
      // this.drawnCircle.destroy();
      // this.previousCircleContainerAnchor.alpha = 0;

      const fillColor = this.getFillColor(circleContainer)
      const newContainer = this.completeCircle([{x: circleContainer.x, y:circleContainer.y}], this.getNewCircleRadiusOnResize(mousePosition, circleContainer), false, false, null, true, fillColor);
      this.removeContainer(circleContainer)
      this.updateState();
      this.activateListner(newContainer);
      
      // this.circleOutlines.delete(circleContainer.name);
      // this.circlesClicked.delete(circleContainer.name);
      // this.circlesClicked.set(newContainer.name, true);
      this.render();
      this.stage.removeListener('pointerup', onResizeEnd);
      this.stage.removeListener('pointermove', onResizeMove);
    }
    const onResizeMove = (e: PIXI.InteractionEvent) => {
      if(isResizeDragging) {
        this.isGlobalResizing = true;
        this.circleContainersOnResizing.removeChildren();
        resizePoint.alpha = 0;
        const mousePosition = e.data.getLocalPosition(this.stage);

        circleContainer.children.forEach((child, i) => {
          if (child.name != ENamingTypes.RESIZE_ANCHOR){
            circleContainer.getChildAt(i).destroy()
          } else {
              this.previousCircleContainerAnchor = child;
          }
        })
        const drawPoints = [{x: circleContainer.x, y:circleContainer.y}]
        const newRadius = this.getNewCircleRadiusOnResize(mousePosition, circleContainer);

        const fillColor = this.getFillColor(circleContainer);
        this.completeCircle(drawPoints, newRadius , true, false, null, true, fillColor);
        this.render();
      } else if(!isResizeDragging) {
        this.isGlobalResizing = false;
      }
    }

    // circleContainer.on('pointerdown', onDragStart);
    if(isResize) resizePoint.on('pointerdown', onResizeStart);
  }

  drawSelectedCircleBorder(container: PIXI.Graphics, points: IPoint[], circleRadius: number) {
    if(!points?.length) {
      points = this.getObjectMetaProps()[container.name].points
    }
    const center = this.getShapeCenter(points);
    const border = new PIXI.Graphics();
    border.lineStyle(3, 0x0000, 1);
    border.drawCircle(center.x, center.y, circleRadius);
    border.endFill();
    border.alpha = 0;

    // container.addChild(border);

    return border;
  }

  getNewCircleRadiusOnResize(mousePosition, circleContainer){
    const deltaX = mousePosition.x - circleContainer.x; 
    const deltaY = mousePosition.y - circleContainer.y;
    const newRadius = Math.sqrt(deltaX * deltaX + deltaY * deltaY)
    return newRadius;
  }

  isDrawMode = false;
  initialPositionOffest_X: number;
  initialPositionOffest_Y: number;
  toggleDrawMode(mode?: boolean) {
    this.isDrawMode = mode == null ? !this.isDrawMode : mode;
    if(!this.isDrawMode) {
        this.stage.cursor = 'default';
        this.backgroundSprite.cursor = 'default';
    } else {
        this.stage.cursor = 'pointer';
        this.backgroundSprite.cursor = 'pointer';
    }
    this.vertices.removeChildren();
    this.lines.removeChildren();
    this.points = [];

    const canvasMid_X = this.element.canvasWidth / 2;
    const canvasMid_Y = this.element.canvasHeight / 2;

    const point = {x: canvasMid_X, y: canvasMid_Y};
    this.points.push(point);
    this.drawShapeVertices(this.vertices, this.lines, this.points, () => {});
  
    const circleContainer = this.completeCircle(this.points, this.DEFAULT_CIRCLE_RADIUS, false, false, null, true);
    circleContainer.position.set(circleContainer.x += this.initialPositionOffest_X, circleContainer.y += this.initialPositionOffest_Y);
    
    this.points = [];
    this.initialPositionOffest_X += 15;
    this.initialPositionOffest_Y += 15;

    // this.isDrawMode = false;
    this.stage.cursor = 'default';
    this.backgroundSprite.cursor = 'default';
    
    this.activateSelectionTool();
    this.render(); 
    // console.log('Circle mode', this.isDrawMode)
    return this.isDrawMode;
  }

  clearDrawnCircles() {
    this.deleteObject(this.drawnCircle);
    this.drawnCircle.destroy();
    this.drawnCircle = undefined;
    this.render();
  }

  getCircleTools() {
    return this.circleTools;
  }

  // circlesClicked = new Map();
  // circleBordersToggledOffByClickElsewhere = new Map();
  // deselectAllCirclesListener() {
  //   const deselectAll = () => {
  //     for (const [key, value] of this.circleOutlines.entries()) {
  //       if (value && !this.circlesClicked.get(key)){
  //         value.outline.alpha = 0;
  //         value.menu.alpha = 0;
  //         value.resizePoint.alpha = 0;
  //         value.visible = false;
  //       } else {
  //         this.circlesClicked.set(key, false);
  //         this.circlesSelectStatus.set(key, false);
  //       }
  //     }
  //     this.render();
  //   }
  //   this.stage.on('pointerup', deselectAll);
  // }

  loadAssets() {
    return this.spriteLoader.loadSprites()
  }

  // initToolStateSub(){
  //   const toolStateSub = this.getToolStateSub();
  //   Object.keys(toolStateSub).forEach(key => {
  //     switch (key) {
  //       case 'selector':
  //         toolStateSub[key].subscribe(o => this.onSelectToolSub(o))   
  //         break;      
  //       default:
  //         break;
  //     }
  //   })
  // }

  // isSelectionToolActive = false
  // onSelectToolSub(o: {val: boolean, origin: 'BASE' | 'CHILD'}){
  //   this.isSelectionToolActive = o.val
  //   if(!o.val) return this.removeListners();
  //   this.activateListners();
  // }

  // removeListners(){
  //   this.drawnCircles.forEach(circleContainer => {
  //     // TODO: these follow the same as freehand-line but will require alot of refactoring so going with this instead
  //     this.removeListner(circleContainer)
  //   })
  // }

  // activateListners(){
  //   this.drawnCircles.forEach(circleContainer => {
  //     // TODO: these follow the same as freehand-line but will require alot of refactoring so going with this instead
  //     this.activateListner(circleContainer)
  //   })
  // } 

  // removeListner(circleContainer: PIXI.Graphics){
  //   circleContainer.interactiveChildren = false;
  // }

  activateListner(container: PIXI.Graphics){
    container.interactive = true;
    container.children.forEach(child => {
      if(child.name?.includes(ENamingTypes.RESIZE_ANCHOR)){
        child.interactive = false // should olny be true when selected  
      }
      child.interactive = true
    })
  }

  activateSelectionListners(){
    this.getObjectContainer().forEach((container: PIXI.Graphics) => this.activateListner(container))
  }

  changeDrawnGraphicColor(currentSelectedObject: PIXI.Graphics){
    if (!currentSelectedObject.name) return;

    const circleGraphic = <PIXI.Graphics>currentSelectedObject.children.filter(child => child.name.includes(ENamingTypes.TOOL))[0]
    const pos_x = circleGraphic.x;
    const pos_y = circleGraphic.y;
    const circleRadius = (circleGraphic.width - OUTLINE_WIDTH) / 2;
    const alpha = circleGraphic.alpha;

    circleGraphic.clear();
    circleGraphic.beginFill(this.selectedColor, this.ObjectMetaPropsContainer[currentSelectedObject.name].fillOpacity);
    circleGraphic.drawCircle(0, 0, circleRadius);
    circleGraphic.endFill();
    circleGraphic.interactive = true;
    circleGraphic.zIndex = 6;
    circleGraphic.interactive = true;

    circleGraphic.lineStyle(OUTLINE_WIDTH, this.getParsedColor('#ffffff'), 1);
    circleGraphic.drawCircle(0, 0, circleRadius);
    circleGraphic.position.set(pos_x, pos_y);
    circleGraphic.alpha = 1;

    const drawColor = this.selectedColor;
    this.ObjectMetaPropsContainer[currentSelectedObject.name].color = drawColor;
  
    this.render();
  }
  getUpdatedState(entry: Partial<IEntryStateVirtualTools>): Partial<IEntryStateVirtualTools> {
    entry.data[EPixiTools.CIRCLE_FILL] = Object.values(this.ObjectMetaPropsContainer);
    return entry;
  }

  handleNewState() {
    const data = this.getQuestionState(EPixiTools.CIRCLE_FILL);
    if(!data || data.length == 0) {
      return;
    }

    data.forEach((circle) => {
      const temp: ICircle = JSON.parse(JSON.stringify(circle))
      this.completeCircle(temp.points, temp.radius, false, true, {x: temp.x, y: temp.y}, true, temp.color);
    });

    this.render();
  }
}
