import { Component, OnInit, Input } from '@angular/core';
import { LangService } from '../../core/lang.service';
import { LoginGuardService } from '../../api/login-guard.service';
import { WhitelabelService } from '../../domain/whitelabel.service';

@Component({
  selector: 'confidentiality-agreement',
  templateUrl: './confidentiality-agreement.component.html',
  styleUrls: ['./confidentiality-agreement.component.scss']
})
export class ConfidentialityAgreementComponent implements OnInit {

  @Input() savePayload: { [key: string]: any };
  @Input() saveProp: string;

  constructor(
    public lang: LangService,
    private whitelabel: WhitelabelService,
    private loginGuard: LoginGuardService,
  ) { }

  ngOnInit() {
  }

}
