import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/api/auth.service';
import { ALL_SCHOOLS, BcAccountsService, DistrictDetail, Pagination, School } from 'src/app/bc-accounts/bc-accounts.service';
import { TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { AccountType } from 'src/app/constants/account-types';
import { BreadcrumbsService } from 'src/app/core/breadcrumbs.service';
import { LangService } from 'src/app/core/lang.service';
import { SidepanelService } from 'src/app/core/sidepanel.service';
import { BcHeaderLayoutComponent, ButtonConfig } from '../bc-header-layout/bc-header-layout.component';

enum AccountTab {
  DISTRICT = 'District',
  SCHOOL = 'School',
}

@Component({
  selector: 'bc-accounts-admins',
  templateUrl: './bc-accounts-admins.component.html',
  styleUrls: ['./bc-accounts-admins.component.scss']
})
export class BcAccountsAdminsComponent implements OnInit, OnDestroy {

  @ViewChild(BcHeaderLayoutComponent) headerLayout: BcHeaderLayoutComponent;


  accountType: AccountType;

  breadcrumb = [];



  pagination: Pagination;

  selectedSchool: School;
  districtDetail: DistrictDetail;
  selectedTestWindow: TestWindow;


  // for district admin only
  extraButtons: ButtonConfig[] = [];
  previousSchool: School = null;
  tabs: AccountTab[];
  selectedTab: AccountTab;
  AccountTab = AccountTab;

  userSubscription: Subscription;

  constructor(
    private bcAccounts: BcAccountsService,
    private breadcrumbService: BreadcrumbsService,
    private sidePanel: SidepanelService,
    private lang: LangService,
    private auth: AuthService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    this.pagination = this.bcAccounts.getInitialPagination();

    this.sidePanel.activate();
    this.sidePanel.unexpand();

    this.tabs = [];

    this.userSubscription = this.auth.user().subscribe(userInfo => {
      this.accountType = userInfo.accountType;


      if (this.isDistrictAdmin()) {
        this.tabs = [AccountTab.DISTRICT, AccountTab.SCHOOL];
        this.selectedTab = AccountTab.DISTRICT;
      }

      this.updateBreadcrumbs();
    })

  }

  ngOnDestroy() {
    if (this.userSubscription) this.userSubscription.unsubscribe();
  }

  updateBreadcrumbs() {
    let admin = this.isSchoolAdmin() ? this.lang.tra('sa_dashboard_school_admin') : this.lang.tra('da_dashboard_district_admin');
    if (!this.isFSA()) admin += this.lang.tra('sa_grad_bcgrad');
    else admin += this.lang.tra('sa_fsa_bcfsa');

    let breadcrumbs = [];
    if (this.isSchoolAdmin()) {
      breadcrumbs = [
        this.breadcrumbService._CURRENT(admin, `${this.getBaseRoute()}/dashboard`),
        this.breadcrumbService._CURRENT(this.lang.tra('sa_admin_accounts_bc'), `${this.getSchoolAdminRoute()}`)
      ];
    } else if (this.isDistrictAdmin()) {
      breadcrumbs = [
        this.breadcrumbService._CURRENT(this.lang.tra('da_dashboard_district_admin_bc'), `${this.getBaseRoute()}/dashboard`),
        this.breadcrumbService._CURRENT(this.lang.tra('sa_admin_accounts_bc'), `${this.getSchoolAdminRoute()}`)
      ];
    } else {
      breadcrumbs = [
        this.breadcrumbService._CURRENT("Ministry Administration Coordinator", `${this.getBaseRoute()}/dashboard`),
        this.breadcrumbService._CURRENT(this.lang.tra('sa_admin_accounts_bc'), `${this.getSchoolAdminRoute()}`)
      ];
    }
    this.breadcrumb = breadcrumbs;
  }

  getBaseRoute() {

    if (this.isSchoolAdmin()) {
      return `/${this.lang.c()}/${AccountType.SCHOOL_ADMIN}/bc-fsa`;
    } else if (this.isDistrictAdmin()) {
      return `/${this.lang.c()}/${AccountType.DIST_ADMIN}/bc-fsa`;
    } else {
      return `/${this.lang.c()}/${AccountType.MINISTRY_ADMIN}/bc-fsa`;
    }
  }

  getSchoolAdminRoute() {
    return `${this.getBaseRoute()}/school_administrators`;
  }

  isFSA(): boolean {
    return this.route.snapshot.data['schoolType'] === 'BC_FSA'
  }


  isDistrictAdmin(): boolean {
    return this.auth.isDistrictAdmin(this.accountType);
  }

  isSchoolAdmin(): boolean {
    return this.auth.isSchoolAdmin(this.accountType);
  }

  isMinistryAdmin(): boolean {
    return this.auth.isMinistryAdmin(this.accountType);
  }


  extraButtonClicked(buttonId: string) {
    if (buttonId == 'all-schools') {
      if (this.headerLayout) {
        this.headerLayout.forceSchoolSelection(ALL_SCHOOLS);
      }
    } else if (buttonId == 'previous-school') {
      if (this.previousSchool && this.headerLayout) {
        this.headerLayout.forceSchoolSelection(this.previousSchool);
      }
    }
  }

  onSelectedSchoolChange(school: School) {
    this.previousSchool = this.selectedSchool;
    this.selectedSchool = school;
    if (this.isDistrictAdmin()) {
      if (this.selectedSchool.groupId > 0) {
        this.extraButtons = [{
          buttonId: 'all-schools',
          tra: 'sa_dist_admin_view_all_schools',
          style: {
            'background': '#176FF3',
            'color': 'white',
            'border-radius': '5px',
            'height': '28px',
          },
        }];
      } else if (this.previousSchool) {
        this.extraButtons = [{
          buttonId: 'previous-school',
          tra: 'sa_dist_admin_return_to_previous_school',
          style: {
            'background': '#E2E2E2',
            'border-radius': '5px',
            'height': '28px',
          },
        }];
      } else {
        this.extraButtons = [];
      }
    }
  }

  onSelectedDistrictChange(districtDetail: DistrictDetail) {
    this.districtDetail = districtDetail;
  }

  onSelectedTestWindowChange(testWindow: TestWindow) {
    this.selectedTestWindow = testWindow;
  }

  onSelectedTabChange(tab: AccountTab) {
    this.selectedTab = tab;
  }
}
