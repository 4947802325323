import { Component } from '@angular/core';

export const keyboardSets = {
  en: {
    grade6: {
      rows: [
        [
          {
            class: "keycap",
            latex: "7"
          },
          {
            class: "keycap",
            latex: "8"
          },
          {
            class: "keycap",
            latex: "9"
          },
          {
            class: "keycap",
            latex: "\\div"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap",
            latex: "("
          },
          {
            class: "keycap",
            latex: ")"
          },
          {
            class: "separator w5"
          },
          {
            class: "separator w5"
          },
        ],
        [
          {
            class: "keycap",
            latex: "4"
          },
          {
            class: "keycap",
            latex: "5"
          },
          {
            class: "keycap",
            latex: "6"
          },
          {
            class: "keycap",
            latex: "\\times"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap tex",
            insert: "#@^2",
            label: "<span><i>x</i>&thinsp;²</span>"
          },
          {
            class: "keycap tex",
            insert: "$$#@^{#?}$$",
            latex: "x^{#0}"
          },
          {
            class: "action",
            command: ["insert", "\\quad"],
            label: "&#x2fd;"
          },
        ],
        [
          {
            class: "keycap",
            latex: "1"
          },
          {
            class: "keycap",
            latex: "2"
          },
          {
            class: "keycap",
            latex: "3"
          },
          {
            class: "keycap",
            latex: "-"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap tex",
            latex: "x"
          },
          {
            class: "keycap tex",
            latex: "\\frac{#0}{#0}"
          },
          {
            class: "action",
            command: ["performWithFeedback", "deletePreviousChar"],
            label: "&#x232b;"
          }
        ],
        [
          {
            class: "keycap",
            latex: "0"
          },
          {
            class: "keycap",
            latex: "."
          },
          {
            class: "keycap",
            latex: "="
          },
          {
            class: "keycap",
            latex: "+"
          },
          {
            class: "separator w5"
          },
          {
            class: "action",
            command: ["performWithFeedback", "moveToPreviousChar"],
            label: "<svg><use xlink:href='#svg-arrow-left' /></svg>"
          },
          {
            class: "action",
            command: ["performWithFeedback", "moveToNextChar"],
            label: "<svg><use xlink:href='#svg-arrow-right' /></svg>"
          },
          {
            class: "action",
            command: ["performWithFeedback", "complete "],
            label: "<svg><use xlink:href='#svg-tab' /></svg>"
          }
        ]
      ]
    },
    grade9: {
      rows: [
        [
          {
            class: "keycap",
            latex: "\\lt"
          },
          {
            class: "keycap",
            latex: "\\gt"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap",
            latex: "7"
          },
          {
            class: "keycap",
            latex: "8"
          },
          {
            class: "keycap",
            latex: "9"
          },
          {
            class: "keycap",
            latex: "\\div"
          },
          {
            class: "separator w5"
          },
          {
            class: "separator w5"
          },
          {
            class: "separator w5"
          },
          {
            class: "separator w5"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap",
            latex: "\\sqrt{#0}"
          },
        ],
        [
          {
            class: "keycap",
            latex: "\\leq"
          },
          {
            class: "keycap",
            latex: "\\geq"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap",
            latex: "4"
          },
          {
            class: "keycap",
            latex: "5"
          },
          {
            class: "keycap",
            latex: "6"
          },
          {
            class: "keycap",
            latex: "\\times"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap tex",
            insert: "#@^2",
            label: "<span><i>x</i>&thinsp;²</span>"
          },
          {
            class: "keycap tex",
            insert: "$$#@^{#?}$$",
            latex: "x^{#0}"
          },
          {
            class: "action",
            command: ["insert", "\\quad"],
            label: "&#x2fd;"
          },
        ],
        [
          {
            class: "keycap",
            latex: "["
          },
          {
            class: "keycap",
            latex: "]"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap",
            latex: "1"
          },
          {
            class: "keycap",
            latex: "2"
          },
          {
            class: "keycap",
            latex: "3"
          },
          {
            class: "keycap",
            latex: "-"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap tex",
            latex: "x"
          },
          {
            class: "keycap tex",
            latex: "\\frac{#0}{#0}"
          },
          {
            class: "action",
            command: ["performWithFeedback", "deletePreviousChar"],
            label: "&#x232b;"
          }
        ],
        [
          {
            class: "keycap",
            latex: "("
          },
          {
            class: "keycap",
            latex: ")"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap",
            latex: "0"
          },
          {
            class: "keycap",
            latex: "."
          },
          {
            class: "keycap",
            latex: "="
          },
          {
            class: "keycap",
            latex: "+"
          },
          {
            class: "separator w5"
          },
          {
            class: "action",
            command: ["performWithFeedback", "moveToPreviousChar"],
            label: "<svg><use xlink:href='#svg-arrow-left' /></svg>"
          },
          {
            class: "action",
            command: ["performWithFeedback", "moveToNextChar"],
            label: "<svg><use xlink:href='#svg-arrow-right' /></svg>"
          },
          {
            class: "action",
            command: ["performWithFeedback", "complete "],
            label: "<svg><use xlink:href='#svg-tab' /></svg>"
          }
        ]
      ]
    },
    CAEC: {
      rows: [
        [
          {
            class: "keycap",
            latex: "7"
          },
          {
            class: "keycap",
            latex: "8"
          },
          {
            class: "keycap",
            latex: "9"
          },
          {
            class: "keycap",
            latex: "\\div"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap",
            latex: "("
          },
          {
            class: "keycap",
            latex: ")"
          },
          {
            class: "keycap",
            latex: "\\sqrt{#0}"
          },
        ],
        [
          {
            class: "keycap",
            latex: "4"
          },
          {
            class: "keycap",
            latex: "5"
          },
          {
            class: "keycap",
            latex: "6"
          },
          {
            class: "keycap",
            latex: "\\times"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap tex",
            insert: "#@^2",
            label: "<span><i>x</i>&thinsp;²</span>"
          },
          {
            class: "keycap tex",
            insert: "$$#@^{#?}$$",
            latex: "x^{#0}"
          },
          {
            class: "action",
            command: ["insert", "\\quad"],
            label: "&#x2fd;"
          },
        ],
        [
          {
            class: "keycap",
            latex: "1"
          },
          {
            class: "keycap",
            latex: "2"
          },
          {
            class: "keycap",
            latex: "3"
          },
          {
            class: "keycap",
            latex: "-"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap tex",
            latex: "x"
          },
          {
            class: "keycap tex",
            latex: "\\frac{#0}{#0}"
          },
          {
            class: "action",
            command: ["performWithFeedback", "deletePreviousChar"],
            label: "&#x232b;"
          }
        ],
        [
          {
            class: "keycap",
            latex: "0"
          },
          {
            class: "keycap",
            latex: "."
          },
          {
            class: "keycap",
            latex: "="
          },
          {
            class: "keycap",
            latex: "+"
          },
          {
            class: "separator w5"
          },
          {
            class: "action",
            command: ["performWithFeedback", "moveToPreviousChar"],
            label: "<svg><use xlink:href='#svg-arrow-left' /></svg>"
          },
          {
            class: "action",
            command: ["performWithFeedback", "moveToNextChar"],
            label: "<svg><use xlink:href='#svg-arrow-right' /></svg>"
          },
          {
            class: "action",
            command: ["performWithFeedback", "complete "],
            label: "<svg><use xlink:href='#svg-tab' /></svg>"
          }
        ]
      ]
    },
    diploma: {
      rows: [
        [
          {
            class: "keycap",
            latex: "\\leq"
          },
          {
            class: "keycap",
            latex: "\\geq"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap",
            latex: "7"
          },
          {
            class: "keycap",
            latex: "8"
          },
          {
            class: "keycap",
            latex: "9"
          },
          {
            class: "keycap",
            latex: "\\div"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap",
            latex: "\\frac{#0}{#0}"
          },
          {
            class: "keycap",
            latex: "\\sqrt{#0}"
          },
          {
            class: "keycap",
            latex: "\\sqrt[#0]{#0}"
          },
        ],
        [
          {
            class: "keycap",
            latex: "\\lt"
          },
          {
            class: "keycap",
            latex: "\\gt"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap",
            latex: "4"
          },
          {
            class: "keycap",
            latex: "5"
          },
          {
            class: "keycap",
            latex: "6"
          },
          {
            class: "keycap",
            latex: "\\times"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap tex",
            insert: "#@^2",
            label: "<span><i>x</i>&thinsp;²</span>"
          },
          {
            class: "keycap tex",
            insert: "$$#@^{#?}$$",
            latex: "x^{#0}"
          },
          {
            class: "action",
            command: ["insert", "\\quad"],
            label: "&#x2fd;"
          },
        ],
        [
          {
            class: "keycap",
            latex: "["
          },
          {
            class: "keycap",
            latex: "]"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap",
            latex: "1"
          },
          {
            class: "keycap",
            latex: "2"
          },
          {
            class: "keycap",
            latex: "3"
          },
          {
            class: "keycap",
            latex: "-"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap tex",
            latex: "\\pi"
          },
          {
            class: "keycap tex",
            latex: "\\theta"
          },
          {
            class: "action",
            command: ["performWithFeedback", "deletePreviousChar"],
            label: "&#x232b;"
          }
        ],
        [
          {
            class: "keycap",
            insert: "\\footnotesize+\\normalsize{#0}",
            latex: "(+)"
          },
          {
            class: "keycap",
            insert: "\\footnotesize-\\normalsize{#0}",
            latex: "(-)"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap",
            latex: "0"
          },
          {
            class: "keycap",
            latex: "."
          },
          {
            class: "keycap",
            latex: "="
          },
          {
            class: "keycap",
            latex: "+"
          },
          {
            class: "separator w5"
          },
          {
            class: "action",
            command: ["performWithFeedback", "moveToPreviousChar"],
            label: "<svg><use xlink:href='#svg-arrow-left' /></svg>"
          },
          {
            class: "action",
            command: ["performWithFeedback", "moveToNextChar"],
            label: "<svg><use xlink:href='#svg-arrow-right' /></svg>"
          },
          {
            class: "action",
            command: ["performWithFeedback", "complete "],
            label: "<svg><use xlink:href='#svg-tab' /></svg>"
          }
        ]
      ]
    }
  },
  fr: {
    grade6: {
      rows: [
        [
          {
            class: "keycap",
            latex: "7"
          },
          {
            class: "keycap",
            latex: "8"
          },
          {
            class: "keycap",
            latex: "9"
          },
          {
            class: "keycap",
            latex: "\\div"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap",
            latex: "("
          },
          {
            class: "keycap",
            latex: ")"
          },
          {
            class: "separator w5"
          },
          {
            class: "separator w5"
          },
        ],
        [
          {
            class: "keycap",
            latex: "4"
          },
          {
            class: "keycap",
            latex: "5"
          },
          {
            class: "keycap",
            latex: "6"
          },
          {
            class: "keycap",
            latex: "\\times"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap tex",
            insert: "#@^2",
            label: "<span><i>x</i>&thinsp;²</span>"
          },
          {
            class: "keycap tex",
            insert: "$$#@^{#?}$$",
            latex: "x^{#0}"
          },
          {
            class: "action",
            command: ["insert", "\\quad"],
            label: "&#x2fd;"
          },
        ],
        [
          {
            class: "keycap",
            latex: "1"
          },
          {
            class: "keycap",
            latex: "2"
          },
          {
            class: "keycap",
            latex: "3"
          },
          {
            class: "keycap",
            latex: "-"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap tex",
            latex: "x"
          },
          {
            class: "keycap tex",
            latex: "\\frac{#0}{#0}"
          },
          {
            class: "action",
            command: ["performWithFeedback", "deletePreviousChar"],
            label: "&#x232b;"
          }
        ],
        [
          {
            class: "keycap",
            latex: "0"
          },
          {
            class: "keycap",
            latex: ","
          },
          {
            class: "keycap",
            latex: "="
          },
          {
            class: "keycap",
            latex: "+"
          },
          {
            class: "separator w5"
          },
          {
            class: "action",
            command: ["performWithFeedback", "moveToPreviousChar"],
            label: "<svg><use xlink:href='#svg-arrow-left' /></svg>"
          },
          {
            class: "action",
            command: ["performWithFeedback", "moveToNextChar"],
            label: "<svg><use xlink:href='#svg-arrow-right' /></svg>"
          },
          {
            class: "action",
            command: ["performWithFeedback", "complete "],
            label: "<svg><use xlink:href='#svg-tab' /></svg>"
          }
        ]
      ]
    },
    grade9: {
      rows: [
        [
          {
            class: "keycap",
            latex: "\\lt"
          },
          {
            class: "keycap",
            latex: "\\gt"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap",
            latex: "7"
          },
          {
            class: "keycap",
            latex: "8"
          },
          {
            class: "keycap",
            latex: "9"
          },
          {
            class: "keycap",
            latex: "\\div"
          },
          {
            class: "separator w5"
          },
          {
            class: "separator w5"
          },
          {
            class: "separator w5"
          },
          {
            class: "separator w5"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap",
            latex: "\\sqrt{#0}"
          },
        ],
        [
          {
            class: "keycap",
            latex: "\\leq"
          },
          {
            class: "keycap",
            latex: "\\geq"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap",
            latex: "4"
          },
          {
            class: "keycap",
            latex: "5"
          },
          {
            class: "keycap",
            latex: "6"
          },
          {
            class: "keycap",
            latex: "\\times"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap tex",
            insert: "#@^2",
            label: "<span><i>x</i>&thinsp;²</span>"
          },
          {
            class: "keycap tex",
            insert: "$$#@^{#?}$$",
            latex: "x^{#0}"
          },
          {
            class: "action",
            command: ["insert", "\\quad"],
            label: "&#x2fd;"
          },
        ],
        [
          {
            class: "keycap",
            latex: "["
          },
          {
            class: "keycap",
            latex: "]"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap",
            latex: "1"
          },
          {
            class: "keycap",
            latex: "2"
          },
          {
            class: "keycap",
            latex: "3"
          },
          {
            class: "keycap",
            latex: "-"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap tex",
            latex: "x"
          },
          {
            class: "keycap tex",
            latex: "\\frac{#0}{#0}"
          },
          {
            class: "action",
            command: ["performWithFeedback", "deletePreviousChar"],
            label: "&#x232b;"
          }
        ],
        [
          {
            class: "keycap",
            latex: "("
          },
          {
            class: "keycap",
            latex: ")"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap",
            latex: "0"
          },
          {
            class: "keycap",
            latex: ","
          },
          {
            class: "keycap",
            latex: "="
          },
          {
            class: "keycap",
            latex: "+"
          },
          {
            class: "separator w5"
          },
          {
            class: "action",
            command: ["performWithFeedback", "moveToPreviousChar"],
            label: "<svg><use xlink:href='#svg-arrow-left' /></svg>"
          },
          {
            class: "action",
            command: ["performWithFeedback", "moveToNextChar"],
            label: "<svg><use xlink:href='#svg-arrow-right' /></svg>"
          },
          {
            class: "action",
            command: ["performWithFeedback", "complete "],
            label: "<svg><use xlink:href='#svg-tab' /></svg>"
          }
        ]
      ]
    },
    CAEC: {
      rows: [
        [
          {
            class: "keycap",
            latex: "7"
          },
          {
            class: "keycap",
            latex: "8"
          },
          {
            class: "keycap",
            latex: "9"
          },
          {
            class: "keycap",
            latex: "\\div"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap",
            latex: "("
          },
          {
            class: "keycap",
            latex: ")"
          },
          {
            class: "keycap",
            latex: "\\sqrt{#0}"
          },
        ],
        [
          {
            class: "keycap",
            latex: "4"
          },
          {
            class: "keycap",
            latex: "5"
          },
          {
            class: "keycap",
            latex: "6"
          },
          {
            class: "keycap",
            latex: "\\times"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap tex",
            insert: "#@^2",
            label: "<span><i>x</i>&thinsp;²</span>"
          },
          {
            class: "keycap tex",
            insert: "$$#@^{#?}$$",
            latex: "x^{#0}"
          },
          {
            class: "action",
            command: ["insert", "\\quad"],
            label: "&#x2fd;"
          },
        ],
        [
          {
            class: "keycap",
            latex: "1"
          },
          {
            class: "keycap",
            latex: "2"
          },
          {
            class: "keycap",
            latex: "3"
          },
          {
            class: "keycap",
            latex: "-"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap tex",
            latex: "x"
          },
          {
            class: "keycap tex",
            latex: "\\frac{#0}{#0}"
          },
          {
            class: "action",
            command: ["performWithFeedback", "deletePreviousChar"],
            label: "&#x232b;"
          }
        ],
        [
          {
            class: "keycap",
            latex: "0"
          },
          {
            class: "keycap",
            latex: ","
          },
          {
            class: "keycap",
            latex: "="
          },
          {
            class: "keycap",
            latex: "+"
          },
          {
            class: "separator w5"
          },
          {
            class: "action",
            command: ["performWithFeedback", "moveToPreviousChar"],
            label: "<svg><use xlink:href='#svg-arrow-left' /></svg>"
          },
          {
            class: "action",
            command: ["performWithFeedback", "moveToNextChar"],
            label: "<svg><use xlink:href='#svg-arrow-right' /></svg>"
          },
          {
            class: "action",
            command: ["performWithFeedback", "complete "],
            label: "<svg><use xlink:href='#svg-tab' /></svg>"
          }
        ]
      ]
    },
    diploma: {
      rows: [
        [
          {
            class: "keycap",
            latex: "\\leq"
          },
          {
            class: "keycap",
            latex: "\\geq"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap",
            latex: "7"
          },
          {
            class: "keycap",
            latex: "8"
          },
          {
            class: "keycap",
            latex: "9"
          },
          {
            class: "keycap",
            latex: "\\div"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap",
            latex: "\\frac{#0}{#0}"
          },
          {
            class: "keycap",
            latex: "\\sqrt{#0}"
          },
          {
            class: "keycap",
            latex: "\\sqrt[#0]{#0}"
          },
        ],
        [
          {
            class: "keycap",
            latex: "\\lt"
          },
          {
            class: "keycap",
            latex: "\\gt"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap",
            latex: "4"
          },
          {
            class: "keycap",
            latex: "5"
          },
          {
            class: "keycap",
            latex: "6"
          },
          {
            class: "keycap",
            insert: "\\cdot",
            latex: "\\times"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap tex",
            insert: "#@^2",
            label: "<span><i>x</i>&thinsp;²</span>"
          },
          {
            class: "keycap tex",
            insert: "$$#@^{#?}$$",
            latex: "x^{#0}"
          },
          {
            class: "action",
            command: ["insert", "\\quad"],
            label: "&#x2fd;"
          },
        ],
        [
          {
            class: "keycap",
            latex: "["
          },
          {
            class: "keycap",
            latex: "]"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap",
            latex: "1"
          },
          {
            class: "keycap",
            latex: "2"
          },
          {
            class: "keycap",
            latex: "3"
          },
          {
            class: "keycap",
            latex: "-"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap tex",
            latex: "\\pi"
          },
          {
            class: "keycap tex",
            latex: "\\theta"
          },
          {
            class: "action",
            command: ["performWithFeedback", "deletePreviousChar"],
            label: "&#x232b;"
          }
        ],
        [
          {
            class: "keycap",
            insert: "\\footnotesize+\\normalsize{#0}",
            latex: "(+)"
          },
          {
            class: "keycap",
            insert: "\\footnotesize-\\normalsize{#0}",
            latex: "(-)"
          },
          {
            class: "separator w5"
          },
          {
            class: "keycap",
            latex: "0"
          },
          {
            class: "keycap",
            latex: ","
          },
          {
            class: "keycap",
            latex: "="
          },
          {
            class: "keycap",
            latex: "+"
          },
          {
            class: "separator w5"
          },
          {
            class: "action",
            command: ["performWithFeedback", "moveToPreviousChar"],
            label: "<svg><use xlink:href='#svg-arrow-left' /></svg>"
          },
          {
            class: "action",
            command: ["performWithFeedback", "moveToNextChar"],
            label: "<svg><use xlink:href='#svg-arrow-right' /></svg>"
          },
          {
            class: "action",
            command: ["performWithFeedback", "complete "],
            label: "<svg><use xlink:href='#svg-tab' /></svg>"
          }
        ]
      ]
    }
  }
};


export class mathLiveKeyboard { }




