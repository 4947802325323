import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {  GraphComponent } from './graph.component';
import { StudentsInClassesComponent } from './students-in-classes/students-in-classes.component';
import { UiPartialModule } from '../ui-partial/ui-partial.module';
import { UiChartsModule } from '../ui-charts/ui-charts.module';

@NgModule({
  declarations: [
    GraphComponent,
    StudentsInClassesComponent
  ],
  imports: [
    CommonModule,
    UiPartialModule,
    UiChartsModule
  ],
  exports: [
    GraphComponent,
    StudentsInClassesComponent
  ]
})
export class GraphModule { }
