import { IContentElement } from "../models";

export interface IContentElementTable extends IContentElement {
    grid: Array<Array<IContentElementTableCell>>;
    isHeaderRow: boolean;
    isHeaderCol: boolean;
    isColWidthConst: boolean;
    isTableOfValues?: boolean;
    colWidthConst: number;
    someRowsAreHeader?: boolean;
    headerRows?: string;
    someColsAreHeader?: boolean;
    headerCols?: string;
    isCenter?: boolean;
    isHideBorder?: boolean;
  }
  export interface IContentElementTableCell extends IContentElement{
    val: string;
    align?: string;
    mergedCellId?: number;
    mergedCellTopLeft?: Array<number>,
    mergedCellBottomRight?: Array<number>,
    mergedCellRowSpan?: number;
    mergedCellColSpan?: number;
  }

  export const tableTextEditInfo = {
    editExcludeFields: [],
    editTextFields: ['val'],
    editKeyFieldsToShow: ['val'],
    isNoKeyFieldsOverride: true
  }


  export const tableEditInfo = {
    editExcludeFields: ['grid'],
    editKeyFieldsToShow: [null]
  }
