import * as PIXI from "pixi.js-legacy";
import { ICircle, IContentElementVirtualTools, IEntryStateVirtualTools } from "../model";
import { VirtualTools, EToggleMode, EToolType } from "./virtual-tools";
import * as _ from "lodash";
import { SpriteLoader } from "../../element-render-custom-interaction/controllers/sprite-loader";
import { IPoint } from "./virtual-tools";
import { IExtendedTools } from "../element-render-virtual-tools.component";
import { EPixiTools, ENamingTypes, IDrawnMeta } from "../types/types";
import { LAYER_LEVEL } from "../types/constants";
import { QuestionState } from "../../models";

enum ACTIONS {
  DUPLICATE = "DUPLICATE",
  DELETE = "DELETE"
}
export class FreehandCircleOutline extends VirtualTools {
  element: IContentElementVirtualTools;
  spriteLoader: SpriteLoader
  stage: PIXI.Container;
  vertices: PIXI.Graphics;
  backgroundSprite: PIXI.Sprite;
  dupeSprite: PIXI.Sprite;
  deleteSprite: PIXI.Sprite;
  drawnCircle: PIXI.Graphics;
  drawnCircles: PIXI.Graphics[] = [];
  circleTools: IExtendedTools;
  circleRadius: number;
  isGlobalResizing: boolean;
  isGlobalDragging: boolean;
  circleContainersOnResizing: PIXI.Graphics
  DEFAULT_CIRCLE_RADIUS = 10;

  constructor(
    questionState: QuestionState,
    element: IContentElementVirtualTools,  
    addGraphic, 
    render,
    getToolStateSub,
    stage,
    isLocked, 
    textToSpeech,
    isGlobalRotating: boolean, 
    isGlobalDragging: boolean,
    backgroundSprite: PIXI.Sprite,
    colorController: {color: number},
    layerLevel: LAYER_LEVEL,
    spriteLoader: SpriteLoader
  ) {
    super(questionState, addGraphic, render, getToolStateSub, stage, isLocked, textToSpeech, isGlobalRotating, isGlobalDragging);
    this.initTool({name: EPixiTools.CIRCLE, type:EToolType.TOGGLE, layerLevel: layerLevel});
    this.questionState = questionState;    this.initTool({name: EPixiTools.CIRCLE, type:EToolType.TOGGLE });
    this.element = element;
    // this.initToolStateSub()
    this.spriteLoader = spriteLoader;
    this.backgroundSprite = backgroundSprite;
    this.dupeSprite = new PIXI.Sprite();
    this.deleteSprite = new PIXI.Sprite();

    this.loadAssets().then(this.initSprites);

    //#stopping Ticker
    PIXI.Ticker.system.autoStart = false
    PIXI.Ticker.system.stop()

    this.addDrawVertexListener();
    // this.deselectAllCirclesListener();

    this.vertices = new PIXI.Graphics();
    this.vertices.zIndex = 8;
    this.circleRadius = 12;
    this.addGraphic(this.vertices);
    this.circleContainersOnResizing = new PIXI.Graphics;
    this.addGraphic(this.circleContainersOnResizing);

    // this.circleTools = {
    //   parent: 'circle_outline',
    //   colors: [
    //     this.element.fhCircleOutlineColor,
    //     '#c43f2e',
    //     '#2a91d6'
    //   ],        
    //   colorHandler: (color: string) => {
    //     this.circleColor = color;
    //   },
    //   tools: [
    //   {
    //     id: 'delete',
    //     action: () => this.clearDrawnCircles(),
    //     iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/delete/1684351529074/delete.png',
    //     isSelected: () => false,
    //   },
    //   {
    //     id: 'colorPicker',
    //     isColorPick: true,
    //     colorController: this.circleColor,
    //     action: () => {},
    //     isSelected: () => false,
    //   },
    // ]}

    // Render scene
    this.render();
  }

  initSprites = ({resources}) => {
    if (!resources || Object.keys(resources).length == 0) {
      this.dupeSprite.texture = PIXI.utils.TextureCache.duplicate;
      this.deleteSprite.texture = PIXI.utils.TextureCache.delete;
    } else {
      this.dupeSprite.texture = resources.duplicate.texture;
      this.deleteSprite.texture = resources.delete.texture;
    }
  }

  drawCircleOutline(x: number, y: number, fill: {color: number, opacity: number}, points: IPoint[], isInteractive: boolean, circleRadius: number) {
    const transformedPoints = []
    points.map(point => {
      transformedPoints.push({x: point.x + x, y: point.y + y});
    })
    const point = transformedPoints[0];

    const circle = new PIXI.Graphics();
    circle.beginFill(fill.color, fill.opacity);
    circle.drawCircle(point.x, point.y, circleRadius);
    circle.beginHole();
    circle.drawCircle(point.x, point.y, circleRadius - 2);
    circle.endHole();
    circle.endFill();
    circle.zIndex = 6;
    circle.interactive = true;

    circle.lineStyle(0.5, this.getParsedColor('#ffffff'), 1);
    circle.drawCircle(point.x, point.y, circleRadius);

    let hasRotationPoint = false;
    points.map(point => {
      if(point.isRotationPoint && isInteractive) {
        hasRotationPoint = true;
        this.drawRotationPoint({x: point.x + x, y: point.y + y}, circle);
      }
    })

    if(isInteractive && !hasRotationPoint) {
      this.addDragAndRotateListener(circle);
    }

    return circle;
  }
  
  // circleContainer: PIXI.Graphics;
  // containerID = 0;
  // circleOutlines = new Map<string, {outline: PIXI.Graphics, menu: PIXI.Graphics, resizePoint: PIXI.Graphics, visible: boolean}>();
  completeCircle(points: IPoint[], circleRadius: number, isFromResize?: boolean, isRestore?: boolean, origin?: IPoint, drawOutlineBorder?: boolean, color?: number) {
    // Translate points to 0,0
    let transX = 0;
    let transY = 0;
    for(let i = 0; i< points.length; i++) {
      if(i == 0) {
        transX = points[0].x;
        transY = points[0].y;
        points[0].x = 0;
        points[0].y = 0;
      } else {
        points[i].x -= transX;
        points[i].y -= transY;
      }
    }

    this.vertices.removeChildren();

    const circleContainer = new PIXI.Graphics();
    circleContainer.name = this.getName(ENamingTypes.CONTAINER)
    
    const fillColor = color? color : this.selectedColor;
    const fillOpacity = this.element.fhCircleShapeOpacity || 0.8;
    const fill = {color: fillColor, opacity: fillOpacity};
    const circle = this.drawCircleOutline(0, 0, fill, points, false, circleRadius);
    circle.name =  this.getName(ENamingTypes.TOOL)
    circle.pivot.set(0.5);
    if(!this.isDrawMode) circle.interactive = true;
    circleContainer.addChild(circle);
    circleContainer.zIndex = this.getContainerZindex(7);
    const center = this.getShapeCenter(points);
    
    circleContainer.pivot.set(center.x,center.y);
    
    if(isRestore) {
      circleContainer.position.set(origin.x, origin.y);
    } else {
      circleContainer.position.set(transX + center.x, transY + center.y);
    }
    // if (!drawOutlineBorder) circleContainer.interactiveChildren = false
    // this.addGraphic(circleContainer);
    // this.drawnCircle = circleContainer;

    const resizePoint = this.drawResizePoint({x: center.maxX + circleRadius, y: 0}, circleContainer, circle, isFromResize, isFromResize, color);
    circleContainer.addChild(resizePoint)
    // resizePoint.alpha = 0;

    let border;
    let menu;
    if (drawOutlineBorder && isFromResize){
      border = this.drawSelectedCircleBorder(circleContainer, circle, points, circle.width/2, fillColor);
      border.alpha = 1;
      resizePoint.alpha = 1;

      const menuContainer =  new PIXI.Container();
      menuContainer.name = this.getName(ENamingTypes.MENU);
      menuContainer.addChild(border)
      // this.isLineSelected = true;
      // this.lineMenu = {border: border, menu: menu, rotationPoint: rotationPoints};
      // if (!isFromResize) this.circleOutlines.set(circleContainer.name, {outline: border, menu: menu, resizePoint: resizePoint, visible: true})
    }

    if (!isFromResize){
      this.addContainer(circleContainer, <ICircle>{
        points,      
        _isSelected: isRestore ? false : drawOutlineBorder,
        color: fillColor,
        fillOpacity,
        zIndex: circleContainer.zIndex,
        x: circleContainer.x,
        y: circleContainer.y,
        radius: circleRadius,
      })
      
      this.updateState();
      this.addSelectCircleListener(circle, circleContainer, resizePoint, [...points], border, menu);
      // this.drawnCircles.push(circleContainer)
    } else {
      this.circleContainersOnResizing.addChild(circleContainer);
    }
    return circleContainer;
  }

  drawMenu(obj: PIXI.Graphics, container: PIXI.Graphics, points?: IPoint[]) {
    if(!points?.length) {
      points = this.getObjectMetaProps()[container.name].points;
    }
    const center = this.getShapeCenter(points);
    const menu = new PIXI.Graphics();
    const menuYOffset = 5;
    const menuY = center.y + ((center.maxY - center.minY) / 2) + menuYOffset;
    const menuHeight = 30;
    const menuWidth = 55;
    menu.beginFill(0x645f73);
    menu.lineStyle(0);
    menu.drawRoundedRect(0, 0, menuWidth, menuHeight, 5);
    menu.position.set(center.minX - menuWidth/2, menuY + obj.width/2);
    menu.endFill();

    menu.alpha = 0;

    const containerId = this.getContainerIdFromName(container.name);

    // Menu icons
    const dupe = new PIXI.Sprite();
    dupe.texture = this.dupeSprite.texture;
    dupe.scale.set(0.03);
    dupe.anchor.set(0,0.5);
    dupe.y = menuHeight / 2;
    dupe.x = 10;
    dupe.interactive = true;
    dupe.name = this.getName(ENamingTypes.MENU_DUPE, containerId)

    const del = new PIXI.Sprite();
    del.texture = this.deleteSprite.texture;
    del.scale.set(0.03);
    del.anchor.set(0,0.5);
    del.y = menuHeight / 2;
    del.x = dupe.x + dupe.width + 5;
    del.interactive = true;
    del.name = this.getName(ENamingTypes.MENU_DEL, containerId)

    menu.addChild(dupe);
    menu.addChild(del);
    this.addMenuListeners(obj, ACTIONS.DUPLICATE, dupe, points, container);
    this.addMenuListeners(container, ACTIONS.DELETE, del, points);
    
    // container.addChild(menu);

    return menu;
  }

  addMenuListeners(obj: PIXI.Graphics, action: ACTIONS, option: PIXI.Sprite, points: IPoint[], container?: PIXI.Graphics) {
    let func;
    option.cursor = 'pointer';
    if(action == ACTIONS.DUPLICATE) {
      func = (e) => {
        const currentTarget = (e.currentTarget as PIXI.Sprite)
        const toolObj = this.getToolObjectFromName(currentTarget.name)
        const circleColor = this.getFillColor(container);
        const dupPosition = this.getDuplicateObjectPosition(currentTarget)
        const circleContainer = this.completeCircle([...points], toolObj.width/2, false, true, {x: dupPosition.x, y: dupPosition.y}, false, circleColor);
        this.activateListner(circleContainer)
        this.render();
      }
    } else if(action == ACTIONS.DELETE) {
      func = (e) => {
        this.deleteContainerFromMenu(e.currentTarget as PIXI.Sprite)
        this.updateState();
      }
    }
    option.on('pointerup', func);
  }

  deleteObject(obj: PIXI.Graphics) {
    obj.removeChildren();
    obj.clear();
  }

  // circleBorder: PIXI.Graphics;
  // circlesSelectStatus = new Map();
  addSelectCircleListener(circle: PIXI.Graphics, container: PIXI.Graphics, resizePoint: PIXI.Graphics, points: IPoint[], existingBorder: PIXI.Graphics,existingMenu: PIXI.Graphics) {
    circle.on('pointerdown', ($event) => super.onPointerDown($event))
  }

  clearActiveSelection = (container: PIXI.Graphics) => {
    this.removeCircleMenu(container);
  }

  addActiveSelection = (container: PIXI.Graphics) => {
    this.addCircleMenu(container)
  }

  addCircleMenu(container: PIXI.Graphics){

    // get lineMenuContainer Object
    const containerId = this.getContainerIdFromName(container.name);
    const menuContainerName = this.getName(ENamingTypes.MENU, containerId)
    let menuContainer = <PIXI.Container>container.getChildByName(menuContainerName);
    const circleName = this.getName(ENamingTypes.TOOL, containerId)
    const circle = <PIXI.Graphics>container.getChildByName(circleName);

    // resize point
    const resizePointName = this.getName(ENamingTypes.RESIZE_ANCHOR, containerId);
    const resizePoint = <PIXI.Graphics>container.getChildByName(resizePointName);
    if(resizePoint){
      resizePoint.alpha = 1
      resizePoint.interactive = true
    }
    
    
    // new menu container childs
    const border = this.drawSelectedCircleBorder(container, circle, undefined, circle.width/2, this.getFillColor(container));
    const menu = this.drawMenu(circle, container);
    border.name = this.getName(ENamingTypes.BORDER, containerId);
    border.alpha = 1;
    menu.alpha = 1;

    if(!menuContainer){
      // add new menu container inside lineContainer
      menuContainer =  new PIXI.Container();
      menuContainer.name = this.getName(ENamingTypes.MENU, containerId);
      container.addChild(menuContainer);
    }
    menuContainer.addChild(...[border, menu])
  }

  removeCircleMenu(container: PIXI.Graphics){
    const containerId = this.getContainerIdFromName(container.name);
    const menuContainerName = this.getName(ENamingTypes.MENU, containerId)
    let menuContainer = <PIXI.Container>container.getChildByName(menuContainerName);
    if(menuContainer) menuContainer.removeChildren()

    // resize point
    const resizePointName = this.getName(ENamingTypes.RESIZE_ANCHOR, containerId);
    const resizePoint = <PIXI.Graphics>container.getChildByName(resizePointName);
    if(resizePoint) {
      resizePoint.alpha = 0
      resizePoint.interactive = false
    }
  }

   // Draws (adds) a single vertice on the stage.
   drawCircleVertices(vertices: PIXI.Graphics, point: IPoint, isHover?: boolean) {

    const vertice = new PIXI.Graphics();
    const fillAlpha = isHover ? 0.5 : 1;
    vertice.beginFill(0x0000, fillAlpha);
    vertice.drawCircle(point.x, point.y, 4);
    vertice.endFill();
    vertice.interactive = true;
    vertice.zIndex = 6;
    vertices.addChild(vertice);

    // If both diagonal corner points are set, draw a rectangle
    if (this.currentCirclePoints.length == 2){
      if (this.circleVisulizer) this.circleVisulizer.destroy();
      const center = this.currentCirclePoints[0];
      const outterPoint = this.currentCirclePoints[1];
      const radius = Math.sqrt( (outterPoint.x - center.x)*(outterPoint.x - center.x) + (outterPoint.y - center.y)*(outterPoint.y - center.y) )
      const rectangle = new PIXI.Graphics();
      rectangle.lineStyle(2, 0x0000, fillAlpha);
      rectangle.drawCircle(center.x, center.y, radius);
      this.circleVisulizer = rectangle
      vertices.addChild(rectangle);
    }

    this.render();

    return vertice;
  }

  isDrawingCircle: boolean;
  points: IPoint[] = [];
  currentCirclePoints;
  hoverVertice: PIXI.Graphics;
  circleVisulizer: PIXI.Graphics;
  addDrawVertexListener() {
    const addVerticeMouseDown = (e) => {
      if(this.isDrawMode && !this.isGlobalDragging && !this.isGlobalRotating && !this.isGlobalResizing) {
        this.isDrawingCircle = true;
        this.currentCirclePoints = [];
        const {x,y} = e.data.getLocalPosition(this.stage);
        const point = {x, y};
        this.points.push(point);
        this.currentCirclePoints.push(point);
        this.drawCircleVertices(this.vertices, point);

        this.stage.on('pointerup', addVerticeMouseUP);
        this.stage.on('pointermove', addHoverVertice);
      }
    }

    const addVerticeMouseUP = (e) => {
      if(this.isDrawMode && !this.isGlobalDragging && !this.isGlobalRotating && !this.isGlobalResizing) {
        const {x,y} = e.data.getLocalPosition(this.stage);
        const point = {x, y};
        this.points.push(point);
        if (this.currentCirclePoints.length > 1) this.currentCirclePoints.pop();
        this.currentCirclePoints.push(point);
        this.drawCircleVertices(this.vertices, point);
        this.isDrawingCircle = false;


        const center = this.currentCirclePoints[0];
        const outterPoint = this.currentCirclePoints[1];
        const radius = Math.sqrt( (outterPoint.x - center.x)*(outterPoint.x - center.x) + (outterPoint.y - center.y)*(outterPoint.y - center.y) )

        this.completeCircle([center], radius);
        this.render();
        this.currentCirclePoints = [];

        this.stage.removeListener('pointerup', addVerticeMouseUP);
        this.stage.removeListener('pointermove', addHoverVertice);
      }
    }

    const addHoverVertice = (e) => {
      if(this.isDrawMode && !this.isGlobalDragging && !this.isGlobalRotating && !this.isGlobalResizing) {
        const {x,y} = e.data.getLocalPosition(this.stage);
        const point = {x, y};
        this.points.push(point);
        if (this.currentCirclePoints.length > 1) this.currentCirclePoints.pop();
        this.currentCirclePoints.push(point);

        if (this.hoverVertice) this.hoverVertice.destroy();
        this.hoverVertice = this.drawCircleVertices(this.vertices, point, true);
        ;
      }
    }

    this.stage.on('pointerdown', addVerticeMouseDown);
  }

  drawResizePoint(point: {x: number, y: number}, container: PIXI.Graphics, circle: PIXI.Graphics, noListeners: boolean, showByDefault?: boolean, color?: number) {
    const resizePoint = new PIXI.Graphics();
    const containerId = this.getContainerIdFromName(container.name);
    resizePoint.name = this.getName(ENamingTypes.RESIZE_ANCHOR, containerId);
    resizePoint.beginFill(color, 1);
    resizePoint.drawCircle(point.x, point.y, 4);
    resizePoint.endFill();
    resizePoint.pivot.set(0, 0);
    if(!showByDefault) resizePoint.alpha = 0

    // container.addChild(resizePoint);
    if(!noListeners) this.addDragAndResizeListener(container, circle, true, resizePoint);

    return resizePoint;
  }

  previousCircleContainerAnchor: PIXI.DisplayObject;
  // circleContainersDragStatus = new Map();

  addDragAndResizeListener(circleContainer: PIXI.Graphics, circle: PIXI.Graphics, isResize?: boolean, resizePoint?: PIXI.Graphics) {
    let initialDiffX = 0;
    let initialDiffY = 0;
    let isDragging = false;
    this.isGlobalDragging = false;
    let isResizeDragging = false
    circleContainer.cursor = 'grab';
    if(!this.isDrawMode) circleContainer.interactive = true;
    if (isResize){
      resizePoint.cursor = 'ew-resize'
      resizePoint.interactive = false;
    }

    const onResizeStart = (e) => {
      this.isResizingPointerDown = true
      isResizeDragging = true;
      this.isGlobalResizing = true;
      circleContainer.cursor = 'grabbing';
      circleContainer.removeAllListeners();

      this.stage.on('pointerup', onResizeEnd)
      .on('pointermove', onResizeMove);
    }
    const onResizeEnd = (e) => {
      this.isResizingPointerDown = false
      isResizeDragging = false;
      this.isGlobalResizing = false;
      circleContainer.cursor = 'grab';
      circleContainer.removeAllListeners();
      circle.removeAllListeners();
      this.circleContainersOnResizing.removeChildren();
      const mousePosition = e.data.getLocalPosition(this.stage);
      
      if (this.previousCircleContainerAnchor) {
        this.previousCircleContainerAnchor.alpha = 0;
        this.previousCircleContainerAnchor.destroy()
      };
      
      // circleContainer.children.forEach((child, i) => {
      //   circleContainer.getChildAt(i).removeAllListeners();
      //   circleContainer.getChildAt(i).destroy();
      // })
      // this.drawnCircle.destroy();
      const drawPoints = [{x: circleContainer.x, y:circleContainer.y}];
      const circleRadius = this.getNewCircleRadiusOnResize(mousePosition, circleContainer)
      const circleColor = this.getFillColor(circleContainer);
      const newContainer = this.completeCircle(drawPoints, circleRadius, false, false, null, true, circleColor);
      this.removeContainer(circleContainer)
      this.updateState();
      // this.circleOutlines.delete(circleContainer.name);
      // this.circlesClicked.delete(circleContainer.name);
      // this.circlesClicked.set(newContainer.name, true);
      this.render();

      this.stage.removeListener('pointerup', onResizeEnd);
      this.stage.removeListener('pointermove', onResizeMove);
    }

    let previousRadius = circle.width / 2;
    const onResizeMove = (e: PIXI.InteractionEvent) => {
      if(isResizeDragging) {
        const mousePosition = e.data.getLocalPosition(this.stage);
        const newRadius = this.getNewCircleRadiusOnResize(mousePosition, circleContainer);
        // if change in the radius is less than 1 px, don't update, helps reduce the redraw frequency.
        if (Math.abs(newRadius - previousRadius) < 1) return;

        previousRadius = newRadius;

        this.isGlobalResizing = true;
        this.circleContainersOnResizing.removeChildren();
        resizePoint.alpha = 0;

        circleContainer.children.forEach((child, i) => {
          if (child.name != ENamingTypes.RESIZE_ANCHOR){
            circleContainer.getChildAt(i).destroy()
          } else {
              this.previousCircleContainerAnchor = child;
          }
        })
        const drawPoints = [{x: circleContainer.x, y:circleContainer.y}]
        const circleColor = this.getFillColor(circleContainer);

        this.completeCircle( drawPoints, newRadius, true, false, null, true, circleColor);
        this.render();
      } else if(!isResizeDragging) {
        this.isGlobalResizing = false;
      }
    }

    // circleContainer.on('pointerdown', onDragStart);
    if (isResize) resizePoint.on('pointerdown', onResizeStart);
  }

  drawSelectedCircleBorder(container: PIXI.Graphics, circle: PIXI.Graphics, points: IPoint[], circleRadius: number, color: number) {

    if(!points?.length){
      points = this.getObjectMetaProps()[container.name].points
    }

    const border = new PIXI.Graphics();
    border.lineStyle(3, color, 0.3);
    border.drawCircle(circle.x, circle.y, circleRadius);
    border.endFill();
    border.alpha = 0;

    container.addChild(border);

    return border;
  }

  getNewCircleRadiusOnResize(mousePosition, circleContainer){
    const deltaX = mousePosition.x - circleContainer.x; 
    const deltaY = mousePosition.y - circleContainer.y;
    const newRadius = Math.sqrt(deltaX * deltaX + deltaY * deltaY)
    return newRadius;
  }

  isDrawMode = false;
  toggleDrawMode(mode?: boolean) {
    this.isDrawMode = mode == null ? !this.isDrawMode : mode;
    if(!this.isDrawMode) {
        this.stage.cursor = 'default';
        this.backgroundSprite.cursor = 'default';
    } else {
        this.stage.cursor = 'pointer';
        this.backgroundSprite.cursor = 'pointer';
    }
    this.vertices.removeChildren();
    this.points = [];

    this.render(); 
    console.log('Circle mode', this.isDrawMode)
    return this.isDrawMode;
  }

  clearDrawnCircles() {
    this.deleteObject(this.drawnCircle);
    this.drawnCircle.destroy();
    this.drawnCircle = undefined;
    this.render();
  }

  getCircleTools() {
    return this.circleTools;
  }

  circlesClicked = new Map();
  circleBordersToggledOffByClickElsewhere = new Map();

  loadAssets() {
    return this.spriteLoader.loadSprites()
  }

  activateListner(container: PIXI.Graphics){
    container.interactive = true;
    container.children.forEach(child => {
      if(child.name?.includes(ENamingTypes.RESIZE_ANCHOR)){
        child.interactive = false // should olny be true when selected  
      }
      child.interactive = true
    })
  }

  activateSelectionListners(){
    this.getObjectContainer().forEach((container: PIXI.Graphics) => this.activateListner(container))
  }


  changeDrawnGraphicColor(currentSelectedObject: PIXI.Graphics){
    if (!currentSelectedObject.name) return;

    const circleGraphic = <PIXI.Graphics>currentSelectedObject.children.filter(child => child.name.includes(ENamingTypes.TOOL))[0]
    const pos_x = circleGraphic.x;
    const pos_y = circleGraphic.y;
    const radius = circleGraphic.width / 2;
    const pivot = circleGraphic.pivot;
    const alpha = circleGraphic.alpha;

    circleGraphic.clear()
    circleGraphic.beginFill(this.selectedColor, this.ObjectMetaPropsContainer[currentSelectedObject.name].fillOpacity);
    circleGraphic.drawCircle(pos_x, pos_y, radius);
    circleGraphic.beginHole();
    circleGraphic.drawCircle(pos_x, pos_y, radius - 2);
    circleGraphic.endHole();
    circleGraphic.endFill();
    circleGraphic.zIndex = 6;
    circleGraphic.interactive = true;

    circleGraphic.lineStyle(0.5, this.getParsedColor('#ffffff'), 1);
    circleGraphic.drawCircle(pos_x, pos_y, radius);
    circleGraphic.alpha = alpha,
    circleGraphic.pivot = pivot;

    const fillColor = this.selectedColor;
    this.ObjectMetaPropsContainer[currentSelectedObject.name].color = fillColor;

    const resizePointGraphic = <PIXI.Graphics>currentSelectedObject.children.filter(child => child.name.includes(ENamingTypes.RESIZE_ANCHOR))[0];
    resizePointGraphic.clear();
    resizePointGraphic.beginFill(this.selectedColor, 1);
    resizePointGraphic.drawCircle(pos_x + radius, 0, 4);
    resizePointGraphic.endFill();
    resizePointGraphic.pivot.set(0, 0);

    const menuContainer = <PIXI.Graphics>currentSelectedObject.children.filter(child => child.name.includes(ENamingTypes.MENU))[0];
    const containerId = this.getContainerIdFromName(currentSelectedObject.name);

    const borderName = this.getName(ENamingTypes.BORDER, containerId);
    const border = <PIXI.Graphics>menuContainer.getChildByName(borderName);

    const border_pos_x = border.x;
    const border_pos_y = border.y;

    border.clear();
    border.lineStyle(3, this.selectedColor, 0.3);
    border.drawCircle(circleGraphic.x, circleGraphic.y, radius);
    border.endFill();
    border.position.set(border_pos_x, border_pos_y)

    this.render();
  }

  getUpdatedState(entry: Partial<IEntryStateVirtualTools>): Partial<IEntryStateVirtualTools> {
    entry.data[EPixiTools.CIRCLE] = Object.values(this.ObjectMetaPropsContainer);
    return entry;
  }

  handleNewState() {
    const data = this.getQuestionState(EPixiTools.CIRCLE);
    if(!data || data.length == 0) {
      return;
    }

    data.forEach((circle) => {
      const temp: ICircle = JSON.parse(JSON.stringify(circle))
      this.completeCircle(temp.points, temp.radius, false, true, {x: temp.x, y: temp.y}, true, temp.color);
    });

    this.render();
  }
}
