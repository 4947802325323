import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { TextParagraphStyle } from '../element-render-text/model';
import { QuestionState } from '../models';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { IContentElementMath } from './model';
import { WhitelabelService } from 'src/app/domain/whitelabel.service';
import { StyleprofileService } from 'src/app/core/styleprofile.service';


@Component({
  selector: 'element-render-math',
  templateUrl: './element-render-math.component.html',
  styleUrls: ['./element-render-math.component.scss']
})
export class ElementRenderMathComponent implements OnInit {

  @Input() element:IContentElementMath;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() isInline:boolean;
  @Input() questionPubSub?: QuestionPubSub;
  @Input() changeCounter?: number

  constructor(
    public whitelabel: WhitelabelService,
    public styleProfile: StyleprofileService
  ) { }

  ngOnInit() {
    // console.log('mathquill in angular', (window as any).MathQuill);
    // console.log('isInline', this.isInline)
  }

  isHeading(){
    return this.element.paragraphStyle === TextParagraphStyle.HEADLINE
  }
  isHeadingSmall(){
    return this.element.paragraphStyle === TextParagraphStyle.HEADLINE_SMALL
  }
  isBody(){
    return this.element.paragraphStyle === TextParagraphStyle.REGULAR
  }
  isSmall(){
    return this.element.paragraphStyle === TextParagraphStyle.SMALL
  }

  getProp() {
    if (!this.element["content"]) {
      return 'latex'
    }
    return 'content';
  }
}
