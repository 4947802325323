import { Component, OnInit, Input } from '@angular/core';
import { LangService } from '../../core/lang.service';
import { LoginGuardService } from '../../api/login-guard.service';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { IFooterDef } from '../../domain/contexts/common';
import { AccountType } from '../../constants/account-types';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() hasLinks:boolean = true;
  @Input() hasComments:boolean = true;
  @Input() isAppealShown:boolean = false;
  @Input() appealSessionId:number;
  @Input() appealAttemptId:number;
  @Input() techSupportDataKey:string;

  constructor(
    public lang: LangService,
    private whitelabel: WhitelabelService,
    private loginGuard: LoginGuardService,
  ) { }

  ngOnInit() {
    
  }

  supportReqActivate(){
    let techSupportInfo;
    if (this.techSupportDataKey){
      techSupportInfo = this.whitelabel.getSiteData(this.techSupportDataKey)
    }
    this.loginGuard.supportReqActivate(techSupportInfo)
  }

  clang(){
    return this.lang.getCurrentLanguage();
  }



  getFooter(){
    return this.whitelabel.getFooter();
  }
  isFooterInternal(footer:IFooterDef){
    return !!footer.route;
  }

  getCopyright(){
    return this.whitelabel.getSiteText('copyright');
  }

  renderAppealLink(){
    if (this.appealAttemptId){
      return `/${this.lang.c()}/${AccountType.TEST_TAKER}/file-appeal/attempt/${this.appealAttemptId}`
    }
    else if (this.appealSessionId){
      return `/${this.lang.c()}/${AccountType.TEST_TAKER}/file-appeal/session/${this.appealSessionId}`
    }
    else {
      return `/${this.lang.c()}/${AccountType.TEST_TAKER}/file-appeal`
    }
  }

}
