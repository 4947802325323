import * as PIXI from "pixi.js-legacy";
import { IContentElementVirtualTools, IEntryStateVirtualTools } from "../model";
import { VirtualTools, EToolType } from "./virtual-tools";
import * as _ from "lodash";
import { SpriteLoader } from "../../element-render-custom-interaction/controllers/sprite-loader";
import { IPoint } from "./virtual-tools";
import { IExtendedTools } from "../element-render-virtual-tools.component";
import { MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER } from "@angular/material/tooltip";
import { EPixiTools, ENamingTypes, IDrawnMeta } from "../types/types";
import { LAYER_LEVEL } from "../types/constants";
import { QuestionState } from "../../models";

enum ACTIONS {
  DUPLICATE = "DUPLICATE",
  DELETE = "DELETE"
}

enum RELATIVEPOSITIONS {
  BOTTOM_RIGHT = 'bottom right',
  BOTTOM_LEFT = 'bottom left',
  TOP_RIGHT = 'top right',
  TOP_LEFT = 'top left'
}

export interface IPointCenter {
    x: number;
    y: number;
    maxX: number;
    maxY: number;
    minX: number;
    minY: number;
    height: number;
    width: number;
}


export class FreehandRectangleOutline extends VirtualTools {
  element: IContentElementVirtualTools;
  spriteLoader: SpriteLoader
  stage: PIXI.Container;
  rectangle: PIXI.Graphics;
  vertices: PIXI.Graphics;
  lines: PIXI.Graphics;
  backgroundSprite: PIXI.Sprite;
  vFlipSprite: PIXI.Sprite;
  hFlipSprite: PIXI.Sprite;
  dupeSprite: PIXI.Sprite;
  deleteSprite: PIXI.Sprite;
  rectangles: PIXI.Graphics[] = [];
  // rectangleTools: IExtendedTools;
  points: IPoint[] = [];
  isSelecting: boolean;
  isGlobalResizing = false;

  constructor(
    questionState: QuestionState,
    element: IContentElementVirtualTools,  
    addGraphic, 
    render,
    getToolStateSub,
    stage,
    isLocked, 
    textToSpeech,
    isGlobalRotating: boolean, 
    isGlobalDragging: boolean,
    backgroundSprite: PIXI.Sprite,
    colorController: {color: number},
    layerLevel: LAYER_LEVEL,
    spriteLoader: SpriteLoader
  ) {
    super(questionState, addGraphic, render, getToolStateSub, stage, isLocked, textToSpeech, isGlobalRotating, isGlobalDragging);
    this.initTool({name: EPixiTools.RECTANGLE, type: EToolType.TOGGLE, layerLevel: layerLevel});
    this.questionState = questionState;    this.initTool({name: EPixiTools.RECTANGLE, type: EToolType.TOGGLE });
    this.element = element;
    this.spriteLoader = spriteLoader;
    this.backgroundSprite = backgroundSprite;
    this.vFlipSprite = new PIXI.Sprite();
    this.hFlipSprite = new PIXI.Sprite();
    this.dupeSprite = new PIXI.Sprite();
    this.deleteSprite = new PIXI.Sprite();
    this.loadAssets().then(this.initSprites);

    this.addDrawVertexListener();

    this.isSelecting = false;
    this.isGlobalResizing = false;

    this.vertices = new PIXI.Graphics();
    this.rectangleContainersOnResizing = new PIXI.Graphics;
    this.addGraphic(this.vertices);
    this.addGraphic(this.rectangleContainersOnResizing);


    //#stopping Ticker
    PIXI.Ticker.system.autoStart = false
    PIXI.Ticker.system.stop()

    // this.rectangleTools = {
    //   parent: 'rectangle_outline',
    //   colors: [
    //     this.element.fhRectangleOutlineColor,
    //     '#c43f2e',
    //     '#2a91d6'
    //   ],        
    //   colorHandler: (color: string) => {
    //     this.rectangleColor = color;
    //   },
    //   tools: [
    //   {
    //     id: 'delete',
    //     action: () => this.clearRectangles(),
    //     iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/delete/1684351529074/delete.png',
    //     isSelected: () => false,
    //   },
    //   {
    //     id: 'colorPicker',
    //     isColorPick: true,
    //     colorController: this.rectangleColor,
    //     action: () => {},
    //     isSelected: () => false,
    //   },
    // ]}

    // Render scene
    this.render();
  }

  initSprites = ({resources}) => {
    if (!resources || Object.keys(resources).length == 0) {
      this.dupeSprite.texture = PIXI.utils.TextureCache.duplicate;
      this.deleteSprite.texture = PIXI.utils.TextureCache.delete;
    } else {
      this.dupeSprite.texture = resources.duplicate.texture;
      this.deleteSprite.texture = resources.delete.texture;
    }
  }

  drawSelectedOutline(obj: PIXI.Graphics, container: PIXI.Graphics, points: IPoint[]) {
    let outline = new PIXI.Graphics();
    outline.beginFill(0x0000, 0);
    outline.lineStyle(2, 0x0000, 1);
    for(let i=0; i<points.length; i++) {
      if(i==0) {
        outline.moveTo(points[i].x, points[i].y);
      } else {
        outline.lineTo(points[i].x, points[i].y);
        if(!points[i+1]) { 
          outline.lineTo(points[0].x, points[0].y);
        }
      }
    }
    outline.closePath();
    outline.endFill();
    outline.alpha = 0;

    obj.addChild(outline);

    return outline;
  }

  // Draws (adds) a single vertice on the stage.
  drawRectangleVertices(vertices: PIXI.Graphics, point: IPoint, isHover?: boolean) {

    const vertice = new PIXI.Graphics();
    const fillAlpha = isHover ? 0.5 : 1;
    vertice.beginFill(0x0000, fillAlpha);
    vertice.drawCircle(point.x, point.y, 4);
    vertice.endFill();
    vertice.interactive = true;
    vertice.zIndex = 6;
    vertices.addChild(vertice);

    // If both diagonal corner points are set, draw a rectangle
    if (this.currentRectanglePoints.length == 2){
      if (this.rectangleVisulizer) this.rectangleVisulizer.destroy();
      const topLeftPoint = this.currentRectanglePoints[0];
      const bottomRightPoint = this.currentRectanglePoints[1];
      const width = bottomRightPoint.x - topLeftPoint.x;
      const height = bottomRightPoint.y - topLeftPoint.y;
      const rectangle = new PIXI.Graphics();
      rectangle.lineStyle(2, 0x0000, fillAlpha);
      rectangle.drawRect(topLeftPoint.x, topLeftPoint.y, width, height);
      this.rectangleVisulizer = rectangle
      vertices.addChild(rectangle);
    }

    this.render();

    return vertice;
  }

  isDrawingRectangle: boolean;
  rectangleAndPoints: [{ points: [{x: any, y:any}], rectangle: PIXI.Graphics}];
  currentRectanglePoints
  hoverVertice: PIXI.Graphics;
  rectangleVisulizer: PIXI.Graphics;
  addDrawVertexListener() {
    // Three listeners, mose down to start drawing, up for finishing, plus a hover one.
    const addVerticeMouseDown = (e) => {
      if(this.isDrawMode && !this.isGlobalDragging && !this.isGlobalRotating && !this.isDrawingRectangle && !this.isSelecting && !this.isGlobalResizing) {
        this.isDrawingRectangle = true;
        this.currentRectanglePoints = [];
        const {x,y} = e.data.getLocalPosition(this.stage);
        const point = {x, y};
        this.points.push(point);
        this.currentRectanglePoints.push(point);
        this.drawRectangleVertices(this.vertices, point);

        this.stage.on('pointerup', addVerticeMouseUP);
        this.stage.on('pointermove', addHoverVertice);
      }
    }

    const addVerticeMouseUP = (e) => {
      if(this.isDrawMode && !this.isGlobalDragging && !this.isGlobalRotating && this.isDrawingRectangle && !this.isSelecting && !this.isGlobalResizing) {
        const {x,y} = e.data.getLocalPosition(this.stage);
        const point = {x, y};
        this.points.push(point);
        if (this.currentRectanglePoints.length > 1) this.currentRectanglePoints.pop();
        this.currentRectanglePoints.push(point);
        this.drawRectangleVertices(this.vertices, point);
        this.isDrawingRectangle = false;

        this.completeRectangle(this.currentRectanglePoints);
        this.render();
        this.currentRectanglePoints = [];

        this.stage.removeListener('pointerup', addVerticeMouseUP);
        this.stage.removeListener('pointermove', addHoverVertice);
      }
    }

    const addHoverVertice = (e) => {
      if(this.isDrawMode && !this.isGlobalDragging && !this.isGlobalRotating && this.isDrawingRectangle && !this.isSelecting && !this.isGlobalResizing) {
        const {x,y} = e.data.getLocalPosition(this.stage);
        const point = {x, y};
        this.points.push(point);
        if (this.currentRectanglePoints.length > 1) this.currentRectanglePoints.pop();
        this.currentRectanglePoints.push(point);

        if (this.hoverVertice) this.hoverVertice.destroy();
        this.hoverVertice = this.drawRectangleVertices(this.vertices, point, true);
      }
    }

    this.stage.on('pointerdown', addVerticeMouseDown);
  }

  // this method makes sure the draw points of the rectangle are always the top left, and bottom right,
  // otherwise the drag listener will be bugged out if the rectangle "grows" into the negative x or y
  // direction.
  ensureDiagonalPoints(points: IPoint[]){
    const pointA = points[0];
    const pointB = points[1];
    const relativePosition = this.getPointRelativePosition(pointA, pointB);
    let newPoints: IPoint[] = [];
    let resizePointPosition = {x: pointB.x, y: pointB.y, position: ''};
    switch (relativePosition){
      case RELATIVEPOSITIONS.BOTTOM_RIGHT:
        newPoints = points;
        resizePointPosition.position = RELATIVEPOSITIONS.BOTTOM_RIGHT;
        break;
      case RELATIVEPOSITIONS.BOTTOM_LEFT:
        newPoints.push({x: pointB.x, y: pointA.y, isRotationPoint: false});
        newPoints.push({x: pointA.x, y: pointB.y, isRotationPoint: false});
        resizePointPosition.position = RELATIVEPOSITIONS.BOTTOM_LEFT;
        break;
      case RELATIVEPOSITIONS.TOP_RIGHT:
        newPoints.push({x: pointA.x, y: pointB.y, isRotationPoint: false});
        newPoints.push({x: pointB.x, y: pointA.y, isRotationPoint: false});
        resizePointPosition.position = RELATIVEPOSITIONS.TOP_RIGHT;
        break;
      case RELATIVEPOSITIONS.TOP_LEFT:
        newPoints.push({x: pointB.x, y: pointB.y, isRotationPoint: false});
        newPoints.push({x: pointA.x, y: pointA.y, isRotationPoint: false});
        resizePointPosition.position = RELATIVEPOSITIONS.TOP_LEFT;
        break;
      default:
        newPoints = points;
        resizePointPosition.position = RELATIVEPOSITIONS.BOTTOM_RIGHT;
        break;
    }
    return {
      newPoints: newPoints,
      resizePointPosition: resizePointPosition
    };
  }

  getPointRelativePosition(pointA: IPoint, pointB: IPoint){
    if (pointA.x < pointB.x){
      if (pointA.y < pointB.y){
        return RELATIVEPOSITIONS.BOTTOM_RIGHT;
      } else {
        return RELATIVEPOSITIONS.TOP_RIGHT
      }
    } else {
      if (pointA.y < pointB.y){
        return RELATIVEPOSITIONS.BOTTOM_LEFT;
      } else {
        return RELATIVEPOSITIONS.TOP_LEFT;
      }
    }
  }
  
  // isFromRefresh is a flag to indicate the rectangle to be drawn is duruing a dragging,
  // resizing etc, that should not have any event listeners attatched.

  completeRectangle(points: IPoint[], isFromResize?: boolean, isRestore?: boolean, origin?: IPoint, drawOutlineBorder?: boolean, color?: number) {
    this.vertices.removeChildren();

    const obj = this.ensureDiagonalPoints(points);
    points = obj.newPoints
    const resizePointPosition = obj.resizePointPosition;

    // Translate points to 0,0
    let transX = 0;
    let transY = 0;

    for(let i = 0; i< points.length; i++) {
      if(i == 0) {
          transX = points[0].x;
          transY = points[0].y;
          points[0].x = 0;
          points[0].y = 0;
      } else {
          points[i].x -= transX;
          points[i].y -= transY;
      }
    }

    resizePointPosition.x -= transX;
    resizePointPosition.y -= transY;
    const fillColor = color? color : this.selectedColor;
    const fillOpacity = this.element.fhCircleShapeOpacity || 0.8;
    const rectangleContainer = new PIXI.Graphics();
    rectangleContainer.name = this.getName(ENamingTypes.CONTAINER)
    const fill = {color: fillColor, opacity: fillOpacity};
    const rectangle = this.drawRectangle(0, 0, fill, points);
    rectangle.name = this.getName(ENamingTypes.TOOL)
    rectangle.pivot.set(0.5);
    if(!this.isDrawMode) rectangle.interactive = true;
    rectangleContainer.addChild(rectangle);
    rectangleContainer.zIndex = this.getContainerZindex(7);
    const center = this.getShapeCenter(points);

    rectangleContainer.pivot.set(center.x,center.y);
    
    if(isRestore) {
      rectangleContainer.position.set(origin.x, origin.y);
    } else {
      rectangleContainer.position.set(transX + center.x, transY + center.y);
    }

    const resizePoint = this.drawResizePoint(rectangleContainer, rectangle, resizePointPosition, fillColor, isFromResize, isFromResize);
    rectangleContainer.addChild(resizePoint)

    if (drawOutlineBorder && isFromResize){
      const {border} = this.drawSelectedRectangleBorder(rectangleContainer, fillColor, points, isFromResize);

      const menuContainer =  new PIXI.Container();
      menuContainer.name = this.getName(ENamingTypes.MENU);
      menuContainer.addChild(border)
    }

    if (!isFromResize){
      const newRect = {
        points, 
        resizePoint: resizePointPosition,     
        _isSelected: isRestore ? false : drawOutlineBorder,
        color: fillColor,
        fillOpacity,
        zIndex: rectangleContainer.zIndex,
        x: rectangleContainer.x,
        y: rectangleContainer.y
      };

      this.addContainer(rectangleContainer, newRect)

      this.updateState();
      this.addSelectRectangleListener(rectangle)
    } else {
      this.rectangleContainersOnResizing.addChild(rectangleContainer);
    }    
    return rectangleContainer;
  }

  drawRectangle(x: number, y: number, fill: {color: number, opacity: number}, points: IPoint[]) {
    const rectangleOutlineThickness = 3;
    const rectangle = new PIXI.Graphics;
    rectangle.beginFill(fill.color, fill.opacity);

    // rectangle.lineStyle(, this.getParsedColor(fill.color), fill.opacity);
    const transformedPoints = []
    points.map(point => {
      transformedPoints.push({x: point.x + x, y: point.y + y});
    })

    const topLeftPoint = transformedPoints[0];
    const bottomRightPoint = transformedPoints[1];
    const width = bottomRightPoint.x - topLeftPoint.x;
    const height = bottomRightPoint.y - topLeftPoint.y;

    // top line: 
    rectangle.drawRect(transformedPoints[0].x, transformedPoints[0].y, width, rectangleOutlineThickness);
    // bottom line: 
    rectangle.drawRect(transformedPoints[0].x, transformedPoints[0].y + height - rectangleOutlineThickness, width, rectangleOutlineThickness);
    // left line:
    rectangle.drawRect(transformedPoints[0].x, transformedPoints[0].y, rectangleOutlineThickness, height);
    // right line:
    rectangle.drawRect(transformedPoints[0].x + width - rectangleOutlineThickness, transformedPoints[0].y, rectangleOutlineThickness, height);

    rectangle.endFill();

    rectangle.x += x;
    rectangle.y += y;
    rectangle.pivot.set(x, y);
    rectangle.zIndex = 50;

    return rectangle;
  }

  drawResizePoint(parent: PIXI.Graphics, rectangle: PIXI.Graphics, resizePointPosition: any, fillColor: number, showByDefault?: boolean, noListener?: boolean) {
    const resizePoint = new PIXI.Graphics();
    const containerId = this.getContainerIdFromName(parent.name);
    resizePoint.name = this.getName(ENamingTypes.RESIZE_ANCHOR, containerId);
    resizePoint.beginFill(fillColor, 0.9);
    resizePoint.drawCircle(0, 0, 4);
    resizePoint.position.set(resizePointPosition.x, resizePointPosition.y);
    resizePoint.endFill();
    resizePoint.pivot.set(0, 0);
    // resizePoint.rotation = -0.785398;
    if (!showByDefault) resizePoint.alpha = 0;
    // parent.addChild(resizePoint);

    if(!noListener) this.addDragAndResizeListener(parent, true, {point: resizePoint, position: resizePointPosition.position});

    return resizePoint;
  }

  previousCircleContainerAnchor: PIXI.DisplayObject;
  rectangleContainersOnResizing: PIXI.Graphics;
  deltaX: number;
  deltaY: number;
  rectangleContainersDragStatus = new Map();
  addDragAndResizeListener(rectangleContainer: PIXI.Graphics, isResize?: boolean, resizePointObj?: {point: PIXI.Graphics, position: RELATIVEPOSITIONS}) {
    this.isGlobalDragging = false;
    let isResizeDragging = false;
    rectangleContainer.cursor = 'grab';
    if(!this.isDrawMode) rectangleContainer.interactive = true;
    const rectangle = <PIXI.Graphics>rectangleContainer.children.filter(child => child.name.includes(ENamingTypes.TOOL))[0];

    // this.rectangleContainersDragStatus.set(rectangleContainer.name, false); // Map to record if the rectangle is being dragged

    let resizePoint
    if (isResize){
      resizePoint = resizePointObj.point;
      resizePoint.cursor = 'ew-resize'
      resizePoint.interactive = false;
    }

    let isDragged = false;
    const onResizeStart = (e) => {
      this.isResizingPointerDown = true;
      isResizeDragging = true;
      this.isGlobalResizing = true;
      rectangleContainer.cursor = 'grabbing';
      rectangleContainer.removeAllListeners();

      this.stage.on('pointerup', onResizeEnd)
      .on('pointermove', onResizeMove);
    }
    const onResizeEnd = (e) => {
      this.isResizingPointerDown = false;
      isResizeDragging = false;
      this.isGlobalResizing = false;
      rectangleContainer.cursor = 'grab';
      if (this.previousCircleContainerAnchor) this.previousCircleContainerAnchor.destroy();
      this.rectangleContainersOnResizing.removeChildren();
      // this.previousCircleContainerAnchor = rectangleContainer;
      // this.previousCircleContainerAnchor.alpha = 0;

      if (!isDragged){
        this.stage.removeListener('pointerup', onResizeEnd);
        this.stage.removeListener('pointermove', onResizeMove);
        return;
      }

      const fillColor = this.getFillColor(rectangleContainer);
      const newContainer = this.completeRectangle(this.getNewPositionOnResize(rectangleContainer, resizePointObj.position), false, false, null, true, fillColor);
      this.activateListner(newContainer)
      this.removeContainer(rectangleContainer)
      this.updateState();
      this.render();

      this.stage.removeListener('pointerup', onResizeEnd);
      this.stage.removeListener('pointermove', onResizeMove);
    }
    
    const onResizeMove = (e: PIXI.InteractionEvent) => {
        if(isResizeDragging) {
          this.isGlobalResizing = true;
          isDragged = true;
          this.rectangleContainersOnResizing.removeChildren();
          resizePoint.alpha = 0;

          const mousePosition = e.data.getLocalPosition(this.stage);
          this.deltaX = mousePosition.x - rectangleContainer.x; 
          this.deltaY = mousePosition.y - rectangleContainer.y;

          rectangleContainer.children.forEach((child, i) => {
            if (child.name != ENamingTypes.RESIZE_ANCHOR){
                rectangleContainer.getChildAt(i).destroy()
            } else {
                this.previousCircleContainerAnchor = child;
                const {x,y} = e.data.getLocalPosition(this.stage);
                const point = {x, y};
                this.points.push(point);
            }
          })

          const fillColor = this.getFillColor(rectangleContainer);
          this.completeRectangle(this.getNewPositionOnResize(rectangleContainer, resizePointObj.position), true, false, null, true, fillColor);
          this.render();
        } else if(!isResizeDragging) {
          this.isGlobalResizing = false;
        }
    }

    if(isResize) resizePoint.on('pointerdown', onResizeStart)
  }

  // This method updates the new rectangle draw points during resizing, handling
  // different resize point positions.
  getNewPositionOnResize(rectangleContainer: PIXI.Graphics, relativePosition: RELATIVEPOSITIONS) {
    const pivot = rectangleContainer.pivot;
    let newPointA = {x: rectangleContainer.x - pivot.x, y:rectangleContainer.y - pivot.y};
    let newPointB = {x: rectangleContainer.x + this.deltaX, y:rectangleContainer.y + this.deltaY};

    switch (relativePosition){
      case RELATIVEPOSITIONS.BOTTOM_RIGHT:
        break;
      case RELATIVEPOSITIONS.BOTTOM_LEFT:
        newPointA = {x: rectangleContainer.x + pivot.x, y:rectangleContainer.y - pivot.y};
        break;
      case RELATIVEPOSITIONS.TOP_RIGHT:
        newPointA = {x: rectangleContainer.x - pivot.x, y:rectangleContainer.y + pivot.y};
        break;
      case RELATIVEPOSITIONS.TOP_LEFT:
        newPointA = {x: rectangleContainer.x + pivot.x, y:rectangleContainer.y + pivot.y};
        break;
      default:
        break;
    }

    return [newPointA, newPointB];
  }

  drawMenu(obj: PIXI.Graphics, container: PIXI.Graphics, points?: IPoint[]) {
    if(!points?.length){
      points = this.getObjectMetaProps()[container.name].points
    }
    const center = this.getShapeCenter(points);
    const menu = new PIXI.Graphics();
    const menuYOffset = 15;
    const menuY = center.y + ((center.maxY - center.minY) / 2) + menuYOffset
    const menuHeight = 30;
    const menuWidth = 55;
    menu.beginFill(0x333c42);
    menu.lineStyle(0);
    menu.drawRoundedRect(0, 0, menuWidth, menuHeight, 5);

    const menuPosX = points[1].x < 0 ? 0 : center.minX;
    const menuPoxY = points[1].y < 0 ? menuY + (center.maxY - center.minY) : menuY;
    menu.position.set(menuPosX, menuPoxY);
    menu.endFill();

    menu.alpha = 0;

    const containerId = this.getContainerIdFromName(container.name);

    // Menu icons
    const dupe = new PIXI.Sprite();
    dupe.texture = this.dupeSprite.texture;
    dupe.scale.set(0.03);
    dupe.anchor.set(0,0.5);
    dupe.y = menuHeight / 2;
    dupe.x = 10;
    dupe.interactive = true;
    dupe.name = this.getName(ENamingTypes.MENU_DUPE, containerId)

    const del = new PIXI.Sprite();
    del.texture = this.deleteSprite.texture;
    del.scale.set(0.03);
    del.anchor.set(0,0.5);
    del.y = menuHeight / 2;
    del.x = dupe.x + dupe.width + 5;
    del.interactive = true;
    del.name = this.getName(ENamingTypes.MENU_DEL, containerId)

    menu.addChild(dupe);
    menu.addChild(del);
    this.addMenuListeners(obj, container, ACTIONS.DUPLICATE, dupe, points);
    this.addMenuListeners(container, container, ACTIONS.DELETE, del, points);
    
    // container.addChild(menu);

    return menu;
  }

  addMenuListeners(rectangle: PIXI.Graphics, rectangleContainerSource: PIXI.Graphics,  action: ACTIONS, option: PIXI.Sprite, points: IPoint[]) {
    let func;
    option.cursor = 'pointer';
    if(action == ACTIONS.DUPLICATE) {
      func = (e) => {
        const fillColor = this.getFillColor(rectangleContainerSource);
        const dupPosition = this.getDuplicateObjectPosition((e.currentTarget as PIXI.Sprite))
        const rectangleContainer = this.completeRectangle([...points], false, true, {x: dupPosition.x, y: dupPosition.y}, false, fillColor);
        this.activateListner(rectangleContainer)
        this.render();
      }
    } else if(action == ACTIONS.DELETE) {
      func = (e) => {
        this.deleteContainerFromMenu(e.currentTarget as PIXI.Sprite)
        this.updateState();
      }
    }
    option.on('pointerup', func);
  }

  deleteObject(obj: PIXI.Graphics) {
    obj.removeChildren();
    obj.clear();
  }


  isDrawMode = false;
  toggleDrawMode(mode?: boolean) {
    this.isDrawMode = mode == null ? !this.isDrawMode : mode;
    if(!this.isDrawMode) {
        this.stage.cursor = 'default';
        this.backgroundSprite.cursor = 'default';
    } else {
        this.stage.cursor = 'pointer';
        this.backgroundSprite.cursor = 'pointer';
    }
    this.points = [];


    this.render(); 

    return this.isDrawMode;
  }

  clearRectangles() {
    this.rectangles.forEach((rectangle) => {
      this.deleteObject(rectangle);
      rectangle.destroy();
    });
    this.rectangles = [];
    this.render();
  }

  // getRectangleTools() {
  //   return this.rectangleTools;
  // }

  // rectangleBorder: PIXI.Graphics;
  // rectanglesSelectStatus = new Map();

  // Map of rectangle container and its outline
  // rectangleOutlines = new Map<string, {outline: PIXI.Graphics, rotationPoint: PIXI.Graphics, menu: PIXI.Graphics, resizePoint: PIXI.Graphics, isVisible: boolean}>();
  addSelectRectangleListener(obj: PIXI.Graphics) {
    obj.on('pointerdown', ($event) => super.onPointerDown($event))
  }

  clearActiveSelection = (container: PIXI.Graphics) => {
    this.removeRectangleMenu(container);
  }

  addActiveSelection = (container: PIXI.Graphics) => {
    this.addRectangleMenu(container)
  }

  addRectangleMenu(container: PIXI.Graphics){

    // get lineMenuContainer Object
    const containerId = this.getContainerIdFromName(container.name);
    const menuContainerName = this.getName(ENamingTypes.MENU, containerId)
    let menuContainer = <PIXI.Container>container.getChildByName(menuContainerName);
    const rectName = this.getName(ENamingTypes.TOOL, containerId)
    const rect = <PIXI.Graphics>container.getChildByName(rectName);

    // resize point
    const resizePointName = this.getName(ENamingTypes.RESIZE_ANCHOR, containerId);
    const resizePoint = <PIXI.Graphics>container.getChildByName(resizePointName);
    resizePoint.alpha = 1
    resizePoint.interactive = true
    
    
    // new menu container childs
    const {border, rotationPoint} = this.drawSelectedRectangleBorder(container, this.getFillColor(container), undefined, true);
    const menu = this.drawMenu(rect, container);
    border.alpha = 1;
    border.name = this.getName(ENamingTypes.BORDER, containerId);
    // rotationPoint.alpha = 1
    menu.alpha = 1;

    if(!menuContainer){
      // add new menu container inside lineContainer
      menuContainer =  new PIXI.Container();
      menuContainer.name = this.getName(ENamingTypes.MENU, containerId);
      container.addChild(menuContainer);
    }
    menuContainer.addChild(...[border, menu])
  }

  removeRectangleMenu(container: PIXI.Graphics){
    const containerId = this.getContainerIdFromName(container.name);
    const menuContainerName = this.getName(ENamingTypes.MENU, containerId)
    let menuContainer = <PIXI.Container>container.getChildByName(menuContainerName);
    if(menuContainer) menuContainer.removeChildren()

    // resize point
    const resizePointName = this.getName(ENamingTypes.RESIZE_ANCHOR, containerId);
    const resizePoint = <PIXI.Graphics>container.getChildByName(resizePointName);
    if(resizePoint) {
      resizePoint.alpha = 0
      resizePoint.interactive = false
    }
  }

  
  drawSelectedRectangleBorder(container: PIXI.Graphics, color: number, points?: IPoint[], noListener?: boolean) {
    if(!points?.length) {
      points = this.getObjectMetaProps()[container.name].points
    }
    const center = this.getShapeCenter(points);
    const border = new PIXI.Graphics();
    const width = center.maxX - center.minX;
    const height = center.maxY - center.minY;
    let offsetX = center.x - width/2;
    let offsetY = center.y - height/2;

    if (points[1].x < 0)  offsetX = 0;
    if (points[1].y < 0)  offsetY = 0;

    border.lineStyle(4, color, 0.3);
    border.drawRoundedRect( offsetX, offsetY, width, height, 3);
    border.drawRoundedRect( offsetX + 3, offsetY + 3, width - 6, height - 6, 1);
    // border.moveTo(offsetX + width/2, offsetY);
    // border.lineTo(offsetX + width/2, offsetY - 20);
    border.alpha = 1;

    const rotationPoint = new PIXI.Graphics();
    rotationPoint.beginFill(this.selectedColor, 1);
    rotationPoint.drawCircle(0, 0, 4);
    rotationPoint.position.set(offsetX + width/2, offsetY - 20);
    rotationPoint.alpha = 1;

    if (!noListener) this.addRotateListener(container, rotationPoint);
    // container.addChild(rotationPoint);
    // container.addChild(border);
    return {
      border: border,
      rotationPoint: rotationPoint
    };
  }

  addRotateListener(obj, rotatePoint){
    this.isGlobalDragging = false;
    let isRotateDragging = false;

    rotatePoint.cursor = 'ew-resize'
    rotatePoint.interactive = true;

    let initialAngle = Math.atan2(obj.pivot.y - rotatePoint.y, obj.pivot.x - rotatePoint.x) + Math.PI;
    const onRotateStart = (e) => {
      this.isRotatingPointerDown = true;
      isRotateDragging = true;
      this.isGlobalRotating = true;

      this.stage.on('pointerup', onRotateEnd)
      .on('pointermove', onRotate);
    }
    const onRotateEnd = (e) => {
      this.isRotatingPointerDown = false;
      isRotateDragging = false;
      this.isGlobalRotating = false;
      this.render();

      this.stage.removeListener('pointerup', onRotateEnd);
      this.stage.removeListener('pointermove', onRotate);
    }
    const onRotate = (e: PIXI.InteractionEvent) => {
        if(isRotateDragging) {
          const mousePosition = e.data.getLocalPosition(this.stage);
          const mouseAngle = Math.atan2(obj.y - mousePosition.y, obj.x - mousePosition.x) + Math.PI;
          obj.rotation = mouseAngle - initialAngle;
          this.render();
        }
    }

    rotatePoint.on('pointerdown', onRotateStart);
  }

  loadAssets() {
    return this.spriteLoader.loadSprites()
  }

  activateListner(container: PIXI.Graphics){
    container.interactive = true;
      container.children.forEach(child => {
        if(child.name?.includes(ENamingTypes.RESIZE_ANCHOR)){
          child.interactive = false // should olny be true when selected  
        }
        child.interactive = true
      })
  }

  activateSelectionListners(){
    this.getObjectContainer().forEach((container: PIXI.Graphics) => this.activateListner(container))
  }

  changeDrawnGraphicColor(currentSelectedObject: PIXI.Graphics){
    if (!currentSelectedObject.name) return;
    const rectangleGraphic = <PIXI.Graphics>currentSelectedObject.children.filter(child => child.name.includes(ENamingTypes.TOOL))[0]
    const pos_x = rectangleGraphic.x;
    const pos_y = rectangleGraphic.y;
    const width = rectangleGraphic.width;
    const height = rectangleGraphic.height;
    const rotation = rectangleGraphic.rotation;
    const pivot = rectangleGraphic.pivot;
    const alpha = rectangleGraphic.alpha;

    const rectangleOutlineThickness = 3;
    rectangleGraphic.clear();
    rectangleGraphic.beginFill(this.selectedColor, this.ObjectMetaPropsContainer[currentSelectedObject.name].fillOpacity);

    // top line: 
    rectangleGraphic.drawRect(0, 0, width, rectangleOutlineThickness);
    // bottom line: 
    rectangleGraphic.drawRect(0, 0 + height - rectangleOutlineThickness, width, rectangleOutlineThickness);
    // left line:
    rectangleGraphic.drawRect(0, 0, rectangleOutlineThickness, height);
    // right line:
    rectangleGraphic.drawRect(0 + width - rectangleOutlineThickness, 0, rectangleOutlineThickness, height);

    rectangleGraphic.endFill();

    rectangleGraphic.x += pos_x;
    rectangleGraphic.y += pos_y;
    rectangleGraphic.pivot = pivot;
    rectangleGraphic.zIndex = 50;
    rectangleGraphic.rotation = rotation;


    const fillColor = this.selectedColor
    this.ObjectMetaPropsContainer[currentSelectedObject.name].color = fillColor;

    const menuContainer = <PIXI.Graphics>currentSelectedObject.children.filter(child => child.name.includes(ENamingTypes.MENU))[0]

    const containerID = this.getContainerIdFromName(currentSelectedObject.name);
    const borderName = this.getName(ENamingTypes.BORDER, containerID);
    const border = <PIXI.Graphics>menuContainer.getChildByName(borderName);
    const border_pos_x = border.x;
    const border_pos_y = border.y;

    border.clear();
    border.lineStyle(4, this.selectedColor, 0.3);
    border.drawRoundedRect( 0, 0, width, height, 3);
    border.drawRoundedRect( 0 + 3, 0 + 3, width - 6, height - 6, 1);
    border.alpha = 1;
    border.position.set(border_pos_x, border_pos_y)

    const resizePoint = <PIXI.Graphics>currentSelectedObject.children.filter(child => child.name.includes(ENamingTypes.RESIZE_ANCHOR))[0];
    const resize_pos_x = resizePoint.x;
    const resize_pos_y = resizePoint.y;
    const resize_pivot = resizePoint.pivot;
    resizePoint.clear();
    resizePoint.beginFill(this.selectedColor, 0.9);
    resizePoint.drawCircle(0, 0, 4);
    resizePoint.position.set(resize_pos_x, resize_pos_y);
    resizePoint.endFill();
    resizePoint.pivot = resize_pivot;

    this.render();
  }
  getUpdatedState(entry: Partial<IEntryStateVirtualTools>): Partial<IEntryStateVirtualTools> {
    entry.data[EPixiTools.RECTANGLE] = Object.values(this.ObjectMetaPropsContainer);
    console.log(entry.data[EPixiTools.RECTANGLE], 'update rectangle')
    return entry;
  }

  handleNewState() {
    const data = this.getQuestionState(EPixiTools.RECTANGLE);
    if(!data || data.length == 0) {
      return;
    }

    // this.rectData = JSON.parse(JSON.stringify(data));
    data.forEach((rect) => {
      const temp: IDrawnMeta = JSON.parse(JSON.stringify(rect))
      this.completeRectangle(temp.points, false, true, {x: temp.x, y: temp.y}, true, temp.color);
    });

    this.render();
  }
}
