import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/api/auth.service';

interface IDataFrame {
  records: any[],
  title: string,
  slug: string,
  gridOptions: {
    columnDefs: IColumnDef[];
    defaultColDef: IDefaultColDef;
    api?:any // added by ag-grid
  }
}
interface IColumnDef {
  headerName: string;
  field: string;
  width?: number;
  checkboxSelection?: boolean;
}
interface IDefaultColDef {
  filter: boolean;
  sortable: boolean;
  resizable: boolean;
}

@Component({
  selector: 'panel-tw-summary',
  templateUrl: './panel-tw-summary.component.html',
  styleUrls: ['./panel-tw-summary.component.scss']
})
export class PanelTwSummaryComponent implements OnInit {

  @Input() testWindowId:number; // this is only used to track changes, central services is the source of truth so this never actually gets fed in anywhere
  @Input() roleContext:any;

  constructor(
    private auth:AuthService,
  ) { }

  dataFrames:IDataFrame[]
  dfInitMap:Map<string, boolean> = new Map();
  
  isLoading = true
  isLoadingInProgress = false
  selectedDf:string;

  ngOnInit(): void {
    this.loadData();
  }

  isDfSelectedOrInited(slug:string){
    return this.isDfSelected(slug) || this.dfInitMap.get(slug);
  }

  isDfSelected(slug:string){
    return (this.selectedDf === slug);
  }

  selectDf(slug:string){
    this.selectedDf = slug;
    this.dfInitMap.set(slug, true);
  }

  exportCsv(df:IDataFrame){
    df.gridOptions.api.exportDataAsCsv(df.title);
  }

  ngOnChanges(changes){
    if(changes.testWindowId){
      this.loadData();
    }
  }

  dataLoadOptions = {
    includeAsmtSample: false,
    focusAsmtSwapRisk: false,
  }

  async loadData(){
    if (!this.isLoadingInProgress ){
      this.isLoading = true;
      this.isLoadingInProgress = true;
      const prefDfSelected = this.selectedDf;
      this.selectedDf = null;
      this.dfInitMap = new Map();
      this.dataFrames = await this.auth.apiGet('public/test-ctrl/summary', this.testWindowId, {
        query: {
          includeAsmtSample:  this.dataLoadOptions.includeAsmtSample ? '1' : '',
          focusAsmtSwapRisk:  this.dataLoadOptions.focusAsmtSwapRisk ? '1' : '',
          ... this.roleContext,
        }
      });
      this.isLoading = false;
      this.isLoadingInProgress = false;
      if (prefDfSelected){
        this.selectDf(prefDfSelected)
      }
    }
  }

}
