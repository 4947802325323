<div class="element-type-table" [class.center-table]="element.isCenter">
  <table style="width:auto; line-height: normal;" *ngIf="element && element.grid">
    <tr *ngFor="let row of element.grid; let i = index;"  [class.header-row]="isRowHeader(i)" [class.header-row-no-bottom-border]="isRowHeader_no_bottom_border(i) || (isRowHeader(i) && element.isHideBorder)">
      <td
              *ngFor="let cell of row; let col_i = index;"
              [class.header-col]="checkColHeaderByIndex(col_i)"
              [class.is-selected-edit]="editSelection.isSelectedEdit(cell, questionPubSub)"
              [style.border]="editSelection.getBorder(cell, questionPubSub)"
              [class.header-col]="isColHeader(col_i)"
              [class.header-col-no-right-border]="isColHeader_no_right_border(col_i) || (isColHeader(col_i) && element.isHideBorder)"
              [ngStyle]="{
                            'text-align':parseCellAlignment(cell.align),
                            'width.em':getColWidth(col_i)
                          }"
              [attr.rowspan]="getCellRowSpan(i, col_i)"
              [attr.colspan]="getCellColSpan(i, col_i)"
              [style.display] = "isCellHidden(i, col_i)? 'none' : ''"
              [class.no-border] = "element.isHideBorder"
      >
        <div [ngSwitch]="cell.elementType">
          <div *ngSwitchCase="'text'"   class="inline-block">            <element-render-text      [element]="cell" [questionState]="questionState"  [questionPubSub]="questionPubSub" ></element-render-text></div>
          <div *ngSwitchCase="'math'"   class="inline-block is-math">    <element-render-math      [element]="cell" [questionState]="questionState" [isInline]="true"  ></element-render-math></div>
          <div *ngSwitchCase="'image'"  class="inline-block">            <element-render-image     [element]="cell" [questionState]="questionState" ></element-render-image></div>
          <div *ngSwitchDefault class="element-text">
            <render-highlightable
            [inputString]="cell.val"
            [entryId]="cell.entryId"
            [prop]="'val'"
            >
              <markdown-inline [input]="cell.val"></markdown-inline>
            </render-highlightable>
          </div>
        </div>
      </td>
    </tr>
  </table>
</div>
