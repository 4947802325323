import accounting from 'accounting-js';
import { countDecimals } from '../../ui-testrunner/models';

export const INPUT_NUMBER_FORMATS = {
  'None': function(val, isFrench){
    if(!isFormattable(val, isFrench)) return val;
    let unformattedNumber = unformatNumber(val, isFrench);
    return accounting.formatNumber(unformattedNumber, getFormatNumberSettings(countDecimals(unformattedNumber), isFrench));
  },
  '0': function(val, isFrench) {
    return accounting.formatNumber(unformatNumber(val, isFrench), getFormatNumberSettings(0, isFrench));
  },
  '0.0': function(val, isFrench) {
    return accounting.formatNumber(unformatNumber(val, isFrench), getFormatNumberSettings(1, isFrench));
  },
  '0.00': function(val, isFrench) {
    return accounting.formatNumber(unformatNumber(val, isFrench), getFormatNumberSettings(2, isFrench));
  },
  '0.000': function(val, isFrench) {
    return accounting.formatNumber(unformatNumber(val, isFrench), getFormatNumberSettings(3, isFrench));
  }
}

const isFormattable = (val, isFrench) => {
  if(isFrench) return !val.endsWith(',');
  return !val.endsWith('.');
}

const getFormatNumberSettings = (precision: number, isFrench: boolean) => {
  return { precision, thousand: getThousand(isFrench), decimal: getDecimal(isFrench) }
}

const getThousand = (isFrench) => {
  return isFrench ? " " : ","
}

const getDecimal = (isFrench) => {
  return isFrench ? "," : "."
}

const unformatNumber = (formattedVal, isFrench) => {
  return Number(accounting.unformat(String(formattedVal), getDecimal(isFrench)))
}