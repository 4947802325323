import { Component, Input, EventEmitter, Output, OnChanges, OnInit, SimpleChanges, DoCheck } from '@angular/core';
import { HyperlinkService } from '../hyperlink.service';
import { ScoringTypes } from '../models';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { IContentElementTextLink } from './model';



const SCORING_TYPE = ScoringTypes.AUTO;

@Component({
  selector: 'element-render-text-link',
  templateUrl: './element-render-text-link.component.html',
  styleUrls: ['./element-render-text-link.component.scss']
})
export class ElementRenderTextLinkComponent implements OnInit, DoCheck {

  @Input() element:IContentElementTextLink;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() changeCounter:number;
  @Input() questionState:any;
  @Input() questionPubSub?: QuestionPubSub;
  @Input() isHighContrast: boolean = false;
  @Input() saveQuestion?: Function; // Used in the test runner for split screen saving
  @Input() isShowingResults?: boolean
  @Input() isIconOnly?: boolean;

  textLinkCaption?: string;

  constructor(
    private hyperLinkService: HyperlinkService
    ) { }

  ngOnInit() {
    this.textLinkCaption = this.getTextLinkCaption();
  }
 
  getTextLinkCaption = () => this.element.readingSelectionCaption ? this.element.readingSelectionCaption : this.element.caption;

  /**
   * Triggered when the component is clicked on for navigation
   */
  async openLink() {
    // If the function for saving is passed in, force waiting for successful save
    // Before proceeding
    if (this.saveQuestion && !this.isShowingResults) {
      await this.saveQuestion(true);
    }
  
    const link = this.hyperLinkService.linkRequest.getValue();
      if ((link.readerId === this.element.readerId && link.readerId && this.element.readerElementId===link.readerElementId) 
            || (link.itemLabel === this.element.itemLabel && this.element.itemLabel)) {
        this.hyperLinkService.linkRequest.next({
          readerElementId: undefined,
          readerId: undefined,
          itemLabel: undefined
        })
      } else if (this.element.itemLabel) {
        this.hyperLinkService.linkRequest.next({
          readerElementId: undefined,
          readerId: undefined,
          itemLabel: this.element.itemLabel
        })
      }  else {
        this.hyperLinkService.linkRequest.next({
          readerElementId: this.element.readerElementId,
          readerId: this.element.readerId,
          itemLabel: undefined
        })
      }
  }

  ngDoCheck() {
    this.textLinkCaption = this.getTextLinkCaption();
  }
}
