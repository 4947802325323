export enum StuAsmtStatus {
  REGISTERED = 'REGISTERED',
  SUBMITTED = 'SUBMITTED',
  ABSENT = 'ABSENT',
}

export type IStudent = {
  uid:number,
  first_name :string,
  last_name :string,
  lang :string,
  dob:string,
  student_gov_id:string,
  asmt_status?: {
    [asmt_type_slug:string]: {
      status_code?: StuAsmtStatus,
      is_expected: boolean,
      is_in_ts: boolean,
      is_submitted: boolean,
      is_absent: boolean,
      is_unexpected_submission?: boolean,
    }
  }
  isAtLeastOneStatus?: boolean,
}

type IAsmtComponent = {
  id:number,
  asmt_type_slug:string, 
  caption_short:string, 
  meta:string, 
  isFrOnly?:boolean,// todo:DB_DATA_MODEL 
}

export type ISchlTwSignOff = {
  overall_issues_no: boolean,
  overall_issues_yes: boolean,
  overall_issues_notes: string,
}

export class TwStatement {

  constructor (
    public schl_group_id: number,
    public test_window_id: number,
    public asmtComponents:IAsmtComponent[],
    public students:IStudent[],
    public schl_tw_sign_off:{id:number, meta:ISchlTwSignOff},
  ){
    this.refreshStats();
  }

  public isSaving:boolean = false
  public studentWithStatusRef:Map<number, boolean> = new Map()

  public statRows = [
    {prop:'n_reg', caption: 'Registrations'},
    {prop:'n_sub', caption: 'Submissions'},
    {prop:'n_abs', caption: 'Absences'},
    {prop:'n_gap', caption: 'To Clarify'},
  ]

  public assessmentComponentStats:{
    [asmt_type_slug:string]: {
      n_reg: number,
      n_sub: number,
      n_abs: number,
      n_gap: number,
    }
  } = {};

  refreshStats(){
    this.assessmentComponentStats = {};
    for (let ac of this.asmtComponents){
      let n_reg = 0;
      let n_sub = 0;
      let n_abs = 0;
      let n_gap = 0;
      for (let student of this.students){
        const asmt_status = student.asmt_status[ac.asmt_type_slug];
        let isAtLeastOneStatus = false;
        if (asmt_status){
          if (asmt_status.is_expected || asmt_status.is_unexpected_submission){
            isAtLeastOneStatus = true;
            n_reg ++;
            if (asmt_status.is_absent){
              asmt_status.status_code = StuAsmtStatus.ABSENT;
              n_abs ++;
            }
            else if (asmt_status.is_submitted){
              asmt_status.status_code = StuAsmtStatus.SUBMITTED;
              n_sub ++;
            }
            else {
              asmt_status.status_code = StuAsmtStatus.REGISTERED;
              n_gap ++;
            }
          }
        }
        if (isAtLeastOneStatus){
          this.studentWithStatusRef.set(+student.uid, true)
        }
      }
      this.assessmentComponentStats[ac.asmt_type_slug] = {
        n_reg,
        n_sub,
        n_abs,
        n_gap,
      }
    }
  }
  
}