import { IContentElementDynamicImage } from "../element-render-image/model";
import { IContentElement, IEntryState } from "../models";
import { PubSubTypes } from "./pubsub/types";

export interface IContentElementFrame extends IContentElement {
    id?: number;
    content: IContentElement[];
    activatedStyles?: string[];
    styleRaw?: { [key: string]: string | number };
    width?: number;
    height?: number;
    maxHeight?: number;
    isClickable?: boolean;
    isAnimatable?: boolean;
    isOff?: boolean;
    toggleTargetId?: number;
    toggleTargetGroupId?: string;
    eventType?: PubSubTypes;
    eventData?: any;
    linkedContent?: {
      // not yet implemented
      itemId: number;
      frameId: number;
    };
}


export interface IContentElementFrameStateStyle {
    backGroundImg: IContentElementDynamicImage;
    dropShadowX: number;
    dropShadowY: number;
    blurRadius: number;
    shadowColor: string;
    backgroundColor: string;
    padding: number;
}



export interface IEntryStateFrame extends IEntryState {
    rotation: number;
    currHeight: number;
}

export const frameEditInfo = {
    editExcludeFields: ['content'],
    editKeyFieldsToShow: [null],
}
  
