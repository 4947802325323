
<ng-container *ngIf="isVisible()">
  <div 
    style="padding:0.5em; background-color:#ccc;" 
    *ngIf="element.content && element.content.length"
  >
    <div style="padding:0.5em; border: 1px solid #ccc;">
      <element-render *ngFor="let contentElement of element.content"
      [contentElement]="contentElement" 
      [questionState]="questionState" 
      [isLocked]="isLocked"
      [questionPubSub]="questionPubSub"
      ></element-render>
    </div>
  </div>
  <div [ngSwitch]="!!element.isPlainText" class="printable-response-display">
    <div *ngSwitchCase="true" style=" white-space: pre-wrap;">
      {{getResponse()}}
    </div>
    <div *ngSwitchCase="false" >
      <markdown 
        class="markdown" 
        [data]="getResponse()" 
        [class.is-condensed]="true"
        ngPreserveWhitespaces
      ></markdown>
    </div>
  </div>
</ng-container>