<div class="page-body">
  <div>
    <header
    [breadcrumbPath]="breadcrumb"
    ></header>
    <div class="page-content is-fullpage">
      <div  class="dashboard-cards-container" [class.is-full-width]="isFullWidth">

        <div *ngIf="!this.isLoaded && !this.isLoadFailed"> <!-- Loading --> </div>
        <div *ngIf="this.isLoadFailed"> You do not have the required roles to access this page. </div>
        <div class="notification is-warning" *ngIf="IS_COUNTS_WARNING"> This page is under maintenance and should not be used for reporting purposes. </div>
        <div class="notification is-danger" *ngIf="IS_COUNTS_DISABLED || IS_COUNTS_DISABLED"> Some functionality on this page has been disabled while it undergoes maintenance. </div>

        <div class="card dashboard-card" *ngIf="!IS_COUNTS_DISABLED">
          <div class="image-holder">
            <img src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/test-window-icon/1666377674742/test-window-icon.png">
          </div>
          <div class="table-holder">
            <div class="lead">
              <tra slug="sa_class_test_window_lb"></tra>
            </div>
            <div class="space-between">
              <div>
                <tra slug="txt_select_tw_for_detail"></tra>
              </div>
              <div>
                <mat-slide-toggle [(ngModel)]="isShowQa" >
                  Show QA? ({{isShowQa ? 'Yes' : 'No'}})
                </mat-slide-toggle>
              </div>
            </div>
            <table>
              <tr>
                <th>ID</th>
                <th>Window Title</th>
                <th>Dates</th>
                <!-- <th>Status</th> -->
                <th *ngIf="!IS_TW_SELECT_DISABLED">Select</th>
              </tr>
              <ng-container *ngFor="let window of testWindows"> 
                <tr *ngIf="!(!isShowQa && window.is_qa==1)" >
                  <td>{{window.id}}</td>
                  <td>
                    <div>
                      <strong>{{renderTwTitle(window.title)}}</strong>
                    </div>
                    <div class="space-between">
                      <div>
                        <code>{{window.type_slug}}</code>
                        <code style="color: blue">{{window.test_ctrl_group_id}}</code>
                        <span *ngIf="window.is_qa==1"  class="tag is-danger">QA</span>
                      </div>
                      <div style="font-size: 0.8em;">
                        <a [routerLink]="getTestWindowRoute(window)">
                          Edit
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>{{renderDate(window.date_start)}} to {{renderDate(window.date_end)}}</td>
                  <!-- <td>{{window.status}}</td> -->
                  <td  *ngIf="!IS_TW_SELECT_DISABLED">
                    <ng-container [ngSwitch]="!!checkActiveWindow(window)">
                      <button *ngSwitchCase="true" class="button is-small is-info">
                        Selected
                      </button>
                      <button *ngSwitchCase="false" class="button is-small" (click)="selectTestWindowFocus(window)">
                        Not Selected
                      </button>
                    </ng-container>
                  </td>
                </tr>
              </ng-container>
              <tr>
                <td></td>
                <td>
                  <button
                    class="button is-info is-small"
                    (click)="createTestWindow()">
                      <tra slug="create_new_admin_window"></tra>
                  </button>
                </td>
                <td></td>
                <td></td>
              </tr>
            </table>

          </div>
        </div>

        <ng-container *ngIf="isWindowSelected()">

          <div class="card dashboard-card" *wlCtx="'IS_TC_SUMM_DETAILED'">
            <div class="image-holder">
              <img src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/account-icon/1666377712300/account-icon.png">
            </div>
            <div class="table-holder">
              <div class="lead">
                <div>{{getTestWindowTitle()}} Summary</div>
              </div>
              <div>
                <panel-tw-summary [testWindowId]="getTestWindowId()"></panel-tw-summary>
              </div>
            </div>
          </div>

          <div class="card dashboard-card" *ngIf="isShowSummaries()">
            <div class="image-holder">
              <img src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/account-icon/1666377712300/account-icon.png">
            </div>
            <div class="table-holder">


              <div class="lead">
                <div>Summaries</div>
                <div class="buttons">
                  <!-- <button class="button is-small">Export to Excel</button> -->
                </div>
              </div>
              <div>
                <!-- Click on the link to the boards and schools to further filter the d -->
              </div>
              <table>
                <tr>
                  <th>Account Type</th>
                  <ng-container *ngIf="!IS_COUNTS_DISABLED">
                    <th *ngFor="let col of userGroupSummaryColumns">
                      <tra [slug]="col.caption"></tra>
                    </th>
                  </ng-container>
                </tr>
                <tr *ngFor="let userGroupType of userGroupTypes">
                  <td [ngSwitch]="userGroupType.caption" class="is-data-label">
                    <a *ngSwitchDefault [href]="getRawLink(userGroupType)" target="_blank">
                      <tra [slug]="userGroupType.caption"></tra>
                    </a>
                    <a *ngSwitchCase="'Schools'" routerLink="/en/test-ctrl/school">
                      <tra [slug]="userGroupType.caption"></tra>
                    </a>
                    <a *ngSwitchCase="'Boards'" routerLink="/en/test-ctrl/school-boards">
                      <tra [slug]="userGroupType.caption"></tra>
                    </a>
                    <a *ngSwitchCase="'Classes'" routerLink="/en/test-ctrl/school-classes">
                      <tra [slug]="userGroupType.caption"></tra>
                    </a>
                    <a *ngSwitchCase="'Teachers'" routerLink="/en/test-ctrl/school-teachers">
                      <tra [slug]="userGroupType.caption"></tra>
                    </a>
                    <a *ngSwitchCase="'Students'" routerLink="/en/test-ctrl/school-students">
                      <tra [slug]="userGroupType.caption"></tra>
                    </a>
                  </td>
                  <ng-container *ngIf="!IS_COUNTS_DISABLED">
                    <td *ngFor="let col of userGroupSummaryColumns" class="has-data">
                      <!-- {{getColValue(userGroupType.id, col.id)}} -->
                      <a [routerLink]="getColLink(userGroupType.id)" [queryParams]="{ order: col.id }">
                        {{getColValue(userGroupType.id, col.id)}}
                      </a>
                    </td>
                  </ng-container>
                </tr>
              </table>

              <!-- <div  style="margin-top:2em;">
                <strong>Technical Readiness Tracker</strong>
              </div>
              <table >
                <tr>
                  <th>Tally</th>
                  <th>SchName</th>
                  <th>SchMident</th>
                  <th>PrincipalFirstName</th>
                  <th>PrincipalEmail</th>
                </tr>
                <tr *ngFor="let school of g9Summary.tech_readi" [class.is-strong]="school.tally==10">
                  <td>{{school.tally}}/10</td>
                  <td>{{school.SchName}}</td>
                  <td>{{school.SchMident}}</td>
                  <td>{{school.PrincipalFirstName}}</td>
                  <td>{{school.PrincipalEmail}}</td>
                </tr>
              </table> -->
              <div class="summary-timestamp">{{renderTimestampAsUIString()}}</div>

              <div *ngIf="twFiles && twFiles.length">
                <hr>
                <h4>Administration Window Extracts</h4>
                <table>
                  <tr *ngFor="let file of twFiles">
                    <td>{{file.slug}}</td>
                    <td>
                      <a [href]="file.url" target="_blank">
                        {{file.file_name}}
                      </a>
                    </td>
                    <td>{{file.created_on}}</td>
                  </tr>
                </table>
              </div>
              
            </div>
          </div>

          <div *ngIf="isShowDataDownloads()" class="card dashboard-card" >
            <div class="image-holder">
              <img src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/excel/1606094586078/excel.png">
            </div>
            <div class="table-holder">
              <div class="lead">Data Downloads</div>
              <div>
                The links below can be used to download the relevant data. Data mapping is consistent with the codebook. The only data frames that are currently avaialble are the ones marked with the spreadsheet icon (<i style="color: #15aabf;" class="fas fa-file-excel"></i>).
              </div>
              <div>
                <strong>Filter is set to the active test window.</strong>
              </div>
              <table>
                <ng-container *ngFor="let section of codebookTable.sections">
                  <tr class="lead-row">
                    <td>{{section.id}}</td>
                    <td [ngSwitch]="!!section.link" class="lead-caption">
                      <span *ngSwitchCase="false">{{section.caption}}</span>
                      <a *ngSwitchCase="true" target="_blank" [href]="section.link">{{section.caption}}</a>
                    </td>
                    <td></td>
                  </tr>
                  <tr *ngFor="let dataFrame of section.dataFrames">
                    <td>{{section.id}}.{{dataFrame.id}}</td>
                    <td [ngSwitch]="!!dataFrame.isEnabled">
                      <div (click)="downloadTransferTable(dataFrame, section.id)" *ngSwitchCase="true" target="_blank" style="cursor:pointer; text-decoration:underline;">
                        <i style="color: #15aabf; margin-right:0.5em;" class="fas fa-file-excel"></i>
                        {{dataFrame.caption}}
                      </div>
                      <span *ngSwitchCase="false">
                        {{dataFrame.caption}}
                      </span>
                    </td>
                    <td>
                      <div [ngClass]="{ 'td-line-breaks': dataFrame.id == 9.4 }">
                        {{dataFrame.description}}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3"></td>
                  </tr>
                </ng-container>
              </table>
            </div>
          </div>

        </ng-container>

        <div class="card dashboard-card" *wlCtx="'IS_TC_ALL_WINDOW_SUMMARY'">
          <div class="table-holder" >
            <!-- Load Writing Summary for All Sessions -->
            <button 
              class="button is-info" 
              style="margin-bottom: 1em;" 
              (click)="loadAllWinSummary()" 
              [disabled]="isLoadingAllWinSummary">
                <tra slug="load_admin_summary_windows"></tra>
            </button>

            <!-- Loading all writing session summary... -->
            <div *ngIf="isLoadingAllWinSummary" class="notification is-warning">
              <tra slug="loading_all_window"></tra>
            </div>
            <div *ngIf="allWinSummaryRecords">
              <ag-grid-angular
                class="ag-theme-alpine ag-grid-fullpage"
                style="max-height: 30em;"
                [rowData]="allWinSummaryRecords"
                [gridOptions]="allWinGridOptions"
                [enableCellTextSelection]="true"
              ></ag-grid-angular>
              <div style="margin-top:1em;">
                <button class="button" (click)="downloadAllWinSummary()">
                  Export CSV
                </button>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="!IS_COUNTS_DISABLED">
          <div class="card dashboard-card" *wlCtx="'IS_TC_SCHOOL_ADMIN_BULK'">
            <div class="table-holder" >
              <div class="lead"><tra slug="lbl_tctrl_bulk_load"></tra></div>   
              <div class="space-between">
                <div>
                  <tra-md slug="tctrl_bulk_load_desc"></tra-md>
                </div> 
              </div>
              <br>
              <div style="display: flex; justify-content: flex-end;">
                <div style = " margin-right:  0.5em;">
                  <mat-slide-toggle [(ngModel)]="dryRun" >
                    <tra slug="lbl_tctrl_bulk_load_admin_dry_run"></tra>? ({{ getDryRunStatus() }})   
                  </mat-slide-toggle>
                </div>
                <div style = " margin-right:  0.5em;">
                  <mat-slide-toggle [(ngModel)]="revokeExistingAdmin" > 
                    <tra slug="lbl_tctrl_bulk_load_admin_revoke_admin"></tra>? ({{ getRevokeExistingAdminStatus() }})   
                  </mat-slide-toggle>
                </div> 
                <div class="file template">
                  <label class="file-label">
                      <span class="file-cta" (click)="bulkLoadTemplate()">
                          <span class="icon"><i class="fas fa-table"></i></span>
                          <span><tra slug="sa_import_template"></tra></span>  
                      </span>
                  </label>
                </div>
                <div class="file">
                  <label class="file-label">
                      <input class="file-input" type="file" (change)="bulkLoad($event.target.files)">
                      <span class="file-cta">
                          <span class="icon"><i class="fas fa-table"></i></span>
                          <span><tra slug="lbl_tctrl_bulk_load_admin"></tra></span>     
                      </span>
                  </label>
                </div>
              </div>
              <br>
              <div style="overflow-y:scroll; height:400px;">
                <table>
                  <tr>
                    <th><tra slug="lbl_tctrl_bulk_load_admin_id"></tra></th>   
                    <th><tra slug="lbl_tctrl_bulk_load_admin_load_date"></tra></th>   
                    <th><tra slug="lbl_tctrl_bulk_load_admin_loader_uid"></tra></th> 
                    <th><tra slug="lbl_tctrl_bulk_load_admin_revoked_admin"></tra></th>   
                    <th><tra slug="lbl_tctrl_bulk_load_admin_input_account"></tra></th>  
                    <th><tra slug="lbl_tctrl_bulk_load_admin_success_account"></tra></th>   
                    <th><tra slug="lbl_tctrl_bulk_load_admin_fail_account"></tra></th>   
                    <th><tra slug="lbl_tctrl_bulk_load_admin_revoked_account"></tra></th>   
                    <th><tra slug="lbl_tctrl_bulk_load_admin_not_included_account"></tra></th>  
                    <th><tra slug="lbl_tctrl_bulk_load_admin_idle_account"></tra></th>   
                  </tr>
                  <ng-container *ngFor="let bulkLoadRecord of bulkLoadRecords">
                    <tr>
                      <td>{{bulkLoadRecord.id}}</td>
                      <td>{{bulkLoadRecord.date}}</td>
                      <td>{{bulkLoadRecord.loader_uid}}</td>
                      <td><i *ngIf="bulkLoadRecord.revoke_existing_admin === 1" class="fa fa-check"></i></td>
                      <td><a *ngIf ="bulkLoadRecord.input_accounts" href="{{bulkLoadRecord.input_accounts}}"><tra slug="lbl_tctrl_download"></tra></a></td> 
                      <td><a *ngIf ="bulkLoadRecord.sucess_accounts" href="{{bulkLoadRecord.sucess_accounts}}"><tra slug="lbl_tctrl_download"></tra></a></td>
                      <td><a *ngIf ="bulkLoadRecord.fail_accounts" href="{{bulkLoadRecord.fail_accounts}}"><tra slug="lbl_tctrl_download"></tra></a></td>
                      <td><a *ngIf ="bulkLoadRecord.revoked_accounts" href="{{bulkLoadRecord.revoked_accounts}}"><tra slug="lbl_tctrl_download"></tra></a></td>
                      <td><a *ngIf ="bulkLoadRecord.not_included_accounts" href="{{bulkLoadRecord.not_included_accounts}}"><tra slug="lbl_tctrl_download"></tra></a></td>
                      <td><a *ngIf ="bulkLoadRecord.idle_accounts" href="{{bulkLoadRecord.idle_accounts}}"><tra slug="lbl_tctrl_download"></tra></a></td>
                    </tr>
                  </ng-container>
                </table>
              </div>  
            </div>
          </div>
        </ng-container>
        <ng-container *wlCtx="'IS_TC_SCORING_SCATTER'">
          <div class="card dashboard-card" *ngIf="!IS_COUNTS_DISABLED && isWindowSelected()">
            <div class="table-holder">
              <div class="lead">Scoring Windows</div>
              <div>
                <div class="sub">Scoring Window Setup</div>
                <br>
                <button class="button" (click)="openScoringWindowModal('add')">Setup Scoring Window</button>
                <br>
                <table *ngIf="activeScoringWindows.length">
                  <tr>
                    <th>Scoring Window ID</th>
                    <th>Name</th>
                    <th>Start On</th>
                    <th>End On</th>
                    <th>Active?</th>
                    <th>Paused?</th>
                    <th>Scoring Disabled?</th>
                    <th>Locked?</th>
                    <th>Hidden for Scorers?</th>
                    <th>Lang</th>
                    <th></th>
                  </tr>                
                  <tr *ngFor="let scoringWindow of activeScoringWindows">
                    <td>{{scoringWindow.id}}</td>
                    <td>{{scoringWindow.name}}</td>
                    <td>{{renderDate(scoringWindow.start_on)}}</td>
                    <td>{{renderDate(scoringWindow.end_on)}}</td>
                    <td>{{scoringWindow.is_active}}</td>
                    <td>{{scoringWindow.is_paused}}</td>
                    <td>{{scoringWindow.is_scoring_disabled}}</td>
                    <td>{{scoringWindow.is_locked}}</td>
                    <td>{{scoringWindow.is_hidden_for_scorers}}</td>
                    <td>{{scoringWindow.lang}}</td>
                    <td><button class="button is-small" (click)="openScoringWindowModal('edit', scoringWindow.id)">Edit</button></td>
                  </tr>
                </table>
              </div>
              <br>
              <div>
                <div class="sub">Scoring Window Items Setup</div>
                <br>
                <table *ngIf="activeScoringWindows.length">
                  <tr>
                    <th>Scoring Window</th>
                    <th></th>
                  </tr>
                  <tr>
                    <td>
                      <select [(ngModel)]="selectedScoringWindowForItems">
                        <option *ngFor="let scoringWindow of activeScoringWindows" [ngValue]="scoringWindow">
                          [{{scoringWindow.id}}] - {{scoringWindow.name}}
                        </option>
                      </select>
                    </td>
                    <td><button class="button" (click)="openScoringWindowItemsModal()">Setup Scoring Window Items</button></td>
                  </tr>
                </table>
              </div>
              <br>
              <div>
                <div class="sub">Test Window Connections</div>
                <table *ngIf="scoringWindowTestWindows.length">
                  <tr>
                    <th>Test Window ID</th>
                    <th>Test Window Name</th>
                    <th>Scoring Window ID</th>
                    <th>Scoring Window Name</th>
                    <th></th>
                  </tr>
                  <tr *ngFor="let scoringWindowTestWindow of scoringWindowTestWindows">
                    <td>{{scoringWindowTestWindow.tw_id}}</td>
                    <td>{{getTestWindowName(scoringWindowTestWindow)}}</td>
                    <td>{{scoringWindowTestWindow.mw_id}}</td>
                    <td>{{scoringWindowTestWindow.mw_name}}</td>
                    <td><button class="button is-small" (click)="revokeScoringWindowTestWindow(scoringWindowTestWindow)">Revoke</button></td>
                  </tr>
                </table>
                <br>
                <table *ngIf="activeScoringWindows.length">
                  <tr>
                    <th>Test Window</th>
                    <th>Scoring Window</th>
                    <th></th>
                  </tr>
                  <tr>
                    <td>
                      {{getTestWindowId()}} - {{getTestWindowName(getTestWindow())}}
                    </td>
                    <td>
                      <select [(ngModel)]="selectedScoringWindowToAdd">
                        <option *ngFor="let scoringWindow of activeScoringWindows" [ngValue]="scoringWindow">
                          [{{scoringWindow.id}}] - {{scoringWindow.name}}
                        </option>
                      </select>
                    </td>
                    <td><button class="button is-small" (click)="addScoringWindowTestWindow()">Add</button></td>
                  </tr>
                </table>
              </div>
              <br>
              <div>
                <div class="sub">Move Response Sets</div>
                <table *ngIf="responseSetsTransferLogs.length">
                  <tr>
                    <th>Scoring Window ID (From)</th>
                    <th>Scoring Window ID (From)</th>
                    <th>Num of Response Selections</th>
                    <th>Num of TAQR Cache</th>
                    <th>Num of Response Sets</th>
                    <th>Num of Response Set Selections</th>
                    <th>Created On</th>
                  </tr>
                  <tr *ngFor="let log of responseSetsTransferLogs">
                    <td>{{log.marking_window_id_from}}</td>
                    <td>{{log.marking_window_id_to}}</td>
                    <td>{{log.num_response_selections}}</td>
                    <td>{{log.num_taqr_cache}}</td>
                    <td>{{log.num_response_sets}}</td>
                    <td>{{log.num_response_set_selections}}</td>
                    <td>{{renderDate(log.created_on)}}</td>
                  </tr>
                </table>
                <br>
                <table>
                  <tr>
                    <th>Scoring Window ID (From)</th>
                    <th>Scoring Window ID (To)</th>
                    <th>Response Sets</th>
                  </tr>
                  <tr>
                    <td>
                      <select [(ngModel)]="responseSetsFrom">
                        <option *ngFor="let scoringWindow of activeScoringWindows" [ngValue]="scoringWindow">
                          [{{scoringWindow.id}}] - {{scoringWindow.name}}
                        </option>
                      </select>
                    </td>
                    <td>
                      <select [(ngModel)]="responseSetsTo">
                        <option *ngFor="let scoringWindow of activeScoringWindows" [ngValue]="scoringWindow">
                          [{{scoringWindow.id}}] - {{scoringWindow.name}}
                        </option>
                      </select>
                    </td>
                    <td>
                      <button class="button is-small" (click)="moveResponseSets(responseSetsFrom, responseSetsTo)">Move</button>
                    </td>
                  </tr>
                </table>
              </div>  
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!IS_COUNTS_DISABLED">
          <div class="card dashboard-card" *wlCtx="'IS_TC_DAILY_REPORT'">
            <div class="table-holder" >
              <div class="lead">Daily Report Setting</div>
              <br>
              <div>Daily Reporting Status: ({{isActivateDailyReporting ? 'ON' : 'OFF'}})</div>
              <br>
              <button class="button" (click)="promptReportingPassword()">Edit Daily Reporting</button>
            </div>
          </div>
        </ng-container>

        <div class="card dashboard-card"> <!-- Manage List  -->
          <div>
            <strong>Manage Lists: </strong>
            <a routerLink="/en/test-ctrl/school"> Schools </a> | 
            <a routerLink="/en/test-ctrl/school-boards"> {{getSchoolDistrictsCaption()}} </a>
          </div>
        </div>

      </div>
    </div>
  </div>
  <footer [hasLinks]="true"></footer>
</div>

<div class="custom-modal" *ngIf="cModal()">
  <div [ngSwitch]="cModal().type" class="modal-contents" style="min-width:42em;">
    <div>
      <div *ngSwitchCase="TestControllerModal.SCORING_WINDOW_FORM">
        <div class="close-button-container">
          <i (click)="pageModal.closeModal()" class="fa fa-times fa-2x close-button" aria-hidden="true"></i>
        </div>
        <div class="modal-container">
          <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="scoringWindowForm.name">
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Active?</mat-label>
            <mat-select [(ngModel)]='scoringWindowForm.is_active'>
                <mat-option *ngFor="let option of yesOrNoSelections" [value]="option.value">
                {{option.description}}
                </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Start On</mat-label>
            <input [min]="now" matInput [matDatepicker]="startOnDatePicker" [(ngModel)]="scoringWindowForm.start_on">
            <mat-datepicker-toggle matSuffix [for]="startOnDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startOnDatePicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>End On</mat-label>
            <input [min]="now" matInput [matDatepicker]="endOnDatePicker" [(ngModel)]="scoringWindowForm.end_on">
            <mat-datepicker-toggle matSuffix [for]="endOnDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endOnDatePicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Paused?</mat-label>
            <mat-select [(ngModel)]='scoringWindowForm.is_paused'>
                <mat-option *ngFor="let option of yesOrNoSelections" [value]="option.value">
                {{option.description}}
                </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Scoring Disabled?</mat-label>
            <mat-select [(ngModel)]='scoringWindowForm.is_scoring_disabled'>
                <mat-option *ngFor="let option of yesOrNoSelections" [value]="option.value">
                {{option.description}}
                </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Locked?</mat-label>
            <mat-select [(ngModel)]='scoringWindowForm.is_locked'>
                <mat-option *ngFor="let option of yesOrNoSelections" [value]="option.value">
                {{option.description}}
                </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Hidden for Scorers?</mat-label>
            <mat-select [(ngModel)]='scoringWindowForm.is_hidden_for_scorers'>
                <mat-option *ngFor="let option of yesOrNoSelections" [value]="option.value">
                {{option.description}}
                </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Lang</mat-label>
            <mat-select [(ngModel)]='scoringWindowForm.lang'>
                <mat-option *ngFor="let option of langSelections" [value]="option.value">
                {{option.description}}
                </mat-option>
            </mat-select>
          </mat-form-field>
          <button *ngIf="cModal().config.mode == 'add'" class="button is-info" (click)="saveScoringWindow('add')">Save</button>  
          <button *ngIf="cModal().config.mode == 'edit'" class="button is-info" (click)="saveScoringWindow('edit', cModal().config.id)">Save</button>  
        </div>
      </div>
      <div *ngSwitchCase="TestControllerModal.SCORING_WINDOW_ITEMS">
        <div class="close-button-container">
          <i (click)="pageModal.closeModal()" class="fa fa-times fa-2x close-button" aria-hidden="true"></i>
        </div>
        <div class="modal-container">
          <table>
            <tr>
              <th>Scoring Window ID</th>
              <th>Group ID</th>
              <th>Item ID</th>
              <th>Score Profile ID</th>
              <th>Slug</th>
              <th>Batch Alloc Policy ID</th>
              <th>Caption</th>
            </tr>
            <tr *ngFor="let item of cModal().config.newMarkingWindowItems">
              <td>{{item.marking_window_id}}</td>
              <td>{{item.group_id}}</td>
              <td>{{item.item_id}}</td>
              <td>{{item.score_profile_id}}</td>
              <td>{{item.slug}}</td>
              <td>
                <select [(ngModel)]="item.batch_alloc_policy_id">
                  <option *ngFor="let batchPolicy of cModal().config.batchPolicyList" [ngValue]="batchPolicy.id">
                    [{{batchPolicy.id}}] - {{batchPolicy.description}}
                  </option>
                </select>
              </td>
              <td>
                <input type="text" [(ngModel)]="item.caption"/>
              </td>      
            </tr>
          </table>
          <br>
          <button class="button is-info" (click)="saveScoringWindowItems()">Save</button>
        </div>
      </div>
      <div *ngSwitchCase="TestControllerModal.DAILY_REPORTING_PWD">
        <div class="close-button-container">
          <i (click)="pageModal.closeModal()" class="fa fa-times fa-2x close-button" aria-hidden="true"></i>
        </div>
        <div class="daily-reporting-container">
          <label>
            Password:
            <input type="password" [(ngModel)]="inputDailyReportPwd"/>
          </label>
          <button class="button is-small" (click)="checkPwd()">Submit</button>
          <div *ngIf="isWrongPasswordPrompted" class="warning-msg">Wrong password</div>
        </div>
      </div>
      <div *ngSwitchCase="TestControllerModal.DAILY_REPORTING">
        <div class="close-button-container">
          <i (click)="pageModal.closeModal()" class="fa fa-times fa-2x close-button" aria-hidden="true"></i>
        </div>
        <div class="daily-reporting-container-setting">
          <mat-slide-toggle [(ngModel)]="isActivateDailyReporting" (change)="toggleDailyReporting()" >
            Activate Daily Reporting? ({{isActivateDailyReporting ? 'Yes' : 'No'}})
          </mat-slide-toggle>
          <br>
          <br>
          <button class="button is-small" [disabled]="!isActivateDailyReporting" (click)="runDailyReporting()">
            Instant Run
          </button>
        </div>
      </div>
      <div *ngSwitchCase="TestControllerModal.SYNC_SCORE_PROFILES">
        <div class="close-button-container">
          <i (click)="pageModal.closeModal()" class="fa fa-times fa-2x close-button" aria-hidden="true"></i>
        </div>
        <div class="sync-score-profiles-container">
          <table>
            <tr>
              <td>Item ID</td>
              <td>Description</td>
              <td>Score Profile ID</td>
            </tr>
            <tr *ngFor="let item of syncScoreProfilesPreview.merged">
              <td>{{item.item_id}}</td>
              <td>{{item.caption}}</td>
              <td>{{item.score_profile_id_from}} -> {{item.score_profile_id_to ? item.score_profile_id_to : item.score_profile_id_from}}</td>
            </tr>
          </table>
          <button class="button is-primary is-fullwidth is-info" (click)="syncScoreProfileId(cmc().marking_window_id)">
            Sync
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
