import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import { EditSelectionService } from '../edit-selection.service';
import {QuestionState} from '../models';
import {QuestionPubSub} from '../question-runner/pubsub/question-pubsub';
import { IContentElementTable } from './model';
import { getCellColSpan, getCellRowSpan, isCellHidden, isColHeader, isColHeader_no_right_border, isRowHeader, isRowHeader_no_bottom_border } from 'src/app/ui-item-maker/element-config-table/merge-cell-functions';

@Component({
  selector: 'element-render-table',
  templateUrl: './element-render-table.component.html',
  styleUrls: ['./element-render-table.component.scss'],
})
export class ElementRenderTableComponent implements OnInit {

  @Input() element:IContentElementTable;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() questionPubSub?: QuestionPubSub;

  constructor(
    public editSelection: EditSelectionService
  ) { }

  getCellRowSpan = getCellRowSpan.bind(this);
  getCellColSpan = getCellColSpan.bind(this);
  isCellHidden = isCellHidden.bind(this);
  isRowHeader = isRowHeader.bind(this);
  isRowHeader_no_bottom_border = isRowHeader_no_bottom_border.bind(this);
  isColHeader = isColHeader.bind(this);
  isColHeader_no_right_border = isColHeader_no_right_border.bind(this);

  ngOnInit() {
  }

  // checkHeaderByIndex(i:number){
  //   if (this.element.isHeaderRow && i === 0){
  //     return true;
  //   }
  // }

  checkColHeaderByIndex(i:number){
    if (this.element.isHeaderCol && i === 0){
      return true;
    }
  }

  parseCellAlignment(align:string){
    switch(align){
      case 'left': return 'left';
      case 'right': return 'right';
      default:
      case 'center': return 'center';
    }
  }

  getColWidth(col_i:number){
    if (this.element.isColWidthConst && this.element.colWidthConst){
      return this.element.colWidthConst;
    }
  }

  getCell(row_i:number, col_i:number){
    return this.element.grid[row_i][col_i];
  }
}
