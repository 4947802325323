import { API_ADDRESS_LOCAL, API_ADDRESS_LOCALB } from "../../api/api-addr/l";
import { IContextData } from "./common";

export const VEA:IContextData = {
  id: 'VEA',
  homepageRoute: '/en/general/login/false',
  brandName: 'Vretta eAssessment',
  logo: '',
  footer: null,
  apiAddress: (hostname:string) => {
    switch(hostname){
      case 'localhost': 
        return API_ADDRESS_LOCALB;
      case 'eassessment-qc.vretta.com':
        case 'dt6dnz30h5udd.cloudfront.net':
        return 'https://eassessment-qc-api.vretta.com'
      default: 
        return 'https://api-eassessment.vretta.com';
    }
  },
  siteFlags: {
    'IS_VEA': true,
    'IS_NBED': true, // until distinct whitelabel is created
    'IS_SCOR_CARD_DETAIL': true,
    'IS_SCORER_REPORT': false,
    'IS_GUEST_STU_AVAIL': true,
    'IS_TTS_SOMETIMES': true,
    'INST_MNG_TT': true,
    'TEST_CTRL_REPORT_EXPLR': true,
    'TEST_CTRL_DATA_QUERY': true,
    'IS_TC_SUMM': true,
    'IS_SCOR_SCALES_HELP': false, // for nbed
    'IS_SMCS': true,  // for St.Michael's College School
  },
  siteText: {
    copyright: ' © Vretta Inc. 2020',
    supportEmail: 'support@vretta.com',
    supportPhone: '',
    my_inst: 'My School',
    my_inst_admin: 'You are the **administrator** of this school.',
    my_inst_manage_acct: 'Manage Teachers+',
    currentLogo: 'https://www.vretta.com/logo.svg',
  },
  hosts: [
      'eassessment.vretta.com',
      'localhost:4200'
  ],
  defaultTimezone: 'America/Vancouver',
}
