export const DUPLICATE_OBJECT_SPACE = 20
export const DEFAULT_TICK_MEASURE = 5;

export const DEFAULT_ROWS = 3;
export const DEFAULT_COLS = 2;

export const BLACK = 0x000000;
export const WHITE = 0xffffff;
export const CARET_BLINK_SPEED = 500;

// Keyboard
export const BACKSPACE = 8;
export const ENTER = 13;
export const ARROW_LEFT = 37;
export const ARROW_RIGHT = 39;
export const SINGLE_QUOTE = 222;
export const SPACE = 32;
export const KEY_C = 67;
export const KEY_V = 86;
export const KEY_X = 88;

export enum TICK_LEVEL {
    MAJOR,
    MID,
    MINOR
}

export enum GAP_THRESHOLDS {
    A = 2,
    B = 1,
}

export enum LAYER_LEVEL {
    LEVEL_0 = 0,
    LEVEL_1 = 1,
    LEVEL_2 = 2,
    LEVEL_3 = 3,
    LEVEL_4 = 4,
    LEVEL_5 = 5,
}

export enum TOOLBAR_CATEGORY_ID {
    SELECTION = 'SELECTION',
    PENCIL = 'PENCIL',
    TEXT = 'TEXT',
    DATA = 'DATA',
    MEASUREMENTS = 'MEASUREMENTS',
    SHAPES_DRAW = 'SHAPES_DRAW',
    SHAPES_PRESET = 'SHAPES_PRESET',
    PALETTE = 'PALETTE'
}

export enum TOOLBAR_CATEGORY_ICON_URL {
    SELECTION = 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/selector/selector-solid.png',
    PENCIL = 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/pencil/1686838975207/pencil.png',
    TEXT = 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/text/1686836133001/text.png',
    DATA = 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/ncr-category-icons/data.png',
    MEASUREMENTS = 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/ncr-category-icons/measurements.png',
    SHAPES_DRAW = 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/ncr-category-icons/shapes_draw.png',
    SHAPES_PRESET = 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/ncr-category-icons/shapes_presets.png',
    PALETTE = 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/color-palette-3/1685478658551/color-palette-3.png'
}