import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/api/auth.service';
import { BcAccountsService, CreateSchoolAdminData, DistrictDetail, Pagination, School, AdminRole, SchoolDetail, DistrictAdminRow, SchoolAdminRow, PaginatedRows, FilterCondition } from 'src/app/bc-accounts/bc-accounts.service';
import { AssessmentType, TestWindow } from 'src/app/bc-assessments/bc-assessments.service';
import { AccountType } from 'src/app/constants/account-types';
import { LangService } from 'src/app/core/lang.service';
import { cloneDeep, isEmpty } from 'lodash';
import { LoginGuardService } from 'src/app/api/login-guard.service';
import { BcPaginatedTableComponent, IColumnHeading, Toggle } from '../bc-paginated-table/bc-paginated-table.component';


interface SaAccountsSchoolAdminRow {
  uid: number,
  first_name: string,
  last_name: string,
  contact_email: string,
  status: string,
  role: AdminRole,
  editedRole: AdminRole,
  selected: boolean,
  invited: boolean,
  revoked: boolean,
}

export interface ModalCreateSchoolAdminData extends CreateSchoolAdminData {
  school: School,
  district_group_id: number,
}

export type CreateSchoolAdminErrors = { [key: string]: string };


@Component({
  selector: 'bc-accounts-admins-school',
  templateUrl: './bc-accounts-admins-school.component.html',
  styleUrls: ['./bc-accounts-admins-school.component.scss']
})
export class BcAccountsAdminsSchoolComponent implements OnInit, OnChanges {


  @Input() testWindow: TestWindow;
  @Input() districtDetail: DistrictDetail;
  @Input() schoolDetail: SchoolDetail;
  @Input() accountType: AccountType;


  nameSearch = "";
  nameSearchPlaceHolder: string;

  pagination: Pagination;
  rows: SaAccountsSchoolAdminRow[] = [];
  filteredRows: SaAccountsSchoolAdminRow[] = [];

  roles: AdminRole[] = [null, AdminRole.ADMINISTRATOR, AdminRole.SCORE_ENTRY];

  selectAll: boolean = false;

  isLoading = false;

  // invitation
  shouldOpenNewAdminModal: boolean = false;
  modalCreateSchoolAdminData: ModalCreateSchoolAdminData;
  createSchoolAdminErrors: CreateSchoolAdminErrors;
  schools: School[] = [];


  // all schools selected
  @ViewChild(BcPaginatedTableComponent) paginatedTable: BcPaginatedTableComponent<SchoolAdminRow>;
  columnHeadings: IColumnHeading[] = [];
  showAdminInfo: boolean = false;
  tableColumnWidths: number[] = [];
  allSchoolsPagination: Pagination;
  FilterCondition = FilterCondition;
  showAdminInfoToggle: Toggle;
  zebraField = 'school_code';

  constructor(
    private auth: AuthService,
    private lang: LangService,
    private bcAccounts: BcAccountsService,
    private loginGuard: LoginGuardService,
  ) {
    this.pagination = this.bcAccounts.getInitialPagination();
    this.allSchoolsPagination = this.bcAccounts.getInitialPagination();
    this.showAdminInfoToggle = {
      tra: 'sa_dist_admin_show_admin_info_toggle',
    };
    this.onToggleShowAdminInfo(false);
    this.nameSearchPlaceHolder = this.lang.tra("da_sa_searchAccountName");
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.testWindow || changes.schoolDetail || changes.districtDetail || changes.accountType) {
      this.updateTable();
    }

    if (changes.districtDetail && this.districtDetail) {
      this.bcAccounts.findSchools(this.districtDetail.groupId).then(schools => {
        this.schools = schools.slice(1);
      })
    }
  }

  isScoreEntrySchoolAdmin(): boolean {
    if (this.accountType) return this.auth.isScoreEntrySchoolAdmin(this.accountType);
    return true;
  }

  searchByName(e) { //
    const value = e.target.value.toLocaleLowerCase();
    if (value == "") {
      this.filteredRows = this.rows;
    } else {
      this.filteredRows = this.rows.filter((row) => (row.last_name.toLocaleLowerCase().includes(value) || row.first_name.toLocaleLowerCase().includes(value)));
    }
  }

  onPaginationChange() {
    this.updateTable();
  }

  private updateTable() {
    if (!this.districtDetail || !this.schoolDetail || !this.pagination) return;

    this.isLoading = true;
    this.bcAccounts.findSchoolAdmins(
      this.pagination,
      this.districtDetail.groupId,
      this.schoolDetail.groupId == -1 ? undefined : this.schoolDetail.groupId,
      AssessmentType.FSA,
      true,
    ).then(({ data, count }) => {
      this.rows = data.map(d => {
        let status: string;
        let is_pending: boolean = false;
        let is_revoked: boolean = false;
        if (d.is_revoked) {
          status = this.lang.tra('da_sa_status_revoked');
          is_revoked = true;
        } else if (d.invite_used_by_uid && d.invite_used_on) {
          let translated = this.lang.tra('da_sa_status_access_updated');
          status = translated + ` ${d.invite_used_on.format('MMM. D, YYYY')}`;
        } else if (d.invite_created_on) {
          let translated = this.lang.tra('da_sa_status_pending');
          status = translated + ` ${d.invite_created_on.format('MMM. D, YYYY')}`
          is_pending = true;
        } else {
          status = this.lang.tra('da_sa_status_default');
        }
        let role = this.getRoleForAccountType(d.account_type);
        return {
          uid: d.uid,
          first_name: d.first_name,
          last_name: d.last_name,
          contact_email: d.email,
          status,
          role,
          editedRole: role,
          is_pending,
          selected: false,
          invited: d.invite_created_on !== null && d.invite_created_on !== undefined,
          revoked: is_revoked,
        };
      });
      this.pagination.count = count;
      this.filteredRows = this.rows;
      this.isLoading = false;
    })
  }


  getRoleForAccountType(accountType: AccountType): AdminRole {
    if (accountType === AccountType.BC_FSA_SCHOOL_ADMIN_SCORE_ENTRY) {
      return AdminRole.SCORE_ENTRY;
    } else {
      return AdminRole.ADMINISTRATOR;
    }
  }

  onSelectAllChange() {
    this.rows.map(r => r.selected = this.selectAll);
  }

  onSelectChange(row: SaAccountsSchoolAdminRow) {
    if (this.rows.filter(r => r.selected).length === this.rows.length) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }
  }

  getNameDisplay(row: SaAccountsSchoolAdminRow): string {
    return `${row.first_name} ${row.last_name}`;
  }

  onSelectedRoleChange(row: SaAccountsSchoolAdminRow) {

  }

  onSelectedSchoolForInviteChange(event) {

  }

  shouldDisableSaveButtonForTable(row: SaAccountsSchoolAdminRow): boolean {
    if (row) {
      if (!row.editedRole) return true;
      return row.editedRole === row.role || row.revoked;
    }
    return true;
  }

  saveRole(row: SaAccountsSchoolAdminRow) {
    this.bcAccounts.patchSchoolAdminRole(row.uid, row.editedRole).then(() => {
      this.updateTable();
    })
  }

  cancelRole(row: SaAccountsSchoolAdminRow) {
    row.editedRole = row.role;
  }

  newInviteClicked() {
    this.openNewInviteModal();
  }

  async revokeAccessClicked() {
    let promises = this.rows.filter(r => r.selected).map(r => {
      return this.bcAccounts.revokeSchoolAdminAccess(r.uid, this.schoolDetail.groupId);
    });

    await Promise.all(promises);
    this.updateTable();
  }

  openNewInviteModal() {
    this.createSchoolAdminErrors = {};
    this.modalCreateSchoolAdminData = {
      first_name: '',
      last_name: '',
      role: null,
      email: '',
      school: cloneDeep(this.schoolDetail),
      school_group_id: -1,
      district_group_id: -1,
    };
    this.shouldOpenNewAdminModal = true;
  }

  closeNewInviteModal() {
    this.shouldOpenNewAdminModal = false;
  }

  saveNewInviteModal() {
    this.createSchoolAdminErrors = this.validateCreateSchoolAdminData(this.modalCreateSchoolAdminData);
    if (!isEmpty(this.createSchoolAdminErrors)) {
      return;
    }

    const createSchoolAdminData = this.makeCreateSchoolAdminData(this.modalCreateSchoolAdminData)
    this.bcAccounts.createSchoolAdmin(createSchoolAdminData).then(data => {
      this.loginGuard.quickPopup("Invitation Sent to " + createSchoolAdminData.email + '.');
      this.closeNewInviteModal();
      this.updateTable();
    });
  }

  makeCreateSchoolAdminData(data: ModalCreateSchoolAdminData): CreateSchoolAdminData {
    const {
      first_name,
      last_name,
      email,
      role,
      school,
    } = data;

    return {
      role,
      first_name,
      last_name,
      email,
      school_group_id: school.groupId,
    };
  }

  validateCreateSchoolAdminData(data: ModalCreateSchoolAdminData): CreateSchoolAdminErrors {
    const {
      first_name,
      last_name,
      email,
      role,
      school,
    } = data;

    const errors: CreateSchoolAdminErrors = {};

    if (first_name === '') {
      errors.first_name = 'Please provide a first name.';
    }

    if (last_name === '') {
      errors.last_name = 'Please provide a last name.';
    }

    // using rfc2822 email regex
    const rfc5322EmailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    if (email === '') {
      errors.email = 'Please provide an email.';
    }
    else if (rfc5322EmailRegex.test(email) === false) {
      errors.email = "This email is invalid.";
    }

    if (!this.roles.includes(role) || role == null) {
      errors.role = 'This role is invalid.'
    };

    if (!school || school.groupId == -1) {
      errors.school = 'Please select a school.';
    };

    return errors;
  }

  shouldDisableNewInviteSave(): boolean {
    return !isEmpty(this.validateCreateSchoolAdminData(this.modalCreateSchoolAdminData));
  }

  shouldDisableRevokeButton() {
    return this.rows.filter(r => r.selected).length === 0;
  }

  isSchoolAdmin(): boolean {
    return this.auth.isSchoolAdmin(this.accountType);
  }

  isDistrictAdmin(): boolean {
    return this.auth.isDistrictAdmin(this.accountType);
  }

  getDistrictDisplay = (district: DistrictDetail): string => {
    if (!district) return '';
    return this.bcAccounts.getDistrictDisplay({
      groupId: district.groupId,
      foreignId: district.foreignId,
      name: district.name,
    });
  }

  getSchoolDisplay = (school: School): string => {
    if (!school) return '';
    return this.bcAccounts.getSchoolDisplay(school);
  }

  isSelectedAllSchools(): boolean {
    return !this.schoolDetail || this.schoolDetail.groupId < 0;
  }
  private makeColumnHeading(): IColumnHeading[] {
    let headings: IColumnHeading[] = [
      { heading: this.lang.tra('sa_sr_school_name'), sortBy: 'school_name', onlyAdmin: false },
      { heading: this.lang.tra('sa_lfname'), sortBy: 'first_name', onlyAdmin: true },//admin
      { heading: this.lang.tra('sa_llname'), sortBy: 'last_name', onlyAdmin: true },//admin
      { heading: 'Title', sortBy: 'title', onlyAdmin: true },//admin
      { heading: this.lang.tra('sa_sr_school_code'), sortBy: 'school_code', onlyAdmin: false },
      { heading: this.lang.tra('sa_sr_school_type'), sortBy: 'school_type', onlyAdmin: false },
      { heading: this.lang.tra('sa_aa_district'), sortBy: 'district', onlyAdmin: false },
    ];
    if (!this.showAdminInfo) {
      headings = headings.filter(h => !h.onlyAdmin);
    }
    return headings;
  }

  private makeColumnWidths(): number[] {
    if (this.showAdminInfo) {
      return [200, 200, 200, 200, 200, 400, 150];
    } else {
      return [200, 200, 400, 150];
    }
  }

  onToggleShowAdminInfo(flag: boolean) {
    this.showAdminInfo = flag;
    this.columnHeadings = this.makeColumnHeading();
    this.tableColumnWidths = this.makeColumnWidths();
    if (this.paginatedTable) {
      this.paginatedTable.updateTable();
    }
  }

  getRows = (pagination: Pagination): Promise<PaginatedRows<SchoolAdminRow>> => {
    if (!this.districtDetail) return Promise.resolve({
      data: [],
      count: 0,
    });

    return this.bcAccounts.findSchoolAdmins(
      pagination,
      this.districtDetail.groupId,
      !this.schoolDetail || this.schoolDetail.groupId === -1 ? undefined : this.schoolDetail.groupId,
      AssessmentType.FSA,
      this.showAdminInfo,
    );
  }

  getDisplay = (by: string, row: SchoolAdminRow): string => {
    let data = row[by];
    if (data == null) return '';
    return data.toString();
  }
}
