import * as PIXI from "pixi.js-legacy";
import { IContentElementVirtualTools, IEntryStateVirtualTools } from "../model";
import { EToolType, VirtualTools } from "./virtual-tools";
import * as _ from "lodash";
import { SpriteLoader } from "../../element-render-custom-interaction/controllers/sprite-loader";
import { IExtendedTools, paletteColors } from "../element-render-virtual-tools.component";
import { ENamingTypes, EPixiTools, IDrawnMeta } from "../types/types";
import { LAYER_LEVEL } from "../types/constants";
import { QuestionState } from "../../models";

const COUNTER_DEFAULT_COLOR = '#FFA500';

export class Counter extends VirtualTools {
  element: IContentElementVirtualTools;
  spriteLoader: SpriteLoader
  stage: PIXI.Container;
  backgroundSprite: PIXI.Sprite
  counters: PIXI.Graphics[];
  counterColor: string;
  counterTools: IExtendedTools

  // // 512
  protractorPng = "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/protractor/1681409765420/protractor.png";
  rulerPng = "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/ruler/1681409782957/ruler.png"
  // // 64
  // protractorPng = "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/protractor-3/1681412875326/protractor-3.png";
  // rulerPng = "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/ruler-3/1681412986079/ruler-3.png"

  onColorSelection: (color: string) => {};

  constructor(
    questionState: QuestionState,
    element: IContentElementVirtualTools,  
    addGraphic, 
    render,
    getToolStateSub,
    stage,
    isLocked, 
    textToSpeech,
    isGlobalRotating: boolean, 
    isGlobalDragging: boolean,
    backgroundSprite: PIXI.Sprite,
    layerLevel: LAYER_LEVEL,
    onColorSelection
  ) {
    super(questionState, addGraphic, render, getToolStateSub, stage, isLocked, textToSpeech, isGlobalRotating, isGlobalDragging);
    this.initTool({name: EPixiTools.COUNTER, type: EToolType.TOGGLE, layerLevel: layerLevel});

    this.onColorSelection = onColorSelection;

    this.element = element;
    this.questionState = questionState;    this.element = element;
    this.backgroundSprite = backgroundSprite;
    this.spriteLoader = new SpriteLoader();
    this.counters = [];
    this.counterColor = PIXI.utils.hex2string(this.selectedColor) || '#ffce18';
    
    // Initialize config variables

    //#stopping Ticker
    PIXI.Ticker.system.autoStart = false
    PIXI.Ticker.system.stop()

    // Add stage listener
    this.addCounterListener();

    // this.counterTools = {
    //   parent: 'counter',
    //   colors: [
    //     this.element.counterColor || '#ffce18',
    //     '#c43f2e',
    //     '#2a91d6'
    //   ],        
    //   colorHandler: (color: string) => {
    //     this.counterColor = color;
    //   },
    //   tools: [
    //   {
    //     id: 'delete',
    //     action: () => this.clearCounters(),
    //     isSelected: () => false,
    //     iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/delete/1684351529074/delete.png',
    //   },
    //   {
    //     id: 'colorPicker',
    //     isColorPick: true,
    //     colorController: this.counterColor,
    //     isSelected: () => false,
    //     action: () => {},
    //   },
    // ]}

    this.initCounterManager();

    // Render scene
    this.render();
  }

  countersManager: IDrawnMeta[][] = [];
  initCounterManager() {
    paletteColors.forEach((color) => {
      this.countersManager.push([]);
    })
  }

  insertCounter(x: number, y:number) {
    const counter: IDrawnMeta = {
        points: [{x, y}],
        color: this.selectedColor,
        zIndex: this.getContainerZindex(7),
        fillOpacity: this.element.counterOpacity || 0.8
    }

    // Find the corresponding countersManager array and insert it there
    const counterGroup = paletteColors.findIndex(color => color.toLowerCase() === PIXI.utils.hex2string(this.selectedColor).toLowerCase());

    this.countersManager[counterGroup].push(counter);

    this.updateState();
  }

  drawCounters() {
    const counterNumberColor = this.getParsedColor(this.element.counterNumberColor || '#000000');
    
    this.counters.forEach(counter => this.removeContainer(counter));
    this.containerId = 0;

    this.countersManager.forEach((counterGroup, group) => {
      for(let i=0; i<counterGroup.length; i++) {
        const counterColor = counterGroup[i].color;
        const newCounter = new PIXI.Graphics();
        const number = new PIXI.Text((i+1).toString(), {
            fontSize: this.element.counterRadius * 0.75,
            fill: counterNumberColor,
            opacity: this.element.counterOpacity || 0.8
        });
        number.x = counterGroup[i].points[0].x - (number.width/2);
        number.y = counterGroup[i].points[0].y - (number.height/2);
        newCounter.addChild(number);
        
        newCounter.beginFill(counterColor, counterGroup[i].fillOpacity);
        newCounter.drawCircle(counterGroup[i].points[0].x, counterGroup[i].points[0].y, this.element.counterRadius);
        newCounter.endFill();
        newCounter.cursor = 'pointer';
        newCounter.interactive = true;
        newCounter.name = this.getName(ENamingTypes.CONTAINER);
        newCounter.zIndex = counterGroup[i].zIndex;
        this.addDragListener(newCounter, i, group);
        const point = {x: newCounter.x, y: newCounter.y};
        this.addContainer(newCounter, {
          points: [point],
          _isSelected: false,
          color: counterNumberColor,
          fillOpacity: this.element.counterOpacity || 0.8
        })
        this.counters.push(newCounter);
      }
    })
    this.render();
  }

  isCounterMode = false;
  lastSelectedCounterColor: number;
  selectedColorBeforeToggle: number;
  toggleCounterMode(mode?: boolean) {
    this.isCounterMode = mode == null ? !this.isCounterMode : mode;
    if(!this.isCounterMode) {
        this.stage.cursor = 'default';
        this.backgroundSprite.cursor = 'default';

        // Set the gloabl color back to the color before entering the counter mode
        this.onColorSelection(PIXI.utils.hex2string(this.selectedColorBeforeToggle))
    } else {
        this.stage.cursor = 'pointer';
        this.backgroundSprite.cursor = 'pointer';
        this.selectedColorBeforeToggle = this.selectedColor;

        // Use orange as default if using the counter the first time, continue using the last counter color if not first time
        if (this.lastSelectedCounterColor) 
          this.onColorSelection(PIXI.utils.hex2string(this.lastSelectedCounterColor));
        else
          this.onColorSelection(COUNTER_DEFAULT_COLOR);
    }
    this.render(); 
  
    return this.isCounterMode;
  }

  addCounterListener() {
    const addCounter = (e) => {
        if(this.isCounterMode) {
            const mousePosition = e.data.getLocalPosition(this.stage);
            this.insertCounter(mousePosition.x, mousePosition.y);
            this.drawCounters();
        }
    }

    this.stage.on('pointerup', addCounter);
  }

  isAnyDragging = false;
  addDragListener(obj: PIXI.Graphics, index: number, group: number) {
    let initialDiffX = 0;
    let initialDiffY = 0;
    let endingX = 0;
    let endingY = 0;
    let isDragStart = false;
    let isDragging = false;

    const onDragStart = (e) => {
      if(this.isCounterMode) {
        const mousePosition = e.data.getLocalPosition(this.stage);
        isDragStart = true;
        initialDiffX = mousePosition.x - obj.x
        initialDiffY = mousePosition.y - obj.y
        obj.cursor = 'grabbing';
      }
    }

    const onDragEnd = (e) => {
      if(this.isCounterMode) {
        if(!isDragging && !this.isAnyDragging) {
          // delete counter
          this.countersManager[group].splice(index, 1);
          this.drawCounters();
        } else {
          // Update location
          const originalX = this.countersManager[group][index].points[0].x
          const originalY = this.countersManager[group][index].points[0].y
          this.countersManager[group][index].points[0].x += obj.x;
          this.countersManager[group][index].points[0].y += obj.y;

          // Collision detection
          for(let i=0; i<this.countersManager[group].length; i++) {
            const distanceX = Math.pow(this.countersManager[group][index].points[0].x - this.countersManager[group][i].points[0].x, 2);
            const distanceY = Math.pow(this.countersManager[group][index].points[0].y - this.countersManager[group][i].points[0].y, 2);
            const distance = Math.sqrt(distanceX + distanceY)

            if(distance <= this.element.counterRadius * 2 && i != index) {
              this.countersManager[group][index].points[0].x = originalX;
              this.countersManager[group][index].points[0].y = originalY;
            }
          }
          if(this.countersManager[group][index].points[0].x < 0) {
            this.countersManager[group][index].points[0].x = originalX;
          }
          if(this.countersManager[group][index].points[0].y < 0) {
            this.countersManager[group][index].points[0].y = originalY;
          }
          if(this.countersManager[group][index].points[0].x > this.element.canvasWidth) {
            this.countersManager[group][index].points[0].x = originalX;
          }
          if(this.countersManager[group][index].points[0].y > this.element.canvasHeight) {
            this.countersManager[group][index].points[0].y = originalY;
          }
        }

        isDragging = false;
        isDragStart = false;
        this.isAnyDragging = false;
        obj.cursor = 'grab';

        this.updateState();
        this.drawCounters();
      }
    }
    
    const onDragMove = (e: PIXI.InteractionEvent) => {
        if(isDragStart && this.isCounterMode) {
            this.isAnyDragging = true;
            isDragging = true;
            const mousePosition = e.data.getLocalPosition(this.stage);
            obj.x = endingX = mousePosition.x - initialDiffX;
            obj.y = endingY = mousePosition.y - initialDiffY;
            this.render();
        }
    }

    obj.on('pointerdown', onDragStart)
    .on('pointerup', onDragEnd)
    .on('pointerupoutside', onDragEnd)
    .on('pointermove', onDragMove)
  }
  
  clearCounters() {
    this.counters.forEach(counter => this.removeContainer(counter));
    this.counters = [];
    this.countersManager.forEach((group, index) => {
      this.countersManager[index] = [];
    });

    this.updateState();
    this.render();
  }

  getCounterTools() {
    return this.counterTools
  }


  loadAssets() {
    return this.spriteLoader.loadSprites()
  }

  getUpdatedState(entry: Partial<IEntryStateVirtualTools>): Partial<IEntryStateVirtualTools> {
    entry.data[EPixiTools.COUNTER] = JSON.parse(JSON.stringify(this.countersManager));
    return entry;
  }

  handleNewState() {
    const data = this.getQuestionState(EPixiTools.COUNTER);
    if(!data || data.length == 0) {
      return; 
    }

    this.countersManager = data;
    
    this.drawCounters();
  }
  changeDrawnGraphicColor(){
    this.counterColor = PIXI.utils.hex2string(this.selectedColor);
  }

  
}
