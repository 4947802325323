<div style="display:flex; flex-direction:row; justify-content: space-between;">
    <menu-bar style="flex-grow:1" [menuTabs]="documentViews" (change)="selectDocumentView.emit($event)" [tabIdInit]="selectedDocumentId" [isDocumentSheet]="true" [isNestedView]="isRefDocumentsNestedView"></menu-bar>
    <a *ngIf="showCloseButton" style="font-size:1.4em;" (click)="toggleDocuments.emit()">&times;</a>
</div>
<div *ngIf="activeDocument" [class.add-padding]="addPadding">
    <question-runner 
        [currentQuestion]="activeDocument" 
        [containingWidth]="containingWidth"
        [containingWidthSpill]="containingWidthSpill"
        [allowSubtitles]="allowSubtitles"
        [allowTranscripts]="allowTranscripts"
        [allowAudioPlaybackSpeed]="allowAudioPlaybackSpeed"
        [allowVideoPlaybackSpeed]="allowVideoPlaybackSpeed"
    ></question-runner>
</div>
