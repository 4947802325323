import { NgModule } from '@angular/core';
import {Routes, RouterModule, ExtraOptions} from '@angular/router';

import { ViewCreateAccountComponent } from './ui-testadmin/view-create-account/view-create-account.component';
import { AccountType } from './constants/account-types';
import { ViewIndexComponent } from './ui-landing/view-index/view-index.component';
import { ViewTcDashboardComponent } from './ui-testctrl/view-tc-dashboard/view-tc-dashboard.component';
import { ViewDashboardComponent } from './ui-testadmin/view-dashboard/view-dashboard.component';
import { ViewTrackingComponent } from './ui-dist-admin/view-tracking/view-tracking.component';
import { ViewLoginPortalComponent } from './ui-landing/view-login-portal/view-login-portal.component';

// import {ViewLoginAssessmentsComponent} from './ui-landing/view-login-assessments/view-login-assessments.component';

const routes: Routes = [

  { path: '', component: ViewIndexComponent },

  // { path: `:lang`, component: ViewLoginAssessmentsComponent}, // TEMP

  { path: `:lang/login-portal`, component: ViewLoginPortalComponent },
  { path: `:lang/general/create-account`, component: ViewCreateAccountComponent },

  // { path: `:lang/field/:assessmentSlug`, component: FieldTestLoginComponent }, // temp


  { path: `:lang/${AccountType.TEST_ADMIN}/dashboard`, component: ViewDashboardComponent } ,
  { path: `:lang/${AccountType.TEST_CTRL}/dashboard`, component: ViewTcDashboardComponent },
  { path: `:lang/ministry-admin/bc-grad/session_reports/field_test_logins`, component: ViewTrackingComponent },
  { path: `:lang/ministry-admin/dashboard`, component: ViewTrackingComponent },

  //286685862


  { path: 'mpt-launch', loadChildren: () => import('./ui-mpt-launcher/ui-mpt-launcher-routing.module').then(m => m.UiMptLauncherRoutingModule)},
  // { path: `vretta-ens/home`, component: ViewEnsHomepageComponent },
  { path: 'vretta-ens', loadChildren: () => import('./ui-whitelabel-ens/ui-whitelabel-ens.module').then(m => m.UiWhitelabelEnsModule)},
  { path: 'lang', loadChildren: () => import('./ui-trans/ui-trans-routing.module').then(m => m.UiTransRoutingModule)},
  { path: `:lang`, loadChildren: () => import('./ui-landing/ui-landing.module').then(m => m.UiLandingModule)},
  { path: `:lang/bced-landing`, loadChildren: () => import('./ui-whitelabel-bc-landing/ui-whitelabel-bc-landing.module').then(m => m.UiWhitelabelBcLandingModule)},
  { path: `:lang/${AccountType.CERT_BODY}`,      loadChildren: () => import('./ui-testcert/ui-testcert.module').then(m => m.UiTestcertModule) },
  { path: `:lang/${AccountType.DIST_ADMIN}`,     loadChildren: () => import('./ui-dist-admin/ui-dist-admin.module').then(m => m.UiDistAdminModule) },
  { path: `:lang/${AccountType.EDUCATOR}`,       loadChildren: () => import('./ui-teacher/ui-teacher.module').then(m => m.UiTeacherModule) },
  { path: `:lang/${AccountType.MINISTRY_ADMIN}`, loadChildren: () => import('./ui-ministryadmin/ui-ministryadmin.module').then(m => m.UiMinistryadminModule)},
  { path: `:lang/${AccountType.MRKG_CAND}`,      loadChildren: () => import('./ui-mrkg-cand/ui-mrkg-cand.module').then(m => m.UiMrkgCandModule) },
  { path: `:lang/${AccountType.SCHOOL_DISTRICT_CURRI}`, loadChildren: () => import('./ui-board-lead/ui-board-lead.module').then(m => m.BoardLeadModule) },
  { path: `:lang/${AccountType.SCHL_DISCT_CURR_ELE}`,  loadChildren: () => import('./ui-board-lead/ui-board-lead.module').then(m => m.BoardLeadModule) },
  { path: `:lang/${AccountType.SCHL_DISCT_CURR_SEC}`, loadChildren: () => import('./ui-board-lead/ui-board-lead.module').then(m => m.BoardLeadModule) },
  { path: `:lang/${AccountType.MRKG_COORD}`,     loadChildren: () => import('./ui-mrkg-coord/ui-mrkg-coord.module').then(m => m.UiMrkgCoordModule) },
  { path: `:lang/${AccountType.MRKG_CTRL}`,      loadChildren: () => import('./ui-mrkg-ctrl/ui-mrkg-ctrl.module').then(m => m.UiMrkgCtrlModule) },
  { path: `:lang/${AccountType.MRKG_LEAD}`,      loadChildren: () => import('./ui-mrkg-leader/ui-mrkg-leader.module').then(m => m.UiMrkgLeaderModule) },
  { path: `:lang/${AccountType.MRKG_MRKR}`,      loadChildren: () => import('./ui-mrkg-marker/ui-mrkg-marker.module').then(m => m.UiMrkgMarkerModule) },
  { path: `:lang/${AccountType.MRKG_SUPR}`,      loadChildren: () => import('./ui-mrkg-supr/ui-mrkg-supr.module').then(m => m.UiMrkgSuprModule) },
  { path: `:lang/${AccountType.MRKG_UPLD}`,      loadChildren: () => import('./ui-mrkg-upld/ui-mrkg-upld.module').then(m => m.UiMrkgUpldModule) },
  { path: `:lang/${AccountType.PAYMENT_CTRL}`,   loadChildren: () => import('./ui-payment-ctrl/ui-payment-ctrl.module').then(m => m.UiPaymentCtrlModule) },
  { path: `:lang/${AccountType.SCHOOL_ADMIN}`,   loadChildren: () => import('./ui-schooladmin/ui-schooladmin.module').then(m => m.UiSchooladminModule)},
  { path: `:lang/${AccountType.SCOR_LEAD}`,      loadChildren: () => import('./ui-scoring-leader/ui-scoring-leader.module').then(m => m.UiScoringLeaderModule) },
  { path: `:lang/${AccountType.SCAN_REVW}`,      loadChildren: () => import('./ui-scan-revw/ui-scan-revw.module').then(m => m.UiScanRevwModule) },
  { path: `:lang/${AccountType.SCOR_SCOR}`,      loadChildren: () => import('./ui-scorer/ui-scorer.module').then(m => m.UiScorerModule) },
  { path: `:lang/${AccountType.SCOR_SUPR}`,      loadChildren: () => import('./ui-scoring-super/ui-scoring-super.module').then(m => m.UiScoringSuperModule) },
  { path: `:lang/${AccountType.STUDENT}`,        loadChildren: () => import('./ui-student/ui-student.module').then(m => m.UiStudentModule)},
  { path: `:lang/${AccountType.SUPPORT}`,        loadChildren: () => import('./ui-support/ui-support.module').then(m => m.UiSupportModule) },
  { path: `:lang/${AccountType.TEST_ADMIN}`,     loadChildren: () => import('./ui-testadmin/ui-testadmin.module').then(m => m.UiTestadminModule) },
  { path: `:lang/${AccountType.TEST_AUTH}`,      loadChildren: () => import('./ui-item-maker/ui-item-maker.module').then(m => m.UiItemMakerModule) },
  { path: `:lang/${AccountType.TEST_CTRL}`,      loadChildren: () => import('./ui-testctrl/ui-testctrl.module').then(m => m.UiTestctrlModule) },
  { path: `:lang/${AccountType.TEST_CTRLD}`,     loadChildren: () => import('./ui-testctrl-data/ui-testctrl-data.module').then(m => m.UiTestctrlDataModule) },
  { path: `:lang/${AccountType.TEST_TAKER}`,     loadChildren: () => import('./ui-testtaker/ui-testtaker.module').then(m => m.UiTesttakerModule) },
  { path: `:lang/${AccountType.SCOR_RAFI}`,      loadChildren: () => import('./ui-scoring-leader/ui-scoring-leader.module').then(m => m.UiScoringLeaderModule) },
  { path: `:lang/chat`, loadChildren: () => import('./ui-chat/ui-chat.module').then(m => m.UiChatModule) },
];

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
