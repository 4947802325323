import { getElementWeight, ElementType, QuestionState, IEntryState, IContentElementGridScatterPlot, intializeDefault, IEntryStateGridFill, IEntryStateGridScatterPlot, objectToMap, mapToObject } from "../../models";
import { QuestionPubSub } from "../../question-runner/pubsub/question-pubsub";
import { CustomInteractionCtrl } from "./custom-interaction";
import * as PIXI from "pixi.js-legacy";
import { Direction, ScoringTypes } from "../../models";
import { SpriteLoader } from "./sprite-loader";
import { AXIS, IGraphConfig, pixiGraph, PIXI_GRAPH_DEFAULTS } from "./util/pixi-graph";
import { drawCircle } from './util/drawShapes';
import { CustomInteractionType } from "../model";

export class GridScatterPlot extends CustomInteractionCtrl {
    
    element: IContentElementGridScatterPlot;
    container: PIXI.Container;
    grid: pixiGraph;
    pointsContainer: PIXI.Container;
    spriteLoader: SpriteLoader;
    value: Map<string, number[]>;
    isResponded: boolean;


    constructor(element: IContentElementGridScatterPlot, questionState: QuestionState, questionPubSub: QuestionPubSub, addGraphic, render, zoom, isLocked, removeGraphic, textToSpeech){
        super(questionState, questionPubSub, addGraphic, render, zoom, isLocked, textToSpeech);

        this.element = element;
        this._initializeDefault();
        this._initGraph();
        this.loadAssets().then(({resources}) => this._init())
    }

    private _init(){
        this.render();
    }

    private _initGraph(){
        const {canvasHeight} = this.element;
        let config = { ...PIXI_GRAPH_DEFAULTS, render: this.render, canvasHeight, lineColor: this.getColor(), isHiContrast: this.isHCMode() };
        for (let conf of Object.keys(config)){
            if(conf in this.element){
                config[conf] = this.element[conf];
            }
        }

        this.grid = new pixiGraph(<IGraphConfig>config);
        this.grid.interactive = true;
        this.grid.zIndex = 2;
        this.registerMouseEvents(this.grid);
        this.container.addChild(this.grid);
        
        this.pointsContainer = new PIXI.Container();
        this.grid.addChild(this.pointsContainer);
    }
    
    private _initializeDefault() {
        let defaults = {
            ...PIXI_GRAPH_DEFAULTS,
            isCircled: false,
        }
        
        intializeDefault(defaults, this.element);
        this.value = new Map();
        this.spriteLoader = new SpriteLoader();
        this.container = new PIXI.Container();
        this.container.zIndex = 2;
        this.addGraphic(this.container);
    }

    getUpdatedState() :Partial<IEntryStateGridScatterPlot> {
        const weight = getElementWeight(this.element);
        const {allCorrect, totalCorrect} = this._isCorrect();
        return {
            type: ElementType.CUSTOM_INTERACTION,
            subtype: CustomInteractionType.GRID_SCATTER_PLOT,
            value: mapToObject(this.value),
            isStarted: this.value !== undefined,
            isFilled: this.value.size > 0,
            isCorrect: allCorrect, 
            isResponded: this.isResponded,
            score: allCorrect ? weight : 0,
            weight: weight,
            scoring_type: ScoringTypes.AUTO
        };
    }

    private _isCorrect(){
        let totalCorrect = 0;
        for (const key of Object.keys(this.element.correctPoints)){
            if(this.value.has(key)){
                totalCorrect++;
            }
        }
        let allCorrect = totalCorrect === Object.keys(this.element.correctPoints).length;
        allCorrect = allCorrect && (this.value.size === Object.keys(this.element.correctPoints).length)
        return {allCorrect, totalCorrect}
    }

    handleNewState(): void {
        const qsValue: Map<string, number[]> = objectToMap(this.questionState[this.element.entryId].value);
        this.isResponded = this.questionState[this.element.entryId].isResponded;
        if(qsValue.size) {
            this.value = qsValue;
            qsValue.forEach((value, key) => {
                if(this.pointsContainer){
                    let x = value[0];
                    let y = value[1]
                    if(!this.pointsContainer.getChildByName(this._getName(x, y))){
                        if(this.grid){
                            this._addGraphics(
                              {coord: x, displayCoord: this.grid.xAxisData.get(x).displayCoord},
                              {coord: y, displayCoord: this.grid.yAxisData.get(y).displayCoord}
                            );
                        }
                    }
                }
            });
        }
    }

    loadAssets(): Promise<PIXI.Loader> {
        this.spriteLoader.addSpritestoLoader([]);
        return this.spriteLoader.loadSprites();
    }
    
    registerMouseEvents(obj) {
        obj.on("mousedown", $event => this.activateMouseDown($event, obj));
        obj.on("mouseup", $event => this.deactivateMouseDown($event));
        obj.on("mousemove", $event => this.changeLocation($event, obj));
        obj.on("mouseupoutside", $event => this.deactivateMouseDown($event));
    }


    activateMouseDown(event, obj: any) {
        const mousePos = event.data.getLocalPosition(this.container);
        let x,y;
        if(this.element.isSnapping){
            x = this.grid.getSnappedAxisData(AXIS.X, this.grid.xAxisData, mousePos.x);
            y = this.grid.getSnappedAxisData(AXIS.Y, this.grid.yAxisData, mousePos.y);
        }

        if(!this._validatePosition(x.displayCoord, y.displayCoord)) return;
    
        this.isResponded = true;
        if(this.value.has(this._getName(x.coord, y.coord))){
            this._removeGraphics(x.coord, y.coord);
        } else {
            this._addGraphics(x, y);
        }

        this.updateState();
    }

    deactivateMouseDown($event) {
        // throw new Error("Method not implemented.");
    }
    changeLocation($event, obj: any) {
        // throw new Error("Method not implemented.");
    }

    private _addGraphics(x, y){
        let {isPointCircled, pointColor, pointColorAlpha, pointRadius, circleRadius, circleColor, circleBorder} = this.element

        let point = drawCircle(x.displayCoord, y.displayCoord, pointRadius, {
          isFilled: true,
          color: pointColor,
          alpha: pointColorAlpha
        });
        point.name = this._getName(x.coord, y.coord);
        this.pointsContainer.addChild(point);

        if(isPointCircled){
            let circle = drawCircle(x.displayCoord, y.displayCoord, circleRadius, {
              isFilled: false,
              color: circleColor,
              width: circleBorder
            });
            circle.name = this._getName(x.coord, y.coord, true);
            this.pointsContainer.addChild(circle);
        }

        this.value.set(this._getName(x.coord, y.coord),[x.coord, y.coord]);
        this.render();
    }

    private _removeGraphics = (x: number, y: number) => {
        let point = this.pointsContainer.getChildByName(this._getName(x, y));
        if(this.element.isPointCircled){
            let circle = this.pointsContainer.getChildByName(this._getName(x, y, true));
            this.pointsContainer.removeChild(circle);
        } 
        this.pointsContainer.removeChild(point);
        this.value.delete(this._getName(x, y));
        this.render();
    }

    private _validatePosition(x,y){
        let isInsideGrid = x >= this.grid.xStart && x <= this.grid.xEnd && y <= this.grid.yStart && y >= this.grid.yEnd;
        return isInsideGrid;
    }

    private _getName(x, y, circle?: boolean){
        if(circle) return `circle(${x},${y})`;
        return `point(${x},${y})`;
    }
    
}

