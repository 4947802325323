<div class="modal-buttons">
    
    <button 
        [disabled]="pageModal.isSaving" 
        class="button" 
        *ngIf="!pageModal.getCurrentModal().isProceedOnly"
        (click)="pageModal.closeModal()"
    > <tra [slug]="closeMessage"></tra> </button>

    <button 
        *ngIf="confirmButton"
        [disabled]="isEditDisable || pageModal.isSaving" 
        class="button is-info" 
        (click)="confirmSubmission()"
    >
        <tra [slug]="pageModal.getCurrentModal().confirmationCaption || 'btn_ok'"></tra>
    </button>

</div>
