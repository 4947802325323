export const agGridNumberSort = (num1: number, num2: number) => {
    return num1 - num2;
};

export const arrObjectSort = (arr, prop: string) => {
    return arr.sort((a, b) => {
        const _a = a[prop]
        const _b = b[prop]
        if (_a < _b) {
            return -1;
        }

        if (_a > _b) {
            return 1;
        }

        return 0;
    });
}