import { IMenuTabConfig } from "../../../ui-partial/menu-bar/menu-bar.component";
import { LangService } from '../../../core/lang.service';
import { cloneDeep } from 'lodash';

export interface IView<T> extends IMenuTabConfig<T> {
  imgUrl: string,
  secondaryImgUrl?: string;
  description: string,
  hasIndicator?: boolean,
  disabled?: boolean;
}

export enum SchoolAdminView {
  DASHBOARD = 'dashboard',
  // DASHBOARD1 = 'dashboard1',
  TECH_READI = 'tech_readiness',
  TEACHERS = 'educators',
  STUDENTS = 'students',
  SESSIONS = 'sessions',
  QUESTIONNAIRE = 'questionnaire', 
  REPORTS = 'reports',
  PURCHASES = 'purchases',
  CLASSROOMS = 'classrooms',
  STATEMENT = 'statement',
  SCANNING = 'scanning',
  SCHOOL_ACCESS_PANEL = 'manage-my-school'
}

export enum BCFSASchoolAdminView {
  BC_FSA_TECH_READI = 'tech-readiness',
  BC_FSA_ASSESSMENTS = "assessment_setup1",
  BC_FSA_SESSION_INFO = 'bc_fsa_session_info',
  BC_FSA_ASSESSMENT_MATERIALS = 'assessment_materials',
  BC_FSA_STUDENTS = 'students',
  BC_FSA_SCHOOL_ADMINS = 'school_administrators',
  BC_FSA_FINAL_REPORT = "final_results",
  BC_FSA_COMPLETION_REPORTS = 'bc_fsa_completion_reports',
  BC_FSA_SCORE_ENTRY = 'score-entry',
  BC_FSA_SESSION_PROGRESS_REPORTS = 'session_reports',
}

export enum BCGradSchoolAdminView {
  BC_GRAD_TECH_READI = 'bc_grad_tech_readi',
  BC_GRAD_SESSION_INFO = 'bc_grad_session_info',  
  BC_GRAD_STUDENTS = 'bc_grad_students',
  BC_GRAD_COMPLETION_REPORTS = 'bc_grad_completion_reports',
  BC_GRAD_SCANNING = 'bc_grad_scanning',
}

export enum NBEDSchoolAdminView {
  NBED_DASHBOARD = 'dashboard',
  NBED_TECH_READI = 'tech_readiness',
  NBED_TEACHERS = 'educators',
  NBED_STUDENTS = 'students',
  NBED_SESSIONS = 'sessions',
  NBED_QUESTIONNAIRE = 'questionnaire', 
  NBED_REPORTS = 'reports',
  NBED_CLASSROOMS = 'classrooms',
  NBED_SCANNING = 'scanning',
}

export enum ABEDSchoolAdminView {
  ABED_DASHBOARD = 'dashboard',
  ABED_TECH_READI = 'tech_readiness',
  ABED_TEACHERS = 'educators',
  ABED_STUDENTS = 'students',
  ABED_SESSIONS = 'sessions',
  ABED_STATEMENT = 'statement',
  ABED_QUESTIONNAIRE = 'questionnaire', 
  ABED_REPORTS = 'reports',
  ABED_CLASSROOMS = 'classrooms',
  ABED_SCANNING = 'scanning',
}
  
export const SCHOOL_ADMIN_VIEWS = [
    {
      id:SchoolAdminView.TECH_READI, 
      caption: ('lbl_tech_readi'), //'Technical Readiness',
      imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/clipboard/1602235150476/clipboard.png',
      description: ('txt_tech_readi'), //'',
      whiteLabelContextFlag: 'IS_EQAO',
      hasIndicator: true,
    },
    {
      id:SchoolAdminView.CLASSROOMS, 
      caption: ('lbl_classes_groups'), //'Class Sections',
      imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/school/1602128112754/school.png',
      description: ('txt_sa_classrooms_info_1'), //'You can review and manage the classes as they progress with their introductory materials and administration of the assessment.',
    },
    {
      id:SchoolAdminView.TEACHERS, 
      caption: ('lbl_teachers'), //'Teachers',
      imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/work-from-home/1602128147290/work-from-home.png',
      description: ('txt_teachers'), // '0 accounts registered. Use this view to send email invitations to teachers so that they can access their account.',
    },
    {
      id:SchoolAdminView.STUDENTS, 
      caption: ('g9_students'), //'Students',
      imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/students/1602128010278/students.png',
      description: ('txt_students'), // '67 students are currently enrolled. Click here to validated their information and re-assign them to different teachers.',
      hasIndicator:true
    },
        
    {
      id:SchoolAdminView.SESSIONS, 
      caption: ('g9_sessions'), //'Assessment Sessions',
      imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/calendar/1602128832706/calendar.png',
      description: ('txt_sessions') //'No assessment sessions have yet been scheduled. No assessments sessions have been run. Teachers can schedule assessments from their dashboard.',
    },

    {
      id:SchoolAdminView.QUESTIONNAIRE, 
      caption: ('lbl_questionnaire'), // 'School Administrator Questionnaire'
      imgUrl: '../../../assets/article.png',
      description: ('teacher_questionnaire_complete') //' Complete Questionnaire by June 24'
    },

    {
      id:SchoolAdminView.REPORTS, 
      caption: ('lbl_reports'), //'Assessment Sessions',
      imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/report/1614338286078/report.png',
      description: ('txt_reports_description'), //'No assessment sessions have yet been scheduled. No assessments sessions have been run. Teachers can schedule assessments from their dashboard.',
      whiteLabelContextFlag: 'IS_EQAO',
    },

]

export const SCHOOL_ADMIN_VIEWS_OPTIONAL = [
    {
      id:SchoolAdminView.PURCHASES, 
      caption: ('lbl_purchases'), //'Purchases',
      imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2328649/authoring/purchases/1661365096864/purchases.png',
      description: ('txt_sa_purchases_description') //'Review Purchase Transactions',
    }
]

export const BC_FSA_SCHOOL_ADMIN_VIEWS = [
  {
    id:BCFSASchoolAdminView.BC_FSA_TECH_READI, 
    caption: ('sa_tech_readiness_bc'), //'Technical Readiness',
    imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/515714/authoring/Group_15__1_/1666112755552/Group_15__1_.png',
    description: ("sa_tech_readiness_desc_bc"),
    disabled: false,
  },
  {
    id: BCFSASchoolAdminView.BC_FSA_ASSESSMENT_MATERIALS,
    caption: ('sa_assessment_material_bc'), //'Assessment Materials',
    imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6388/authoring/assessment_materials/1610134259593/assessment_materials.svg',
    description: ('sa_assessment_material_desc_bc'),
    disabled: false,
  },
  {
    id: BCFSASchoolAdminView.BC_FSA_SCHOOL_ADMINS,
    caption: ('sa_admin_accounts_bc'), //'Administrator Accounts',
    imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/515714/authoring/Group_15/1666112729964/Group_15.png',
    description: ('sa_admin_accounts_desc_bc'),
    disabled: false,
  },
  {
    id: BCFSASchoolAdminView.BC_FSA_STUDENTS,
    caption: ('sa_students_bc'), //'Students',
    imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6388/authoring/student_info/1610134278044/student_info.svg',
    description: ('sa_students_desc_bc'),
    disabled: false,
  },
  {
    id: BCFSASchoolAdminView.BC_FSA_SESSION_PROGRESS_REPORTS,
    caption: ('sa_session_reports_bc'), //'Session Reports',
    imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6388/authoring/session_progress_reports/1610134294563/session_progress_reports.svg',
    description: ('sa_session_reports_desc_bc'),
    disabled: false,
  },
  {
    id: BCFSASchoolAdminView.BC_FSA_SCORE_ENTRY,
    caption: ('sa_score_entry_bc'), //'Session Entry',
    imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6526/authoring/Score Entry/1606258686121/Score Entry.png',
    description: ('sa_score_entry_desc_bc'), // '0 accounts registered. Use this view to send email invitations to teachers so that they can access their account.',
    disabled: false,
  },
  {
    id: BCFSASchoolAdminView.BC_FSA_FINAL_REPORT,
    caption: ('sa_results_reports_bc'), //'Final Results',
    imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6388/authoring/final_results/1610134339358/final_results.svg',
    description: ('sa_results_reports_desc_bc'), // '0 accounts registered. Use this view to send email invitations to teachers so that they can access their account.',
    disabled: false,
  }
]

// export const BC_FSA_SCHOOL_ADMIN_SCORE_ENTRY_VIEWS = cloneDeep(BC_FSA_SCHOOL_ADMIN_VIEWS);

export const BC_GRAD_SCHOOL_ADMIN_VIEWS = [
  {
    id:BCGradSchoolAdminView.BC_GRAD_TECH_READI, 
    caption: ('Tech Readiness'), //'Technical Readiness',
    imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6526/authoring/Technical Readiness/1606258416870/Technical Readiness.png',
    description: ('See this page to review the documentation required to ensure your school’s devices are ready for administering the assessment.'), //'',
    disabled: false
  },
  {
    id:BCGradSchoolAdminView.BC_GRAD_SESSION_INFO, 
    caption: ('Session Info'), //'Technical Readiness',
    imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6526/authoring/Session Information/1606258514776/Session Information.png',
    description: ('See this page to access the session passwords and view the administration manual.'), //'',
    disabled: false
  },
  {
    id:BCGradSchoolAdminView.BC_GRAD_STUDENTS, 
    caption: ('Students'), //'Students',
    imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6526/authoring/Students/1606258542435/Students.png',
    description: ('See this page to review the list of registered students, handle late registrations, and resolve issues.'), //
    disabled: false
  },
  {
    id:BCGradSchoolAdminView.BC_GRAD_COMPLETION_REPORTS, 
    caption: ('Completion Reports'), //'Technical Readiness',
    imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6526/authoring/Completion Reports/1606258648133/Completion Reports.png',
    description: ('See this page to monitor the assessment submissions at your school.'), //'',
    disabled: true,
  },
  {
    id:BCGradSchoolAdminView.BC_GRAD_SCANNING, 
    caption: ('Printing & Uploading Response Sheets'), //'Students',
    imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6526/authoring/Scanning/1606258703307/Scanning.png',
    description: ('See this page to print and upload scans of the personalized student response sheets for the Grade 10 Numeracy Assessment.'), // '67 students are currently enrolled. Click here to validated their information and re-assign them to different teachers.',
    disabled: false,
  },
]

export const NBED_SCHOOL_ADMIN_VIEWS = [
  {
    id:NBEDSchoolAdminView.NBED_TECH_READI, 
    caption: ('lbl_tech_readi'), //'Technical Readiness',
    imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/clipboard/1602235150476/clipboard.png',
    description: ('txt_tech_readi'), //'',
    whiteLabelContextFlag: 'IS_EQAO',
    hasIndicator: true,
  },
  {
    id:NBEDSchoolAdminView.NBED_CLASSROOMS, 
    caption: ('lbl_classes_groups_nbed'), //'Class Sections',
    imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/school/1602128112754/school.png',
    description: ('txt_sa_classrooms_info_1_nbed'), //'You can review and manage the classes as they progress with their introductory materials and administration of the assessment.',
  },
  {
    id:NBEDSchoolAdminView.NBED_TEACHERS, 
    caption: ('lbl_teachers_nbed'), //'Teachers',
    imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/work-from-home/1602128147290/work-from-home.png',
    description: ('txt_teachers'), // '0 accounts registered. Use this view to send email invitations to teachers so that they can access their account.',
  },
  {
    id:NBEDSchoolAdminView.NBED_STUDENTS, 
    caption: ('g9_students'), //'Students',
    imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/students/1602128010278/students.png',
    description: ('txt_students_nbed'), // '67 students are currently enrolled. Click here to validated their information and re-assign them to different teachers.',
    hasIndicator:true
  },
      
  {
    id:NBEDSchoolAdminView.NBED_SESSIONS, 
    caption: ('g9_sessions'), //'Assessment Sessions',
    imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/calendar/1602128832706/calendar.png',
    description: ('txt_sessions_nbed') //'No assessment sessions have yet been scheduled. No assessments sessions have been run. Teachers can schedule assessments from their dashboard.',
  },

  {
    id:NBEDSchoolAdminView.NBED_REPORTS, 
    caption: ('lbl_reports'), //'Assessment Sessions',
    imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/report/1614338286078/report.png',
    description: ('txt_reports_description'), //'No assessment sessions have yet been scheduled. No assessments sessions have been run. Teachers can schedule assessments from their dashboard.',
    whiteLabelContextFlag: 'IS_EQAO',
  },
]

export const ABED_SCHOOL_ADMIN_VIEWS = [
  {
    id: SchoolAdminView.TECH_READI,
    caption: ('abed_techre_title'), //'Technical Readiness',
    imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/clipboard/1602235150476/clipboard.png',
    description: ('abed_txt_tech_readi'), //'',
    whiteLabelContextFlag: "IS_ABED",
    hasIndicator: true
  },
  {
    id: ABEDSchoolAdminView.ABED_CLASSROOMS,
    caption: ('lbl_classes_groups_ABED'), //'Groupings'
    imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/school/1602128112754/school.png',
    description: ('txt_sa_classrooms_info_1_ABED'), //'You can review and manage groupings.'
    whiteLabelContextFlag: 'IS_ABED'
  },
  {
    id: ABEDSchoolAdminView.ABED_TEACHERS,
    caption: ('abed_teach_and_exsups'), //'Invigilators',
    imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/work-from-home/1602128147290/work-from-home.png',
    description: ('abed_txt_teachers')
  },
  {
    id: ABEDSchoolAdminView.ABED_STUDENTS,
    caption: ('g9_students'), //'Students',
    imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/students/1602128010278/students.png',
    description: ('abed_txt_students'), //
    whiteLabelContextFlag: 'IS_ABED',
    hasIndicator: true
  },
  {
    id: ABEDSchoolAdminView.ABED_SESSIONS,
    caption: ('abed_ass_sess'), //'Assessment Sessions',
    imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/calendar/1602128832706/calendar.png',
    description: ('abed_txt_sessions'), 
    whiteLabelContextFlag: 'IS_ABED'
  },
  // ---- Disabling Principal Statement view until further notice 
  // {
  //   id: ABEDSchoolAdminView.ABED_STATEMENT,
  //   caption: ('Principal Statement'), // todo:TRANS
  //   imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/report/1614338286078/report.png',
  //   description: ('Review student attendance and reported issues.'), //'No assessment sessions have yet been scheduled. No assessments sessions have been run. Teachers can schedule assessments from their dashboard.',
  //   whiteLabelContextFlag: 'IS_ABED'
  // }
];