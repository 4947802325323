import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, Subject } from 'rxjs';
import { PrintAltTextService } from 'src/app/ui-item-maker/print-alt-text.service';
import { defaultImageScaleFactor, IContentElementImage } from '../element-render-image/model';
import { TextToSpeechService } from '../text-to-speech.service';
import { BackgroundFillService } from '../background-fill.service';
import { HighlighterService } from '../highlighter.service';

@Component({
  selector: 'render-image',
  templateUrl: './render-image.component.html',
  styleUrls: ['./render-image.component.scss']
})
export class RenderImageComponent implements OnInit {

  @Input() element:IContentElementImage;
  @Input() scaleWidth:number = 1;
  @Input() scaleHeight:number = 1;
  @Input() parentElement?:IContentElementImage

  urlRaw:string;
  url:SafeResourceUrl;
  urlRawHighContrast:string;
  urlHighContrast:SafeResourceUrl;
  isMicrosoftEdgeActive:boolean = false

  constructor(
    private sanitizer: DomSanitizer,
    private printAltText: PrintAltTextService,
    public text2Speech: TextToSpeechService,
    public bgFillService: BackgroundFillService,
    public highlighter: HighlighterService
  ) { }

    scaleFactor;
    baseElement
  ngOnInit() {
    this.updateUrl();
    if (this.parentElement) {
      this.baseElement = this.parentElement
    } else {
      this.baseElement = this.element
    }
    document.addEventListener("dragstart", this.preventDrag)
    this.isMicrosoftEdgeActive = this.isMicrosoftEdge()
  }

  ngOnDestroy(){
    document.removeEventListener("dragstart", this.preventDrag)
  }

  ngDoCheck() {
    this.updateUrl();
    this.scaleFactor = defaultImageScaleFactor;
    if (!this.element.scaleFactor) {
      //This is the old scaleFactor which was not recorded in the element.  
      this.element.scaleFactor = 20;
    }
    /*if (defaultImageScaleFactor!=this.element.scaleFactor) {
      this.element.scale *= this.element.scaleFactor/defaultImageScaleFactor;
      this.element.scaleFactor = defaultImageScaleFactor;
    }*/
    //console.log(this.element.scale, defaultImageScaleFactor)

  }

  getScale(){
    let scaleFactor = defaultImageScaleFactor; //this.element.scaleFactor || defaultImageScaleFactor
    return (scaleFactor/100)*(this.element.scale)
  }

  updateUrl(){
    if (this.urlRaw !== this.element.url){
      this.urlRaw = this.element.url;
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlRaw);
    }
    if (this.element.hiContrastImg && this.urlRawHighContrast !== this.element.hiContrastImg.url){
      this.urlRawHighContrast = this.element.hiContrastImg.url;
      this.urlHighContrast = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlRawHighContrast);
    }
    if (this.urlRaw==null) {
      this.url = undefined
    }
    if (this.urlRawHighContrast==null) {
      this.urlHighContrast = undefined
    }
  }

  private checkURLNull(url:SafeResourceUrl) {
    if (url && url!="" && url !=null) {
      return true;
    }
    return false;
  }

  getImageUrl(){
    if (!this.text2Speech.isHiContrast || !this.baseElement.isHiContrastSensitive) {
      if (this.checkURLNull(this.url)) {
        return this.url
      }
    } else {
        if (this.checkURLNull(this.urlHighContrast)) {
          return this.urlHighContrast
        }
    }
    return undefined
  }

  showPrintModeAltText() {
    return this.printAltText.getAltTextVisible();
  }

  getStyle() {
    const style = {}
    if (this.baseElement.isNoInvertOnHiContrast && this.text2Speech.isHiContrast) {
      style["filter"]="invert(1)";
    }
    return style;
  }

  preventDrag(event){
    if(event.target.className === "display-image")
      event.preventDefault();
  }

  isMicrosoftEdge() {
    const userAgent = window.navigator.userAgent;
    return userAgent.indexOf("Edge") > -1;  
  }
}
