<ng-container>
  <!-- In most cases, render the original content as is -->
  <span #highlightableSpan *ngIf="!isHighlighted && !isDiv">
    <ng-container *ngTemplateOutlet="originalContent"></ng-container>
  </span>
  <div #highlightableDiv *ngIf="!isHighlighted && isDiv">
    <ng-container *ngTemplateOutlet="originalContent"></ng-container>
  </div>

  <!-- When highlighted in authoring and its a whole-string highlight -->
  <span *ngIf="(isHighlighted && isWholeHighlight) && !isDiv"  class="is-highlighted" [class.is-resolved-highlight]="isHighlighted && isResolvedHighlight">
    <ng-container *ngTemplateOutlet="originalContent"></ng-container>
  </span>
  <div *ngIf="(isHighlighted && isWholeHighlight) && isDiv" [class.is-image-highlighted]="isImage" [class.is-highlighted]="!isImage" [class.is-resolved-highlight]="isHighlighted && isResolvedHighlight">
    <ng-container *ngTemplateOutlet="originalContent"></ng-container>
  </div>

   <!-- When highlighted in authoring and its an interval highlight (start/end within the string) -->
  <span *ngIf="isHighlighted && !isWholeHighlight" class="is-mark-inline markdown-inline">
    <span *ngIf="preHighlightHtml" [innerHTML]="preHighlightHtml"></span>
    <span *ngIf="highlightHtml" class="is-highlighted" [class.is-resolved-highlight]="isResolvedHighlight" [innerHTML]="highlightHtml"></span>
    <span *ngIf="postHighlightHtml" [innerHTML]="postHighlightHtml"></span>
  </span>

  <ng-template #originalContent>
    <ng-content></ng-content>
  </ng-template>

</ng-container>