import { IContentElement, IEntryStateScored, IScoredResponse } from "../models";

export enum ValidatorMode {
    ALL_OR_NOTHING = "ALL_OR_NOTHING",
    NORMAL = "NORMAL",
    COMBINATION = "COMBINATIONS",
  }
  
  export interface IValidatorCombinationProp{
    validateId: number;
    elementType: string;
    correctValue?: string;
    dndElements?: Array<{
      targetId: string;
      correctValue: string;
      isOrderImp: boolean;
    }>
    
    score?: number  
  } 


export interface IContentElementValidator extends IValidatorCombinationProp, IContentElement, IScoredResponse {
    // validateId: number;
    // correctValue: string;
    validateProp: string;
    targetEntryIdList: string;
    combinations?: IValidatorCombinationProp[][]
    mode: ValidatorMode;
  }

export interface IEntryStateValidator extends IEntryStateScored {
    entryIds?: string;
    value?: number;
}

export const validatorEditInfo = {
  editExcludeFields: []
}