<div class="page-body">
    <header [breadcrumbPath]="breadcrumb" [hasSidebar]="true"></header>
    <div class="page-content">
        <div class="bc-accounts-admins">
            <bc-header-layout [tra]="'sa_admin_accounts_bc'"
                [imgSrc]="'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/504237/authoring/admin/1627063636881/admin.png'"
                [accountType]="accountType" [buttons]="extraButtons" (button)="extraButtonClicked($event)"
                (school)="onSelectedSchoolChange($event)" (district)="onSelectedDistrictChange($event)"
                (testWindow)="onSelectedTestWindowChange($event)">
            </bc-header-layout>

            <bc-tabs *ngIf="isDistrictAdmin()" [tabs]="tabs" (select)="onSelectedTabChange($event)"></bc-tabs>

            <bc-accounts-admins-school *ngIf="selectedTab != AccountTab.DISTRICT" [districtDetail]="districtDetail"
                [schoolDetail]="selectedSchool" [accountType]="accountType" [testWindow]="selectedTestWindow">
            </bc-accounts-admins-school>

            <bc-accounts-admins-district *ngIf="isDistrictAdmin() && selectedTab == AccountTab.DISTRICT"
                [districtDetail]="districtDetail" [schoolDetail]="selectedSchool" [accountType]="accountType"
                [testWindow]="selectedTestWindow">
            </bc-accounts-admins-district>
        </div>
    </div>
</div>