import { Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import { EditingDisabledService } from 'src/app/ui-item-maker/editing-disabled.service';

export enum ExpansionPanelContentType {
  JSON_VIEW = 'JsonView',
  TEXT_AREA = 'TextArea',
  SPAN_LIST = 'SpanList'
}

export interface CustomSpanData {
  text: string,
  style: any
}
export interface IExpansionPanelContent {
  type: ExpansionPanelContentType;
  label?: string;
  data: any;
}
@Component({
  selector: 'expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss']
})
export class ExpansionPanelComponent implements OnInit {

  @Input() title : string;
  @Input() subTitle: string
  @Input() description: string
  @Input() content: IExpansionPanelContent[];
  @Input() isNgContent:boolean;
  @Input() style: any;
  @Input() signalOpenClose: boolean = false;
  @Input() isExpanded: boolean = false;
  @Input() readOnly: boolean = false;

  @Output() opened =  new EventEmitter<boolean>();
  @Output() closed =  new EventEmitter<boolean>();

  ExpansionPanelContentType = ExpansionPanelContentType;

  constructor(
    private editingDisabled: EditingDisabledService
  ) {

  }

  ngOnInit(): void {
  }

  isReadOnly() {
    return this.editingDisabled.isReadOnly()
  }

  getBorderColor(){
    if(this.style && this.style['borderColor']){
      return this.style['borderColor']
    }
    return '#d3d3d3'
  }


  panelOpened(): void {
    if (this.signalOpenClose) this.opened.emit()
  }

  panelClosed(){
    if (this.signalOpenClose) this.closed.emit()
  }

}
