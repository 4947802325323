<!-- CLOSER_LOOK_20210807 despite significant conflicts in the merge, I could not find anything that was not in the incoming version, so I was able to use the incoming branch wholesale for this file  -->

<div
  #elPosContainer
  class="pos-container element-render-grouping"
  [style.width.em]="element.groupWidth ? element.groupWidth : 'auto' "
  [class.is-dragging]="isDragging"
>
  <div *ngIf="showInstr() && isHorizontal()" class="question-block-label mb-3" style="text-align: center; font-size:0.8em;"> 
    <tra-md [slug]="getInstrSlug()" [style.color]="getInstrColor()"></tra-md>
  </div>
  <div 
    cdkDropListGroup
    class="pos-container-sub" 
    [class.is-targets-above]="element.isTargetsAbove" 
    [class.is-horizontal]="isHorizontal()"
    [style.column-gap.em]="element.draggableTargetSpacing"
  >
    <div style="display: flex;">
      <div
        cdkDropList
        tabindex="0" (keyup.enter)="onEnterContainer($event, null, draggables, true)"
        (mousedown)="onClickToDragContainer($event, null, draggables, true)"
        class="drag-drop-list drag-drop-source mb-3 is-empty"
        [ngStyle]="getDDSourceStyle()"
        [class.not-manual]="!isManual()"
        [class.is-layout-horizontal] = "isManual() && element.isManualDraggableOrientationHorizontal"
        [cdkDropListData]="draggables"
        cdkDropListSortingDisabled
        (cdkDropListDropped)="drop($event, null, true); isDragging=false"
        (cdkDropListEntered)="isDragging=true"
        [cdkDropListOrientation]="isManual() && element.isManualDraggableOrientationHorizontal ? 'horizontal' : 'vertical' "
        [style.height.em]="isManual() && element.isDraggableContainerGone ? 0 : null"
        [class.is-horizontal]="isHorizontal()"
        [style.row-gap.em]="element.draggableVerticalSpacing"
      >
        <div 
          *ngFor="let elPos of draggables; index as idx" 
          tabindex="0" (keyup.enter)="onEnter($event, elPos, idx, {});dragStarted(elPos)"
          (mousedown)="onClickToDrag($event, elPos, idx, {});dragStarted(elPos)"
          cdkDrag [cdkDragDisabled]="isLocked" 
          class="drag-el"
          [class.is-locked-in-place] = "elPos.ref.isLocked"
          [class.is-bg-brdr-disabled] = "elPos.ref.element.removeDraggableBgBorder"
          [class.is-centered]="elPos.isTargetsContentsCentred"
          [ngStyle]="getDraggableStyle(elPos, true)"
          [class.is-horizontal]="isHorizontal()"
        > 
          <div *ngIf="elPos.ref.label">
            {{elPos.ref.label}}&nbsp;
          </div>
          <element-render
            [class.is-selected]="isSelected(elPos)"
            *ngIf="elPos.ref.element"
            [contentElement]="elPos.ref.element"
            [isLocked]="true"
            [questionState]="{}"
          ></element-render>
          <render-audio
            *ngIf="getElementAudio(elPos.ref.voiceover) && getElementAudio(elPos.ref.voiceover) != ''"
            [url]="getElementAudio(elPos.ref.voiceover)"
            [trigger]="getDragTrigger(elPos)"
            [isTriggerDisabled]="!isVoiceoverEnabled()"
          ></render-audio>
        </div>
      </div>
    </div>
    <div *ngIf="showInstr() && !isHorizontal()" class="question-block-label mb-3" style="text-align: center; font-size:0.8em;"> 
      <tra-md [slug]="getInstrSlug()" [style.color]="getInstrColor()"></tra-md>
    </div>
    <div *ngIf="!element.isShowSeparatorInRenderHidden" style="display:flex;align-items:center;justify-content: center;">
      <div>
        <div *ngIf="this.element.separatorType == 'text'">
          <element-render-text *ngIf="this.element.separatorText" [element]='this.element.separatorText'></element-render-text>
        </div>
        <div *ngIf="this.element.separatorType == 'image'">
          <element-render-image *ngIf="this.element.separatorImage" [element]='this.element.separatorImage'></element-render-image>
        </div>
        <div *ngIf="this.element.separatorType == 'canvas'">
          <element-render-canvas *ngIf="this.element.separatorCanvas" [element]='this.element.separatorCanvas' [changeCounter]="this.element.separatorCanvas._changeCounter"></element-render-canvas>
        </div>
      </div>
    </div>
    <div class="group-container" 
         [class.is-vertical]="element.isVerticalLayout || isHorizontal()" 
         [class.no-wrap-groups]="element.isGroupContainerNoWrap" 
         [style.align-items]="element.topAlignTargets ? 'start' : 'center'"
         [style.row-gap.em]="element.targetVerticalSpacing"
    >
      <div *ngFor="let target of targets; index as idx" style="display:flex;align-items:center;">
        <div *ngIf="idx !== 0 && element.isRenderDelimiter" style="padding:0.3em; flex-grow:0; align-self:center;">
          <div *ngIf="!element.delimiterType || element.delimiterType=='text'" [style.line-height]="'1em'">
            <!--<markdown-inline [input]="element.delimiter"></markdown-inline>-->
          </div>
          <div *ngIf="element.delimiterType=='image'">
            <element-render-image *ngIf="element.delimiterImg" [element]="element.delimiterImg"></element-render-image>
          </div>
          <div *ngIf="element.delimiterType=='icon'">
            <i *ngIf="element.delimiterImg" [class]="element.delimiterIcon"></i>
          </div>
        </div>
        <div [class.is-side-by-side]="isSideBySide()">
          <div 
            class="drag-drop-container" 
            [class.is-horizontal]="element.isTargetListHorizontal"
            [style.border]="this.element.removeContainerBgBorder ? '' : 'solid 1px black' "
            [style.backgroundColor]="this.element.removeContainerBgBorder ? 'transparent' : getTargetColor(target)" 
            [style.margin]="getTargetMargins()"
            [style.position]="getPosition()"
            [style.min-width.em]="this.element.customTargetDim ? getTargetWidth(target.targetContext) : '0'"
            [style.min-height.em]="this.element.customTargetDim ? getTargetHeight(target.targetContext): '0'"
            [style.max-width.em]="this.element.customTargetDim && this.element.targetWidth && element.wrapInTargets ? getTargetWidth(target.targetContext) : null"
            [style.max-height.em]="this.element.customTargetDim ? getTargetHeight(target.targetContext, true) : null"
            [style.left.em]="getLeft(target.targetContext)"
            [style.top.em]="getTop(target.targetContext)"
          >
            <div class="group-title" 
              (mousedown)="playDropAudio(target.targetContext)"
              [style.border-bottom]="this.element.removeContainerBgBorder ? '' : '1px solid #ccc'"
              [style.color]="target.targetContext.headerTextColor ? target.targetContext.headerTextColor : this.element.targetColourScheme.textColor" 
              [style.height.em]="target.targetContext.headerMinHeight ? target.targetContext.headerMinHeight : null"
              [style.background-color]="target.targetContext.headerBGColour ? target.targetContext.headerBGColour : null"
              [style.padding.px]="target.targetContext.headerMinHeight == 0 ? 0 : 10"
            >
              <element-render
                  *ngIf="target.targetContext.element"
                  [contentElement]="target.targetContext.element"
                  [isLocked]="true"
                  [questionState]="{}"
              ></element-render>
              <render-audio
                      [url]="getElementAudio(target.targetContext.voiceover)"
                      [trigger]="getDropTrigger(target.targetContext)"
                      [isTriggerDisabled]="!isVoiceoverEnabled()"
              ></render-audio>
            </div>
            <div
              cdkDropList
              tabindex="0" (keyup.enter)="onEnterContainer($event, target.targetContext, target.contents, false)"
              (mousedown)="onClickToDragContainer($event, target.targetContext, target.contents, false)"
              [cdkDropListData]="target.contents"
              [cdkDropListOrientation]="element.isTargetListHorizontal ? 'horizontal' : 'vertical'"
              class="drag-drop-list"
              (cdkDropListDropped)="drop($event, target.targetContext, false); isDragging=false"
              (cdkDropListEntered)="isDragging=true"
              [ngStyle]="getStyleForTarget(target.targetContext)"
              [class.is-horizontally-placed]="element.isTargetListHorizontal"
              [class.is-wrapped]="element.customTargetDim && element.wrapInTargets"
              [class.is-fixed-height]="!!target.targetContext.height"
              [style.min-width.em]="element.targetContainerWidth ? element.targetContainerWidth : 3"
            >
              <div class="hover-indicator"></div>
              <div 
                *ngFor="let assignedElement of getContentsOrdered(target); index as elementId" 
                cdkDrag
                [cdkDragDisabled]="isLocked"
                tabindex="0" (keyup.enter)="onEnter($event, assignedElement, elementId, target); dragStarted(assignedElement)"
                (mousedown)="onClickToDrag($event, assignedElement, elementId, target); dragStarted(assignedElement)"              
                class="drag-el" 
                [class.is-not-padded]="element.isNotPadded"
                [class.is-locked-in-place] = "assignedElement.ref.isLocked"
                [class.is-bg-brdr-disabled] = "assignedElement.ref.element.removeDraggableBgBorder"
                [class.is-centered]="assignedElement.isTargetsContentsCentred"
                [ngStyle]="getDraggableStyle(assignedElement)"
              >
                <div *ngIf="element.wrapInTargets && assignedElement.ref.element.elementType=='image'">
                  <div>{{assignedElement.isTargetsContentsCentred}}</div>
                  <img *ngIf = "assignedElement.ref.element.images && assignedElement.ref.element.images['default'] && assignedElement.ref.element.images['default'].image.url"
                   [src]="assignedElement.ref.element.images['default'].image.url" style="object-fit:contain;width:100%;"/>
                </div>
                <div *ngIf="!(element.wrapInTargets && assignedElement.ref.element.elementType=='image')" [class.is-selected]="isSelected(assignedElement)">
                  <div>{{assignedElement.isTargetsContentsCentred}}</div>
                  <element-render
                    *ngIf="assignedElement.ref.element"
                    [contentElement]="assignedElement.ref.element"
                    [isLocked]="true"
                    [questionState]="{}"
                  ></element-render>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>