export const getYearMonthMaxDate = (year:number, month_1_based:number) => {
    // Create a date for the first of the month
    const date = new Date(year, month_1_based-1, 1);
    // Set the date to the last day of the month
    date.setMonth(date.getMonth()+1); 
    date.setDate(0);
    // Return the last date of the month
    return date.getDate();
  }

  