<!-- <button *ngIf="!isRecording" class="button" (click)="startRecording()">Start Recording</button>
<button *ngIf="isRecording" class="button" (click)="stopRecording()">Stop Recording</button> -->

<!-- <div *ngIf="isLocked">
    <a [href]="capture.url">Télécharger votre enregistrement</a>
</div>
*ngIf="!isLocked"  -->

<div class="centered-stack" id="microphone">
  <div *ngIf="isCountdownInProgress" class="centered-stack" style="margin-bottom:2em;">
    <div *ngIf="element.setTimer && time > 0" [style.position]="getCountdownProgressBarPosition()" [style.margin-top.em]="getCountdownProgressBarTopMargin()">
      <!--<div style="color: red;font-weight: bold;">
                Recording Starts In {{time}} Seconds
            </div>-->
      <div class="progress-bar" [ngStyle]="getProgressBarBorder()">
        <div class="progress-bar-fill" [ngStyle]="getProgressBarFillEm()"></div>
      </div>
    </div>
    <div *ngIf="!isCountdownEarlyEnd" style="margin-top:1em;">
      <button (click)="isCountdownEarlyEnd = true" class="button has-icon end-countdown" [disabled]="isLocked">
          <span class=" recorder-circle" [class.is-hi-contrast]="isHighContrast()"></span>
          <span style="margin: 0em 1em;">
            <div>Enregistrer</div>
            <div>maintenant</div>
          </span>
      </button>
    </div>
  </div>

  <!-- *ngIf="!isCountdownInProgress || isCountdownEarlyEnd" -->
  <div class="audio-recorder-visibility" [class.is-visible]="!isCountdownInProgress || isCountdownEarlyEnd">
    <div *ngIf="isDeviceError" class="notification is-danger">
      L'évaluation est incapable d'accèder à votre microphone. Veuillez vérifier ce problème avec un⸱e technicien⸱ne.
    </div>
    <!-- <div style="display: flex; flex-direction: row; align-items: center;">
            <div *ngIf="element.isPractice" (click)="resetRecording()" class="undo-button" [class.is-active]="isRecorded">
                <div style="margin-bottom:0.5em;">Refaire</div>
                <i class="fas fa-undo"></i>
            </div>
            <table>
                <tr>
                    <td style="width:5em;background-color:#333;"></td>
                    <td class="header-cell is-cell-record">Enregistrer</td>
                    <td class="header-cell is-cell-stop">Arrêter</td>
                    <td class="header-cell is-cell-save">Envoyer</td>
                </tr>
                <tr>
                    <!- <td style="background-color:#000"> <img *ngIf="isRecordingSim" style="width:2em;" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/Feb-02-2021 15-55-10/1612299541888/Feb-02-2021 15-55-10.gif" /></td> ->
                    <td style="background-color:#ccc; overflow:hidden;" [ngSwitch]="!!isChrome()"> 
                        <div *ngSwitchCase="true" id="waveform" style="overflow:hidden"  [class.is-transparent]="!isRecordingSim"></div> 
                        <div *ngSwitchCase="false" [class.is-hidden]="!isRecordingSim" >
                            <div *ngIf="isRecorderInitializing" style="font-size:0.7em;">Initialization...</div>
                            <img *ngIf="!isRecorderInitializing" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/voice/1613601522306/voice.gif">
                        </div>
                    </td>
                    <td (click)="startRec()" [class.is-disabled]="isRecorded" [class.is-active]="isRecordingSim" class="btn-cell is-cell-record"> <i class="fas fa-circle"></i></td>
                    <td (click)="stopRec()"  [class.is-disabled]="isRecorded" class="btn-cell is-cell-stop"> <i class="fas fa-square"></i></td>
                    <td (click)="saveRec()"  [class.is-disabled]="capture.isFilled" [class.is-active]="isUploading" class="btn-cell is-cell-save"> <i class="fas fa-cloud-upload-alt"></i> </td>
                </tr>
                <tr>
                    <td colspan="4" style="background-color:#FFFD4C">
                        <div class="space-between">
                            <span> {{msg}} <span *ngIf="isUploading">(Téléversement)</span> </span>
                            <span>
                                <span *ngIf="isRecordingSim">{{currentRecordingTime}}</span>
                                <!- <button *ngIf="element.isPractice" (click)="restartState()">Recommencé</button> ->
                            </span>
                        </div>
                    </td>
                </tr>
            </table>
            <div (click)="playRecording()" class="play-button" [class.is-active]="isRecorded">
                <div style="margin-bottom:0.5em;">Écouter</div>
                <i class="fas fa-play-circle"></i>
            </div>
        </div> -->
    <div class="audio-grid-container" [class.is-old-browser]="isOldBrowser()">
        <!-- header -->
      <div class="audio-controls-header" style="font-size:0.8em;" [class.is-old-browser]="isOldBrowser()">
        <div [class.is-titleActivated]="isRecordingSim">Enregistrer</div>
        <div [class.is-titleActivated]="isRecorded && !isSaving && !capture.isFilled">Mettre en pause</div>
        <div [ngSwitch]="isUploadErrored" style="margin-left: 0.7em;">
          <div *ngSwitchCase="false" [class.is-titleActivated]="isTransferred || isSaving">{{ isTransferred ? "Enregistrement reçu" : "Envoyer" }}</div>
          <div *ngSwitchCase="true" [class.is-titleActivated]="isUploadErrored">L'envoi a échoué. Réessayer</div>
        </div>
      </div>
      <!-- Controls -->
      <div class="audio-controls" [class.is-hi-contrast]="isHighContrast()" [class.is-old-browser]="isOldBrowser()">
        <div *ngIf="!isResumeButtonEnabled()" class="audio-grid-item" [class.is-hi-contrast]="isHighContrast()" [class.is-rightBorder]="true" [class.is-old-browser]="isOldBrowser()">
          <img class="audio-controls-btn" 
            tabindex="0" 
            [class.is-enabled]="isInitialState()" 
            [class.is-active]="isRecordingSim"
            [class.invert]="isHighContrast()" 
            [src]="isHighContrast() ? 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/HC-Record/1673643713554/HC-Record.png' 
                                    : 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/Record/1673643523817/Record.png'" 
            (keyup.Enter)="isInitialState() ? startRec() : ''; " 
            (click)="startRec()" 
            />
        </div>

        <div *ngIf="isResumeButtonEnabled()" class="audio-grid-item" [class.is-hi-contrast]="isHighContrast()" [class.is-rightBorder]="true" [class.is-old-browser]="isOldBrowser()">
          <img class="audio-controls-btn" 
            tabindex="0" 
            [class.is-enabled]="isResumeButtonEnabled()" 
            [class.is-active]="isRecordingSim"
            [class.invert]="isHighContrast()" 
            [src]="isHighContrast() ? 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/HC-Record/1673643713554/HC-Record.png' 
                                    : 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/Record/1673643523817/Record.png'" 
            (keyup.Enter)="isResumeButtonEnabled() ? resumeRec() : ''; " 
            (click)="resumeRec()" 
            />
        </div>


        <div *ngIf="!displayPauseButton()" class="audio-grid-item" [class.is-hi-contrast]="isHighContrast()" [class.is-rightBorder]="true" [class.is-old-browser]="isOldBrowser()">
          <img class="audio-controls-btn" 
            tabindex="0" 
            [class.is-enabled]="isRecordingState()" 
            [class.invert]="isHighContrast()" 
            [src]="isHighContrast() ? 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/HC-Stop/1673643741249/HC-Stop.png'
                                     : 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/Stop/1673643552580/Stop.png'" 
            (click)="stopRec()" 
            (keyup.Enter)="isRecordingState() ? stopRec() : ''; " 
          />
        </div>

        <div *ngIf="displayPauseButton()" class="audio-grid-item" [class.is-hi-contrast]="isHighContrast()" [class.is-rightBorder]="true" [class.is-old-browser]="isOldBrowser()">
          <img class="audio-controls-btn" 
            tabindex="0" 
            [class.is-enabled]="isRecordingState()" 
            [class.invert]="isHighContrast()" 
            [src]="isHighContrast() ? 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/download/1675958088278/download.png'
                                     : 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/download/1675958088278/download.png'" 
            (click)="pauseRec()" 
            (keyup.Enter)="isRecordingState() ? pauseRec() : ''; " 
          />
        </div>




        <div class="audio-grid-item" [class.is-disabled]="!canUploadRecording()" [class.is-hi-contrast]="isHighContrast()" [ngSwitch]="getSavingState()" [class.is-old-browser]="isOldBrowser()">
          <img *ngSwitchCase="'save'" 
            tabindex="0" 
            class="audio-controls-btn" 
            [class.is-enabled]="canUploadRecording()" 
            [class.is-disabled]="!canUploadRecording()"
            [class.invert]="isHighContrast()" 
            [src]="isHighContrast() ? 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/HC-Upload/1673643774562/HC-Upload.png'
                                    : 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/Upload/1673643573577/Upload.png'" 
            (keyup.Enter)="isRecorded ? uploadRecording() : ''; " 
            (click)="uploadRecording()" 
          />
          <div *ngSwitchCase="'saving'" style="text-align: center;">
            <div class="lds-ring" [class.is-hi-contrast]="isHighContrast()">
              <div [class.is-hi-contrast]="isHighContrast()"></div>
              <div [class.is-hi-contrast]="isHighContrast()"></div>
              <div [class.is-hi-contrast]="isHighContrast()"></div>
              <div [class.is-hi-contrast]="isHighContrast()"></div>
            </div>
          </div>
          <img *ngSwitchCase="'uploaded'" class="audio-controls-btn" [class.is-enabled]="capture.isFilled" 
            [class.invert]="isHighContrast()" 
            [src]="isHighContrast() ? 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/HC-Uploaded/1673898954894/HC-Uploaded.png'
                                    : 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/1203032/authoring/Uploaded/1673899010334/Uploaded.png'" />
        </div>
      </div>
      <!-- Control Info Panel -->
      <div *ngIf="isShowAudioInfoPanel()" class="audio-controls-info" [class.is-hi-contrast]="isHighContrast()" [class.is-old-browser]="isOldBrowser()">
        <div class="audio-grid-item" [class.is-hi-contrast]="isHighContrast()" [class.is-rightBorder]="true" [style.margin-left.em]="1">
          <div *ngIf="isRecorded" class="play-button"
            (click)="playRecording()" 
            (keyup.Enter)="playRecording()"
          >
            <img 
              tabindex="0"
              class="audio-control-info-btn"
              [class.invert]="isHighContrast()"
              [class.is-enabled]="isRecorded && !isPlaying" 
              [class.is-active]="isPlaying"    
              [src]="isHighContrast() ? 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/96360/authoring/HC-Play/1630589654958/HC-Play.svg'
                                      : 'https://d3azfb2wuqle4e.cloudfront.net/UI_Elements/recordingComponent/Play.svg'" 
              style="vertical-align: middle;width:20%"                
            />
            <span class="btn-text exception-text"
              [class.is-enabled]="isRecorded && !isPlaying" 
              [class.is-active]="isPlaying"
            >
              Écouter
            </span>
          </div>
          <img *ngIf="isRecordingSim" class="audio-info-btn" style="width:50%" 
            [class.invert]="isHighContrast()"  
            [src]="isHighContrast() ? 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/96360/authoring/HC-Wave Icon/1630589795545/HC-Wave Icon.svg'
                                    : 'https://d3azfb2wuqle4e.cloudfront.net/UI_Elements/recordingComponent/Wave Icon.svg'" />
        </div>
        <div class="audio-grid-item" [class.is-hi-contrast]="isHighContrast()" [class.is-rightBorder]="true" style="overflow:hidden;" [ngSwitch]="!!isChrome()">
          <div *ngSwitchCase="true" id="waveform" class="waveform" style="margin-top: 0.2em;"></div>
          <div *ngSwitchCase="false" class="audio-info-btn" style="margin-top: 0.2em;">
            <div *ngIf="isRecordingSim">
              <div *ngIf="isRecorderInitializing" style="font-size:0.7em;">Initialization...</div>
              <img *ngIf="!isRecorderInitializing" class="audio-info-btn" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/voice/1613601522306/voice.gif" />
            </div>
            <div *ngIf="!isRecordingSim">
              <hr style="border: 1px solid #888888;width:3em;"/>
            </div>
          </div>
        </div>
        <div class="audio-grid-item" [class.is-hi-contrast]="isHighContrast()" [style.margin-right.em]="1">
          <div *ngIf="isTimeDisplayed()" class="audio-info-btn audio-time" [class.is-hi-contrast]="isHighContrast()">{{ currentRecordingTime }}</div>

          <div *ngIf="isResetButtonShown()" class="play-button"
            style="cursor: pointer;"
            (keyup.Enter)="resetRecording()"
            (click)="resetRecording()"
          >
            <img 
              tabindex="0"
              style="vertical-align: middle; width: 18%;"
              [class.invert]="isHighContrast()"
              [src]="isHighContrast() ? 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/96360/authoring/HC-Reset_Circle/1630589722050/HC-Reset_Circle.svg'
                                      : 'https://d3azfb2wuqle4e.cloudfront.net/UI_Elements/recordingComponent/Reset_Circle.svg'"              
            />
            <span class="btn-text exception-text">
              Refaire
            </span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!isChrome()" class="padded-audio-display">
      <mictest [startRec]="triggerStartRec" [stopRec]="triggerStopRec" [saveRec]="triggerPeriodicSave" [pauseRec]="triggerPauseRec" [resumeRec]="triggerResumeRec" (onUrlGenerated)="onUrlGenerated($event)" (onDeviceError)="onDeviceError($event)" (onNewBlobAvailable)="onPeriodicSaveDataAvailable($event)"></mictest>
    </div>

    <div *ngIf="isRecorded">
      <render-audio [hasControls]="false" [trigger]="audioTrigger" [forceNativePlayer]="true" [url]="blobDataURI" (end)="donePlaying($event)"></render-audio>
    </div>
  </div>
</div>

<a 
  [class.is-hidden]="!renderDownloadUrl() || !element.allowDownload"
  target="_blank" 
  [href]="renderDownloadUrl()"
>
  <i class="fas fa-file-download" style="margin-top: 0.5em;"></i>
  <span style="font-size:0.8em;">
    {{lang.tra('btn_download_recorded_audio')}}
  </span>
</a>

<render-audio [url]="warningForAuto.url" [hasControls]="false" [trigger]="warningForAutoTrigger"></render-audio>
<render-audio [url]="standardBeep.url" [hasControls]="false" [trigger]="standardBeepTrigger"></render-audio>

<!-- <div [ngSwitch]="!!isLocked" style="opacity:0">
    <capture-voice *ngSwitchCase="false"
        [element]="capture" 
        [isResponse]="true"
        [recordEndCallback]="stopRecording"
        [startNow]="element.setTimer && !(time>0)"
        (notifyRecordingStart)="voiceCaptureStarted()"
    ></capture-voice>
    
</div> -->
