import { ISectionDef } from "../../../ui-testtaker/view-tt-test-runner/view-tt-test-runner.component";
import { IQuestionRun } from "../../../ui-item-maker/item-set-editor/models";
import { IQuestionConfig } from "../../models";
import { LangService } from "../../../core/lang.service";

type SectionQuestionIdTuple = string;
export type QuestionTitleMap = Map<SectionQuestionIdTuple, string>;

const checkIfGradedQuestion = (questionConfig:Partial<IQuestionConfig>) => {
  if (questionConfig && questionConfig.isReadingSelectionPage){
    return false;
  }
  return true;
}

export const getQuestionWordSlug = (questionWordSlug?: string, config?: {isLowerCase?: boolean, isPlural?: boolean} ) => {
  let baseSlug = questionWordSlug ?? "title_question";
  return `${baseSlug}${config?.isPlural ? 's':''}${config?.isLowerCase ? '_lc':''}`;
}

export interface IQuestionTitleConfig {
  sections:ISectionDef[], 
  questionSrc:Map<number, IQuestionRun>, 
  useQuestionLabel:boolean, 
  lang:LangService, 
  questionWordSlug?:string,
  isCountThroughSections?:boolean,
}

export const getQuestionTitles = (config:IQuestionTitleConfig):QuestionTitleMap => {
  
  const {
    sections,
    questionSrc,
    useQuestionLabel,
    lang,
    questionWordSlug,
    isCountThroughSections,
  } = config;

  console.log('isCountThroughSections', isCountThroughSections)

  const questionWord = lang.tra(getQuestionWordSlug(questionWordSlug));
  const map:QuestionTitleMap = new Map();

  let questionCounter = 0;

  if(sections) {

    sections.forEach((section, sectionIndex) => { 
      if (!isCountThroughSections)  {
        questionCounter = 0;
      }
      section.questions.forEach((questionId, questionIndex) =>{
        const questionConfig:Partial<IQuestionConfig> = questionSrc.get(questionId);
       
        let title;
        if (!questionConfig){
          console.warn('Missing item config', questionId);
          return;
        }
  
        if (questionConfig.caption){
          title = questionConfig.caption
        }
        else{
          const isCountedQuestion = checkIfGradedQuestion(questionConfig);
          if (isCountedQuestion){
            questionCounter ++;
          }
          if (useQuestionLabel){
            title = questionConfig.label;
          }
          else {
            title = questionWord + " " + questionCounter;
          }
          if (questionConfig.isReadingSelectionPage){
            title = lang.tra('lbl_reading_selection');
          }
        }
        const tuple = [section.sectionId, questionId];
        map.set(JSON.stringify(tuple), title)
      })
    })
  }
  // console.log('getQuestionTitles', sections, questionSrc, map)
  return map;
}

export const getQuestionTitleFromMap = (map: QuestionTitleMap, sId: number, qId: number) => {
  const  tuple = JSON.stringify([sId, qId]);
  return map.get(tuple);
}