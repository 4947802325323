import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'chat-emoji-picker',
  templateUrl: './chat-emoji-picker.component.html',
  styleUrls: ['./chat-emoji-picker.component.scss']
})
export class ChatEmojiPickerComponent implements OnInit {

  @Output() insertHandler = new EventEmitter();

  public style = {
    width: '100%',
    height: '100%',
    bottom: '0',
    right: '0',
  };

  constructor() { }

  ngOnInit(): void {
  }

  select(e) {
    this.insertHandler.emit(e.emoji);
  }

}
