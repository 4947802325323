import * as PIXI from "pixi.js-legacy";
import { IContentElementVirtualTools, IEntryStateVirtualTools, ITable } from "../model";
import { EToolType, VirtualTools } from "./virtual-tools";
import * as _ from "lodash";
import { SpriteLoader } from "../../element-render-custom-interaction/controllers/sprite-loader";
import { DEFAULT_COLS, DEFAULT_ROWS, DEFAULT_TICK_MEASURE, LAYER_LEVEL } from '../types/constants'
import { OnInit } from "@angular/core";
import { ENamingTypes, EPixiTools } from "../types/types";
import {TextBox} from "./textbox";
import { ITextboxData } from "../model";
import { QuestionState } from "../../models";
export enum ACTIONS {
  ROW = "ROW",
  COLUMN = "COLUMN",
  DELETE = "DELETE",
  DELETEROW = "DELETEROW",
  DELETECOL = "DELETECOL",
  DUPLICATE = "DUPLICATE"
}

export class Table extends VirtualTools{
  element: IContentElementVirtualTools;
  spriteLoader: SpriteLoader
  backgroundSprite: PIXI.Sprite;
  hcPrevState: boolean;
  text: PIXI.Graphics;
  tables: PIXI.Graphics[];
  tableManager: PIXI.Graphics[] = [];
  deleteSprite: PIXI.Sprite;
  rowSprite: PIXI.Sprite;
  delRow: PIXI.Sprite;
  columnSprite: PIXI.Sprite;
  dupeSprite: PIXI.Sprite;
  tableData: ITextboxData[][][] = [];
  tableGraphics: PIXI.Graphics[][][] = [];
  tableDrawInfo: ITable[] = [];
  TextBox: TextBox;
  cellIdCounter: number = 0;

  constructor(
    questionState: QuestionState,
    element: IContentElementVirtualTools, 
    addGraphic, 
    render,
    getToolStateSub,
    stage,
    isLocked, 
    textToSpeech,
    isGlobalRotating: boolean, 
    isGlobalDragging: boolean,
    backgroundSprite: PIXI.Sprite,
    layerLevel: LAYER_LEVEL,
    spriteLoader: SpriteLoader
  ) {
    super(questionState, addGraphic, render, getToolStateSub, stage, isLocked, textToSpeech, isGlobalRotating, isGlobalDragging);
    this.initTool({name: EPixiTools.TABLE, type: EToolType.BUTTON, layerLevel: layerLevel});
    this.questionState = questionState;
    //#stopping Ticker
    PIXI.Ticker.system.autoStart = false
    PIXI.Ticker.system.stop()

    this.element = element;
    this.isGlobalRotating = isGlobalRotating;
    this.isGlobalDragging = isGlobalDragging;

    this.spriteLoader = spriteLoader;
    this.tables = [];
    // this.tables.name = this.getName(ENamingTypes.CONTAINER);
    // this.tables.zIndex = this.getContainerZindex(20);
    this.delRow = new PIXI.Sprite();
    this.deleteSprite = new PIXI.Sprite();
    this.rowSprite = new PIXI.Sprite();
    this.columnSprite = new PIXI.Sprite();
    this.dupeSprite = new PIXI.Sprite();
    this.TextBox = new TextBox(questionState, <IContentElementVirtualTools>this.element, addGraphic, render, getToolStateSub, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging, this.redrawTable, this.deselectAll, LAYER_LEVEL.LEVEL_4, this.spriteLoader);

    this.hcPrevState = this.textToSpeech.isHiContrast;  


    this.loadAssets().then((res) => {
      this.initSprites(res);
    });

    // this.addGraphic(this.tables);
  }

  initSprites = ({resources}) => {
    if (!resources || Object.keys(resources).length == 0) {
      this.deleteSprite.texture = PIXI.utils.TextureCache.delete;
      this.rowSprite.texture = PIXI.utils.TextureCache.row;
      this.delRow.texture = PIXI.utils.TextureCache.deleteRow;
      this.columnSprite.texture = PIXI.utils.TextureCache.column;
      this.dupeSprite.texture = PIXI.utils.TextureCache.duplicate;
    } else {
      this.deleteSprite.texture = resources.delete.texture;
      this.rowSprite.texture = resources.row.texture;
      this.delRow.texture = resources.deleteRow.texture;
      this.columnSprite.texture = resources.column.texture;
      this.dupeSprite.texture = resources.duplicate.texture;
    }
  }

  populateEmptyTable = (rows: number, columns: number) => {
    const defaultValue = {id: null, points: [{x: null, y: null}],input: ``, activeCharIndex: -1, fontSize: 10, isTableCell: true, tableId: this.tableManager.length, width: this.TextBox.cellWidth}
    const table: ITextboxData[][] = [];
    for(let i=0; i<rows; i++) {
      const row: ITextboxData[] = [];
      for(let v=0; v<columns; v++) {
        let newCell = <ITextboxData>JSON.parse(JSON.stringify(defaultValue));
        newCell.points[0].x = v > 0 ? row[v-1].points[0].x + newCell.width : 0;
        row.push(newCell);
      }
      table.push(row);
    }
    return table;
  }

  initialTableX = 45;
  initialTableY = 45;
  newTable(tableData?: ITextboxData[][], tableDrawInfo?: ITable, isRestore?: boolean, isDuplicate?: boolean) {
    // Default rows and columns are set by the config
    if(tableDrawInfo && tableDrawInfo.isRevoked) {
      return;
    }
    if(!isRestore) {
      this.initialTableX += 5;
      this.initialTableY += 5;
    }

    if(tableData && tableDrawInfo) {
      tableData.forEach(row => {
        row.forEach(cell => {
          cell.tableId = this.tableManager.length;
        })
      })
      tableDrawInfo.id = this.tableManager.length;
      if(isDuplicate) {
        tableDrawInfo.points[0].x += 20;
        tableDrawInfo.points[0].y += 20;
      }
      tableDrawInfo.zIndex = this.getContainerZindex(20);;
      this.tableData.push(tableData);
      this.tableDrawInfo.push(tableDrawInfo);
    } else {
      const newTableDrawInfo = {
        id: this.tableManager.length, 
        points: [{x: this.initialTableX, y: this.initialTableY}], 
        isRevoked: false, 
        zIndex: this.getContainerZindex(20),
         _isSelected: true
      } 
      this.tableData.push(this.populateEmptyTable(DEFAULT_ROWS, DEFAULT_COLS));
      this.tableDrawInfo.push(newTableDrawInfo);
      tableDrawInfo = this.tableDrawInfo[this.tableDrawInfo.length -1];
    }
    
        
    const tableContainer = new PIXI.Graphics();
    tableContainer.name = this.getName(ENamingTypes.CONTAINER)

    const tableIndex = this.tableData.length - 1;

    const newTableGraphic: PIXI.Graphics[][] = [];
    this.drawTable(tableIndex, tableContainer, newTableGraphic);
    this.tableGraphics.push(newTableGraphic);

    this.addContainer(tableContainer, tableDrawInfo);
    this.selectTableListener(tableContainer, tableIndex);
    this.tableDragListener(tableContainer, tableIndex);
    this.tableManager.push(tableContainer)

    this.clearMenus();
    if(!isRestore) {
      this.drawMenu(tableContainer, this.tableManager.length - 1);
    }
    return tableContainer;
  }

  initialTextboxX = 45;
  initialTextboxY = 45;
  newStandaloneTextbox() {
    this.initialTextboxX += 5;
    this.initialTextboxY += 5;

    this.TextBox.createTextBox(this.initialTextboxX, this.initialTextboxY, 0xffffff, null, true);

    this.activateSelectionTool();
    this.render();
  }

  syncTableWidths(tableIndex: number) {
    // Sync for highest cell width in each column
    const tableData = this.tableData[tableIndex];
    const numColumns = tableData[0].length; Math.max
    for(let i = 0; i<numColumns; i++) {
      const cols: number[] = [];
      tableData.forEach((row) => {
        cols.push(row[i].width)
      })

      tableData.forEach((row) => {
        row[i].width = Math.max(...cols);
      })
    }
  }

  deselectAll = () => {
    this.tableData.forEach(table => {
      table.forEach(row => {
        row.forEach(cell => {
          cell.isActive = false;
          cell.isEditing = false;
          cell.selectedStartIndex = null;
          cell.selectedEndIndex = null;
        })
      })
    });
  }

  redrawTable = (tableIndex: number) => {
    const drawTable = (tableIndex: number, tableContainer: PIXI.Graphics, tableGraphic: PIXI.Graphics[][]) => {
      this.syncTableWidths(tableIndex);

      // tableGraphic.splice(0, tableGraphic.length);
      for(let i = 0; i<this.tableData[tableIndex].length; i++) {
        for(let v = 0; v<this.tableData[tableIndex][i].length; v++) {
          const textboxData = this.tableData[tableIndex][i][v];
          const preTextboxData = this.tableData[tableIndex][i][v-1];
          if(!textboxData.isDrawn) {
            const cell = this.TextBox.createTextBox(
              (preTextboxData ? preTextboxData.points[0].x + preTextboxData.width : 0), 
              (this.TextBox.cellHeight*i) + (i-0.25),
              i == 0 ? 0xd6d6d6 : 0xffffff,
              textboxData,
              false,
              tableContainer
              );
            tableGraphic[i][v] = cell;
          }
        }
      }
  
      tableContainer.x = this.tableDrawInfo[tableIndex].points[0].x;
      tableContainer.y = this.tableDrawInfo[tableIndex].points[0].y;
      tableContainer.zIndex = this.tableDrawInfo[tableIndex].zIndex;
    }

    this.tableData[tableIndex].forEach((row, i) => {
      row.forEach((cell, v) => {
        cell.isDrawn = false;
        while(this.tableGraphics[tableIndex][i][v].children[0]) {
          this.tableGraphics[tableIndex][i][v].removeChild(this.tableGraphics[tableIndex][i][v].children[0]);
        }
        this.tableGraphics[tableIndex][i][v].clear();
      })
    })

    this.updateState();
    drawTable(tableIndex, this.tableManager[tableIndex], this.tableGraphics[tableIndex]);
  }

  drawTable(tableIndex: number, tableContainer: PIXI.Graphics, tableGraphic: PIXI.Graphics[][]) {
    // tableGraphic.splice(0, tableGraphic.length);
    for(let i = 0; i<this.tableData[tableIndex].length; i++) {
      if(tableGraphic.length <= i) {
        tableGraphic.push([]);
      }
      for(let v = 0; v<this.tableData[tableIndex][i].length; v++) {
        if(!this.tableData[tableIndex][i][v].isDrawn) {
          const cell = this.TextBox.createTextBox(
            (this.tableData[tableIndex][i][v].points[0].x),
            (this.TextBox.cellHeight*i) + (i-0.25),
            i == 0 ? 0xd6d6d6 : 0xffffff,
            this.tableData[tableIndex][i][v],
            false,
            tableContainer,
            );
          tableGraphic[i].push(cell);
        }
      }
    }

    tableContainer.x = this.tableDrawInfo[tableIndex].points[0].x;
    tableContainer.y = this.tableDrawInfo[tableIndex].points[0].y;
    tableContainer.zIndex = this.tableDrawInfo[tableIndex].zIndex;


    this.updateState();
  }

  drawMenu(container: PIXI.Graphics, tableIndex) {
    const menu = new PIXI.Graphics();
    const menuYOffset = 10;
    const menuHeight = 30;
    const menuWidth = 100;
    menu.beginFill(0x333c42);
    menu.lineStyle(0);
    menu.drawRoundedRect(0, 0, menuWidth, menuHeight, 5);
    menu.y = container.height + menuYOffset+ 5;
    menu.endFill();
    menu.interactive = false;

    const row = new PIXI.Sprite();
    row.texture = this.rowSprite.texture;
    row.scale.set(0.03);
    row.anchor.set(0,0.5);
    row.y = menuHeight / 2;
    row.x = 10;
    row.interactive = true;

    const col = new PIXI.Sprite();
    col.texture = this.columnSprite.texture;
    col.scale.set(0.03);
    col.anchor.set(0,0.5);
    col.y = menuHeight / 2;
    col.x = row.x + row.width + 5;
    col.interactive = true;

    const dupe = new PIXI.Sprite();
    dupe.texture = this.dupeSprite.texture;
    dupe.scale.set(0.03);
    dupe.anchor.set(0,0.5);
    dupe.y = menuHeight / 2;
    dupe.x = col.x + col.width + 5;
    dupe.interactive = true;

    const del = new PIXI.Sprite();
    del.texture = this.deleteSprite.texture;
    del.scale.set(0.03);
    del.anchor.set(0,0.5);
    del.y = menuHeight / 2;
    del.x = dupe.x + dupe.width + 5;
    del.interactive = true;

    const delRow = new PIXI.Sprite();
    delRow.texture = this.delRow.texture;
    delRow.scale.set(0.02);
    delRow.anchor.set(0,0.5);
    delRow.y = 0-menuYOffset-(menuHeight/2);
    delRow.x = 0-(menuYOffset*2);
    delRow.interactive = true;

    const delCol = new PIXI.Sprite();
    delCol.texture = this.delRow.texture;
    delCol.scale.set(0.02);
    delCol.anchor.set(0,0.5);
    delCol.y = 0 + (menuYOffset/2) - delCol.width - 2;
    delCol.x = container.width - (this.TextBox.cellWidth/2) - (delCol.width/2);
    delCol.interactive = true;

    menu.addChild(row);
    menu.addChild(col);
    menu.addChild(dupe);
    menu.addChild(del);

    const table = this.tableData[tableIndex];
    if (table.length > 1){
      this.addMenuListeners(container, ACTIONS.DELETEROW, delRow, tableIndex);
      menu.addChild(delRow);
    } 
    
    if (table[0].length > 1){
      menu.addChild(delCol);
      this.addMenuListeners(container, ACTIONS.DELETECOL, delCol, tableIndex);
    } 

    this.addMenuListeners(container, ACTIONS.ROW, row, tableIndex);
    this.addMenuListeners(container, ACTIONS.COLUMN, col, tableIndex);
    this.addMenuListeners(container, ACTIONS.DELETE, del, tableIndex);
    this.addMenuListeners(container, ACTIONS.DUPLICATE, dupe, tableIndex);
    
    this.TextBox.activeMenu = menu;
    container.addChild(menu);
    return menu;
  }

  addMenuListeners(obj: PIXI.Graphics, action: ACTIONS, option: PIXI.Sprite, tableIndex?: number) {
    let func;
    option.cursor = 'pointer';
    const emptyCell: ITextboxData = { 
      id: null,
      points: [{ x: null,
      y: null }],
      input: '',
      activeCharIndex: -1,
      fontSize: 10,
      isTableCell: true,
      tableId: tableIndex,
      width: this.TextBox.cellWidth,
      zIndex: this.getContainerZindex(7) 
    }

    const redrawMenu = (table) => {
      this.clearMenus();
      this.drawMenu(table, tableIndex);
    }
  
    if(action == ACTIONS.DELETE) {
      func = (e) => {
        this.clearTickerListeners();
        this.tableDrawInfo[tableIndex].isRevoked = true;
        obj.destroy();
        this.TextBox.deselectAll();
        this.render();
        this.updateState();
        return;
      }
    } else if(action == ACTIONS.COLUMN) {
      func = (e) => {
        this.TextBox.wasTextBoxSelected = true;
        const table = this.tableData[tableIndex];
        const tableContainer = this.tableManager[tableIndex]

        table.forEach((row, i) => {
          const newCell = <ITextboxData>JSON.parse(JSON.stringify(emptyCell));
          newCell.points[0].x = row[row.length-1].points[0].x + row[row.length-1].width;
          row.push(newCell);
        });

        this.drawTable(tableIndex, this.tableManager[tableIndex], this.tableGraphics[tableIndex]);

        redrawMenu(tableContainer);

        this.render();
      }
    } else if(action == ACTIONS.ROW) {
      func = (e) => {
        this.TextBox.wasTextBoxSelected = true;
        const table = this.tableData[tableIndex];
        const tableContainer = this.tableManager[tableIndex]

        const prevIndex = this.tableData[tableIndex][table.length-1];
        const emptyRow: ITextboxData[] = [];

        prevIndex.forEach((cell) => {
          const newCell = <ITextboxData>JSON.parse(JSON.stringify(emptyCell));
          newCell.points[0].x = cell.points[0].x
          emptyRow.push(JSON.parse(JSON.stringify(newCell)))
        })

        table.push(emptyRow);
        this.syncTableWidths(tableIndex);
        this.drawTable(tableIndex, this.tableManager[tableIndex], this.tableGraphics[tableIndex]);

        redrawMenu(tableContainer);

        this.render();
      }
    } else if(action == ACTIONS.DELETEROW) {
      func = (e) => {
        this.TextBox.wasTextBoxSelected = true;
        const table = this.tableData[tableIndex];
        const tableGraphic = this.tableGraphics[tableIndex];
        const tableContainer = this.tableManager[tableIndex]

        if (table.length === 1) return;

        table.pop();
        tableGraphic.pop().forEach((col) => {
          col.destroy();
        })

        redrawMenu(tableContainer);
        this.updateState();

        this.render();
      }
    } else if(action == ACTIONS.DELETECOL) {
      func = (e) => {
        this.TextBox.wasTextBoxSelected = true;
        const table = this.tableData[tableIndex];
        const tableGraphic = this.tableGraphics[tableIndex];
        const tableContainer = this.tableManager[tableIndex]

        if (table[0].length === 1) return;
        
        table.forEach((row) => {
          row.pop();
        })
        tableGraphic.forEach(row => {
          row.pop().destroy();
        })

        redrawMenu(tableContainer);
        this.updateState();

        this.render();
      }
    } else if(action == ACTIONS.DUPLICATE) {
      func = (e) => {
        this.clearMenus();
        const newTableData = <ITextboxData[][]>JSON.parse( JSON.stringify(this.tableData[tableIndex]))
        const newTableDrawInfo = <ITable>JSON.parse(JSON.stringify(this.tableDrawInfo[tableIndex]));
        
        newTableData.forEach(row => {
          row.forEach(cell => {
            cell.isDrawn = false;
          })
        })
        
        this.newTable(newTableData, newTableDrawInfo, false, true)

        this.render();
      }
    }
    option.on('pointerup', func);
  }

  clearMenus() {
    if(this.TextBox.activeMenu) {
      this.TextBox.activeMenu.destroy();
      this.TextBox.activeMenu = null;
    }
  }

  selectTableListener(table: PIXI.Graphics, tableIndex) {
    const selectTable = () => {
        this.clearMenus();
        this.drawMenu(table, tableIndex);
        this.render();
    }

    table.on('pointerdown', selectTable)
  }

  tableDragListener(obj: PIXI.Graphics, tableIndex: number) {
    let initialDiffX = 0;
    let initialDiffY = 0;
    let isDragging = false;
    this.isGlobalDragging = false;
    obj.cursor = 'grab';
    obj.interactive = true;
    
    const onDragStart = (e) => {
      let isEditing = false;
      this.tableData[tableIndex].forEach((row) => {
        row.forEach(cell => {
          if(cell.isEditing) {
            isEditing = true;
          }
        })
      })
      
      if(!isEditing) {
        const mousePosition = e.data.getLocalPosition(this.stage);
        isDragging = true;
        initialDiffX = mousePosition.x - obj.x
        initialDiffY = mousePosition.y - obj.y
        obj.cursor = 'grabbing';
      }
    }
    const onDragEnd = (e) => {
        isDragging = false;
        obj.cursor = 'grab';

        this.tableDrawInfo[tableIndex].points[0].x = obj.x;
        this.tableDrawInfo[tableIndex].points[0].y = obj.y;
    }
    const onDragMove = (e: PIXI.InteractionEvent) => {
        if(isDragging && !this.isGlobalRotating && !this.isProtRotateDragging && !this.isRulerRotateDragging) {
            this.isGlobalDragging = true;
            const mousePosition = e.data.getLocalPosition(this.stage);
            obj.x = mousePosition.x - initialDiffX; // obj x = 3
            obj.y = mousePosition.y - initialDiffY;

            this.render();
        } else if(!isDragging) {
          this.isGlobalDragging = false;
        }
    }

    obj.on('pointerdown', onDragStart)
    .on('pointerup', onDragEnd)
    .on('pointerupoutside', onDragEnd)
    .on('pointermove', onDragMove);
  }

  clearTickerListeners() {
    this.TextBox.stopAllBlinks();
  }

  loadAssets = () => {
    return this.spriteLoader.loadSprites()
  }

  changeDrawnGraphicColor(){}

  getUpdatedState(entry: Partial<IEntryStateVirtualTools>): Partial<IEntryStateVirtualTools> {
    const tableData = this.tableData.map((table) => {
      const temp = JSON.parse(JSON.stringify(table))
      temp.forEach((row) => {
        row.forEach((cell) => {
          cell.isDrawn = false;
        })
      })

      return temp;
    });
    entry.data[EPixiTools.TABLE] = {
      tableData: tableData,
      tableDrawInfo: this.tableDrawInfo
    };
    return entry;
  }

  handleNewState() {
    const data = this.getQuestionState(EPixiTools.TABLE);
    if(!data || data.length == 0) {
      return; 
    }

    data['tableData'].forEach((table, i) => {
      this.newTable(table, data['tableDrawInfo'][i], true);
    });

    this.render();
  }
}
