import * as PIXI from "pixi.js-legacy";
import { IContentElementVirtualTools, IEntryStateVirtualTools } from "../model";
import { VirtualTools, EToggleMode, EToolType } from "./virtual-tools";
import * as _ from "lodash";
import { SpriteLoader } from "../../element-render-custom-interaction/controllers/sprite-loader";
import { IPoint } from "./virtual-tools";
import { IExtendedTools } from "../element-render-virtual-tools.component";
import { DUPLICATE_OBJECT_SPACE, LAYER_LEVEL } from "../types/constants";
import { ENamingTypes, EPixiTools, IDrawnMeta } from "../types/types";
import { fill } from "cypress/types/lodash";
import { QuestionState } from "../../models";

enum ACTIONS {
  FLIPH = "FLIPH",
  FLIPV = "FLIPV",
  DUPLICATE = "DUPLICATE",
  DELETE = "DELETE"
}

export class FreehandLine extends VirtualTools {
  element: IContentElementVirtualTools;
  spriteLoader: SpriteLoader
  stage: PIXI.Container;
  vertices: PIXI.Graphics;
  lines: PIXI.Graphics;
  backgroundSprite: PIXI.Sprite;
  vFlipSprite: PIXI.Sprite;
  hFlipSprite: PIXI.Sprite;
  dupeSprite: PIXI.Sprite;
  deleteSprite: PIXI.Sprite;
  lineTools: IExtendedTools;
  lineContainersOnResizing: PIXI.Graphics;


  constructor(
    questionState: QuestionState,
    element: IContentElementVirtualTools,  
    addGraphic, 
    render,
    getToolStateSub,
    stage,
    isLocked, 
    textToSpeech,
    isGlobalRotating: boolean, 
    isGlobalDragging: boolean,
    backgroundSprite: PIXI.Sprite,
    colorController: {color: number},
    layerLevel: LAYER_LEVEL,
    spriteLoader: SpriteLoader
  ) {
    super(questionState, addGraphic, render, getToolStateSub, stage, isLocked, textToSpeech, isGlobalRotating, isGlobalDragging);
    this.initTool({name: EPixiTools.LINE, type: EToolType.TOGGLE, layerLevel: layerLevel});
    this.questionState = questionState;    this.initTool({name: EPixiTools.LINE, type: EToolType.TOGGLE });
    this.element = element;
    this.spriteLoader = spriteLoader;
    this.backgroundSprite = backgroundSprite;
    this.vFlipSprite = new PIXI.Sprite();
    this.hFlipSprite = new PIXI.Sprite();
    this.dupeSprite = new PIXI.Sprite();
    this.deleteSprite = new PIXI.Sprite();

    this.loadAssets().then(this.initSprites);

    //#stopping Ticker
    PIXI.Ticker.system.autoStart = false
    PIXI.Ticker.system.stop()

    this.addDrawVertexListener();

    this.vertices = new PIXI.Graphics();
    this.lines = new PIXI.Graphics();
    this.lines.zIndex = 1;
    this.vertices.zIndex = 8;
    this.addGraphic(this.vertices);
    this.addGraphic(this.lines);
    this.lineContainersOnResizing = new PIXI.Graphics;
    this.addGraphic(this.lineContainersOnResizing);

    // this.lineTools = {
    //   parent: 'line',
    //   colors: [
    //     this.element.fhLineColor,
    //     '#c43f2e',
    //     '#2a91d6'
    //   ],        
    //   colorHandler: (color: string) => {
    //     this.lineColor = color;
    //   },
    //   tools: [
    //   {
    //     id: 'delete',
    //     action: () => this.clearDrawnLines(),
    //     iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/delete/1684351529074/delete.png',
    //     isSelected: () => false,
    //   },
    //   {
    //     id: 'colorPicker',
    //     isColorPick: true,
    //     colorController: this.lineColor,
    //     action: () => {},
    //     isSelected: () => false,
    //   },
    // ]}

    // Render scene
    this.render();
  }

  initSprites = ({resources}) => {
    if (!resources || Object.keys(resources).length == 0) {
      this.vFlipSprite.texture = PIXI.utils.TextureCache.vflip;
      this.hFlipSprite.texture = PIXI.utils.TextureCache.hflip;
      this.dupeSprite.texture = PIXI.utils.TextureCache.duplicate;
      this.deleteSprite.texture = PIXI.utils.TextureCache.delete;
    } else {
      this.vFlipSprite.texture = resources.vflip.texture;
      this.hFlipSprite.texture = resources.hflip.texture;
      this.dupeSprite.texture = resources.duplicate.texture;
      this.deleteSprite.texture = resources.delete.texture;
    }
  }
  
  lineGraphicMap = new Map<string, {graphic: PIXI.Graphics, points: IPoint[]}>();
  completeLine(points: IPoint[], isFromResize?: boolean, isRestore?: boolean, origin?: IPoint, drawOutlineBorder?: boolean, color?: number) {
    // Translate points to 0,0
    let transX = 0;
    let transY = 0;
    for(let i = 0; i< points.length; i++) {
      if(i == 0) {
        transX = points[0].x;
        transY = points[0].y;
        points[0].x = 0;
        points[0].y = 0;
      } else {
        points[i].x -= transX;
        points[i].y -= transY;
      }
    }

    this.vertices.removeChildren();
    this.lines.removeChildren();

    const lineContainer = new PIXI.Graphics();
    lineContainer.name = this.getName(ENamingTypes.CONTAINER); 

    const fillColor = color? color : this.selectedColor;
    const fillOpacity = this.element.fhRectangleShapeOpacity || 0.8
    const fill = {color: fillColor, opacity: fillOpacity};
    const line = this.drawLine(0, 0, fill, points, false, false);
    line.name = this.getName(ENamingTypes.TOOL);
    line.pivot.set(0.5);
    if(!this.isDrawMode) line.interactive = true;
    lineContainer.addChild(line);
    this.lineGraphicMap.set(line.name, {graphic: line, points: points});
    lineContainer.zIndex = this.getContainerZindex(7);
    const center = this.getShapeCenter(points);
    lineContainer.pivot.set(0,0);
    if(isRestore) {
      lineContainer.position.set(origin.x, origin.y);
      // console.log(`x: ${origin.x}, y: ${origin.y}`, 'origin');
    } else {
      lineContainer.position.set(transX, transY);
      // console.log(`x: ${transX}, y: ${transY}`, 'trans');
    }

    const head = points[0];
    const tail = points[1];
    const rotationPoints = this.drawRotationAndResizePoints(head, tail, lineContainer, isFromResize, line);
    lineContainer.addChild(rotationPoints)

    if (!isFromResize){
      //add listeners if not draw mode
      this.addSelectLineListener(line, lineContainer);

      // keep track of added lines and metadata - also adds container to stage
      this.addContainer(lineContainer, { 
        points, 
        _isSelected: isRestore ? false : !this.isDrawMode, 
        color: fillColor, 
        fillOpacity,
        x: lineContainer.x,
        y: lineContainer.y,
        zIndex: lineContainer.zIndex
      })

      this.updateState();

    } else {
      this.lineContainersOnResizing.addChild(lineContainer);
    }
    return lineContainer;
  }


  drawMenu(line: PIXI.Graphics, container: PIXI.Graphics) {
    const points = this.ObjectMetaPropsContainer[container.name].points
    const center = this.getShapeCenter(points);
    const menu = new PIXI.Graphics();
    const menuYOffset = 15;
    const menuY = center.y + ((center.maxY - center.minY) / 2) + menuYOffset
    const menuHeight = 30;
    const menuWidth = 55;
    menu.beginFill(0x645f73);
    menu.lineStyle(0);
    menu.drawRoundedRect(0, 0, menuWidth, menuHeight, 5);
    menu.position.set(center.minX, menuY);
    menu.endFill();

    menu.alpha = 0;

    const containerId = this.getContainerIdFromName(container.name);

    // Menu icons
    // const hFlip = new PIXI.Sprite();
    // hFlip.texture = this.hFlipSprite.texture;
    // hFlip.scale.set(0.03);
    // hFlip.anchor.set(0,0.5);
    // hFlip.y = menuHeight / 2;
    // hFlip.x = 10;
    // hFlip.interactive = true;
    // hFlip.name = this.getName(ENamingTypes.MENU_HFLIP, containerId)

    // const vFlip = new PIXI.Sprite();
    // vFlip.texture = this.vFlipSprite.texture;
    // vFlip.scale.set(0.03);
    // vFlip.anchor.set(0,0.5);
    // vFlip.y = menuHeight / 2;
    // vFlip.x = hFlip.x + hFlip.width + 5;
    // vFlip.interactive = true;
    // vFlip.name = this.getName(ENamingTypes.MENU_VFLIP, containerId)

    const dupe = new PIXI.Sprite();
    dupe.texture = this.dupeSprite.texture;
    dupe.scale.set(0.03);
    dupe.anchor.set(0,0.5);
    // dupe.y = menuHeight / 2;
    // dupe.x = vFlip.x + vFlip.width + 5;
    dupe.y = menuHeight / 2;
    dupe.x = 10;
    dupe.interactive = true;
    dupe.name = this.getName(ENamingTypes.MENU_DUPE, containerId)

    const del = new PIXI.Sprite();
    del.texture = this.deleteSprite.texture;
    del.scale.set(0.03);
    del.anchor.set(0,0.5);
    del.y = menuHeight / 2;
    del.x = dupe.x + dupe.width + 5;
    del.interactive = true;
    del.name = this.getName(ENamingTypes.MENU_DEL, containerId)

    // menu.addChild(hFlip);
    // menu.addChild(vFlip);
    menu.addChild(dupe);
    menu.addChild(del);
    // this.addMenuListeners(line, ACTIONS.FLIPH, hFlip, points);
    // this.addMenuListeners(line, ACTIONS.FLIPV, vFlip, points);
    this.addMenuListeners(line, ACTIONS.DUPLICATE, dupe, points, container);
    this.addMenuListeners(container, ACTIONS.DELETE, del, points);

    return menu;
  }

  addMenuListeners(obj: PIXI.Graphics, action: ACTIONS, option: PIXI.Sprite, points: IPoint[], container?: PIXI.Graphics) {
    const center = this.getShapeCenter(points);
    let func: Function;
    let flipped = false;
    option.cursor = 'pointer';
  
    // if(action == ACTIONS.FLIPH) {
    //   func = (e) => {
    //     if(!flipped) {
    //       obj.scale.y = -1;
    //       obj.y = center.y * 2;
    //     } else {
    //       obj.scale.y = 1;
    //       obj.y = 0;
    //     }
    //     flipped = !flipped

    //     this.render();
    //   }
    // } else if(action == ACTIONS.FLIPV) {
    //   func = (e) => {
    //     if(!flipped) {
    //       obj.scale.x = -1;
    //       // obj.x = center.x * 2;
    //       this.render();
    //     } else {
    //       obj.scale.x = 1;
    //       // obj.x = 0;
    //       this.render();
    //     }

    //     flipped = !flipped
    //   }
    // } 
    if(action == ACTIONS.DUPLICATE) {
      func = (e) => {
        const fillColor = this.getFillColor(container);
        const dupPosition = this.getDuplicateObjectPosition((e.currentTarget as PIXI.Sprite))
        const lineContainer = this.completeLine([...points], false, true, dupPosition, false, fillColor);
        this.render();
      }
    } else if(action == ACTIONS.DELETE) {
      func = (e) => {
        // get line container
        const lineContainer = this.getContainerFromName((e.currentTarget as PIXI.Sprite).name);
        this.removeContainer(lineContainer);
        this.updateState();
        this.render();
      }
    }
    option.on('pointerup', func);
  }

  deleteObject(obj: PIXI.Graphics) {
    obj.removeChildren();
    obj.clear();
  }

  addSelectLineListener(line: PIXI.Graphics, container: PIXI.Graphics) {
    line.on('pointerdown', ($event) => super.onPointerDown($event))
  }

  clearActiveSelection = (container: PIXI.Graphics) => {
    this.removeLineMenu(container);
  }

  addActiveSelection = (container: PIXI.Graphics) => {
    this.addLineMenu(container)
  }


  addLineMenu(lineContainer:PIXI.Graphics){
    // get lineMenuContainer Object
    const containerId = this.getContainerIdFromName(lineContainer.name);
    const lineMenuContainerName = this.getName(ENamingTypes.MENU, containerId)
    let lineMenuContainer = <PIXI.Container>lineContainer.getChildByName(lineMenuContainerName);
    const lineName = this.getName(ENamingTypes.TOOL, containerId)
    const line = <PIXI.Graphics>lineContainer.getChildByName(lineName);

    
    // new menu container childs
    const outterBorder = this.drawSelectedLineBorder(lineContainer, this.getFillColor(lineContainer));
    outterBorder.name = 'outterBorder'
    const menu = this.drawMenu(line, lineContainer);
    outterBorder.alpha = 1;
    menu.alpha = 1;

    
    if(!lineMenuContainer){
      // add new menu container inside lineContainer
      lineMenuContainer =  new PIXI.Container();
      lineMenuContainer.name = this.getName(ENamingTypes.MENU, containerId);
      lineContainer.addChild(lineMenuContainer);
    }
    lineMenuContainer.addChild(...[outterBorder, menu])
  }

  removeLineMenu(lineContainer:PIXI.Graphics){
    const containerId = this.getContainerIdFromName(lineContainer.name);
    const lineMenuContainerName = this.getName(ENamingTypes.MENU, containerId)
    let lineMenuContainer = <PIXI.Container>lineContainer.getChildByName(lineMenuContainerName);
    if(lineMenuContainer) lineMenuContainer.removeChildren()
  }

  drawSelectedLineBorder(lineContainer: PIXI.Graphics, fillColor: number, resizingPoints?: IPoint[]) {
    let points;

    if(resizingPoints?.length){
      points = resizingPoints
    } 
    else{
      points = this.ObjectMetaPropsContainer[lineContainer.name].points
    } 
    const pointA = points[1];
    const pointB = points[0];
    let rotation = Math.atan2(pointB.y - pointA.y, pointB.x - pointA.x) + Math.PI;
    
    rotation = this.snapRotation(rotation);
    const distanceX = Math.pow(pointB.x - pointA.x, 2);
    const distanceY = Math.pow(pointB.y - pointA.y, 2);
    const line = new PIXI.Graphics();
    const length = Math.sqrt(distanceX + distanceY);
    const height = 2;

    // This is to avoid the alpha being added up for the line outline and
    // the rotation point out line.
    const colorMatrix = new PIXI.filters.AlphaFilter();
    colorMatrix.alpha = 0.5;
    line.filters = [colorMatrix];

    line.beginFill(fillColor, 0.2);
    line.drawRect(pointB.x, pointB.y - (height / 2) - 3.5, length, 8)
    line.drawCircle(pointB.x, pointB.y, 6)
    line.drawCircle(pointB.x + length, pointB.y, 6)

    line.pivot.set(pointB.x, pointB.y);
    line.position.set(pointB.x, pointB.y);
    line.rotation = rotation;

    return line;
  }

  isDrawingLine: boolean;
  lineAndPoints: [{ points: [{x: any, y:any}], line: PIXI.Graphics}];
  currentLinePoints;
  hoverVertice: PIXI.Graphics;
  lineVisulizer: PIXI.Graphics;
  // Draws (adds) a single vertice on the stage.
  drawLineVertices(vertices: PIXI.Graphics, point: IPoint, isHover?: boolean) {

    const vertice = new PIXI.Graphics();
    const fillAlpha = isHover ? 0.5 : 1;
    vertice.beginFill(0x0000, fillAlpha);
    vertice.drawCircle(point.x, point.y, 3);
    vertice.endFill();
    vertice.interactive = true;
    vertice.zIndex = 6;
    vertices.addChild(vertice);

    // If both diagonal corner points are set, draw a rectangle
    if (this.currentLinePoints.length == 2){
      if (this.lineVisulizer) this.lineVisulizer.destroy();
      const topLeftPoint = this.currentLinePoints[0];
      const bottomRightPoint = this.currentLinePoints[1];
      const hoverLine = new PIXI.Graphics();
      hoverLine.lineStyle(2, 0x0000, fillAlpha);
      hoverLine.moveTo(topLeftPoint.x, topLeftPoint.y);
      hoverLine.lineTo(bottomRightPoint.x, bottomRightPoint.y);
      this.lineVisulizer = hoverLine
      vertices.addChild(hoverLine);
    }

    this.render();
    return vertice;
  }

  points: IPoint[] = [];
  addDrawVertexListener() {
    const addVerticeMouseDown = (e) => {
      if (!this.isDrawMode || this.isGlobalRotating || this.isGlobalDragging) return;
      this.isDrawingLine = true;
      this.currentLinePoints = [];
      const {x,y} = e.data.getLocalPosition(this.stage);
      const point = {x, y};
      this.points.push(point);
      this.currentLinePoints.push(point);
      this.drawLineVertices(this.vertices, point);
      this.stage.on('pointermove', addHoverVertice);
      this.stage.on('pointerup', addVerticeMouseUP);
    }

    const addVerticeMouseUP = (e) => {
      if (!this.isDrawMode || this.isGlobalRotating || this.isGlobalDragging) return;
      if(this.isDrawMode && !this.isGlobalDragging && !this.isGlobalRotating && this.isDrawingLine) {
        const {x,y} = e.data.getLocalPosition(this.stage);
        const point = {x, y};
        this.points.push(point);
        if (this.currentLinePoints.length > 1) this.currentLinePoints.pop();
        this.currentLinePoints.push(point);
        this.drawLineVertices(this.vertices, point);
        this.isDrawingLine = false;

        this.completeLine(this.currentLinePoints);
        this.currentLinePoints = [];
        this.points = [];

        // this.isDrawMode = false;
        this.stage.cursor = 'default';
        this.backgroundSprite.cursor = 'default';
        this.render();
      }

      this.stage.removeListener('pointermove', addHoverVertice);
      this.stage.removeListener('pointerup', addVerticeMouseUP);
    }

    const addHoverVertice = (e) => {
      if (!this.isDrawMode || this.isGlobalRotating || this.isGlobalDragging) return;

      if(this.isDrawMode && !this.isGlobalDragging && !this.isGlobalRotating && this.isDrawingLine) {
        const {x,y} = e.data.getLocalPosition(this.stage);
        const point = {x, y};
        this.points.push(point);
        if (this.currentLinePoints.length > 1) this.currentLinePoints.pop();
        this.currentLinePoints.push(point);

        if (this.hoverVertice) this.hoverVertice.destroy();
        this.hoverVertice = this.drawLineVertices(this.vertices, point, true);
      }
    }

    this.stage.on('pointerdown', addVerticeMouseDown);
  }

  isDrawMode = false;
  toggleDrawMode(mode?: boolean) {
    this.isDrawMode = mode == null ? !this.isDrawMode : mode;
    if(!this.isDrawMode) {
        this.stage.cursor = 'default';
        this.backgroundSprite.cursor = 'default';
    } else {
        this.stage.cursor = 'pointer';
        this.backgroundSprite.cursor = 'pointer';
    }
    this.vertices.removeChildren();
    this.lines.removeChildren();
    this.points = [];

    this.render(); 

    return this.isDrawMode;
  }

  clearDrawnLines() {
    // this.objectContainer.forEach((line) => {
    //   this.deleteObject(line);
    //   line.destroy();
    // });
    // this.objectContainer = [];
    // this.render();
  }

  getLineTools() {
    return this.lineTools;
  }

  drawRotationAndResizePoints(head: IPoint, tail: IPoint, lineContainer: PIXI.Graphics, noListener?: boolean, exline?: any) {
    
    // The rotation and resize points need to be perciesly at the head and tail of the line,
    const resizePointA = {x: exline.x, y: exline.y};
    const resizePointB_X = resizePointA.x + (exline.width * Math.cos(exline.rotation));
    const resizePointB_Y = resizePointA.y + (exline.width * Math.sin(exline.rotation));
    const resizePointB = {x: resizePointB_X, y: resizePointB_Y}

    const rotationResizePoint = new PIXI.Graphics();
    const containerID = this.getContainerIdFromName(lineContainer.name)
    rotationResizePoint.name = this.getName(ENamingTypes.RESIZE_ANCHOR, containerID);
    rotationResizePoint.beginFill(0x0000, 1);
    rotationResizePoint.drawCircle(resizePointA.x, resizePointA.y - 0.5, 3);
    rotationResizePoint.drawCircle(resizePointB.x, resizePointB.y - 0.5, 3);
    rotationResizePoint.endFill();

    if (!noListener) this.addLineDragAndRotateListener(lineContainer, head, tail, rotationResizePoint);
    return rotationResizePoint;
  }


  deltaX: number;
  deltaY: number;
  previousLineContainerResizeAnchor: PIXI.DisplayObject;
  lineContainersDragStatus = new Map();
  // TODO: needs refactoring
  addLineDragAndRotateListener(lineContainer: PIXI.Graphics, head: IPoint, tail: IPoint, rotatePoint?: PIXI.Graphics) {
    this.isGlobalDragging = false;
    let isRotateDragging = false;
    let initialAngle = 0;
    lineContainer.cursor = 'grab';
    if(!this.isDrawMode) lineContainer.interactive = true;

    rotatePoint.cursor = 'ew-resize'
    rotatePoint.interactive = this.isSelectionActive;
    this.lineContainersDragStatus.set(lineContainer.name, false);
    initialAngle = Math.atan2(lineContainer.pivot.y - rotatePoint.y, lineContainer.pivot.x - rotatePoint.x) + Math.PI;

    let isDraggingOrigin = false
    let isDragged = false;
    const onRotateAndResizeStart = (e) => {
      this.isRotatingPointerDown = true;
      this.isResizingPointerDown = true;
      const isLineComplete = this.isLineComplete(lineContainer); 
      if(isLineComplete && this.isDrawMode) return; // cannot resize when drawMode 
      const mousePosition = e.data.getLocalPosition(this.stage);
      const lineContainerOrigin = {x: lineContainer.x, y:lineContainer.y};
      if (Math.abs(mousePosition.x - lineContainerOrigin.x) < 10 && Math.abs(mousePosition.y - lineContainerOrigin.y) < 10){
        isDraggingOrigin = true;
      } else {
        isDraggingOrigin = false;
      }
      isRotateDragging = true;
      this.isGlobalRotating = true;
      lineContainer.removeAllListeners();

      this.stage
      .on('pointermove', onRotateAndResize)
      .on('pointerup', onRotateAndResizeEnd);
    }
    const onRotateAndResizeEnd = (e) => {
      if(isRotateDragging){        
        this.isRotatingPointerDown = false;
        this.isResizingPointerDown = false;
        isRotateDragging = false;
        this.isGlobalRotating = false;
        if ((<PIXI.Graphics>this.previousLineContainerResizeAnchor)?.geometry) this.previousLineContainerResizeAnchor.destroy();
        this.lineContainersOnResizing.removeChildren();

        if (!isDragged){
          this.stage
            .removeListener('pointerup', onRotateAndResizeEnd)
            .removeListener('pointermove', onRotateAndResize);
          return
        }

        const drawPoints = this.getNewDrawPoints(lineContainer, isDraggingOrigin, head, tail)
        const fillColor = this.getFillColor(lineContainer);
        const newLine = this.completeLine(drawPoints, false, false, null, !this.isDrawMode, fillColor);
        this.removeContainer(lineContainer);
        this.updateState();
        this.render();
        this.stage
        .removeListener('pointerup', onRotateAndResizeEnd)
        .removeListener('pointermove', onRotateAndResize);
      }
    }
    const onRotateAndResize = (e: PIXI.InteractionEvent) => {
      if(isRotateDragging) {

        isDragged = true;

        this.lineContainersOnResizing.removeChildren();
        const mousePosition = e.data.getLocalPosition(this.stage);
        const mouseAngle = Math.atan2(lineContainer.y - mousePosition.y, lineContainer.x - mousePosition.x) + Math.PI;
        this.deltaX = mousePosition.x - lineContainer.x; 
        this.deltaY = mousePosition.y - lineContainer.y;
        rotatePoint.alpha = 0;

        const containerId = this.getContainerIdFromName(lineContainer.name)
        lineContainer.children.forEach((child, i) => {
          if (child.name != this.getName(ENamingTypes.RESIZE_ANCHOR, containerId)){
            lineContainer.getChildAt(i).destroy()
          } else {
              this.previousLineContainerResizeAnchor = child;
          }
        })

        console.log('dragging')
        const drawPoints = this.getNewDrawPoints(lineContainer, isDraggingOrigin, head, tail)
        const fillColor = this.getFillColor(lineContainer);
        const newLineContainer = this.completeLine(drawPoints, true, false, null, false, fillColor);
        this.drawSelectedLineBorder(newLineContainer, fillColor, drawPoints);

        // console.log(lineContainer.x, lineContainer.y, lineContainer.width, lineContainer.height)
        // lineContainer.rotation = mouseAngle - initialAngle;
        this.render();
      }
    }

    rotatePoint.on('pointerdown', onRotateAndResizeStart)
  }

  isLineComplete(lineContainer: PIXI.Graphics){
    // checks if the line is drawn or only single point is added on the stage
    // inferring from the logic of the component there should be maximum of two point
    const [pointA, pointB]  = this.ObjectMetaPropsContainer[lineContainer.name].points
    if(pointA.x === pointB.x && pointA.y === pointB.y) return false

    // maybe check length
    return true
  }

  getNewDrawPoints(lineContainer: PIXI.Graphics, isDraggingOrigin: boolean, head, tail){
    const lineContainerOrigin = {x: lineContainer.x, y:lineContainer.y};
    let pointA = {x: lineContainerOrigin.x, y: lineContainerOrigin.y};
    const pointB = {x: lineContainerOrigin.x + this.deltaX, y: lineContainerOrigin.y + this.deltaY};
    const diffX = tail.x - head.x;
    const diffY = tail.y - head.y;

    if (isDraggingOrigin){
      pointA = {x: lineContainerOrigin.x + diffX, y: lineContainerOrigin.y + diffY};
    }

    return [pointA, pointB];
  }

  loadAssets() {
    return this.spriteLoader.loadSprites()
  }

  isSelectionActive = false
  // deactivate interactive listners
  deactivateSelectionListners(){
    this.getObjectContainer().forEach((container: PIXI.Graphics) => {
      if(this._isSelected(container)) this.toggleSelection(container, EToggleMode.OFF)
      container.interactive = false
      container.children.forEach(child => { 
        child.interactive = false
      })
    })
    this.isSelectionActive = false;
  }

  activateSelectionListners(){
    this.getObjectContainer().forEach((container: PIXI.Graphics) => this.activateSelectionListner(container));
    this.isSelectionActive = true;
  }
  
  changeDrawnGraphicColor(currentSelectedObject: PIXI.Graphics){
    if (!currentSelectedObject.name) return;

    const lineDisplayObject = currentSelectedObject.children.filter(child => child.name.includes(ENamingTypes.TOOL))[0]
    const graphicAndPoints = this.lineGraphicMap.get(lineDisplayObject.name);
    let lineGraphic = graphicAndPoints.graphic;
    const points = graphicAndPoints.points;

    const pos_x = lineGraphic.x;
    const pos_y = lineGraphic.y;
    const alpha = lineGraphic.alpha;
    const name = lineGraphic.name;
    const pivot = lineGraphic.pivot;
    const rotation = lineGraphic.rotation;

    lineGraphic.clear();

    const transformedPoints = []
    points.map(point => {
      transformedPoints.push({x: point.x + pos_x, y: point.y + pos_y});
    })

    const pointA = transformedPoints[1];
    const pointB = transformedPoints[0];
    const distanceX = Math.pow(pointB.x - pointA.x, 2);
    const distanceY = Math.pow(pointB.y - pointA.y, 2);
    const length = Math.sqrt(distanceX + distanceY);
    const height = 2;
    lineGraphic.beginFill(this.selectedColor, this.ObjectMetaPropsContainer[currentSelectedObject.name].fillOpacity);
    lineGraphic.drawRect(pointB.x, pointB.y - (height / 2), length, height);
    lineGraphic.endFill();

    // Draw a thiker line behind the line and set it to invisible, so it's easier to click on the line.
    lineGraphic.beginFill(0xfffff, 0);
    lineGraphic.drawRect(pointB.x, pointB.y - (height / 2) - 5, length, 10)
    lineGraphic.pivot = pivot;
    lineGraphic.position.set(pointB.x, pointB.y);
    lineGraphic.rotation = rotation;
  
    lineGraphic.x += pos_x;
    lineGraphic.y += pos_y;
    lineGraphic.pivot.set(pos_x, pos_y);
    lineGraphic.zIndex = 4;
    lineGraphic.interactive = true;
    lineGraphic.name = name;

    const fillColor = this.selectedColor;
    this.ObjectMetaPropsContainer[currentSelectedObject.name].color = fillColor;

    const lineMenuContainer = <PIXI.Container>currentSelectedObject.children.filter(child => child.name.includes(ENamingTypes.MENU))[0];
    const outterBorderGraphic = <PIXI.Graphics>lineMenuContainer.getChildByName('outterBorder');

    outterBorderGraphic.clear();
    const colorMatrix = new PIXI.filters.AlphaFilter();
    colorMatrix.alpha = 0.5;
    outterBorderGraphic.filters = [colorMatrix];

    outterBorderGraphic.beginFill(fillColor, 0.2);
    outterBorderGraphic.drawRect(pointB.x, pointB.y - (height / 2) - 3.5, length, 8)
    outterBorderGraphic.drawCircle(pointB.x, pointB.y, 6)
    outterBorderGraphic.drawCircle(pointB.x + length, pointB.y, 6)

    outterBorderGraphic.pivot.set(pointB.x, pointB.y);
    outterBorderGraphic.position.set(pointB.x, pointB.y);
    outterBorderGraphic.rotation = rotation;

    this.render();
  }
  getUpdatedState(entry: Partial<IEntryStateVirtualTools>): Partial<IEntryStateVirtualTools> {
    entry.data[EPixiTools.LINE] = Object.values(this.ObjectMetaPropsContainer);
    return entry;
  }

  handleNewState() {
    const data = this.getQuestionState(EPixiTools.LINE);
    if(!data || data.length == 0) {
      return;
    }

    data.forEach((line) => {
      const temp = JSON.parse(JSON.stringify(line));
      this.completeLine(temp.points, false, true, {x: temp.x, y: temp.y}, false, temp.color);
    });

    this.render();
  }
}
