import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LangService } from '../../core/lang.service';
import { AuthService } from 'src/app/api/auth.service';
import { RoutesService } from 'src/app/api/routes.service';
import { Dictionary } from './controllers/dictionary';
import { DictionaryEN } from './controllers/dictionary-en';
import { DictionaryFR } from './controllers/dictionary-fr';

export interface SelectedWord {
  word: string,
  json: any,
  isActive: boolean
}

@Component({
  selector: 'widget-dictionary',
  templateUrl: './widget-dictionary.component.html',
  styleUrls: ['./widget-dictionary.component.scss']
})
export class WidgetDictionaryComponent implements OnInit, OnDestroy {
  
  @Input() zoom:number;
  @Input() isZoomed:boolean;
  @Input() isShowSynonyms_FR?: boolean;
  @ViewChild('resultBox') resultBox: ElementRef<HTMLDivElement>;

  constructor(
    public lang: LangService,
    private auth: AuthService,
    private route: RoutesService,
  ) { }

  input;
  suggestions: [];
  selectedWord: SelectedWord;
  dictionaryManager: DictionaryEN | DictionaryFR;
  currentWordDefinition

  ngOnInit() {
    this.selectedWord = {
      word: '',
      json: undefined,
      isActive: false
    }

    switch(this.lang.getCurrentLanguage()){
      case 'en':
        this.dictionaryManager = new DictionaryEN(this.lang, this.route, this.auth);
        return;
      case 'fr':
        this.dictionaryManager = new DictionaryFR(this.lang, this.route, this.auth);
        return;
      default:
        this.dictionaryManager = new DictionaryEN(this.lang, this.route, this.auth);
    } 
  }

  ngAfterViewInit(){}
  ngOnDestroy() {}

  isLoadingSuggestions: boolean = false;
  showSearchInput: boolean = false;
  currentUserInput: string = '';
  onUserInput(e: KeyboardEvent){

    let userData = (<HTMLInputElement>e.target).value; //user enetered data
    this.currentUserInput = userData;

    if (e.key && e.key == 'Enter'){
      this.getSuggestions(userData)
    }

    if(!userData || userData == ''){
      this.showSearchInput = false;
    }
  }

  getSuggestions(userInput?: string){
    const searchWord = userInput ? userInput : this.currentUserInput;
    this.isLoadingSuggestions = true;
    this.showSearchInput = true;

    this.dictionaryManager.getSuggestions(searchWord).then((res => {
      this.isLoadingSuggestions = false;
      this.suggestions = res.map(suggestion => suggestion.word);
      const searchWordCandidates = res.filter(suggestion => suggestion.is_searched).map(suggestion => suggestion.word); // not ideal
      const actualSearchWord = searchWordCandidates[0] || searchWord;
      const exactWordMatch = this.suggestions.filter(suggestion => suggestion === actualSearchWord)[0];
      if (exactWordMatch){
        this.selectWord(exactWordMatch);
        return;
      }
      
    })).catch((error)=>{
      this.isLoadingSuggestions = false;
      console.error('Error retrieving data');
    })
  }

  userValue
  showSuggestions(list){
      let listData;
      if(!list.length){
          this.userValue = this.input.value;
          listData = '<li>'+ this.userValue +'</li>';
      }else{
          listData = list.join('');
      }
      this.resultBox.nativeElement.innerHTML = listData;
  }

  selectWord(suggestion: string){
    this.dictionaryManager.getWordData(suggestion).then(res => {
      try {
        const processedJSON = this.dictionaryManager.preprocessResponseRawJSON(res[0].raw_json);
        const wordDictData_json = JSON.parse(processedJSON)
        this.dictionaryManager.selectedWord = {
          word: suggestion,
          json: wordDictData_json,
          isActive: true,
        }
        this.showSearchInput = false;
        this.currentWordDefinition = this.dictionaryManager.getCurrentWordDefinition()
      } catch (error) {
        console.log(error)
      }
    })
  }
}
