<div class="option-container" style="position: relative;" [style.height.em]="element.size">
    <div *ngFor="let optionElement of element.options; let index = index" 
        [style.position]="'absolute'"
        [style.left.em]="optionElement.xBubble" 
        [style.top.em]="optionElement.yBubble" 
    >
        <!--<render-image class="image-option" *ngIf="isSelected(optionElement)" (click)="selectOptionManual(optionElement)" [element]="optionElement.urlSelected"></render-image>
        <render-image class="image-option" *ngIf="!isSelected(optionElement)" (click)="selectOptionManual(optionElement)" [element]="optionElement.urlNotSelected"></render-image>
        -->
        <div 
          (click)="!isLocked ? selectOptionManual(optionElement) : ''" 
          class="pointer"
          tabindex="1"
          [class.is-selected-edit]="editSelection.isSelectedEdit(optionElement, this.questionPubSub)"
          [style.border]="editSelection.getBorder(optionElement, this.questionPubSub)"
        >
            <element-render-image
                [element]="optionElement.dynamicImage" 
                [isSelected]="isSelected(optionElement)"
                [questionPubSub]="questionPubSub"
            ></element-render-image>
        </div>
    </div>
    <div *ngFor="let optionElement of element.options; let index = index">
        <div [ngStyle]="{'left.em':optionElement.xBubble, 'top.em':optionElement.yBubble }">
            <div *ngFor="let subtext of optionElement.subtext; let index = index">
                <div 
                    (click)="!isLocked ? selectOptionManual(optionElement) : ''"
                    [style.position]="'absolute'" 
                    [style.left.em]="subtext.x/subtext.size" 
                    [style.top.em]="subtext.y/subtext.size" 
                    [style.font-size.em]="subtext.size"
                    [style.white-space]="'pre'"
                    [style.color]="subtext.colourScheme ? subtext.colourScheme.textColor : '#000000'">
                    {{subtext.text}}
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="getIsMaxMsgShowing()" (click)="turnMaxMsgOff()" class="mcq-max-selected-msg" audio-slug [slug]="getMaxMsgSlug()">
    <tra-md [slug]="getMaxMsgSlug()" [isCondensed]="true" [isFlex]="true"></tra-md>
</div>
