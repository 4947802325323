import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginFormComponent } from './login-form/login-form.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { UiPartialModule } from '../ui-partial/ui-partial.module';
import { MultiFactorAuthenticationComponent } from './multi-factor-authentication/multi-factor-authentication.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule, 
    ReactiveFormsModule,
    UiPartialModule,
  ],
  declarations: [
    LoginFormComponent,
    MultiFactorAuthenticationComponent
  ],
  exports: [
    LoginFormComponent,
    MultiFactorAuthenticationComponent
  ]
})
export class UiLoginModule { }
