import * as PIXI from "pixi.js-legacy";
import { IContentElementVirtualTools, IDrawing, IEntryStateVirtualTools } from "../model";
import { EToolType, IPoint, VirtualTools } from "./virtual-tools";
import * as _ from "lodash";
import { SpriteLoader } from "../../element-render-custom-interaction/controllers/sprite-loader";
import { BLACK, DEFAULT_TICK_MEASURE, LAYER_LEVEL, WHITE } from '../types/constants'
import { OnInit } from "@angular/core";
import { IExtendedTools } from "../element-render-virtual-tools.component";
import { ENamingTypes, EPixiTools, IDrawnMeta } from "../types/types";
import { QuestionState } from "../../models";

export enum DRAW_MODES {
  PENCIL = "pencil",
  MARKER = "marker",
  HIGHLIGHTER = "HIGHLIGHTER",
}

export class FreehandPencil extends VirtualTools{
  element: IContentElementVirtualTools;
  spriteLoader: SpriteLoader
  backgroundSprite: PIXI.Sprite;
  hcPrevState: boolean;
  staticBg: PIXI.Graphics;
  isDrawMode = false;
  isEraseMode = false;
  pencilTools: IExtendedTools;
  pencilColor = '#000000'
  pencilThickness = 1; // could be three
  strokeRadius = 1.5;
  eraseCursor: PIXI.Graphics;
  drawCursor: PIXI.Graphics;
  cursor: PIXI.Graphics;
  clicked = false;

  constructor(
    questionState: QuestionState,
    element: IContentElementVirtualTools,  
    addGraphic, 
    render,
    getToolStateSub,
    stage,
    isLocked, 
    textToSpeech,
    isGlobalRotating: boolean, 
    isGlobalDragging: boolean,
    backgroundSprite: PIXI.Sprite,
    staticBg: PIXI.Graphics,
    layerLevel: LAYER_LEVEL,
    spriteLoader: SpriteLoader
  ) {
    super(questionState, addGraphic, render, getToolStateSub, stage, isLocked, textToSpeech, isGlobalRotating, isGlobalDragging);
    this.initTool({name: EPixiTools.PENCIL, type: EToolType.TOGGLE, layerLevel: layerLevel});
    this.questionState = questionState;
    //#stopping Ticker
    PIXI.Ticker.system.autoStart = false
    PIXI.Ticker.system.stop()
    this.hcPrevState = this.textToSpeech.isHiContrast;  

    this.element = element;
    this.isGlobalRotating = isGlobalRotating;
    this.isGlobalDragging = isGlobalDragging;
    this.staticBg = staticBg;
    this.backgroundSprite = backgroundSprite

    this.spriteLoader = spriteLoader;
    // Cursors
    this.cursor = new PIXI.Graphics();
    this.cursor.zIndex = this.getContainerZindex(7) * 1000; // the cursor should always be on top of the lines

    this.eraseCursor = new PIXI.Graphics();
    this.eraseCursor.lineStyle(1, BLACK, 1)
    this.eraseCursor.drawCircle(0,0, this.strokeRadius + 2);
    this.eraseCursor.endFill();
    this.eraseCursor.alpha = 0;

    this.drawCursor = new PIXI.Graphics();
    this.drawCursor.beginFill(this.selectedColor)
    this.drawCursor.lineStyle(1, WHITE, 1)
    this.drawCursor.drawCircle(0,0, this.strokeRadius);
    this.drawCursor.endFill();
    this.drawCursor.alpha = 0;

    this.cursor.addChild(this.eraseCursor);
    this.cursor.addChild(this.drawCursor);

    this.addGraphic(this.cursor);

    this.pencilTools = {
      parent: 'pencil',
      colors: [
        '#000000',
        '#c43f2e',
        '#2a91d6'
      ],        
      colorHandler: (color: string) => {
        this.pencilColor = color;
      },
      tools: [
      {
        id: 'delete',
        action: () => {},
        iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/delete/1684351529074/delete.png',
        isSelected: () => false,
      },
      {
        id: 'colorPicker',
        isColorPick: true,
        colorController: this.pencilColor,
        action: () => {},
        isSelected: () => false,
      },
      {
        id: 'eraser',
        action: () => this.toggleEraseMode(),
        isSelected: () => this.isEraseMode,
        iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/eraser/1687449970161/eraser.png',
      },
    ]}

    this.addClickListener();
    this.addCursorListener();
    this.addDrawListener();

    this.render();
  }

  hideCursors() {
    this.eraseCursor.alpha = 0;
    this.drawCursor.alpha = 0;
  }

  isSelected_Pencil() {
    return this.isDrawMode && this.activeMode === DRAW_MODES.PENCIL;
  }

  isSelected_Marker() {
    return this.isDrawMode && this.activeMode === DRAW_MODES.MARKER;
  }

  isSelected_Highlighter() {
    return this.isDrawMode && this.activeMode === DRAW_MODES.HIGHLIGHTER;
  }

  activeMode = undefined;
  toggleDrawMode(toolMode: DRAW_MODES, toggle?: boolean) {
    if(this.activeMode && this.activeMode != toolMode){
      this.determinAndSetDrawMode(toolMode);
      return; 
    } 
    this.isEraseMode = false;
    this.isDrawMode = toggle == null ? !this.isDrawMode : toggle;
    this.hideCursors();

    if(this.isDrawMode) {
      this.drawCursor.alpha = 1;
      this.determinAndSetDrawMode(toolMode);
      this.activeMode = toolMode;
    } else {
      this.staticBg.cursor = 'default';
      this.lines.forEach(line => line.cursor = 'default');
      this.toggleEraseMode(false);
      this.activeMode = undefined;
    }

    return this.isDrawMode;
  }

  determinAndSetDrawMode(toolMode: DRAW_MODES){
    switch (toolMode){
      case DRAW_MODES.PENCIL:
        this.togglePencilMode();
        break;
      case DRAW_MODES.MARKER:
        this.toggleMarkerMode();
        break;
      case DRAW_MODES.HIGHLIGHTER:
        this.toggleHighlighterMode();
        break;
    }
  }

  togglePencilMode(toggle?: boolean) {
    this.strokeRadius = 1.5;
    this.activeMode = DRAW_MODES.PENCIL;
    this.strokeOpacity = 1;

    this.updateDrawCursor();
  }

  toggleMarkerMode(toggle?: boolean) {
    this.strokeRadius = 3;
    this.activeMode = DRAW_MODES.MARKER;
    this.strokeOpacity = 1;

    this.updateDrawCursor();
  }

  strokeOpacity = 1;
  toggleHighlighterMode(toggle?: boolean) {
    this.strokeRadius = 6;
    this.activeMode = DRAW_MODES.HIGHLIGHTER;
    this.strokeOpacity = this.element.fhHighlighterOpacity? this.element.fhHighlighterOpacity : 0.25;

    this.updateDrawCursor();
  }

  updateDrawCursor(){
    if(!this.drawCursor) return;
    this.drawCursor.clear()
    this.drawCursor.beginFill(this.selectedColor, this.strokeOpacity)
    this.drawCursor.lineStyle(1, WHITE, 1)
    this.drawCursor.drawCircle(0,0, this.strokeRadius);
    this.drawCursor.endFill();

    this.render();
  }

  toggleEraseMode(toggle?: boolean, toggleDrawMode = false) {
    this.isDrawMode = false;
    this.isEraseMode = toggle == null ? !this.isEraseMode : toggle;
    this.hideCursors();

    if(this.isEraseMode) {
      // make sure the drawn strokes are interactable
      this.getObjectContainer().forEach((container: PIXI.Graphics) => {
        container.interactive = true;
        container.children.forEach(child => { 
          child.interactive = true;       
        })
      })

      this.eraseCursor.alpha = 1;
    } else {
      this.staticBg.cursor = 'default';
      this.lines.forEach(line => line.cursor = 'default')
      if(toggleDrawMode) {
        this.toggleDrawMode(undefined, true);
      }
    }

    return this.isEraseMode
  }

  undo() {
    const line = this.lines.pop();
    if(line) {
      line.clear();
      line.destroy();
    }
  }

  addCursorListener() {
    const trackCursor = (e) => {
      if(this.isDrawMode || this.isEraseMode) {
        const mousePosition = e.data.getLocalPosition(this.stage);
        this.cursor.position.set(mousePosition.x, mousePosition.y);
        this.staticBg.cursor = 'none';
        this.lines.forEach(line => line.cursor = 'none')
        this.render();
      }
    }
    this.staticBg.on('pointermove', trackCursor)
    this.backgroundSprite.on('pointermove', trackCursor)
  }


  removeLineFromDrawdata(lineIndex: number){
    const targetIndex = this.drawData.findIndex(line => line.lineIndex === lineIndex);
    this.drawData = this.drawData.length == 1 ? [] : [...this.drawData.slice(0, targetIndex), ...this.drawData.slice(targetIndex+1)];
  }

  addEraseListener(obj: PIXI.Graphics, lineIndex: number) {
    const erase = () => {
      // console.log('pointer over')
      if(this.clicked && this.isEraseMode) {
        this.removeLineFromDrawdata(lineIndex);
        this.updateState();
        obj.destroy();
      }
    }

    const eraseOnClick = () => {
      if(this.isEraseMode) {
        this.removeLineFromDrawdata(lineIndex);
        this.updateState();
        obj.destroy();
      }
    }
    obj.on('pointerover', erase).on('pointerdown', eraseOnClick)
  }

  addClickListener() {
    this.clicked = false;
    const onClick = () => {
      this.clicked = true;
    }

    const onRelease = () => {
      this.clicked = false;
    }

    this.stage.on('pointerdown', onClick).on('pointerup', onRelease)
  }


  lastPoint: IPoint = {x: null, y: null};
  draw(x, y, container: PIXI.Graphics, color: number, isRestore?: boolean) {
    let distance;
    if(this.lastPoint.x != null && this.lastPoint.y != null){
      const distX = Math.pow(this.lastPoint.x - x,2)
      const distY = Math.pow(this.lastPoint.y - y,2)
      distance = Math.sqrt(distX + distY);

      if(distance < 0.1){
        this.lastPoint = {x: x, y: y}
        return;
      }
    }
    container.drawCircle(x, y, this.strokeRadius);

    if(this.lastPoint.x != null && this.lastPoint.y != null && distance > 1) {
      const line = new PIXI.Graphics();
      line.position.set(0, 0);
      line.lineStyle((this.strokeRadius * 2), color, 1);
      line.moveTo(this.lastPoint.x, this.lastPoint.y)
      line.lineTo(x, y);
      line.endFill();

      const rotation = Math.atan2(this.lastPoint.y - y, this.lastPoint.x - x) + Math.PI;
      const hitbox = new PIXI.Graphics();
      hitbox.beginFill(color, 0.0001);
      hitbox.drawRoundedRect(0,0-(this.strokeRadius * 2)/2+0.5, distance + this.strokeRadius, (this.strokeRadius * 2), this.strokeRadius);
      hitbox.pivot.set(0.5, 0.5)
      hitbox.rotation = rotation;
      hitbox.endFill();
      hitbox.position.set(this.lastPoint.x, this.lastPoint.y)
      hitbox.zIndex = -1;
      hitbox.interactive = true;

      container.addChild(line);
      container.addChild(hitbox);
    }
    if (this.strokeOpacity != 1){
      const colorMatrix = new PIXI.filters.AlphaFilter();
      colorMatrix.alpha = this.strokeOpacity;
      colorMatrix.resolution = 3;
      container.filters = [colorMatrix];
    }

    if(!isRestore) {
      this.drawData[this.drawData.length-1].points.push({
        x,
        y
      });
    }
    this.lastPoint = {x: x, y: y}
  }


  lines: PIXI.Graphics[] = [];
  drawData: IDrawing[] = [];
  addDrawListener() {
    const onClick = (e) => {
      if(this.isDrawMode) {
        const newLine = new PIXI.Graphics();
        newLine.beginFill(this.selectedColor);
        newLine.name = this.getName(ENamingTypes.CONTAINER);
        newLine.zIndex = this.getContainerZindex(7);
        this.addContainer(newLine, {points: [], zIndex: newLine.zIndex});
        newLine.interactive = true;
        newLine.cursor = 'none';
        this.lines.push(newLine);
        
        const mousePosition = e.data.getLocalPosition(this.stage);

        const drawData: IDrawing = {
          points: [],
          color: this.selectedColor,
          zIndex: newLine.zIndex,
          type: this.activeMode,
          lineIndex: this.drawData.length
        }
        this.drawData.push(drawData);

        this.addEraseListener(newLine, drawData.lineIndex);

        this.draw(mousePosition.x, mousePosition.y, newLine, this.selectedColor);

        window.addEventListener('pointerup', onDragEnd);
      }
    }

    const onDrag = (e) => {
      if(this.clicked && this.isDrawMode) {
        const mousePosition = e.data.getLocalPosition(this.stage);

        this.draw(mousePosition.x, mousePosition.y, this.lines[this.lines.length-1], this.selectedColor);
      }
    }

    const onDragEnd = () => {
      this.clicked = false;
      if(this.isDrawMode) {
        // this.lines[this.lines.length-1].endFill();
        // the stroke just drawn should not be interactive, otherwise the onclick event will not trigger 
        // when drawing new strokes if the starting point is on a drawn stroke.
        this.lines[this.lines.length-1].interactive = false;

        this.lastPoint = {x: null, y: null};

        this.updateState();

        window.removeEventListener('pointerup', onDragEnd);
      }
    }

    this.stage.on('pointerdown', onClick).on('pointermove', onDrag)
    // this.drawings.on('pointerdown', onClick).on('pointermove', onDrag)
    // this.backgroundSprite.on('pointerdown', onClick).on('pointermove', onDrag)
  }

  getPencilTools() {
    return this.pencilTools;
  }

  loadAssets = () => {
    return this.spriteLoader.loadSprites()
  }

  onPaletteColorChange(o: {val: boolean, color?: {color: number, string: string}; origin: 'BASE' | 'CHILD'}){
    if (o.color){
      this.selectedColor = o.color.color
      this.updateDrawCursor();
    }
  }

  changeDrawnGraphicColor(){}

  getUpdatedState(entry: Partial<IEntryStateVirtualTools>): Partial<IEntryStateVirtualTools> {
    entry.data[EPixiTools.PENCIL] = JSON.parse(JSON.stringify(this.drawData))
    console.log(entry.data[EPixiTools.PENCIL], 'pencil entry data');
    return entry;
  }
  handleNewState() {
    const data = this.getQuestionState(EPixiTools.PENCIL);
    if(!data || data.length == 0) {
      return; 
    }

    this.drawData = JSON.parse(JSON.stringify(data));
    this.drawData.forEach((line, i) => {
      line.lineIndex = i;
      const newLine = new PIXI.Graphics();
      const color = line.color ? line.color : this.selectedColor;
      newLine.beginFill(color);
      newLine.name = this.getName(ENamingTypes.CONTAINER);
      newLine.zIndex = line.zIndex;
      this.addContainer(newLine, {points: [...line.points], zIndex: newLine.zIndex});
      newLine.interactive = true;
      newLine.cursor = 'none';
      this.addEraseListener(newLine, i);
      this.lines.push(newLine);

      this.determinAndSetDrawMode(line.type);
      
      const temp = JSON.parse(JSON.stringify(line))
      temp.points.forEach((point) => {
        this.draw(point.x, point.y, newLine, line.color, true)
      });

      this.activeMode = undefined;
      this.lines[this.lines.length-1].interactive = false;

      this.lastPoint = {x: null, y: null};
    });

    this.render();
  }
  
}