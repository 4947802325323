import { Injectable } from '@angular/core';
import { IUserInfo, AuthService, getFrontendDomain } from '../api/auth.service';
import { BehaviorSubject } from 'rxjs';
import { RoutesService } from '../api/routes.service';
import { G9DemoDataService } from './g9-demo-data.service';
import {AccountType} from "../constants/account-types";
import { techReadiGoModePrimary, techReadiGoModeJunior, techReadiGoMode, techReadiGoModeOsslt, boardTechReadinessCheckPrimary, boardTechReadinessCheckJunior, boardTechReadinessCheck, boardTechReadinessCheckOsslt } from './sa-tech-readiness/data/checklist';
import { IFilterToggle } from '../ui-partial/filter-toggles/filter-toggles.component';
import { ASSESSMENT } from '../ui-teacher/data/types';
import { IContactIT, IIpData } from './sa-tech-readiness/data/types';
import { LangService } from '../core/lang.service';
import { WhitelabelService } from '../domain/whitelabel.service';
import { TwStatement } from './sa-tw-statement/model/tw-statement';

const SCHOOL_ADMIN_ROLE = 'schl_admin';

export type GroupRolesList = Array<{
  role_type:string,
  group_id:number,
}>;

export enum ClassFilterId { // todo:DB_DATA_MODEL
  Primary = 'Primary',
  Junior = 'Junior',
  G9 = 'G9',
  OSSLT = 'OSSLT',
  TCLE = 'NBED_TCLE',
  TCN = 'NBED_TCN',
  SCIENCES8 = 'NBED_SCIENCES8',
  MBED_SAMPLE = 'MBED_SAMPLE',
  SMCS_G7_EN = 'SMCS_G7_EN',
  ABED_SAMPLE = 'ABED_SAMPLE',
  ABED_GRADE_6 = 'ABED_GRADE_6',
  ABED_GRADE_9 = 'ABED_GRADE_9',
  ABED_GRADE_12 = 'ABED_GRADE_12',
  ABED_CAEC = 'ABED_CAEC',
}

export enum SchoolType {
  ELEMENTARY = 'ELEMENTARY',
  SECONDARY = 'SECONDARY',
  K12 = 'K12',
}

export enum TestWindowType {
  EQAO_G3P = 'EQAO_G3P',
  EQAO_G6J = 'EQAO_G6J',
  EQAO_G9M = 'EQAO_G9M',
  EQAO_G10L = 'EQAO_G10L',
}

@Injectable({
  providedIn: 'root'
})
export class MySchoolService {
  school: any;

  // services + init
  constructor(
    private auth: AuthService,
    private routes: RoutesService,
    private g9DemoData: G9DemoDataService, // temp
    private lang: LangService,
    private whiteLabelService: WhitelabelService
  ) { 
    this.auth.user().subscribe(v => {
      this._userInfo = v;
      if(v !== null) {
        // temp change to avoid API error
        if ([AccountType.BC_GRAD_MINISTRY_ADMIN, AccountType.BC_FSA_MINISTRY_ADMIN].includes(this._userInfo.accountType)) {
          console.log('return nothing');
          return;          
        }
        // this.loadMyRoles();
      }
    });
    this.fromBoardlead = false;
    this.newSchoolLoaded = false;
  }

  // vars (these should never be public)
  private _userInfo:IUserInfo;
  private _roles:GroupRolesList;
  private info: BehaviorSubject<GroupRolesList> = new BehaviorSubject(null);
  fail: BehaviorSubject<boolean> = new BehaviorSubject(null);

  private currentSchoolGroupId:number;

  private classFilterToggles:IFilterToggle[] //  = [];
  private school_extra_course_type = [];
  private privateSchoolClassFilterToggles:IFilterToggle[];
  
  // private classFilterToggles:IFilterToggle[] = [
  //   {
  //     id: ClassFilterId.Primary,
  //     caption: 'lbl_primary',
  //     value: false,
  //   },
  //   {
  //     id: ClassFilterId.Junior,
  //     caption: 'lbl_junior',
  //     value: false,
  //   },
  //   {
  //     id: ClassFilterId.G9,
  //     caption: 'txt_g9_math',
  //     value: false,
  //   },
  //   {
  //     id: ClassFilterId.OSSLT,
  //     caption: 'lbl_osslt',
  //     value: false,
  //   },
  // ]

  private currentTestWindow
  private fromBoardlead
  private newSchoolLoaded;

  // public methods
  public sub() {
    return this.info;
  }

  private hasSchoolAdminRole(roles: GroupRolesList) {
    return roles.find(role => ['schl_admin', 'bc_fsa_admin'].includes(role.role_type))
  }
  public patchSchoolTestAttempts(){
    return this.auth.apiPatch(this.routes.SCHOOL_ADMIN_SCHOOL, 1, {});
  }
  
  private loadMyRoles(isFromDashboard?: boolean){
    return this.auth
      .apiFind(this.routes.SCHOOL_ADMIN_ROLES)
      .then((roles:GroupRolesList) => {
        this._roles = roles;
        if (roles.length > 0 && this.hasSchoolAdminRole(roles)){
          console.log('sa role confirmed')
          if (this.currentSchoolGroupId){
            this.currentSchoolGroupId = this.currentSchoolGroupId;
          }
          else {
            const activeRole = roles[0]; // arbitrary... assumption is that there is not more than one school per school admin right now
            this.currentSchoolGroupId = activeRole.group_id;
          }
          return this.auth
              .apiFind(this.routes.SCHOOL_ADMIN_SCHOOL, {
                query: {
                  group_id: this.currentSchoolGroupId,
                  isReadReplica: isFromDashboard,
                }
              })
        }
        else {
          console.log('sa role failed')
          this.fail.next(true);
        }
      })
      .then(async schoolsData => {
        if (schoolsData) {
          await this.g9DemoData.init(schoolsData[0]); // always just one school from this request
          this.school = schoolsData[0].school;
          const school_info = [{ id: this.school[0].id, type_slug: this.school[0].type_slug }]
          this.auth.apiFind(this.routes.SCHOOL_ADMIN_SCHOOL_PROFILE, {
            query: {
              school_info: school_info,
              schl_group_id: this.school[0].group_id,
            }
          })
          .then((data) => {
            if (data) {
              this.school[0].school_profile = data[0];
              this.initClassFilterToggles();
            }
          });
          this.school_extra_course_type = schoolsData[0].school_extra_course_type
          this.newSchoolLoaded = true;
          // console.log(schoolsData, this.school[0].id)
          this.info.next(this._roles);
        }
      })
  }

  stuAsmtSignoffs:Map<string, boolean> = new Map();
  stuAsmtRevokedSignoffs:Map<string, any[]> = new Map();
  convertClassFilterToTwTypeSlug(classfilterId:string){
    switch (classfilterId){
      // #TODO: Update TestWindowType Enum
      case ClassFilterId.TCLE: return 'NBED_TCLE';
      case ClassFilterId.TCN: return 'NBED_TCN';
      case ClassFilterId.SCIENCES8: return 'NBED_SCIENCES8';
      case ClassFilterId.Primary: return TestWindowType.EQAO_G3P;
      case ClassFilterId.Junior: return TestWindowType.EQAO_G6J;
      case ClassFilterId.G9: return TestWindowType.EQAO_G9M;
      case ClassFilterId.OSSLT:
      default: return TestWindowType.EQAO_G10L;
    }
  }

  async saveSchlTwSignoff(twStatement:TwStatement){
    twStatement.isSaving = true;
    await this.auth
      .apiPatch(
        'public/school-admin/tw-statement', 
        twStatement.test_window_id,
        {
          schl_group_id: twStatement.schl_group_id,
          schl_tw_sign_off: twStatement.schl_tw_sign_off
        },
        this.configureQueryParams({})
      ) 
    twStatement.isSaving = false
  }
  async initTwStatement(test_window_id:number){
    const {asmtComponents, students, schl_tw_sign_off} = await this.auth
      .apiGet(
        'public/school-admin/tw-statement', 
        test_window_id,
        this.configureQueryParams({})
      ) 
    return new TwStatement(this.currentSchoolGroupId, test_window_id, asmtComponents, students, schl_tw_sign_off)
  }
  async initStuAsmtSignoff(){
    if(!this.classFilterToggles){
      this.initClassFilterToggles();
    }
    return this.classFilterToggles.map(classFilter => {
      const tw_type_slug = this.convertClassFilterToTwTypeSlug(classFilter.id);
      const testWindows = this.g9DemoData.testWindows.filter(tw => tw.type_slug === tw_type_slug)
      testWindows.forEach( testWindow => {
        this.auth
          .apiFind(
            this.routes.SCHOOL_ADMIN_STUDENT_ASMT_INFO_SIGNOFF, 
            this.configureQueryParams({tw_type_slug, test_window_id:testWindow.id})
          ) 
        .then(records => {
          let isAnyNonRevoked = false;
          const revokedRecords = [];
          records.data.forEach(record => {
            if (record.is_revoked === 0){
              isAnyNonRevoked = true;
            }
            else{
              revokedRecords.push(record)
            }
          });
          this.stuAsmtSignoffs.set(classFilter.id+testWindow.id, isAnyNonRevoked)
          this.stuAsmtRevokedSignoffs.set(classFilter.id+testWindow.id, revokedRecords)
        })
      })
    })
  }

  isSigningOff:boolean;
  async signoffStuAsmt(classfilterId:ClassFilterId){
    if (this.isSigningOff){ return }
    this.isSigningOff = true;
    const tw_type_slug = this.convertClassFilterToTwTypeSlug(classfilterId);
    await this.auth
      .apiCreate(
        this.routes.SCHOOL_ADMIN_STUDENT_ASMT_INFO_SIGNOFF, {tw_type_slug}, this.configureQueryParams({tw_type_slug, test_window_id:this.currentTestWindow.id})
      ) 
    this.stuAsmtSignoffs.set(classfilterId+this.currentTestWindow.id, true);
    this.isSigningOff = false
  }
  checkStuAsmtSignoff(classfilterId:ClassFilterId){
    let test_window_id;
    if(this.currentTestWindow){
      test_window_id = this.currentTestWindow.id
    }
    return this.stuAsmtSignoffs.get(classfilterId + test_window_id)
  }

  checkStuAsmtSignoffReport(classfilterId:ClassFilterId, testWindowId){
    return this.stuAsmtSignoffs.get(classfilterId + testWindowId)
  }

  getStuAsmtSignoffHistory(classfilterId:ClassFilterId){
    let test_window_id;
    if(this.currentTestWindow){
      test_window_id = this.currentTestWindow.id
    }
    return this.stuAsmtRevokedSignoffs.get(classfilterId + test_window_id)
  }

  getCurrentSchoolId(){
    const school = this.school[0];
    if (school){
      return school.id
    }
  }

  isSASNLogin(){
    const school = this.school[0];
    if(school){
      return (school.is_sasn_login == 1);
    }
  }

  loadSchoolReports(singleStudentUid?:number, bypassAdminSignOff = false){
    const queryparams = {
      schl_group_id: this.currentSchoolGroupId,
      schoolsId:this.school[0].id, 
      assessment_slug:ASSESSMENT.OSSLT_OPERATIONAL,
      singleStudentUid,
      clientDomain:getFrontendDomain(),
      bypassAdminSignOff,
      tw_id: -1 // was hard-coded to 7, which is not better. This value has been disabled on the server side for the time being.
    }
    if (this._roles.length > 0 && this.hasSchoolAdminRole(this._roles)){
      return this.auth
          .apiFind(this.routes.SCHOOL_ADMIN_REPORTS, {
            query: queryparams
          }).then(res =>{
            return res;
          })
    }
    else {
      this.fail.next(true);
    }
  }

  loadSchoolPJReports(assessment, singleStudentUid?:number, bypassAdminSignOff = false, getData = true, tw_id?:any){
    const assessment_slug = assessment == ClassFilterId.Primary?ASSESSMENT.PRIMARY_OPERATIONAL:ASSESSMENT.JUNIOR_OPERATIONAL
    let queryparams = {
      schl_group_id: this.currentSchoolGroupId,
      schoolsId:this.school[0].id, 
      assessment_slug,
      singleStudentUid,
      clientDomain:getFrontendDomain(),
      bypassAdminSignOff,
      studentNum: 50,
      tw_id: -1 // was hard-coded to 7, which is not better. This value has been disabled on the server side for the time being.
    }
    if (this._roles.length > 0 && this.hasSchoolAdminRole(this._roles)){
      if(getData){
        return this.auth
        .apiFind(this.routes.SCHOOL_ADMIN_PJ_REPORTS, {
          query: queryparams
        }).then(res =>{
          return res;
        })
      } else {
        if(!tw_id){
          return
        }
        queryparams.tw_id =tw_id
        return this.auth
        .apiGet(this.routes.SCHOOL_ADMIN_PJ_REPORTS, this.school[0].id, {
          query: queryparams
        }).then(res =>{
          return res;
        })
      }
    }
    else {
      this.fail.next(true);
    }
  }

  generateSchoolPJReports(assessment, tw_id:any, singleStudentUid?:number, bypassAdminSignOff = false){
    const assessment_slug = assessment == ClassFilterId.Primary?ASSESSMENT.PRIMARY_OPERATIONAL:ASSESSMENT.JUNIOR_OPERATIONAL
    const queryparams = {
      schl_group_id: this.currentSchoolGroupId,
      schoolsId:this.school[0].id, 
      assessment_slug,
      singleStudentUid,
      clientDomain:getFrontendDomain(),
      bypassAdminSignOff,
      tw_id,
    }
    if (this._roles.length > 0 && this.hasSchoolAdminRole(this._roles)){
      return this.auth
        .apiCreate(this.routes.SCHOOL_ADMIN_PJ_REPORTS, {},{
          query: queryparams
        }).then(res =>{
          return res;
        })
    }
    else {
      this.fail.next(true);
    }
  }   
  
  loadSchoolG9ClassesWithReport(){
    const queryparams = {
      schl_group_id: this.currentSchoolGroupId,
    }
    if (this._roles.length > 0 && this.hasSchoolAdminRole(this._roles)){
      return this.auth
          .apiGet(this.routes.SCHOOL_ADMIN_CLASSES, -1, {
            query: queryparams
          }).then(res =>{
            return res;
          })
    }
    else {
      this.fail.next(true);
    }
  }

  getCurrentSchoolGroupId() {
    return this.currentSchoolGroupId;
  }

  fetchSecureLink(route:string){
    return this.auth.apiGet(route, this.currentSchoolGroupId, this.configureQueryParams());
  }

  configureQueryParams(query?:any, currentLang?:any, method?:any){
    const schoolData: any = this.g9DemoData.schoolData
    if (schoolData){
      if (method == 'create') {
        return {
          //schl_group_id: schoolData.group_id,
          schl_group_id: this.currentSchoolGroupId,
          is_private: schoolData.is_private,
          currentLang: this.lang.c(),
          ... query
        }  
      }
      return {
        query: {
          //schl_group_id: schoolData.group_id,
          schl_group_id: this.currentSchoolGroupId,
          is_private: schoolData.is_private,
          currentLang: this.lang.c(),
          ... query
        }
      }
    }
    return null;
  }

  configureIPQueryParams(is_verified:number){
    const schoolData: any = this.g9DemoData.schoolData
    if (schoolData){
      return {
        query: {
          schl_group_id: schoolData.group_id,
          is_private: schoolData.is_private,
          is_verified
        }
      }
    }
    return null;
  }
  loadChecklistStates(isFromDashboard?: boolean){
    let checklistPromise;
    if (this.currentSchoolGroupId){
      //return Promise.resolve({});
      return this.auth
      .apiGet(
        this.routes.SCHOOL_ADMIN_CHECKLIST,
        this.currentSchoolGroupId,
        this.configureQueryParams()
      )
      .then( (checklistStates: any) => {
        console.log(checklistStates)
        const state = {};
        checklistStates.checklistItems.forEach(checkItem => {
          state[checkItem.slug] = checkItem.value;
        })
        state[boardTechReadinessCheckPrimary] = checklistStates.boardPrimayTechReadiCompleted;
        state[boardTechReadinessCheckJunior] = checklistStates.boardJuniorTechReadiCompleted;
        state[boardTechReadinessCheck] = checklistStates.boardTechReadiCompleted;
        state[boardTechReadinessCheckOsslt] = checklistStates.boardOssltTechReadiCompleted;
        state[techReadiGoModePrimary] = checklistStates.warnPrimayTechReadiCompleted;
        state[techReadiGoModeJunior] = checklistStates.warnJuniorTechReadiCompleted;
        state[techReadiGoMode] = checklistStates.warnTechReadiCompleted
        state[techReadiGoModeOsslt] = checklistStates.warnOssltTechReadiCompleted;
        // console.log('checklist state raw', checklistStates)
        // console.log('checklist state', JSON.parse(JSON.stringify(state)))
        return state;
      });
     
    }

   return this.loadMyRoles(isFromDashboard).then( roles => {
     //return Promise.resolve({});
     return  this.auth
     .apiGet(
       this.routes.SCHOOL_ADMIN_CHECKLIST,
       this.currentSchoolGroupId,
       this.configureQueryParams()
     )
     .then( (checklistStates: any) => {
       const state = {};
       checklistStates.forEach(checkItem => {
         state[checkItem.slug] = checkItem.value;
         state[boardTechReadinessCheck] = checkItem.boardTechReadiCompleted;
         state[techReadiGoMode] = checkItem.warnTechReadiCompleted;
       })
       return state;
     });
    })  
  }



  createIpAddress(payload:IIpData,is_verified:number){
    return this.auth
    .apiCreate(
      this.routes.SCHOOL_ADMIN_IP,
        payload,
      this.configureIPQueryParams(is_verified)
    )
  }
  getIpAddresses(){
    return this.auth
    .apiFind(
      this.routes.SCHOOL_ADMIN_IP,
      this.configureQueryParams()
    )
  }
  revokeIpAddress(id:number){
    return this.auth
    .apiRemove(
      this.routes.SCHOOL_ADMIN_IP,
      id,
      this.configureQueryParams()
    )
  }
  updateITContacts(payload:IContactIT){
   return this.auth
   .apiUpdate(
     this.routes.SCHOOL_ADMIN_CONTACT,
     this.currentSchoolGroupId,
    payload,
    this.configureQueryParams()
   )
  }

  getITContacts(){
    return this.auth
    .apiGet(
      this.routes.SCHOOL_ADMIN_CONTACT,
      this.currentSchoolGroupId,
      this.configureQueryParams()
    )
  }

  toggleTRChecklist(slug:string, value:boolean){
    return this.auth
      .apiUpdate(
        this.routes.SCHOOL_ADMIN_CHECKLIST,
        this.currentSchoolGroupId,
        {
          slug,
          value,
        },
        this.configureQueryParams()
      )
  }

  getClassFilterToggles(){
    if(!this.classFilterToggles){
      this.initClassFilterToggles();
    }
    return this.classFilterToggles;
  }

  getPrivateSchoolClassFilterToggles(){
    if(!this.privateSchoolClassFilterToggles){
      this.initPrivateSchoolClassFilterToggles()
    }
    return this.privateSchoolClassFilterToggles;
  }

  initClassFilterToggles(){
    this.classFilterToggles = [];
    if (this.school[0]?.school_profile) {
      let asmtToggles = this.school[0].school_profile;
      this.classFilterToggles = asmtToggles.map((asmt) => {
        // const caption_slug = asmt.assessment_slug.replace("NBED_", '').replace("EQAO_", '').toLowerCase(); // todo:DB_DATA_MODEL
        // const assessment_slug = asmt.assessment_slug.replace("EQAO_", ''); // todo:DB_DATA_MODEL
        return ({
          id: asmt.assessment_slug,
          caption: asmt.label, // this.lang.tra(caption_slug + "_class_filter")
          value: false,
          is_disabled: asmt.is_disabled == 1 ? true : false,
          assessmentDefId: asmt.assessment_def_id
        })
      })

      return;
    }
  }

  initPrivateSchoolClassFilterToggles(){
    this.privateSchoolClassFilterToggles = [];
    if (this.school[0]?.school_profile) {
      let asmtToggles = this.school[0].school_profile;
      if (this.whiteLabelService.isABED()) {
        asmtToggles = asmtToggles.filter(asmt => asmt.assessment_slug.includes('ABED_GRADE_'));
      }

      this.privateSchoolClassFilterToggles = asmtToggles.map((asmt) => {
        const caption_slug = asmt.assessment_slug.replace("NBED_", '').replace("EQAO_", '').replace("ABED_", '').toLowerCase();
        const assessment_slug = asmt.assessment_slug.replace("EQAO_", '');
        return ({
          id: assessment_slug,
          caption: this.lang.tra(caption_slug + "_class_filter"),
          value: false,
          assessmentDefId: asmt.assessment_def_id
        })
      })

      return;
    }
    this.loadExtraPrivateSchoolCourseType()
  }

  loadExtraPrivateSchoolCourseType(){
    if(this.school_extra_course_type && this.school_extra_course_type.length > 0){
      this.school_extra_course_type.forEach( sect =>{
        switch(sect.type_slug){
          case 'EQAO_G3P':
            if( this.privateSchoolClassFilterToggles.find(pscft => pscft.id === ClassFilterId.Primary) == undefined){
              this.privateSchoolClassFilterToggles.push(
                {
                  id: ClassFilterId.Primary,
                  caption: 'lbl_primary',
                  value: false,
                }
              )
            }
            break;
          case 'EQAO_G6J':
            if( this.privateSchoolClassFilterToggles.find(pscft => pscft.id === ClassFilterId.Junior) == undefined){
              this.privateSchoolClassFilterToggles.push(
                {
                  id: ClassFilterId.Junior,
                  caption: 'lbl_junior',
                  value: false,
                }
              )
            }
            break;
          case 'EQAO_G9M':
            if( this.privateSchoolClassFilterToggles.find(pscft => pscft.id === ClassFilterId.G9) == undefined){
              this.privateSchoolClassFilterToggles.push(
                {
                  id: ClassFilterId.G9,
                  caption: 'txt_g9_math',
                  value: false,
                }
              )
            }
            break;
          case 'EQAO_G10L':
            if( this.privateSchoolClassFilterToggles.find(pscft => pscft.id === ClassFilterId.OSSLT) == undefined){
              this.privateSchoolClassFilterToggles.push(
                {
                  id: ClassFilterId.OSSLT,
                  caption: 'lbl_osslt',
                  value: false,
                }
              )
            }
            break;    
          default:
            break;
        }
      })
    }
  }

  // to do
  constructPermissionsParams(query?:any){
    // if (!this._info){ console.warn('myInst constructPermissionsParams early exit'); return; }
    const instit_group_id = 0; // this._info.groupId;
    return {
      query:{ 
        ... query,
        instit_group_id, 
      }
    }
  }

  setCurrentTestWindow(tw){
    this.currentTestWindow = tw
  }

  getCurrentTestWindow(){
    return this.currentTestWindow;
  }

  getIsFromBoardLed(){
    return this.fromBoardlead;
  }

  getNewSchoolLoaded(){
    return this.newSchoolLoaded;
  }

  selectSchool(newSchlGrpID:number){
    if(newSchlGrpID){
      this.currentSchoolGroupId = newSchlGrpID
      this.classFilterToggles = undefined;
    }
    if((this.currentSchoolGroupId && newSchlGrpID== undefined)){
      return
    }
    this.loadMyRoles(true);
  }
  resetSchoolGroupIdAndRole(newSchlGrpID:number){
    this.currentSchoolGroupId = newSchlGrpID
    this.fromBoardlead = true;
    this.newSchoolLoaded = false;
    this.loadMyRoles(false);
  }

  getCurrentSchoolType(){
    return this.school[0].type_slug;
  }

  isMBED = () => this.whiteLabelService.getSiteFlag('IS_MBED')
}
