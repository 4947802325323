import { LangService } from "src/app/core/lang.service";
import { DICTIONARY_LANG, Dictionary } from "./dictionary";
import { RoutesService } from "src/app/api/routes.service";
import { AuthService } from "src/app/api/auth.service";
import { SelectedWord } from "../widget-dictionary.component";

const spaceAfterPrefix = '&nbsp;&nbsp;&nbsp;';
export class DictionaryEN extends Dictionary {

    selectedWord: SelectedWord;
    constructor(lang: LangService, route: RoutesService, auth: AuthService) {
        super(lang, route, auth);

        this.dictionaryLang = DICTIONARY_LANG.EN;
    }

    getSuggestions(userData: string) {
        return this.auth.apiFind(this.route.DICTIONARY_SUGGESTIONS, {
            query: {
                userData,
                lang: this.dictionaryLang
            }
        })
    }

    getWordData(word) {
        return this.auth.apiGet(this.route.DICTIONARY_SUGGESTIONS, word, {query: {lang: this.dictionaryLang}});
    }

    /**
     * Process the raw json of the word entry, replace & restore some special characters
     * @param raw_json 
     * @returns 
     */
    preprocessResponseRawJSON(raw_json: string) {
        let processedJSON = raw_json

        // Find all the double quotes within the values and add escape character to them
        const defRegEx = /(?:{"def": ")(.*?)(?:"})/g;

        let addEscapeChar = (match: string, defContent: string) => {
            const quoteChar = /"/g
            const updatedDefContent = defContent.replace(quoteChar, '\\"');
            return match.replace(defContent, updatedDefContent);
        }

        // Replace the u00a0 unicode char found in the string with a space
        const nbspRegEx = /u00a0/g;

        // Replace the u2013 dash char
        const enDashRegEx = /u2013/g

        processedJSON = processedJSON.replace(nbspRegEx, ' ');
        processedJSON = processedJSON.replace(enDashRegEx, '–');
        processedJSON = processedJSON.replace(defRegEx, addEscapeChar);
        return processedJSON;
    }

    /**
     * Get the full set of definitions of the current selected word.
     * @returns An array of definition groups based on the semantic
     */
    getCurrentWordDefinition() {
        this.selectedWordDefinitions = [];
        this.getEntryDefGroups(this.selectedWord.json.entry);
        const definitions = [];

        this.selectedWordDefinitions.forEach(def => {
            definitions.push(this.getEntryDefUnit(def));
        })

        return definitions;
    }

    selectedWordDefinitions = [];
    /**
     * Get the definition in groups based on the sematic category of the word.
     * @param jsonEntry The full JSON data of the selected word.
     * @returns null, the method updates this.selectedWordDefinitions 
     */
    getEntryDefGroups(jsonEntry) {
        if (Array.isArray(jsonEntry) || (typeof jsonEntry === "object")) {
            for (let key in jsonEntry) {
                if (Array.isArray(jsonEntry[key]) || (typeof jsonEntry[key] === "object")) {
                    if (key === "sensecat") {
                        this.selectedWordDefinitions.push(jsonEntry);
                        return
                    }
                    else { this.getEntryDefGroups(jsonEntry[key])};
                }
            }
        }
    }

    /**
     * Get the individual definitions within each def group
     * @param defGroupJSONEntry The JSON data of the def group
     * @returns An array of definitions of the word under the same semantic category
     */
    getEntryDefUnit(defGroupJSONEntry) {
        const defs = [];
        const prefix = this.getDefPrefix(defGroupJSONEntry);

        let processSensecatEntry = (entry) => {
            if (entry.defgrp?.defunit?.def) {
                const obj = {
                    definition: prefix + spaceAfterPrefix + entry.defgrp.defunit.def,
                    examples: []
                }

                if (entry.exmplgrp) {
                    if (Array.isArray(entry.exmplgrp.exmplunit)) {
                        entry.exmplgrp.exmplunit.forEach((example) => {
                            obj.examples.push(example.exmpl);
                        })
                    }
                    else {
                        obj.examples.push(entry.exmplgrp.exmplunit.exmpl);
                    }
                }
                defs.push(obj);
            };
        }

        let extracctDefFromSensecat = (sensecat) => {
            if (Array.isArray(sensecat)){
                sensecat.forEach(entry => {
                    processSensecatEntry(entry);
                })
            }
            else if (sensecat.sensecat){
                extracctDefFromSensecat(sensecat.sensecat)
            }
            else {
                processSensecatEntry(sensecat);
            }
        }

        if(defGroupJSONEntry.sensecat)
            extracctDefFromSensecat(defGroupJSONEntry.sensecat)
        else {
            defs.push(this.getDef(defGroupJSONEntry));
        }
        return defs;
    }

    /**
     * Get the definition content of a single definition entry
     * @param defGroupJSONEntry The JSON data of the def group
     * @returns The definition content to be displayed (in raw html)
     */
    getDef(defGroupJSONEntry) {
        let pospVal = ''
        let groupintro = ''
        let subjectFieldLabel = ''
        let hwtext = ''

        if (defGroupJSONEntry.pospgrp?.pospunit?.posp['@value']) {
            pospVal = this.getDefPrefix(defGroupJSONEntry);
            groupintro = defGroupJSONEntry.sensecat?.xrefgrp?.groupintro;
            subjectFieldLabel = this.getSubjectFiledLabel(defGroupJSONEntry)
            hwtext = defGroupJSONEntry.sensecat?.xrefgrp?.xrefunit?.xref.xrhw['#text'];

            return '<i>' + pospVal + '</i>' + spaceAfterPrefix + subjectFieldLabel + '&nbsp;' + groupintro + '&nbsp;' + '<b><u>' + hwtext + '</u></b>';
        }

    }


    /**
     * Convert the short-hand phases in the JSON data to the related text content
     * @param defGroupJSONEntry The "def group" prop of the word entry data
     * @returns The actual text content to be displayed
     */
    getDefPrefix(defGroupJSONEntry) {
        let pospVal = defGroupJSONEntry.pospgrp?.pospunit?.posp['@value'];
        if (!pospVal) return;

        switch (pospVal) {
            case 'symbol:chemical':
                return '<i>the chemical symbol for</i>'
            case 'abbreviation:Internet_domain_name':
                return '<i>the internet domain name for</i>'
            case 'abbreviation':
                return '<i>abbreviation for</i>'
            case 'symbol':
                return '<i>symbol for</i>'
            case 'noun':
                return '<i>n</i>'
            case 'conjunction':
                return '<i>conj</i>'
            case 'acronym:noun':
                return '<i>n acronym for </i>'
            case 'adjective':
                return '<i>adj</i>'
            case 'sentence_substitute':
                return '<i>sentence substitute<i>'
            default:
                return '<i>' + pospVal + '</i>'
        }
    }

    /**
     * Convert the short-hand word subject in the JSON data to the related full text content
     * @param defGroupJSONEntry The "def group" prop of the word entry data
     * @returns The actual text content to be displayed
     */
    getSubjectFiledLabel(defGroupJSONEntry) {
        if (defGroupJSONEntry.sensecat?.lbsubjfld) {
            const subjectFiledLabel = defGroupJSONEntry.sensecat.lbsubjfld['@value'];

            switch (subjectFiledLabel) {
                case 'Sci:Chemistry':
                    return '<i>chem</i>'
                default:
                    return ''
            }
        }
        return ''
    }

    getDefEntryDefData(defEntry) {
        if (defEntry && (typeof defEntry === "object")) {
            return defEntry.definition
        }
        return defEntry
    }

    getDefEntryExampleData(defEntry) {
        if (defEntry && (typeof defEntry === "object")) {
            return defEntry.examples;
        }
        return false;
    }

    highlightTargetWord(example: any) {
        let exampleContent = example
        if (typeof example === "object"){
            exampleContent = example['#text'];
        }
        const target = this.selectedWord.word;
        const re = new RegExp(target, "g");

        return exampleContent.replace(re, `<span class="dictionaryExampleTargetWord">${target}</span>`);
    }

    getSelectedWordDefinitionContent(selectedWordDefinitionEntry: any) {
        throw new Error("Method not implemented.");
    }

    getDefEntrySynonymOpposites(defEntry: any) {
        throw new Error("Method not implemented.");
        return [];
    }

    getDefEntrySynonymDisplay(jsonEntry){
        throw new Error("Method not implemented.");
        return []
    }

    getDefEntryOppositeDisplay(jsonEntry){
        throw new Error("Method not implemented.");
        return []
    }
}