import { getElementWeight, IEntryState, IEntryStateScored, QuestionState, ScoringTypes } from "../../models";
import { TextToSpeechService } from "../../text-to-speech.service";
import { IContentElementVirtualTools, IEntryStateVirtualTools } from "../model";
import * as PIXI from "pixi.js-legacy";
import { BehaviorSubject } from "rxjs";
import { IDrawnMeta, ENamingTypes, EPixiTools } from "../types/types";
import { arrObjectSort } from "../../../core/util/sort";
import { DUPLICATE_OBJECT_SPACE, LAYER_LEVEL } from "../types/constants";
import { IToolStateSub } from "../element-render-virtual-tools.component";

export interface IPoint {
  x: number,
  y: number,
  isRotationPoint?: boolean
  position?: string;
}

export enum EToggleMode {
  ON = 'ON',
  OFF = 'OFF',
  TOGGLE = 'TOGGLE'
}

export enum EToolType {
  BUTTON = 'button',
  TOGGLE = 'toggle'
}

export const OUTLINE_WIDTH = 0.5;
export abstract class VirtualTools {

    abstract element: IContentElementVirtualTools;
    render;
    addGraphic;
    isLocked: boolean;
    textToSpeech: TextToSpeechService;
    stage: PIXI.Container;
    isRulerRotateDragging: boolean;
    isProtRotateDragging: boolean;
    isGlobalRotating: boolean;
    isGlobalDragging: boolean;
    questionState: QuestionState;

    // tool config
    toolName: string
    toolType: EToolType
    toolLayerLevel: LAYER_LEVEL

    // objectContainer:  PIXI.Graphics[] = [];
    ObjectMetaPropsContainer: {[key:string] : IDrawnMeta} = {} // store graphics data
    private _containerId: number;
    abstract getUpdatedState(entry: Partial<IEntryStateVirtualTools>): Partial<IEntryStateVirtualTools>;
    abstract handleNewState(): void;
    getToolStateSub: () => IToolStateSub;

    constructor(questionState: QuestionState, addGraphic, render, getToolStateSub, stage, isLocked, textToSpeech: TextToSpeechService, isGlobalRotating: boolean, isGlobalDragging: boolean){
      this.questionState = questionState;
      this.isGlobalDragging = isGlobalDragging;
      this.isGlobalRotating = isGlobalRotating;
      this.addGraphic = addGraphic;
      this.render = render;
      this.textToSpeech = textToSpeech
      this.isLocked = isLocked
      this.stage = stage;
      this.getToolStateSub = getToolStateSub;
      this.selectedColor = this.getParsedColor('#333333');

      this._init();
    }

    abstract loadAssets(): Promise<PIXI.Loader>;


    protected initTool(config:{ name: EPixiTools, type: EToolType, layerLevel?: LAYER_LEVEL}){
      const { name, type, layerLevel} = config;
      this.toolName = name;
      this.toolType = type;
      this.toolLayerLevel = layerLevel;
    }

    private _init(){
      this.containerId = this.getNewContainerId(); // #TODO: this should be updated after state is restored
      this._initToolStateSub();
      this._onStagePointerDown();
    }

    updateState() : void {
      if (!this.questionState) return;
      const entryState: Partial<IEntryStateVirtualTools> = this.questionState[this.element.entryId];

      if (!entryState) return;
      if(!entryState?.isResponded) {
        entryState.isResponded = true;
      }
      if(!entryState?.isFilled) {
        entryState.isFilled = true;
      }
      this.getUpdatedState(this.questionState[this.element.entryId]);
    }


    // Naming containers

    get containerId(){
      return this._containerId;
    } 

    set containerId(val: number){
      this._containerId = val      
    }

    increaseContainerId = () => this.containerId = this.containerId + 1 ;

    getNewContainerId = () => {
      if(!this.getObjectContainer().length) return 0;
      const sortedChildren = <PIXI.Graphics[]>(arrObjectSort(this.getObjectContainer(), 'name'))
      const lastChildName = sortedChildren[sortedChildren.length - 1].name;      
      const lastContainerId = +(this.getContainerIdFromName(lastChildName))
      return lastContainerId + 1
    }

    getToolNameFromObject = (name: string) => {
      const strs = name.split('-')
      return strs[0]
    }

    getContainerIdFromName(name: string){
      const strs = name.split('-')
      return +(strs[strs.length - 1])
    }
      
    getName = (conatinerType: string, containerId?: number) => {
      if(containerId == null) containerId = this.containerId
      return `${this.toolName}-${conatinerType}-${containerId}`
    }
   
    // add containers to main container
    addContainer(o: PIXI.Graphics, meta: IDrawnMeta){
      this.addGraphic(o)
      this.ObjectMetaPropsContainer[o.name] = meta
      if(meta._isSelected){
        if(this.currentSelectedObject) this.toggleSelection(this.currentSelectedObject, EToggleMode.OFF)
        this.toggleSelection(o, EToggleMode.ON)        
      }

      // make interactive if selection tool is enabled
      if(this.selectionState) this.activateSelectionListner(o);
      
      // TODO; refactor
      // if(this.toolType === EToolType.BUTTON){
      //   if(!this.selectionState) {
      //     this.activateSelectionTool();
      //   } else {
      //     this.activateSelectionListner(o);
      //   }
      // }
      //increase container id for the next object
      this.increaseContainerId();
    }

    // destroy and remove containers from main container
    removeContainer(o: PIXI.Graphics){
      // const currentContainerIndex = this.objectContainer.indexOf(o)
      // if(currentContainerIndex !== -1) this.objectContainer.splice(currentContainerIndex, 1)
      // this.objectContainer.removeChild(o)
      if(this.ObjectMetaPropsContainer[o.name]) delete this.ObjectMetaPropsContainer[o.name]

      try {      
        o.children.forEach((child, i) => {
          o.getChildAt(i).removeAllListeners();
          o.getChildAt(i).destroy();
        })
        o.removeAllListeners();
        this.stage.removeChild(o)
      } catch (error) {
        console.log('failed to remove container', o.name)
      }
    }


    protected activateSelectionTool(){
      const toolStateSub = this.getToolStateSub();
      toolStateSub['selector'].next({val: true, origin: 'CHILD'});
    }

    private _initToolStateSub(){
      const toolStateSub = this.getToolStateSub();
      Object.keys(toolStateSub).forEach(key => {
        switch (key) {
          case 'selector':
            toolStateSub[key].subscribe(o => this._onSelectToolSub(o))   
            break;     
          case 'colorPicker':
            toolStateSub[key].subscribe(o => this.onPaletteColorChange(o))   
            break;     
          default:
            break;
        }
      })
    }
  
    private selectionState: boolean
    private _onSelectToolSub(o: {val: boolean, origin: 'BASE' | 'CHILD'}){
      this.selectionState = o.val
      if(!o.val) return this.deactivateSelectionListners();
      this.activateSelectionListners();
    }

    onPaletteColorChange(o: {val: boolean, color?: {color: number, string: string}; origin: 'BASE' | 'CHILD'}){
      if (o.color){
        this.selectedColor = o.color.color
        if (!this.currentSelectedObject || !this._isSelected(this.currentSelectedObject)) return;

        this.changeDrawnGraphicColor(this.currentSelectedObject);
      }
    }

    abstract changeDrawnGraphicColor(currentSelectedObject: PIXI.Graphics)

    // activate interactive listners
    protected activateSelectionListner(container: PIXI.Graphics){
      // Override in child class if needed
      container.interactive = true;
      container.children.forEach(child => {
        child.interactive = true
      })
    }

    protected activateSelectionListners(){
      this.getObjectContainer().forEach((container: PIXI.Graphics) => this.activateSelectionListner(container))
    }

    // deactivate interactive listners
    protected deactivateSelectionListners(){
      // Override in child class if needed
      // console.log('deactivating...', this.toolName)
      // if (this.toolName == "TEXTBOX"){
      //   console.log('here')
      // }

      const setDescendantsInteractive = (container: PIXI.Container) => {
        if (container.children && container.children.length > 0){
          container.children.forEach(child => {
            child.interactive = false;
            setDescendantsInteractive(<PIXI.Container>child);
          })
        }
        return;
      }

      this.getObjectContainer().forEach((container: PIXI.Graphics) => {
        if(this._isSelected(container)) this.toggleSelection(container, EToggleMode.OFF)
        container.interactive = false
        setDescendantsInteractive(container);
      })
    }

    // Drag Drop selection function

    private _currentSelectedObject: PIXI.Graphics | undefined
    private _previousSelectedObject: PIXI.Graphics | undefined
    private selectionObjectInitialPosition: PIXI.ObservablePoint 
    private pointerInitialPosition: PIXI.Point;
    private initialPointerDownDiff: {x: number, y: number}
    private _currentSelectedColor: number;
    
    private isSelectionDragging: boolean
    protected isSelectionPointerDown: boolean
    protected isResizingPointerDown: boolean
    protected isRotatingPointerDown: boolean

    set currentSelectedObject(obj: PIXI.Graphics | undefined){
      this._currentSelectedObject = obj;
    }

    get currentSelectedObject(){
      return this._currentSelectedObject;
    }

    set previousSelectedObject(obj: PIXI.Graphics | undefined){
      this._previousSelectedObject = obj;
    }

    get previousSelectedObject(){
      return this._previousSelectedObject;
    }

    get selectedColor(){
      return this._currentSelectedColor;
    }

    set selectedColor(color: number){
      this._currentSelectedColor = color;
    }

    onPointerDown(e: PIXI.InteractionEvent){

      const currentTarget = (e.currentTarget) as PIXI.Graphics
      const currentContainer = this.getContainerFromName(currentTarget.name);

      this.previousSelectedObject = this.currentSelectedObject
      this.currentSelectedObject = currentContainer
      
      this.selectionObjectInitialPosition = currentContainer.position  // e.currentTarget.position
      this.pointerInitialPosition = e.data.getLocalPosition(this.stage);
      this.initialPointerDownDiff = {
        x: this.pointerInitialPosition.x - this.selectionObjectInitialPosition.x ,
        y: this.pointerInitialPosition.y - this.selectionObjectInitialPosition.y ,
      }

      // cursor type
      this.currentSelectedObject.cursor = 'grabbing'
      this.isSelectionPointerDown = true;

      // assign events
      const _onPointerMove = (e: PIXI.InteractionEvent) => {
        if(this.currentSelectedObject && this.isSelectionPointerDown){
          if(!this.isSelectionDragging) this.isSelectionDragging = true
          const mousePosition = e.data.getLocalPosition(this.stage);
          const initialDiff = this.initialPointerDownDiff
          const newPosition = {x: mousePosition.x - initialDiff.x , y: mousePosition.y - initialDiff.y}
          this.currentSelectedObject.x = newPosition.x; // obj x = 3
          this.currentSelectedObject.y = newPosition.y;
  
          // hide the menu for current object
          // this.hideMenu()
  
          this.render()
        } else {
          this.isSelectionDragging = false
        }
      }
  
      const _onPointerUp = (e: PIXI.InteractionEvent) => {
        if(this.previousSelectedObject && this.previousSelectedObject === this.currentSelectedObject && !this.isSelectionDragging){
          // toggle off return
          this.toggleSelection(this.currentSelectedObject, EToggleMode.TOGGLE)          
        } else {
          // clean up for previous if any
          if(this.previousSelectedObject) this.toggleSelection(this.previousSelectedObject, EToggleMode.OFF)
          // toggle on 
          this.toggleSelection(this.currentSelectedObject, EToggleMode.ON)
        }
  
        // cleanup
        this.currentSelectedObject.cursor = 'grab'
        this.isSelectionDragging = false;
        this.isSelectionPointerDown = false;
  
        // remove listners from stage
        this.stage
        .removeListener('pointermove', _onPointerMove)
        .removeListener('pointerup', _onPointerUp)

        this.ObjectMetaPropsContainer[this.currentSelectedObject.name].x = this.currentSelectedObject.x
        this.ObjectMetaPropsContainer[this.currentSelectedObject.name].y = this.currentSelectedObject.y
        this.updateState();
        this.render()
      }

      this.stage
      .on('pointermove', _onPointerMove)
      .on('pointerup', _onPointerUp)

    }

    // clear selection
    protected _clearActiveSelection(obj: PIXI.Graphics){
      this.clearActiveSelection(obj);
      this.clearActiveSelectionCleanUp(obj);
      this.render();
    }

    clearActiveSelection = (obj: PIXI.Graphics) => {} // TODO: make abstract
    clearActiveSelectionCleanUp = (container: PIXI.Graphics) => {
      if(container){
        const objectMeta = this.ObjectMetaPropsContainer[container.name];
        if(objectMeta){ objectMeta._isSelected = false; }
      }
    }

    // toggle on new selection : displays menu for the object
    protected _addActiveSelection(obj: PIXI.Graphics){
      if(this.currentSelectedObject?.name !== obj.name) this.currentSelectedObject = obj
      this.addActiveSelection(obj);
      this.addActiveSelectionCleanUp(obj);
      this.render();
    }

    addActiveSelection = (obj: PIXI.Graphics) => {} // TODO: make abstract

    addActiveSelectionCleanUp = (container: PIXI.Graphics) => {
      if(container){
        const objectMeta = this.ObjectMetaPropsContainer[container.name];
        if(objectMeta){ objectMeta._isSelected = true; }
      }
    }

    toggleSelection = (container: PIXI.Graphics, toggleMode: EToggleMode) => {
      let mode: 'activate' | 'deactivate';
      switch (toggleMode) {
        case EToggleMode.TOGGLE:
          if(this._isSelected(container)){
            mode = "deactivate"
          } else {
            mode = "activate"
          }
          break;
        case EToggleMode.OFF:
          mode = "deactivate"
          break;
        case EToggleMode.ON:
          mode = "activate"
          break
        default: return;
      }

      if(mode === "activate") {
        this.updateContainerZindex(container)
        return this._addActiveSelection(container)
      }

      else {
        return this._clearActiveSelection(container)
      }

    }

    // check if current object is selected or not
    _isSelected(container: PIXI.Graphics){
      const containerMeta = this.ObjectMetaPropsContainer[container.name] 
      const isSelected = containerMeta?._isSelected
      return !!isSelected
    }

    // #TODO: extend this for drawing mode on
    _onStagePointerDown() {
      const deselectCurrent = (e: PIXI.InteractionEvent) => {
        if(this.currentSelectedObject && this._isSelected(this.currentSelectedObject)){
          this.toggleSelection(this.currentSelectedObject, EToggleMode.OFF)
          this.currentSelectedObject = undefined;
          console.log(this.toolName, this.isSelectionPointerDown)
        }
        this.updateState();
        this.render();
        this.stage.removeListener('pointerup', deselectCurrent);
      }
      this.stage.on('pointerdown', (e: PIXI.InteractionEvent) => {
        let extractedToolName;
        if(e?.target?.name) extractedToolName = this.getToolNameFromObject(e.target.name)
        
        const isFromSameTool = extractedToolName && this.toolName === extractedToolName;
        
        // e.target.name == null  - most likely user clicked on stage and not on any object
        // !this.isSelectionPointerDown -  not clicked on any objects for current tools
        // !isFromSameTool - event is bubbling and currently not in the same tool as where from it started - deselect selected container
        // this makes sure that only one object is selected at a time all others are deslected
        // (!this.isSelectionPointerDown && !e.target.name) || !isFromSameTool
        if(!this.isSelectionPointerDown && !this.isRotatingPointerDown && !this.isResizingPointerDown ){          
          this.stage.on('pointerup', deselectCurrent);
        }
      })
    }
    
    

    // menu common methods
    deleteContainerFromMenu(currentObject: PIXI.DisplayObject){
      const currentContainer = this.getContainerFromName(currentObject.name);
      this.removeContainer(currentContainer);
      this.render();
    }

    // get Methods

    /**
     * This stores all the conatiners for the tool controller
     * @returns: PIXI.Graphics[]
     */
    protected getObjectContainer(){
      // const map = this.stage.children.map(child => {return {name: child.name, zIndex: child.zIndex}})
      return this.stage.children.filter(children => children.name?.includes(this.toolName))
    }

    /**
     * @returns  ObjectMetaPropsContainer : {[key:string] : IDrawnMeta} 
     */
    protected getObjectMetaProps(){
      return this.ObjectMetaPropsContainer
    }

    /**
     * 
     * @param name string 
     * 
     * Get container object by passing container's or any of it's children name
     * @returns PIXI.Graphics <tool container>
     */
    getContainerFromName(name : string){
      return this._getContainerFromName(name);
    }

    private _getContainerFromName(name : string){
      return <PIXI.Graphics>this._getObjectfromName(name, ENamingTypes.CONTAINER)
    }

    /**
     * @returns : line, rectangle, circle object from the container
     */
    getToolObjectFromName(name : string){
      return <PIXI.Graphics>this._getObjectfromName(name, ENamingTypes.TOOL)
    }

    /**
     * 
     * @param name container or tool or anchor name  
     * @returns PIXI.Graphics
     */
    getRotationAnchorFromName(name: string){
      return <PIXI.Graphics>this._getObjectfromName(name, ENamingTypes.ROTATION_ANCHOR)
    }

    private _getObjectfromName(name: string, type:ENamingTypes){
      const containerId = this.getContainerIdFromName(name);
      const containerName = this.getName(type, containerId)
      return this.stage.getChildByName(containerName, true)
    }

    /**
     * 
     * @param currentObject PIXI.DisplayObject
     * 
     * @returns position: { x: number, y: number }
     */
    getDuplicateObjectPosition(currentObject: PIXI.DisplayObject){
      const currentContainer = this.getContainerFromName(currentObject.name);
      return { x: currentContainer.x + DUPLICATE_OBJECT_SPACE, y: currentContainer.y + DUPLICATE_OBJECT_SPACE}
    }


    getText(text:string, style, resolution:number, x, y) {
      const textObj = new PIXI.Text(text, style)
      if (resolution) {
        textObj.resolution = resolution
      }
      if (x) {
        textObj.x = x
      }
      if (y) {
        textObj.y = y
      }
      return textObj
    }

    isHCMode(){
      return this.textToSpeech.isHiContrast;
    }

    getColor() {
      return this.isHCMode() ? 0xffffff : 0x000000;
    }

    getParsedColor(hex: string) {
      return PIXI.utils.string2hex(hex);
    }

    getRadians(angle:number): number {
      return angle * (Math.PI/180);
    }

    getDegrees(angle:number): number {
      return angle * (180/Math.PI);
    }

    getLocalCoordinates(parent: PIXI.Graphics, child: PIXI.Graphics) {
      return {
        x: parent.x + child.x,
        y: parent.y + child.y
      }
    }

    addDragAndRotateListener(obj: PIXI.Graphics, isRotate?: boolean, rotatePoint?: PIXI.Graphics, addDragInteraction? : boolean) {
      let initialDiffX = 0;
      let initialDiffY = 0;
      let isDragging = false;
      this.isGlobalDragging = false;
      let isRotateDragging = false;
      let initialAngle = 0;
      obj.cursor = 'grab';
      // obj.interactive = false;
      if(isRotate) {
        rotatePoint.cursor = 'ew-resize'
        // rotatePoint.interactive = true;
      }
  
      if(addDragInteraction){
        // temp - should be using  this.onPointerDown()
        const onDragStart = (e) => {
            const mousePosition = e.data.getLocalPosition(this.stage);
            isDragging = true;
            initialDiffX = mousePosition.x - obj.x
            initialDiffY = mousePosition.y - obj.y
            obj.cursor = 'grabbing';
            console.log('drag start')
        }
        const onDragEnd = (e) => {
            isDragging = false;
            obj.cursor = 'grab';
        }
        const onDragMove = (e: PIXI.InteractionEvent) => {
            if(isDragging && !this.isGlobalRotating && !this.isProtRotateDragging && !this.isRulerRotateDragging) {
                this.isGlobalDragging = true;
                const mousePosition = e.data.getLocalPosition(this.stage);
                obj.x = mousePosition.x - initialDiffX; // obj x = 3
                obj.y = mousePosition.y - initialDiffY;
                this.render();
            } else if(!isDragging) {
              this.isGlobalDragging = false;
            }
        }

        obj.on('pointerdown', onDragStart)
        .on('pointerup', onDragEnd)
        .on('pointerupoutside', onDragEnd)
        .on('pointermove', onDragMove);
      }

      if(isRotate) {
        initialAngle = Math.atan2(obj.pivot.y - rotatePoint.y, obj.pivot.x - rotatePoint.x) + Math.PI;
      }
      const onRotateStart = (e) => {
        this.isRotatingPointerDown = true
        isRotateDragging = true;
        this.isGlobalRotating = true;
        console.log('rotate start')
      }
      const onRotateEnd = (e) => {
        isRotateDragging = false;
        this.isGlobalRotating = false;
        this.isRotatingPointerDown = false
      }
      const onRotate = (e: PIXI.InteractionEvent) => {
          if(isRotateDragging) {
            const mousePosition = e.data.getLocalPosition(this.stage);

            const mouseAngle = Math.atan2(obj.y - mousePosition.y, obj.x - mousePosition.x) + Math.PI;
            
            obj.rotation = mouseAngle - initialAngle;
  
            this.render();
          }
      }

      if(isRotate) {
        rotatePoint.on('pointerdown', onRotateStart)
        .on('pointerup', onRotateEnd)
        .on('pointerupoutside', onRotateEnd)
        .on('pointermove', onRotate);
      }
    }

    drawRotationPoint(point: {x: number, y: number}, parent: PIXI.Graphics) {
      const rotationPoint = new PIXI.Graphics();
      rotationPoint.beginFill(0x0000, 0.9);
      rotationPoint.drawCircle(point.x, point.y, 6);
      rotationPoint.endFill();
      rotationPoint.position.set(0 + point.x, 0 + point.y);
      rotationPoint.pivot.set(0 + point.x, 0 + point.y);
  
      parent.addChild(rotationPoint);
  
      this.addDragAndRotateListener(parent, true, rotationPoint);

      return rotationPoint;
    }

    drawPolygon(x: number, y: number, fill: {color: number, opacity: number}, points: IPoint[], isInteractive: boolean) {
      const polygon = new PIXI.Graphics;
      polygon.beginFill(fill.color, fill.opacity);
      const transformedPoints = []
      
      points.map(point => {
        transformedPoints.push({x: point.x + x, y: point.y + y});
      })
  
  
      polygon.drawPolygon(transformedPoints);
  
      polygon.endFill();
  
      polygon.x += x;
      polygon.y += y;
      polygon.pivot.set(x, y);
      polygon.zIndex = 4;
  
      let hasRotationPoint = false;
      points.map(point => {
        if(point.isRotationPoint && isInteractive) {
          hasRotationPoint = true;
          this.drawRotationPoint({x: point.x + x, y: point.y + y}, polygon);
        }
      })
  
      if(isInteractive && !hasRotationPoint) {
        this.addDragAndRotateListener(polygon);
      }

      return polygon;
    }

    drawLine(x: number, y: number, fill: {color: number, opacity: number}, points: IPoint[], isInteractive: boolean, isVerticesDrawn:boolean = true) {
      const transformedPoints = []
      points.map(point => {
        transformedPoints.push({x: point.x + x, y: point.y + y});
      })

      const pointA = transformedPoints[1];
      const pointB = transformedPoints[0];
      let rotation = Math.atan2(pointB.y - pointA.y, pointB.x - pointA.x) + Math.PI;
      rotation = this.snapRotation(rotation);
      const distanceX = Math.pow(pointB.x - pointA.x, 2);
      const distanceY = Math.pow(pointB.y - pointA.y, 2);
      const line = new PIXI.Graphics();
      const length = Math.sqrt(distanceX + distanceY);
      const height = 2;
      line.beginFill(fill.color, fill.opacity);
      line.drawRect(pointB.x, pointB.y - (height / 2), length, height);
      line.endFill();

      // Draw a thiker line behind the line and set it to invisible, so it's easier to click on the line.
      line.beginFill(0xfffff, 0);
      line.drawRect(pointB.x, pointB.y - (height / 2) - 5, length, 10)
      line.pivot.set(pointB.x, pointB.y);
      line.position.set(pointB.x, pointB.y);
      line.rotation = rotation;
    
      line.x += x;
      line.y += y;
      line.pivot.set(x, y);
      line.zIndex = 4;
      line.interactive = true;

      const vertices = new PIXI.Graphics();
      if (isVerticesDrawn){
        vertices.beginFill(fill.color);
        vertices.drawCircle(pointB.x, pointB.y, 4);
        vertices.drawCircle(length, 0, 4);
        vertices.endFill();
        vertices.interactive = true;
        vertices.zIndex = 6;
      }
      line.addChild(vertices);

      return line;
    }

    drawCircle(x: number, y: number, fill: {color: number, opacity: number}, points: IPoint[], isInteractive: boolean, circleRadius: number) {
      const transformedPoints = []
      points.map(point => {
        transformedPoints.push({x: point.x + x, y: point.y + y});
      })
      const point = transformedPoints[0];

      const circle = new PIXI.Graphics();
      circle.beginFill(fill.color, fill.opacity);
      circle.drawCircle(point.x, point.y, circleRadius);
      circle.endFill();
      circle.interactive = true;
      circle.zIndex = 6;
      circle.interactive = true;

      circle.lineStyle(OUTLINE_WIDTH, this.getParsedColor('#ffffff'), 1);
      circle.drawCircle(point.x, point.y, circleRadius);

      let hasRotationPoint = false;
      points.map(point => {
        if(point.isRotationPoint && isInteractive) {
          hasRotationPoint = true;
          this.drawRotationPoint({x: point.x + x, y: point.y + y}, circle);
        }
      })
  
      if(isInteractive && !hasRotationPoint) {
        this.addDragAndRotateListener(circle);
      }

      return circle;
    }

    // Some common method being used by the freehand polygon and line tools
    drawShapeVertices(vertices: PIXI.Graphics, lines: PIXI.Graphics, points: IPoint[], addMoveVertexListener: (vertice, i) => any, verticeSize?: number) {
      vertices.removeChildren();
      lines.removeChildren();
      for(let i=0; i<points.length; i++) {
          if(points[i-1]) {
              this.drawVerticeLine(points[i], points[i-1], lines);
          }
          const vertice = new PIXI.Graphics();
          const circleRadius = verticeSize? verticeSize : 7;
          vertice.beginFill(0x0000);
          vertice.drawCircle(points[i].x, points[i].y, circleRadius);
          vertice.endFill();
          vertice.interactive = true;
          vertice.zIndex = 6;
          addMoveVertexListener(vertice, i);
          vertices.addChild(vertice);
      }
      
      this.render();
    }

    // Draws the black dot and connect to the previous dot (if available)
    drawVerticeLine(pointA: IPoint, pointB: IPoint, lines: PIXI.Graphics) {
      const line = new PIXI.Graphics();
      line.position.set(0, 0);
      line.lineStyle(2, 0x0000, 1);
      line.moveTo(pointB.x, pointB.y)
      line.lineTo(pointA.x, pointA.y);
      lines.addChild(line);
    }

    getShapeCenter(points: IPoint[]) {
      let x = 0;
      let y = 0;
      let maxX = 0, minX = 0, maxY = 0, minY = 0;
      for(let i=0; i < points.length; i++) {
          x = points[i].x;
          y = points[i].y;
          if (i == 0) {
              maxX = x;
              minX = x;
              maxY = y;
              minY = y;
          } else {
              if (maxX < x)
                  maxX = x;
              if (minX > x)
                  minX = x;
              if (maxY < y)
                  maxY = y;
              if (minY > y)
                  minY = y;
          }
      }
      x = (maxX + minX) / 2;
      y = (maxY + minY) / 2;
      const height = maxY - minY;
      const width = maxX - minX;
  
      return {x, y, maxX, maxY, minX, minY, height, width};
    }

    drawSelectedBorder(container: PIXI.Graphics, points?: IPoint[]) {
      if(!points?.length) {
        points = this.getObjectMetaProps()[container.name].points
      }
      const center = this.getShapeCenter(points);
      const border = new PIXI.Graphics();
      border.lineStyle(2, 0x0000, 1);
      border.beginFill(0x0000, 0.1);
      border.drawRect(center.minX - 2, center.minY - 2, (center.maxX-center.minX) + 4, (center.maxY-center.minY) + 4);
      border.endFill();
      border.alpha = 0;
  
      container.addChild(border);
  
      return border;
    }

    removeAccessibilityKeyListeners() {}

    drawPoint(x, y, color?){
      const a = new PIXI.Graphics();
      const fillColor = color? color : 0x0000;
      a.beginFill(color, 0.9);
      a.drawCircle(x, y, 4);
      a.zIndex = 99;
      this.addGraphic(a)
    }

    snapRotation(rotation){
      const threshhold = 0.05;
      // 0
      if (Math.abs(rotation - 0) < threshhold){
        rotation = 0
      }
      // 45
      if (Math.abs(rotation - 0.785398) < threshhold){
        rotation = 0.785398
      }
      // 90
      if (Math.abs(rotation - 1.5708) < threshhold){
        rotation = 1.5708
      }
      // 135
      if (Math.abs(rotation - 2.35619) < threshhold){
        rotation = 2.35619
      }
      // 180
      if (Math.abs(rotation - 3.14159) < threshhold){
        rotation = 3.14159
      }
      // 225
      if (Math.abs(rotation - 3.92699) < threshhold){
        rotation = 3.92699
      }
      // 270
      if (Math.abs(rotation - 4.71239) < threshhold){
        rotation = 4.71239
      }
      // 315
      if (Math.abs(rotation - 5.49779) < threshhold){
        rotation = 5.49779
      }
      // 360
      if (Math.abs(rotation - 6.28319) < threshhold){
        rotation = 6.28319
      }
      return rotation;
    }

    get canvasSize(){ 
      return { height: this.element.canvasHeight ,  width: this.element.canvasWidth} 
    }

    getFillColor(container: PIXI.Graphics){
      if (this.ObjectMetaPropsContainer[container.name] && this.ObjectMetaPropsContainer[container.name].color) return this.ObjectMetaPropsContainer[container.name].color;
      return this.selectedColor;
    }

    getContainerZindex(defaultLevel: number){
      if (this.toolLayerLevel) return 10 ** (this.toolLayerLevel + 1);
      return defaultLevel
    }

    updateContainerZindex(container: PIXI.Graphics) {
      this.stage.sortChildren()
      const namezIndex = this.stage.children.filter(child => child.name && (child.zIndex === this.getContainerZindex(7)) || (child.zIndex / this.getContainerZindex(7) > 1 && child.zIndex / this.getContainerZindex(7) < 10)).map(child => {return {name: child.name, zIndex: child.zIndex}});
      const nameArray = Array.from(namezIndex.map(({name, zIndex}) => name));
      const zindexArray = Array.from(namezIndex.map(({name, zIndex}) => zIndex));
      const index = nameArray.indexOf(container.name)

      // if already the last, do nothing
      if (index === nameArray.length - 1) return;

      const maxIndex_z = Math.max(...zindexArray); 
      const minIndex_z = Math.min(...zindexArray); 

      container.zIndex = maxIndex_z + 1;
      
    }

    getQuestionState(tool: EPixiTools): any {
      const questionState = this.questionState[this.element?.entryId]
      if(questionState && questionState.data) {
        return <IDrawnMeta[]>this.questionState[this.element?.entryId]?.data[tool];
      } else {
        return [];
      }
    }

  }