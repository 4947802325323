import { Injectable } from '@angular/core';
import { AuthService } from '../api/auth.service';
import { DBT } from '../api/models';
import { RoutesService } from '../api/routes.service';
import { IQuestionConfig } from '../ui-testrunner/models';
import * as moment from 'moment-timezone';
import { ItemMakerService } from './item-maker.service';
import { BehaviorSubject } from 'rxjs';
import { ItemType } from './models';
import { EntryType, INotificationEntry, NotifStatus, NotifType, INotificationRes, ENoteItemType, INotificationEntryItem, INotificationEntryAsset, INotificationEntryAssetGroup, INotificationEntryAssessment, INotificationEntryAuthGroup} from './element-notes/types';

const personalTypes = [NotifType.ASSIGNED_COMMENT, NotifType.REPLIED_COMMENT, NotifType.REPLIED_ASSIGNED_COMMENT, NotifType.RESOLVED_COMMENT];

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private auth: AuthService, 
    private routes: RoutesService,
    private myItems: ItemMakerService
  ) { 
    // this.myItems.loadMyAuthoringGroups(false).then( () => {this.myItems.refreshGroupsAsSuper()})
  }

  data: INotificationEntry[]

  hasUnreadPersonal: boolean = false;
  numUnread: number = 0;
  dataLoaded = new BehaviorSubject<{data: INotificationEntry[], fetchType: NotifStatus}>(null);

  refreshData(config: {fetchRead: boolean, emitData: boolean} = {fetchRead: false, emitData: true}) {
    const query: any = {
      is_read: config.fetchRead ? 1 : 0
    };
    return null; // temporary disable until optimization is in place
    return this.auth.apiFind(this.routes.TEST_AUTH_NOTIFICATIONS, {query}).then( (res: INotificationRes[]) => {
      if(!config.fetchRead) {
        this.numUnread = 0; //Only reset the count if you're fetching the unread ones
      }
      this.data = <INotificationEntry[]>res.map( (r:INotificationRes) => { 
        const activateM = moment(r.activates_on);
        const renderName = (firstname?: string, lastname?: string) : string => {
          let name = "";
          if(!firstname) {
            return "";
          } else {
            name = firstname; 
            if(lastname) {
              name += " " + lastname;
            }
          }

          return name;
        }

        if(!r.is_read) {
          this.numUnread++;
        }

        const entry: INotificationEntry = {
          id: r.id,        
          entryType: r.entryType,
          date: activateM.format('MMMM D, YYYY'),
          time: activateM.format('h:mm a'),
          notification:  {
            notifType: r.notifType,
            user: renderName(r.user?.first_name, r.user?.last_name),
            email: r.email, //For sharing actions
            commentResolveType: r.commentResolveType
          },
          status: r.is_read ? NotifStatus.READ : NotifStatus.UNREAD
        }

        switch(entry.entryType) {
          case EntryType.ITEM :
            const itemEntry: INotificationEntryItem = {
              ...entry,
              assessment: r.asmt.name,
              itemSetId: r.asmt.id,
              itemLabel: r.item.label,
              itemIsArchived: r.item.isArchived,
              itemType: r.item.itemType,
              asmtIsArchived: r.asmt.isArchived,
              questionContent: !r.item.content ? undefined : JSON.parse(r.item.content),
              authGroup: r.authGroup.description,
              hasAsmtAccess: r.asmt.has_access
            }
            return itemEntry;

          case EntryType.ASSET :
            const assetEntry: INotificationEntryAsset = {
              ...entry,
              assetId: r.asset.id,
              assetName: r.asset.name,
              assetIsArchived: r.asset.is_archived,
              authGroup: r.authGroup ? r.authGroup.description : "",
              hasLibraryAccess: r.asset.has_library_access
            }
            return assetEntry;

          case EntryType.ASSET_GROUP :
            const assetGroupEntry: INotificationEntryAssetGroup = {
              ...entry,
              assetGroupId: r.assetGroup.id,
              assetGroupName: r.assetGroup.name,
              assetGroupIsDeleted: r.assetGroup.is_deleted,
              hasAssetGroupAccess: r.assetGroup.has_access
            }
            return assetGroupEntry;
          case EntryType.ASMT :
            const asmtEntry: INotificationEntryAssessment = {
              ...entry,
              assessment: r.asmt.name,
              asmtIsArchived: r.asmt.isArchived,
              itemSetId: r.asmt.id,
              authGroup: r.authGroup.description,
              linkToFramework: r.linkToFramework,
              hasAsmtAccess: r.asmt.has_access
            }
            return asmtEntry
          case EntryType.AUTH_GROUP :
            const authGroupEntry : INotificationEntryAuthGroup = {
              ...entry,
              authGroup: r.authGroup.description
            }
            return authGroupEntry;
        }
      });

      if(!config.fetchRead){
        this.updatePersonal();
      }
      if(config.emitData) {
        const fetchType = config.fetchRead ? NotifStatus.READ : NotifStatus.UNREAD;
        this.dataLoaded.next( {data: this.data, fetchType});
      }
      return this.data;
    })
  }

  updatePersonal(stopShort: boolean = false) {
    this.hasUnreadPersonal = false;
    for(const notif of this.data) {
      notif.isPersonal = personalTypes.includes(notif.notification.notifType);
      if(notif.isPersonal && notif.status === NotifStatus.UNREAD) {
        this.hasUnreadPersonal = true;
        if(stopShort) {
          return;
        }
      }
    }
  }

  getNumUnread() {
    return this.numUnread;
  }

  isSuper() {
    return this.myItems.hasGroupsAsSuper;
  }
  
  markRead(notifs: INotificationEntry[]) {
    return this.auth.apiPatch(this.routes.TEST_AUTH_NOTIFICATIONS, null, {notif_ids: notifs.map( n => n.id ), is_read: 1}).then(res => {
      for(const notif of notifs) {
        if(notif.status === NotifStatus.READ) {
          continue;
        }
        notif.status = NotifStatus.READ;
        this.numUnread--;
        this.updatePersonal(true);
      }
    })
  } 
}
