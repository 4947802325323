import { normalizeSync } from 'normalize-diacritics';

export const DROPDOWN_MONTHS = [
    '01', 
    '02',
    '03',
    '04',
    '05',
    '06', 
    '07',
    '08',
    '09',
    '10',
    '11',
    '12'
];

export const MONTH_LABELS = {
    'en': {
      '01': 'January',
      '02': 'February', 
      '03': 'March',
      '04': 'April',
      '05': 'May',
      '06': 'June',
      '07': 'July', 
      '08': 'August',
      '09': 'September',
      '10': 'October',
      '11': 'November',
      '12': 'December'
    },
    'fr': {
      '01': 'janvier',
      '02': 'février',
      '03': 'mars',
      '04': 'avril',
      '05': 'mai', 
      '06': 'juin',
      '07': 'juillet',
      '08': 'août',
      '09': 'septembre',
      '10': 'octobre',
      '11': 'novembre',
      '12': 'décembre'
    }
  }

export const RENDER_MONTH_LABEL_BY_CODE = (monthCode:string, lang:string) => {
  const monthMapLang = MONTH_LABELS[lang]
  return (monthMapLang[monthCode] || '')
}

export const numMatchFuzz = (userInput:string, availableOptions:string[]) => {
  const matchFound = ''
  const userInputSanitized = String(+((userInput || '').trim()))
  if (availableOptions){
    if (availableOptions.includes(userInputSanitized)){
      return userInputSanitized;
    }
  }
  return '';
}

export const MONTH_MATCH_FUZZ = (userInput:string, lang:string) => {
  const simplifyCaption = (str:string) => {
    str = (normalizeSync(str) || '')
      .toLowerCase()
      .replace(/[^a-zA-Z]/g, '')
    return str;
  }
  userInput = simplifyCaption(userInput || '')
  if (userInput){
    for (let monthCode of DROPDOWN_MONTHS){
      const caption = simplifyCaption(MONTH_LABELS[lang][monthCode] || '');
      if(caption.startsWith(userInput)) {
        return monthCode;
      }
    }
  }
  return '';
}