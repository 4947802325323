import { TOOLBAR_CATEGORY_ID } from "src/app/ui-testrunner/element-render-virtual-tools/types/constants";

export enum EToolbarPresets {
  ALL = 'ALL', 
  NO_DRAWINGS = 'NO DRAWINGS',
}

export const DEFAULT_TOOLBAR_ORDER_LIST = [TOOLBAR_CATEGORY_ID.SELECTION, TOOLBAR_CATEGORY_ID.PENCIL, TOOLBAR_CATEGORY_ID.TEXT, TOOLBAR_CATEGORY_ID.DATA, TOOLBAR_CATEGORY_ID.MEASUREMENTS, TOOLBAR_CATEGORY_ID.SHAPES_DRAW, TOOLBAR_CATEGORY_ID.SHAPES_PRESET, TOOLBAR_CATEGORY_ID.PALETTE];

export const TOOLBAR_PRESET_ALL = [
    {
      categoryName: TOOLBAR_CATEGORY_ID.SELECTION, 
      isCategoryDisabled: false,
      tools: [{toolName: 'selector', isDisabled: false}]
    },
    {
      categoryName: TOOLBAR_CATEGORY_ID.PENCIL, 
      isCategoryDisabled: false,
      tools: [
        {toolName: 'pencil', isDisabled: false},
        {toolName: 'marker', isDisabled: false},
        {toolName: 'highlighter', isDisabled: false},
        {toolName: 'eraser', isDisabled: false},
      ]
    },
    {
      categoryName: TOOLBAR_CATEGORY_ID.TEXT, 
      isCategoryDisabled: false,
      tools: [{toolName: 'text', isDisabled: false}]
    },
    {
      categoryName: TOOLBAR_CATEGORY_ID.DATA, 
      isCategoryDisabled: false,
      tools: [
        {toolName: 'table', isDisabled: false},
        {toolName: 'graph', isDisabled: false},
      ]
    },
    {
      categoryName: TOOLBAR_CATEGORY_ID.MEASUREMENTS, 
      isCategoryDisabled: false,
      tools: [
        {toolName: 'protractor', isDisabled: false},
        {toolName: 'ruler', isDisabled: false},
        {toolName: 'counter', isDisabled: false},
      ]
    },
    {
      categoryName: TOOLBAR_CATEGORY_ID.SHAPES_DRAW, 
      isCategoryDisabled: false,
      tools: [
        {toolName: 'line', isDisabled: false},
        {toolName: 'polygon', isDisabled: false},
        {toolName: 'circle_outline', isDisabled: false},
        {toolName: 'rectangle_outline', isDisabled: false},
      ]
    },
    {
      categoryName: TOOLBAR_CATEGORY_ID.SHAPES_PRESET, 
      isCategoryDisabled: false,
      tools: [
        {toolName: 'circle', isDisabled: false},
        {toolName: 'rectangle', isDisabled: false}
      ]
    },
    {
      categoryName: TOOLBAR_CATEGORY_ID.PALETTE, 
      isCategoryDisabled: false,
      tools: [{toolName: 'colorPicker', isDisabled: false}]
    },
]


export const TOOLBAR_PRESET_NO_DRAWINGS = [
  {
    categoryName: TOOLBAR_CATEGORY_ID.SELECTION, 
    isCategoryDisabled: false,
    tools: [{toolName: 'selector', isDisabled: false}]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.PENCIL, 
    isCategoryDisabled: false,
    tools: [
      {toolName: 'pencil', isDisabled: false},
      {toolName: 'marker', isDisabled: false},
      {toolName: 'highlighter', isDisabled: false},
      {toolName: 'eraser', isDisabled: false},
    ]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.TEXT, 
    isCategoryDisabled: false,
    tools: [{toolName: 'text', isDisabled: false}]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.DATA, 
    isCategoryDisabled: false,
    tools: [
      {toolName: 'table', isDisabled: false},
      {toolName: 'graph', isDisabled: false},
    ]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.MEASUREMENTS, 
    isCategoryDisabled: false,
    tools: [
      {toolName: 'protractor', isDisabled: false},
      {toolName: 'ruler', isDisabled: false},
      {toolName: 'counter', isDisabled: false},
    ]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.SHAPES_DRAW, 
    isCategoryDisabled: true,
    tools: [
      {toolName: 'line', isDisabled: false},
      {toolName: 'polygon', isDisabled: false},
      {toolName: 'circle_outline', isDisabled: false},
      {toolName: 'rectangle_outline', isDisabled: false},
    ]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.SHAPES_PRESET, 
    isCategoryDisabled: true,
    tools: [
      {toolName: 'circle', isDisabled: false},
      {toolName: 'rectangle', isDisabled: false}
    ]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.PALETTE, 
    isCategoryDisabled: false,
    tools: [{toolName: 'colorPicker', isDisabled: false}]
  },
]

export const TOOLBAR_PRESET_BASIC_DRAWINGS = [
  {
    categoryName: TOOLBAR_CATEGORY_ID.SELECTION, 
    isCategoryDisabled: false,
    tools: [{toolName: 'selector', isDisabled: false}]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.PALETTE, 
    isCategoryDisabled: false,
    tools: [{toolName: 'colorPicker', isDisabled: false}]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.PENCIL, 
    isCategoryDisabled: false,
    tools: [
      {toolName: 'pencil', isDisabled: false},
      {toolName: 'marker', isDisabled: false},
      {toolName: 'highlighter', isDisabled: false},
      {toolName: 'eraser', isDisabled: false},
    ]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.TEXT, 
    isCategoryDisabled: true,
    tools: [{toolName: 'text', isDisabled: false}]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.DATA, 
    isCategoryDisabled: true,
    tools: [
      {toolName: 'table', isDisabled: false},
      {toolName: 'graph', isDisabled: false},
    ]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.MEASUREMENTS, 
    isCategoryDisabled: true,
    tools: [
      {toolName: 'protractor', isDisabled: false},
      {toolName: 'ruler', isDisabled: false},
      {toolName: 'counter', isDisabled: false},
    ]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.SHAPES_DRAW, 
    isCategoryDisabled: false,
    tools: [
      {toolName: 'line', isDisabled: false},
      {toolName: 'polygon', isDisabled: false},
      {toolName: 'circle_outline', isDisabled: false},
      {toolName: 'rectangle_outline', isDisabled: false},
    ]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.SHAPES_PRESET, 
    isCategoryDisabled: false,
    tools: [
      {toolName: 'circle', isDisabled: false},
      {toolName: 'rectangle', isDisabled: false}
    ]
  },
]

export const TOOLBAR_PRESET_ABED_PILOT = [
  {
    categoryName: TOOLBAR_CATEGORY_ID.SELECTION, 
    isCategoryDisabled: false,
    tools: [{toolName: 'selector', isDisabled: false}]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.PALETTE, 
    isCategoryDisabled: false,
    tools: [{toolName: 'colorPicker', isDisabled: false}]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.MEASUREMENTS, 
    isCategoryDisabled: false,
    tools: [
      {toolName: 'protractor', isDisabled: false},
      {toolName: 'ruler', isDisabled: false},
      {toolName: 'counter', isDisabled: false},
    ]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.SHAPES_DRAW, 
    isCategoryDisabled: false,
    tools: [
      {toolName: 'line', isDisabled: true},
      {toolName: 'polygon', isDisabled: false},
      {toolName: 'circle_outline', isDisabled: true},
      {toolName: 'rectangle_outline', isDisabled: true},
    ]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.PENCIL, 
    isCategoryDisabled: true,
    tools: [
      {toolName: 'pencil', isDisabled: false},
      {toolName: 'marker', isDisabled: false},
      {toolName: 'highlighter', isDisabled: false},
      {toolName: 'eraser', isDisabled: false},
    ]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.TEXT, 
    isCategoryDisabled: true,
    tools: [{toolName: 'text', isDisabled: false}]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.DATA, 
    isCategoryDisabled: true,
    tools: [
      {toolName: 'table', isDisabled: false},
      {toolName: 'graph', isDisabled: false},
    ]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.SHAPES_PRESET, 
    isCategoryDisabled: true,
    tools: [
      {toolName: 'circle', isDisabled: false},
      {toolName: 'rectangle', isDisabled: false}
    ]
  },
]

export const TOOLBAR_PRESET_ABED_LITERACY = [
  {
    categoryName: TOOLBAR_CATEGORY_ID.SELECTION, 
    isCategoryDisabled: false,
    tools: [{toolName: 'selector', isDisabled: false}]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.PENCIL, 
    isCategoryDisabled: false,
    tools: [
      {toolName: 'pencil', isDisabled: false},
      {toolName: 'marker', isDisabled: false},
      {toolName: 'highlighter', isDisabled: false},
      {toolName: 'eraser', isDisabled: false},
    ]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.TEXT, 
    isCategoryDisabled: false,
    tools: [{toolName: 'text', isDisabled: false}]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.SHAPES_DRAW, 
    isCategoryDisabled: false,
    tools: [
      {toolName: 'line', isDisabled: false},
      {toolName: 'polygon', isDisabled: false},
      {toolName: 'circle_outline', isDisabled: false},
      {toolName: 'rectangle_outline', isDisabled: false},
    ]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.SHAPES_PRESET, 
    isCategoryDisabled: false,
    tools: [
      {toolName: 'circle', isDisabled: false},
      {toolName: 'rectangle', isDisabled: false}
    ]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.PALETTE, 
    isCategoryDisabled: false,
    tools: [{toolName: 'colorPicker', isDisabled: false}]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.MEASUREMENTS, 
    isCategoryDisabled: true,
    tools: [
      {toolName: 'protractor', isDisabled: false},
      {toolName: 'ruler', isDisabled: false},
      {toolName: 'counter', isDisabled: false},
    ]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.DATA, 
    isCategoryDisabled: true,
    tools: [
      {toolName: 'table', isDisabled: false},
      {toolName: 'graph', isDisabled: false},
    ]
  },
]

export const TOOLBAR_PRESET_ABED_PAT_9 = [
  {
    categoryName: TOOLBAR_CATEGORY_ID.SELECTION, 
    isCategoryDisabled: false,
    tools: [{toolName: 'selector', isDisabled: false}]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.PENCIL, 
    isCategoryDisabled: true,
    tools: [
      {toolName: 'pencil', isDisabled: false},
      {toolName: 'marker', isDisabled: false},
      {toolName: 'highlighter', isDisabled: false},
      {toolName: 'eraser', isDisabled: false},
    ]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.MEASUREMENTS, 
    isCategoryDisabled: false,
    tools: [
      {toolName: 'protractor', isDisabled: false},
      {toolName: 'ruler', isDisabled: false},
      {toolName: 'counter', isDisabled: false},
    ]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.TEXT, 
    isCategoryDisabled: true,
    tools: [{toolName: 'text', isDisabled: false}]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.SHAPES_DRAW, 
    isCategoryDisabled: false,
    tools: [
      {toolName: 'line', isDisabled: false},
      {toolName: 'polygon', isDisabled: false},
      {toolName: 'circle_outline', isDisabled: false},
      {toolName: 'rectangle_outline', isDisabled: false},
    ]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.SHAPES_PRESET, 
    isCategoryDisabled: true,
    tools: [
      {toolName: 'circle', isDisabled: false},
      {toolName: 'rectangle', isDisabled: false}
    ]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.PALETTE, 
    isCategoryDisabled: false,
    tools: [{toolName: 'colorPicker', isDisabled: false}]
  },
  {
    categoryName: TOOLBAR_CATEGORY_ID.DATA, 
    isCategoryDisabled: true,
    tools: [
      {toolName: 'table', isDisabled: false},
      {toolName: 'graph', isDisabled: false},
    ]
  },
]

export const TOOLBAR_CONFIG_PRESETS = {
    'ABED Pilot': TOOLBAR_PRESET_ABED_PILOT,
    'ABED Literacy': TOOLBAR_PRESET_ABED_LITERACY,
    'ALL': TOOLBAR_PRESET_ALL,
    'NO DRAWING': TOOLBAR_PRESET_NO_DRAWINGS,
    'BASIC DRAWINGS': TOOLBAR_PRESET_BASIC_DRAWINGS,
    'Custom': 'CUSTOM',
    'ABED_PAT9': TOOLBAR_PRESET_ABED_PAT_9,
}

export function applyToolbarPreset(presetData: {categoryName: string, isCategoryDisabled: boolean, tools: {toolName: string, isDisabled: boolean}[]}[]){
  // PENCIL
  const pencil = presetData.filter(category => category.categoryName ===  TOOLBAR_CATEGORY_ID.PENCIL)[0];
  this.element.pencilCategoryDisabled = pencil.isCategoryDisabled;
  this.element.fhPencilDisabled = pencil.tools.filter(tool => tool.toolName === 'pencil')[0].isDisabled;
  this.element.fhMarkerDisabled = pencil.tools.filter(tool => tool.toolName === 'marker')[0].isDisabled;
  this.element.fhHighlighterDisabled = pencil.tools.filter(tool => tool.toolName === 'highlighter')[0].isDisabled;

  // TEXT
  const text = presetData.filter(category => category.categoryName ===  TOOLBAR_CATEGORY_ID.TEXT)[0];
  this.element.textBoxCategoryDisabled = text.isCategoryDisabled;
  this.element.textBoxDisabled = text.tools.filter(tool => tool.toolName === 'text')[0].isDisabled;

  // DATA
  const data = presetData.filter(category => category.categoryName ===  TOOLBAR_CATEGORY_ID.DATA)[0];
  this.element.dataCategoryDisabled = data.isCategoryDisabled;
  this.element.tableDisabled = data.tools.filter(tool => tool.toolName === 'table')[0].isDisabled;
  this.element.graphDisabled = data.tools.filter(tool => tool.toolName === 'graph')[0].isDisabled;

  // MEASUREMENTS
  const measurements = presetData.filter(category => category.categoryName ===  TOOLBAR_CATEGORY_ID.MEASUREMENTS)[0];
  this.element.measurementCategoryDisabled = measurements.isCategoryDisabled;
  this.element.isProtractorDisabled = measurements.tools.filter(tool => tool.toolName === 'protractor')[0].isDisabled;
  this.element.isRulerDisabled = measurements.tools.filter(tool => tool.toolName === 'ruler')[0].isDisabled;
  this.element.isCounterDisabled = measurements.tools.filter(tool => tool.toolName === 'counter')[0].isDisabled;

  // SHAPES_DRAW
  const shapes_draw = presetData.filter(category => category.categoryName ===  TOOLBAR_CATEGORY_ID.SHAPES_DRAW)[0];
  this.element.shapesCategoryDisabled = shapes_draw.isCategoryDisabled;
  this.element.fhLineDisabled = shapes_draw.tools.filter(tool => tool.toolName === 'line')[0].isDisabled;
  this.element.fhPolygonDisabled = shapes_draw.tools.filter(tool => tool.toolName === 'polygon')[0].isDisabled;
  this.element.fhCircleOutlineDisabled = shapes_draw.tools.filter(tool => tool.toolName === 'circle_outline')[0].isDisabled;
  this.element.fhRectangleOutlineDisabled = shapes_draw.tools.filter(tool => tool.toolName === 'rectangle_outline')[0].isDisabled;

  // SHAPES_PRESET
  const shapes_preset = presetData.filter(category => category.categoryName ===  TOOLBAR_CATEGORY_ID.SHAPES_PRESET)[0];
  this.element.shapePrestesCategoryDisabled = shapes_preset.isCategoryDisabled;
  this.element.fhCircleShapeDisabled = shapes_preset.tools.filter(tool => tool.toolName === 'circle')[0].isDisabled;
  this.element.fhRectangleShapeDisabled = shapes_preset.tools.filter(tool => tool.toolName === 'rectangle')[0].isDisabled;

  this.element.toolbarCategoryOrderList = [];
  presetData.forEach(category => {
    this.element.toolbarCategoryOrderList.push(category.categoryName)
  })
}

