import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EditSelectionService {

  constructor() { }

  isSelectedEdit(element, questionPubSub){
    return questionPubSub?.selectedEntry?.id && questionPubSub?.selectedEntry?.id === element?.entryId;
  }

  getBorder(element, questionPubSub, defaultBorder = null) {
    return this.isSelectedEdit(element, questionPubSub) ? questionPubSub?.selectedEntry?.border : defaultBorder
  }
}
