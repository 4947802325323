import { Component, OnInit, Input } from '@angular/core';
import { LangService } from '../../core/lang.service';
import { LoginGuardService } from '../../api/login-guard.service';
import { WhitelabelService } from '../../domain/whitelabel.service';

@Component({
  selector: 'invig-intro-vid',
  templateUrl: './invig-intro-vid.component.html',
  styleUrls: ['./invig-intro-vid.component.scss']
})

export class InvigIntroVidComponent implements OnInit {


  constructor(
    public lang: LangService,
    private whitelabel: WhitelabelService,
    private loginGuard: LoginGuardService,
  ) { }

  ngOnInit() {
  }

}
