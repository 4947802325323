import * as PIXI from "pixi.js-legacy";
import { IContentElementVirtualTools, IEntryStateVirtualTools } from "../model";
import { VirtualTools, EToolType } from "./virtual-tools";
import * as _ from "lodash";
import { SpriteLoader } from "../../element-render-custom-interaction/controllers/sprite-loader";
import { DEFAULT_TICK_MEASURE, LAYER_LEVEL } from '../types/constants'
import { IPoint } from "./virtual-tools";
import { EPixiTools, ENamingTypes } from "../types/types";
import { MenuInputBox, ITextboxData } from "./menuInputBox";
import { QuestionState } from "../../models";

enum ACTIONS {
  DELETE = "DELETE",
  SIZE_UP_X = "SIZE_UP_X",
  SIZE_DOWN_X = "SIZE_DOWN_X",
  SIZE_UP_Y = "SIZE_UP_Y",
  SIZE_DOWN_Y = "SIZE_DOWN_Y",
}

enum DRAG_HANDLES {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT'
}

interface GraphInfo {
  x: number;
  y: number;
  head2Org_X: number;
  head2Org_Y: number;
  width: number;
  height: number;
  isGhost?: boolean;
  axisTickMultiplier_x?: number,
  axisTickMultiplier_y?: number,
  isRevoked?: boolean
}
export class Graph extends VirtualTools {
  element: IContentElementVirtualTools;
  spriteLoader: SpriteLoader
  backgroundSprite: PIXI.Sprite;
  staticBg: PIXI.Graphics;
  hcPrevState: boolean;
  graph: PIXI.Graphics;
  rulerAnchorA: PIXI.Graphics;
  rulerAnchorB: PIXI.Graphics;
  tick: PIXI.Graphics;
  axisLength_X = 300;
  axisLength_Y = 300;
  ghostGraph: PIXI.Graphics;
  dupeSprite: PIXI.Sprite;
  deleteSprite: PIXI.Sprite;
  gridGap: number;
  upSprite: PIXI.Sprite;
  downSprite: PIXI.Sprite;
  MenuInputBox_x: MenuInputBox
  MenuInputBox_y: MenuInputBox

  constructor(
    questionState: QuestionState,
    element: IContentElementVirtualTools,  
    addGraphic, 
    render,
    getToolStateSub,
    stage,
    isLocked, 
    textToSpeech,
    isGlobalRotating: boolean, 
    isGlobalDragging: boolean,
    backgroundSprite,
    layerLevel: LAYER_LEVEL,
    spriteLoader: SpriteLoader
  ) {
    super(questionState, addGraphic, render, getToolStateSub, stage, isLocked, textToSpeech, isGlobalRotating, isGlobalDragging);
    this.initTool({name: EPixiTools.GRAPH, type: EToolType.BUTTON, layerLevel: layerLevel});
    this.questionState = questionState;    this.initTool({name: EPixiTools.GRAPH, type: EToolType.BUTTON });
    this.element = element;
    this.spriteLoader = spriteLoader;
    this.isGlobalRotating = isGlobalRotating;
    this.isGlobalDragging = isGlobalDragging;
    this.deleteSprite = new PIXI.Sprite();
    this.upSprite = new PIXI.Sprite();
    this.downSprite = new PIXI.Sprite();
    this.gridGap = this.element.tickSpacing || DEFAULT_TICK_MEASURE;

    this.axisLength_X = this.gridGap * 16
    this.axisLength_Y = this.gridGap * 16
    
    //#stopping Ticker
    PIXI.Ticker.system.autoStart = false
    PIXI.Ticker.system.stop()

    this.hcPrevState = this.textToSpeech.isHiContrast;

    this.loadAssets().then(this.initSprites);
    this.ghostGraph = new PIXI.Graphics();
    this.addGraphic(this.ghostGraph);
    this.graphContainersDuringResize = new PIXI.Graphics();
    this.addGraphic(this.graphContainersDuringResize);

    this.MenuInputBox_x = new MenuInputBox(questionState, <IContentElementVirtualTools>this.element, addGraphic, render, getToolStateSub, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging, () => {});
    this.MenuInputBox_y = new MenuInputBox(questionState, <IContentElementVirtualTools>this.element, addGraphic, render, getToolStateSub, this.stage, this.isLocked, this.textToSpeech, this.isGlobalRotating, this.isGlobalDragging, () => {});
    // Render scene
    this.render();
  }

  initSprites = ({resources}) => {
    if (!resources || Object.keys(resources).length == 0) {
      this.deleteSprite.texture = PIXI.utils.TextureCache.delete;
      this.upSprite.texture = PIXI.utils.TextureCache.up;
      this.downSprite.texture = PIXI.utils.TextureCache.down;
    } else {
      this.deleteSprite.texture = resources.delete.texture;
      this.upSprite.texture = resources.up.texture;
      this.downSprite.texture = resources.down.texture;
    }
  }

  graphOriginOffset_X: number;
  graphOriginOffset_Y: number;
  setGraphOrigin(){
    this.getGridCenter()
    this.graphOriginOffset_X = this.a * this.gridGap;
    this.graphOriginOffset_Y = this.b * this.gridGap;
  }


  // Default graph origin is the center point of the background grid.
  getDefaultGraphOrigin() : IPoint{
    this.getGridCenter()
    const graphOriginOffset_X = this.a * this.gridGap;
    const graphOriginOffset_Y = this.b * this.gridGap;

    return {
      x: graphOriginOffset_X,
      y: graphOriginOffset_Y,
      isRotationPoint: false
    }
  }

  a;
  b;
  getGridCenter() {

    const gap = this.element.tickSpacing || DEFAULT_TICK_MEASURE;

    const centerIndex_X = Math.floor(this.element.canvasWidth / gap / 2)
    const centerIndex_Y = Math.floor(this.element.canvasHeight / gap / 2)

    let counter = 0;
    let closestX = 0;
    let distanceX = 999999;
    let closestY = 0;
    let distanceY = 999999;

    for(let i = gap; i<this.element.canvasWidth; i+=gap) {
      if (counter % 10 == 0 && Math.abs(centerIndex_X - counter) < distanceX){
        distanceX = Math.abs(centerIndex_X - counter);
        closestX = counter + 1;
      }
      counter++

    }
    counter = 0;
    for(let i = gap; i<this.element.canvasHeight; i+=gap) {
      if ((counter % 10 == 0) && (Math.abs(centerIndex_Y - counter) < distanceY)){
        distanceY = Math.abs(centerIndex_Y - counter);
        closestY = counter + 1;
      }
      counter++

    }
    this.a = closestX;
    this.b = closestY;
  }

  // X axis is drawn from left to right
  drawXAxis(graph: PIXI.Graphics, xAxisLength: number, yAxisLength: number, headToOriginDistance_X: number, tickMultiplier: number) {
    const xAxis = new PIXI.Graphics();
    xAxis.name = 'xAxis';

    const xOffest = headToOriginDistance_X < this.gridGap ? +xAxisLength/2 : - (headToOriginDistance_X - xAxisLength / 2);
    // if negative x length is less than the grid gap, remove negative x part
    if (headToOriginDistance_X < this.gridGap){
      xAxisLength -= headToOriginDistance_X;
      headToOriginDistance_X = 0;
    }
    // if positive x length is less than the grid gap, remove positive x part
    const lineWidth = (xAxisLength - headToOriginDistance_X) < this.gridGap? headToOriginDistance_X : xAxisLength;
    // console.log(xAxisLength, headToOriginDistance_X, lineWidth)
    const lineThickness = 2;
    const yOffset = yAxisLength/2 ;

    if (lineWidth != headToOriginDistance_X){
      // draw arrow
      const arrow = new PIXI.Graphics();
      arrow.x = lineWidth + lineThickness;
      arrow.y = lineThickness/2;
      let arrowWidth = 5, arrowHalfWidth = arrowWidth / 2, arrowHeight = arrowWidth;
      arrow.beginFill(0x0000, 1);
      arrow.lineStyle(0, 0x0000, 1);
      arrow.lineTo(-arrowWidth, arrowHalfWidth);
      arrow.lineTo(-arrowWidth, -arrowHalfWidth);
      arrow.lineTo(0, 0);
      arrow.endFill();
      xAxis.addChild(arrow);
    }

    xAxis.beginFill(0x0000);
    xAxis.drawRect(0,0, lineWidth, lineThickness);
    xAxis.x = xOffest;
    xAxis.y = yOffset;
    xAxis.endFill();

    this.drawScales(xAxis, xAxisLength, true, headToOriginDistance_X, tickMultiplier);

    xAxis.interactive = false
    xAxis.cursor = 'grab';


    graph.addChild(xAxis);
  }

  // Y axis is drawn from top to bottom
  drawYAxis(graph: PIXI.Graphics, xAxisLength: number, yAxisLength: number, headToOriginDistance_Y: number, tickMultiplier: number) {
    const yAxis = new PIXI.Graphics();
    yAxis.name = 'yAxis';
    const lineThickness = 2;

    const yOffest = headToOriginDistance_Y < this.gridGap ? +yAxisLength/2 : - (headToOriginDistance_Y - yAxisLength / 2);
    // if negative y length is less than the grid gap, remove negative y part
    if (headToOriginDistance_Y < this.gridGap) {
      yAxisLength -= headToOriginDistance_Y;
      headToOriginDistance_Y = 0;
    } 
    // if positive y length is less than the grid gap, remove positive y part
    const lineWidth = (yAxisLength - headToOriginDistance_Y) < this.gridGap ? headToOriginDistance_Y : yAxisLength;

    yAxis.beginFill(0x0000);
    yAxis.drawRect(0,0, lineThickness, lineWidth);
    yAxis.x = xAxisLength/2;
    yAxis.endFill();

    if (headToOriginDistance_Y != 0){
      // draw arrow
      const arrow = new PIXI.Graphics();
      arrow.x = lineThickness/2;
      arrow.y = -lineThickness;
      let arrowWidth = 5, arrowHalfWidth = arrowWidth / 2, arrowHeight = arrowWidth;
      arrow.beginFill(0x0000, 1);
      arrow.lineStyle(0, 0x0000, 1);
      arrow.lineTo(arrowHalfWidth, arrowHeight);
      arrow.lineTo(-arrowHalfWidth, arrowHeight);
      arrow.lineTo(0, 0);
      arrow.endFill();
      yAxis.addChild(arrow);
    }

    yAxis.beginFill(0x0000);
    yAxis.drawRect(0,0, 2, lineWidth);

    this.drawScales(yAxis, yAxisLength, false, headToOriginDistance_Y, tickMultiplier);

    yAxis.y = yOffest;

    graph.addChild(yAxis);
  }

  drawScales(line: PIXI.Graphics, axisLength: number, isXAxis: boolean, headToOriginDistance: number, tickMultiplier: number){
    const scale = new PIXI.Graphics();
    const gap = this.element.tickSpacing || DEFAULT_TICK_MEASURE;
    const namingType = isXAxis? ENamingTypes.TICK_X : ENamingTypes.TICK_Y;
    scale.name = this.getName(namingType);
    scale.beginFill(0x000);

    let hideNumberDisplayEvery = 1;
    if (gap < 20 && gap >= 10)  hideNumberDisplayEvery = 2;
    else if (gap < 10) hideNumberDisplayEvery = 5;
    
    let counter = 0
    if (isXAxis){
      // positive x
      for (let i = headToOriginDistance + gap; i < axisLength - gap / 2; i += gap) {
        counter++
        scale.drawRect(i - 0.75, 0, 1.5, gap/8 + 2);

        if ((counter % hideNumberDisplayEvery) === 0) 
        this.addNumberDisplay(i, 10, counter, line, true, tickMultiplier)
      }
      // negative x
      counter = 0
      for (let i = headToOriginDistance - gap; i > gap / 2; i-=gap) {
        counter--
        scale.drawRect(i - 0.75, 0, 1.5, gap/8 + 2);

        if ((counter % hideNumberDisplayEvery) === 0) 
        this.addNumberDisplay(i, 10, counter, line, true, tickMultiplier)
      }
    } else {
      // positive y
      for (let i = headToOriginDistance - gap; i > gap / 2; i-=gap) {
        counter++
        scale.drawRect(0, i,  -gap/8, 1.5);

        if ((counter % hideNumberDisplayEvery) === 0) 
        this.addNumberDisplay(-6, i, counter, line, false, tickMultiplier)
      }
      // negative y
      counter = 0
      for (let i = headToOriginDistance + gap; i < axisLength - gap / 2; i+=gap) {
        counter--
        scale.drawRect(0, i, -gap/8, 1.5);

        if ((counter % hideNumberDisplayEvery) === 0) 
        this.addNumberDisplay(-6, i, counter, line, false, tickMultiplier)
      }
    }
    scale.endFill();
    scale.zIndex = 20;

    line.addChild(scale);
  }

  addNumberDisplay(xOffset: number, yOffset: number, displayBaseNumber: number, line: PIXI.Graphics, isXAxis: boolean, tickMultiplier: any){
    const numberDisplay = new PIXI.Text(displayBaseNumber + '', {fontSize: 8});
    const namingType = isXAxis? ENamingTypes.TICK_NUMBER_X : ENamingTypes.TICK_NUMBER_Y;
    numberDisplay.name = this.getName(namingType) + "_" + displayBaseNumber;
    numberDisplay.style.fill = 0x0000;
    numberDisplay.anchor.set(0.5, 0.5);

    numberDisplay.x = isXAxis ? xOffset : xOffset - numberDisplay.width;
    numberDisplay.y = !isXAxis ? yOffset : yOffset;
    line.addChild(numberDisplay)

    const newNumber = displayBaseNumber * (tickMultiplier.toFixed(2)*100)/100;

    let displayNumber
    if (newNumber < 0) {
      displayNumber = '-' + this.formatNumberDisplay(-1 * newNumber);
    } else {
      displayNumber = this.formatNumberDisplay(newNumber);
    }
    numberDisplay.text = displayNumber;
  }

  isGraphMode = false;
  toggleGraph(mode?: boolean) {
    this.isGraphMode = mode == null ? !this.isGraphMode : mode;

    if(this.isGraphMode) {
      this.completeGraph(this.axisLength_X, this.axisLength_Y, undefined, undefined, true);
      this.render();
    } else{
      // this.graph.destroy();
      this.render();
    }

    this.isGraphMode = false;    
    this.activateSelectionTool()
    return this.isGraphMode;
  }

  graphContainerRotationPoints = new Map()
  drawGraphRotationPoint(cornerPoints, graphContainer: PIXI.Graphics, graph: PIXI.Graphics) {

    const center = this.getShapeCenter(cornerPoints);
    const rotationPoint = new PIXI.Graphics();
    const containerId = this.getContainerIdFromName(graphContainer.name);
    rotationPoint.name = this.getName(ENamingTypes.ROTATION_ANCHOR, containerId)
    const graphInfo = this.graphContainersInfo.get(graphContainer.name);

    rotationPoint.beginFill(0x0000, 0.9);
    // center.maxX - graphInfo.head2Org_X
    rotationPoint.drawRect(center.x, center.y - graphInfo.head2Org_Y, 2, -20)
    rotationPoint.drawCircle(center.x + 1, center.y - graphInfo.head2Org_Y - 20, 4);
    rotationPoint.endFill();
    rotationPoint.position.set(0 + center.x, 0 + center.y);
    rotationPoint.pivot.set(center.x, center.y);

    graphContainer.addChild(rotationPoint);
    this.graphContainerRotationPoints.set(graphContainer.name, rotationPoint);

    this.addGraphDragAndRotateListener(graphContainer, graph, true, rotationPoint);

    return rotationPoint;
  }

  getGraphCornerPoints(axisLength_X: number, axisLength_Y: number){
    const topLeft = {x: 0, y: 0}
    const topRight = {x: axisLength_X, y: 0}
    const bottomLeft = {x: 0, y: axisLength_Y}
    const bottomRight = {x: axisLength_X, y: axisLength_Y}
    return [topLeft, topRight, bottomLeft, bottomRight];
  }

  graphContainer;
  // containerID = 0;
  headToOriginDistance: {head2Org_X: number, head2Org_Y: number};
  completeGraph(xAxisLength: number, yAxisLength: number, graphOrigin?: IPoint, isGhost?: boolean, selectedByDefault?: boolean, headToOriginDistance? : {head2Org_X: number, head2Org_Y: number}, tickMultipliers? : {axisTickMultiplier_x: number, axisTickMultiplier_y: number}, rotation?) : PIXI.Graphics{

    const graph = new PIXI.Graphics();
    graph.name = this.getName(ENamingTypes.TOOL)
    const headToOriginDistance_X = headToOriginDistance? headToOriginDistance.head2Org_X : xAxisLength / 2;
    const headToOriginDistance_Y = headToOriginDistance? headToOriginDistance.head2Org_Y : yAxisLength / 2;
    const tickMultiplier_x = tickMultipliers? tickMultipliers.axisTickMultiplier_x : 1
    const tickMultiplier_y = tickMultipliers? tickMultipliers.axisTickMultiplier_y : 1

    this.drawXAxis(graph, xAxisLength, yAxisLength, headToOriginDistance_X, tickMultiplier_x);
    this.drawYAxis(graph, xAxisLength, yAxisLength, headToOriginDistance_Y, tickMultiplier_y);
    graph.interactive = false;
    graph.cursor = 'grab';
    graph.zIndex = 10;

    const graphContainer = new PIXI.Graphics();
    graphContainer.name = this.getName(ENamingTypes.CONTAINER)
    const graphContainerOrigin = graphOrigin? graphOrigin : this.getDefaultGraphOrigin();
    const graphCornorPoints = this.getGraphCornerPoints(xAxisLength, yAxisLength);
    const center = this.getShapeCenter(graphCornorPoints);
    graphContainer.addChild(graph)
    graphContainer.zIndex = this.getContainerZindex(7);

    graphContainer.pivot.set(center.x,center.y);
    graphContainer.position.set(graphContainerOrigin.x, graphContainerOrigin.y);
    if(rotation) graphContainer.rotation = rotation;

    let rotationPoint;

    if (!isGhost){
      // this.stage.addChild(graphContainer);
      // this.graphContainer = graphContainer;

      
      const graphInfo: GraphInfo = {
        x: graphContainer.x,
        y: graphContainer.y,
        head2Org_X: headToOriginDistance_X,
        head2Org_Y: headToOriginDistance_Y,
        width: xAxisLength,
        height: yAxisLength,
        isGhost: false,
        axisTickMultiplier_x: tickMultiplier_x,
        axisTickMultiplier_y: tickMultiplier_y
      }
      this.graphContainersInfo.set(graphContainer.name, graphInfo);

      rotationPoint = this.drawGraphRotationPoint(graphCornorPoints, graphContainer, graph);
      rotationPoint.alpha = 0;
      this.addGraphDragAndRotateListener(graphContainer, graph);
      this.addSelectGraphListener(graph, graphContainer, rotationPoint, graphCornorPoints, selectedByDefault);
      this.addContainer(graphContainer, {
        points: graphCornorPoints,
        _isSelected: selectedByDefault
      })
    }

    if (selectedByDefault && isGhost){
      this.drawGraphBorderOnResize(graphContainer, graph, headToOriginDistance_X, headToOriginDistance_Y, xAxisLength, yAxisLength);
      // if (isGhost){
      // } else {
      //   let outterBorder = this.drawSelectedGraphBorder(graphContainer, graphCornorPoints, isGhost);
      //   let dragPoints = this.drawDragPoints(graphContainer, graph, graphCornorPoints, isGhost, isGhost);
      //   let menu = this.drawMenu(graphContainer, graphCornorPoints, isGhost);
      //   outterBorder.alpha = 1;
      //   dragPoints.alpha = 1;
      //   menu.alpha = 1;
      //   rotationPoint.alpha = 1;
      //   this.graphAndSelectBorder.set(graphContainer.name, {graph: graph, border: outterBorder, menu: menu, dragHandle: dragPoints, rotationPoint: rotationPoint})
      // }
    }

    this.updateState();
    return graphContainer
  }

  initGhostGraphOnDragging(graphContainer:PIXI.Graphics, rotation?){
      const graphInfo = this.graphContainersInfo.get(graphContainer.name)
      const ghostGraph = this.completeGraph(graphInfo.width, graphInfo.height, undefined, true, false, {head2Org_X: graphInfo.head2Org_X, head2Org_Y: graphInfo.head2Org_Y});
      ghostGraph.alpha = 0.1;
      ghostGraph.x = graphContainer.x;
      ghostGraph.y = graphContainer.y
      if (rotation) ghostGraph.rotation = rotation
      this.ghostGraph.addChild(ghostGraph);

      return ghostGraph;
  }

  addGraphDragAndRotateListener(graphContainer: PIXI.Graphics, graph: PIXI.Graphics, isRotate?: boolean, rotatePoint?: PIXI.Graphics) {
    let initialDiffX = 0;
    let initialDiffY = 0;
    let isDragging = false;
    this.isGlobalDragging = false;
    let isRotateDragging = false;
    let initialAngle = 0;
    graphContainer.cursor = 'grab';
    graphContainer.interactive = false;
    if(isRotate) {
      rotatePoint.cursor = 'ew-resize'
      rotatePoint.interactive = false;
    }

    let ghostGraph: PIXI.Graphics;
    const onDragStart = (e) => {
      this.isSelectionPointerDown = true
      const mousePosition = e.data.getLocalPosition(this.stage);
      isDragging = true;
      initialDiffX = mousePosition.x - graphContainer.x
      initialDiffY = mousePosition.y - graphContainer.y
      graphContainer.cursor = 'grabbing';
      this.ghostGraph.removeChildren();
      ghostGraph = this.initGhostGraphOnDragging(graphContainer, graphContainer.rotation);

      this.stage.on('pointerup', onDragEnd)
      .on('pointermove', onDragMove);
    }
    const onDragEnd = (e) => {
      isDragging = false;
      graphContainer.cursor = 'grab';
      if (ghostGraph){
        graphContainer.x = ghostGraph.x;
        graphContainer.y = ghostGraph.y;
        ghostGraph.removeChildren()
        ghostGraph.clear()

        const graphInfo = this.graphContainersInfo.get(graphContainer.name);
        graphInfo.x = graphContainer.x;
        graphInfo.y = graphContainer.y;
      }
      this.ghostGraph.removeChildren();
      this.render();
      this.isSelectionPointerDown = false

      this.stage.removeListener('pointerup', onDragEnd);
      this.stage.removeListener('pointermove', onDragMove);
    }
    const onDragMove = (e: PIXI.InteractionEvent) => {
      if(!this.isDragExpanding && isDragging && !this.isGlobalRotating && !this.isProtRotateDragging && !this.isRulerRotateDragging) {
          this.isGlobalDragging = true;
          const mousePosition = e.data.getLocalPosition(this.stage);
          graphContainer.x = mousePosition.x - initialDiffX;
          graphContainer.y = mousePosition.y - initialDiffY;
          
          ghostGraph.x = mousePosition.x - initialDiffX;
          ghostGraph.y = mousePosition.y - initialDiffY;

          ghostGraph.x = this.snapToNearestGrid_x(graphContainer);
          ghostGraph.y = this.snapToNearestGrid_y(graphContainer);
          this.render();
      } else if(!isDragging) {
        this.isGlobalDragging = false;
      }
    }

    if(isRotate) {        
      initialAngle = Math.atan2(graphContainer.pivot.y - rotatePoint.y, graphContainer.pivot.x - rotatePoint.x) + Math.PI;
    }

    const onRotateStart = (e) => {
      this.isRotatingPointerDown = true
      isRotateDragging = true;
      this.isGlobalRotating = true;

      this.stage.on('pointerup', onRotateEnd)
      .on('pointermove', onRotate);
    }
    const onRotateEnd = (e) => {
      this.isRotatingPointerDown = false
      isRotateDragging = false;
      this.isGlobalRotating = false;

      this.stage.removeListener('pointerup', onRotateEnd);
      this.stage.removeListener('pointermove', onRotate);
    }
    const onRotate = (e: PIXI.InteractionEvent) => {
        if(isRotateDragging) {
          const mousePosition = e.data.getLocalPosition(this.stage);

          const mouseAngle = Math.atan2(graphContainer.y - mousePosition.y, graphContainer.x - mousePosition.x) + Math.PI / 2;
          
          graphContainer.rotation = mouseAngle - initialAngle;

          this.render();
        }
    }

    if (!isRotate) graphContainer.on('pointerdown', onDragStart);

    if (isRotate) rotatePoint.on('pointerdown', onRotateStart);
  }

  graphAndSelectBorder = new Map<string, {graph: PIXI.Graphics, border: PIXI.Graphics, menu: PIXI.Graphics, dragHandle: PIXI.Graphics, rotationPoint: PIXI.Graphics}>();
  addSelectGraphListener(graph: PIXI.Graphics, container: PIXI.Graphics, rotationPoint: PIXI.Graphics, points: IPoint[], selectedByDefault?: boolean) {
    // let selected = selectedByDefault;
    // let dragging = false;
    // let resizing = false;
    // let outline;
    // let outterBorder: PIXI.Graphics;
    // let menu;
    // let dragPoints;

    // const onClick = (e) => {
    //   dragging = false;
    //   resizing = false
    //   // this.stage.on('pointermove', checkDragging).on('pointerup', toggleMenu);
    // }
    // const checkDragging = (e) => {
    //   dragging = this.isGlobalDragging;
    //   resizing = this.isDragExpanding;
    // }
    // const toggleMenu = (e) => {
    //   if(!dragging && !resizing) {

    //     const containerBorderInfo = this.graphAndSelectBorder.get(container.name)

    //     if (containerBorderInfo && containerBorderInfo.border && containerBorderInfo.dragHandle && containerBorderInfo.menu && containerBorderInfo.rotationPoint){
    //       outterBorder = containerBorderInfo.border;
    //       dragPoints = containerBorderInfo.dragHandle;
    //       menu = containerBorderInfo.menu
    //     } else {
    //       outterBorder = this.drawSelectedGraphBorder(container, points, false);
    //       dragPoints = this.drawDragPoints(container, graph, points);
    //       menu = this.drawMenu(container, points);
    //     }

    //     selected = !selected;
    //     if(selected) {
    //       outterBorder.alpha = 1;
    //       dragPoints.alpha = 1
    //       menu.alpha = 1;
    //       rotationPoint.alpha = 1;

    //       this.graphAndSelectBorder.set(container.name, {graph: graph, border: outterBorder, menu: menu, dragHandle: dragPoints, rotationPoint: rotationPoint})
    //     } else {
    //       outterBorder.destroy();
    //       dragPoints.destroy();
    //       rotationPoint.alpha = 0;
    //       menu.destroy();
    //       this.graphAndSelectBorder.set(container.name, {graph: graph, border: undefined, menu: undefined, dragHandle: undefined, rotationPoint: rotationPoint})
    //     }

    //     this.render();

    //     // this.stage.removeListener('pointermove', checkDragging);
    //     // this.stage.removeListener('pointerup', toggleMenu);
    //   }
    // }

    // graph.on('pointerdown', onClick).on('pointermove', checkDragging).on('pointerup', toggleMenu);
    graph.on('pointerdown', ($event) => super.onPointerDown($event))
  }

  clearActiveSelection = (container: PIXI.Graphics) => {
    this.removeGraphMenu(container);
  }

  addActiveSelection = (container: PIXI.Graphics) => {
    this.addGraphMenu(container)
  }

  addGraphMenu(container: PIXI.Graphics){
    console.log("select graph")
    // get lineMenuContainer Object
    const containerId = this.getContainerIdFromName(container.name);
    const menuContainerName = this.getName(ENamingTypes.MENU, containerId)
    let menuContainer = <PIXI.Container>container.getChildByName(menuContainerName);
    const graphName = this.getName(ENamingTypes.TOOL, containerId)
    const graph = <PIXI.Graphics>container.getChildByName(graphName);

    // resize point
    const rotationPointName = this.getName(ENamingTypes.ROTATION_ANCHOR, containerId);
    const rotationPoint = <PIXI.Graphics>container.getChildByName(rotationPointName);
    rotationPoint.alpha = 1
    rotationPoint.interactive = true
    
    
    // new menu container childs
    const border = this.drawSelectedGraphBorder(container, undefined, false);
    const dragPoints = this.drawDragPoints(container, graph);
    const menu = this.drawMenu(container);
    dragPoints.alpha = 1;
    border.alpha = 1;
    menu.alpha = 1;

    if(!menuContainer){
      // add new menu container inside lineContainer
      menuContainer =  new PIXI.Container();
      menuContainer.name = this.getName(ENamingTypes.MENU, containerId);
      container.addChild(menuContainer);
    }
    menuContainer.addChild(...[border, dragPoints, menu])
  }

  removeGraphMenu(container: PIXI.Graphics){
    const containerId = this.getContainerIdFromName(container.name);
    const menuContainerName = this.getName(ENamingTypes.MENU, containerId)
    let menuContainer = <PIXI.Container>container.getChildByName(menuContainerName);
    if(menuContainer) menuContainer.removeChildren()

    this.MenuInputBox_x.onChange.removeAllListeners();
    this.MenuInputBox_y.onChange.removeAllListeners();

    // resize point
    const rotationPointName = this.getName(ENamingTypes.ROTATION_ANCHOR, containerId);
    const rotationPoint = <PIXI.Graphics>container.getChildByName(rotationPointName);
    if(rotationPoint) {
      rotationPoint.alpha = 0
      rotationPoint.interactive = false
    }
  }




  snapToNearestGrid_x(graphContainer: PIXI.Graphics){
    let index = Math.floor(graphContainer.x / this.gridGap)
    let remainder = graphContainer.x % this.gridGap
    if ((index + 1) * this.gridGap < this.element.canvasWidth && remainder > this.gridGap / 2){
      index = index + 1;
    }
    // graphContainer.x = index * gridGap;
    return index * this.gridGap;
  }

  snapToNearestGrid_y(graphContainer: PIXI.Graphics){
    let index = Math.floor(graphContainer.y / this.gridGap)
    let remainder = graphContainer.y % this.gridGap
    if ((index + 1) * this.gridGap < this.element.canvasHeight && remainder > this.gridGap / 2){
      index = index + 1;
    }
    // graphContainer.y = index * gridGap;
    return index * this.gridGap;
  }

  drawDragPoints(container: PIXI.Graphics, graph: PIXI.Graphics, points?: IPoint[], noListener?: boolean, isGhost?: boolean){
    if(!points?.length) points = this.getObjectMetaProps()[container.name].points;
    const center = this.getShapeCenter(points);
    const dragPoints = new PIXI.Graphics();
    const containerId = this.getContainerIdFromName(container.name);
    dragPoints.name = this.getName(ENamingTypes.RESIZE_ANCHOR, containerId);

    const graphInfo = this.graphContainersInfo.get(container.name)

    dragPoints.beginFill(0x0000, 0.9);
    // dragPoints.drawCircle(graphInfo.head2Org_X + 1, 0 - 2, 4);
    // dragPoints.drawCircle(0 - 2, graphInfo.head2Org_Y + 1, 4);
    // dragPoints.drawCircle(graphInfo.head2Org_X + 1, center.maxY + 2, 4);
    // dragPoints.drawCircle(center.maxX + 2, graphInfo.head2Org_Y + 1, 4);
    dragPoints.endFill();

    const top = new PIXI.Graphics();
    top.beginFill(0x0000, 0.9);
    top.drawCircle(graphInfo.head2Org_X + 1, 0, 4);
    top.endFill();


    const bottom = new PIXI.Graphics();
    bottom.beginFill(0x0000, 0.9);
    bottom.drawCircle(graphInfo.head2Org_X + 1, center.maxY, 4);
    bottom.endFill();

    const left = new PIXI.Graphics();
    left.beginFill(0x0000, 0.9);
    left.drawCircle(0, graphInfo.head2Org_Y + 1, 4);
    left.endFill();

    const right = new PIXI.Graphics();
    right.beginFill(0x0000, 0.9);
    right.drawCircle(center.maxX, graphInfo.head2Org_Y + 1, 4);
    right.endFill();

    dragPoints.addChild(top);
    dragPoints.addChild(bottom);
    dragPoints.addChild(left);
    dragPoints.addChild(right);

    dragPoints.alpha = 0
    dragPoints.interactive = true;

    this.setXandYoffset(dragPoints, container, isGhost)
    // container.addChild(dragPoints);

    if (!noListener) {
      this.addDragExpandListener(container, graph, top, DRAG_HANDLES.TOP);
      this.addDragExpandListener(container, graph, bottom, DRAG_HANDLES.BOTTOM);
      this.addDragExpandListener(container, graph, left, DRAG_HANDLES.LEFT);
      this.addDragExpandListener(container, graph, right, DRAG_HANDLES.RIGHT);
    }
    // this.addDragExpandListener(container, graph, dragPoints);

    return dragPoints;
  }

  isDragExpanding = false;
  graphBeingDragged: PIXI.Graphics;
  previousGraphContainerResizeAnchor;
  graphContainersDuringResize: PIXI.Graphics;
  addDragExpandListener(graphContainer: PIXI.Graphics, graph: PIXI.Graphics, dragPoint: PIXI.Graphics, dragPointPosition: DRAG_HANDLES) {
    let initialMousePosX = 0;
    let initialMousePosY = 0;
    let isDragging = false;
    this.isGlobalDragging = false;
    graphContainer.cursor = 'grab';
    graphContainer.interactive = true;

    dragPoint.cursor = 'ew-resize'
    dragPoint.interactive = true;

    let borderDragged: DRAG_HANDLES;

    let isClick = true;
    let rotation;
    const onDragStart = (e) => {
      this.isResizingPointerDown = true
      rotation = graphContainer.rotation;
      isClick = true;
      const mousePosition = e.data.getLocalPosition(this.stage);
      isDragging = true
      this.isDragExpanding = true
      initialMousePosX = mousePosition.x;
      initialMousePosY = mousePosition.y;
      graphContainer.cursor = 'grabbing';
      this.graphBeingDragged = graphContainer;
      borderDragged = dragPointPosition;

      this.graphContainersInfo.get(graphContainer.name);
      this.graphContainersInfo.set('resize', this.graphContainersInfo.get(graphContainer.name));

      this.stage.on('pointerup', onDragEnd)
      .on('pointermove', onDragMove); 
    }
    const onDragEnd = (e) => {
        if (!this.isDragExpanding || isClick){
          isDragging = false;
          this.isDragExpanding = false;
          this.graphBeingDragged = undefined;
          graphContainer.cursor = 'grab';
          this.stage.removeListener('pointermove', onDragMove); 
          this.stage.removeListener('pointerup', onDragEnd);
          return;
        } 
        this.clearPreviousContainer(graphContainer);
        const selectBorder = this.graphAndSelectBorder.get(graphContainer.name);
        graph.removeAllListeners();
        if (selectBorder && selectBorder.dragHandle){
          selectBorder.dragHandle.removeAllListeners();
          selectBorder.dragHandle.destroy();
        }
        isDragging = false;
        this.isDragExpanding = false;
        graphContainer.cursor = 'grab';
        this.graphBeingDragged = undefined;
        // if (this.previousGraphContainerResizeAnchor) this.previousGraphContainerResizeAnchor.clear();

        const lastGhostGraphInfo = this.graphContainersInfo.get('resize')
        if (lastGhostGraphInfo){
          const origin: IPoint = {
            x: lastGhostGraphInfo.x,
            y: lastGhostGraphInfo.y,
          }
          const tickMultipliers = {
            axisTickMultiplier_x: lastGhostGraphInfo.axisTickMultiplier_x, 
            axisTickMultiplier_y: lastGhostGraphInfo.axisTickMultiplier_y
          }
          const resizedGraph = this.completeGraph(lastGhostGraphInfo.width, lastGhostGraphInfo.height, origin, false, true, {head2Org_X: lastGhostGraphInfo.head2Org_X, head2Org_Y: lastGhostGraphInfo.head2Org_Y}, tickMultipliers, rotation);
          resizedGraph.x = lastGhostGraphInfo.x;
          resizedGraph.y = lastGhostGraphInfo.y;
          
          this.ghostGraph.removeChildren();
          this.graphContainersInfo.set('resize', undefined);
          this.activateListner(resizedGraph)
          this.removeContainer(graphContainer)
          this.isResizingPointerDown = false
          this.render()
          this.updateState();
        }
        
        this.stage.removeListener('pointermove', onDragMove); 
        this.stage.removeListener('pointerup', onDragEnd);
    }

    let taggedPoint_X = 0;
    let taggedPoint_Y = 0;
    const onDragMove = (e: PIXI.InteractionEvent) => {
      if(isDragging && !this.isGlobalRotating && !this.isProtRotateDragging && !this.isRulerRotateDragging) {
        graph.removeAllListeners();
        this.isDragExpanding = true
        const mousePosition = e.data.getLocalPosition(this.stage);
        const xDiff = mousePosition.x - initialMousePosX;
        const yDiff = mousePosition.y - initialMousePosY;
        const res = this.drawNewGraphOnResize(graphContainer, borderDragged, xDiff, yDiff, taggedPoint_X, taggedPoint_Y, dragPoint, rotation);
        if (res) {
          taggedPoint_X = res.taggedPoint_X;
          taggedPoint_Y = res.taggedPoint_Y;
          isClick = false;
          this.render();
        }
        const revokedContainer = this.graphContainersInfo.get(graphContainer.name);
        revokedContainer.isRevoked = true;
        this.graphContainersInfo.set(graphContainer.name, revokedContainer);
      } else if(!isDragging) {
        this.isDragExpanding = false;
      }
    }

    dragPoint.on('pointerdown', onDragStart);
  }

  clearPreviousContainer(graphContainer: PIXI.Graphics, dragPoint?: PIXI.Graphics){
    const selectBorder = this.graphAndSelectBorder.get(graphContainer.name);
    if (selectBorder){
      selectBorder.rotationPoint.alpha = 0;
      selectBorder.dragHandle.alpha = 1;
    }
    if (dragPoint) dragPoint.alpha = 0;
    graphContainer.children.forEach((child, i) => {
      if (child.name != ENamingTypes.RESIZE_ANCHOR){
        graphContainer.getChildAt(i).destroy()
      } else {
          this.previousGraphContainerResizeAnchor = child;
          child.alpha = 0;
      }
    })
  }

  initGhostGraphOnResizing(width: number, height: number, x: number, y: number, head2OrgX?: number , head2OrgY?: number, tickMultipliers? : {axisTickMultiplier_x: number, axisTickMultiplier_y: number}, rotation?){
    this.ghostGraph.removeChildren();
    let head2Org
    if (head2OrgX !== undefined || head2OrgY !== undefined) {
      head2Org = {
        head2Org_X: head2OrgX, 
        head2Org_Y: head2OrgY
      }
    }
    
    const ghostGraph = this.completeGraph(width, height, undefined, true, true, head2Org, tickMultipliers, rotation);
    ghostGraph.x = x;
    ghostGraph.y = y;
    ghostGraph.x = this.snapToNearestGrid_x(ghostGraph);
    ghostGraph.y = this.snapToNearestGrid_y(ghostGraph);

    const graphInfo: GraphInfo = {
      x: ghostGraph.x,
      y: ghostGraph.y,
      head2Org_X: head2OrgX !== undefined? head2OrgX : width/2,
      head2Org_Y: head2OrgY !== undefined? head2OrgY : height/2,
      width: width,
      height: height,
      isGhost: true,
      axisTickMultiplier_x: tickMultipliers.axisTickMultiplier_x,
      axisTickMultiplier_y: tickMultipliers.axisTickMultiplier_y
    }
    this.graphContainersInfo.set('resize', graphInfo);
    
    this.ghostGraph.addChild(ghostGraph);
    return ghostGraph;
  }

  graphContainersInfo = new Map<string, GraphInfo>();


  // Note: Draw new graph as the resize is going on. New graph is drawn when the mouse movment difference in the x or y 
  // direction hits the grid gap, this is done by initial and current mouse position difference modulus by the grid gap, 
  // however the mouse position values cannot be percise to exactly a 0 remainder most of the time, so the tolerance/threshold is 1.
  // taggedPoint_X and taggedPoint_Y records the latest new "chunk" added
  // to the axis (think of latest snap point), if the calculation result of a new mouse movement is still around that 
  // point (< 1), then there's no need to re-draw the graph.
  drawNewGraphOnResize(graphContainer: PIXI.Graphics, borderDragged:DRAG_HANDLES, xDiff: number, yDiff: number, taggedPoint_X: number, taggedPoint_Y: number, dragPoint: PIXI.Graphics, rotation){
    const num_x = Math.round(xDiff / (this.gridGap)); // which x grid the mouse currently sets at
    const remainder_x = Math.abs(xDiff) % (this.gridGap); // the mouse position within the grid
    const num_y = Math.round(yDiff / (this.gridGap));
    const remainder_y = Math.abs(yDiff) % (this.gridGap);
    const originalContainerInfo = this.graphContainersInfo.get(graphContainer.name)

    const graphInfo = this.graphContainersInfo.get(graphContainer.name);
    const tickMultipliers = {
      axisTickMultiplier_x: graphInfo.axisTickMultiplier_x, 
      axisTickMultiplier_y: graphInfo.axisTickMultiplier_y
    }

    switch (borderDragged){
      case DRAG_HANDLES.TOP:
        if ((Math.abs(remainder_y - 0) < 1 || Math.abs(remainder_y - this.gridGap / 2) < 1) && num_y != taggedPoint_Y){
          this.clearPreviousContainer(graphContainer, dragPoint);
          yDiff = Math.round(yDiff / this.gridGap) * this.gridGap
          const head2OrgY = originalContainerInfo.head2Org_Y - num_y * this.gridGap;
          console.log(head2OrgY)
          if (head2OrgY < 0) return;

          const newheight = head2OrgY + originalContainerInfo.height - originalContainerInfo.head2Org_Y
          this.initGhostGraphOnResizing(originalContainerInfo.width, newheight, originalContainerInfo.x, originalContainerInfo.y, originalContainerInfo.head2Org_X, head2OrgY, tickMultipliers, rotation);
          taggedPoint_Y = num_y;
        }
        break;
      case DRAG_HANDLES.BOTTOM:
        if ((Math.abs(remainder_y - 0) < 1 || Math.abs(remainder_y - this.gridGap / 2) < 1) && num_y != taggedPoint_Y){
          this.clearPreviousContainer(graphContainer, dragPoint);
          const head2OrgY = originalContainerInfo.head2Org_Y;
          console.log(head2OrgY)

          if ( originalContainerInfo.height + yDiff - head2OrgY <= 0) return;

          this.initGhostGraphOnResizing(originalContainerInfo.width, originalContainerInfo.height + num_y * this.gridGap, originalContainerInfo.x, originalContainerInfo.y, originalContainerInfo.head2Org_X, head2OrgY, tickMultipliers, rotation);
          taggedPoint_Y = num_y;
        }
        break;
      case DRAG_HANDLES.LEFT:
        // console.log(num_x, remainder_x, 'tagged: ', taggedPoint_X, ' num_x: ', num_x)
        if ((Math.abs(remainder_x - this.gridGap / 2) < 1 || Math.abs(remainder_x - 0) < 1)&& num_x != taggedPoint_X){
          this.clearPreviousContainer(graphContainer, dragPoint);
          const head2OrgX = originalContainerInfo.head2Org_X - num_x * this.gridGap;
          console.log(head2OrgX)

          if (head2OrgX < 0) return;

          const newWidth = originalContainerInfo.width - num_x * this.gridGap;
          this.initGhostGraphOnResizing(newWidth, originalContainerInfo.height, originalContainerInfo.x, originalContainerInfo.y, head2OrgX, originalContainerInfo.head2Org_Y, tickMultipliers, rotation);
          taggedPoint_X = num_x;
        }
        break;
      case DRAG_HANDLES.RIGHT:
        if ((Math.abs(remainder_x - 0) < 1 || Math.abs(remainder_x - this.gridGap / 2) < 1) && num_x != taggedPoint_X){
          this.clearPreviousContainer(graphContainer, dragPoint);
          const head2OrgX = originalContainerInfo.head2Org_X;
          if (originalContainerInfo.width + xDiff - head2OrgX <= 0) return;
          this.initGhostGraphOnResizing(originalContainerInfo.width + num_x * this.gridGap, originalContainerInfo.height, originalContainerInfo.x, originalContainerInfo.y, head2OrgX, originalContainerInfo.head2Org_Y, tickMultipliers, rotation);
          taggedPoint_X = num_x;
        }
        break;
      default:
        break;
    }
    return {
      taggedPoint_X: taggedPoint_X,
      taggedPoint_Y: taggedPoint_Y
    }
  }

  // Menu related

  drawMenuIcons(container: PIXI.Graphics, menu: PIXI.Graphics, menuHeight: number, itemGap: number, noListeners?: boolean){
    const containerId = this.getContainerIdFromName(container.name);

    const del = new PIXI.Sprite();
    del.texture = this.deleteSprite.texture;
    del.scale.set(0.03);
    del.anchor.set(0,0.5);
    del.y = menuHeight / 2;
    del.x = 10;
    del.interactive = true;
    del.name = this.getName(ENamingTypes.MENU_DEL, containerId)

    // X axis tick number 
    const xAxisText = new PIXI.Text("X Axis: ", {fontSize: 8});
    xAxisText.style.fill = 0xffffff;
    xAxisText.y = (menu.height / 2) - (xAxisText.height / 2);
    xAxisText.x = del.x + itemGap;

    const down_button_container_x = new PIXI.Graphics();
    down_button_container_x.lineStyle(1, 0xffffff, 0.15);
    down_button_container_x.drawRoundedRect(0,0, 12.3, menuHeight/2, 2)
    // down_button_container_x.position.set(fontSizeContainer_x.x + fontSizeContainer_x.width, menuHeight/2 - ((menuHeight/2) / 2));
    down_button_container_x.position.set(xAxisText.x + 10 + itemGap, menuHeight/2 - ((menuHeight/2) / 2));


    const down_x = new PIXI.Sprite();
    down_x.texture = this.downSprite.texture;
    down_x.scale.set(0.012);
    down_x.anchor.set(0,0.5);
    down_x.y = down_button_container_x.height / 2;
    down_x.interactive = true;

    down_button_container_x.addChild(down_x);

    const textBoxTest_x = this.MenuInputBox_x.createTextBox(0, 0, 0xffffff, null, 25, menuHeight/2, container);
    const graphInfo = this.graphContainersInfo?.get(container?.name);
    this.MenuInputBox_x.updateText(this.formatNumberDisplay(graphInfo?.axisTickMultiplier_x || this.currentMultiplier_x));
    textBoxTest_x.position.set(down_button_container_x.x + down_button_container_x.width, menuHeight/2 - ((menuHeight/2) / 2));
    this.MenuInputBox_x.deselectAll();
    this.MenuInputBox_x.deselectTextboxes();
    if (!noListeners) this.MenuInputBox_x.onChange.on('onInputUpdate', this.onNumberDisplayUpdate_x.bind(this))


    const up_button_container_x = new PIXI.Graphics();
    up_button_container_x.lineStyle(1, 0xffffff, 0.15);
    up_button_container_x.drawRoundedRect(0,0, 12.3, menuHeight/2, 2)
    up_button_container_x.position.set(textBoxTest_x.x + textBoxTest_x.width, menuHeight/2 - ((menuHeight/2) / 2));

    const up_x = new PIXI.Sprite();
    up_x.texture = this.upSprite.texture;
    up_x.scale.set(0.012);
    up_x.anchor.set(0,0.5);
    up_x.y = up_button_container_x.height / 2;
    up_x.interactive = true;

    up_button_container_x.addChild(up_x);

    // Y axis tick number 
    const yAxisText = new PIXI.Text("Y Axis: ", {fontSize: 8});
    yAxisText.style.fill = 0xffffff;
    yAxisText.y = (menu.height / 2) - (yAxisText.height / 2);
    yAxisText.x = up_button_container_x.x + itemGap;


    const down_button_container_y = new PIXI.Graphics();
    down_button_container_y.lineStyle(1, 0xffffff, 0.15);
    down_button_container_y.drawRoundedRect(0,0, 12.3, menuHeight/2, 2)
    // down_button_container_y.position.set(fontSizeContainer_y.x + fontSizeContainer_y.width, menuHeight/2 - ((menuHeight/2) / 2));
    down_button_container_y.position.set(yAxisText.x + 10 + itemGap, menuHeight/2 - ((menuHeight/2) / 2));


    const down_y = new PIXI.Sprite();
    down_y.texture = this.downSprite.texture;
    down_y.scale.set(0.012);
    down_y.anchor.set(0,0.5);
    down_y.y = down_button_container_y.height / 2;
    down_y.interactive = true;

    down_button_container_y.addChild(down_y);

    const textBoxTest_y = this.MenuInputBox_y.createTextBox(0, 0, 0xffffff, null, 25, menuHeight/2, container);
    this.MenuInputBox_y.updateText(this.formatNumberDisplay(graphInfo?.axisTickMultiplier_y || this.currentMultiplier_y));
    textBoxTest_y.position.set(down_button_container_y.x + down_button_container_y.width, menuHeight/2 - ((menuHeight/2) / 2));
    this.MenuInputBox_y.deselectAll();
    this.MenuInputBox_y.deselectTextboxes();
    if (!noListeners) this.MenuInputBox_y.onChange.on('onInputUpdate', this.onNumberDisplayUpdate_y.bind(this))

    const up_button_container_y = new PIXI.Graphics();
    up_button_container_y.lineStyle(1, 0xffffff, 0.15);
    up_button_container_y.drawRoundedRect(0,0, 12.3, menuHeight/2, 2)
    up_button_container_y.position.set(textBoxTest_y.x + textBoxTest_y.width, menuHeight/2 - ((menuHeight/2) / 2));

    const up_y = new PIXI.Sprite();
    up_y.texture = this.upSprite.texture;
    up_y.scale.set(0.012);
    up_y.anchor.set(0,0.5);
    up_y.y = up_button_container_y.height / 2;
    up_y.interactive = true;

    up_button_container_y.addChild(up_y)
    menu.addChild(del);
    menu.addChild(xAxisText);
    menu.addChild(up_button_container_x);
    menu.addChild(down_button_container_x);
    menu.addChild(yAxisText);
    menu.addChild(up_button_container_y);
    menu.addChild(down_button_container_y);
    menu.addChild(textBoxTest_x);
    menu.addChild(textBoxTest_y);

    return [del, up_x, down_x, up_y, down_y];
  }

  drawMenu(container: PIXI.Graphics, points?: IPoint[], noListener?: boolean) {
    this.MenuInputBox_x.onChange.removeAllListeners();
    this.MenuInputBox_y.onChange.removeAllListeners();

    if(!points?.length) points = this.getObjectMetaProps()[container.name].points;
    const center = this.getShapeCenter(points);
    const menu = new PIXI.Graphics();
    const menuYOffset = 15;
    const menuY = center.y + ((center.maxY - center.minY) / 2) + menuYOffset
    const menuHeight = 30;
    const menuWidth = 215;
    const itemGap = 20;
    menu.beginFill(0x645f73);
    menu.lineStyle(0);
    menu.drawRoundedRect(0, 0, menuWidth, menuHeight, 5);
    menu.position.set(center.minX, menuY);
    menu.endFill();
    menu.alpha = 0;
    
    const [del, up_x, down_x, up_y, down_y] = this.drawMenuIcons(container, menu, menuHeight, itemGap)

    if(!noListener) {
      this.addMenuListeners(container, ACTIONS.DELETE, del);
      this.addMenuListeners(container, ACTIONS.SIZE_UP_X, up_x);
      this.addMenuListeners(container, ACTIONS.SIZE_DOWN_X, down_x);
      this.addMenuListeners(container, ACTIONS.SIZE_UP_Y, up_y);
      this.addMenuListeners(container, ACTIONS.SIZE_DOWN_Y, down_y);
    };
    
    this.setXandYoffset(menu, container, false);
    // container.addChild(menu);

    return menu;
  }

  addMenuListeners(obj: PIXI.Graphics, action: ACTIONS, option: PIXI.Sprite, fontSizeTextGraphic?: PIXI.Text) {
    let func;
    option.cursor = 'pointer';
    if(action == ACTIONS.DELETE) {
      func = (e) => {
        this.deleteContainerFromMenu(e.currentTarget as PIXI.Sprite)
        this.ghostGraph.removeChildren()
        this.graphContainersInfo.get(obj.name).isRevoked = true;
        this.updateState();
      }
    } else if(action == ACTIONS.SIZE_UP_X) {
      func = (e) => {
        this.increastTickMark_x(obj);
        // fontSizeTextGraphic.text = this.formatNumberDisplay(this.currentMultiplyer_x);
        this.MenuInputBox_x.updateText(this.formatNumberDisplay(this.currentMultiplier_x));

        this.updateState();
        this.render();
      }
    } else if(action == ACTIONS.SIZE_DOWN_X) {
      func = (e) => {
        this.decreaseTickMark_x(obj);
        // fontSizeTextGraphic.text = this.formatNumberDisplay(this.currentMultiplyer_x);
        this.MenuInputBox_x.updateText(this.formatNumberDisplay(this.currentMultiplier_x));

        this.updateState();
        this.render();
      }
    } else if(action == ACTIONS.SIZE_UP_Y) {
      func = (e) => {
        this.increastTickMark_y(obj);
        // fontSizeTextGraphic.text = this.formatNumberDisplay(this.currentMultiplyer_y);
        this.MenuInputBox_y.updateText(this.formatNumberDisplay(this.currentMultiplier_y));

        this.updateState();
        this.render();
      }
    } else if(action == ACTIONS.SIZE_DOWN_Y) {
      func = (e) => {
        this.decreaseTickMark_y(obj);
        // fontSizeTextGraphic.text = this.formatNumberDisplay(this.currentMultiplyer_y);
        this.MenuInputBox_y.updateText(this.formatNumberDisplay(this.currentMultiplier_y));

        this.updateState();
        this.render();
      }
    }

    option.on('pointerup', func);
  }

  deleteObject(obj: PIXI.Graphics) {
    obj.removeAllListeners();
    obj.removeChildren();
    obj.clear();
    this.ghostGraph.removeChildren();
    this.toggleGraph();
  }

  loadAssets() {
    return this.spriteLoader.loadSprites()
  }

  drawSelectedGraphBorder(container: PIXI.Graphics, points?: IPoint[], isGhost?: boolean) {
    if(!points?.length) points = this.getObjectMetaProps()[container.name].points;
    const center = this.getShapeCenter(points);
    const border = new PIXI.Graphics();
    border.lineStyle(2, 0x0000, 1);
    border.beginFill(0x0000, 0.1);
    border.drawRect(center.minX, center.minY, (center.maxX-center.minX), (center.maxY-center.minY));
    border.endFill();
    border.alpha = 0;

    this.setXandYoffset(border, container, isGhost);
    // container.addChild(border);

    return border;
  }


  // A helper function to set the x and y offset of a component within the graph container (for graphs that
  // have asymetrical x and y pos vs. negative length)
  setXandYoffset(component: PIXI.Graphics, graphContainer:PIXI.Graphics, isGhost: boolean){
    const graphInfo = isGhost? this.graphContainersInfo.get('resize') : this.graphContainersInfo.get(graphContainer.name);
    if (graphInfo) {
      component.x -= (graphInfo.head2Org_X - graphInfo.width / 2);
      component.y -= (graphInfo.head2Org_Y - graphInfo.height / 2);
    }
  }

  // This method handles the select border of the graph on resize, the actual border is a child of the graph
  // container and the position is unreliable when user is resizing the graph, so when resizing, draw a visual
  // select border that's a direct child of the graph.
  drawGraphBorderOnResize(graphContainer: PIXI.Graphics, graph: PIXI.Graphics, headToOriginDistance_X: number, headToOriginDistance_Y: number, xAxisLength: number, yAxisLength: number){
    const border = new PIXI.Graphics();
    border.lineStyle(2, 0x0000, 1);
    border.beginFill(0x0000, 0.1);
    const xOffest = - (headToOriginDistance_X - xAxisLength / 2);
    const yOffest = - (headToOriginDistance_Y - yAxisLength / 2);
    border.drawRect(xOffest, yOffest, xAxisLength, yAxisLength);
    border.endFill();
    border.alpha = 1;

    border.lineStyle(0, 0x0000, 1);
    border.beginFill(0x0000, 0.9);
    border.drawCircle(xOffest + headToOriginDistance_X + 1, yOffest, 4);
    border.drawCircle(xOffest + headToOriginDistance_X + 1, yOffest + yAxisLength, 4);
    border.drawCircle(xOffest, yOffest + headToOriginDistance_Y + 1, 4);
    border.drawCircle(xOffest + xAxisLength, yOffest + headToOriginDistance_Y + 1, 4);
    border.endFill();

    border.beginFill(0x0000, 0.9);
    border.drawRect(xOffest + headToOriginDistance_X, yOffest - 2, 2, -20)
    border.drawCircle(xOffest + headToOriginDistance_X + 1, yOffest - 20, 4);
    border.endFill();

    graph.addChild(border);

    const graphCornorPoints = this.getGraphCornerPoints(xAxisLength, yAxisLength);

    const center = this.getShapeCenter(graphCornorPoints);
    const menu = new PIXI.Graphics();
    const menuYOffset = 15 + yOffest;
    const menuY = center.y + ((center.maxY - center.minY) / 2) + menuYOffset
    const menuHeight = 30;
    const menuWidth = 215;
    const itemGap = 20;
    menu.beginFill(0x645f73);
    menu.lineStyle(0);
    menu.drawRoundedRect(0, 0, menuWidth, menuHeight, 5);
    menu.position.set(center.minX, menuY);
    menu.endFill();
    menu.alpha = 1;

    this.MenuInputBox_x.onChange.removeAllListeners();
    this.MenuInputBox_y.onChange.removeAllListeners();

    // Menu icons
    const del = new PIXI.Sprite();
    del.texture = this.deleteSprite.texture;
    del.scale.set(0.03);
    del.anchor.set(0,0.5);
    del.y = menuHeight / 2;
    del.x = 10;
    del.interactive = true;

    this.drawMenuIcons(graphContainer, menu, menuHeight, itemGap, true)

    menu.x = xOffest;
    // menu.y = yOffest;
    graphContainer.addChild(menu);


    return border;
  }

  // X and Y axis tick number display update
  tickIncrementMultiplier = [1, 2, 5];
  currentMultiplier_x = 1;
  multiplierArray_x = [0.01, 0.02, 0.05, 0.1, 0.2, 0.5, 1];
  currentMultiplierIndex_x = 6;
  getAxisNumberDisplayGraphics_x(container: PIXI.Graphics){
    const graph = <PIXI.Graphics>container.children.filter(child => child.name && child.name.includes(ENamingTypes.TOOL))[0];
    const xAxis = <PIXI.Graphics>graph.children.filter(child => child.name.includes('xAxis'))[0];
    const ticks_x = xAxis.children.filter(child => child.name && child.name.includes(ENamingTypes.TICK_NUMBER_X)).map(child => <PIXI.Text>child);

    return ticks_x;
  }

  getAxisNumberDisplayGraphics_y(container: PIXI.Graphics){
    const graph = <PIXI.Graphics>container.children.filter(child => child.name && child.name.includes(ENamingTypes.TOOL))[0];
    const xAxis = <PIXI.Graphics>graph.children.filter(child => child.name.includes('yAxis'))[0];
    const ticks_y = xAxis.children.filter(child => child.name && child.name.includes(ENamingTypes.TICK_NUMBER_Y)).map(child => <PIXI.Text>child);

    return ticks_y
  }

  updateMultipluer(multiplierInput: number, multiplierIndexInput: number, multiplierArrayInput: number[]){
    if (multiplierIndexInput > multiplierArrayInput.length - 1){
      const multiplyerIndex = (multiplierIndexInput - 6) % this.tickIncrementMultiplier.length;
      let multiplyer_ten = Math.floor((multiplierIndexInput - 6) / this.tickIncrementMultiplier.length);
      multiplierInput = this.tickIncrementMultiplier[multiplyerIndex] * Math.pow(10, multiplyer_ten);
      multiplierArrayInput.push(multiplierInput);
    } else {
      multiplierInput =  multiplierArrayInput[multiplierIndexInput];
    }

    return {multiplier: multiplierInput,
            multiplierIndex: multiplierIndexInput,
            multiplierArray: multiplierArrayInput
    }
  }

  applyMultiplyer(ticks: PIXI.Text[], multiplyer: any, isYaxis?: boolean){
    ticks.forEach(number => {
      const baseIndex = Number.parseInt(number.name.match(/.*_(-?\d+)$/)[1])

      // (multiplyer.toFixed(2)*100)/100 is to fix decimals arithmetic in javascript, to avoid 
      // calculations like 0.1 + 0.2 = 0.30000000000000004
      // cut decimals after 2 positions, and multiply the number by 100 to remove the decimal part.
      const newNumber = (baseIndex * (multiplyer.toFixed(2)*100) / 100);

      let format
      if (newNumber < 0) {
        format = '-' + this.formatNumberDisplay(-1 * newNumber);
      } else {
        format = this.formatNumberDisplay(newNumber);
      }
      number.text = format;

    })
  }

  // Method to abbreviate numbers with "K", "M", "B" or "T"
  formatNumberDisplay(n: number){
    if (n < 1e3) return n + '';
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  };

  increastTickMark_x(container: PIXI.Graphics){
    this.currentMultiplierIndex_x += 1;
    // if last edit was a user input resulting in a non-integer index
    this.currentMultiplierIndex_x = Math.floor(this.currentMultiplierIndex_x);

    const updateMultiplyerRes = this.updateMultipluer(this.currentMultiplier_x, this.currentMultiplierIndex_x, this.multiplierArray_x);
    this.currentMultiplier_x = updateMultiplyerRes.multiplier;
    this.currentMultiplierIndex_x = updateMultiplyerRes.multiplierIndex;
    this.multiplierArray_x = updateMultiplyerRes.multiplierArray;

    const ticks_x = this.getAxisNumberDisplayGraphics_x(container);
    this.applyMultiplyer(ticks_x, this.currentMultiplier_x);


    // Update the stored multiplier in the graph data
    const graphInfo = this.graphContainersInfo.get(container.name);
    graphInfo.axisTickMultiplier_x = this.currentMultiplier_x;

    this.render()
  } 

  decreaseTickMark_x(container: PIXI.Graphics) {
    if (this.currentMultiplierIndex_x - 1 >= 0) {
      this.currentMultiplierIndex_x -= 1;
      // if last edit was a user input resulting in a non-integer index
      this.currentMultiplierIndex_x = Math.ceil(this.currentMultiplierIndex_x);
    }

    this.currentMultiplier_x =  this.multiplierArray_x[this.currentMultiplierIndex_x];
    const ticks_x = this.getAxisNumberDisplayGraphics_x(container);
    this.applyMultiplyer(ticks_x, this.currentMultiplier_x);

    // Update the stored multiplier in the graph data
    const graphInfo = this.graphContainersInfo.get(container.name);
    graphInfo.axisTickMultiplier_x = this.currentMultiplier_x;

    this.render()
  }

  currentMultiplier_y = 1;
  multiplierArray_y = [0.01, 0.02, 0.05, 0.1, 0.2, 0.5, 1];
  currentMultiplierIndex_y = 6;
  increastTickMark_y(container: PIXI.Graphics){
    this.currentMultiplierIndex_y += 1;
    this.currentMultiplierIndex_y = Math.floor(this.currentMultiplierIndex_y);

    const updateMultiplyerRes = this.updateMultipluer(this.currentMultiplier_y, this.currentMultiplierIndex_y, this.multiplierArray_y);
    this.currentMultiplier_y = updateMultiplyerRes.multiplier;
    this.currentMultiplierIndex_y = updateMultiplyerRes.multiplierIndex;
    this.multiplierArray_y = updateMultiplyerRes.multiplierArray;
    
    const ticks_y = this.getAxisNumberDisplayGraphics_y(container);
    this.applyMultiplyer(ticks_y, this.currentMultiplier_y, true);

    // Update the stored multiplier in the graph data
    const graphInfo = this.graphContainersInfo.get(container.name);
    graphInfo.axisTickMultiplier_y = this.currentMultiplier_y;

    this.render()
  } 

  decreaseTickMark_y(container: PIXI.Graphics) {
    if (this.currentMultiplierIndex_y - 1 >= 0) {
      this.currentMultiplierIndex_y -= 1;
      // if last edit was a user input resulting in a non-integer index
      this.currentMultiplierIndex_y = Math.ceil(this.currentMultiplierIndex_y);
    }

    this.currentMultiplier_y =  this.multiplierArray_y[this.currentMultiplierIndex_y];
    const ticks_y = this.getAxisNumberDisplayGraphics_y(container);
    this.applyMultiplyer(ticks_y, this.currentMultiplier_y, true);

    // Update the stored multiplier in the graph data
    const graphInfo = this.graphContainersInfo.get(container.name);
    graphInfo.axisTickMultiplier_y = this.currentMultiplier_y;

    this.render()
  }

  changeDrawnGraphicColor(){}

  activateListner(container: PIXI.Graphics){
    container.interactive = true;
      container.children.forEach(child => {
        child.interactive = true
      })
  }

  activateSelectionListners(){
    this.getObjectContainer().forEach((container: PIXI.Graphics) => this.activateListner(container))
  }

  onNumberDisplayUpdate_x(textBoxData: ITextboxData){
    if (!textBoxData.input || textBoxData.input === '') return;

    this.updateCurrentMultiplierArrayAndIndex_x(Number(textBoxData.input));

    const ticks_x = this.getAxisNumberDisplayGraphics_x(textBoxData.sourceContainer);
    this.currentMultiplier_x = Number(textBoxData.input);
    // this.currentMultiplierIndex_x

    // Update the stored multiplier in the graph data
    const graphInfo = this.graphContainersInfo.get(textBoxData.sourceContainer.name);
    graphInfo.axisTickMultiplier_x = this.currentMultiplier_x;

    this.applyMultiplyer(ticks_x, this.currentMultiplier_x, true);
  }

  onNumberDisplayUpdate_y(textBoxData: ITextboxData){
    if (!textBoxData.input || textBoxData.input === '') return;

    this.updateCurrentMultiplierArrayAndIndex_y(Number(textBoxData.input));

    const ticks_y = this.getAxisNumberDisplayGraphics_y(textBoxData.sourceContainer);
    this.currentMultiplier_y = Number(textBoxData.input);
    // this.currentMultiplierIndex_y

    // Update the stored multiplier in the graph data
    const graphInfo = this.graphContainersInfo.get(textBoxData.sourceContainer.name);
    graphInfo.axisTickMultiplier_y = this.currentMultiplier_y;

    this.applyMultiplyer(ticks_y, this.currentMultiplier_y, true);
  }

  // This method helps to determin which range of the multiplyer that user manually typed should
  // fall into, for example if the current multiplier list is [0.01, 0.02, 0.05, 0.1, 0.2, 0.5, 1, 2, 5],
  // and user typed 25 into the value, then the current multiplier list should be updated together with
  // the active multiplier index. The multiplier list will be expaned so that the user input is the second
  // largest number, i.e. [0.01, 0.02, 0.05, 0.1, 0.2, 0.5, 1, 2, 5, 10, 50]. 
  updateCurrentMultiplierArrayAndIndex_x(value: number){
    const currMultiplierArray = this.multiplierArray_x;

    if (currMultiplierArray.indexOf(value) != -1){
      this.currentMultiplierIndex_x = currMultiplierArray.indexOf(value);
      return;
    } 

    let insertIndex = this.sortedIndex(currMultiplierArray, value);
    while (insertIndex >= currMultiplierArray.length){
      const multiplyerIndex = (insertIndex - 6) % this.tickIncrementMultiplier.length;
      let multiplyer_ten = Math.floor((insertIndex - 6) / this.tickIncrementMultiplier.length);
      const multiplierInput = this.tickIncrementMultiplier[multiplyerIndex] * Math.pow(10, multiplyer_ten);
      currMultiplierArray.push(multiplierInput);
      insertIndex = this.sortedIndex(currMultiplierArray, value);
    }

    // Set index to a non-integer (middle of the index of the next and previous multiplier),
    // the floor() and ceil() in the increase will round it to the appropreate index when the plus/minus
    // button is clicked.
    this.currentMultiplierIndex_x = insertIndex - 0.5;
    this.multiplierArray_x = currMultiplierArray;
  }

  updateCurrentMultiplierArrayAndIndex_y(value: number){
    const currMultiplierArray = this.multiplierArray_y;

    if (currMultiplierArray.indexOf(value) != -1){
      this.currentMultiplierIndex_y = currMultiplierArray.indexOf(value);
      return;
    } 

    let insertIndex = this.sortedIndex(currMultiplierArray, value);
    while (insertIndex >= currMultiplierArray.length){
      const multiplyerIndex = (insertIndex - 6) % this.tickIncrementMultiplier.length;
      let multiplyer_ten = Math.floor((insertIndex - 6) / this.tickIncrementMultiplier.length);
      const multiplierInput = this.tickIncrementMultiplier[multiplyerIndex] * Math.pow(10, multiplyer_ten);
      currMultiplierArray.push(multiplierInput);
      insertIndex = this.sortedIndex(currMultiplierArray, value);
    }

    // Set index to a non-integer (middle of the index of the next and previous multiplier),
    // the floor() and ceil() in the increase will round it to the appropreate index when the plus/minus
    // button is clicked.
    this.currentMultiplierIndex_y = insertIndex - 0.5;
    this.multiplierArray_y = currMultiplierArray;
  }

  sortedIndex(array, value) {
    var low = 0, high = array.length;

    while (low < high) {
      var mid = low + high >>> 1;
      if (array[mid] < value) low = mid + 1;
      else high = mid;
    }

    return low;
  }

  getUpdatedState(entry: Partial<IEntryStateVirtualTools>): Partial<IEntryStateVirtualTools> {
    const newData = [];
    this.graphContainersInfo.forEach((val) => {
      if(val != undefined && !val.isGhost && !val.isRevoked){
        newData.push(val);
      }
    });

    entry.data[EPixiTools.GRAPH] = newData;
    return entry;
  }

  handleNewState() {
    const data = this.getQuestionState(EPixiTools.GRAPH);
    if(!data || data.length == 0) {
      return; 
    }

    data.forEach((graph) => {
      const temp: GraphInfo = JSON.parse(JSON.stringify(graph))
      this.completeGraph(temp.width, temp.height, {x: temp.x, y: temp.y}, undefined, false, {head2Org_X: temp.head2Org_X, head2Org_Y: temp.head2Org_Y}, {axisTickMultiplier_x: temp.axisTickMultiplier_x, axisTickMultiplier_y: temp.axisTickMultiplier_y});
    });

    this.render();
  }
}
