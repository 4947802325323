import { ALLOW_PASI_UPDATES } from './../constants/feature-flags';
import { Injectable } from '@angular/core';
import { initMappedList, IMappedList } from './data/util';
import { STUDENT_G9_COURSES, STUDENT_G9_COURSES_SIMPLE, G9_COURSES } from './data/constants';
import { IIdentifiedEntry, IDataDefinition } from './data/types';
import { IStudentAccount, IStudentTestSession } from './data/types';
import { IClassroomArchivedTeachers, IUserInfo, IClassroomSlot, ASSESSMENT } from '../../app/ui-teacher/data/types';
import { IClassroom, ISemester, ISession} from "./data/types"
import { generateEntries, randArrEntry, randInt, randId, coinFlip, randDate, IAvailableSession } from "../ui-testadmin/demo-data.service";
import { randomIdentity } from "../constants/fakenames";
import { LangService } from '../core/lang.service';
import { AuthService } from '../api/auth.service';
import { RoutesService } from '../api/routes.service';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { AssessmentCode, DATA_MAPPING_EQAO_G9_STUDENT, ICheckMarkMapping, IDataMappingG9Student } from './data/mappings/eqao-g9';
import { BC_SAMPLE_SCHOOL_FSA } from './data/sample-data/school';
import { WhitelabelService } from "../../app/domain/whitelabel.service";
import { ClassFilterId } from './my-school.service';
import { ReadingSelectionButtonRendererComponent } from '../ui-scoring-leader/panel-sl-training-materials/reading-selection-button-renderer/reading-selection-button-renderer.component';
import { SCHOOL_ADMIN_TECH_READ_CHECKLIST_MASTER } from './sa-tech-readiness/data/checklist-master';
import { AssessmentsService } from '../assessments.service';
import { mtz } from '../core/util/moment';

export const assessmentSessionStatuses = ["active", "scheduled", "submitted"];
export const sessionsCountLabel = "SessionsCount";

export const generateAccessCode = (len: number) => {
  let result = '';
  const characters = 'ABCDEFGHJKLMNPQRTUVWXYZ12346789';
  const charactersLength = characters.length;
  for (var i = 0; i < len; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
export const hyphenateAccessCode = (accessCode: string) => {
  if (!accessCode) { return accessCode; }
  let result = '';
  const hyphenInterval = 4;
  for (var i = 0; i < accessCode.length; i++) {
    if (i > 0 && (i % hyphenInterval == 0)) {
      result += '-'
    }
    result += accessCode.charAt(i);
  }
  return result;
}

// todo:DB_DATA_MODEL
export const G9_SLUG_TO_CAPTION = {
  PRIMARY_SAMPLE: 'lbl_primary_sample_assessment',
  PRIMARY_OPERATIONAL: 'lbl_primary_operational_assessment',
  JUNIOR_SAMPLE: 'lbl_junior_sample_assessment',
  JUNIOR_OPERATIONAL: 'lbl_junior_operational_assessment',
  G9_SAMPLE: 'g9_sample_test',
  G9_OPERATIONAL: 'g9_assess_math',
  OSSLT_SAMPLE: 'lbl_osslt_practice_test',
  OSSLT_OPERATIONAL: 'lbl_osslt_test',
  TCLE_OPERATIONAL: 'lbl_nbed_tcle_operational_test',
  TCLE_SAMPLE: 'lbl_nbed_tcle_sample_test',
  TCN_OPERATIONAL: 'lbl_nbed_tcn_operational_test',
  TCN_SAMPLE: 'lbl_nbed_tcn_sample_test',
  SCIENCES8_OPERATIONAL: 'lbl_nbed_sciences8_operational_test',
  SCIENCES8_SAMPLE: 'lbl_nbed_sciences8_sample_test',
  ABED_SAMPLE: 'lbl_abed_sample_test',
  ABED_OPERATIONAL: 'lbl_abed_operational_test',
  ABED_ELA_6A: 'assessment_abed_ela_part_a',
  ABED_ELA_6B: 'assessment_abed_ela_part_b',
  ABED_FLA_6A: 'assessment_abed_fla_part_a',
  ABED_FLA_6B: 'assessment_abed_fla_part_b',
  ABED_MATH_6A: 'assessment_abed_math_part_a',
  ABED_MATH_6B: 'assessment_abed_math_part_b',
  ABED_MATH_6A2: 'assessment_abed_math_part_a2',
  ABED_MATH_6B2: 'assessment_abed_math_part_b2',
  ABED_SCIENCES_6: 'assessment_abed_g6_science',
  ABED_SOCIAL_STUDIES_6: 'assessment_abed_g6_social',

  ABED_ELA_9A: 'assessment_abed_g9_ela_part_a',
  ABED_ELA_9B: 'assessment_abed_g9_ela_part_b',
  ABED_FLA_9A: 'assessment_abed_g9_fla_part_a',
  ABED_FLA_9B: 'assessment_abed_g9_fla_part_b',
  ABED_MATH_9A: 'assessment_abed_g9_math_part_a',
  ABED_MATH_9B: 'assessment_abed_g9_math_part_b',
  ABED_SCIENCES_9: 'assessment_abed_g9_science',
  ABED_SOCIAL_STUDIES_9: 'assessment_abed_g9_social',

  ABED_KE_ELA_9A: 'assessment_abed_g9_K&E_ela_part_a',
  ABED_KE_ELA_9B: 'assessment_abed_g9_K&E_ela_part_b',
  ABED_KE_MATH_9: 'assessment_abed_g9_K&E_math',
  ABED_KE_SCIENCES_9: 'assessment_abed_g9_K&E_science',
  ABED_KE_SOCIAL_STUDIES_9: 'assessment_abed_g9_K&E_social',

  ABED_SAMPLE_ELA_6: 'assessment_abed_sample_ela_6',
  ABED_SAMPLE_MATH_6: 'assessment_abed_sample_math_6',
  ABED_SAMPLE_SCIENCE_6: 'assessment_abed_sample_science_6',
  ABED_SAMPLE_FR_IMMERSION_6: 'assessment_abed_sample_fr_immersion_6',
  ABED_SAMPLE_FR_FIRST_6: 'assessment_abed_sample_fr_first_6',

  ABED_ELA_30_2: 'ABED_ELA_30_2',
  ABED_ELA_30_1: 'ABED_ELA_30_1',
  ABED_FLA_30_1: 'ABED_FLA_30_1',
  ABED_FR_30_1: 'ABED_FR_30_1',
  ABED_SS_30_1: 'ABED_SS_30_1',
  ABED_SS_30_2: 'ABED_SS_30_2'
}

export const overlapVariables = [
  'first_name',         'last_name',                'eqao_student_school_enrol_type', 'eqao_student_gov_id',     'SASN',
  'date_of_birth',      'eqao_gender',              'eqao_date_entered_school',       'eqao_date_entered_board', 'eqao_indigenous_type',
  'eqao_first_language','eqao_enrolled_ontario',    'eqao_out_province_residence',    'eqao_status_in_canada',   'eqao_time_in_canada',
  'eqao_refugee',       'eqao_born_outside_canada', 'eqao_is_g3',                     'eqao_is_g6',              'eqao_is_g9',        'eqao_is_g10',
  'nbed_student_identification_number',             'nbed_user_id',                   'nbed_student_grade',
  'abed_student_identification_number',             'abed_student_grade',             'abed_date_of_birth'
 ]

 const VIDEO_INTRO_SLUG = "invig_video_intro_seen";

@Injectable({
  providedIn: 'root'
})

export class G9DemoDataService {


  teachers: IMappedList<IIdentifiedEntry>;
  invigilators:any[];
  schoolAdminStudents: IMappedList<IStudentAccount>;
  classOptions: IMappedList<IDataDefinition>;
  classrooms: IClassroom[];
  guestClasses : any[];
  guestClassesInvigilate : any[];
  semesters: IMappedList<ISemester>;
  teacherClassrooms: IMappedList<any>;
  schoolData: any;
  assessments:IMappedList<ISession>;
  schoolSessions: any[];
  private isFromSchoolAdmin = false;
  private studentMappings = DATA_MAPPING_EQAO_G9_STUDENT;
  public checkMarkMapping: ICheckMarkMapping = {};
  public g10PropCheckMarkMapping: ICheckMarkMapping = {};
  private forceTeacherDataRefresh: boolean = false;
  teacherChecklist: any[];

  private classroomMappings = [
    { source: 'id', target: 'id' },
    { source: 'name', target: 'class_code' },
    { source: 'teacher', target: 'educator' },
    { source: 'teacher_uid', target: 'teacher_uid' },
    { source: 'semester_id', target: 'semester' },
    { source: 'semester_foreign_id', target: 'semester_foreign_id' },
    { source: 'num_students', target: 'students' },
    { source: 'group_type', target: 'course_type' },
    { source: 'is_grouping', target: 'is_grouping' },
    { source: 'is_placeholder', target: 'is_placeholder' },
    { source: 'is_fi', target: 'is_fi' },
    { source: 'group_id', target: 'group_id' },
  ];

  abedSessionsCounts = [];
  activeSessionsCount:number;
  scheduledSessionsCount:number;
  submittedSessionsCount: number;
  primaryActiveSessionsCount:number;
  primaryScheduledSessionsCount:number;
  juniorActiveSessionsCount:number;
  juniorScheduledSessionsCount:number;
  g9ActiveSessionsCount:number;
  g9ScheduledSessionsCount:number;
  ossltActiveSessionsCount:number;
  ossltScheduledSessionsCount:number;
  attempts: IMappedList<IIdentifiedEntry>;
  testWindows: any[];
  schoolDistrict:{foreign_id?: string} = {};
  schoolDist: {};
  isUnSubmitting = false;
  showPaymentAgreement = false;
  totalNonAcceptedRecords = [];
  studentAttemptInfo = []
  allowPASIUpdates: boolean = ALLOW_PASI_UPDATES;
  globalStudentByUid;

  constructor(
    private lang: LangService,
    private whiteLabel: WhitelabelService,
    private auth: AuthService,
    private routes: RoutesService,
    private assessmentsService: AssessmentsService
  ) {
    // const res = require("./data/sample-response.json");
    // this.init(BC_SAMPLE_SCHOOL_FSA)
  }

  initCheckmarkMappings() {
    this.studentMappings.forEach((studentMapping: IDataMappingG9Student) => {
      this.checkMarkMapping[studentMapping.target] = (this.lang.c() === 'fr' && studentMapping.ignoreChekMarkInFrench) ? undefined:studentMapping.checkMarkMap;

      const isG10 = studentMapping.key_namespace === 'eqao_sdc_g10';
      let prefix = isG10 ? '_g10_' : '';
      this.g10PropCheckMarkMapping[`${prefix}${studentMapping.target}`] = studentMapping.checkMarkMap;
    })
  }

  async init(res: any) {

    if (this.whiteLabel.isABED()) {
      await this.assessmentsService.initData(true);
      this.assessmentsService.getEnabledAssessmentDefs().forEach(assDef => {
        // avoids more hardcoding in this service
        let relevantCount = this.abedSessionsCounts.
        find(countInfo => countInfo.classFilter === assDef.assessment_slug)
        if (relevantCount == null) {
          const newItemIdx = this.abedSessionsCounts.push({
            classFilter: assDef.assessment_slug
          });

          for (const status of assessmentSessionStatuses) {
            this.abedSessionsCounts[newItemIdx - 1][status + sessionsCountLabel] = 0;
          }
        }

        else {
          for (const status of assessmentSessionStatuses) {
            relevantCount[status + sessionsCountLabel] = 0;
            }
        }
      });
    }

    this.schoolData = res.school[0];
    this.showPaymentAgreement = res.showPaymentAgreement;
    this.totalNonAcceptedRecords = res.totalNonAcceptedRecords;
    this.studentAttemptInfo = res.student_attempt_info;
    const schoolLang = this.lang.c()
    // this.schoolData["lang"];
    // this.lang.setCurrentLanguage(schoolLang);
    this.initCheckmarkMappings();
    const studentData = res.students || [];
    // console.log(res.students);
    const teacherData = res.teachers || [];
    const semesterData = res.school_semesters || [];
    const sessionData = res.sessions || [];
    const classesData: any[] = res.classes || [];
    const guestClassesData:any[] = res.guest_classes || [];
    const guestClassesInvigilateData:any[] = res.guest_classes_invigilators || [];
    const testAttempts = res.test_attempts || [];
    const studentSubmissions = res.student_submissions || [];
    const studentTestAttempts: any[] = res.student_testAttempts || []
    const SDC_conflicts_g3 = res.SDC_conflicts_g3 ||[]
    const SDC_conflicts_g6 = res.SDC_conflicts_g6 ||[]
    const SDC_conflicts_g9 = res.SDC_conflicts_g9 ||[]
    const SDC_conflicts_g10 = res.SDC_conflicts_g10 ||[]
    //const students_created_loaded_time = res.students_created_loaded_time || []
    const mapBy = (arr, prop) => {
      const m = new Map();
      arr.forEach(entry => {
        m.set(entry[prop], entry)
      })
      return m;
    }

    const metaKeys = [];


    // access_code: "ANTW"
    // caption: null
    // date_time_start: "2020-11-05T01:45:18.000Z"
    // school_class_id: 9
    // slug: "G9_SAMPLE"
    // test_session_id: 584


    //creates map from student id value to student data
    const studentByUid = mapBy(studentData, 'id');
    this.globalStudentByUid = studentByUid;
    const teachersByUid = mapBy(teacherData, 'id');
    const classesByGroupId = mapBy(classesData, 'group_id');
    const classesByClassId = mapBy(classesData, 'id');
    const teacherByClassGroupId = new Map();

    res.classes_sessions = res.classes_sessions || [];
    res.classes_closed_sessions = res.classes_closed_sessions || [];
    res.classes_teachers = res.classes_teachers || [];
    res.classes_invigilators = res.classes_invigilators || [];
    res.student_meta = res.student_meta || [];
    res.tw_student_meta = res.tw_student_meta || [];
    res.student_accommodations = res.student_accommodations || [];
    res.classes_students = res.classes_students || [];

    classesData.forEach(classroom => {
      classroom.when = (classroom.notes || '').split('When:').join('')
      classroom.students = []
      classroom.openAssessments = []
      classroom.recentAssessments = []
      classroom.scheduledAssessments = []
    })

    res.classes_sessions.forEach(session => {
      const classroom = classesByClassId.get(session.school_class_id);
      let currDate = new Date();
      let sessionDate = new Date(session.date_time_start)
      if (currDate.getTime() < sessionDate.getTime()) {
        classroom.scheduledAssessments.push(session)
      }
      else{
        classroom.openAssessments.push(session);
      }
    });

    res.classes_closed_sessions.forEach(session => {
      const classroom = classesByClassId.get(session.school_class_id);
      const closeTime = new Date(session.closed_on);
      const openTime = new Date(session.date_time_start);
      classroom.recentAssessments.push(session);
    })

    res.student_meta.forEach(meta => {
        const student = studentByUid.get(meta.uid);
        if(!student) {
          console.warn('no student found for uid: ' + meta.uid)
          return;
        }
        student[meta.key_namespace] = student[meta.key_namespace] || {}
        student[meta.key_namespace][meta.key] = meta.value;
        //student[meta.key] = meta.value;
        //metaKeys.push(meta.key)
    })

    res.tw_student_meta.forEach(tw_meta => {
      const student = studentByUid.get(tw_meta.uid);
      if(!student) {
        console.warn('no student found for uid: ' + tw_meta.uid);
        return;
      }
      student[tw_meta.key_namespace] = student[tw_meta.key_namespace] || {};
      if(tw_meta.meta != null) {
        student[tw_meta.key_namespace][tw_meta.key] = tw_meta.meta;
      }else{
        student[tw_meta.key_namespace][tw_meta.key] = tw_meta.value;
      }
    })

    res.student_accommodations.forEach(accomm => {
      const student = studentByUid.get(accomm.uid);
      if(!student) return;
      if(student.accommodations) student.accommodations.push(accomm.name);
      else student.accommodations = [accomm.name];
    })

    res.classes_teachers.forEach(entry => {
      const teacher = teachersByUid.get(entry.uid)
      const classroom = classesByGroupId.get(entry.group_id);
      if(classroom){
        classroom.teacher_uid = entry.uid;
      }
      if (teacher) {
        teacher.classes = teacher.classes || [];
        teacher.semesters = teacher.semesters || [];
        if(classroom){
          classroom.teacher = teacher.first_name + ' ' + teacher.last_name;
          teacher.classes.push(classroom.name)
          teacher.semesters.push(classroom.semester_id)
        }
        teacherByClassGroupId.set(entry.group_id, teacher)
      }
    })

    // Array to keep track of teachers and their students
    const teachersWithStudents: {
      teacher: any,
      students: any[]
    }[] = [];

    // Helper function to find one element in the array
    const firstOrDefault = (inputArray: any[], condition: any): any => {
      if (inputArray == null || inputArray.length == 0) {
        return null;
      }
      for(let i = 0; i < inputArray.length; i++) {
        const el = inputArray[i];
        if (condition(el) === true) {
          return el;
        }
      }
      return null;
    };

    res.classes_students.forEach(entry => {
      const student = studentByUid.get(entry.uid)
      const teacher = teacherByClassGroupId.get(entry.group_id)
      const classroom = classesByGroupId.get(entry.group_id);
      if (student) {
        //student.class_code = classroom.name;
        student.groupings = student.groupings||[];
        student.class_code = student.class_code||[];
        student.classroomIds = student.classroomIds || []
        student.is_guest = entry.is_guest
        student.scg_id = entry.scg_id
        //moved classroom related logic within this IF so that having a walk-in student doesn't end up throwing errors
        if(classroom){
          student.groupings.push({id: classroom.id, classCode: classroom.name, test_window_id: classroom.test_window_id})
          student.class_code.push(classroom.name)
          student.teacher = classroom.teacher;
          student.semester_id = classroom.semester_id
          classroom.courses = classroom.courses || []
          student.classroomIds.push(entry.group_id);
          const relevantCourse = STUDENT_G9_COURSES.map[student.Program];
          if (relevantCourse) {
            classroom.courses.push(this.lang.tra(relevantCourse.course));
          }
          classroom.course_id = student.Program;
          classroom.num_students = classroom.num_students || 0;
          classroom.num_students++;
        }
        if (teacher) {
          // Track the teachers with their students
          const teacherWithStudents = firstOrDefault(teachersWithStudents, (ts: any) => ts.teacher && ts.teacher.id === teacher.id);
          // New teacher, let's add it to the list
          if (!teacherWithStudents) {
            teachersWithStudents.push({
              teacher: teacher,
              students: [student]
            })
          } else {
            // Check for students duplication to avoid extera student count BUG#
            const teachersStudent = firstOrDefault(teacherWithStudents.students, (st: any) => st.id === student.id);
            if (!teachersStudent) {
              teacherWithStudents.students.push(student);
            }
          }
          // teacher.num_students = teacher.num_students || 0;
          // teacher.num_students++;
        }
      }
    });

    // Fix num_students on teacher object
    teachersWithStudents.forEach((ts: any) => {
      if (ts.teacher) {
        ts.teacher.num_students = ts.students ? ts.students.length : 0
      }
    });


    classesData.forEach(classroom => {
      classroom.courses = _.uniq(classroom.courses).join(', ')
    })

    teacherData.forEach(teacher => {
      teacher.classes = teacher.classes || []
      teacher.classes = teacher.classes.join(', ')
      //teacher.semester = 'test'
    })

    const studentsList = [];

    const submissionMap = {}

    // todo: split up is_submitted by assessment
    const operationalSubmRef = [
      // {filter: ClassFilterId.G9, slug: 'G9_OPERATIONAL'},
      {filter: ClassFilterId.OSSLT, slug: 'OSSLT_OPERATIONAL'},
    ]
    const operationalSubmMap = new Map()
    operationalSubmRef.forEach(record => {
      submissionMap[record.filter] = {};
      operationalSubmMap.set(record.slug, record.filter)
    });
    studentSubmissions.forEach(stuSubmRecord =>{
      if(stuSubmRecord){ // .is_submitted == 1
        const submFilter = operationalSubmMap.get(stuSubmRecord.slug);
        if (submFilter){
          submissionMap[submFilter][stuSubmRecord.id] = stuSubmRecord
        }
      }
    });

    const getSubmissionStatusStr = (state:boolean) => {
      if (state){
        return '1'; // this.lang.tra('lbl_status_submitted');
      }
      else {
        return '#'; // this.lang.tra('lbl_no')
      }
    }

    // console.log(studentByUid.values());
    for (let student of studentByUid.values()) {
      const studentNormalized = this.apiToClientPayloadStudent(student);
      operationalSubmRef.forEach(record => {
        let submState = submissionMap[record.filter][student.id]
        // todo: split up is_submitted by assessment
        studentNormalized.is_submitted_state = submState;
        studentNormalized.is_submitted = getSubmissionStatusStr(!!submState);
        if (submState){
          // todo: other data models to be updated when g9 included here
          // console.log('studentNormalized submission')
          studentNormalized.osslt__is_submitted = '1'; // "Yes";
          if (submState.overall == 1){
            studentNormalized.osslt__is_success = '1'; // 'Yes'
          }
          else if (submState.is_data_insufficient == 1){
            studentNormalized.osslt__is_success = '2'; // 'Insuf.'
          }
          else {
            studentNormalized.osslt__is_success = '0'; // 'Not Yet'
          }
        }
        else{
          studentNormalized.osslt__is_success = '#';
          studentNormalized.osslt__is_submitted = '#'; // no
        }
      });
      studentNormalized.testAttempts = studentTestAttempts.filter(attempt => attempt.uid == studentNormalized.id)
      studentNormalized.havePrimarySubmitted = '#';
      const primarySlug = 'PRIMARY_OPERATIONAL';
      const havePrimarySubmitted = studentNormalized.testAttempts.find(ta => ta.slug === primarySlug && +ta.is_submitted === 1)
      if(havePrimarySubmitted!=undefined){
        studentNormalized.havePrimarySubmitted = '1';
      }
      studentNormalized.haveJuniorSubmitted = '#';
      const juniorSlug = 'JUNIOR_OPERATIONAL';
      const haveJuniorSubmitted = studentNormalized.testAttempts.find(ta => ta.slug === juniorSlug && +ta.is_submitted === 1)
      if(haveJuniorSubmitted!=undefined){
        studentNormalized.haveJuniorSubmitted = '1';
      }

      studentNormalized.haveOSSLTSubmitted = '#';
      const ossltSlug = 'OSSLT_OPERATIONAL';
      const haveOSSLTSubmitted = studentNormalized.testAttempts.find(ta => ta.slug === ossltSlug && +ta.is_submitted === 1)
      if(haveOSSLTSubmitted!=undefined){
        studentNormalized.haveOSSLTSubmitted = '1';
      }
      studentNormalized.haveG9Submitted = '#';
      const g9Slug = 'G9_OPERATIONAL';
      const haveG9Submitted = studentNormalized.testAttempts.find(ta => ta.slug === g9Slug && +ta.is_submitted === 1)
      if(haveG9Submitted!=undefined){
        studentNormalized.haveG9Submitted = '1';
      }
      studentNormalized.is_guest =  student.is_guest
      studentNormalized.scg_id = student.scg_id

      // console.log("Student: ", student)
      if(student.nbed_sdc && student.nbed_sdc.NBED_UserId && student.nbed_sdc.Student_Identification_Number){
        studentNormalized.nbed_student_identification_number = student.nbed_sdc.Student_Identification_Number;
        studentNormalized.nbed_user_id = student.nbed_sdc.NBED_UserId;
      }
      if(student.abed_sdc && student.abed_sdc.ABED_UserId && student.abed_sdc.Student_Identification_Number){
        studentNormalized.abed_student_identification_number = student.abed_sdc.Student_Identification_Number;
        studentNormalized.abed_user_id = student.abed_sdc.ABED_UserId;
      }
      if(student.mbed_sdc && student.mbed_sdc.MBED_UserId && student.mbed_sdc.Student_Identification_Number){
        studentNormalized.mbed_student_identification_number = student.mbed_sdc.Student_Identification_Number;
        studentNormalized.mbed_user_id = student.mbed_sdc.MBED_UserId;
      }

      studentNormalized.diplomaExamInfo = student.diplomaExamInfo || [];

      studentsList.push(studentNormalized);

      if (student.classroomIds) {
        student.classroomIds.forEach(classGroupId => {
          const classroom = classesByGroupId.get(classGroupId);
          classroom.students.push(studentNormalized)
        })
      }
    }

    this.schoolAdminStudents = initMappedList(studentsList);
    const sampleStudents = this.schoolAdminStudents.list;
    const nameToId = new Map();
    const classOptionsList = classesData.map((classroom, index) => {
      const id = classroom.id;
      nameToId.set(classroom.name, id);
      return { id, label: classroom.name, group_type: classroom.group_type};
    });
    for (let student of sampleStudents) {
      //student.eqao_g9_class_code_label = student.eqao_g9_class_code;
      //student.eqao_g9_class_code = nameToId.get(student.eqao_g9_class_code);

      studentByUid.get(student.id).classroomIds.forEach(classID => {
        const studentClass = classesData.find(theClass => theClass.group_id == classID)
        if(studentClass.group_type == 'EQAO_G3'){
          student.class_group_id = studentClass.group_id
          student._g3_eqao_g9_class_code = studentClass.id;
          student._g3_eqao_g9_class_code_label = studentClass.name
        }
        if(studentClass.group_type == 'EQAO_G6'){
          student.class_group_id = studentClass.group_id
          student._g6_eqao_g9_class_code = studentClass.id;
          student._g6_eqao_g9_class_code_label = studentClass.name
        }
        if(studentClass.group_type == 'EQAO_G9'){
          student.class_group_id = studentClass.group_id
          student.eqao_g9_class_code = studentClass.id;
          student.eqao_g9_class_code_label = studentClass.name
        }
        if(studentClass.group_type == 'EQAO_G10'){
          student.class_group_id = studentClass.group_id
          student._g10_eqao_g9_class_code = studentClass.id;
          student._g10_eqao_g9_class_code_label = studentClass.name
        }
        if(studentClass.group_type == 'NBED_TCLE'){
          student._tcle_eqao_g9_class_code = studentClass.id;
          student._tcle_eqao_g9_class_code_label = studentClass.name
        }
        if(studentClass.group_type == 'NBED_TCN'){
          student._tcn_eqao_g9_class_code = studentClass.id;
          student._tcn_eqao_g9_class_code_label = studentClass.name
        }
        if(studentClass.group_type == 'NBED_SCIENCES8'){
          student._sciences8_eqao_g9_class_code = studentClass.id;
          student._sciences8_eqao_g9_class_code_label = studentClass.name
        }
        if(studentClass.group_type == 'ABED_SAMPLE'){
          student._abed_sample_eqao_g9_class_code = studentClass.id;
          student._abed_sample_eqao_g9_class_code_label = studentClass.name
        }
        if(studentClass.group_type == 'ABED_GRADE_6'){
          student._abed_grade_6_eqao_g9_class_code = studentClass.id;
          student._abed_grade_6_eqao_g9_class_code_label = studentClass.name
        }
        if(studentClass.group_type == 'ABED_GRADE_9'){
          student._abed_grade_9_eqao_g9_class_code = studentClass.id;
          student._abed_grade_9_eqao_g9_class_code_label = studentClass.name
        }
        if(studentClass.group_type == 'ABED_GRADE_12'){
          student._abed_grade_12_eqao_g9_class_code = studentClass.id;
          student._abed_grade_12_eqao_g9_class_code_label = studentClass.name
        }
      });
    }
    this.classOptions = initMappedList(classOptionsList);
    const teachersList = [];
    let time = "";
    let time2 = "";
    for (let teacher of teacherData) {

      if (this.whiteLabel.isABED())
      {
        time = this.auth.formatDateForWhitelabel(teacher.created_on, undefined, this.lang.getCurrentLanguage());
        time2 = this.auth.formatDateForWhitelabel(teacher.expire_on, undefined, this.lang.getCurrentLanguage());
      }

      else
      {
        if (teacher.created_on) {
          time = moment.tz(teacher.created_on, moment.tz.guess()).format(this.lang.tra('datefmt_dashboard_short'));
        }
        if (teacher.expire_on) {
          time2 = moment.tz(teacher.expire_on, moment.tz.guess()).format(this.lang.tra('datefmt_dashboard_short'));
        }
      }


      const name = teacher.first_name + " " + teacher.last_name;
      teachersList.push({
        id: teacher.id,
        label: name,
        invigilator: name,
        // firstName: name.trim().split(" ")[0],
        // lastName: name.trim().split(" ")[1],
        firstName: teacher.first_name,
        lastName: teacher.last_name,
        email: teacher.contact_email,
        invit_id: teacher.invit_id || null,
        secret_key: teacher.secret_key || null,
        invit_email: teacher.invit_email || null,
        isConfirmed: !!teacher.contact_email,
        classCode: teacher.classes,
        semester:teacher.semesters,
        semester_labels: (teacher.semesters && teacher.semesters.lengh>0) ? teacher.semesters.map(semester => semesterData.find(semester2 => semester2.id == semester).name):[],
        students: teacher.num_students || 0,
        startTime: time, //This and below are still sample data
        endTime: time2,
        expireOn: time2
      });
    }

    this.teachers = initMappedList(teachersList);
    this.invigilators = res.classes_invigilators

    // console.log('classesData', classesData)
    this.semesters = initMappedList(semesterData.map(semester => {
      return {
        id: semester.id,
        foreign_scope_id: semester.foreign_scope_id,
        foreign_id: semester.foreign_id,
        label: semester.name,
        testWindowId: semester.test_window_id
      }
    }))
    this.attempts = initMappedList(testAttempts.map(attempt => {
      return {
        twtdar_order: attempt.twtdar_order,
        submissions: attempt.submissions,
        submitted_test_session_id: attempt.submitted_test_session_id,
      }
    }))

    if(!Array.isArray(sessionData)){
      this.activeSessionsCount = 0;
      this.scheduledSessionsCount = 0;
      this.submittedSessionsCount = 0;
      this.primaryActiveSessionsCount = 0;
      this.primaryScheduledSessionsCount = 0;
      this.juniorActiveSessionsCount = 0;
      this.juniorScheduledSessionsCount = 0;
      this.g9ActiveSessionsCount = 0;
      this.g9ScheduledSessionsCount = 0;
      this.ossltActiveSessionsCount = 0;
      this.ossltScheduledSessionsCount = 0;
      this.assessments = initMappedList(sessionData.school_sessions.map(session => {
        const sessionStates = sessionData.session_states.find(ss => ss.test_session_id === session.test_session_id)
        const subSessionRecord_A = sessionStates.states.subSessionRecords.find(ssr => ssr.caption === "A");
        let subSessionRecord_B = sessionStates.states.subSessionRecords.find(ssr => ssr.caption === "B");
        if(session.slug == ASSESSMENT.PRIMARY_SAMPLE || session.slug == ASSESSMENT.PRIMARY_OPERATIONAL || session.slug == ASSESSMENT.JUNIOR_SAMPLE || session.slug == ASSESSMENT.JUNIOR_OPERATIONAL){
          subSessionRecord_B = sessionStates.states.subSessionRecords.find(ssr => ssr.caption === "1");
        }
        const isSampleTest = session.slug == ASSESSMENT.PRIMARY_SAMPLE || session.slug == ASSESSMENT.JUNIOR_SAMPLE || session.slug == ASSESSMENT.G9_SAMPLE || session.slug == ASSESSMENT.OSSLT_SAMPLE
        return {
          id: session.test_session_id,
          school_name:null,
          isPaid: '0/1',
          paymentStatus: isSampleTest ? 'lbl_not_required' : 'lbl_required',
          studentsPaid: session.studentsPaid || 0,
          invigilator: `${session.first_name} ${session.last_name}`,
          classroom_id: session.school_class_id,
          firstName: session.first_name,
          lastName: session.last_name,
          description: '',
          slug:session.slug,
          isFieldTest: session.is_field_test,
          classCode: session.name,
          students: session.num_students,
          startTime: this.auth.formatDateForWhitelabel(session.date_time_start, undefined, this.lang.getCurrentLanguage()),
          startTimeUTC: session.date_time_start,
          endTime: session.date_time_end != null ? this.auth.formatDateForWhitelabel(session.date_time_end) : '',
          closed_on: this.auth.formatDateForWhitelabel(session.closed_on),
          closed_on_raw: moment(session.closed_on).utc().valueOf(), // value of yields epoch time
          isclosed: (session.is_closed == 1) ? true: false,
          status: this.returnSessionStatus(session) ? 'active' : 'pending',
          submissions: this.getSubmissionStatus(this.attempts.list, session), // twtdar_order = 0
          submissions_1: this.getSubmissionStatus(this.attempts.list, session, 1), // twtdar_order = 1
          //submissions: 0,
          isConfirmed: coinFlip(),
          session_a: subSessionRecord_A ? subSessionRecord_A : null,
          session_b: subSessionRecord_B ? subSessionRecord_B : null,
          tw_slug: session.tw_slug
        }
      }))
    }
    this.teacherClassrooms = initMappedList(classesData.map(classroom => {
      classroom.scheduledAssessments.forEach(session => this.processSessionEntry(session));
      classroom.openAssessments.forEach(session => this.processSessionEntry(session));
      classroom.recentAssessments.forEach(session => this.processSessionEntry(session));
      const is_invigilating = res.classes_teachers.find( record => record.group_id == classroom.group_id && record.role_type == 'schl_teacher_invig' ) != null
      return {
        id: classroom.id,
        semester_id: classroom.semester_id,
        test_window_id: classroom.test_window_id,
        foreign_id: classroom.foreign_id,
        course_name_short: classroom.course_name_short,
        course_name_full: classroom.course_name_full,
        name: classroom.name,
        classCode: hyphenateAccessCode(classroom.access_code),
        owner: '',
        isAssigned: !(classroom.is_grouping == 1),
        group_id: classroom.group_id,
        curricShort: classroom.group_type || 'EQAO_G9',
        // curricShort: `${this.lang.tra('txt_g9_math')} (${classroom.courses})`,
        enableClassListingByCC: true,
        currentStudents: initMappedList(classroom.students.map(student => {
          return {
            ...student,
            uid: student.id,
            displayName: [student.first_name, student.middle_name, student.last_name].join(' ')
          }
        })),
        // currentTeachers: [],
        currentTeachers: classroom.teacher ? classroom.teacher : [],
        openAssessments: classroom.openAssessments,
        recentAssessments: classroom.recentAssessments,
        scheduledAssessments: classroom.scheduledAssessments,
        timeCreated: '--',
        timeLastTouched: '--',
        is_invigilating,
        is_fi: classroom.is_fi,
        allow_ISR: classroom.allow_ISR || 0
      }
    }));

    // this.schoolData = res.school[0];
    this.schoolDistrict = {
      foreign_id: this.schoolData.sd_foreign_id
      //foreign_id:res.schl_dist[0].foreign_id
    };
    this.schoolDist = res.schl_dist;
    this.classrooms = [];
    for (let classroom of classesData) {
      this.classrooms.push(this.apiToClientPayloadClass(classroom));
    }

    this.guestClasses = [];
    for (let classroom of guestClassesData) {
      //this.guestClasses.push(this.apiToClientPayloadClass(classroom));
      classroom.access_code = hyphenateAccessCode(classroom.access_code)
      this.guestClasses.push(classroom);
    }

    this.guestClassesInvigilate = guestClassesInvigilateData;

    this.schoolAdminStudents.list.forEach(student => {
      //attatch conflict info
      const SDC_conflict_g3 =  SDC_conflicts_g3.find(conflict => conflict.uid == student.id)
      if(SDC_conflict_g3 != undefined && SDC_conflict_g3.compare_result!='[]'){
        student.SDC_conflict_g3 = SDC_conflict_g3
      }
      const SDC_conflict_g6 =  SDC_conflicts_g6.find(conflict => conflict.uid == student.id)
      if(SDC_conflict_g6 != undefined && SDC_conflict_g6.compare_result!='[]'){
        student.SDC_conflict_g9 = SDC_conflict_g6
      }
      const SDC_conflict_g9 =  SDC_conflicts_g9.find(conflict => conflict.uid == student.id)
      if(SDC_conflict_g9 != undefined && SDC_conflict_g9.compare_result!='[]'){
        student.SDC_conflict_g9 = SDC_conflict_g9
      }
      const SDC_conflict_g10 =  SDC_conflicts_g10.find(conflict => conflict.uid == student.id)
      if(SDC_conflict_g10 != undefined && SDC_conflict_g10.compare_result!='[]'){
        student.SDC_conflict_g10 = SDC_conflict_g10
      }
      this.processNewStudentAccount(student);



      //attatch loaded_created_time
      // const student_created_loaded_time = students_created_loaded_time.find(record => record.uid == student.id)
      // if(student_created_loaded_time != undefined){
      //   const created_time = student_created_loaded_time.school_role_created_on
      //   const loaded_time = student_created_loaded_time.user_metas_import_created_date
      //   student.created_loaded_time = created_time
      //   if(loaded_time > created_time){
      //     student.created_loaded_time = loaded_time
      //   }
      // }
    });

    this.testWindows = res.test_windows;
    if(res.sessions && res.sessions.school_sessions){
      this.schoolSessions = res.sessions.school_sessions
    }
  }

  processNewStudentAccount(student: IStudentAccount) {
    student['_g10_NonParticipationStatus_deferred'] = "#"
    student['_g10_NonParticipationStatus_exempted'] = "#"
    student['_g10_NonParticipationStatus_osslc'] = "#"
    if(student['_g10_NonParticipationStatus'] == '1'){
      student['_g10_NonParticipationStatus_exempted'] = '1';
    }
    if(student['_g10_NonParticipationStatus'] == '2'){
      student['_g10_NonParticipationStatus_deferred'] = '1';
    }
    if(student['_g10_NonParticipationStatus'] == '3'){
      student['_g10_NonParticipationStatus_osslc'] ='1';
    }

    const prefixs = ['_g3_','_g6_','','_g10_']
    const afixs = ['_pj_reading', '_pj_writing', '_pj_mathematics']
    prefixs.forEach(prefix => {
      afixs.forEach(afixs =>{
        student[prefix+'eqao_acc_assistive_tech_1'+afixs+'_chrome'] = '#';
        student[prefix+'eqao_acc_assistive_tech_1'+afixs+'_other'] = '#';
        if(student[prefix+'eqao_acc_assistive_tech'+afixs] == '2'){
          student[prefix+'eqao_acc_assistive_tech_1'+afixs+'_chrome'] = '1';
          student[prefix+'eqao_acc_assistive_tech_1'+afixs+'_other'] = '#';
        }
        if(student[prefix+'eqao_acc_assistive_tech'+afixs] == '1'){
          student[prefix+'eqao_acc_assistive_tech_1'+afixs+'_chrome'] = '#';
          student[prefix+'eqao_acc_assistive_tech_1'+afixs+'_other'] = '1';
        }
      })
    })
    student['_g10_eqao_acc_assistive_tech_1_chrome'] = '#';
    student['_g10_eqao_acc_assistive_tech_1_other'] = '#';
    if(student['_g10_eqao_acc_assistive_tech'] == '2'){
      student['_g10_eqao_acc_assistive_tech_1_chrome'] = '1';
      student['_g10_eqao_acc_assistive_tech_1_other'] = '#';
    }
    if(student['_g10_eqao_acc_assistive_tech'] == '1'){
      student['_g10_eqao_acc_assistive_tech_1_chrome'] = '#';
      student['_g10_eqao_acc_assistive_tech_1_other'] = '1';
    }
    student['eqao_acc_assistive_tech_1_chrome'] = '#';
    student['eqao_acc_assistive_tech_1_other'] = '#';
    if(student['eqao_acc_assistive_tech'] == '2'){
      student['eqao_acc_assistive_tech_1_chrome'] = '1';
      student['eqao_acc_assistive_tech_1_other'] = '#';
    }
    if(student['eqao_acc_assistive_tech'] == '1'){
      student['eqao_acc_assistive_tech_1_chrome'] = '#';
      student['eqao_acc_assistive_tech_1_other'] = '1';
    }
    student.teacher = this.getTeachers(student);
    student.invigilators = this.getTeachers(student, true);
  }



  public processSessionEntry(session: any) {
    const mStart = mtz(session.date_time_start);
    session.timeDirectStart = mStart.format(this.lang.tra('datefmt_day_month'));
    session.dateTimeStartLong = mStart.format(this.lang.tra('datefmt_sentence'));
    session.hourDirectStart = mStart.format(this.lang.tra('timefmt_hour_time'));

    if(session.closed_on) {
      const mClosed = mtz(session.closed_on);
      session.timeDirectClose = mClosed.format(this.lang.tra('datefmt_day_month'));
    }

    session.name = session.asmt_design_caption || session.slug
    if (session.name_custom){
      let suffix = session.name
      if (suffix){
        suffix = ' | ' + suffix;
      }
      else { 
        suffix = ''
      }
      session.name = session.name_custom + suffix;
    }

    return session;
  }

  getAPITargetMapping(sourceMapping: string): string {
    let targetMapping = '';
    let mapping = this.studentMappings.find(mapping => mapping.source === sourceMapping);
    if (mapping) {
      targetMapping = mapping.target;
    }
    return targetMapping;
  }

  generateStudentInvigilationRecord(studentAccount) {
    // console.log(JSON.stringify(studentAccount))
    // studentAccount.status = {};
    return {
      studentAccount,
      session_a: { // this should be an array
        is_session_active: true,
      },
    }
  }
  getSubmissionStatus(testAttempts, session, twtdar_order = 0) {

    const attempt = testAttempts.find(attempt => attempt.submitted_test_session_id === session.test_session_id && attempt.twtdar_order === twtdar_order);
    if(!attempt){
      return '0';
    }
    return attempt.submissions;

    /* const states = session_states.find(state => { return state.test_session_id === session.test_session_id }).states.studentStates;
    for (const [key, value] of Object.entries(states)) {
      for (const [innerKey, innerValue] of Object.entries(value)) {
        if (innerKey === 'submitted_test_session_id' && innerValue === session.test_session_id) {
          submissions.push(key)
        }
      }
    } */
  }
  getDate(dateTime)
  {
    const mStart = moment.tz(dateTime, moment.tz.guess());
    let formatted = mStart.format(this.lang.tra('datefmt_sentence'));
    formatted = (formatted === 'Invalid date') ? this.lang.tra('sa_invalid_date') : formatted;
    return formatted;
  }




  returnSessionStatus(session: any) {
    let currDate = new Date();
    let sessionDate = new Date(session.date_time_start);
    let status = "";
    // console.log(session);
    // console.log(session.slug);

    if (session.is_closed == 1) {
      // if session is submitted
      this.submittedSessionsCount++;
      status = assessmentSessionStatuses[2];

      // only used for ABED right now!!!
      this.dynamicallyUpdateSessionCount(session, status);
      return true;
    }

    else if (currDate.getTime() > sessionDate.getTime()) {
      // if active session (session is scheduled in the past)
      this.activeSessionsCount++;
      status = assessmentSessionStatuses[0];
      if (session.slug.toUpperCase().indexOf("PRIMARY") > -1)    this.primaryActiveSessionsCount++;
      if (session.slug.toUpperCase().indexOf("JUNIOR") > -1)    this.juniorActiveSessionsCount++;
      if (session.slug.toUpperCase().indexOf("G9") > -1)    this.g9ActiveSessionsCount++;
      if (session.slug.toUpperCase().indexOf("OSSLT") > -1) this.ossltActiveSessionsCount++;

      // only used for ABED right now!!!
      this.dynamicallyUpdateSessionCount(session, status);
      return true;
    }

    else {
      // else, if scheduled/pending session (session is scheduled in the present or future)
      this.scheduledSessionsCount++;
      status = assessmentSessionStatuses[1];
      if (session.slug.toUpperCase().indexOf("PRIMARY") > -1)    this.primaryScheduledSessionsCount++;
      if (session.slug.toUpperCase().indexOf("JUNIOR") > -1)    this.juniorScheduledSessionsCount++;
      if (session.slug.toUpperCase().indexOf("G9") > -1)    this.g9ScheduledSessionsCount++;
      if (session.slug.toUpperCase().indexOf("OSSLT") > -1) this.ossltScheduledSessionsCount++;

      // only used for ABED right now!!!
      this.dynamicallyUpdateSessionCount(session, status);
      return false;
    }
  }
  public timeConvert(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    if (rhours > 0) {
      return rhours + "hr " + rminutes + "min";
    }
    return rminutes + "min";
  }

  dynamicallyUpdateSessionCount(session: any, status: string, isDecrease: boolean = false): void {
    // dynamically updating session count instead of having a ton of variables
    // status should only one of the following: ["active", "scheduled", "submitted"], as in
    // the array, assessmentSessionStatuses
    if (!this.whiteLabel.isABED()) {
      return;
    }

    const sessCount = this.abedSessionsCounts.find
    (countInfo => countInfo.classFilter.toLocaleUpperCase() === session.tw_slug.toLocaleUpperCase());


    if (sessCount != null) {
      if (!isDecrease) {
        sessCount[status + sessionsCountLabel]++;
      }

      else {
        sessCount[status + sessionsCountLabel]--;
      }

    }
  }

  getStudentsByClassroomId(classroomId: string): IMappedList<IStudentAccount> {
    const classroom = this.teacherClassrooms.map[classroomId];
    if (classroom) {
      return classroom.currentStudents;
    }
  }

  getSessionByClassroomId(classroomId: string, sessionId: string) {
    const classroom = this.teacherClassrooms.map[classroomId]
    if (classroom && classroom.openAssessments) {
      for (let i = 0; i < classroom.openAssessments.length; i++) {
        const session = classroom.openAssessments[i];
        if (('' + session.test_session_id) === ('' + sessionId)) {
          return session;
        }
      }
      for (let i = 0; i < classroom.scheduledAssessments.length; i++) {
        const session = classroom.scheduledAssessments[i];
        if (('' + session.test_session_id) === ('' + sessionId)) {
          return session;
        }
      }
    }

    return {};
  }

  getAssessmentsByClassroomId(classroomId: string) {
    const sessions = this.schoolSessions
    const targetSession = sessions.filter( (session:any) => session.school_class_id == classroomId)
    return targetSession;
  }

  getOngoingSessionsByClassroomId(classroomId: string) {
    const classSessions = this.getAssessmentsByClassroomId(classroomId)
    const onGoingClassSessions = classSessions.filter( (session:any) => session.is_closed !== 1)
    return onGoingClassSessions
  }

  getClassroomNameById(classroomId: string) {
    const classroom = this.teacherClassrooms.map[classroomId]
    if (classroom) {
      return classroom.name;
    }
    return '';
  }
  getClassroomById(classroomId: string){
    const classroom = this.teacherClassrooms.map[classroomId]
    if (classroom) {
      return classroom;
    }
    return '';
  }

  apiToClientPayloadClass(fromAPI: any): IClassroom {
    let newPayload: any = {};

    this.classroomMappings.forEach((mapping: { source: string, target: string }) => {
      let val = _.get(fromAPI, mapping.source);
      newPayload[mapping.target] = val;
    });

    newPayload.course_type = newPayload.course_type || 'EQAO_G9';
    newPayload.is_grouping = +(newPayload.is_grouping || 0);

    const courseId = fromAPI.course_id;
    const course = STUDENT_G9_COURSES.map[courseId];
    if (course) {
      const isInPerson = course.inPerson;
      const isRemote = course.remote;
      const courseType = course.course;
      const simpleCourseId = STUDENT_G9_COURSES_SIMPLE.list.filter(item => item.course === courseType)[0].id;
      newPayload.course = simpleCourseId;
      newPayload.remote_courses_sdc_student_rem_inp_1 = isInPerson;
      newPayload.remote_courses_sdc_student_rem_inp_2 = isRemote;
    }
    newPayload.openAssessments = []; //Here and below is still unchanged from the sample data
    newPayload.recentAssessments = [];
    newPayload.onboarding = 0;
    newPayload.assessment = 0;
    newPayload.group_id = fromAPI.group_id;
    return newPayload;
  }

  //Use this when saving the data back to the API
  clientToApiPayloadClass(fromClient: IClassroom): any {
    let newPayload: any = {};

    this.classroomMappings.forEach((mapping: { source: string, target: string }) => {
      _.set(newPayload, mapping.source, fromClient[mapping.target]);
    });

    const simpleCourse = STUDENT_G9_COURSES_SIMPLE.map[fromClient.course];
    const courseType: G9_COURSES = simpleCourse.course;
    const complexCourse = STUDENT_G9_COURSES.list.filter((course) => {
      return course.course === courseType &&
        course.inPerson === fromClient.remote_courses_sdc_student_rem_inp_1 &&
        course.remote === fromClient.remote_courses_sdc_student_rem_inp_2;
    })[0];
    newPayload.course_id = complexCourse.id;

    return newPayload;
  }

  getNamespacePropPrefix(key_namespace: string) {
    switch(key_namespace) {
      case 'eqao_sdc_g3':
        return '_g3_';
      case 'eqao_sdc_g6':
        return '_g6_';
      case 'eqao_sdc_g10':
        return '_g10_';
      case 'eqao_sdc':
      default:
        return '';
    }
  }

  key_namespaces = [
    'eqao_sdc',
    'eqao_sdc_g3',
    'eqao_sdc_g6',
    'eqao_sdc_g10'
  ];

  apiToClientPayloadStudent(fromAPI: any): IStudentAccount {
    let newPayload: IStudentAccount = { id: fromAPI.id, first_name: fromAPI.first_name, middle_name: fromAPI.middle_name, last_name: fromAPI.last_name}; //id is only here because it is required for IStudentAccount (not sure why), but we only use uid
    this.studentMappings.forEach((mapping:Partial<IDataMappingG9Student> ) => { // { source: string, target: string, key_namespace:string}
      if(mapping.source == 'StudentOEN'){ //temp solution
        let val;
        if(mapping.key_namespace == 'eqao_sdc'){
          // this is a little messier for the sake of NBED, but going to do it to preserve release/eqao's use of the array as much as possible
          val =  _.get(fromAPI['nbed_sdc'], 'Student_Identification_Number')
        }
        if (!val){
          for(const key_namespace of this.key_namespaces) {
            let val = _.get(fromAPI[key_namespace], mapping.source);
            if(val) {
              break;
            }
          }
        }
        if(val) {
          newPayload[mapping.target] = val;
        }
        return;
      }
      let val = _.get(fromAPI[mapping.key_namespace], mapping.source);
      const prefix = this.getNamespacePropPrefix(mapping.key_namespace);
      newPayload[prefix+mapping.target] = val;
    });
    newPayload.groupings = fromAPI.groupings;
    newPayload.uid = fromAPI.id;
    newPayload.is_PASI_student = fromAPI.is_PASI_student;
    newPayload.AcceptedByTeacher = fromAPI.AcceptedByTeacher;
    newPayload.RejectedByTeacher = fromAPI.RejectedByTeacher;
    newPayload.is_guest = fromAPI.is_guest;
    newPayload.first_name = fromAPI.first_name;
    newPayload.middle_name = fromAPI.middle_name;
    newPayload.last_name = fromAPI.last_name;
    newPayload.isPaid = fromAPI.isPaid;
    newPayload.altPaymentStatus = fromAPI.altPaymentStatus;
    if (!newPayload['eqao_is_g3']&&!newPayload['eqao_is_g6']&&!newPayload['eqao_is_g9'] && !newPayload['eqao_is_g10']){
      newPayload['eqao_is_g3'] = '1'
    }
    newPayload['test_sessions'] = [];
    newPayload.status = { isOnline: false, assessments: {} };
    newPayload.course = {
      ... fromAPI['nbed_course'] || fromAPI['abed_course'] // todo, should not have to list these explicitly
    }
    newPayload.accommodations = fromAPI.accommodations;
    if(fromAPI.abed_pasi_sync){
      const OriginalNameRefId = fromAPI.abed_pasi_sync.OriginalNameRefId;
      if(fromAPI.abed_pasi_sync.StudentNames){
        try{
          const StudentNames = JSON.parse(fromAPI.abed_pasi_sync.StudentNames).Name;
          if(StudentNames && OriginalNameRefId){
            const originalName = StudentNames.find(n => +n.RefId === +OriginalNameRefId);
            if(originalName){
              if(originalName.MiddleName) newPayload.originalName = `${originalName.FirstName} ${originalName.MiddleName} ${originalName.LastName}`;
              else newPayload.originalName = `${originalName.FirstName} ${originalName.LastName}`;
            }
          }
        }catch(e){
          console.log(fromAPI);
        }
      }
      try{
        const parseRestriction = JSON.parse(fromAPI.abed_pasi_sync.DisclosureRestrictions)?.StudentDisclosureRestrictionInfo || [];
        const restrictionLookup = parseRestriction.find(r => r.IsActive === true || r.IsActive === false);
        const parseSecASNs = JSON.parse(fromAPI.abed_pasi_sync.SecondaryStateProvinceIds);
        const primaryStateProvinceId = fromAPI.abed_pasi_sync.PrimaryStateProvinceId;

        newPayload.DisclosureRestrictions = restrictionLookup?.IsActive;
        newPayload.DisclosureRestrictionsIsActive = restrictionLookup?.IsActive === true;
        newPayload.DisclosureRestrictionsIsNotActive = restrictionLookup?.IsActive === false;
  
        if(parseSecASNs){
          newPayload.SecondaryStateProvinceIds = parseSecASNs?.string;
        }

        if (primaryStateProvinceId != "" && primaryStateProvinceId != null) {
          newPayload.PrimaryStateProvinceId = primaryStateProvinceId;
        }

      }catch(e){
        console.log("error while parsing")
      }
      newPayload.ScheduledRecordDisposalDate = this.renderDate(fromAPI.abed_pasi_sync.ScheduledRecordDisposalDate);
      newPayload.IsDeceased = +fromAPI.abed_pasi_sync.IsDeceased;
      newPayload.Grade = fromAPI.abed_pasi_sync.StudentGrade;
      if(fromAPI.abed_pasi_sync.StudentSchoolEnrolmentInfo){
        newPayload.ExitDateType = fromAPI.abed_pasi_sync.StudentSchoolEnrolmentInfo.ExitDateType;
        newPayload.RegistrationExitDate = this.renderDate(fromAPI.abed_pasi_sync.StudentSchoolEnrolmentInfo.RegistrationExitDate);
        newPayload.RegistrationStartDate = this.renderDate(fromAPI.abed_pasi_sync.StudentSchoolEnrolmentInfo.RegistrationStartDate);
      }
    }
    return newPayload;
  }


  isNBED = () => this.whiteLabel.getSiteFlag('IS_NBED');

  clientToApiPayloadStudent(fromClient: Partial<IStudentAccount>): any {
    let newPayload: any = { id: 0, first_name: "", last_name: "", roles: [] };

    this.studentMappings.forEach((mapping: { source: string, target: string }) => {
      _.set(newPayload, mapping.source, fromClient[mapping.target]);
    });

    // if student is assigned an NBED assessment
    if(fromClient.nbed_group_type){
      delete newPayload['StudentOEN'];
      newPayload['key_namespace'] = 'nbed_sdc';
    }

    if (fromClient.abed_group_type){
      delete newPayload['StudentOEN'];
      newPayload['key_namespace'] = 'abed_sdc';
    }

    return newPayload;
  }

  clientToApiPayloadOSSLTStudent(fromClient: Partial<IStudentAccount>): any {
    let newPayload: any = { id: 0, first_name: "", last_name: "", roles: [] };

    this.studentMappings.forEach((mapping:Partial<IDataMappingG9Student>) => { //  { source: string, target: string, key_namespace:string}
      if(overlapVariables.indexOf(mapping.source) > -1){
        _.set(newPayload, mapping.source, fromClient[mapping.target]);
      }else{
        if(mapping.key_namespace == 'eqao_sdc_g10'){
          _.set(newPayload, mapping.source, fromClient["_g10_"+mapping.target]);
        }
      }
    });

    return newPayload;
  }

  getKeyNamespace(curricShort: string) {
    switch(curricShort) {
      case AssessmentCode.EQAO_G3:
        return 'eqao_sdc_g3'
      case AssessmentCode.EQAO_G6:
        return 'eqao_sdc_g6'
      case AssessmentCode.EQAO_G9:
        return 'eqao_sdc'
      case AssessmentCode.EQAO_G10:
        return 'eqao_sdc_g10'
      case AssessmentCode.NBED_TCLE:
        return 'nbed_sdc'
      case AssessmentCode.NBED_TCN:
        return 'nbed_sdc'
      case AssessmentCode.NBED_SCIENCES8:
        return 'nbed_sdc'
      case AssessmentCode.MBED_SAMPLE:
        return 'mbed_sdc'
    }
    return 'eqao_sdc';
  }

  getPropName(prop: string, curricShort: string) {
    if(overlapVariables.includes(prop)) {
      return prop;
    }

    return `${this.getNamespacePropPrefix(this.getKeyNamespace(curricShort))}${prop}`;
  }

  getPropVal(student:IStudentAccount, prop: string, curricShort: AssessmentCode) {
    return student[this.getPropName(prop, curricShort)];
  }

  classFilterToCurricShort(classFilter: ClassFilterId) {
    switch(classFilter) {
      case ClassFilterId.G9:
        return AssessmentCode.EQAO_G9;
      case ClassFilterId.OSSLT:
        return AssessmentCode.EQAO_G10;
      case ClassFilterId.Junior:
        return AssessmentCode.EQAO_G6;
      case ClassFilterId.Primary:
        return AssessmentCode.EQAO_G3;
    }
  }

  asmtSlugToCurricShort(asmtSlug: ASSESSMENT) {
    if(asmtSlug?.includes('PRIMARY')) {
      return AssessmentCode.EQAO_G3;
    } else if(asmtSlug?.includes('JUNIOR')) {
      return AssessmentCode.EQAO_G6;
    } else if(asmtSlug?.includes('OSSLT')) {
      return AssessmentCode.EQAO_G10;
    } else if(asmtSlug?.includes('G9')){
      return AssessmentCode.EQAO_G9
    }
  }

  // generateStudents(len=30){
  //   const mappedList = initMappedList(generateEntries(len, (i) => {
  //     const lang = this.lang.getCurrentLanguage();
  //     const random = genericIdentity(this.lang.tra('sample_student_last_name'), lang);
  //     const courses = STUDENT_G9_COURSES_SIMPLE.list
  //     const classroom = randArrEntry([
  //       {classCode:'MFM1P - AP - 3', course:courses[0].id},
  //       {classCode:'MPM1D - AC - 1', course:courses[1].id},
  //       {classCode:'MPM1D - AC - 2', course:courses[1].id},
  //     ])
  //     return {
  //       id: randInt(1000,9999),
  //       uid: randId(),
  //       email: random.email,
  //       group_id: 0, //randInt(1000,9999),
  //       first_name: random.firstName,
  //       last_name: random.lastName,
  //       displayName: random.name,
  //       eqao_student_gov_id: String(randInt(Math.pow(10, 8), Math.pow(10, 9) - 1)),
  //       test_sessions: [],
  //       lang: randArrEntry(['EN', 'FR']),
  //       eqao_g9_course: <string> classroom.course,
  //       classCode: coinFlip(0.9) ? classroom.classCode : undefined,
  //     }
  //   })) ;
  //  mappedList.list.sort((a, b) => {
  //     const _a = a;
  //     const _b = b;
  //     if (_a.last_name > _b.last_name){ return 1 }
  //     if (_a.last_name < _b.last_name){ return -1 }
  //     if (_a.first_name > _b.first_name){ return 1 }
  //     if (_a.first_name < _b.first_name){ return -1 }
  //     else{ return 0 }
  //   })
  //   return mappedList;
  // }

  getguestClasssByHostClassroomGroupId(classroomGroupId: string) {
    return this.guestClasses.filter( (gclass:any) => gclass.invig_sc_group_id == classroomGroupId)
  }

  getGuestClassInvigilateByHostClassroomGroupId(classroomGroupId: string) {
    return this.guestClassesInvigilate.filter( (gci:any) => gci.group_id == classroomGroupId);
  }

  addGuessClass(newGuessClass){
    this.guestClasses.splice(0,0,newGuessClass)
  }

  addGuessClassInvigilate(newGuessInvigilate){
    this.guestClassesInvigilate.splice(0,0,newGuessInvigilate)
  }

  removeGuessClass(guessClassId){
    const targetClass = this.guestClasses.find(gc => gc.scg_id == guessClassId )
    const targetClassIndex = this.guestClasses.indexOf(targetClass)
    if(targetClassIndex != -1){
      this.guestClasses.splice(targetClassIndex,1)
    }
    const targetInvigilate = this.guestClassesInvigilate.find(gci => gci.scg_id == guessClassId )
    const targetInvigilateIndex = this.guestClassesInvigilate.indexOf(targetInvigilate)
    if(targetInvigilateIndex != -1){
      this.guestClassesInvigilate.splice(targetInvigilateIndex,1)
    }
  }

  isCurrentSchoolSandbox(){
    if (this.schoolData){
      return +this.schoolData.is_sandbox === 1
    }
  }

  getAdditionalInvigilatorByGroupId(groupId: string){
    return this.invigilators.filter(invig =>  +invig.group_id == +groupId)
  }

  setIsFromSchoolAdmin(value){
    this.isFromSchoolAdmin = value
  }

  getIsFromSchoolAdmin(){
    return this.isFromSchoolAdmin
  }

  getIsUnsubmitting(){
    return this.isUnSubmitting
  }

  setIsUnsubmitting(value){
    this.isUnSubmitting = value
  }

  removeStudentFromClassroom(student, classroomId) {
    this.teacherClassrooms.map[classroomId].currentStudents.list = this.teacherClassrooms.map[classroomId].currentStudents.list.filter((s) => {
      console.log(`s.id: ${s.id} student.id: ${student.id}`)
      return s.id !== student.id
    });
  }

  getLinearAssignDate(uid:any, sessionID:any){
    const theStudentAttemptInfo = this.studentAttemptInfo.find(studentAttemptInfoRecord =>
        +studentAttemptInfoRecord.uid === +uid
     && +studentAttemptInfoRecord.test_session_id === +sessionID
     && studentAttemptInfoRecord.twtdar_user_metas_filter.includes('"eqao_dyn.Linear": ["1"]')) // use "eqao_dyn.Linear": ["1"] in twtdar.user_metas_filter to determine if the attempt has linear test form.
    if(theStudentAttemptInfo){
      const  ta_created_on_moment = moment.tz(theStudentAttemptInfo.ta_created_on, moment.tz.guess());
      const timezone = ta_created_on_moment.zoneAbbr();
      const returnValue = `${ta_created_on_moment.format('YYYY-MM-DD kk:mm')} ${timezone}`
      return returnValue
    }
    return ''
  }

  filterSessionTestWindow(session: ISession, currentTestWindowId: number): boolean {
    //const semester = this.g9DemoData.semesters.list.find(sm => sm.id === +theClass.semester);
    return this.filterClassroomTestWindow(session.classroom_id, currentTestWindowId);
  }

  filterClassroomTestWindow(classroomId: number | string, currentTestWindowId: number): boolean {
    const classroom = this.classrooms.find(cr => cr.id === +classroomId)
    if(!classroom) {
      return false;
    }

    const semester = this.semesters.map[+classroom.semester]
    if(semester){
      return currentTestWindowId == semester.testWindowId
    }
    return false;
  }

  async loadStudentAccommodations(group_type, uid?){
    console.log(`group_type: ${group_type}`)
    return await this.auth.apiFind(this.routes.STUDENT_ACCOMMODATIONS, {
      query: {
      isABED: 1,
      student_uid: uid,
      group_type,
      schl_group_id: this.schoolData.group_id
    }});
  }

  updateStudentAccommodations = async (uid, accommodationList) => {
    try{
      await this.auth.apiPatch(this.routes.STUDENT_ACCOMMODATIONS, uid,
        {accommodationList},
        {
          query: {
            schl_group_id: this.schoolData.group_id
          }
        }
        );
    }catch(e){console.log("error: ", e);}
  }

  getSchoolGroupId = () => {
    if(!this.schoolData) return;
    return this.schoolData.group_id
  }

  validationAccomm(accommodationList){
    let valid = true;
    for(let accomm of accommodationList){
      if(accomm.has_extra_notes && accomm.value && !accomm.extra_notes_value) valid = false;
    }
    return valid;
  }

  public getForceTeacherDataRefresh(): boolean
  {
    return this.forceTeacherDataRefresh;
  }

  public setForceTeacherDataRefresh(forceTeacherDataRefresh: boolean)
  {
    forceTeacherDataRefresh = forceTeacherDataRefresh == null ? false : forceTeacherDataRefresh;
    this.forceTeacherDataRefresh = forceTeacherDataRefresh;
  }

  public validDate(dateString) {
    let isValid = true;
    if(dateString.length != 8){
      isValid = false;
      return;
    }
    const year  = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day   = dateString.slice(6, 8);
    const date = new Date(`${year}-${month}-${day}`);
    isValid = !isNaN(date.getTime()) && date <= new Date();

    return isValid;
  }

  public validateStudentInfo(student){
    if(student.first_name.trim() == '') return false;
    if(student.last_name.trim()  == '') return false;
    return true;
  }
  confidentialityChecks: any[];
  public haveUserAgreedOnConfidentiality(isABED){
    if (this.whiteLabel.getSiteFlag('IS_SCH_CONFAGR')){
      // todo: re-enable once ABED hard-coding is removed
      let slug;
      if(isABED){
        slug = 'abed_conf_agr';
      }
      this.confidentialityChecks = SCHOOL_ADMIN_TECH_READ_CHECKLIST_MASTER['abed_conf_checks'].filter((check) => check.isHidden == false);
      return this.auth.apiFind(this.routes.CONFIDENTIALITY_AGREEMENT, {
        query: { 
          schl_group_id: this.schoolData.group_id,
          slug
        }
      })
    }
  }

  public acceptConfidentialityAgreement(isABED, confidentialityName, confidentialityDate){
    if (this.whiteLabel.getSiteFlag('IS_SCH_CONFAGR')){
      // todo: re-enable once ABED hard-coding is removed
      let slug;
      if(confidentialityName.trim() == ""){
        alert(this.lang.tra('abed_invalid_name_error'));
        return;
      }
      const meta = {Name: confidentialityName, Date: confidentialityDate};
      if(isABED){
        slug = 'abed_conf_agr';
      }
      return this.auth.apiPatch(this.routes.CONFIDENTIALITY_AGREEMENT, null, {
        meta: JSON.stringify(meta)
      }, {
        query: { 
          schl_group_id: this.schoolData.group_id,
          slug
        }
      })
    }
  }

  public getConfidentialityChecks(){
    return this.confidentialityChecks;
  }

  public disableConfidentiality = () => {
    return this.confidentialityChecks.findIndex(c => c.val == false) != -1;
  }

  buildSchlGroupIdQuery(){
    return {schl_group_id: this.schoolData.group_id};
  }

  hasTeacherDismissedIntro(){
    return this.teacherChecklist.findIndex((c) => c.slug === VIDEO_INTRO_SLUG && c.value === true) != -1;
  }

  //First we pull this user's checklist on the school level. And kept a local version of the checklist so that we only ping the endpoint
  //  if they haven't dismissed the intro video, then it will popup and when they dismiss it, it will update the db once. The user can still
  //  rewatch the video as much as they would like, but it would not effect the db.
  public async checkInvigIntroStatus(){
    if(!this.schoolData) return;
    const query = this.buildSchlGroupIdQuery();
    this.teacherChecklist = await this.auth.apiGet(this.routes.EDUCATOR_CHECKLIST, this.schoolData.group_id, {query});
    const hasTheTeacherDismissed = this.hasTeacherDismissedIntro();
    return {hasTheTeacherDismissed}
  }

  public async toggleInvigIntroStatus(){
    const hasTheTeacherDismissed = this.hasTeacherDismissedIntro();
    console.log(hasTheTeacherDismissed);
    if(!hasTheTeacherDismissed){
      const query = this.buildSchlGroupIdQuery();
      const data = {
        slug: VIDEO_INTRO_SLUG,
        value: true
      };
      let toDismissLocally = this.teacherChecklist.find((c) => c.slug === VIDEO_INTRO_SLUG);
      if(toDismissLocally) toDismissLocally.value = true;
      else this.teacherChecklist.push(data);
      return await this.auth.apiPatch(this.routes.EDUCATOR_CHECKLIST, this.schoolData.group_id, data, {query});
    }
  }

  getTeachers(acct, isInvigilators = false){
    const classFilter = acct.abed_group_type || acct.GroupType;
    let classID = acct[this.getClassCodePrefix(classFilter) + "eqao_g9_class_code"]
    var classroom;
    if(classID){
      classroom = this.classrooms.find(theClass => theClass.id == classID )
      if(isInvigilators) {
        let invigilators = '';
        const class_group_id = this.teacherClassrooms.map[classroom.id].group_id
        const classInvigilators = class_group_id?this.invigilators.filter(invig => invig.group_id == class_group_id):[]
        classInvigilators.forEach(invig => {
          const invigName = invig.first_name + " " + invig.last_name;
          invigilators = invigilators == '' ? invigName : invigilators + ', '+ invigName
        })
        return invigilators;
      }
      if(classroom.teacher_uid != undefined){
        const teacher = this.teachers.list.find(teacher => teacher.id == classroom.teacher_uid )
        if(teacher != undefined){
          return teacher.firstName+ ' '+teacher.lastName
        }
      }
    }
    return '';
  }

  getClassCodePrefix = (classFilterId: ClassFilterId) => {
    switch(classFilterId){
      case ClassFilterId.Primary:
        return '_g3_';
      case ClassFilterId.Junior:
        return '_g6_';
      case ClassFilterId.G9:
        return ClassFilterId.G9
      case ClassFilterId.TCLE:
        return '_tcle_'
      case ClassFilterId.TCN:
        return '_tcn_'
      case ClassFilterId.SCIENCES8:
        return '_sciences8_'
      case ClassFilterId.ABED_SAMPLE:
        return '_abed_sample_'
      case ClassFilterId.ABED_GRADE_6:
        return '_abed_grade_6_'
      case ClassFilterId.ABED_GRADE_9:
        return '_abed_grade_9_'
      case ClassFilterId.ABED_GRADE_12:
        return '_abed_grade_12_'
      case ClassFilterId.OSSLT:
      default:
        return '_g10_'
    }
  }


  async getPasiCert(){
    return await this.auth.apiFind(this.routes.PASI_STATUS, {
      query: {
        schl_group_id: this.schoolData.group_id,
        slug: "pasi_certificate",
      }
     })
  }

  async getPasiStatus(){
    return await this.auth.apiFind(this.routes.PASI_STATUS, {
      query: {
        schl_group_id: this.schoolData.group_id,
        slug: "pasi_status",
      }
     })
  }
  getPASILock(){
    return this.allowPASIUpdates;
  }

  getStudentByUID(uid){
    return this.globalStudentByUid.get(uid);
  }

  renderDate(date){
    // if(this.isFromSchoolAdmin){
    //   console.log("school admin")
      return this.auth.formatDateOnly(date, null, this.lang.c());
    // }else{
    //   return this.auth.formatDateForWhitelabel(date, null, this.lang.c());
    // }
  }
}
