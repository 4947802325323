import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export const CLICK_TO_DRAG_DEFAULT = true;
@Injectable({
  providedIn: 'root'
})
export class AccessibilityService {
  public clickToDragDrop:boolean = CLICK_TO_DRAG_DEFAULT;
  public log = new Subject();
  constructor() { }
}
