<div *ngIf="!isNestedView || !isDocumentSheet" class="tabs">
  <ul *ngIf="!isSubMenu">
    <li 
      *ngFor="let tab of menuTabs" 
      [class.is-hidden]="!isTabHidden(tab)"
      [class.is-active]="isActiveId(tab.id)"
      (click)="setActiveId(tab.id, true)"
      (keyup.enter)="setActiveId(tab.id)"
      tabindex="0"
    >
      <a>
        <span><tra [slug]="tab.caption"></tra></span>
        <span *ngIf="tab.isCountEnabled" style="margin-left:0.3em;">
          ({{tab.count()}})
        </span>
      </a>
    </li>
  </ul>

  <ul *ngIf="!!isSubMenu" class="sub-menu">
    <li
            *ngFor="let tab of menuTabs"
            [class.is-hidden]="!isTabHidden(tab)"
            [class.is-active]="isActiveId(tab.id)"
            (click)="setActiveId(tab.id)"
    >
      <a>
        <span>{{tab.caption}} </span>
        <span *ngIf="tab.isCountEnabled" style="margin-left:0.3em;">
          ({{tab.count()}})
        </span>
      </a>
    </li>
  </ul>
</div>


<div *ngIf="isNestedView && isDocumentSheet" class="nested-view">
  <div *ngIf="getActiveTab()" class="current-selection-display">
      <span><tra [slug]="getActiveTab().caption"></tra></span>
      <span *ngIf="getActiveTab().isCountEnabled" style="margin-left:0.3em;">
        ({{getActiveTab().count()}})
      </span>
  </div>
  <div class="dropdown">
    <div>
      <input type="text" placeholder="Search Sheet.." class="nested-view-searchbox" [(ngModel)]="searchWord"  (ngModelChange)="filterFunction()" (focus)="toggleSelectSheetDropdown(true)" (blur)="onNestedSelectionFocusOut()">
      <div class="dropdown-content" [class.show]="selectSheetDropdownOn">
        <a #tabElement *ngFor="let tab of menuTabs" (click)="setActiveId(tab.id, true)">
          <span><tra [slug]="tab.caption"></tra></span>
          <span *ngIf="tab.isCountEnabled" style="margin-left:0.3em;">
            ({{tab.count()}})
          </span>
        </a>
        <a *ngIf="noResult" [style.opacity]="0.4">
          <span><tra slug="No sheet found"></tra></span>
        </a>
      </div>
    </div>
    <div class="dropbtn-container">
      <button (click)="toggleSelectSheetDropdown()" class="dropbtn" (blur)="onNestedSelectionFocusOut()">
        <i [style.display]="selectSheetDropdownOn ? '' : 'none'" class="fas fa-chevron-up" name="dropdown-icon-up"></i>
        <i [style.display]="selectSheetDropdownOn ? 'none' : ''" class="fas fa-chevron-down" name="dropdown-icon-down"></i>
      </button>
    </div>
    
  </div>



  <ul *ngIf="!!isSubMenu" class="sub-menu">
    <li
            *ngFor="let tab of menuTabs"
            [class.is-hidden]="!isTabHidden(tab)"
            [class.is-active]="isActiveId(tab.id)"
            (click)="setActiveId(tab.id)"
    >
      <a>
        <span>{{tab.caption}} </span>
        <span *ngIf="tab.isCountEnabled" style="margin-left:0.3em;">
          ({{tab.count()}})
        </span>
      </a>
    </li>
  </ul>
</div>
