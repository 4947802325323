import { IPanelLink } from "./type";
import { AccountType } from "../../../constants/account-types";
import { SchoolAdminView, NBEDSchoolAdminView, ABEDSchoolAdminView } from "../../../ui-schooladmin/view-schooladmin-dashboard/data/views";

export const schoolAdminPanels:IPanelLink[] = [
      {
        caption: 'lbl_tech_readi', 
        routerLink: `/en/${AccountType.SCHOOL_ADMIN}/${SchoolAdminView.TECH_READI}`,
        iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/clipboard/1602235150476/clipboard.png',
    },
    {
        caption: 'lbl_classes', 
        routerLink: `/en/${AccountType.SCHOOL_ADMIN}/${SchoolAdminView.CLASSROOMS}`,
        iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/school/1602128112754/school.png',
    },
    {
        caption: 'lbl_teachers', // Teachers/Invigilators
        routerLink: `/en/${AccountType.SCHOOL_ADMIN}/${SchoolAdminView.TEACHERS}`,
        iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/work-from-home/1602128147290/work-from-home.png',
    },
    {
        caption: 'g9_students', 
        routerLink: `/en/${AccountType.SCHOOL_ADMIN}/${SchoolAdminView.STUDENTS}`,
        iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/students/1602128010278/students.png',
    },
    {
        caption: 'g9_sessions', 
        routerLink: `/en/${AccountType.SCHOOL_ADMIN}/${SchoolAdminView.SESSIONS}`,
        iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/calendar/1602128832706/calendar.png',
    },
]

export const schoolAdminPanels_NBED:IPanelLink[] = [
    {
        caption: 'lbl_tech_readi', 
        routerLink: `/en/${AccountType.SCHOOL_ADMIN}/${NBEDSchoolAdminView.NBED_TECH_READI}`,
        iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/clipboard/1602235150476/clipboard.png',
    },
    {
        caption: 'lbl_teachers_nbed', 
        routerLink: `/en/${AccountType.SCHOOL_ADMIN}/${NBEDSchoolAdminView.NBED_TEACHERS}`,
        iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/work-from-home/1602128147290/work-from-home.png',
    },
    {
        caption: 'g9_students',
        routerLink: `/en/${AccountType.SCHOOL_ADMIN}/${NBEDSchoolAdminView.NBED_STUDENTS}`,
        iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/students/1602128010278/students.png',
    },
]

export const schoolAdminPanels_ABED:IPanelLink[] = [
    {   // Groupings
        caption: 'lbl_classes_groups_ABED', 
        routerLink: `/en/${AccountType.SCHOOL_ADMIN}/${ABEDSchoolAdminView.ABED_CLASSROOMS}`,
        iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/school/1602128112754/school.png',
    },
    {   // Invigilators
        caption: 'abed_teach_and_exsups', 
        routerLink: `/en/${AccountType.SCHOOL_ADMIN}/${ABEDSchoolAdminView.ABED_TEACHERS}`,
        iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/work-from-home/1602128147290/work-from-home.png',
    },
    {   // Students
        caption: 'g9_students', 
        routerLink: `/en/${AccountType.SCHOOL_ADMIN}/${ABEDSchoolAdminView.ABED_STUDENTS}`,
        iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/students/1602128010278/students.png',
    },
    {   // Assessment Sessions
        caption: 'abed_ass_sess', 
        routerLink: `/en/${AccountType.SCHOOL_ADMIN}/${ABEDSchoolAdminView.ABED_SESSIONS}`,
        iconUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/calendar/1602128832706/calendar.png',
    },
]