export const generateEntries = <T> (len:number, method:(i?:number)=>T) : T[] => {
  const arr = [];
  for (let i=0; i<len; i++){
    arr.push( method(i) );
  }
  return arr;
}

export const rangeToArr = (from, to, ascending = true) => {
  const direction = ascending ? 1 : -1;
  const list = [];
  if(ascending) {
    for(let i = from; i <= to; i++) {
      list.push(i);
    }
  }
  else {
    for(let i = to; i >= from; i--) {
      list.push(i);
    }
  }
  return list;
}

export const mapBy = (arr, prop) => {
  const m = new Map();
  arr.forEach(entry => {
    m.set(entry[prop], entry)
  })
  return m;
}
