
<div *ngIf="getImage()" 
    style="position: relative;" 
    [class.center-img]="element.isCentered"
    [style.left.em]=" element.setRelative ? element.XPosition : 0" 
    [style.top.em]=" element.setRelative ? element.YPosition : 0"
    [style.filter]="getFilter()"
    [style.padding.em]="getPadding()"
>
    <div 
      [tooltip]="!!getImage().image?.altText && !element.disableAltText ? getImage().image?.altText : undefined "
      [options]="{'display': !!getImage().image?.altText && !element.disableAltText}"
      [class.is-selected-edit]="editSelection.isSelectedEdit(getImage().image, questionPubSub)"
      [style.border]="editSelection.getBorder(getImage().image, questionPubSub)"
    >
        <render-image *ngIf="getImage()" [parentElement]="element" [element]="getImage().image" (click)="clickTrigger.next(true)"></render-image>
    </div>
    <!--<render-image *ngIf="getImage()" [element]="getImage().image" [scaleWidth]="this.element.canvasScaleWidth" [scaleHeight]="this.element.canvasScaleHeight"></render-image>-->
    <div 
        *ngFor="let sub of this.element.subtexts"
        class="image-sub-text"
        [class.is-transparent-text]="sub.invisible"
        [style.width.em]="sub.width - 5"
        [style.font-size.em]="sub.size"
        [style.left.em]="sub.x/sub.size" 
        [style.top.em]="sub.y/sub.size" 
        [style.font-family]="sub.fontFamily ? sub.fontFamily : '' "
        [style.line-height]="sub.lineHeight ? sub.lineHeight : 1.3 "
        [style.text-align]="sub.alignment"
        [style.color]="getSubTextColor(sub)"
        [style.transform]="getTransform(sub)"
    >
        <render-highlightable
        [inputString]="sub.text"
        [entryId]="sub.entryId"
        [prop]="'text'"
        >
          <markdown-inline class="ignore-inversion" [ngStyle]="getSubtextStyle()" [notInline]="true" [input]="sub.text" [style.text-align]="sub.alignment"></markdown-inline>
        </render-highlightable>
      </div>

    <render-audio *ngIf="element.voiceover?.url"
        [url]="element.voiceover?.url"
        [trigger]="clickTrigger"
        [isTriggerDisabled]="!textToSpeech.isActive"
    ></render-audio>
</div>

