import { Component, OnInit, Input, ElementRef, ViewChild, SimpleChanges  } from '@angular/core';
import { HighlighterService, IConfigElemSelection } from './../highlighter.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'render-highlightable',
  templateUrl: './render-highlightable.component.html',
  styleUrls: ['./render-highlightable.component.scss']
})
export class RenderHighlightableComponent implements OnInit {

  @ViewChild('highlightableSpan') highlightableSpanRef: ElementRef;
  @ViewChild('highlightableDiv') highlightableDivRef: ElementRef;

  subscription = new Subscription();

  @Input() inputString: string;
  @Input() entryId: number;
  @Input() prop: string;
  @Input() isDiv: boolean = false;

  // Something that cannot be highlighted for selection ever, e.g. MCQ
  @Input() isNotSelectable = false;
  // Can only be highlighted as a whole
  @Input() isWholeHighlight = false;
  @Input() isImage = false;

  isHighlighted: boolean = false;
  preHighlightHtml: string;
  highlightHtml: string;
  postHighlightHtml: string;
  isResolvedHighlight: boolean;

  constructor(
    private highlighter: HighlighterService,
  ) { }

  ngOnInit(): void {
    // Read any selected text from this element when the button to comment on content is pressed
    this.subscription.add(this.highlighter.initHighlightcomment.subscribe(initHighlight => {
      if(initHighlight) {
        // Currently doesn't work if a highlight is applied, so unhighlight and let user try again
        if (this.isHighlighted) this.unhighlight();
        else this.getSelection();
      }
    }))

    // Highlight part of the text if it refers to this element
    this.subscription.add(this.highlighter.applyHighlight.subscribe(newHighlight => {
      this.unhighlight();
      if (!newHighlight) return;
      newHighlight.targets?.forEach(target => {
        if (target.entryId == this.entryId && target.prop == this.prop){
          this.isResolvedHighlight = newHighlight.isResolved;
          if (target.selection.isWhole) this.highlightWhole()
          else this.applyHighlight(target.selection.start, target.selection.end)
        }
      })
    }))
  }

  ngOnChanges(changes: SimpleChanges) {
    // If the user is modifying this text in the config, call to shift highlight locations appropriately
    if (changes.inputString && changes.inputString.previousValue) {
      this.highlighter.processConfigTextChange(this.entryId, this.prop, changes.inputString.previousValue, changes.inputString.currentValue)
      //If some part of this config text is currently actively highlighted, find which comment it is caused by and reapply highlight to the new range (which was just modified in the call above) if it's valid
      if (this.isHighlighted && !this.isWholeHighlight) {
        const {noteId} = this.highlighter.currentSelectedHighlight
        const commentDetail = this.highlighter.getCommentDetailById(noteId)
        const commentDetailForElem = commentDetail?.find(detail => detail.prop === this.prop && detail.entryId === this.entryId)
        if (!commentDetailForElem) return;
        const {start, end} = commentDetailForElem;
        this.applyHighlight(start, end)
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.highlighter.invalidateElementHighlights(this.entryId, this.prop);
  }

  getSelection(){

    try {

      if (this.isNotSelectable) return;
      const selection = window.getSelection();
      if (!selection) return;

      const selectedRange = selection.getRangeAt(0);

      const highlightableElement =  this.isDiv ? this.highlightableDivRef.nativeElement : this.highlightableSpanRef.nativeElement;

      const isPartOfSelect = selectedRange.intersectsNode(highlightableElement);
      // Don't proceed if nothing in the component was a part of the selection
      if (!isPartOfSelect) return;

      // Figure out if the selection started and/or ended in this component
      const startContainer = selectedRange.startContainer;
      const endContainer = selectedRange.endContainer;
      const isFirstInSelect = (highlightableElement == startContainer || highlightableElement.contains(startContainer))
      const isLastInSelect = (highlightableElement == endContainer || highlightableElement.contains(endContainer))

      // If it doesn't start or end there but the element is selected, consider the entire current string selected
      let startRaw = 0;
      let endRaw = this.inputString.length
      if (isFirstInSelect) {
        const startRendered = this.calculateOffset(selectedRange.startContainer, selectedRange.startOffset, highlightableElement);
        startRaw = this.highlighter.indexConversion({targetRenderedIndex: startRendered, rawString: this.inputString}).rawIndex
      }
      if (isLastInSelect) {
        const endRendered = this.calculateOffset(selectedRange.endContainer, selectedRange.endOffset, highlightableElement);
        endRaw = this.highlighter.indexConversion({targetRenderedIndex: endRendered, rawString: this.inputString}).rawIndex
      }

      const selectionInfo: IConfigElemSelection = {
        start: !this.isWholeHighlight ? startRaw : undefined,
        end: !this.isWholeHighlight ? endRaw : undefined,
        isWhole: this.isWholeHighlight ? 1 : undefined,
        highlightHtml: this.highlighter.splitHtmlOnHighlight(this.inputString, startRaw, endRaw).highlight
      }
      // Only proceed if something was highlighted
      if (startRaw !== endRaw) this.highlighter.passSelectionFromElement(this.entryId, this.prop, selectionInfo, highlightableElement)

    } catch (e) {
    }

  }

  unhighlight(){
    this.isHighlighted = false;
  }

  /**
 * Prompts the text within the index range to be rendered separately as a highlight
 * @param start - The index in the raw input string where the highlight starts (inclusive)
 * @param end - The index in the raw input string where the highlight ends (exclusive)
 */
  applyHighlight(startRaw: number, endRaw: number) {
    const {preHighlight, highlight, postHighlight} = this.highlighter.splitHtmlOnHighlight(this.inputString, startRaw, endRaw);

    this.preHighlightHtml = preHighlight;
    this.highlightHtml  = highlight;
    this.postHighlightHtml  = postHighlight;
    this.isHighlighted = true;
  }

  highlightWhole(){
    this.isHighlighted = true;
  }

  calculateOffset(container: Node, offset: number, highlightableElement: any): number {
    let currentNode = container;
    let calculatedOffset = offset;
    while (currentNode !== highlightableElement && currentNode.parentNode) {
      const parent = currentNode.parentNode;
      const childNodes = Array.from(parent.childNodes);
      for (let i = 0; i < childNodes.length; i++) {
        if (childNodes[i] === currentNode) {
          break;
        } else if (childNodes[i].textContent) {
          calculatedOffset += childNodes[i].textContent.length
        }
      }

      currentNode = parent;
    }
    return calculatedOffset;
  }

}
