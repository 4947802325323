import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LangService } from '../../core/lang.service';
import { ElementType, IElementTypeDef } from '../../ui-testrunner/models';
import { EditingDisabledService } from '../editing-disabled.service';

@Component({
  selector: 'select-element',
  templateUrl: './select-element.component.html',
  styleUrls: ['./select-element.component.scss']
})
export class SelectElementComponent implements OnInit {

  @Input() elementTypes:IElementTypeDef[];
  @Input() isElTypeDefRes:boolean;
  @Output() insert:EventEmitter<IElementTypeDef | ElementType | string> = new EventEmitter();
  @Input() isDisplayStyleDropdown:boolean 
  
  elementTypesRep

  constructor(
      private editingDisabled: EditingDisabledService,
      public lang: LangService
  ) { }

  elementTypeForInsertion = new FormControl('0');

  ngOnInit() {
    if (!this.elementTypeForInsertion.value){
      this.elementTypeForInsertion.setValue(ElementType.TEXT);
    }

    
    this.elementTypesRep = [...this.elementTypes]
    if (this.elementTypesRep) {
      this.elementTypesRep.forEach((type, index) => {
        if (type.id === 'chemistry') {
          this.elementTypesRep.splice(index, 1) 
        }
      })
    }
  }

  insertListEntry(){
    const i = this.elementTypeForInsertion.value;
    const elTypeDef = this.isDisplayStyleDropdown? this.elementTypesRep[i] : this.elementTypes[i];
    if (this.isElTypeDefRes){
      this.insert.emit(elTypeDef);
    }
    else{
      this.insert.emit(elTypeDef.id);
    }
    
  }

  isReadOnly() {
    return this.editingDisabled.isReadOnly();
  }
}
