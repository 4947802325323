export const SCHOOL_ADMIN_TECH_READ_CHECKLIST_MASTER  = {
  'tech_redi_training': {
    caption: ('tech_redi_training_txt'),
    links: [
      {
        linkUrl: `https://www.eqao.com/the-assessments/primary-division/`,
        linkCaption: 'tech_readi_pimary_link_caption' 
      },
      {
        linkUrl: `https://www.eqao.com/the-assessments/junior-division/`,
        linkCaption: 'tech_readi_junior_link_caption' 
      }
    ]
  },
  'tech_redi_school_board_check': {
    caption: ('tech_redi_school_board_txt'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
    links: []
  },
  'tech_redi_school_board_check_pj': {
    caption: ('tech_redi_school_board_pj_txt'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
    links: []
  },
  'tech_redi_school_board_check_scanner_pj': {
    caption: ('tech_redi_school_board_scanner_pj_txt'), //'The school board has provided assurances with regards to availability of the technical readiness of the network.',
    links: []
  },
  'tech_redi_school_board_safe_sender': {
    caption: 'tech_redi_school_board_safe_sender_txt',
    links: []
  },
  'tech_redi_school_board_safe_sender_pj': {
    caption: 'tech_redi_school_board_safe_sender_pj_txt',
    links: []
  },
  'tech_redi_school_board_safe_sender_abed': 
  {
    caption: 'abed_techread_email',
    links: []
  },
  // 'tech_redi_students': {
  //   caption: 'tech_redi_students_txt',
  //   links: [{
  //     linkUrl: 'link_school_admin_students',
  //     linkCaption: 'tech_redi_student_page_link' 
  //   }]
  // },
  // 'tech_redi_students_abed': 
  // {
  //   caption: 'abed_techread_student',
  //   links: 
  //   [
  //     {
  //       linkUrl: 'link_school_admin_students',
  //       linkCaption: 'abed_techread_studentrev' 
  //     }
  //   ],
  // },
  // 'tech_redi_accomm': {
  //   caption: 'tech_redi_accomm_txt',
  //   links: [{
  //     linkUrl: 'link_school_admin_students',
  //     linkCaption: 'tech_redi_accomm_link' 
  //   }]
  // },
  'tech_redi_accomm_pj': {
    caption: 'tech_redi_accomm_pj_txt',
    links: [{
      linkUrl: 'link_school_admin_students',
      linkCaption: 'tech_redi_accomm_link' 
    }]
  },
  // 'tech_redi_accomm_abed': 
  // {
  //   caption: 'abed_techread_acc',
  //   links: [{
  //     linkUrl: 'link_school_admin_students',
  //     linkCaption: 'abed_techread_accrev' 
  //   }]
  // },
  'tech_redi_classrooms': {
    caption: 'tech_redi_classrooms_txt',
    links: [{
      linkUrl: `link_school_admin_classrooms`,
      linkCaption: 'tech_redi_classrooms_link' 
    }]
  },
  'tech_redi_classrooms_pj': {
    caption: 'tech_redi_classrooms_pj_txt',
    links: [{
      linkUrl: `link_school_admin_classrooms`,
      linkCaption: 'tech_redi_classrooms_pj_link' 
    }]
  },
  'tech_redi_classrooms_abed': 
  {
    caption: 'abed_techread_class',
    links: 
    [
      {
        linkUrl: `link_school_admin_classrooms`,
        linkCaption: 'abed_techread_classrev' 
      }
    ]
  },
  'tech_redi_teacher_acc': {
    caption: 'tech_redi_teacher_acc_txt', 
    links: [
      {
        linkUrl: `link_school_admin_teachers`,
        linkCaption: 'tech_redi_teacher_acc_link' 
      }
    ]
  },
  'tech_redi_teacher_acc_pj': {
    caption: 'tech_redi_teacher_acc_pj_txt', 
    links: [
      {
        linkUrl: `link_school_admin_teachers`,
        linkCaption: 'tech_redi_teacher_acc_link' 
      }
    ]
  },
  'tech_redi_teacher_acc_abed': 
  {
    caption: 'abed_techread_teacher', 
    links: 
    [
      {
        linkUrl: `link_school_admin_teachers`,
        linkCaption: 'abed_techread_teacherrev' 
      }
    ]
  },
  'tech_redi_device_1': 
  {
    caption: 'tech_read_check_dev_1', 
    links: [
      {
        linkUrl: 'tech_readi_process_url',
        linkCaption: 'tech_readi_process_caption', 
      },
      {
        linkUrl: 'download_seb_windows_url',
        linkCaption: 'download_seb_windows'
      },
      {
        linkUrl: 'download_seb_mac_url',
        linkCaption: 'download_seb_mac'
      },
      {
        slug: 'SEB_CONFIG',
        isSecure: true,
        linkCaption: 'tech_redi_download_seb_cf', 
        descSlug: 'txt_dl_seb_config'
      },{
        slug: 'SEB_CONFIG_PASS',
        isSecure: true,
        linkCaption: 'tech_redi_download_seb_pass',
      },
      {
        linkUrl: 'https://chrome.google.com/webstore/detail/kioåsk/afhcomalholahplbjhnmahkoekoijban',
        linkCaption: 'tech_redi_device_kiosk_ext',
      }, {
        slug: 'KIOSK_POLICY',
        isSecure: true,
        linkCaption: 'tech_redi_device_kiosk_cfg',
        descSlug: 'txt_dl_kiosk_policy'
      }, 
      {
        slug: 'KIOSK_POLICY_CREDS',
        isSecure: true,
        linkCaption: 'tech_redi_device_kiosk_pass',
      },
      {
        linkUrl: 'direct_link_asses',
        linkCaption: 'direct_link'
      }
    ],
    selectionCaption:[
      {
        heading:'tech_read_check_dev_2', 
        selectionOptions: [
          {slug:'tech_read_check_dev_3', caption:'tech_read_check_dev_3'},
          {slug:'tech_read_check_dev_4', caption:'tech_read_check_dev_4'},
          {slug:'tech_read_check_dev_soft_winmac', caption:'tech_read_check_dev_soft'},
        ],
        isForG9: true,
        isForOsslt: true,
      },
      {
        heading:'tech_read_check_dev_5',
        selectionOptions: [
          {slug:'tech_read_check_dev_6', caption:'tech_read_check_dev_6'},
          {slug:'tech_read_check_dev_soft_chrometab', caption:'tech_read_check_dev_soft'},
        ],
        isForG9: true,
        isForOsslt: true,
      },
      {
        heading:'sa_tech_readiness_alt_access', 
        selectionOptions: [],
        isForG9: true,
        isForOsslt: true,
      },
      // {
      //   heading:'sa_tech_readiness_footer_mod',
      //   selectionOptions: [],
      //   isForOsslt: true,
      // },
      // {
      //   heading:'sa_tech_readiness_clarify_passwords',
      //   selectionOptions: [],
      //   isForOsslt: true,
      // },
      {
        heading:'lbl_direct_access',  
        selectionOptions: [
          {slug:'tech_read_check_dev_softlock_insecure', caption:'tech_read_enable_g9_softlock'},
        ],
        isForG9: true,
      },
      {
        heading:'tech_read_enable_g9_softlock_content', 
        selectionOptions: [],
        isForG9: true,
      },
    ]
  },
  'tech_redi_device_1_pj': {
    caption: 'tech_read_check_dev_1_pj', 
    links: [
      {
        linkUrl: 'direct_link_asses',
        linkCaption: 'direct_link_no_assis_tech', 
      },
      // {
      //   linkUrl: 'tech_readi_process_url',
      //   linkCaption: 'tech_readi_process_caption', 
      // },
      {
        linkUrl: 'download_seb_windows_url',
        linkCaption: 'download_seb_windows'
      },
      {
        linkUrl: 'download_seb_mac_url',
        linkCaption: 'download_seb_mac'
      },
      {
        slug: 'SEB_CONFIG',
        isSecure: true,
        linkCaption: 'tech_redi_download_seb_cf', 
        descSlug: 'txt_dl_seb_config'
      },{
        slug: 'SEB_CONFIG_PASS',
        isSecure: true,
        linkCaption: 'tech_redi_download_seb_pass',
      },
      {
        linkUrl: 'https://chrome.google.com/webstore/detail/kioåsk/afhcomalholahplbjhnmahkoekoijban',
        linkCaption: 'tech_redi_device_kiosk_ext',
      }, {
        slug: 'KIOSK_POLICY',
        isSecure: true,
        linkCaption: 'tech_redi_device_kiosk_cfg',
        descSlug: 'txt_dl_kiosk_policy'
      }, 
      {
        slug: 'KIOSK_POLICY_CREDS',
        isSecure: true,
        linkCaption: 'tech_redi_device_kiosk_pass',
      },
      {
        linkUrl: 'direct_link_asses',
        linkCaption: 'direct_link'
      }
    ]
  },
  'tech_redi_device_1_abed': 
  {
    caption: 'abed_techread_check_dev_1', 
    links: 
    [
      // commented out for now, as we do not have technical readiness doc for ABED yet
      // {
      //   linkUrl: 'tech_readi_process_url',
      //   linkCaption: 'abed_techread_process', 
      // },
      {
        linkUrl: 'abed_download_seb_windows_url',
        linkCaption: 'abed_techread_SEB_Windows'
      },
      {
        linkUrl: 'abed_download_seb_mac_url',
        linkCaption: 'abed_techread_SEB_Mac'
      },
      {
        slug: 'SEB_CONFIG',
        isSecure: true,
        linkCaption: 'abed_techread_SEB_configfile', 
        descSlug: 'abed_txt_dl_seb_config',
        disabled: false
      },
      // {
      //   slug: 'SEB_CONFIG_PASS',
      //   isSecure: true,
      //   linkCaption: 'abed_techread_SEB_pw',
      //   disabled: false
      // },
      {
        linkUrl: 'https://chrome.google.com/webstore/detail/kioåsk/afhcomalholahplbjhnmahkoekoijban',
        linkCaption: 'abed_techread_kiosk_ext',
      }, {
        slug: 'KIOSK_POLICY',
        isSecure: true,
        linkCaption: 'abed_techread_kiosk_policy',
        descSlug: 'abed_txt_dl_kiosk_policy',
        disabled: false
      }, 
      // {
      //   slug: 'KIOSK_POLICY_CREDS',
      //   isSecure: true,
      //   linkCaption: 'abed_techread_kiosk_pw',
      //   disabled: false
      // },
      // {
      //   linkUrl: 'abed_techread__login_link',
      //   linkCaption: 'abed_techread_assistlink'
      // }
    ],
    selectionCaption:
    [
      {
        heading:'abed_techread_check_dev_2', 
        selectionOptions: 
        [
          {slug:'abed_techread_check_dev_3', caption:'abed_techread_check_dev_3'},
          {slug:'abed_techread_check_dev_4', caption:'abed_techread_check_dev_4'},
          // {slug:'abed_techread_check_dev_5', caption:'abed_techread_check_dev_5'},
        ],
        isForG9: false,
        isForOsslt: false,
        isForABED: true,
      },
      {
        heading:'abed_techread_check_dev_6',
        selectionOptions: 
        [
          {slug:'abed_techread_check_dev_7', caption:'abed_techread_check_dev_7'},
          // {slug:'abed_techread_check_dev_5', caption:'abed_techread_check_dev_5'},
        ],
        isForG9: false,
        isForOsslt: false,
        isForABED: true,
      },
      {
        // heading:'abed_techread_check_alt_access', 
        // selectionOptions: [],
        // isForG9: false,
        // isForOsslt: false,
        // isForABED: true,
      },
      // {
      //   heading:'sa_tech_readiness_footer_mod',
      //   selectionOptions: [],
      //   isForOsslt: true,
      // },
      // {
      //   heading:'sa_tech_readiness_clarify_passwords',
      //   selectionOptions: [],
      //   isForOsslt: true,
      // },
      // {
      //   heading:'abed_techread_check_direct',  
      //   selectionOptions: 
      //   [
      //     {
      //       slug:'abed_techread_check_direct_2', 
      //       caption:'abed_techread_check_direct_2'
      //     },
      //   ],
      //   isForG9: false,
      //   isForOsslt: false,
      //   isForABED: true,
      // },
      // {
      //   heading:'abed_techread_check_direct_3', 
      //   selectionOptions: [],
      //   isForG9: false,
      //   isForOsslt: false,
      //   isForABED: true,
      // },
    ]
  },
  'tech_redi_schedule_assessment': {
    caption: ('tech_redi_schedule_assessment_txt'), 
    links: [{
      linkUrl: 'link_schedule_assessment',
      linkCaption: 'tech_redi_schedule_assessment_link' 
    }]
  },
  'tech_redi_schedule_sample': {
    caption: ('tech_redi_schedule_sample_txt'),
    links: [{
      linkUrl: 'link_schedule_sample',
      linkCaption: 'tech_redi_schedule_sample_link' 
    }]
  },
  'tech_redi_print_response_primary': {
    caption: ('tech_redi_print_response_primary_txt'),
    links: [{
      linkUrl: 'link_school_admin_sessions',
      linkCaption: 'tech_redi_print_response_link' 
    }]
  },
  'tech_redi_print_response_junior': {
    caption: ('tech_redi_print_response_junior_txt'),
    links: [{
      linkUrl: 'link_school_admin_sessions',
      linkCaption: 'tech_redi_print_response_link' 
    }]
  },
  'tech_redi_scan_response': {
    caption: ('tech_redi_scan_response_txt'), 
    links: [{
      linkUrl: 'link_school_admin_sessions',
      linkCaption: 'tech_redi_scan_page_link' 
    }]
  },
  'tech_redi_review_image': {
    caption: ('tech_redi_review_image_txt'),
    links: [
      {
        linkUrl: 'link_school_admin_sessions',
        linkCaption: 'tech_redi_scan_page_link' 
      },
      {
        linkUrl: 'link_to_scanning_process',
        linkCaption: 'tech_redi_kb_link' 
      }
    ]
  },
  'tech_redi_validate_stu_info': {
    caption: ('tech_redi_validate_stu_info_txt'),
    links: [
      {
        linkUrl: 'link_validate_stu_info',
        linkCaption: 'tech_redi_validate_stu_info_link' 
      },
      {
        linkUrl: 'link_to_student_data',
        linkCaption: 'tech_redi_kb_link' 
      }
    ]
  },
  'tech_redi_validate_stu_info_pj_private': {
    caption: ('tech_redi_validate_stu_info_txt_pj_private'),
    links: [
      {
        linkUrl: 'link_validate_stu_info',
        linkCaption: 'tech_redi_validate_stu_info_link' 
      },
      {
        linkUrl: 'link_to_kb',
        linkCaption: 'tech_redi_kb_link' 
      }
    ]
  },
  'tech_redi_access_isr': {
    caption: ('tech_redi_access_isr_txt'),
    links: [{
      linkUrl: 'link_access_isr',
      linkCaption: 'tech_redi_access_isr_link' 
    }]
  },
  'abed_conf_checks':[
    {val: false, caption: `abed_confidentiality_check_nocopy`, isHidden: false},
    {val: false, caption: `abed_confidentiality_check_discuss`, isHidden: false},
    {val: false, caption: `abed_confidentiality_check_understand`, isHidden: true},
    {val: false, caption: `abed_confidentiality_check_comments`, isHidden: false}
  ]
}
  
  
  
  
  
  
  

  
  ////  ....
  
  // {
  //   id: 'devices_seb',
  //   caption: 'tech_redi_device_seb_txt', 
  //   links: [{
  //     linkUrl: 'https://safeexambrowser.org/download_en.html',
  //     linkCaption: 'tech_redi_download_seb_sw'
  //   }]
  // },
  // {
  //   id: 'devices_seb_config',
  //   caption: 'tech_redi_device_seb_config_txt',
  //   links: [
  //     {
  //       slug: 'SEB_CONFIG',
  //       isSecure: true,
  //       linkCaption: 'tech_redi_download_seb_cf', 
  //       descSlug: 'txt_dl_seb_config'
  //     },{
  //       slug: 'SEB_CONFIG_PASS',
  //       isSecure: true,
  //       linkCaption: 'tech_redi_download_seb_pass',
  //     }
  //   ]
  // },
  // {
  //   id: 'devices_kiosk',
  //   caption: 'tech_redi_device_kiosk_txt', 
  //   links: [{
  //     linkUrl: 'https://chrome.google.com/webstore/detail/kioåsk/afhcomalholahplbjhnmahkoekoijban',
  //     linkCaption: 'tech_redi_device_kiosk_ext',
  //   }, {
  //     slug: 'KIOSK_POLICY',
  //     isSecure: true,
  //     linkCaption: 'tech_redi_device_kiosk_cfg',
  //     descSlug: 'txt_dl_kiosk_policy'
  //   }, {
  //     slug: 'KIOSK_POLICY_CREDS',
  //     isSecure: true,
  //     linkCaption: 'tech_redi_device_kiosk_pass',
  //   }]
  // },